import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedCheckableTag, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useImages } from 'hooks/useImages';
import { useServerConfig } from 'hooks/useServerConfig';
import { CominghomeBlock } from 'models/server/CominghomeBlock';
import { Datapoint } from 'models/server/Datapoint';
import { DatapointType } from 'models/server/enums/DatapointType';
import { WholePageSpace } from 'pages/SceneEditPage/SceneEditPage.styles';
import SwitchpointDeviceView from 'pages/SchedulerEditPage/SwitchpointDeviceView/SwitchpointDeviceView';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './HomeButtonsEditPage.styles';

const configItems: (0 | 1 | 2)[] = [0, 1, 2];

const HomeButtonsEditPage = () => {
    const { t } = useTranslation();
    const { in_home, away_from_home, sleep } = useImages();
    const { currentServer, currentVirtualDevice, serverConfigLoading } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { onGoBack } = useGoBack();

    const config: Record<
        number,
        {
            title: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            img: any;
            prop: 'ComingHome' | 'LeavingHome' | 'Sleep';
        }
    > = useMemo(
        () => ({
            0: {
                title: t('general.comingHome'),
                img: in_home,
                prop: 'ComingHome',
            },
            1: {
                title: t('general.leavingHome'),
                img: away_from_home,
                prop: 'LeavingHome',
            },
            2: {
                title: t('general.sleep'),
                img: sleep,
                prop: 'Sleep',
            },
        }),
        [in_home, away_from_home, sleep],
    );

    const timeProgramDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.CentralComingHomeConfig),
        [currentVirtualDevice?.datapoints],
    );

    const [currentModeIndex, setCurrentModeIndex] = useState<0 | 1 | 2>(0);
    const [blocks, setBlocks] = useState<CominghomeBlock[]>(timeProgramDatapoint?.CominghomeConfig?.Buttons ?? []);

    useEffect(() => {
        setBlocks(timeProgramDatapoint?.CominghomeConfig?.Buttons ?? []);
    }, [timeProgramDatapoint?.CominghomeConfig?.Buttons]);

    const onSave = async () => {
        if (!timeProgramDatapoint) {
            return;
        }

        const newDatapoint: Datapoint = {
            ...timeProgramDatapoint,
            CominghomeConfig: {
                Buttons: blocks,
            },
        };

        const result = await onDatapointChange(newDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onValueChange = (block: CominghomeBlock, value: string) => {
        setBlocks((prev) =>
            prev.map((prevBlock) =>
                prevBlock === block ? { ...prevBlock, [config[currentModeIndex].prop]: value } : prevBlock,
            ),
        );
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!timeProgramDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (timeProgramDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <WholePageSpace size={20} wrap>
                    {configItems.map((type) => (
                        <ThemedCheckableTag
                            onClick={() => setCurrentModeIndex(type)}
                            key={type}
                            checked={currentModeIndex === type}
                        >
                            <Styled.HeaderTagWrapper>
                                <Styled.TagImg src={config[type].img} />
                                {config[type].title}
                            </Styled.HeaderTagWrapper>
                        </ThemedCheckableTag>
                    ))}
                </WholePageSpace>
                <Space size={20} wrap>
                    <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                    <ThemedButton $action onClick={onSave}>
                        {t('general.save')}
                    </ThemedButton>
                </Space>
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {blocks.map((block, index) => (
                    <SwitchpointDeviceView
                        ObjectID={block.ObjectID}
                        currentValue={block[config[currentModeIndex].prop]}
                        key={index}
                        onChangeDeviceValue={(v) => onValueChange(block, v)}
                    />
                ))}
            </Space>
        </Styled.MainWrapper>
    );
};

export default HomeButtonsEditPage;
