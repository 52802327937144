import styled from 'styled-components';

export const Title = styled.div`
    font-weight: 500;
    margin: 8px 5px 3px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    min-width: 300px;
`;

export const PropertyWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
