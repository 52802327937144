import { AccountInfo } from '@azure/msal-browser';
import { routes } from 'App';
import { Form } from 'antd';
import { demoTypeSearch, demoTypeSearchValue } from 'components/ProtectedRoute/ProtectedRoute';
import { isEmpty } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import { useServerApi } from 'hooks/useServerApi';
import loginBackgroundImg from 'images/loginBackgroundImg.jpg';
import logoImg from 'images/tem_smarthome_logo_light.png';
import { User } from 'models/User';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Styled from './LoginPage.styles';

enum FormFields {
    username = 'username',
    password = 'password',
}

const LoginPage = () => {
    const { t } = useTranslation();
    const { updateAuthUser, login, getAccount, logout } = useAuth();
    const { apiWithoutLicenseFetch } = useApi();
    const { testServerConnection } = useServerApi();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(true);
    const [account, setAccount] = useState<AccountInfo>();
    const [user, setUser] = useState<User>();
    const [showServerCredencials, setShowServerCredentials] = useState(false);

    const init = async () => {
        const account = await getAccount();
        setAccount(account);

        if (account) {
            const { result } = await apiWithoutLicenseFetch<User>('User');

            if (!result) {
                toast.error(t('errors.connectionFailed'));
                setIsLoading(false);
                return;
            }

            if (result.isLocalUser && result.portalUsers.length === 0) {
                setShowServerCredentials(true);
                setUser(result);
                setIsLoading(false);
                return;
            }

            updateAuthUser({
                ...result,
                portalUsers: result.portalUsers.map((pu) => ({ ...pu, configuration: undefined })),
            });
            navigate(routes.home);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    const onLogin = () => {
        const loginResult = login();

        if (!loginResult) {
            toast.error(t('errors.connectionFailed'));
            return;
        }

        init();
    };

    const onServerLogin = async ({ username, password }: { username: string; password: string }) => {
        if (!user) {
            return;
        }

        setIsLoading(true);

        const serverUser: User = {
            ...user,
            username: username,
            password: password,
        };
        const info = await testServerConnection(serverUser);

        if (info.result) {
            updateAuthUser(serverUser);
            navigate(routes.home);
            return;
        }

        switch (info.code) {
            case 401:
                toast.error(t('errors.wrongLoginOrPassword'));
                break;
            default:
                toast.error(t('errors.connectionFailed'));
                break;
        }

        setIsLoading(false);
    };

    const onShowDemo = () => {
        navigate({ pathname: routes.home, search: `?${demoTypeSearch}=${demoTypeSearchValue}` });
    };

    return (
        <Styled.MainWrapper>
            <ToastContainer style={{ zIndex: 999999998 }} />
            <Styled.BackgroundImage src={loginBackgroundImg} />
            <Styled.ContentWrapper>
                <Styled.LogoImage src={logoImg} />
                {isLoading ? (
                    <Styled.ButtonWrapper>
                        <Styled.ColorSpin size="large" />
                    </Styled.ButtonWrapper>
                ) : (
                    <Styled.ButtonWrapper>
                        {account && !isEmpty(account.idTokenClaims?.username?.toString()) && (
                            <Styled.HelloWrapper>
                                <Styled.Hello>{`${t('loginPage.currentAccount')}:`}</Styled.Hello>
                                {account.idTokenClaims?.username?.toString()}
                            </Styled.HelloWrapper>
                        )}
                        {!showServerCredencials && (
                            <Styled.LoginButton onClick={onLogin} type="primary">
                                {t('loginPage.login')}
                            </Styled.LoginButton>
                        )}
                        {account && (
                            <Styled.LogoutButton onClick={logout} type="primary">
                                {t('header.logout')}
                            </Styled.LogoutButton>
                        )}
                        {!showServerCredencials && (
                            <Styled.DemoButton onClick={onShowDemo} type="primary">
                                {t('loginPage.checkDemo')}
                            </Styled.DemoButton>
                        )}
                        {showServerCredencials && (
                            <Styled.LoginForm form={form} onFinish={onServerLogin}>
                                <Styled.Hello>{`${t('loginPage.serverUsername')}:`}</Styled.Hello>
                                <Form.Item
                                    name={FormFields.username}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('errors.fieldCannotBeEmpty'),
                                        },
                                    ]}
                                >
                                    <Styled.UsernameInput />
                                </Form.Item>
                                <Styled.Hello>{`${t('loginPage.serverPassword')}:`}</Styled.Hello>
                                <Form.Item
                                    name={FormFields.password}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('errors.fieldCannotBeEmpty'),
                                        },
                                    ]}
                                >
                                    <Styled.PasswordInput />
                                </Form.Item>
                                <Styled.LoginButton type="primary" htmlType="submit">
                                    {t('loginPage.login')}
                                </Styled.LoginButton>
                            </Styled.LoginForm>
                        )}
                    </Styled.ButtonWrapper>
                )}
            </Styled.ContentWrapper>
        </Styled.MainWrapper>
    );
};

export default LoginPage;
