import { ThemedCard } from 'components/ThemedComponents/ThemedComponents.styles';
import styled, { css } from 'styled-components';

export const ElementWrapper = styled(ThemedCard)<{ $selectable?: boolean; $draggable?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    min-width: 120px;
    position: relative;
    ${(props) =>
        props.$selectable &&
        css`
            cursor: pointer;

            &:hover {
                background-color: ${props.theme.colors.backgroundLayer2 + 'B3'} !important;
            }
        `}
    ${(props) =>
        props.$draggable &&
        css`
            cursor: -webkit-grab;
        `}
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 14px;
`;

export const DropdownMenu = styled.div`
    position: absolute;
    right: 3px;
    top: 8px;
    cursor: pointer;
`;
