import { Tooltip } from 'antd';
import {
    ThemedButton,
    ThemedCheckboxGroup,
    ThemedModal,
    ThemedSearch,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { AuthContext } from 'contexts/AuthContext';
import { isEmpty } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import { PortalUser } from 'models/User';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import * as Styled from './AssignServerModal.styles';

type Props = {
    user: PortalUser;
    onCancel: () => void;
    onRefetch: () => void;
};

const AssignServerModal = ({ user, onCancel, onRefetch }: Props) => {
    const { t } = useTranslation();
    const { servers } = useContext(AuthContext);
    const { apiFetch } = useApi();

    const [search, setSearch] = useState<string>('');
    const [selectedServers, setSelectedServers] = useState<number[]>(user.userServerAssignments.map((x) => x.serverId));
    const [loading, setLoading] = useState(false);

    const filteredServers = useMemo(() => {
        const licenseServers = servers.filter((x) => x.licenseId === user.licenseId);

        if (isEmpty(search)) {
            return licenseServers.map((x) => ({ label: x.name, value: x.id }));
        }

        return licenseServers
            .filter(
                (x) =>
                    x.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
                    x.address?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
                    x.tags?.some((z) => z.value?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())),
            )
            .map((x) => ({ label: x.name, value: x.id }));
    }, [servers, search, user]);

    const onSelectAll = () => {
        setSelectedServers((prev) => [
            ...prev,
            ...filteredServers.filter((z) => !selectedServers.includes(z.value)).map((x) => x.value),
        ]);
    };

    const onSave = async () => {
        setLoading(true);

        const result = await apiFetch('UserServerAssignments', 'PUT', undefined, {
            userId: user.id,
            serverIds: selectedServers,
        });

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(false);
            return;
        }

        setLoading(false);
        onCancel();
        onRefetch();
    };

    return (
        <ThemedModal
            width={400}
            title={t('general.assignServers')}
            open
            onCancel={onCancel}
            style={{ top: 100 }}
            maskClosable={false}
            footer={null}
        >
            <Styled.Wrapper>
                <Styled.Row>
                    <ThemedSearch
                        value={search}
                        onChange={(v) => setSearch(v.currentTarget.value)}
                        prefix={<IoSearchOutline />}
                    />
                    <Tooltip title={t('general.onlyFilteredServersWillBeSelected')}>
                        <ThemedButton onClick={onSelectAll}>{t('general.selectAll')}</ThemedButton>
                    </Tooltip>
                </Styled.Row>
                {filteredServers.length === 0 && <Styled.Empty>{t('general.noServersToShow')}</Styled.Empty>}
                <ThemedCheckboxGroup
                    disabled={loading}
                    options={filteredServers}
                    value={selectedServers}
                    onChange={(v) => setSelectedServers(v as number[])}
                    $version="2"
                />
            </Styled.Wrapper>
            <Footer>
                <ThemedButton onClick={onCancel} $version="2" disabled={loading}>
                    {t('general.cancel')}
                </ThemedButton>
                <ThemedButton onClick={onSave} $version="2" loading={loading}>
                    {t('general.save')}
                </ThemedButton>
            </Footer>
        </ThemedModal>
    );
};

export default AssignServerModal;
