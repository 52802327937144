import { ServerContext } from 'contexts/ServerContext';
import { useImages } from 'hooks/useImages';
import { useServerApi } from 'hooks/useServerApi';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { useContext, useState } from 'react';
import * as Styled from './FavoriteView.styles';

type Props = {
    virtualDevice: VirtualDevice;
};

const rankings = [1, 2, 3, 4, 5];

const FavoriteView = ({ virtualDevice }: Props) => {
    const { favourite, ranking } = virtualDevice;
    const { favorite_active, favorite_empty } = useImages();
    const currentServer = useContext(ServerContext);
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;

    const [rankingVisible, setRankingVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onFavoriteClick = async () => {
        setIsLoading(true);
        await apiServerFetchWithCustomPortalId(currentServer.server?.id ?? 0, 'objects', undefined, 'PUT', {
            objects: [
                {
                    id: virtualDevice.id,
                    favourite: !favourite,
                    ranking: ranking,
                },
            ],
        });
        setIsLoading(false);
        setRankingVisible(false);
    };

    const onRankingClick = async (newRanking: number) => {
        setIsLoading(true);
        await apiServerFetchWithCustomPortalId(currentServer.server?.id ?? 0, 'objects', undefined, 'PUT', {
            objects: [
                {
                    id: virtualDevice.id,
                    favourite: favourite,
                    ranking: newRanking === 1 && newRanking === ranking ? 0 : newRanking,
                },
            ],
        });
        setIsLoading(false);
        setRankingVisible(false);
    };

    if (isLoading) {
        return <Styled.LoadingSpin />;
    }

    return (
        <Styled.MainWrapper
            onMouseEnter={!isTouchDevice ? () => setRankingVisible(true) : undefined}
            onMouseLeave={() => setRankingVisible(false)}
        >
            {rankingVisible && (
                <Styled.RankingWrapper>
                    {rankings.map((r) => (
                        <Styled.Circle onClick={() => onRankingClick(r)} key={r} $filled={ranking >= r} />
                    ))}
                </Styled.RankingWrapper>
            )}
            <Styled.FavImage onClick={onFavoriteClick} src={favourite ? favorite_active : favorite_empty} />
        </Styled.MainWrapper>
    );
};

export default FavoriteView;
