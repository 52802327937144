import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const FloorTag = styled(ThemedCheckableTag)`
    padding: 8px 16px;
`;
