import { AuthContext } from 'contexts/AuthContext';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from './useAuth';

const usePortalUser = () => {
    const { authUser: user } = useAuth();
    const { servers } = useContext(AuthContext);
    const { serverId, licenseId } = useParams<{ serverId: string; licenseId: string }>();

    const currentServer = useMemo(
        () => (serverId ? servers.find((x) => x.id === Number(serverId)) : undefined),
        [servers, serverId],
    );

    const portalUser = useMemo(
        () => user?.portalUsers?.find((x) => x.licenseId === (currentServer?.licenseId ?? Number(licenseId ?? 0))),
        [currentServer, user, licenseId],
    );

    return { currentServer, portalUser, licenseId };
};

export default usePortalUser;
