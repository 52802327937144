import { Space } from 'antd';
import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const HeaderWrapper = styled(Space)`
    display: flex;
    gap: 10px;
`;

export const TimeThemedCheckableTag = styled.div`
    padding: 10px 15px;
    display: flex;

    gap: 15px;
    align-items: center;
    width: auto;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    border-radius: 8px;
`;

export const IconWrapper = styled.div`
    width: 20px;
    display: flex;
    align-items: center;
    top: 8px;
    right: 8px;
    cursor: pointer;
`;

export const AddThemedCheckableTag = styled(ThemedCheckableTag)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    width: 80px;
`;

export const FitContentSpace = styled(Space)`
    height: fit-content;
    align-items: flex-start;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
`;

export const SliderWrapper = styled.div`
    width: 150px;
`;

export const InputWrapper = styled.div`
    width: 100px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
`;

export const TimeInputWrapper = styled(InputWrapper)`
    margin-left: -35px;
`;
