import { routes } from 'App';
import { Form } from 'antd';
import { isEmpty, validatePassword } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import loginBackgroundImg from 'images/loginBackgroundImg.jpg';
import logoImg from 'images/tem_smarthome_logo_light.png';
import InvitationStatus from 'models/InvitationStatus';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Styled from './AcceptInvitationPage.styles';

enum FormFields {
    newPassword = 'newPassword',
    newPasswordConfirm = 'newPasswordConfirm',
    code = 'code',
}

const AcceptInvitationPage = () => {
    const { apiPublicFetch } = useApi();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const invitationData = searchParams.get('data');
    const [codeForm] = Form.useForm();
    const [form] = Form.useForm();
    const newPassword = Form.useWatch(FormFields.newPassword, form);
    const newPasswordConfirm = Form.useWatch(FormFields.newPasswordConfirm, form);

    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState<InvitationStatus>();
    const [state, setState] = useState<'enterCode' | 'enterPassword' | 'accepted'>('enterCode');
    const [seconds, setSeconds] = useState(90);

    useEffect(() => {
        form.validateFields();
    }, [newPassword, newPasswordConfirm]);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((p) => {
                if (p < 1) {
                    return 0;
                }
                return p - 1;
            });
        }, 1000);
        return () => interval && clearInterval(interval);
    }, []);

    const startInterval = () => {
        setSeconds(90);
    };

    const getStatus = async () => {
        const { result } = await apiPublicFetch<InvitationStatus>(
            'Invitations/Status',
            undefined,
            `?data=${invitationData}`,
        );

        return result;
    };

    const init = async () => {
        setIsLoading(true);

        const result = await getStatus();

        if (!result) {
            setIsLoading(false);
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }

        setStatus(result);

        if (result.alreadyAccepted || result.expired || result.invitationNotFound || result.userNotFound) {
            setIsLoading(false);
            return;
        }

        const { result: res } = await apiPublicFetch<InvitationStatus>(
            'Invitations/SendEmailCode',
            'POST',
            `?data=${invitationData}`,
        );

        if (!res) {
            toast.error(t('errors.errorWhileSendingValue'));
        } else {
            setState('enterCode');
            startInterval();
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (!invitationData) {
            navigate(routes.login);
            return;
        }
        init();
    }, []);

    const onAccept = async ({ newPassword }: { newPassword: string }) => {
        setIsLoading(true);

        const { result } = await apiPublicFetch<InvitationStatus>(
            'Invitations/Accept',
            'PUT',
            `?data=${invitationData}&password=${newPassword}`,
        );

        if (!result) {
            toast.error(t('errors.errorWhileSendingValue'));
            setIsLoading(false);
            return;
        }

        setState('accepted');
        setIsLoading(false);
    };

    const onConfirmCode = async ({ code }: { code: string }) => {
        setIsLoading(true);

        const { code: resCode } = await apiPublicFetch<{ code: number }>(
            'Invitations/VerifyEmailCode',
            'PUT',
            `?data=${invitationData}&code=${code}`,
        );

        if (resCode !== 200) {
            toast.error(t('errors.codeIsNotCorrect'));

            await apiPublicFetch<InvitationStatus>('Invitations/SendEmailCode', 'POST', `?data=${invitationData}`);

            startInterval();
            setIsLoading(false);
            return;
        }

        if (status?.isNewADUser) {
            setState('enterPassword');
            setIsLoading(false);
            return;
        }

        const { code: resCode2 } = await apiPublicFetch<InvitationStatus>(
            'Invitations/Accept',
            'PUT',
            `?data=${invitationData}`,
        );

        if (resCode2 !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setStatus(undefined);
            setIsLoading(false);
            return;
        }

        setState('accepted');
        setIsLoading(false);
    };

    const onNavigateToLogin = () => {
        navigate(routes.login);
    };

    const currentView = useMemo(() => {
        if (isLoading) {
            return <Styled.ColorSpin size="large" />;
        }

        if (!status) {
            return <Styled.RedEmptyError title={t('errors.couldNotGetInvitation')} />;
        }

        if (status.invitationNotFound || status.userNotFound) {
            return (
                <Styled.RedEmptyError title={t('errors.invitationNotFound')}>
                    <Styled.LoginButton onClick={onNavigateToLogin}>{t('general.goToLoginPage')}</Styled.LoginButton>
                </Styled.RedEmptyError>
            );
        }

        if (status.alreadyAccepted) {
            return (
                <Styled.GreenEmptySuccess title={t('errors.invitationAlreadyAccepted')}>
                    <Styled.LoginButton onClick={onNavigateToLogin}>{t('general.goToLoginPage')}</Styled.LoginButton>
                </Styled.GreenEmptySuccess>
            );
        }

        if (status.expired) {
            return (
                <Styled.RedEmptyError title={t('errors.invitationExpired')}>
                    <Styled.LoginButton onClick={onNavigateToLogin}>{t('general.goToLoginPage')}</Styled.LoginButton>
                </Styled.RedEmptyError>
            );
        }

        if (state === 'accepted') {
            return (
                <Styled.GreenEmptySuccess title={t('errors.invitationAccepted')}>
                    <Styled.LoginButton onClick={onNavigateToLogin}>{t('general.goToLoginPage')}</Styled.LoginButton>
                </Styled.GreenEmptySuccess>
            );
        }

        if (state === 'enterCode') {
            return (
                <Styled.LoginForm form={codeForm} onFinish={onConfirmCode}>
                    <Styled.EnterCodeInfo>{t('loginPage.codeHasBeenSentToYourEmail')}</Styled.EnterCodeInfo>
                    <Styled.LeftInfo>{`${seconds}s ${t('general.left')}`}</Styled.LeftInfo>
                    {seconds > 0 ? (
                        <>
                            <Styled.Hello>{`${t('general.code')}:`}</Styled.Hello>
                            <Form.Item
                                name={FormFields.code}
                                rules={[
                                    {
                                        required: true,
                                        message: t('errors.fieldCannotBeEmpty'),
                                    },
                                ]}
                            >
                                <Styled.PasswordInput />
                            </Form.Item>
                            <Styled.LoginButton type="primary" htmlType="submit">
                                {t('general.confirm')}
                            </Styled.LoginButton>
                        </>
                    ) : (
                        <Styled.LoginButton onClick={init} type="primary">
                            {t('general.resendCode')}
                        </Styled.LoginButton>
                    )}
                </Styled.LoginForm>
            );
        }

        if (state === 'enterPassword') {
            return (
                <Styled.LoginForm form={form} onFinish={onAccept}>
                    <Styled.Hello>{`${t('general.newPassword')}:`}</Styled.Hello>
                    <Form.Item
                        name={FormFields.newPassword}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                            {
                                message: t('errors.passwordNotValid'),
                                validator: (_, value) => {
                                    if (isEmpty(value) || validatePassword(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            },
                        ]}
                    >
                        <Styled.PasswordInput />
                    </Form.Item>
                    <Styled.Hello>{`${t('loginPage.confirmNewPassword')}:`}</Styled.Hello>
                    <Form.Item
                        name={FormFields.newPasswordConfirm}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                            {
                                message: t('errors.passwordsMustBeTheSame'),
                                validator: (_, value) => {
                                    if (isEmpty(value) || form.getFieldValue(FormFields.newPassword) === value) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            },
                        ]}
                    >
                        <Styled.PasswordInput />
                    </Form.Item>
                    <Styled.LoginButton type="primary" htmlType="submit">
                        {t('general.acceptInvitation')}
                    </Styled.LoginButton>
                </Styled.LoginForm>
            );
        }

        return <Styled.RedEmptyError title={t('errors.somethingWentWrong')} />;
    }, [isLoading, status, state, seconds]);

    return (
        <Styled.MainWrapper>
            <ToastContainer style={{ zIndex: 999999998 }} />
            <Styled.BackgroundImage src={loginBackgroundImg} />
            <Styled.ContentWrapper>
                <Styled.LogoImage src={logoImg} />
                <Styled.ButtonWrapper>{currentView}</Styled.ButtonWrapper>
            </Styled.ContentWrapper>
        </Styled.MainWrapper>
    );
};

export default AcceptInvitationPage;
