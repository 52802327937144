import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import HidingView from '../HidingView/HidingView';
import * as Styled from './WindowsAndDoors.styles';

const WindowsAndDoors = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints, category } = virtualDevice;

    const numberOfOpenDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualNumberOfOpen),
        [datapoints],
    );
    const numberOfClosedDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualNumberOfClosed),
        [datapoints],
    );
    const numberOfTiledDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualNumberOfTiledWindows,
            ),
        [datapoints],
    );
    const devicesDatapoints = useMemo(
        () => datapoints?.filter((x) => x.type == DatapointType.StatusLight || x.type === DatapointType.eHandleWindow),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {numberOfOpenDatapoint && <DatapointValueView valueOrDp={numberOfOpenDatapoint} />}
            {numberOfClosedDatapoint && <DatapointValueView valueOrDp={numberOfClosedDatapoint} />}
            {numberOfTiledDatapoint && <DatapointValueView valueOrDp={numberOfTiledDatapoint} />}
            <HidingView numberOfVisible={0} category={category}>
                {devicesDatapoints.map((dp, index) => (
                    <DatapointValueView showClosedOpenTiledAsValue valueOrDp={dp} key={index} />
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default WindowsAndDoors;
