import { HiDotsVertical } from 'react-icons/hi';

import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { WpcElement } from '../WpcSchemeEdit';
import * as Styled from './WpcSchemeElement.styles';
import { WpcElementType } from 'pages/Wpc/pages/WpcDashboardPage/WpcDashboardPage';

type Props = {
    element?: WpcElement;
    draggable?: boolean;
    onClick?: () => void;
    onDuplicate?: () => void;
    onRemove?: () => void;
    onChangeType?: () => void;
};

const WpcSchemeElement = ({ element, draggable, onRemove, onDuplicate, onClick, onChangeType }: Props) => {
    const { t } = useTranslation();

    if (!element) {
        return <></>;
    }

    return (
        <Styled.ElementWrapper checked={!!onClick} onClick={onClick} $selectable={!!onClick} $draggable={draggable}>
            {onRemove && onDuplicate && element.removable && (
                <Styled.DropdownMenu>
                    <Dropdown
                        menu={{
                            items: [
                                { key: '1', label: <div onClick={onDuplicate}>{t('general.duplicate')}</div> },
                                { key: '2', label: <div onClick={onRemove}>{t('general.remove')}</div> },
                                ...(element.type === WpcElementType.DirectHeatingCircuit
                                    ? [{ key: 3, label: <div onClick={onChangeType}>{t('wpc.changeToMixed')}</div> }]
                                    : []),
                                ...(element.type === WpcElementType.MixedHeatingCircuit
                                    ? [{ key: 4, label: <div onClick={onChangeType}>{t('wpc.changeToDirect')}</div> }]
                                    : []),
                            ],
                        }}
                    >
                        <HiDotsVertical fontSize={20} />
                    </Dropdown>
                </Styled.DropdownMenu>
            )}
            {element.icon}
            <Styled.Title>{element.name}</Styled.Title>
        </Styled.ElementWrapper>
    );
};

export default WpcSchemeElement;
