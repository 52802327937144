import { SetupInfo } from 'models/server/SetupInfo';

export const getDemoBulk = (): SetupInfo =>
    JSON.parse(
        JSON.stringify({
            devices: [
                {
                    id: 1,
                    islocal: true,
                    name: 'Radio Server',
                    serial: '130A09100100000023430077D1',
                    type: 13,
                },
                {
                    gwSerial: '130A09100100000023430077D1',
                    id: 2,
                    islocal: true,
                    name: 'Radio Valve 1',
                    serial: '0000003000000000e71fb3ee0f',
                    sleeping: false,
                    type: 5,
                },
                {
                    gwSerial: '130A09100100000023430077D1',
                    id: 3,
                    islocal: true,
                    name: 'Radio Window /Door Contact 1',
                    serial: '0000003000000000e71fb3ee0d',
                    sleeping: true,
                    type: 4,
                },
                {
                    gwSerial: '130A09100100000023430077D1',
                    id: 4,
                    islocal: true,
                    name: 'Radio Leakage Sensor 1',
                    serial: '0000003000000000e71fb3ee0c',
                    sleeping: true,
                    type: 23,
                },
                {
                    gwSerial: '130A09100100000023430077D1',
                    id: 5,
                    islocal: true,
                    name: 'Radio Socket 1',
                    serial: '0000003000000000e71fb3ee0b',
                    sleeping: false,
                    type: 2,
                },
                {
                    gwSerial: '130A09100100000023430077D1',
                    id: 6,
                    islocal: true,
                    name: 'Radio Bulb 1',
                    serial: '0000003000000000e71fb3ee0a',
                    sleeping: false,
                    type: 3,
                },
            ],
            info: {
                'bsp-version': '2.20.0',
                internet: true,
                name: 'Radio Server',
                oem: 'TEM',
                projectHash: '687b7f567ae38d260ff419aa14880cf3',
                projectId: '2db39986532561ce41d3ad220ba43cf9',
                projectName: 'myHome',
                role: 'master',
                sdcard: true,
                serialnumber: '130A09100100000023430077D1',
                serverName: 'Radio Server',
                starterkit: false,
                swcinfo: {
                    dependencies: {
                        mobileapp: '>=v2.40.0',
                        progtool: '>=v2.36.0',
                    },
                    name: 'radioserver',
                    sid: '001-alpha',
                    version: 'v2.20.0-alpha.0',
                },
                time: {
                    datetime: '2022-12-13T10:21:42Z',
                    timezone: 'Europe/Warsaw',
                },
                version: 'v2.20.0-alpha.0',
            },
            ios: [
                {
                    device: 2,
                    id: 1,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                    value: 55,
                },
                {
                    device: 2,
                    id: 2,
                    name: 'Living_room Room controller Actual setpoint',
                    path: '/0/38/',
                    type: 3,
                    value: 22,
                },
                {
                    device: 2,
                    id: 3,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                    value: 21.05,
                },
                {
                    device: 3,
                    id: 4,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                    value: 100,
                },
                {
                    device: 3,
                    id: 5,
                    name: 'Door/Window binary sensor',
                    path: '/0/48/10/',
                    type: 2,
                    value: 'false',
                },
                {
                    device: 3,
                    id: 6,
                    name: 'Lock access control notification',
                    path: '/0/113/6/',
                    type: 2,
                    value: 'false',
                },
                {
                    device: 4,
                    id: 7,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                    value: 95,
                },
                {
                    device: 4,
                    id: 8,
                    name: 'Water binary sensor',
                    path: '/0/48/6/',
                    type: 2,
                    value: 'false',
                },
                {
                    device: 4,
                    id: 9,
                    name: 'Water notification',
                    path: '/0/113/5/',
                    type: 2,
                    value: 'false',
                },
                {
                    device: 5,
                    id: 10,
                    name: 'Living_room Timer switch Output',
                    path: '/0/37/',
                    type: 1,
                    value: 'false',
                },
                {
                    device: 5,
                    id: 11,
                    name: 'Power meter',
                    path: '/0/50/2/',
                    type: 5,
                    value: '0',
                },
                {
                    device: 5,
                    id: 12,
                    name: 'Energy meter',
                    path: '/0/50/0/',
                    type: 6,
                    value: '0',
                },
                {
                    device: 5,
                    id: 13,
                    name: 'Bedroom Room controller Room temperature',
                    path: '/0/49/1/',
                    type: 7,
                    value: 24.02,
                },
                {
                    device: 5,
                    id: 14,
                    name: 'Bedroom Room controller Relative humidity room air',
                    path: '/0/49/5/',
                    type: 8,
                    value: 32.7,
                },
                {
                    device: 6,
                    id: 15,
                    name: 'Living_room Light controller Output 1',
                    path: '/0/51/',
                    type: 3,
                    value: '#630000000000ffffff',
                },
            ],
            multiserver: {
                encryptedPassword:
                    'ZxU2nZNsFAE837cbMn/JygdpGW2j9XltMXHSYq314lWEYoWknvqPnxgEFzHbI/9qQrBuNlDEzBchOa6/75pr3ApuFuEMsMx+EDmwZXRsKxcYas5HMcVy3+u4IxMvWyLxkzl19LqcLb6dxhAeHApy2RmrPHk1pmcTDLaX3SNWdBIBqqbZAPg75ZBDa400Cru2XFR3SCLvZ0VHIRNnV02ZsMMKYwyG1sZdKsDzcS7TJoIiaKFcHPkF/gVJ9zRwbs12RYU0PmVexVEqsJj6nPXCQHpPKNv6UAMmg+PoYvKQEGKLQbPo8HR2awgf2so/RkSfh5sRooeQk5kT2lRQCUD3bcyFqLp3DXP57CMfuKmEO/pRCVG/TAepqB/IguexLj4bGWjrWaeapgXlkRug2s2mKxttUxbCml5Z5FrG4sv+hTqG5r91hVeKRuvN/SopzMtEeIFvTIkpl6TU5U6qMZFd5sDliOizu9/eg2aJXrB+HfrwUP/pNDz5w0LviL/oJd653wk3XHQO1M0C5dqp3UZHKSt73Fgc72/FpO76owUWuCQOqmjpGax41cOw2XioFwaq9Ptga0FVoJ4TjkT0lVKBpN3tqBDr7Ztai4PADcXJUkd7/SCwHUBcY5pnGEmt1tlGBAhfdJ1Mv+7XFxTmETJJrEhv+lQQ+FCnnwREfmcVmcI=',
                guid: '0b9f6fcc-4279-41a6-853e-7a5428c734ba',
                localGatewaysReady: true,
                projectId: '97181358f32d1c737beee1b5eee4f303',
                remotes: [],
                role: 'master',
                user: '__admin',
            },
            notifications: [],
            objects: {
                items: [
                    {
                        category: 5,
                        datapoints: [
                            {
                                customname: '',
                                id: 57,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice1',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 2,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 58,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice2',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 2,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 59,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice3',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 2,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 60,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice4',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 3,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 65,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice9',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 67,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice11',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 69,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice1',
                                objectid: 90,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 8,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 70,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice2',
                                objectid: 90,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 5,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 71,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice3',
                                objectid: 90,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 3,
                                writeprotect: false,
                            },
                            {
                                EnergyManagementConfig: {
                                    Devices: [
                                        {
                                            L1: true,
                                            L2: true,
                                            L3: true,
                                            MaxEnergy: 10,
                                            MaxPower: 8,
                                            MinPower: 0,
                                            MinRuntime: 15,
                                            Name: 'Car charger',
                                            PreparationTime: 5,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [
                                                        28800, 28800, 28800, 28800, 28800, 28800, 28800,
                                                    ],
                                                    Hysteresis: 2,
                                                    Level: 0,
                                                    Mode: 1,
                                                },
                                            ],
                                        },
                                        {
                                            L1: true,
                                            L2: false,
                                            L3: false,
                                            MaxEnergy: 8,
                                            MaxPower: 5,
                                            MinPower: 0,
                                            MinRuntime: 30,
                                            Name: 'Air condition',
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: ['2023-09-29T22:00:00Z'],
                                                    FinishTimeScheduler: [
                                                        37800, 37800, 37800, 37800, 37800, 37800, 37800,
                                                    ],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                    Priority: 1,
                                                },
                                            ],
                                        },
                                        {
                                            L1: true,
                                            L2: true,
                                            L3: false,
                                            MaxEnergy: 5,
                                            MaxPower: 3,
                                            MinPower: 0,
                                            MinRuntime: 5,
                                            Name: 'Garden water system',
                                            PreparationTime: 0,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: ['2023-10-31T23:00:00Z'],
                                                    FinishTimeScheduler: [
                                                        37800, 52200, 52200, 52200, 52200, 52200, 41400,
                                                    ],
                                                    Hysteresis: 0,
                                                    Level: 0,
                                                    Mode: 3,
                                                    Priority: 3,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 81,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'manager.config',
                                objectid: 90,
                                range: [],
                                remote: true,
                                type: 37,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 90,
                        name: 'Energy Manager',
                        properties: [
                            {
                                id: 25,
                                pinid: '286415d1e4b39c86e6ad09b40b8a902e',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 26,
                                pinid: '7e054ba83ac8126bed1d5f82e74be4f8',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 5,
                        roomid: 6,
                        type: 36,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 1,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 2,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 1,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 3,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 1,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 4,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 5,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 6,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 7,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 8,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 1,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 1,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 2,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 3,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 4,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 5,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 6,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 7,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 8,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 9,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 10,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 11,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 12,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 13,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 14,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 15,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 16,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 17,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 18,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 19,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 2,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 20,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 2,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 21,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 2,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 2,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 9,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 10,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 11,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 12,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 13,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 14,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 15,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 16,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 17,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 18,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 19,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 20,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 21,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 22,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 23,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: 1,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 4,
                        datapoints: [
                            {
                                customname: '',
                                id: 22,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 3,
                                range: ['1', '3', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 23,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeHeating',
                                objectid: 3,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 22,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 24,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsRoomTemperature',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: 2,
                                value: 22,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 25,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRelativeHumidity',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: 19,
                                value: 45,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 26,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 3,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 27,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeCooling',
                                objectid: 3,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 28,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsHoliday',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 29,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsEconomyMode',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 30,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutdoorTemperature',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -32768,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 31,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyHeatingTemperature',
                                objectid: 3,
                                range: ['0', '40'],
                                remote: true,
                                type: -2,
                                value: 16,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 32,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyCoolingTemperature',
                                objectid: 3,
                                range: ['15', '40'],
                                remote: true,
                                type: -2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 33,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualSetpoint',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 34,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRequiredOpMode',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 35,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDewpointTemperature',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 36,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualValvePositionCont',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 37,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualOpMode',
                                objectid: 3,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: false,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Heating: 22,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Heating: 16,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 38,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tph.timeProgram',
                                objectid: 3,
                                range: [],
                                remote: true,
                                type: 8,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 3,
                        name: 'Room controller',
                        properties: [
                            {
                                id: 24,
                                pinid: 'a70e5f4a2254aaf3a903b53ff91cc84b',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 25,
                                pinid: '6d6e20e2d6a7e82386ef15930d41824e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 26,
                                pinid: '405502632d68e338630ad9729e626c57',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 27,
                                pinid: '39fa7456dcd04f3d3731db82167564ff',
                                readonly: false,
                                value: '17',
                            },
                            {
                                id: 28,
                                pinid: '7e18b8e617aeae482e9f731784a2811a',
                                readonly: false,
                                value: '22',
                            },
                            {
                                id: 29,
                                pinid: 'a24eef4850f0f8094196540b46e01d76',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 30,
                                pinid: 'a61f83aa5674dfddcfcad8c6d242f657',
                                readonly: false,
                                value: '24',
                            },
                            {
                                id: 31,
                                pinid: '1dc82f53ee242ebd9018c9fc26ccfb71',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 32,
                                pinid: '66e5d781fdd48c9fef7e5adc2d0f9338',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 33,
                                pinid: '682d434e1d70ccc758edb3dddc34e38b',
                                readonly: false,
                                value: 'false',
                            },
                        ],
                        ranking: 0,
                        roomid: 1,
                        type: 21,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 39,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 40,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 4,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 41,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 42,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 43,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 44,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 45,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Scenes: [
                                    {
                                        Name: 'Off',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 0,
                                                Description: 'Mood lights',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#000000000000000000',
                                            },
                                            {
                                                Brightness: 0,
                                                Description: 'TV LEDs',
                                                LightType: 6,
                                                Name: 'AQ2',
                                                Value: '#000000000000000000',
                                            },
                                            {
                                                Description: 'Reading lamp',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'Top light',
                                                LightType: 0,
                                                Name: 'AQ4',
                                                Value: false,
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'On',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'Mood lights',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#640000000000FFFFFF',
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'TV LEDs',
                                                LightType: 6,
                                                Name: 'AQ2',
                                                Value: '#640000000000FFFFFF',
                                            },
                                            {
                                                Description: 'Reading lamp',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'Top light',
                                                LightType: 0,
                                                Name: 'AQ4',
                                                Value: true,
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Watch TV',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 50,
                                                Description: 'Mood lights',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#320000000000C512FF',
                                            },
                                            {
                                                Brightness: 30,
                                                Description: 'TV LEDs',
                                                LightType: 6,
                                                Name: 'AQ2',
                                                Value: '#1E0000000000FF7F1A',
                                            },
                                            {
                                                Description: 'Reading lamp',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'Top light',
                                                LightType: 0,
                                                Name: 'AQ4',
                                                Value: false,
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Party',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'Mood lights',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#640000000000FF110A',
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'TV LEDs',
                                                LightType: 6,
                                                Name: 'AQ2',
                                                Value: '#640000000000071DFF',
                                            },
                                            {
                                                Description: 'Reading lamp',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'Top light',
                                                LightType: 0,
                                                Name: 'AQ4',
                                                Value: true,
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 46,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'light.sceneConfig',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 9,
                                writeprotect: false,
                            },
                            {
                                customname: 'Mood lights',
                                id: 47,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value0',
                                objectid: 4,
                                range: ['10', '100'],
                                remote: true,
                                type: 3,
                                value: '#000000000000000000',
                                writeprotect: false,
                            },
                            {
                                customname: 'TV LEDs',
                                id: 48,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value1',
                                objectid: 4,
                                range: ['10', '100'],
                                remote: true,
                                type: 29,
                                value: '#000000000000000000',
                                writeprotect: false,
                            },
                            {
                                customname: 'Reading lamp',
                                id: 49,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value2',
                                objectid: 4,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'Top light',
                                id: 50,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value3',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 4,
                        name: 'Light controller',
                        properties: [
                            {
                                id: 34,
                                pinid: 'fc0f688783acc959f0fc678eed9a35c5',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 35,
                                pinid: '9ab0c26420545b5e89b4db810aa66bca',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 36,
                                pinid: 'be0dd8d2546f42b7f97d941e238b8e23',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 37,
                                pinid: '312a6c543cc5aa30a0374ab3f2cc55df',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 38,
                                pinid: '4305706ea659aa3d5d3d12ffedeb5855',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 2,
                        type: 17,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 51,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 5,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 52,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 5,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 53,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 54,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 5,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 55,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 5,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 56,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 57,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 58,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 59,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 60,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 61,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 5,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 62,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 5,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 63,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 5,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 5,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 39,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 40,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 41,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 42,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 43,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 44,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 45,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 46,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 47,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 48,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 49,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 50,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 51,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 52,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 53,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: 2,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 4,
                        datapoints: [
                            {
                                customname: '',
                                id: 64,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 6,
                                range: ['1', '3', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 65,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeHeating',
                                objectid: 6,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 22,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 66,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsRoomTemperature',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: 2,
                                value: 21.5,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 68,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 6,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 69,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeCooling',
                                objectid: 6,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 70,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsHoliday',
                                objectid: 6,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 71,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsEconomyMode',
                                objectid: 6,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 72,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutdoorTemperature',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -32768,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 73,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyHeatingTemperature',
                                objectid: 6,
                                range: ['0', '40'],
                                remote: true,
                                type: -2,
                                value: 16,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 74,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyCoolingTemperature',
                                objectid: 6,
                                range: ['15', '40'],
                                remote: true,
                                type: -2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 75,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualSetpoint',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 76,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRequiredOpMode',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 77,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDewpointTemperature',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 78,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualValvePositionCont',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 79,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualOpMode',
                                objectid: 6,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: false,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Heating: 22,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Heating: 16,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 80,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tph.timeProgram',
                                objectid: 6,
                                range: [],
                                remote: true,
                                type: 8,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 6,
                        name: 'Room controller',
                        properties: [
                            {
                                id: 54,
                                pinid: 'a70e5f4a2254aaf3a903b53ff91cc84b',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 55,
                                pinid: '6d6e20e2d6a7e82386ef15930d41824e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 56,
                                pinid: '405502632d68e338630ad9729e626c57',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 57,
                                pinid: '39fa7456dcd04f3d3731db82167564ff',
                                readonly: false,
                                value: '17',
                            },
                            {
                                id: 58,
                                pinid: '7e18b8e617aeae482e9f731784a2811a',
                                readonly: false,
                                value: '22',
                            },
                            {
                                id: 59,
                                pinid: 'a24eef4850f0f8094196540b46e01d76',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 60,
                                pinid: 'a61f83aa5674dfddcfcad8c6d242f657',
                                readonly: false,
                                value: '24',
                            },
                            {
                                id: 61,
                                pinid: '1dc82f53ee242ebd9018c9fc26ccfb71',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 62,
                                pinid: '66e5d781fdd48c9fef7e5adc2d0f9338',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 63,
                                pinid: '682d434e1d70ccc758edb3dddc34e38b',
                                readonly: false,
                                value: 'false',
                            },
                        ],
                        ranking: 0,
                        roomid: 2,
                        type: 21,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 81,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 7,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 82,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 7,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 83,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 7,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 84,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 7,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '06:00',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '22:00',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 85,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 7,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 7,
                        name: 'Christmas tree',
                        properties: [
                            {
                                id: 64,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 2,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 86,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 8,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 87,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 8,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 88,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 8,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 89,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 8,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 90,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 8,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 91,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 8,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 92,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 8,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 93,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 8,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 8,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 65,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 66,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 67,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 68,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 69,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 70,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 71,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 72,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 3,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 94,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 9,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 95,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 9,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 96,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 9,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 97,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 9,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 98,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 9,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 99,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 9,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 100,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 9,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 101,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 9,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 102,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 9,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 103,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 9,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 104,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 9,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 105,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 9,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 106,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 9,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 9,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 73,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 74,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 75,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 76,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 77,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 78,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 79,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 80,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 81,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 82,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 83,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 84,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 85,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 86,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 87,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: 3,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 107,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarmClock',
                                objectid: 10,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 108,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartSnoozeTimer',
                                objectid: 10,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 109,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsAlarm',
                                objectid: 10,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 110,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsSnooze',
                                objectid: 10,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 111,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSnoozeIntervalDuration',
                                objectid: 10,
                                range: ['0', '30'],
                                remote: true,
                                type: 7,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    Alarms: [
                                        {
                                            Active: true,
                                            Description: 'Work',
                                            Friday: true,
                                            Monday: true,
                                            Saturday: false,
                                            Sunday: false,
                                            Thursday: true,
                                            Time: '08:00',
                                            Tuesday: true,
                                            Wednesday: true,
                                        },
                                        {
                                            Active: true,
                                            Description: 'Holidays',
                                            Friday: false,
                                            Monday: false,
                                            Saturday: true,
                                            Sunday: true,
                                            Thursday: false,
                                            Time: '10:30',
                                            Tuesday: false,
                                            Wednesday: false,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 112,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'alarm.alarmProgram',
                                objectid: 10,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 10,
                        name: 'Alarm clock',
                        properties: [
                            {
                                id: 88,
                                pinid: '82cbb2b6dcbc77e13dd2fc67c8bffd6e',
                                readonly: false,
                                value: '60',
                            },
                        ],
                        ranking: 0,
                        roomid: 3,
                        type: 1,
                        visualisationpw: false,
                    },
                    {
                        category: 3,
                        datapoints: [
                            {
                                customname: '',
                                id: 130,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerOpen',
                                objectid: 11,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 131,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerClose',
                                objectid: 11,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 132,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPosition',
                                objectid: 11,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 133,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsMoving',
                                objectid: 11,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 134,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 11,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 11,
                        name: 'Gate',
                        properties: [
                            {
                                id: 99,
                                pinid: 'b780ddd106ab95a5e42cf60fde71eb6f',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 100,
                                pinid: '7ba0af0f96b341184d22fc868717937e',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 101,
                                pinid: '4d4f4632abfa5edaad4c46dfa9cc89b9',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 102,
                                pinid: '5bc979396db5154a1987975c040362f4',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 103,
                                pinid: 'bd4ee83aa7d412ab669b3b39c1c6af83',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 104,
                                pinid: 'c4187fd5723c6a556daff8c9eb589461',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 105,
                                pinid: 'e5e1aabb049e6accdf4a5b975488e411',
                                readonly: false,
                                value: '3',
                            },
                            {
                                id: 106,
                                pinid: '966c9d30ff4e91775c58b78b2b07d5a3',
                                readonly: false,
                                value: 'true',
                            },
                            {
                                id: 107,
                                pinid: '25a7abf3b503c2f920c34dcbbbaad8ca',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 4,
                        type: 16,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 135,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 12,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 136,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 12,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 137,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 12,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 12,
                        name: 'Light switch',
                        properties: [
                            {
                                id: 108,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 4,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 138,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 13,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 139,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 140,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 13,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 141,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 13,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '06:00',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '22:00',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 142,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 13,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 13,
                        name: 'Charger',
                        properties: [
                            {
                                id: 109,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 4,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 7,
                        datapoints: [
                            {
                                customname: '',
                                id: 143,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLight',
                                objectid: 14,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 144,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFan',
                                objectid: 14,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 145,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTrigger',
                                objectid: 14,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 146,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 14,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 147,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 14,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 148,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 14,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 14,
                        name: 'WC ventilation controller',
                        properties: [
                            {
                                id: 110,
                                pinid: 'dc842059fd8415315d13079f34e02200',
                                readonly: false,
                                value: '30',
                            },
                            {
                                id: 111,
                                pinid: '3daabf87af1090a3754d68fbb93d1cc7',
                                readonly: false,
                                value: '180',
                            },
                            {
                                id: 112,
                                pinid: 'edcac574688c27b234b39bfe74d9405d',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 113,
                                pinid: 'cc1c1a5e2d1c16e0513dbe8c3b9a7e32',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 5,
                        type: 27,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 149,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIncreaseButton',
                                objectid: 15,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 150,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 15,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 30,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 151,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDecreaseButton',
                                objectid: 15,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 15,
                        name: 'Bath temperature',
                        properties: [
                            {
                                id: 114,
                                pinid: 'dc69db02b3dca8e0ce1b1d9c082c517e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 115,
                                pinid: 'd2e0cdc9069752464c57f94212a0b579',
                                readonly: false,
                                value: '40',
                            },
                            {
                                id: 116,
                                pinid: '94b2bdae3b8bd951dfc8e7fc2fbeeadc',
                                readonly: false,
                                value: '30',
                            },
                            {
                                id: 117,
                                pinid: 'd32197522c4b2a6328d1168c1b061693',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 118,
                                pinid: '27fb4d07177ef8167d9d8a2bcc557d5f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 119,
                                pinid: 'd037bfb9af6ecbf735e05cacf43427b7',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 5,
                        type: 23,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 152,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 153,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 16,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 154,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 16,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 155,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 156,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 157,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 158,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 159,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 16,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 120,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 121,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 122,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 123,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 124,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 125,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 126,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 127,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 6,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 160,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CalendarSwitchPoints: [
                                        {
                                            Date: '2022-12-01T09:00:00Z',
                                            Value: true,
                                        },
                                        {
                                            Date: '2022-12-30T23:00:00Z',
                                            Value: false,
                                        },
                                        {
                                            Date: '2023-06-15T18:15:00Z',
                                            Value: false,
                                        },
                                        {
                                            Date: '2023-02-01T11:00:00Z',
                                            Value: true,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 161,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'calendar.dateProgram',
                                objectid: 17,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 17,
                        name: 'Calendar',
                        properties: [
                            {
                                id: 128,
                                pinid: 'b52f3381c31ccdb7a9672771e2251da0',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 6,
                        type: 4,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 162,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 163,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 18,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 164,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 18,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 165,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 166,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 167,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 168,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 169,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 18,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 129,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 130,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 131,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 132,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 133,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 134,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 135,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 136,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 7,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 7,
                        datapoints: [
                            {
                                customname: '',
                                id: 170,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLight',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 171,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFan',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 172,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTrigger',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 173,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 174,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 175,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 19,
                        name: 'WC ventilation controller',
                        properties: [
                            {
                                id: 137,
                                pinid: 'dc842059fd8415315d13079f34e02200',
                                readonly: false,
                                value: '30',
                            },
                            {
                                id: 138,
                                pinid: '3daabf87af1090a3754d68fbb93d1cc7',
                                readonly: false,
                                value: '180',
                            },
                            {
                                id: 139,
                                pinid: 'edcac574688c27b234b39bfe74d9405d',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 140,
                                pinid: 'cc1c1a5e2d1c16e0513dbe8c3b9a7e32',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 8,
                        type: 27,
                        visualisationpw: false,
                    },
                    {
                        category: 3,
                        datapoints: [
                            {
                                customname: '',
                                id: 176,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDoorBell',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 20,
                        name: 'Door controller',
                        properties: [
                            {
                                id: 141,
                                pinid: 'eb8f2f52ad4e0607d8c9cbd87fa417e8',
                                readonly: false,
                                value: '30',
                            },
                        ],
                        ranking: 0,
                        roomid: 9,
                        type: 13,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 177,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartTimer',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 178,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 179,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 180,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCountdown',
                                objectid: 21,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 60,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 181,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 182,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 183,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 21,
                        name: 'Staircase light switch',
                        properties: [
                            {
                                id: 142,
                                pinid: '8cc630e7a9a6ba6a9b7813c7679ad377',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 143,
                                pinid: 'c88c9061436916a3ef3e6393fb0cd661',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 144,
                                pinid: '0cb41e1f95ddb7ac731db1d7e2afa555',
                                readonly: false,
                                value: '0.5',
                            },
                            {
                                id: 145,
                                pinid: '8d30c2134835fc4edef49119345fa139',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 9,
                        type: 24,
                        visualisationpw: false,
                    },
                    {
                        category: 5,
                        datapoints: [
                            {
                                customname: '',
                                id: 184,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualReset',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 185,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualHourEnergy',
                                objectid: 22,
                                range: ['', ''],
                                remote: true,
                                type: 11,
                                value: 0.01,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 186,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDayEnergy',
                                objectid: 22,
                                range: ['', ''],
                                remote: true,
                                type: 12,
                                value: 0.05,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 187,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualMonthEnergy',
                                objectid: 22,
                                range: ['', ''],
                                remote: true,
                                type: 13,
                                value: 0.13301,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 188,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualYearEnergy',
                                objectid: 22,
                                range: ['', ''],
                                remote: true,
                                type: 14,
                                value: 0.33301,
                                writeprotect: true,
                            },
                            {
                                customname: 'Energy meter',
                                deviceid: 5,
                                id: 189,
                                ioid: 12,
                                isconfiguration: false,
                                name: 'EnergyStatisticDay',
                                objectid: 22,
                                range: [],
                                remote: true,
                                type: 12,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Energy meter',
                                deviceid: 5,
                                id: 190,
                                ioid: 12,
                                isconfiguration: false,
                                name: 'EnergyStatisticHour',
                                objectid: 22,
                                range: [],
                                remote: true,
                                type: 11,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Energy meter',
                                deviceid: 5,
                                id: 191,
                                ioid: 12,
                                isconfiguration: false,
                                name: 'EnergyStatisticMonth',
                                objectid: 22,
                                range: [],
                                remote: true,
                                type: 13,
                                value: 0.13301000000000004,
                                writeprotect: true,
                            },
                            {
                                customname: 'Energy meter',
                                deviceid: 5,
                                id: 192,
                                ioid: 12,
                                isconfiguration: false,
                                name: 'EnergyStatisticYear',
                                objectid: 22,
                                range: [],
                                remote: true,
                                type: 14,
                                value: 0.13301000000000004,
                                writeprotect: true,
                            },
                            {
                                EnergyConfig: {
                                    SingleEnergy: [
                                        {
                                            day: {
                                                id: 189,
                                                type: 12,
                                                value: '0',
                                            },
                                            hour: {
                                                id: 190,
                                                type: 11,
                                                value: '0',
                                            },
                                            ioId: 12,
                                            month: {
                                                id: 191,
                                                type: 13,
                                                value: '0.13301000000000004',
                                            },
                                            year: {
                                                id: 192,
                                                type: 14,
                                                value: '0.13301000000000004',
                                            },
                                        },
                                    ],
                                    TotalEnergy: {
                                        Inputs: [12],
                                        day: {
                                            id: 186,
                                            type: 12,
                                            value: '0',
                                        },
                                        hour: {
                                            id: 185,
                                            type: 11,
                                            value: '0',
                                        },
                                        month: {
                                            id: 187,
                                            type: 13,
                                            value: '0.13301000000000004',
                                        },
                                        year: {
                                            id: 188,
                                            type: 14,
                                            value: '0.13301000000000004',
                                        },
                                    },
                                    guid: 'f5aa1d7e-2ae2-4760-b7cb-39e33f4670b4',
                                },
                                customname: '',
                                id: 193,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 22,
                                range: [],
                                remote: true,
                                type: 25,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 22,
                        name: 'Total energy monitor',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 28,
                        visualisationpw: false,
                    },
                    {
                        category: 12,
                        datapoints: [
                            {
                                customname: '',
                                id: 194,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualLowBatteryLimit',
                                objectid: 23,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 15,
                                writeprotect: false,
                            },
                            {
                                customname: 'Radio Valve 1',
                                deviceid: 2,
                                id: 195,
                                ioid: 1,
                                isconfiguration: false,
                                name: 'Radio Valve 1',
                                objectid: 23,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 55,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Window /Door Contact 1',
                                deviceid: 3,
                                id: 196,
                                ioid: 4,
                                isconfiguration: false,
                                name: 'Radio Window /Door Contact 1',
                                objectid: 23,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Leakage Sensor 1',
                                deviceid: 4,
                                id: 197,
                                ioid: 7,
                                isconfiguration: false,
                                name: 'Radio Leakage Sensor 1',
                                objectid: 23,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 95,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 23,
                        name: 'Battery monitoring',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 5,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 198,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 199,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 200,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadowing',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 201,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralBlindsStop',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 24,
                        name: 'Central blinds',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 6,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 202,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAllOff',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 203,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAllOn',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 25,
                        name: 'Central light controller',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 9,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 204,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualComingHome',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 205,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSleep',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 206,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLeavingHome',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                CominghomeConfig: {
                                    Buttons: [
                                        {
                                            ComingHome: '100',
                                            ID: 'c',
                                            LeavingHome: '0',
                                            ObjectID: 1,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'amB',
                                            LeavingHome: 'CompletelyDown',
                                            ObjectID: 2,
                                            Sleep: 'CompletelyDown',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: '1',
                                            ID: 'g8K',
                                            LeavingHome: '0',
                                            ObjectID: 4,
                                            Sleep: '0',
                                            Type: 17,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'hl5',
                                            LeavingHome: 'CompletelyDown',
                                            ObjectID: 5,
                                            Sleep: 'CompletelyDown',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: '100',
                                            ID: 'hva',
                                            LeavingHome: '0',
                                            ObjectID: 8,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'hvt',
                                            LeavingHome: 'CompletelyDown',
                                            ObjectID: 9,
                                            Sleep: 'CompletelyDown',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: 'Open',
                                            ID: 'hAj',
                                            LeavingHome: 'Close',
                                            ObjectID: 11,
                                            Sleep: 'Ignore',
                                            Type: 16,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'iSK',
                                            LeavingHome: 'Off',
                                            ObjectID: 12,
                                            Sleep: 'Off',
                                            Type: 25,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'j1a',
                                            LeavingHome: 'Off',
                                            ObjectID: 14,
                                            Sleep: 'Off',
                                            Type: 27,
                                        },
                                        {
                                            ComingHome: '100',
                                            ID: 'jJS',
                                            LeavingHome: '0',
                                            ObjectID: 16,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: '100',
                                            ID: 'jRq',
                                            LeavingHome: '0',
                                            ObjectID: 18,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'jRL',
                                            LeavingHome: 'Off',
                                            ObjectID: 19,
                                            Sleep: 'Off',
                                            Type: 27,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'jSX',
                                            LeavingHome: 'Off',
                                            ObjectID: 21,
                                            Sleep: 'Off',
                                            Type: 24,
                                        },
                                        {
                                            ComingHome: 'Disabled',
                                            ID: 'kF0',
                                            LeavingHome: 'ActivateDelayed',
                                            ObjectID: 28,
                                            Sleep: 'ActivateWithoutPresence',
                                            Type: 3,
                                        },
                                        {
                                            ComingHome: 'Disabled',
                                            ID: 'kOQ',
                                            LeavingHome: 'Activate',
                                            ObjectID: 31,
                                            Sleep: 'Ignore',
                                            Type: 18,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'kRX',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 36,
                                            Sleep: 'Ignore',
                                            Type: 25,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'kS6',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 37,
                                            Sleep: 'Ignore',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'kSA',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 39,
                                            Sleep: 'Ignore',
                                            Type: 25,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'kSH',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 40,
                                            Sleep: 'Ignore',
                                            Type: 25,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 207,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 26,
                                range: [],
                                remote: true,
                                type: 23,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 26,
                        name: 'Central push button functions',
                        properties: [
                            {
                                id: 146,
                                pinid: 'c291bcd3f48cff2b46f523e3e2fd2f90',
                                readonly: false,
                                value: '90',
                            },
                        ],
                        ranking: 0,
                        roomid: 10,
                        type: 8,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 208,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualCentralSchedulerOn',
                                objectid: 27,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                TimeProgramList: [
                                    {
                                        Name: 'TimeProgram1',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'jRL',
                                                        IsSelected: false,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: false,
                                                        ObjectID: 1,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'amB',
                                                        IsSelected: false,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'g8K',
                                                        IsSelected: false,
                                                        ObjectID: 4,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'hl5',
                                                        IsSelected: true,
                                                        ObjectID: 5,
                                                        Type: 2,
                                                        Value: 'CompletelyUp',
                                                    },
                                                    {
                                                        ID: 'hml',
                                                        IsSelected: false,
                                                        ObjectID: 7,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'hva',
                                                        IsSelected: false,
                                                        ObjectID: 8,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'hvt',
                                                        IsSelected: false,
                                                        ObjectID: 9,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'iSK',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 25,
                                                        Value: 'Off',
                                                    },
                                                    {
                                                        ID: 'j11',
                                                        IsSelected: true,
                                                        ObjectID: 13,
                                                        Type: 22,
                                                        Value: 'Off',
                                                    },
                                                    {
                                                        ID: 'j1a',
                                                        IsSelected: false,
                                                        ObjectID: 14,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jJS',
                                                        IsSelected: false,
                                                        ObjectID: 16,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jRq',
                                                        IsSelected: false,
                                                        ObjectID: 18,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jSX',
                                                        IsSelected: false,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kRX',
                                                        IsSelected: false,
                                                        ObjectID: 36,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kS6',
                                                        IsSelected: false,
                                                        ObjectID: 37,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kSA',
                                                        IsSelected: false,
                                                        ObjectID: 39,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kSH',
                                                        IsSelected: false,
                                                        ObjectID: 40,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '22:00',
                                            },
                                            {
                                                FB: [
                                                    {
                                                        ID: 'jRL',
                                                        IsSelected: false,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: false,
                                                        ObjectID: 1,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'amB',
                                                        IsSelected: false,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'g8K',
                                                        IsSelected: false,
                                                        ObjectID: 4,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'hl5',
                                                        IsSelected: true,
                                                        ObjectID: 5,
                                                        Type: 2,
                                                        Value: 'Shadowing',
                                                    },
                                                    {
                                                        ID: 'hml',
                                                        IsSelected: false,
                                                        ObjectID: 7,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'hva',
                                                        IsSelected: false,
                                                        ObjectID: 8,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'hvt',
                                                        IsSelected: false,
                                                        ObjectID: 9,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'iSK',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 25,
                                                        Value: 'On',
                                                    },
                                                    {
                                                        ID: 'j11',
                                                        IsSelected: true,
                                                        ObjectID: 13,
                                                        Type: 22,
                                                        Value: 'On',
                                                    },
                                                    {
                                                        ID: 'j1a',
                                                        IsSelected: false,
                                                        ObjectID: 14,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jJS',
                                                        IsSelected: false,
                                                        ObjectID: 16,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jRq',
                                                        IsSelected: false,
                                                        ObjectID: 18,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jSX',
                                                        IsSelected: false,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kRX',
                                                        IsSelected: false,
                                                        ObjectID: 36,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kS6',
                                                        IsSelected: false,
                                                        ObjectID: 37,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kSA',
                                                        IsSelected: false,
                                                        ObjectID: 39,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kSH',
                                                        IsSelected: false,
                                                        ObjectID: 40,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '11:00',
                                            },
                                        ],
                                        days: [0, 6],
                                    },
                                ],
                                customname: '',
                                id: 209,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 27,
                                range: [],
                                remote: true,
                                type: 27,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 27,
                        name: 'Central timer switch',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 29,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 210,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmAlarmSystem',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 211,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmAlarmSystemDelayed',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 212,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmWithoutPresenceDetectors',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 213,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDisarmAlarmSystem',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 214,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarmSystemIsArmed',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: true,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 215,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmedWithoutPresenceDetectors',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 216,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarm',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 217,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAcivationCountdown',
                                objectid: 28,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 60,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 218,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsAlarm',
                                objectid: 28,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 28,
                        name: 'Alarm system',
                        properties: [
                            {
                                id: 147,
                                pinid: '5e2481a0a4b3dcd154e1a3400a519457',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 148,
                                pinid: '7ee2bc6a7b7dd741baee80b5537ef898',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 149,
                                pinid: '6ddccfc197b1766a550c7537ab175ca8',
                                readonly: false,
                                value: '3',
                            },
                        ],
                        ranking: 0,
                        roomid: 10,
                        type: 3,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 219,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarm',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 220,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFireAlarm',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 221,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSmokeAlarm',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 222,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualWaterAlarm',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 29,
                        name: 'Fire and water alarm system',
                        properties: [
                            {
                                id: 150,
                                pinid: '58179165fe8a7a0d486fbd362620c890',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 151,
                                pinid: '0e70a48fd2fdd929a1a92dbd87d283e4',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 10,
                        type: 15,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                Cameras: [
                                    {
                                        CameraType: 1,
                                        IP: 'aaa',
                                        ImageCommand: 'ISAPI/Streaming/channels/101/picture',
                                        ImagePort: 80,
                                        ImageProtocol: 'HTTP',
                                        Name: 'Living room',
                                        Password:
                                            'M0R6STk6x+1vHs6LLUHbReZUEPDe1OayRhb2I2SpvSSgldoxJ7vbSLn6nsF+CR97HkpTOGZ2Qewa/bLlOyTv3DcFv5zg7TMWQoRug0Tz/KS5kJL30MviYL1uT8v5JTsNY3fj16PCJTkMviBKhjJe1buje5dHjd0XtNjNx0Vl0ZVz8zAx7lWIWNFDKw05XHyVvWuk8zsB5l9x06mx2vuHtNlCxfXbFnAr584o8RJamV3HgDJ23EibNqOHKcTEFvmOF3Mpa8u8uwAMSAxdfrsMQNSaQJKDyOu1U8yqUrJssniI7l0UQeXoNsPW/mOsGe2HGcqwrxXQRF5MrBUqpVdB+NwdGNHfFoSLcJC3Ye6Jqgtg8mX7tnC/95n+i7efySw4thQLtU0EriBPFodLeuuyGvAy0y5a62WElGb59yhTV0be8ecdGdrFv4MyI5lsapwhwP67DLFYzRTSONfOnNLP3iRhipdbWNaPHQkT+9L5homIM4hJMgtYydb3ZMsepmiSkGaE3hx2zxyk8G+hs5Gvwrsbc50MDUR73YBC1r+rhLY3+Bg/qfkdvrT/4E2frRkKGzKtsbcGKulCd+YhgVckdEOP229ecCl9AmQ/SERQ4SSapn7wmnk6CLrlez+dxFIrf8Pf1QIrFCoi40n9PvOpIH10Z9gWQh7DHWbOe6UU8So=',
                                        StreamCommand: '',
                                        StreamPort: 554,
                                        StreamProtocol: 'RTSP',
                                        UserName: 'bbb',
                                        http: '',
                                        image: 'aHR0cDovL2JiYjpjY0BhYWE6ODAvSVNBUEkvU3RyZWFtaW5nL2NoYW5uZWxzLzEwMS9waWN0dXJl',
                                        rtsp: 'cnRzcDovL2JiYjpjY0BhYWE6NTU0Lw==',
                                    },
                                    {
                                        CameraType: 1,
                                        IP: 'aaa',
                                        ImageCommand: 'ISAPI/Streaming/channels/101/picture',
                                        ImagePort: 80,
                                        ImageProtocol: 'HTTP',
                                        Name: 'Entrance',
                                        Password:
                                            'ThDT6NvtR+/yOkfB3NLrCZ4U9oQYerlK5brszwybtQtWIesMfnR3Ex7VbyNcnFDQJLPGrZUVEiqQFG+tJGy+ECUBLjd+KcfIiNg3S+jAL17/H/AGo2HkGGx/vozf9PoP08T4scVRhlq0wrOE3QGpVgpyEjolnaor9hcugiibJTSOegjm/yy8I3VYrcjCi4Oa6ZzZfVC+P1YQuG4RxF+V9pNoCUjzU0fi2eACkFDILGcIUd06ay4veyYVlPVmMBNJBhoK72jSWMYyTVfZVGpnj+aaxxsBPs+dKu0Fgh8dfssGMsfowgd5BAh6hsh6TqnLAiImx/O77UqjPh4fw1GPfUwXryOQLuWJ8UoPXwWcYSp9f42G48g9sDFz1tBp8dJX4mS48S56mQjbw+D6G9vSzgBOjUTOo0k/W7i1b5I8rFA12lHvwyMVH1e59NBqw/rCejn/VXBW1739PmYd9cbnfpbJkHhrSgJKqkI2Z3A3/zO3ZCBU8RVSjWrUZvMQ3f0pYSIlMb2FQlRAYBIAD/yUtICXuCRtRb+0N2ZP9m0HrJb3P/LnKn9RY87zoh54/tFOl/jeGx0QeGe9unWWc7c5vbbOtcLWKv1y+8cvDItKUggo9YsB/a7zIsKJqaHoa3xSpnO3N4XFY8BP4yq0imwEvP8J31DyfMA9uR4dgAU1ymM=',
                                        StreamCommand: '',
                                        StreamPort: 554,
                                        StreamProtocol: 'RTSP',
                                        UserName: 'bbb',
                                        http: '',
                                        image: 'aHR0cDovL2JiYjpjY0BhYWE6ODAvSVNBUEkvU3RyZWFtaW5nL2NoYW5uZWxzLzEwMS9waWN0dXJl',
                                        rtsp: 'cnRzcDovL2JiYjpjY0BhYWE6NTU0Lw==',
                                    },
                                    {
                                        CameraType: 1,
                                        IP: 'aaa',
                                        ImageCommand: 'ISAPI/Streaming/channels/101/picture',
                                        ImagePort: 80,
                                        ImageProtocol: 'HTTP',
                                        Name: 'Garage',
                                        Password:
                                            'Pzdi7PHPERCHeOxifkkw6sgUuH2XGYRt34zwuKcADRYGChMD4CO01l0WdXMnkJKWczJJVohVNoIFZDbnamAOMkLi1pzatGJ+PiN4Jr2EtGNMdogNYIKvldgm9kchghWPpDdUvnyEu8DyMngPkOtNfQbj4tuj7v9TeKGjRQFKKj7mDyyEetm9gMN8UkB7OfKmWeEg6RLX0JYDon6Lgi3i3Ae91Nb1HzLGxBTv7aw6HDxGE/vuHq9jfbBHRQdww6AFuuqhKwU+mKcExtvSI13Mt/RT0Zr0dJHUakKE50M6s0y97n+3I2OY2PP8KKR+GACZCaCOmeNm3pHPBbcFjGoZDJ57gO8ZYqFt+MV1dXkapOH+5/fk4trQW7HAYHpI8PKpApLn/xyTANm3KpZ5CqDzpPAvLdaMUC1BpPFRRTIQsxh1Gs1gEqRC2TxzN+cyj7gHUV3yH7qOEAfv7lg2n9C+Z9I8gkJOBCW77EGHJ7WAem06Y+Sod0rutZs4znuVfNKMhMBrgp7uSU2Ossf6J1hWOJxtKRgYJrOayGfzlUm0Lf9h3VHtsgN9f1kmQhsQv6SYLEg0tleK1mNlC0CRYhw+izrFvDrrWJiOPjioFkEkroeR9hf/HNVS8+JqV7vSuJEaD+8Qz4fd3kh1/XrOhaHx8lIAU5pTJSmcDlKke435KEY=',
                                        StreamCommand: '',
                                        StreamPort: 554,
                                        StreamProtocol: 'RTSP',
                                        UserName: 'bbb',
                                        http: '',
                                        image: 'aHR0cDovL2JiYjpjY0BhYWE6ODAvSVNBUEkvU3RyZWFtaW5nL2NoYW5uZWxzLzEwMS9waWN0dXJl',
                                        rtsp: 'cnRzcDovL2JiYjpjY0BhYWE6NTU0Lw==',
                                    },
                                ],
                                customname: '',
                                id: 223,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'camera.cameraConfig',
                                objectid: 30,
                                range: [],
                                remote: true,
                                type: 28,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 30,
                        name: 'Camera',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 30,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 224,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 225,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetActive',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 226,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualReset',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Presences: [
                                    {
                                        ID: 'c',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 1,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'hml',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 7,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'jRL',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 19,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                ],
                                customname: '',
                                id: 227,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 31,
                                range: [],
                                remote: true,
                                type: 26,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 31,
                        name: 'Presence simulation',
                        properties: [
                            {
                                id: 152,
                                pinid: '73f240c55ab991718976c8b02c9aa637',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 10,
                        type: 18,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 228,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfOpen',
                                objectid: 32,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 229,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfClosed',
                                objectid: 32,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 1,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                deviceid: 3,
                                id: 230,
                                ioid: 5,
                                isconfiguration: false,
                                name: 'Door/Window binary sensor',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 32,
                        name: 'Window and door monitoring',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 11,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                Floors: [
                                    {
                                        FloorImage: {
                                            Height: 863,
                                            Name: 'floorplan1.jpeg',
                                            Width: 1399,
                                        },
                                        ID: 3,
                                        Name: 'Flat plan',
                                        Objects: [
                                            {
                                                Height: 32,
                                                ObjID: 18,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 628,
                                                Y: 220,
                                            },
                                            {
                                                DpName: 'VirtualSleep',
                                                Height: 32,
                                                ObjID: 26,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 489,
                                                Y: 723,
                                            },
                                            {
                                                DpName: 'VirtualLeavingHome',
                                                Height: 32,
                                                ObjID: 26,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 716,
                                                Y: 738,
                                            },
                                            {
                                                DpName: '1',
                                                Height: 32,
                                                ObjID: 30,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 766,
                                                Y: 374,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 14,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 917,
                                                Y: 407,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 15,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 885,
                                                Y: 305,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 19,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 608,
                                                Y: 660,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 21,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 768,
                                                Y: 658,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 1,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 772,
                                                Y: 96,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 4,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 225,
                                                Y: 423,
                                            },
                                            {
                                                DpName: 'light.value0',
                                                Height: 12,
                                                ObjID: 4,
                                                WebDesc: false,
                                                Width: 120,
                                                X: 216,
                                                Y: 101,
                                            },
                                            {
                                                DpName: 'light.value1',
                                                Height: 12,
                                                ObjID: 4,
                                                WebDesc: false,
                                                Width: 120,
                                                X: 310,
                                                Y: 478,
                                            },
                                            {
                                                DpName: 'light.value2',
                                                Height: 12,
                                                ObjID: 4,
                                                WebDesc: false,
                                                Width: 120,
                                                X: 419,
                                                Y: 142,
                                            },
                                            {
                                                DpName: 'light.value3',
                                                Height: 32,
                                                ObjID: 4,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 403,
                                                Y: 269,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 5,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 182,
                                                Y: 297,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 6,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 587,
                                                Y: 471,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 8,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 368,
                                                Y: 586,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 9,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 177,
                                                Y: 651,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 35,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 237,
                                                Y: 712,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 16,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1165,
                                                Y: 576,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 36,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 948,
                                                Y: 683,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 37,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1066,
                                                Y: 393,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 38,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1241,
                                                Y: 392,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 39,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 77,
                                                Y: 299,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 40,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 112,
                                                Y: 580,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 3,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 532,
                                                Y: 460,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 17,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1253,
                                                Y: 546,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 705,
                                            Name: 'd86dcde6922c5666715580cba6c449d8.PNG',
                                            Width: 927,
                                        },
                                        ID: 4,
                                        Name: 'Balcony',
                                        Objects: [
                                            {
                                                Height: 32,
                                                ObjID: 39,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 322,
                                                Y: 148,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 13,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 796,
                                                Y: 126,
                                            },
                                            {
                                                DpName: 'VirtualComingHome',
                                                Height: 32,
                                                ObjID: 26,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 175,
                                                Y: 277,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 32,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 609,
                                                Y: 200,
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 231,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'floorPlan.floorConfig',
                                objectid: 33,
                                range: [],
                                remote: true,
                                type: 32,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 33,
                        name: 'Floor plan',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 32,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                Monitor: [
                                    {
                                        Configuration: {},
                                        IOid: 1,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 2,
                                        Log: true,
                                        Name: 'Living_room Room controller Actual setpoint',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 3,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 4,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 5,
                                        Log: true,
                                        Name: 'Door/Window binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 6,
                                        Log: true,
                                        Name: 'Lock access control notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 7,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 8,
                                        Log: true,
                                        Name: 'Water binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 9,
                                        Log: true,
                                        Name: 'Water notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 10,
                                        Log: true,
                                        Name: 'Living_room Timer switch Output',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 11,
                                        Log: true,
                                        Name: 'Power meter',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 12,
                                        Log: true,
                                        Name: 'Energy meter',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 13,
                                        Log: true,
                                        Name: 'Bedroom Room controller Room temperature',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 14,
                                        Log: true,
                                        Name: 'Bedroom Room controller Relative humidity room air',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 15,
                                        Log: true,
                                        Name: 'Living_room Light controller Output 1',
                                        Unit: '',
                                        View: true,
                                    },
                                ],
                                customname: '',
                                id: 232,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'monitor.monitorConfig',
                                objectid: 34,
                                range: [],
                                remote: true,
                                type: 31,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 34,
                        name: 'Monitor',
                        properties: [],
                        ranking: 0,
                        roomid: 10,
                        type: 31,
                        visualisationpw: false,
                    },
                    {
                        category: 4,
                        datapoints: [
                            {
                                customname: '',
                                id: 113,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 35,
                                range: ['1', '3', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 114,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeHeating',
                                objectid: 35,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 22,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 115,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsRoomTemperature',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 116,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRelativeHumidity',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: 19,
                                value: 28.3,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 117,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 35,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 118,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeCooling',
                                objectid: 35,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 119,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsHoliday',
                                objectid: 35,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 120,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsEconomyMode',
                                objectid: 35,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 121,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutdoorTemperature',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -32768,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 122,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyHeatingTemperature',
                                objectid: 35,
                                range: ['0', '40'],
                                remote: true,
                                type: -2,
                                value: 16,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 123,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyCoolingTemperature',
                                objectid: 35,
                                range: ['15', '40'],
                                remote: true,
                                type: -2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 124,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualSetpoint',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 125,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRequiredOpMode',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 126,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDewpointTemperature',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 127,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualValvePositionCont',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 128,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualOpMode',
                                objectid: 35,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: false,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Heating: 22,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Heating: 16,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 129,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tph.timeProgram',
                                objectid: 35,
                                range: [],
                                remote: true,
                                type: 8,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 35,
                        name: 'Room controller',
                        properties: [
                            {
                                id: 89,
                                pinid: 'a70e5f4a2254aaf3a903b53ff91cc84b',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 90,
                                pinid: '6d6e20e2d6a7e82386ef15930d41824e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 91,
                                pinid: '405502632d68e338630ad9729e626c57',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 92,
                                pinid: '39fa7456dcd04f3d3731db82167564ff',
                                readonly: false,
                                value: '17',
                            },
                            {
                                id: 93,
                                pinid: '7e18b8e617aeae482e9f731784a2811a',
                                readonly: false,
                                value: '22',
                            },
                            {
                                id: 94,
                                pinid: 'a24eef4850f0f8094196540b46e01d76',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 95,
                                pinid: 'a61f83aa5674dfddcfcad8c6d242f657',
                                readonly: false,
                                value: '24',
                            },
                            {
                                id: 96,
                                pinid: '1dc82f53ee242ebd9018c9fc26ccfb71',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 97,
                                pinid: '66e5d781fdd48c9fef7e5adc2d0f9338',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 98,
                                pinid: '682d434e1d70ccc758edb3dddc34e38b',
                                readonly: false,
                                value: 'false',
                            },
                        ],
                        ranking: 0,
                        roomid: 3,
                        type: 21,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 233,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 36,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 234,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 36,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 235,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 36,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 36,
                        name: 'Switch',
                        properties: [
                            {
                                id: 153,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 11,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 236,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 237,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 37,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 238,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 37,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 239,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 240,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 241,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 242,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 243,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 37,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 154,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 155,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 156,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 157,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 158,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 159,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 160,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 161,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 12,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 244,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarmClock',
                                objectid: 38,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 245,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartSnoozeTimer',
                                objectid: 38,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 246,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsAlarm',
                                objectid: 38,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 247,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsSnooze',
                                objectid: 38,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 248,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSnoozeIntervalDuration',
                                objectid: 38,
                                range: ['0', '30'],
                                remote: true,
                                type: 7,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    Alarms: [
                                        {
                                            Active: false,
                                            Friday: false,
                                            Monday: false,
                                            Saturday: false,
                                            Sunday: false,
                                            Thursday: false,
                                            Time: '00:00',
                                            Tuesday: false,
                                            Wednesday: false,
                                        },
                                        {
                                            Active: true,
                                            Description: 'Work',
                                            Friday: true,
                                            Monday: true,
                                            Saturday: false,
                                            Sunday: false,
                                            Thursday: true,
                                            Time: '08:00',
                                            Tuesday: true,
                                            Wednesday: true,
                                        },
                                        {
                                            Active: true,
                                            Description: 'Holidays',
                                            Friday: false,
                                            Monday: false,
                                            Saturday: true,
                                            Sunday: true,
                                            Thursday: false,
                                            Time: '10:30',
                                            Tuesday: false,
                                            Wednesday: false,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 249,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'alarm.alarmProgram',
                                objectid: 38,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 38,
                        name: 'Alarm clock',
                        properties: [
                            {
                                id: 162,
                                pinid: '82cbb2b6dcbc77e13dd2fc67c8bffd6e',
                                readonly: false,
                                value: '60',
                            },
                        ],
                        ranking: 0,
                        roomid: 12,
                        type: 1,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 250,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 251,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 252,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 39,
                        name: 'Switch Balcony 1',
                        properties: [
                            {
                                id: 163,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 13,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 253,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 40,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 254,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 40,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 255,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 40,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 40,
                        name: 'Switch Balcony 2',
                        properties: [
                            {
                                id: 164,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 13,
                        type: 25,
                        visualisationpw: false,
                    },
                ],
            },
            rooms: [
                {
                    category: 5,
                    id: 1,
                    name: 'Kitchen',
                    ranking: 0,
                },
                {
                    category: 4,
                    id: 2,
                    name: 'Living room',
                    ranking: 0,
                },
                {
                    category: 3,
                    id: 3,
                    name: 'Bedroom',
                    ranking: 0,
                },
                {
                    category: 9,
                    id: 4,
                    name: 'Garage',
                    ranking: 0,
                },
                {
                    category: 1,
                    id: 5,
                    name: 'Bathroom',
                    ranking: 0,
                },
                {
                    category: 11,
                    id: 6,
                    name: 'Office',
                    ranking: 0,
                },
                {
                    category: 7,
                    id: 7,
                    name: 'Dining room',
                    ranking: 0,
                },
                {
                    category: 15,
                    id: 8,
                    name: 'Toilet',
                    ranking: 0,
                },
                {
                    category: 2,
                    id: 9,
                    name: 'Hall',
                    ranking: 0,
                },
                {
                    category: 16,
                    id: 10,
                    name: 'Central',
                    ranking: 0,
                },
                {
                    category: 8,
                    id: 11,
                    name: 'Dressing room',
                    ranking: 0,
                },
                {
                    category: 10,
                    id: 12,
                    name: 'Child room',
                    ranking: 0,
                },
                {
                    category: 13,
                    id: 13,
                    name: 'Balcony',
                    ranking: 0,
                },
            ],
            time: {
                datetime: '2022-12-13T10:21:42Z',
                timezone: 'Europe/Warsaw',
            },
            token: {
                token: 'ayjUHRzjt5wlU7ZY6kJiRlkoDVWnzO',
            },
            update: {
                checker: {
                    error: {},
                    policy: 'image',
                    timestamp: '2022-12-13T09:39:49Z',
                },
                state: 'NoImageExist',
                update: {
                    error: null,
                    peer: {
                        current: {
                            dependencies: {
                                mobileapp: '>=v2.40.0',
                                progtool: '>=v2.36.0',
                            },
                            name: 'radioserver',
                            sid: '001-alpha',
                            version: 'v2.20.0-alpha.0',
                        },
                        latest: {
                            dependencies: {},
                            name: 'radioserver',
                            version: '*',
                        },
                    },
                    sender: {
                        current: {
                            dependencies: {
                                mobileapp: '>=v2.40.0',
                                progtool: '>=v2.36.0',
                            },
                            name: 'radioserver',
                            sid: '001-alpha',
                            version: 'v2.20.0-alpha.0',
                        },
                        latest: {
                            dependencies: {
                                mobileapp: '>=v2.40.0',
                                progtool: '>=v2.36.0',
                            },
                            name: 'radioserver',
                            sid: '001-alpha',
                            version: 'v2.20.0-alpha.0',
                        },
                    },
                    summary: {
                        available: false,
                        compatible: true,
                        url: '',
                    },
                },
            },
            users: [
                {
                    deletable: false,
                    id: 1,
                    isadmin: true,
                    name: 'admin',
                    password:
                        'pWyLUmOAMd2ySuBJk4uXXpSp6SVOv+aGFSzdOtVgSv4Ezm4/DzNs0NjpuzLbz/93M7lVRie5aWS+A7r6vVOVmiwOPlsZ+ojoqi/o/D3RleFdqpI1guZbJgbPvUKEUwVuz4vM7yAw/K7vfnGVyk9yAmg+22BKN5ZMRHOq8luu3GArN1uK6j814xtHXQHoKzRZrCzQed+07jcJjnDaU9CDIbxvkywc/WJ3k6ouzhHq5mwvIShU+ygR0twigu8b01m8sdCTlvQUJSubj4sSEVus87iiUbIkTM4p35E4G7IroVOfRxdC0TNR2/qzt/jwbxbXOvix/YXdmBoIaHv2YgJve+EIW5P4CrCiCxvXPohgKmyMtkNYEpsfQRFeT63y11VUXf8cM2Xn+WfRWbnud+ieDbw9URto8Kh7jWV9n4NU3vSOwBgNx6JQHqrDwitWrObIjtXiNKQmY8jcGtclvnSGGf48xjiHnFGuGMhePEHG/rQE5JHhWkjvtCnuBqpPeNWLhbZMFGv5oN0dDPrmfbsqQqJiRlJ3qw2zJkoSGQaqsRHQXMi+UXBJLmaj0yS8So2beDU2pwFEoEzELDZRCqQlxy9k1mBLyXQt2/zW860p1kYVDrAUESDqOkZ3ZcoVqXOWaqWoI21fFD/Avn13R83YiTnnvflCvbMPLDcPgzPI7p4=',
                    usergroups: [],
                },
            ],
        }),
    ) as SetupInfo;
