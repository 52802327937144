import EmptyError from 'components/EmptyError/EmptyError';
import { useServerConfig } from 'hooks/useServerConfig';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './ServerControls.styles';
import VirtualDeviceView from './VirtualDeviceView/VirtualDeviceView';

type Props = {
    virtualDevices: VirtualDevice[];
};

export const hasAnyControlsToShow = (vd: VirtualDevice) => {
    switch (vd.type) {
        case VirtualDeviceType.LightController:
        case VirtualDeviceType.Dimmer:
        case VirtualDeviceType.ConstantLightController:
        case VirtualDeviceType.Schedule:
        case VirtualDeviceType.ToiletVentilationController:
        case VirtualDeviceType.FireAndWaterAlarm:
        case VirtualDeviceType.BurglarAlarm:
        case VirtualDeviceType.AlarmClock:
        case VirtualDeviceType.GarageGate:
        case VirtualDeviceType.CentralGarageGate:
        case VirtualDeviceType.StaircaseTimer:
        case VirtualDeviceType.Blinds:
        case VirtualDeviceType.RoomController:
        case VirtualDeviceType.CentralRoomControl:
        case VirtualDeviceType.Monitor:
        case VirtualDeviceType.CentralBattery:
        case VirtualDeviceType.CentralHomeButtons:
        case VirtualDeviceType.CentralWindowsAndDoors:
        case VirtualDeviceType.TotalEnergyMonitor:
        case VirtualDeviceType.CentralBlindsController:
        case VirtualDeviceType.Camera:
        case VirtualDeviceType.EnergyManagement:
        case VirtualDeviceType.OperationalModeSelector:
        case VirtualDeviceType.Alphatronics:
            return true;
        default: {
            const labels = vd.datapoints.filter(
                (x) => (x.type == DatapointType.Number && x.writeprotect) || x.type == DatapointType.StatusLight,
            );
            const sliders = vd.datapoints.filter(
                (x) => (x.type == DatapointType.Number && !x.writeprotect) || x.type == DatapointType.Brightness,
            );
            const switches = vd.datapoints.filter((x) => x.type == DatapointType.Switch);
            const buttons = vd.datapoints.filter((x) => x.type == DatapointType.Button);
            return labels.length > 0 || sliders.length > 0 || switches.length > 0 || buttons.length > 0;
        }
    }
};

const ServerControls = ({ virtualDevices }: Props) => {
    const { t } = useTranslation();
    const { rooms, currentServer, info } = useServerConfig();

    const virtualDevicesToShow = useMemo(() => virtualDevices.filter(hasAnyControlsToShow), [virtualDevices]);

    if (virtualDevices.length === 0) {
        return <EmptyError title={t('errors.noControlsToShow')} />;
    }

    return (
        <Styled.MainWrapper>
            <Styled.ControlsSpace size={20} wrap>
                {currentServer &&
                    virtualDevicesToShow.map((vd) => (
                        <VirtualDeviceView
                            currentServer={currentServer}
                            rooms={rooms}
                            id={vd.id}
                            virtualDevice={vd}
                            key={vd.id}
                            serverSerialNumber={info?.serialnumber ?? ''}
                        />
                    ))}
            </Styled.ControlsSpace>
        </Styled.MainWrapper>
    );
};

export default ServerControls;
