import { useContext, useEffect, useMemo, useState } from 'react';
import WpcSchemeEdit, { SchemeConfig, WpcElement } from './WpcSchemeEdit/WpcSchemeEdit';
import WpcSchemeSelection from './WpcSchemeSelection/WpcSchemeSelection';
import { WpcElementType } from '../WpcDashboardPage/WpcDashboardPage';

import bufferImage from './buffer.png';
import directHeatingCircutImage from './directHeatingCircut.png';
import mixedHeatingCircuitImage from './mixedHeatingCircuit.png';
import hotWaterImage from './hotWater.png';
import heatGeneratorImage from './heatGenerator.png';
import { CgSmartHomeBoiler } from 'react-icons/cg';
import { MdOutlineTheaters } from 'react-icons/md';
import { FaFireBurner, FaHouseFloodWater } from 'react-icons/fa6';
import { TFunction, t } from 'i18next';
import { WpcAuthContext } from 'pages/Wpc/contexts/WpcAuthContext';

export type CreateView = 'schemeSelection' | 'schemeEdit';

const iconProps = {
    fontSize: 40,
};

export const elementsConfig = (t: TFunction): WpcElement[] => [
    {
        type: WpcElementType.HeatGenerator,
        name: t('wpc.heatGenerator'),
        icon: <CgSmartHomeBoiler {...iconProps} />,
        removable: false,
        schemeImage: heatGeneratorImage,
    },
    {
        type: WpcElementType.Buffer,
        name: t('wpc.buffer'),
        icon: <MdOutlineTheaters {...iconProps} />,
        removable: true,
        schemeImage: bufferImage,
    },
    {
        type: WpcElementType.HotWater,
        name: t('wpc.hotWater'),
        icon: <FaHouseFloodWater {...iconProps} />,
        removable: true,
        schemeImage: hotWaterImage,
    },
    {
        type: WpcElementType.MixedHeatingCircuit,
        name: t('wpc.mixedHeatingCircuit'),
        icon: <FaFireBurner {...iconProps} />,
        removable: true,
        schemeImage: mixedHeatingCircuitImage,
    },
    {
        type: WpcElementType.DirectHeatingCircuit,
        name: t('wpc.directHeatingCircuit'),
        icon: <FaFireBurner {...iconProps} />,
        removable: true,
        schemeImage: directHeatingCircutImage,
    },
];

export const schemeConfigs: SchemeConfig[] = [
    {
        schemeId: 3,
        elements: [WpcElementType.HeatGenerator, WpcElementType.HotWater, WpcElementType.MixedHeatingCircuit],
    },
    {
        schemeId: 17,
        elements: [
            WpcElementType.HeatGenerator,
            WpcElementType.HotWater,
            WpcElementType.Buffer,
            WpcElementType.MixedHeatingCircuit,
            WpcElementType.MixedHeatingCircuit,
        ],
    },
];

const isArrayEqual = <T,>(arr1: T[], arr2: T[]) => {
    return (
        arr1.length === arr2.length &&
        arr1.every(function (value, index) {
            return value === arr2[index];
        })
    );
};

const CreateWpcProjectPage = () => {
    const { scheme } = useContext(WpcAuthContext);
    const [currentView, setCurrentView] = useState<CreateView>('schemeSelection');
    const [selectedScheme, setSelectedSchemeIndex] = useState<number | undefined>();

    const hasCustomScheme = useMemo(() => {
        if (!scheme) {
            return false;
        }

        const connectedCurrentScheme = schemeConfigs.find((x) => x.schemeId === scheme.payload.schemeId);

        if (!connectedCurrentScheme) {
            return false;
        }

        return !isArrayEqual(connectedCurrentScheme.elements, scheme.payload.elements);
    }, [scheme, schemeConfigs]);

    const schemesToSelect = useMemo(() => {
        const titledSchemes = schemeConfigs.map((x) => ({
            ...x,
            title: `${t('general.scheme')} ${x.schemeId}`,
        }));
        if (!scheme) {
            return titledSchemes;
        }

        const connectedCurrentScheme = schemeConfigs.find((x) => x.schemeId === scheme.payload.schemeId);

        if (!connectedCurrentScheme) {
            return titledSchemes;
        }

        return !hasCustomScheme
            ? titledSchemes
            : [
                  {
                      ...scheme.payload,
                      title: `${t('general.scheme')} ${connectedCurrentScheme.schemeId} - ${t('general.customized')}`,
                  },
                  ...titledSchemes,
              ];
    }, [schemeConfigs, scheme, hasCustomScheme]);

    useEffect(() => {
        if (scheme) {
            const connectedElement = schemesToSelect.find((x) => x.schemeId === scheme.payload.schemeId);
            setSelectedSchemeIndex(
                hasCustomScheme || !connectedElement ? 0 : schemesToSelect.indexOf(connectedElement),
            );
        }
    }, [scheme, hasCustomScheme, schemesToSelect]);

    const handleSelectScheme = (schemeIndex: number) => {
        setSelectedSchemeIndex(schemeIndex);
    };

    switch (currentView) {
        case 'schemeSelection':
            return (
                <WpcSchemeSelection
                    schemesToSelect={schemesToSelect}
                    selectedScheme={selectedScheme}
                    onSelectScheme={handleSelectScheme}
                    onSetCurrentView={setCurrentView}
                />
            );
        case 'schemeEdit':
            return (
                <WpcSchemeEdit
                    schemesToSelect={schemesToSelect}
                    selectedScheme={selectedScheme}
                    onSetCurrentView={setCurrentView}
                />
            );
    }
};

export default CreateWpcProjectPage;
