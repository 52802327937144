import { ThemedSelect, ThemedSlider, ThemedSwitch } from 'components/ThemedComponents/ThemedComponents.styles';
import { getBooleanValue } from 'helpers/DatapointHelper';
import { useImages } from 'hooks/useImages';
import { OpeartionModeConfigType, OperationModeConfig, OperationModeValue } from 'models/server/OperationModeConfig';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { useMemo } from 'react';
import * as Styled from './BlockValueView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    config?: OperationModeConfig;
    mode: OperationModeValue;
    isOffScene: boolean;
    onValueChanged: (v: string) => void;
};

const BlockValueView = ({ mode, config, virtualDevice, isOffScene, onValueChanged }: Props) => {
    const { radio_bulb, socket, rooms, getCategorySettings } = useImages();

    const categorySettings = useMemo(() => getCategorySettings(virtualDevice.category), [virtualDevice]);

    const image = useMemo(() => {
        switch (config?.Type) {
            case OpeartionModeConfigType.Dimmer:
                return radio_bulb;
            case OpeartionModeConfigType.Switch:
                return socket;
            case OpeartionModeConfigType.Dropdown:
                return rooms;
        }
    }, [config, radio_bulb, socket, rooms]);

    return (
        <Styled.LightWrapper>
            <Styled.HeaderWrapper>
                <Styled.IconWrapper $color={categorySettings.color}>
                    <Styled.ServerImage src={image} />
                </Styled.IconWrapper>
                <Styled.ServerName>{config?.Description}</Styled.ServerName>
                {config?.Type === OpeartionModeConfigType.Switch && (
                    <ThemedSwitch
                        disabled={isOffScene}
                        checked={getBooleanValue(mode.Value?.toString())}
                        onChange={(v) => onValueChanged(v.toString())}
                        $color={categorySettings.color}
                    />
                )}
            </Styled.HeaderWrapper>
            {config?.Type === OpeartionModeConfigType.Dimmer && (
                <Styled.SliderWrapper>
                    <ThemedSlider
                        step={config.Step ? Number(config.Step) : 1}
                        min={Number(config?.Minimum ?? 0)}
                        max={Number(config?.Maximum ?? 100)}
                        disabled={isOffScene}
                        value={Number(mode.Value)}
                        onChange={(v) => onValueChanged(v.toString())}
                    />
                </Styled.SliderWrapper>
            )}
            {config?.Type === OpeartionModeConfigType.Dropdown && (
                <ThemedSelect
                    value={Number(mode.Value)}
                    options={config?.DropdownList.map((x) => ({
                        label: x.Name,
                        value: Number(x.Value),
                    }))}
                    onChange={(v) => onValueChanged(v?.toString())}
                />
            )}
        </Styled.LightWrapper>
    );
};

export default BlockValueView;
