import styled from 'styled-components';

export const Column = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LeftArrow = styled.div`
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100% !important;
    border: 1px solid #f0f0f0 !important;
    border-right: none !important;
`;

export const MainContainer = styled.div`
    min-height: 250px;
    display: flex;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;

    @media (max-width: 550px) {
        padding: 0;
    }
`;

export const ChartContainer = styled.div`
    padding: 25px !important;
    padding-top: 0 !important;
`;

export const Title = styled.div`
    width: 100%;
    padding: 20px 0;
    font-weight: 500;
    font-size: 16px;
    position: relative;

    &::after {
        content: '';
        background-color: ${(props) => props.theme.colors.text};
        height: 1px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 15px;
    }
`;

export const Error = styled.div`
    color: red;
    font-weight: bold;
    font-size: 15;
    padding: 30px;
`;

export const NoDataError = styled.div`
    color: red;
    font-weight: bold;
    font-size: 13;
    padding: 10;
`;

export const Row = styled.div`
    display: flex;
`;

export const SpinWrapper = styled.div`
    height: 50px;
    margin-top: 10px;
`;

export const CheckboxTitle = styled.div`
    display: flex;
    margin: 0 5px;
    white-space: nowrap;
`;

export const OptionSelectWrapper = styled.div`
    width: 150px;
`;

export const FrameSelectWrapper = styled.div`
    width: 120px;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
`;
