import { getBooleanValue } from 'helpers/DatapointHelper';
import { getVirtualDatapointNameTranslation, isEmpty } from 'helpers/StringHelper';
import { Datapoint } from 'models/server/Datapoint';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './DatapointValueView.styles';

export type DatapointValueViewProps = {
    valueOrDp: Datapoint | string;
    sufix?: string;
    title?: string;
    useCustomName?: boolean;
    hideValue?: boolean;
    rounding?: number;
    showOnOffAsBoolValue?: boolean;
    showYesNoAsBoolValue?: boolean;
    showClosedOpenTiledAsValue?: boolean;
    validateValue?: (dp: Datapoint) => string | undefined;
};

const DatapointValueView = ({
    valueOrDp,
    sufix,
    useCustomName,
    hideValue,
    title,
    rounding = 0,
    showOnOffAsBoolValue,
    showYesNoAsBoolValue,
    showClosedOpenTiledAsValue,
    validateValue,
}: DatapointValueViewProps) => {
    const { t } = useTranslation();

    const name = useMemo(() => {
        if (title) {
            return title;
        }

        if (typeof valueOrDp === 'string') {
            return '';
        }

        if (useCustomName) {
            return valueOrDp.customname;
        }

        if (valueOrDp.name === DatapointNames.VirtualOutput) {
            if (typeof valueOrDp.value === 'number') {
                return t('general.value');
            }

            if (typeof valueOrDp.value === 'boolean' || valueOrDp.value === 'true' || valueOrDp.value === 'false') {
                return t('general.onOff');
            }
        }

        return getVirtualDatapointNameTranslation(valueOrDp.name, valueOrDp.customname);
    }, [valueOrDp, useCustomName, title]);

    const currentValue = useMemo(() => {
        if (hideValue) {
            return '';
        }

        if (typeof valueOrDp === 'string') {
            return valueOrDp;
        }

        const validatedValue = validateValue?.(valueOrDp);
        if (validatedValue) {
            return validatedValue;
        }

        if (showYesNoAsBoolValue) {
            return getBooleanValue(valueOrDp.value.toString()) ? t('general.yes') : t('general.no');
        }

        if (showOnOffAsBoolValue) {
            return getBooleanValue(valueOrDp.value.toString()) ? t('general.on') : t('general.off');
        }

        if (showClosedOpenTiledAsValue) {
            switch (Number(valueOrDp.value)) {
                case 0:
                    return t('general.closed');
                case 1:
                    return t('general.open');
                case 2:
                    return t('general.tiled');
                default:
                    return '';
            }
        }

        if (isEmpty(valueOrDp.value?.toString())) {
            return '';
        }

        const numberValue = Number(valueOrDp.value);
        if (!isNaN(numberValue)) {
            return rounding ? numberValue.toFixed(rounding) : numberValue;
        }

        return valueOrDp.value?.toString();
    }, [valueOrDp, hideValue, showOnOffAsBoolValue]);

    const formattedValue = useMemo(() => {
        const validatedValue = typeof valueOrDp !== 'string' && validateValue?.(valueOrDp);
        if (validatedValue) {
            return validatedValue;
        }

        return `${currentValue}${sufix ?? ''}`;
    }, [sufix, currentValue]);

    return (
        <Styled.MainWrapper>
            <Styled.DpName>{name}</Styled.DpName>
            {!hideValue && <div>{formattedValue}</div>}
        </Styled.MainWrapper>
    );
};

export default DatapointValueView;
