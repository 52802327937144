import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { Datapoint } from 'models/server/Datapoint';
import { Presence } from 'models/server/Presence';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './PresenceSimulationEditPage.styles';
import PresenceView from './PresenceView/PresenceView';

const PresenceSimulationEditPage = () => {
    const { t } = useTranslation();

    const { currentServer, currentVirtualDevice, serverConfigLoading } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { onGoBack } = useGoBack();

    const timeProgramDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.PresenceSimulationConfig),
        [currentVirtualDevice?.datapoints],
    );

    const [presences, setPresences] = useState<Presence[]>(timeProgramDatapoint?.Presences ?? []);

    useEffect(() => {
        setPresences(timeProgramDatapoint?.Presences ?? []);
    }, [timeProgramDatapoint?.Presences]);

    const onSave = async () => {
        if (!timeProgramDatapoint) {
            return;
        }

        const newTimeProgramDatapoint: Datapoint = {
            ...timeProgramDatapoint,
            Presences: presences,
        };

        const result = await onDatapointChange(newTimeProgramDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onPresenceChanged = (index: number, presence: Presence) => {
        setPresences((prev) => prev.map((pres, pIndex) => (pIndex === index ? presence : pres)));
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!timeProgramDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (timeProgramDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                <ThemedButton $action onClick={onSave}>
                    {t('general.save')}
                </ThemedButton>
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {presences.map((pres, index) => (
                    <PresenceView presence={pres} key={index} index={index} onPresenceChanged={onPresenceChanged} />
                ))}
            </Space>
        </Styled.MainWrapper>
    );
};

export default PresenceSimulationEditPage;
