import { Spin } from 'antd';
import i18n from 'i18next';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';

interface LocationMarkerProps {
    positionClicked?: (position: { lat: number; lng: number }) => void;
}

const provider = new OpenStreetMapProvider({ params: { 'accept-language': i18n.resolvedLanguage ?? 'en' } });

const Search = () => {
    const map = useMap();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const searchControl = new GeoSearchControl({
            provider,
            showMarker: false,
        });

        map.addControl(searchControl);
        return () => {
            map.removeControl(searchControl);
        };
    }, []);

    return null;
};

const LocationMarker = ({ positionClicked }: LocationMarkerProps) => {
    useMapEvents({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        click(e: any) {
            positionClicked?.(e.latlng);
        },
    });

    return null;
};

interface Props {
    onChange: (position: { lat: string; lng: string }) => void;
    position: { lat: string; lng: string };
}

export const getLabel = async (lat: number, lng: number): Promise<string> => {
    try {
        const result = await provider.search({
            query: `${lat.toFixed(14)}, ${lng.toFixed(14)}`,
        });

        return result?.[0]?.label ?? '?';
    } catch {
        return '';
    }
};

const PositionMap = (props: Props): JSX.Element => {
    const { position, onChange } = props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markerRef = useRef<any>(null);

    const [isLabelLoading, setIsLabelLoading] = useState(false);
    const [zoom, setZoom] = useState<number>();
    const [label, setLabel] = useState('');

    useEffect(() => {
        (async () => {
            setIsLabelLoading(true);
            setLabel(await getLabel(Number(position.lat), Number(position.lng)));
            markerRef?.current?.openPopup();
            setIsLabelLoading(false);
        })();
    }, [position]);

    useEffect(() => {
        setZoom(13);
    }, []);

    return zoom ? (
        <MapContainer style={{ height: '100%' }} center={[Number(position.lat), Number(position.lng)]} zoom={zoom}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker positionClicked={(e) => onChange({ lat: e.lat.toString(), lng: e.lng.toString() })} />
            <Search />
            <Marker ref={markerRef} position={[Number(position.lat), Number(position.lng)]}>
                <Popup>{isLabelLoading ? <Spin /> : label}</Popup>
            </Marker>
        </MapContainer>
    ) : (
        <></>
    );
};

export default PositionMap;
