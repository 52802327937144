import styled from 'styled-components';

export const NetatmoDevices = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const NetatmoDevice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DeviceTitle = styled.div<{ $connected: boolean }>`
    font-weight: bold;
    color: ${(props) => (props.$connected ? '#07bc0c' : '#e74c3c')};
    max-width: 230px;
`;

export const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    color: #07bc0c;
`;

export const Footer = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
    justify-content: flex-end;
`;
