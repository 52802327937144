import { Button, Form, Input, Spin } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import EmptySuccess from 'components/EmptySuccess/EmptySuccess';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

export const BackgroundImage = styled.img`
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.6;
    z-index: -1;
    top: 0;
    left: 0;
`;

export const LogoImage = styled.img`
    object-fit: scale-down;
    max-width: 220px;
    margin-bottom: 50px;
    margin-top: 85px;
`;

export const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LoginButton = styled(Button)`
    max-width: 150px;
    height: 40px;
    background-color: #bfa889 !important;
    align-self: center;
    margin-top: 10px;
    color: white;
`;

export const DemoButton = styled(Button)`
    width: 120px;
    height: 40px;
    background-color: #686767 !important;
    border-color: #bfa889;
    align-self: center;
    margin-top: 10px;
`;

export const ButtonWrapper = styled.div`
    background-color: #686767b3 !important;
    padding: 40px;
    box-shadow: rgb(191 168 137 / 20%) 0px 0px 35px 0px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 350px;
    align-items: center;

    .ant-form-item {
        margin-bottom: 12px;
    }

    .ant-input:-webkit-autofill,
    .ant-input:-webkit-autofill:hover,
    .ant-input:-webkit-autofill:focus,
    .ant-input:-webkit-autofill:active {
        color-scheme: dark;
        -webkit-box-shadow: 0 0 0 30px #686767 inset !important;
        box-shadow: 0 0 0 30px #686767 inset !important;
    }

    .ant-form-item-explain-error {
        font-size: 12px;
        line-height: 14px;
    }
`;

export const LogoutButton = styled(LoginButton)`
    background-color: transparent !important;
    border: 1px solid #bfa889 !important;
`;

export const HelloWrapper = styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
`;

export const Hello = styled.div`
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
`;

export const UsernameInput = styled(Input)`
    background-color: #686767 !important;
    border: 1px solid #f1e8d9 !important;
    color: #f1e8d9;

    .ant-input {
        background-color: transparent;
        color: #f1e8d9;
    }
`;

export const PasswordInput = styled(Input.Password)`
    background-color: #686767 !important;
    border: 1px solid #f1e8d9 !important;
    color: #f1e8d9;
    width: 280px;

    .ant-input {
        background-color: transparent;
        color: #f1e8d9;
    }

    .anticon {
        color: white !important;
    }
`;

export const LoginForm = styled(Form)`
    display: flex;
    flex-direction: column;
    margin-top: -10px;
`;

export const ColorSpin = styled(Spin)`
    .ant-spin-dot .ant-spin-dot-item {
        background-color: #f1e8d9;
    }
`;

export const RedEmptyError = styled(EmptyError)`
    color: #e74c3c !important;
`;

export const GreenEmptySuccess = styled(EmptySuccess)`
    color: #07bc0c !important;
`;

export const EnterCodeInfo = styled.div`
    color: white;
    text-align: center;
`;

export const LeftInfo = styled.div`
    text-align: center;
    font-weight: 600;
    color: #fff;
    margin: 10px 0 0;
`;
