import { overflowXScrollbarStyle } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 25px;
    gap: 15px;
    justify-content: flex-end;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ValuesGrid = styled.div`
    display: flex;
`;

export const DndWrapper = styled.div`
    overflow-x: auto;
    padding-bottom: 3px;
    ${overflowXScrollbarStyle}
`;
