import { Space, Tooltip } from 'antd';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { useApi } from 'hooks/useApi';
import License from 'models/License';
import InviteUserModal from 'pages/Admin/AdminUsersEditPage/InviteUserModal/InviteUserModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoKeyOutline } from 'react-icons/io5';
import { useTheme } from 'styled-components';
import AddEditLicenseModal from '../AddEditLicenseModal/AddEditLicenseModal';
import * as Styled from './LicenseView.styles';

type Props = {
    license: License;
    onReloadLicenses: () => void;
};

const LicenseView = ({ license, onReloadLicenses }: Props) => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const { apiWithCustomLicenseFetch } = useApi();

    const [currentModal, setCurrentModal] = useState<'edit' | 'invite'>();

    const onInviteUser = (email: string, name: string, url: string) => {
        return apiWithCustomLicenseFetch(
            license.id,
            'Invitations/Send',
            'POST',
            `?email=${email}&name=${name}&acceptURL=${encodeURIComponent(url)}`,
        );
    };

    const onEditServer = () => {
        setCurrentModal('edit');
    };

    const onInviteUserModal = () => {
        setCurrentModal('invite');
    };

    const onCloseModal = () => {
        setCurrentModal(undefined);
    };

    return (
        <>
            <Tooltip
                color={license.enabled ? colors.success : colors.error}
                title={license.enabled ? t('general.active') : t('general.inactive')}
            >
                <Styled.MainWrapper>
                    <Styled.HeadWrapper>
                        <Styled.IconWrapper $isConnected={license.enabled}>
                            <IoKeyOutline size={28} />
                        </Styled.IconWrapper>
                        <Styled.ServerName>{`${license.name}`}</Styled.ServerName>
                    </Styled.HeadWrapper>
                    <Line />
                    {license.licenseKey}
                    <Line />
                    <Space size={[20, 10]} wrap>
                        <Styled.InfoWrapper>
                            <Styled.NumberWrapper>{license.servers.length}</Styled.NumberWrapper>
                            {t('general.servers')}
                        </Styled.InfoWrapper>
                        <Styled.InfoWrapper>
                            <Styled.NumberWrapper>{license.users.length}</Styled.NumberWrapper>
                            {t('general.users')}
                        </Styled.InfoWrapper>
                    </Space>
                    <Line />
                    <Space size={10} wrap>
                        <ThemedButton onClick={onEditServer}>{t('general.edit')}</ThemedButton>
                        <ThemedButton onClick={onInviteUserModal}>{t('general.inviteUser')}</ThemedButton>
                    </Space>
                </Styled.MainWrapper>
            </Tooltip>
            {currentModal === 'edit' && (
                <AddEditLicenseModal license={license} reload={onReloadLicenses} onCancel={onCloseModal} />
            )}
            {currentModal === 'invite' && (
                <InviteUserModal onRefresh={onReloadLicenses} onInvite={onInviteUser} onCancel={onCloseModal} />
            )}
        </>
    );
};

export default LicenseView;
