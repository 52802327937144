import { SetupInfo } from 'models/server/SetupInfo';

export const getEnergyBulk = (): SetupInfo =>
    JSON.parse(
        JSON.stringify({
            devices: [
                {
                    id: 1,
                    islocal: true,
                    name: 'Radio Server',
                    serial: '130A09100100000023430077D3',
                    type: 13,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 2,
                    islocal: true,
                    name: 'Radio Bulb',
                    roomid: 3,
                    serial: '0000003000000000cd9830cf0a',
                    sleeping: false,
                    type: 3,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 3,
                    islocal: true,
                    name: 'Radio Socket',
                    roomid: 3,
                    serial: '0000003000000000cd9830cf0b',
                    sleeping: false,
                    type: 2,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 4,
                    islocal: true,
                    name: 'Radio MultiSensor',
                    roomid: 3,
                    serial: '0000003000000000cd9830cf0c',
                    sleeping: true,
                    type: 37,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 5,
                    islocal: true,
                    name: 'Radio Leakage Sensor 1',
                    serial: '0000003000000000cd9830cf0e',
                    sleeping: false,
                    type: 23,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 6,
                    islocal: true,
                    name: 'Radio Valve 1',
                    serial: '0000003000000000cd9830cf0d',
                    sleeping: false,
                    type: 5,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 7,
                    islocal: true,
                    name: 'Multilevel Sensor 1',
                    serial: '0000003000000000cd9830cf0f',
                    sleeping: false,
                    type: 15,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 8,
                    islocal: true,
                    name: 'Multilevel Sensor 2',
                    serial: '0000003000000000cd9830cf10',
                    sleeping: false,
                    type: 15,
                },
                {
                    id: 9,
                    islocal: true,
                    name: 'FT Base Modul 1',
                    serial: '010101201228004E0004513930',
                    type: 38,
                },
                {
                    id: 10,
                    islocal: true,
                    name: 'Roja IF 1',
                    serial: '0101012014ed10',
                    type: 54,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 11,
                    islocal: true,
                    name: 'Radio Window /Door Contact 1',
                    serial: '0000003000000000cd9830cf14',
                    sleeping: true,
                    type: 4,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 12,
                    islocal: true,
                    name: 'Radio Window /Door Contact 2 long name od device test 123456',
                    serial: '',
                    type: 4,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 13,
                    islocal: true,
                    name: 'Sensor Notification 3',
                    serial: '0000003000000000cd9830cf11',
                    sleeping: true,
                    type: 15,
                },
                {
                    id: 14,
                    islocal: false,
                    name: 'Radio Server 1',
                    serial: '130A09100100000023430077D1',
                    type: 13,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 15,
                    islocal: true,
                    name: 'Balcony_New Radio Switch Shutter Plus_1',
                    roomid: 4,
                    serial: '0000003000000000cd9830cf13',
                    sleeping: false,
                    type: 49,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 16,
                    islocal: true,
                    name: 'Radio Leakage Sensor 2',
                    serial: '0000003000000000cd9830cf19',
                    sleeping: false,
                    type: 23,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 17,
                    islocal: true,
                    name: 'Radio Socket 2',
                    serial: '0000003000000000cd9830cf17',
                    sleeping: false,
                    type: 2,
                },
                {
                    id: 18,
                    islocal: true,
                    name: 'Network device 1',
                    serial: '00000090000000000000000001',
                    type: 46,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 19,
                    islocal: true,
                    name: 'Radio Valve 2',
                    serial: '0000003000000000cd9830cf1b',
                    sleeping: false,
                    type: 5,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 20,
                    islocal: true,
                    name: 'Radio CO2 Sensor 1',
                    serial: '0000003000000000cd9830cf1c',
                    sleeping: false,
                    type: 56,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 21,
                    islocal: true,
                    name: 'Radio Valve 3',
                    serial: '0000003000000000cd9830cf1d',
                    sleeping: false,
                    type: 5,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 22,
                    islocal: true,
                    name: 'Radio Siren 1',
                    serial: '0000003000000000cd9830cf1e',
                    sleeping: false,
                    type: 22,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 23,
                    islocal: true,
                    name: 'Radio eHandle ConnectSense 1',
                    serial: '',
                    type: 57,
                },
                {
                    gwSerial: '130A09100100000023430077D3',
                    id: 24,
                    islocal: true,
                    name: 'Radio Valve 4',
                    serial: '0000003000000000cd9830cf12',
                    sleeping: false,
                    type: 5,
                },
                {
                    id: 25,
                    islocal: true,
                    name: 'Modbus TCP 1',
                    serial: '170906B01078D828F9AAEF20DF',
                    type: 51,
                },
                {
                    gwSerial: '010101201228004E0004513930',
                    id: 26,
                    islocal: true,
                    name: 'FT DIN SIX 1',
                    serial: '010101A001EE4B',
                    type: 40,
                },
                {
                    gwSerial: '010101201228004E0004513930',
                    id: 27,
                    islocal: true,
                    name: 'FT RGBW Modul 1',
                    serial: '010101A002BC40',
                    type: 41,
                },
                {
                    gwSerial: '010101201228004E0004513930',
                    id: 28,
                    islocal: true,
                    name: 'FT Switch Dual 1',
                    serial: '010101A003A816',
                    type: 43,
                },
                {
                    gwSerial: '010101201228004E0004513930',
                    id: 29,
                    islocal: true,
                    name: 'FT Switch Dimmer 1',
                    serial: '010101A004A932',
                    type: 42,
                },
                {
                    gwSerial: '0101012014ed10',
                    id: 30,
                    islocal: true,
                    name: 'Roja Blind 1',
                    serial: '010101C000ed1031238180',
                    type: 55,
                },
                {
                    gwSerial: '0101012014ed10',
                    id: 31,
                    islocal: true,
                    name: 'Roja Blind 2',
                    serial: '010101C000ed1031238182',
                    type: 55,
                },
                {
                    gwSerial: '130A09100100000023430077D1',
                    id: 32,
                    islocal: false,
                    name: 'Radio Switch Dual Plus 1',
                    serial: '0000003000000000c8f4ca5c0e',
                    sleeping: false,
                    type: 28,
                },
                {
                    gwSerial: '170906B01078D828F9AAEF20DF',
                    id: 33,
                    islocal: true,
                    name: 'Modbus device 1',
                    serial: '170906B0116F276C746D9A6749',
                    type: 53,
                },
            ],
            info: {
                'bsp-version': '2.22.0',
                internet: true,
                name: 'Radio Server',
                oem: 'TEM',
                projectHash: 'f970a48faff53dc72f36b9ed0aa8d8d2',
                projectId: '0ecb4e6e1d5be843d1abecd5758e6740',
                projectName: 'myHome',
                role: 'master',
                sdcard: true,
                serialnumber: '130A09100100000023430077D3',
                serverName: 'Radio Server',
                starterkit: false,
                state: {
                    progress: 100,
                    value: 'running',
                },
                swcinfo: {
                    dependencies: {
                        mobileapp: '>=v2.40.0',
                        progtool: '>=v2.36.0',
                    },
                    name: 'radioserver',
                    sid: '001-alpha',
                    version: 'v2.22.0-alpha.31',
                },
                time: {
                    datetime: '2023-10-20T13:49:18Z',
                    timezone: 'Europe/Warsaw',
                },
                version: 'v2.22.0-alpha.31',
            },
            ios: [
                {
                    device: 2,
                    id: 7,
                    name: ' - color 1 - ',
                    path: '/0/51/',
                    type: 3,
                },
                {
                    device: 3,
                    id: 8,
                    name: 'Digital output DO2 NEW@',
                    path: '/0/37/',
                    type: 1,
                },
                {
                    device: 3,
                    id: 9,
                    name: 'AC unit ',
                    path: '/0/50/0/',
                    type: 6,
                },
                {
                    device: 3,
                    id: 10,
                    name: 'AC unit',
                    path: '/0/50/2/',
                    type: 5,
                    value: 1333,
                },
                {
                    device: 3,
                    id: 11,
                    name: 'test2 Room controller Room temperature',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 3,
                    id: 12,
                    name: 'test2 Room controller Relative humidity room air',
                    path: '/0/49/5/',
                    type: 8,
                },
                {
                    device: 4,
                    id: 13,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 4,
                    id: 14,
                    name: 'Binary sensor',
                    path: '/0/48/',
                    type: 2,
                },
                {
                    device: 4,
                    id: 15,
                    name: 'Burglar notification',
                    path: '/0/113/7/',
                    type: 2,
                },
                {
                    device: 4,
                    id: 16,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 4,
                    id: 17,
                    name: 'Luminance multilevel sensor',
                    path: '/0/49/3/',
                    type: 4,
                },
                {
                    device: 4,
                    id: 18,
                    name: 'Relative humidity multilevel sensor',
                    path: '/0/49/5/',
                    type: 8,
                },
                {
                    device: 4,
                    id: 19,
                    name: 'Ultraviolet multilevel sensor',
                    path: '/0/49/27/',
                    type: 4,
                },
                {
                    device: 5,
                    id: 20,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 5,
                    id: 21,
                    name: 'Water binary sensor',
                    path: '/0/48/6/',
                    type: 2,
                },
                {
                    device: 5,
                    id: 22,
                    name: 'Water notification',
                    path: '/0/113/5/',
                    type: 2,
                },
                {
                    device: 6,
                    id: 23,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 6,
                    id: 24,
                    name: 'Valveposition multilevel switch',
                    path: '/0/38/',
                    type: 3,
                },
                {
                    device: 6,
                    id: 25,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 7,
                    id: 26,
                    name: 'Dew point multilevel sensor',
                    path: '/0/49/11/',
                    type: 4,
                },
                {
                    device: 7,
                    id: 27,
                    name: 'CO2-level multilevel sensor',
                    path: '/0/49/17/',
                    type: 4,
                },
                {
                    device: 7,
                    id: 28,
                    name: 'Volatile Organic Compound multilevel sensor',
                    path: '/0/49/39/',
                    type: 4,
                },
                {
                    device: 7,
                    id: 29,
                    name: 'Home health notification',
                    path: '/0/113/13/',
                    type: 2,
                },
                {
                    device: 7,
                    id: 30,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 7,
                    id: 31,
                    name: 'Relative humidity multilevel sensor',
                    path: '/0/49/5/',
                    type: 8,
                },
                {
                    device: 8,
                    id: 32,
                    name: 'Dew point multilevel sensor',
                    path: '/0/49/11/',
                    type: 4,
                },
                {
                    device: 8,
                    id: 33,
                    name: 'CO2-level multilevel sensor',
                    path: '/0/49/17/',
                    type: 4,
                },
                {
                    device: 8,
                    id: 34,
                    name: 'Volatile Organic Compound multilevel sensor',
                    path: '/0/49/39/',
                    type: 4,
                },
                {
                    device: 8,
                    id: 35,
                    name: 'Home health notification',
                    path: '/0/113/13/',
                    type: 2,
                },
                {
                    device: 8,
                    id: 36,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 8,
                    id: 37,
                    name: 'Relative humidity multilevel sensor',
                    path: '/0/49/5/',
                    type: 8,
                },
                {
                    device: 9,
                    id: 38,
                    name: 'Group output 1',
                    path: '/groups/1/1',
                    type: 9,
                },
                {
                    device: 9,
                    id: 39,
                    name: 'Group output 1',
                    path: '/groups/2/1',
                    type: 9,
                },
                {
                    device: 30,
                    id: 40,
                    name: 'Blinds position report',
                    path: 'position',
                    type: 4,
                },
                {
                    device: 30,
                    id: 41,
                    name: 'Digital output down',
                    path: 'down',
                    type: 1,
                },
                {
                    device: 30,
                    id: 42,
                    name: 'Digital output up',
                    path: 'up',
                    type: 1,
                },
                {
                    device: 31,
                    id: 43,
                    name: 'Blinds position report',
                    path: 'position',
                    type: 4,
                },
                {
                    device: 31,
                    id: 44,
                    name: 'Digital output down',
                    path: 'down',
                    type: 1,
                },
                {
                    device: 31,
                    id: 45,
                    name: 'Digital output up',
                    path: 'up',
                    type: 1,
                },
                {
                    device: 26,
                    id: 46,
                    name: 'Analog input (AI1)',
                    path: '/in/0/8',
                    type: 4,
                },
                {
                    device: 26,
                    id: 47,
                    name: 'Analog input (AI2)',
                    path: '/in/1/8',
                    type: 4,
                },
                {
                    device: 26,
                    id: 48,
                    name: 'Digital input (DI1)',
                    path: '/in/2/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 49,
                    name: 'Digital input (DI2)',
                    path: '/in/3/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 50,
                    name: 'Digital input (DI3)',
                    path: '/in/4/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 51,
                    name: 'Digital input (DI4)',
                    path: '/in/5/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 52,
                    name: 'Button 1',
                    path: '/in/8/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 53,
                    name: 'Button 2',
                    path: '/in/9/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 54,
                    name: 'Button 3',
                    path: '/in/10/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 55,
                    name: 'Button 4',
                    path: '/in/11/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 56,
                    name: 'Button 5',
                    path: '/in/12/0',
                    type: 2,
                },
                {
                    device: 26,
                    id: 57,
                    name: 'salon_Lighting Lighting Output 3',
                    path: '/out/0/9',
                    type: 3,
                },
                {
                    device: 26,
                    id: 58,
                    name: 'Temperature sensor',
                    path: '/in/6/8',
                    type: 7,
                },
                {
                    device: 26,
                    id: 59,
                    name: 'Relative humidity sensor',
                    path: '/in/7/8',
                    type: 8,
                },
                {
                    device: 28,
                    id: 61,
                    name: 'salon_Lighting Button Output',
                    path: '/out/0/0',
                    type: 1,
                },
                {
                    device: 28,
                    id: 62,
                    name: 'salon_Lighting Switch Output',
                    path: '/out/1/0',
                    type: 1,
                },
                {
                    device: 28,
                    id: 63,
                    name: 'Lights',
                    path: '/in/0/8',
                    type: 5,
                    value: 11,
                },
                {
                    device: 28,
                    id: 64,
                    name: 'Lights',
                    path: '/in/1/8',
                    type: 6,
                },
                {
                    device: 29,
                    id: 65,
                    name: 'Analog output',
                    path: '/out/0/8',
                    type: 3,
                },
                {
                    device: 29,
                    id: 66,
                    name: 'Solars',
                    path: '/in/0/8',
                    type: 5,
                    value: 8738,
                },
                {
                    device: 29,
                    id: 67,
                    name: 'Solars',
                    path: '/in/1/8',
                    type: 6,
                },
                {
                    device: 27,
                    id: 68,
                    name: 'Channel R',
                    path: '/out/0/9/6',
                    type: 3,
                },
                {
                    device: 27,
                    id: 69,
                    name: 'Channel G',
                    path: '/out/0/9/7',
                    type: 3,
                },
                {
                    device: 27,
                    id: 70,
                    name: 'Channel B',
                    path: '/out/0/9/8',
                    type: 3,
                },
                {
                    device: 27,
                    id: 71,
                    name: 'Channel W',
                    path: '/out/0/9/4',
                    type: 3,
                },
                {
                    device: 11,
                    id: 72,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 11,
                    id: 73,
                    name: 'Lock access control notification',
                    path: '/0/113/6/',
                    type: 2,
                },
                {
                    device: 11,
                    id: 74,
                    name: 'Door/Window binary sensor',
                    path: '/0/48/10/',
                    type: 2,
                },
                {
                    device: 12,
                    id: 75,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 12,
                    id: 76,
                    name: 'Lock access control notification',
                    path: '/0/113/6/',
                    type: 2,
                },
                {
                    device: 12,
                    id: 77,
                    name: 'Door/Window binary sensor',
                    path: '/0/48/10/',
                    type: 2,
                },
                {
                    device: 13,
                    id: 78,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 13,
                    id: 79,
                    name: 'Tilt binary sensor',
                    path: '/0/48/11/',
                    type: 2,
                },
                {
                    device: 13,
                    id: 80,
                    name: 'Lock access control notification',
                    path: '/0/113/6/',
                    type: 2,
                },
                {
                    device: 13,
                    id: 81,
                    name: 'Burglar notification',
                    path: '/0/113/7/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 82,
                    name: 'Balcony_Blinds_Blinds position report',
                    path: '/1/38/1/',
                    type: 4,
                },
                {
                    device: 15,
                    id: 83,
                    name: 'Balcony_Blinds_Slats position report',
                    path: '/2/38/2/',
                    type: 4,
                },
                {
                    device: 15,
                    id: 84,
                    name: 'Balcony_Blinds_Blinds target position',
                    path: '/1/38/1/',
                    type: 3,
                },
                {
                    device: 15,
                    id: 85,
                    name: 'Balcony_Blinds_Slats target position',
                    path: '/2/38/2/',
                    type: 3,
                },
                {
                    device: 15,
                    id: 86,
                    name: 'Digital input (DI1)',
                    path: '/0/91/1/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 87,
                    name: 'Digital input (DI2)',
                    path: '/0/91/2/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 88,
                    name: 'Button 1',
                    path: '/0/91/3/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 89,
                    name: 'Button 2',
                    path: '/0/91/4/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 90,
                    name: 'Button 3',
                    path: '/0/91/5/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 91,
                    name: 'Button 4',
                    path: '/0/91/6/',
                    type: 2,
                },
                {
                    device: 15,
                    id: 92,
                    name: 'Button 5',
                    path: '/0/91/7/',
                    type: 2,
                },
                {
                    device: 16,
                    id: 93,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 16,
                    id: 94,
                    name: 'Water binary sensor',
                    path: '/0/48/6/',
                    type: 2,
                },
                {
                    device: 16,
                    id: 95,
                    name: 'Water notification',
                    path: '/0/113/5/',
                    type: 2,
                },
                {
                    device: 17,
                    id: 96,
                    name: 'salon_Lighting Timer switch Output',
                    path: '/0/37/',
                    type: 1,
                },
                {
                    device: 17,
                    id: 97,
                    name: 'Car charger',
                    path: '/0/50/2/',
                    type: 5,
                    value: 5020,
                },
                {
                    device: 17,
                    id: 98,
                    name: 'Car charger',
                    path: '/0/50/0/',
                    type: 6,
                },
                {
                    device: 17,
                    id: 99,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 17,
                    id: 100,
                    name: 'Relative humidity multilevel sensor',
                    path: '/0/49/5/',
                    type: 8,
                },
                {
                    device: 19,
                    id: 101,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 20,
                    id: 104,
                    name: 'CO2-level multilevel sensor',
                    path: '/0/49/17/',
                    type: 4,
                },
                {
                    device: 20,
                    id: 105,
                    name: 'Volatile Organic Compound multilevel sensor',
                    path: '/0/49/39/',
                    type: 4,
                },
                {
                    device: 20,
                    id: 106,
                    name: 'Home health notification',
                    path: '/0/113/13/',
                    type: 4,
                },
                {
                    device: 20,
                    id: 107,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 20,
                    id: 108,
                    name: 'Dew point multilevel sensor',
                    path: '/0/49/11/',
                    type: 7,
                },
                {
                    device: 20,
                    id: 109,
                    name: 'Relative humidity multilevel sensor',
                    path: '/0/49/5/',
                    type: 8,
                },
                {
                    device: 21,
                    id: 110,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 21,
                    id: 111,
                    name: 'Valveposition multilevel switch',
                    path: '/0/38/',
                    type: 3,
                },
                {
                    device: 21,
                    id: 112,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
                {
                    device: 32,
                    id: 115,
                    name: 'Digital output (DO1)',
                    path: '/1/37/',
                    type: 1,
                },
                {
                    device: 32,
                    id: 122,
                    name: 'Color switch',
                    path: '/5/51/',
                    type: 3,
                },
                {
                    device: 22,
                    id: 129,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 23,
                    id: 133,
                    name: 'Tilt binary sensor',
                    path: '/0/48/11/',
                    type: 2,
                },
                {
                    device: 23,
                    id: 134,
                    name: 'Lock access control notification',
                    path: '/0/113/6/',
                    type: 2,
                },
                {
                    device: 24,
                    id: 136,
                    name: 'Battery level',
                    path: '/0/128/',
                    type: 4,
                },
                {
                    device: 24,
                    id: 137,
                    name: 'Valveposition multilevel switch',
                    path: '/0/38/',
                    type: 3,
                },
                {
                    device: 24,
                    id: 138,
                    name: 'Temperature multilevel sensor',
                    path: '/0/49/1/',
                    type: 7,
                },
            ],
            multiserver: {
                encryptedPassword:
                    'OBSptCKCuMYxPY/Rn3b1MjJ8q1kgwMDkdPgVELGfvOtDDYJP29NJ7m9pm4cBrjIsWFGAyEukc4RlEkj+2tq24XGUQUBYgueLPgqnS2c8I0EGW/cjb8hfeUdAcNNQ5DV6F4bRQvvhyDryF5T+bTFaOcM6AxY2JKazyefftuczWY1/7mNQeyzTTKgUkYaPstcAsxPWUQNAav7yh2BAPqk0dE5sqDbTR2UN14uKzklWZEltTaQdQq26Lk5XCEXrkvffmQb1d3cyrFEa5HRdWWxNfzew3VZ+U97fzSYIrlvRrEAvrhQy5wJXQFE1RV8DG/uNyRqXm6ZYXKAgsOYH9GfFzi2FoznOrkIoTQTt4fJy+bygc3FmRAGv06EFzRnR0DkfWx3wdPLjl1VT1EzYDSRBrglTxDR62lng/556bWWlRZVTdnmAOpuajFzRZKSfdU3DxkKLQZ6faB811yi1egEFIFFMP4crKkBaUWT6NlCFtLiWgpWA0UowIDP17k5VvCzlyRT5FzXoJhwdmJtGaFkQrWOEqDvwLAKcdNjKIkN48rtLwfPITTkqtYlW+nnGFtwt9b8pupde/FofMGaDMCdZjWw+jqiJldADkAylcV6sC8idCZksJwWjBN1xN/1Qqrix/d1mC5mrHtkuo16l/FHf6XmvOiAu/xTZ6pUp0DW5efo=',
                guid: '575f038a-d2c1-491d-a4a5-3d6d77cbde72',
                localGatewaysReady: true,
                projectId: '0ecb4e6e1d5be843d1abecd5758e6740',
                remotes: [
                    {
                        connected: false,
                        guid: 'c316dd3a-2395-409e-8272-056445b5cabd',
                        ip: '',
                        localGatewaysReady: false,
                        serial: '130A09100100000023430077D1',
                        version: '',
                    },
                ],
                role: 'master',
                user: '__admin',
            },
            notifications: [
                {
                    deviceid: 4,
                    devicename: '',
                    id: 2,
                    iocategory: 8,
                    objectId: 5,
                    room: 'salon',
                    roomcategory: 4,
                    text: '',
                    type: 1,
                    ugpushconf: {
                        Groups: [1, 1],
                        Users: [1, 3],
                    },
                },
            ],
            objects: {
                items: [
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 1,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 1,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 2,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 1,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 50,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 3,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 4,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 1,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 5,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 1,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 50,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 6,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 7,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 8,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 9,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 10,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 1,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 11,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 1,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 12,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 1,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 13,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 1,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 1,
                        name: 'Blinds1',
                        properties: [
                            {
                                id: 1,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 2,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 3,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 4,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 5,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 6,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 7,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 8,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24001',
                            },
                            {
                                id: 9,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 10,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 11,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 12,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 13,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 14,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 15,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 5,
                        roomid: 1,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 143,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 144,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 50,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 145,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 146,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 147,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 2,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 50,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 148,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 149,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 150,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 151,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 152,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 2,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 153,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 2,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 154,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 2,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 155,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 2,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 2,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 60,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 61,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 62,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 63,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 64,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 65,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 66,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 67,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 68,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 69,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 70,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 71,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 72,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 73,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 74,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: 3,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 156,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 157,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 3,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 158,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 159,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 160,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 161,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 162,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Scenes: [
                                    {
                                        Name: 'Off',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 0,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#000000000000000000',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: false,
                                            },
                                            {
                                                Brightness: 0,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#000000000000000000',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Red',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#64000000000089a8ff',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: false,
                                            },
                                            {
                                                Brightness: 47,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#2F0000000000ff7fda',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Blue',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#64000000000000A6EA',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#6400000000000D1FFF',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Green',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#64000000000000FF00',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#64000000000034FF07',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Pink2',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#640000000000e218ba',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#640000000000FF0ADF',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Orange',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 53,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#350000000000f5a623',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: false,
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#640000000000FFC909',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Turqoise',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 81,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#51000000000050e3c2',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Brightness: 31,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#1F000000000050e3c2',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'colors',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 36,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#240000000000bd10e0',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Brightness: 81,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#510000000000f8e71c',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Mood 1',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 71,
                                                Description: 'Radio Bulb',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#470000000000FF7676',
                                            },
                                            {
                                                Description: 'Radio Socket',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: false,
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'Touch FT DIN SIX',
                                                LightType: 6,
                                                Name: 'AQ3',
                                                Value: '#64000000000000CFC1',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 163,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'light.sceneConfig',
                                objectid: 3,
                                range: [],
                                remote: true,
                                type: 9,
                                writeprotect: false,
                            },
                            {
                                customname: 'Radio Bulb',
                                id: 164,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value0',
                                objectid: 3,
                                range: ['10', '100'],
                                remote: true,
                                type: 3,
                                value: '#000000000000000000',
                                writeprotect: false,
                            },
                            {
                                customname: 'Radio Socket',
                                id: 165,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value1',
                                objectid: 3,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: 'Touch FT DIN SIX',
                                id: 166,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value2',
                                objectid: 3,
                                range: ['10', '100'],
                                remote: true,
                                type: 29,
                                value: '#000000000000000000',
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 3,
                        name: 'Lighting',
                        properties: [
                            {
                                id: 75,
                                pinid: 'fc0f688783acc959f0fc678eed9a35c5',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 76,
                                pinid: '9ab0c26420545b5e89b4db810aa66bca',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 77,
                                pinid: 'be0dd8d2546f42b7f97d941e238b8e23',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 78,
                                pinid: '312a6c543cc5aa30a0374ab3f2cc55df',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 79,
                                pinid: '4305706ea659aa3d5d3d12ffedeb5855',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 5,
                        roomid: 6,
                        type: 17,
                        visualisationpw: false,
                    },
                    {
                        category: 5,
                        datapoints: [
                            {
                                customname: '',
                                id: 35,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualHourEnergy',
                                objectid: 4,
                                range: ['', ''],
                                remote: true,
                                type: 11,
                                value: 0.0008339999999999999,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 36,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDayEnergy',
                                objectid: 4,
                                range: ['', ''],
                                remote: true,
                                type: 12,
                                value: 0.6433974610000011,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 37,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualMonthEnergy',
                                objectid: 4,
                                range: ['', ''],
                                remote: true,
                                type: 13,
                                value: 14.712240281299716,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 38,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualYearEnergy',
                                objectid: 4,
                                range: ['', ''],
                                remote: true,
                                type: 14,
                                value: 36.8406736937977,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 39,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualReset',
                                objectid: 4,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: 'AC unit ',
                                deviceid: 3,
                                id: 40,
                                ioid: 9,
                                isconfiguration: false,
                                name: 'EnergyStatisticDay',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 12,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'AC unit ',
                                deviceid: 3,
                                id: 41,
                                ioid: 9,
                                isconfiguration: false,
                                name: 'EnergyStatisticHour',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 11,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'AC unit ',
                                deviceid: 3,
                                id: 42,
                                ioid: 9,
                                isconfiguration: false,
                                name: 'EnergyStatisticMonth',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 13,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'AC unit ',
                                deviceid: 3,
                                id: 43,
                                ioid: 9,
                                isconfiguration: false,
                                name: 'EnergyStatisticYear',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 14,
                                value: 7,
                                writeprotect: true,
                            },
                            {
                                customname: 'Lights',
                                deviceid: 28,
                                id: 44,
                                ioid: 64,
                                isconfiguration: false,
                                name: 'EnergyStatisticDay',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 12,
                                value: 0.52518769,
                                writeprotect: true,
                            },
                            {
                                customname: 'Lights',
                                deviceid: 28,
                                id: 45,
                                ioid: 64,
                                isconfiguration: false,
                                name: 'EnergyStatisticHour',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 11,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Lights',
                                deviceid: 28,
                                id: 46,
                                ioid: 64,
                                isconfiguration: false,
                                name: 'EnergyStatisticMonth',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 13,
                                value: 12.51269271099999,
                                writeprotect: true,
                            },
                            {
                                customname: 'Lights',
                                deviceid: 28,
                                id: 47,
                                ioid: 64,
                                isconfiguration: false,
                                name: 'EnergyStatisticYear',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 14,
                                value: 100.4507765999999,
                                writeprotect: true,
                            },
                            {
                                customname: 'Solars',
                                deviceid: 29,
                                id: 48,
                                ioid: 67,
                                isconfiguration: false,
                                name: 'EnergyStatisticDay',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 12,
                                value: 0.10237177100000001,
                                writeprotect: true,
                            },
                            {
                                customname: 'Solars',
                                deviceid: 29,
                                id: 49,
                                ioid: 67,
                                isconfiguration: false,
                                name: 'EnergyStatisticHour',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 11,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Solars',
                                deviceid: 29,
                                id: 50,
                                ioid: 67,
                                isconfiguration: false,
                                name: 'EnergyStatisticMonth',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 13,
                                value: 1.7985325703,
                                writeprotect: true,
                            },
                            {
                                customname: 'Solars',
                                deviceid: 29,
                                id: 51,
                                ioid: 67,
                                isconfiguration: false,
                                name: 'EnergyStatisticYear',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 14,
                                value: 9.5268943657,
                                writeprotect: true,
                            },
                            {
                                customname: 'Car charger',
                                deviceid: 17,
                                id: 52,
                                ioid: 98,
                                isconfiguration: false,
                                name: 'EnergyStatisticDay',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 12,
                                value: 0.015838,
                                writeprotect: true,
                            },
                            {
                                customname: 'Car charger',
                                deviceid: 17,
                                id: 53,
                                ioid: 98,
                                isconfiguration: false,
                                name: 'EnergyStatisticHour',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 11,
                                value: 0.0008339999999999999,
                                writeprotect: true,
                            },
                            {
                                customname: 'Car charger',
                                deviceid: 17,
                                id: 54,
                                ioid: 98,
                                isconfiguration: false,
                                name: 'EnergyStatisticMonth',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 13,
                                value: 0.40101499999998363,
                                writeprotect: true,
                            },
                            {
                                customname: 'Car charger',
                                deviceid: 17,
                                id: 55,
                                ioid: 98,
                                isconfiguration: false,
                                name: 'EnergyStatisticYear',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 14,
                                value: 31.98593500000351,
                                writeprotect: true,
                            },
                            {
                                EnergyConfig: {
                                    IOConfigs: [
                                        {
                                            Color: '#396BA7FF',
                                            Diagram: 4,
                                            FlowChart: false,
                                            Graph: 3,
                                            HistoricalChart: true,
                                            Id: 9,
                                            Invert: false,
                                            Role: 2,
                                            Unit: 'kWh',
                                        },
                                        {
                                            Color: '#FFA500FF',
                                            Diagram: 3,
                                            FlowChart: false,
                                            Graph: 2,
                                            HistoricalChart: true,
                                            Id: 64,
                                            Invert: false,
                                            Role: 2,
                                            Unit: 'kWh',
                                        },
                                        {
                                            Color: '#6E8E2CFF',
                                            Diagram: 2,
                                            FlowChart: false,
                                            Graph: 1,
                                            HistoricalChart: true,
                                            Id: 67,
                                            Invert: false,
                                            Role: 1,
                                            Unit: 'kWh',
                                        },
                                        {
                                            Color: '#FF0000FF',
                                            Diagram: 4,
                                            FlowChart: false,
                                            Graph: 3,
                                            HistoricalChart: true,
                                            Id: 98,
                                            Invert: false,
                                            Role: 2,
                                            Unit: 'kWh',
                                        },
                                        {
                                            Color: '#396BA7FF',
                                            Diagram: 6,
                                            FlowChart: true,
                                            Graph: 3,
                                            HistoricalChart: true,
                                            Id: 10,
                                            Invert: false,
                                            Role: 2,
                                            Unit: 'W',
                                        },
                                        {
                                            Color: '#EB5E00FF',
                                            Diagram: 1,
                                            FlowChart: true,
                                            Graph: 1,
                                            HistoricalChart: true,
                                            Id: 63,
                                            Invert: false,
                                            Role: 2,
                                            Unit: 'W',
                                        },
                                        {
                                            Color: '#6E8E2CFF',
                                            Diagram: 4,
                                            FlowChart: true,
                                            Graph: 1,
                                            HistoricalChart: true,
                                            Id: 66,
                                            Invert: false,
                                            Role: 1,
                                            Unit: 'W',
                                        },
                                        {
                                            Color: '#FF0000FF',
                                            Diagram: 2,
                                            FlowChart: true,
                                            Graph: 3,
                                            HistoricalChart: true,
                                            Id: 97,
                                            Invert: false,
                                            Role: 2,
                                            Unit: 'W',
                                        },
                                    ],
                                    SingleEnergy: [
                                        {
                                            day: {
                                                id: 40,
                                                type: 12,
                                                value: '0',
                                            },
                                            hour: {
                                                id: 41,
                                                type: 11,
                                                value: '0',
                                            },
                                            ioId: 9,
                                            month: {
                                                id: 42,
                                                type: 13,
                                                value: '0',
                                            },
                                            year: {
                                                id: 43,
                                                type: 14,
                                                value: '7',
                                            },
                                        },
                                        {
                                            day: {
                                                id: 44,
                                                type: 12,
                                                value: '0.52518769',
                                            },
                                            hour: {
                                                id: 45,
                                                type: 11,
                                                value: '0',
                                            },
                                            ioId: 64,
                                            month: {
                                                id: 46,
                                                type: 13,
                                                value: '12.51269271099999',
                                            },
                                            year: {
                                                id: 47,
                                                type: 14,
                                                value: '100.4507765999999',
                                            },
                                        },
                                        {
                                            day: {
                                                id: 52,
                                                type: 12,
                                                value: '0.015838',
                                            },
                                            hour: {
                                                id: 53,
                                                type: 11,
                                                value: '0.0008339999999999999',
                                            },
                                            ioId: 98,
                                            month: {
                                                id: 54,
                                                type: 13,
                                                value: '0.40101499999998363',
                                            },
                                            year: {
                                                id: 55,
                                                type: 14,
                                                value: '31.98593500000351',
                                            },
                                        },
                                        {
                                            day: {
                                                id: 48,
                                                type: 12,
                                                value: '0.10237177100000001',
                                            },
                                            hour: {
                                                id: 49,
                                                type: 11,
                                                value: '0',
                                            },
                                            ioId: 67,
                                            month: {
                                                id: 50,
                                                type: 13,
                                                value: '1.7985325703',
                                            },
                                            year: {
                                                id: 51,
                                                type: 14,
                                                value: '9.5268943657',
                                            },
                                        },
                                    ],
                                    SinglePower: [97, 10, 63, 66],
                                    TotalEnergy: {
                                        Inputs: [9, 64, 67, 98],
                                        day: {
                                            id: 36,
                                            type: 12,
                                            value: '0.6433974610000011',
                                        },
                                        hour: {
                                            id: 35,
                                            type: 11,
                                            value: '0.0008339999999999999',
                                        },
                                        month: {
                                            id: 37,
                                            type: 13,
                                            value: '14.712240281299716',
                                        },
                                        year: {
                                            id: 38,
                                            type: 14,
                                            value: '36.8406736937977',
                                        },
                                    },
                                    TotalPower: [97, 10, 63, 66],
                                    guid: 'a00f7439-b4d3-4106-a898-b327b29b01ea',
                                },
                                customname: '',
                                id: 56,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 4,
                                range: [],
                                remote: true,
                                type: 25,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 4,
                        name: 'Energy monitor',
                        properties: [],
                        ranking: 4,
                        roomid: 6,
                        type: 28,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 82,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmAlarmSystem',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 83,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmAlarmSystemDelayed',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 84,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmWithoutPresenceDetectors',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 85,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDisarmAlarmSystem',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 86,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarmSystemIsArmed',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: true,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 87,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmedWithoutPresenceDetectors',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 88,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarm',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 89,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAcivationCountdown',
                                objectid: 5,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 45,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 90,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsAlarm',
                                objectid: 5,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: true,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 5,
                        name: 'Burglar Alarm',
                        properties: [
                            {
                                id: 27,
                                pinid: '5e2481a0a4b3dcd154e1a3400a519457',
                                readonly: false,
                                value: '45',
                            },
                            {
                                id: 28,
                                pinid: '7ee2bc6a7b7dd741baee80b5537ef898',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 29,
                                pinid: '6ddccfc197b1766a550c7537ab175ca8',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 2,
                        type: 3,
                        visualisationpw: false,
                    },
                    {
                        category: 12,
                        datapoints: [
                            {
                                customname: '',
                                id: 108,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualLowBatteryLimit',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 50,
                                writeprotect: false,
                            },
                            {
                                customname: 'Radio MultiSensor',
                                deviceid: 4,
                                id: 109,
                                ioid: 13,
                                isconfiguration: false,
                                name: 'Radio MultiSensor',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Leakage Sensor 1',
                                deviceid: 5,
                                id: 110,
                                ioid: 20,
                                isconfiguration: false,
                                name: 'Radio Leakage Sensor 1',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Valve 1',
                                deviceid: 6,
                                id: 111,
                                ioid: 23,
                                isconfiguration: false,
                                name: 'Radio Valve 1',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Window /Door Contact 1',
                                deviceid: 11,
                                id: 112,
                                ioid: 72,
                                isconfiguration: false,
                                name: 'Radio Window /Door Contact 1',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Window /Door Contact 2 long name od device test 123456',
                                deviceid: 12,
                                id: 113,
                                ioid: 75,
                                isconfiguration: false,
                                name: 'Radio Window /Door Contact 2 long name od device test 123456',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: 'Sensor Notification 3',
                                deviceid: 13,
                                id: 114,
                                ioid: 78,
                                isconfiguration: false,
                                name: 'Sensor Notification 3',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Leakage Sensor 2',
                                deviceid: 16,
                                id: 115,
                                ioid: 93,
                                isconfiguration: false,
                                name: 'Radio Leakage Sensor 2',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Valve 2',
                                deviceid: 19,
                                id: 116,
                                ioid: 101,
                                isconfiguration: false,
                                name: 'Radio Valve 2',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Valve 3',
                                deviceid: 21,
                                id: 117,
                                ioid: 110,
                                isconfiguration: false,
                                name: 'Radio Valve 3',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: 'Radio Siren 1',
                                deviceid: 22,
                                id: 118,
                                ioid: 129,
                                isconfiguration: false,
                                name: 'Radio Siren 1',
                                objectid: 6,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 6,
                        name: 'Central batteries controller',
                        properties: [],
                        ranking: 0,
                        roomid: 2,
                        type: 5,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 129,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAllOff',
                                objectid: 7,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 130,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAllOn',
                                objectid: 7,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 7,
                        name: 'Central light controller',
                        properties: [],
                        ranking: 3,
                        roomid: 2,
                        type: 9,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 131,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualCentralSchedulerOn',
                                objectid: 8,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgramList: [
                                    {
                                        Name: 'TimeProgram1',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '23:02',
                                            },
                                        ],
                                        days: [4, 5, 6],
                                    },
                                    {
                                        Name: 'TimeProgram2',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'CompletelyDown',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Shadowing',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '00:00',
                                            },
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'CompletelyDown',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Shadowing',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: '1',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Off',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '01:03',
                                            },
                                        ],
                                        days: [3],
                                    },
                                    {
                                        Name: 'TimeProgram3',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'CompletelyDown',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'CompletelyUp',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: '8',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: '6',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '16:55',
                                            },
                                        ],
                                        days: [1, 2],
                                    },
                                    {
                                        Name: 'TimeProgram4',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '00:00',
                                            },
                                        ],
                                        days: [],
                                    },
                                    {
                                        Name: 'TimeProgram5',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '00:00',
                                            },
                                        ],
                                        days: [],
                                    },
                                    {
                                        Name: 'TimeProgram6',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '00:00',
                                            },
                                        ],
                                        days: [],
                                    },
                                    {
                                        Name: 'TimeProgram7',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: true,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: true,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: true,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: true,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: true,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: true,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: true,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: true,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: true,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: true,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: true,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: true,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: true,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: true,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: true,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: true,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: true,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: true,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: true,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: true,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: true,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: true,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '00:00',
                                            },
                                        ],
                                        days: [],
                                    },
                                ],
                                customname: '',
                                id: 132,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 8,
                                range: [],
                                remote: true,
                                type: 27,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 8,
                        name: 'Central scheduler',
                        properties: [],
                        ranking: 5,
                        roomid: 2,
                        type: 29,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 141,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetActive',
                                objectid: 10,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                AstroConfig: {
                                    FunctionBlocks: [
                                        {
                                            Dawn: 'CompletelyDown',
                                            DawnDelay: 0,
                                            ID: 'c',
                                            ObjectID: 1,
                                            Sunrise: 'Shadowing',
                                            SunriseDelay: 0,
                                            Sunset: 'CompletelyDown',
                                            SunsetDelay: 0,
                                            Twilight: 'Shadowing',
                                            TwilightDelay: 0,
                                            Type: 2,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'e3W',
                                            ObjectID: 5,
                                            Sunrise: 'ActivateDelayed',
                                            SunriseDelay: 0,
                                            Sunset: 'Activate',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 3,
                                        },
                                        {
                                            Dawn: 'Shadowing',
                                            DawnDelay: 0,
                                            ID: 'gGa',
                                            ObjectID: 2,
                                            Sunrise: 'Shadowing',
                                            SunriseDelay: 0,
                                            Sunset: 'CompletelyDown',
                                            SunsetDelay: 0,
                                            Twilight: 'Shadowing',
                                            TwilightDelay: 0,
                                            Type: 2,
                                        },
                                        {
                                            Dawn: '4',
                                            DawnDelay: 0,
                                            ID: 'gGP',
                                            ObjectID: 3,
                                            Sunrise: '2',
                                            SunriseDelay: 0,
                                            Sunset: '5',
                                            SunsetDelay: 0,
                                            Twilight: '6',
                                            TwilightDelay: 0,
                                            Type: 17,
                                        },
                                        {
                                            Dawn: 'On',
                                            DawnDelay: 0,
                                            ID: 'cNu',
                                            ObjectID: 12,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 22,
                                        },
                                        {
                                            Dawn: 'On',
                                            DawnDelay: 0,
                                            ID: 'jV0',
                                            ObjectID: 20,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'Off',
                                            TwilightDelay: 0,
                                            Type: 24,
                                        },
                                        {
                                            Dawn: 'On',
                                            DawnDelay: 0,
                                            ID: 'krJ',
                                            ObjectID: 21,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 24,
                                        },
                                        {
                                            Dawn: 'On',
                                            DawnDelay: 0,
                                            ID: 'krU',
                                            ObjectID: 22,
                                            Sunrise: 'Off',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'Off',
                                            TwilightDelay: 0,
                                            Type: 24,
                                        },
                                        {
                                            Dawn: 'On',
                                            DawnDelay: 0,
                                            ID: 'ks5',
                                            ObjectID: 23,
                                            Sunrise: 'Off',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 24,
                                        },
                                        {
                                            Dawn: '77',
                                            DawnDelay: 0,
                                            ID: 'ksg',
                                            ObjectID: 24,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: '27',
                                            SunsetDelay: 0,
                                            Twilight: '89',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: '66',
                                            DawnDelay: 0,
                                            ID: 'ksz',
                                            ObjectID: 25,
                                            Sunrise: '0',
                                            SunriseDelay: 0,
                                            Sunset: '12',
                                            SunsetDelay: 0,
                                            Twilight: '0',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: '55',
                                            DawnDelay: 0,
                                            ID: 'ksS',
                                            ObjectID: 26,
                                            Sunrise: '62',
                                            SunriseDelay: 0,
                                            Sunset: '14',
                                            SunsetDelay: 0,
                                            Twilight: '20',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: '44',
                                            DawnDelay: 0,
                                            ID: 'ktb',
                                            ObjectID: 27,
                                            Sunrise: '48',
                                            SunriseDelay: 0,
                                            Sunset: '27',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: 'Shadowing',
                                            DawnDelay: 0,
                                            ID: 'kD7',
                                            ObjectID: 29,
                                            Sunrise: 'Shadowing',
                                            SunriseDelay: 0,
                                            Sunset: 'CompletelyDown',
                                            SunsetDelay: 0,
                                            Twilight: 'CompletelyDown',
                                            TwilightDelay: 0,
                                            Type: 2,
                                        },
                                        {
                                            Dawn: 'CompletelyDown',
                                            DawnDelay: 0,
                                            ID: 'kDN',
                                            ObjectID: 30,
                                            Sunrise: 'CompletelyDown',
                                            SunriseDelay: 0,
                                            Sunset: 'Shadowing',
                                            SunsetDelay: 0,
                                            Twilight: 'Shadowing',
                                            TwilightDelay: 0,
                                            Type: 2,
                                        },
                                        {
                                            Dawn: '33',
                                            DawnDelay: 0,
                                            ID: 'kEw',
                                            ObjectID: 31,
                                            Sunrise: '37',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: '0',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'kEP',
                                            ObjectID: 32,
                                            Sunrise: '0',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 17,
                                        },
                                        {
                                            Dawn: 'On',
                                            DawnDelay: 0,
                                            ID: 'ktu',
                                            ObjectID: 34,
                                            Sunrise: 'Off',
                                            SunriseDelay: 0,
                                            Sunset: 'Off',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 22,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'kAO',
                                            ObjectID: 36,
                                            Sunrise: 'Activate',
                                            SunriseDelay: 0,
                                            Sunset: 'Activate',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 18,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'm85',
                                            ObjectID: 55,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'Off',
                                            TwilightDelay: 0,
                                            Type: 22,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'm3b',
                                            ObjectID: 44,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 19,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'm3g',
                                            ObjectID: 45,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 20,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'm53',
                                            ObjectID: 47,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 25,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'c',
                                            MultiServerData: {
                                                DevID: 14,
                                                ID: 521,
                                                IDOff: 527,
                                                IDOn: 526,
                                            },
                                            ObjectID: 57,
                                            Sunrise: 'Shadowing',
                                            SunriseDelay: 0,
                                            Sunset: 'Shadowing',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 2,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'cNr',
                                            MultiServerData: {
                                                DevID: 14,
                                                ID: 533,
                                            },
                                            ObjectID: 58,
                                            Sunrise: '67',
                                            SunriseDelay: 0,
                                            Sunset: '16',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'dXh',
                                            MultiServerData: {
                                                DevID: 14,
                                                IDOff: 544,
                                                IDOn: 545,
                                            },
                                            ObjectID: 59,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 24,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'eCO',
                                            MultiServerData: {
                                                DevID: 14,
                                                IDOff: 549,
                                                IDOn: 550,
                                            },
                                            ObjectID: 60,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 22,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'eMJ',
                                            MultiServerData: {
                                                ArmAlarmDelayed: 553,
                                                DevID: 14,
                                                ID: 554,
                                                IDOff: 555,
                                                IDOn: 552,
                                            },
                                            ObjectID: 61,
                                            Sunrise: 'ActivateWithoutPresence',
                                            SunriseDelay: 0,
                                            Sunset: 'Activate',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 3,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'gd1',
                                            MultiServerData: {
                                                DevID: 14,
                                                IDOff: 568,
                                                IDOn: 567,
                                            },
                                            ObjectID: 63,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'On',
                                            SunsetDelay: 0,
                                            Twilight: 'On',
                                            TwilightDelay: 0,
                                            Type: 25,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'mbK',
                                            ObjectID: 65,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'CompletelyUp',
                                            TwilightDelay: 0,
                                            Type: 2,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'jdj',
                                            ObjectID: 16,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 27,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'jUk',
                                            ObjectID: 17,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 27,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'jUy',
                                            ObjectID: 18,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 27,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'jUM',
                                            ObjectID: 19,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 27,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'j0Q',
                                            ObjectID: 68,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Off',
                                            TwilightDelay: 0,
                                            Type: 19,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'j2B',
                                            ObjectID: 69,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Off',
                                            TwilightDelay: 0,
                                            Type: 20,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'j4M',
                                            ObjectID: 73,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Off',
                                            TwilightDelay: 0,
                                            Type: 25,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'jd3',
                                            ObjectID: 74,
                                            Sunrise: 'On',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 22,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'glo',
                                            MultiServerData: {
                                                DevID: 14,
                                                ID: 570,
                                            },
                                            ObjectID: 77,
                                            Sunrise: '2',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 17,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'kFs',
                                            ObjectID: 87,
                                            Sunrise: '33',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'mfK',
                                            ObjectID: 97,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 22,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'mgo',
                                            ObjectID: 98,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'mgH',
                                            ObjectID: 99,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                        {
                                            Dawn: 'Ignore',
                                            DawnDelay: 0,
                                            ID: 'mh0',
                                            ObjectID: 100,
                                            Sunrise: 'Ignore',
                                            SunriseDelay: 0,
                                            Sunset: 'Ignore',
                                            SunsetDelay: 0,
                                            Twilight: 'Ignore',
                                            TwilightDelay: 0,
                                            Type: 12,
                                        },
                                    ],
                                    Latitude: '-5.58794182897843',
                                    Longitude: '148.364125837977',
                                },
                                customname: '',
                                id: 142,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 10,
                                range: [],
                                remote: true,
                                type: 33,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 10,
                        name: 'Astro function',
                        properties: [
                            {
                                id: 55,
                                pinid: '48c5d3c23b0d61a1fb9589dfab0b0834',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 56,
                                pinid: 'c3a35de847b15c16dd0b6a18dbba417b',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 57,
                                pinid: '7e8cbfad718bdb28c101e298503d908c',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 58,
                                pinid: '67934c83996b2a11c2c16879ada9fae3',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 59,
                                pinid: 'b771613459c3affa9c6e8237ccd21e79',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 4,
                        roomid: 2,
                        type: 34,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                Monitor: [
                                    {
                                        Configuration: {},
                                        IOid: 7,
                                        Log: true,
                                        Name: ' - color 1 - ',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 8,
                                        Log: true,
                                        Name: 'Digital output DO2 NEW@',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 10,
                                        Log: true,
                                        Name: 'AC unit',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 9,
                                        Log: true,
                                        Name: 'AC unit ',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 11,
                                        Log: true,
                                        Name: 'test2 Room controller Room temperature',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 12,
                                        Log: true,
                                        Name: 'test2 Room controller Relative humidity room air',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 13,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 17,
                                        Log: true,
                                        Name: 'Luminance multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 19,
                                        Log: true,
                                        Name: 'Ultraviolet multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 14,
                                        Log: true,
                                        Name: 'Binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 15,
                                        Log: true,
                                        Name: 'Burglar notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 16,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 18,
                                        Log: true,
                                        Name: 'Relative humidity multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 20,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 21,
                                        Log: true,
                                        Name: 'Water binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 22,
                                        Log: true,
                                        Name: 'Water notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 23,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 24,
                                        Log: true,
                                        Name: 'Valveposition multilevel switch',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 25,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 26,
                                        Log: true,
                                        Name: 'Dew point multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 27,
                                        Log: true,
                                        Name: 'CO2-level multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 28,
                                        Log: true,
                                        Name: 'Volatile Organic Compound multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 29,
                                        Log: true,
                                        Name: 'Home health notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 30,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 31,
                                        Log: true,
                                        Name: 'Relative humidity multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 32,
                                        Log: true,
                                        Name: 'Dew point multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 33,
                                        Log: true,
                                        Name: 'CO2-level multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 34,
                                        Log: true,
                                        Name: 'Volatile Organic Compound multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 35,
                                        Log: true,
                                        Name: 'Home health notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 36,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 37,
                                        Log: true,
                                        Name: 'Relative humidity multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 38,
                                        Log: true,
                                        Name: 'Group output 1',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 39,
                                        Log: true,
                                        Name: 'Group output 1',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 40,
                                        Log: true,
                                        Name: 'Blinds position report',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 41,
                                        Log: true,
                                        Name: 'Digital output down',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 42,
                                        Log: true,
                                        Name: 'Digital output up',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 43,
                                        Log: true,
                                        Name: 'Blinds position report',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 44,
                                        Log: true,
                                        Name: 'Digital output down',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 45,
                                        Log: true,
                                        Name: 'Digital output up',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 46,
                                        Log: true,
                                        Name: 'Analog input (AI1)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 47,
                                        Log: true,
                                        Name: 'Analog input (AI2)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 48,
                                        Log: true,
                                        Name: 'Digital input (DI1)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 49,
                                        Log: true,
                                        Name: 'Digital input (DI2)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 50,
                                        Log: true,
                                        Name: 'Digital input (DI3)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 51,
                                        Log: true,
                                        Name: 'Digital input (DI4)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 52,
                                        Log: true,
                                        Name: 'Button 1',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 53,
                                        Log: true,
                                        Name: 'Button 2',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 54,
                                        Log: true,
                                        Name: 'Button 3',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 55,
                                        Log: true,
                                        Name: 'Button 4',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 56,
                                        Log: true,
                                        Name: 'Button 5',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 57,
                                        Log: true,
                                        Name: 'salon_Lighting Lighting Output 3',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 58,
                                        Log: true,
                                        Name: 'Temperature sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 59,
                                        Log: true,
                                        Name: 'Relative humidity sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 61,
                                        Log: true,
                                        Name: 'salon_Lighting Button Output',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 62,
                                        Log: true,
                                        Name: 'salon_Lighting Switch Output',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 63,
                                        Log: true,
                                        Name: 'Lights',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 64,
                                        Log: true,
                                        Name: 'Lights',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 65,
                                        Log: true,
                                        Name: 'Analog output',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 66,
                                        Log: true,
                                        Name: 'Solars',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 67,
                                        Log: true,
                                        Name: 'Solars',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 68,
                                        Log: true,
                                        Name: 'Channel R',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 69,
                                        Log: true,
                                        Name: 'Channel G',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 70,
                                        Log: true,
                                        Name: 'Channel B',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 71,
                                        Log: true,
                                        Name: 'Channel W',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 72,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 73,
                                        Log: true,
                                        Name: 'Lock access control notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 74,
                                        Log: true,
                                        Name: 'Door/Window binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 75,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 76,
                                        Log: true,
                                        Name: 'Lock access control notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 77,
                                        Log: true,
                                        Name: 'Door/Window binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 78,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 79,
                                        Log: true,
                                        Name: 'Tilt binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 80,
                                        Log: true,
                                        Name: 'Lock access control notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 81,
                                        Log: true,
                                        Name: 'Burglar notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 82,
                                        Log: true,
                                        Name: 'Balcony_Blinds_Blinds position report',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 83,
                                        Log: true,
                                        Name: 'Balcony_Blinds_Slats position report',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 84,
                                        Log: true,
                                        Name: 'Balcony_Blinds_Blinds target position',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 85,
                                        Log: true,
                                        Name: 'Balcony_Blinds_Slats target position',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 86,
                                        Log: true,
                                        Name: 'Digital input (DI1)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 87,
                                        Log: true,
                                        Name: 'Digital input (DI2)',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 88,
                                        Log: true,
                                        Name: 'Button 1',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 89,
                                        Log: true,
                                        Name: 'Button 2',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 90,
                                        Log: true,
                                        Name: 'Button 3',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 91,
                                        Log: true,
                                        Name: 'Button 4',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 92,
                                        Log: true,
                                        Name: 'Button 5',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 93,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 94,
                                        Log: true,
                                        Name: 'Water binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 95,
                                        Log: true,
                                        Name: 'Water notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 96,
                                        Log: true,
                                        Name: 'salon_Lighting Timer switch Output',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 97,
                                        Log: true,
                                        Name: 'Car charger',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 98,
                                        Log: true,
                                        Name: 'Car charger',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 99,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 100,
                                        Log: true,
                                        Name: 'Relative humidity multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                ],
                                customname: '',
                                id: 14,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'monitor.monitorConfig',
                                objectid: 11,
                                range: [],
                                remote: true,
                                type: 31,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 11,
                        name: 'Monitor',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 31,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 15,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 12,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 16,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 12,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 17,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 12,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 18,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 12,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '08:10',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '09:21',
                                                    Value: false,
                                                },
                                                {
                                                    Time: '11:18',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '22:00',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 6],
                                        },
                                        {
                                            Name: 'TimeProgram2',
                                            SwitchPoints: [
                                                {
                                                    Time: '04:35',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '10:19',
                                                    Value: false,
                                                },
                                                {
                                                    Time: '18:17',
                                                    Value: true,
                                                },
                                            ],
                                            days: [4, 5],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 19,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 12,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 12,
                        name: 'Timer switch',
                        properties: [
                            {
                                id: 16,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 1,
                        roomid: -1,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 4,
                        datapoints: [
                            {
                                customname: '',
                                id: 91,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 13,
                                range: ['0', '1', '2', '3', '4', '5'],
                                remote: true,
                                type: 16,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 92,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeHeating',
                                objectid: 13,
                                range: ['8', '32'],
                                remote: true,
                                type: 2,
                                value: 32,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 93,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsRoomTemperature',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: 2,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 95,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 13,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 15,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 96,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeCooling',
                                objectid: 13,
                                range: ['18', '40'],
                                remote: true,
                                type: 2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 97,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsHoliday',
                                objectid: 13,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 98,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsEconomyMode',
                                objectid: 13,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 99,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutdoorTemperature',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -32768,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 100,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyHeatingTemperature',
                                objectid: 13,
                                range: ['0', '40'],
                                remote: true,
                                type: -2,
                                value: 16,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 101,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyCoolingTemperature',
                                objectid: 13,
                                range: ['18', '28'],
                                remote: true,
                                type: -2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 102,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualSetpoint',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 8,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 103,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRequiredOpMode',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 104,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDewpointTemperature',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 105,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualValvePositionCont',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 106,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualOpMode',
                                objectid: 13,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: true,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 27,
                                                    Heating: 8,
                                                    Time: '09:20',
                                                },
                                                {
                                                    Cooling: 28,
                                                    Heating: 32,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 4, 5, 6],
                                        },
                                        {
                                            Name: 'TimeProgram2',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 37,
                                                    Heating: 36,
                                                    Time: '06:01',
                                                },
                                            ],
                                            days: [1, 2],
                                        },
                                        {
                                            Name: 'TimeProgram3',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 32,
                                                    Heating: 15,
                                                    Time: '19:07',
                                                },
                                            ],
                                            days: [3],
                                        },
                                        {
                                            Name: 'TimeProgram4',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 20,
                                                    Heating: 22,
                                                    Time: '12:00',
                                                },
                                            ],
                                            days: [],
                                        },
                                        {
                                            Name: 'TimeProgram5',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 20,
                                                    Heating: 22,
                                                    Time: '12:00',
                                                },
                                            ],
                                            days: [],
                                        },
                                        {
                                            Name: 'TimeProgram6',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 20,
                                                    Heating: 22,
                                                    Time: '12:00',
                                                },
                                            ],
                                            days: [],
                                        },
                                        {
                                            Name: 'TimeProgram7',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 20,
                                                    Heating: 22,
                                                    Time: '12:00',
                                                },
                                            ],
                                            days: [],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 107,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tph.timeProgram',
                                objectid: 13,
                                range: [],
                                remote: true,
                                type: 8,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 13,
                        name: 'Room controller',
                        properties: [
                            {
                                id: 30,
                                pinid: 'a70e5f4a2254aaf3a903b53ff91cc84b',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 31,
                                pinid: '6d6e20e2d6a7e82386ef15930d41824e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 32,
                                pinid: '405502632d68e338630ad9729e626c57',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 33,
                                pinid: '39fa7456dcd04f3d3731db82167564ff',
                                readonly: false,
                                value: '17',
                            },
                            {
                                id: 34,
                                pinid: '7e18b8e617aeae482e9f731784a2811a',
                                readonly: false,
                                value: '22',
                            },
                            {
                                id: 35,
                                pinid: 'a24eef4850f0f8094196540b46e01d76',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 36,
                                pinid: 'a61f83aa5674dfddcfcad8c6d242f657',
                                readonly: false,
                                value: '24',
                            },
                            {
                                id: 37,
                                pinid: '1dc82f53ee242ebd9018c9fc26ccfb71',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 38,
                                pinid: '66e5d781fdd48c9fef7e5adc2d0f9338',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 39,
                                pinid: '682d434e1d70ccc758edb3dddc34e38b',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 40,
                                pinid: '61e0bf0decc47bfdd5f48ac97b441d83',
                                readonly: false,
                                value: '8',
                            },
                            {
                                id: 41,
                                pinid: '9f3851d9ef370707f676dfa658a01eb7',
                                readonly: false,
                                value: '32',
                            },
                            {
                                id: 42,
                                pinid: 'eb142acaf5672a835394b7ac965edfc3',
                                readonly: false,
                                value: '18',
                            },
                            {
                                id: 43,
                                pinid: '79f85df54d84e2d4cf9914ebe095f758',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 44,
                                pinid: '8ef87a38a326aee9a7da85eed7b98932',
                                readonly: false,
                                value: '3',
                            },
                            {
                                id: 45,
                                pinid: '893e7553c75522fd9b60a0ccc1317848',
                                readonly: false,
                                value: '15',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 21,
                        visualisationpw: false,
                    },
                    {
                        category: 7,
                        datapoints: [
                            {
                                customname: '',
                                id: 183,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLight',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 184,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFan',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 185,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTrigger',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 186,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 187,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 188,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 16,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 16,
                        name: 'WC ventilation controller 1',
                        properties: [
                            {
                                id: 84,
                                pinid: 'dc842059fd8415315d13079f34e02200',
                                readonly: false,
                                value: '5',
                            },
                            {
                                id: 85,
                                pinid: '3daabf87af1090a3754d68fbb93d1cc7',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 86,
                                pinid: 'edcac574688c27b234b39bfe74d9405d',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 87,
                                pinid: 'cc1c1a5e2d1c16e0513dbe8c3b9a7e32',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 27,
                        visualisationpw: false,
                    },
                    {
                        category: 7,
                        datapoints: [
                            {
                                customname: '',
                                id: 189,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLight',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 190,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFan',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 191,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTrigger',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 192,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 193,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 194,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 17,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 17,
                        name: 'WC ventilation controller 2',
                        properties: [
                            {
                                id: 88,
                                pinid: 'dc842059fd8415315d13079f34e02200',
                                readonly: false,
                                value: '30',
                            },
                            {
                                id: 89,
                                pinid: '3daabf87af1090a3754d68fbb93d1cc7',
                                readonly: false,
                                value: '180',
                            },
                            {
                                id: 90,
                                pinid: 'edcac574688c27b234b39bfe74d9405d',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 91,
                                pinid: 'cc1c1a5e2d1c16e0513dbe8c3b9a7e32',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 27,
                        visualisationpw: false,
                    },
                    {
                        category: 7,
                        datapoints: [
                            {
                                customname: '',
                                id: 195,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLight',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 196,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFan',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 197,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTrigger',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 198,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 199,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 200,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 18,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 18,
                        name: 'WC ventilation controller 3',
                        properties: [
                            {
                                id: 92,
                                pinid: 'dc842059fd8415315d13079f34e02200',
                                readonly: false,
                                value: '30',
                            },
                            {
                                id: 93,
                                pinid: '3daabf87af1090a3754d68fbb93d1cc7',
                                readonly: false,
                                value: '180',
                            },
                            {
                                id: 94,
                                pinid: 'edcac574688c27b234b39bfe74d9405d',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 95,
                                pinid: 'cc1c1a5e2d1c16e0513dbe8c3b9a7e32',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 27,
                        visualisationpw: false,
                    },
                    {
                        category: 7,
                        datapoints: [
                            {
                                customname: '',
                                id: 201,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLight',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 202,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFan',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 203,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTrigger',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 204,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 205,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 206,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 19,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 19,
                        name: 'WC ventilation controller 4',
                        properties: [
                            {
                                id: 96,
                                pinid: 'dc842059fd8415315d13079f34e02200',
                                readonly: false,
                                value: '30',
                            },
                            {
                                id: 97,
                                pinid: '3daabf87af1090a3754d68fbb93d1cc7',
                                readonly: false,
                                value: '180',
                            },
                            {
                                id: 98,
                                pinid: 'edcac574688c27b234b39bfe74d9405d',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 99,
                                pinid: 'cc1c1a5e2d1c16e0513dbe8c3b9a7e32',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 27,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 207,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartTimer',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 208,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 209,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 210,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCountdown',
                                objectid: 20,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 60,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 211,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 212,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 213,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 20,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 20,
                        name: 'Staircase light switch',
                        properties: [
                            {
                                id: 100,
                                pinid: '8cc630e7a9a6ba6a9b7813c7679ad377',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 101,
                                pinid: 'c88c9061436916a3ef3e6393fb0cd661',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 102,
                                pinid: '0cb41e1f95ddb7ac731db1d7e2afa555',
                                readonly: false,
                                value: '0.5',
                            },
                            {
                                id: 103,
                                pinid: '8d30c2134835fc4edef49119345fa139',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 24,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 214,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartTimer',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 215,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: true,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 216,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 217,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCountdown',
                                objectid: 21,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 60,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 218,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 219,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 220,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 21,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 21,
                        name: 'Staircase light switch',
                        properties: [
                            {
                                id: 104,
                                pinid: '8cc630e7a9a6ba6a9b7813c7679ad377',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 105,
                                pinid: 'c88c9061436916a3ef3e6393fb0cd661',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 106,
                                pinid: '0cb41e1f95ddb7ac731db1d7e2afa555',
                                readonly: false,
                                value: '0.5',
                            },
                            {
                                id: 107,
                                pinid: '8d30c2134835fc4edef49119345fa139',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 24,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 221,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartTimer',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 222,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 223,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 224,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCountdown',
                                objectid: 22,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 60,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 225,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 226,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 227,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 22,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 22,
                        name: 'Staircase light switch',
                        properties: [
                            {
                                id: 108,
                                pinid: '8cc630e7a9a6ba6a9b7813c7679ad377',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 109,
                                pinid: 'c88c9061436916a3ef3e6393fb0cd661',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 110,
                                pinid: '0cb41e1f95ddb7ac731db1d7e2afa555',
                                readonly: false,
                                value: '0.5',
                            },
                            {
                                id: 111,
                                pinid: '8d30c2134835fc4edef49119345fa139',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 24,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 228,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartTimer',
                                objectid: 23,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 229,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 23,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: true,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 230,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 23,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 231,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCountdown',
                                objectid: 23,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 60,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 232,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 23,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 233,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 23,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 234,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 23,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 23,
                        name: 'Staircase light switch',
                        properties: [
                            {
                                id: 112,
                                pinid: '8cc630e7a9a6ba6a9b7813c7679ad377',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 113,
                                pinid: 'c88c9061436916a3ef3e6393fb0cd661',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 114,
                                pinid: '0cb41e1f95ddb7ac731db1d7e2afa555',
                                readonly: false,
                                value: '0.5',
                            },
                            {
                                id: 115,
                                pinid: '8d30c2134835fc4edef49119345fa139',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 24,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 235,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 236,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 24,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 89,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 237,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 24,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 89,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 238,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 239,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 240,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 241,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 242,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 24,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 24,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 116,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 117,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 118,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 119,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 120,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 121,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 122,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 123,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 5,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 243,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 244,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 25,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 245,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 25,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 246,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 247,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 248,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 249,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 250,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 25,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 25,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 124,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 125,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 126,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 127,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 128,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 129,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 130,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 131,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 251,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 252,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 26,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 20,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 253,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 26,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 20,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 254,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 255,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 256,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 257,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 258,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 26,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 26,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 132,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 133,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 134,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 135,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 136,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 137,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 138,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 139,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 259,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 27,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 260,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 27,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 27,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 261,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 27,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 27,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 262,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 27,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 263,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 27,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 264,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 27,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 265,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 27,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 266,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 27,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 27,
                        name: 'Dimmer',
                        properties: [
                            {
                                id: 140,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 141,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 142,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 143,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 144,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 145,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 146,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 147,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 278,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 29,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 280,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 281,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 29,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 283,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 284,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 285,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 286,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 287,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 29,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 288,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 29,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 289,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 29,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 290,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 29,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 0,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 29,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 151,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 152,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 153,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 154,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 155,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 156,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 157,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 158,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 159,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 160,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 161,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 162,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 163,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 164,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 165,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 291,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 30,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 293,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 30,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 294,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 30,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 100,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 296,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 30,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 297,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 30,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 298,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 30,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 299,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 30,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 300,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 30,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 301,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 30,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 302,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 30,
                                range: ['0', '1', '2'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 303,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 30,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 0,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 30,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 166,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 167,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 168,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 169,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 170,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 171,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 172,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 173,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 174,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 175,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 176,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 177,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 178,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 179,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 180,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 304,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 305,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 31,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 306,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 31,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 307,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 308,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 309,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 310,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 311,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 31,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 31,
                        name: 'Dimmer FT (solars simulator)',
                        properties: [
                            {
                                id: 181,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 182,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 183,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 184,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 185,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 186,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 187,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 188,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 3,
                        roomid: 6,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 312,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 313,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 32,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 314,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 315,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 316,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 317,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 318,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 32,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Scenes: [
                                    {
                                        Name: 'Off',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Red',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Blue',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Green',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'White',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '100',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'Purple',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'turqoise',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'orange',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'New scene',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Description: 'red',
                                                LightType: 1,
                                                Name: 'AQ1',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'bue',
                                                LightType: 1,
                                                Name: 'AQ2',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'green',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                            {
                                                Description: 'white',
                                                LightType: 1,
                                                Name: 'AQ4',
                                                Value: '100',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 319,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'light.sceneConfig',
                                objectid: 32,
                                range: [],
                                remote: true,
                                type: 9,
                                writeprotect: false,
                            },
                            {
                                customname: 'red',
                                id: 320,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value0',
                                objectid: 32,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'bue',
                                id: 321,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value1',
                                objectid: 32,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'green',
                                id: 322,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value2',
                                objectid: 32,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'white',
                                id: 323,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value3',
                                objectid: 32,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 32,
                        name: 'Light controller FT',
                        properties: [
                            {
                                id: 189,
                                pinid: 'fc0f688783acc959f0fc678eed9a35c5',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 190,
                                pinid: '9ab0c26420545b5e89b4db810aa66bca',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 191,
                                pinid: 'be0dd8d2546f42b7f97d941e238b8e23',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 192,
                                pinid: '312a6c543cc5aa30a0374ab3f2cc55df',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 193,
                                pinid: '4305706ea659aa3d5d3d12ffedeb5855',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 17,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 267,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 34,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 268,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 34,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 269,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 34,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 270,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 34,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '05:02',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '07:07',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 5, 6],
                                        },
                                        {
                                            Name: 'TimeProgram2',
                                            SwitchPoints: [
                                                {
                                                    Time: '10:49',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '10:50',
                                                    Value: false,
                                                },
                                            ],
                                            days: [1, 2, 3, 4],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 271,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 34,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 34,
                        name: 'Timer switch',
                        properties: [
                            {
                                id: 148,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 3,
                        roomid: -1,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 272,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 35,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CalendarSwitchPoints: [
                                        {
                                            Date: '2022-10-25T08:11:00Z',
                                            Value: false,
                                        },
                                        {
                                            Date: '2023-01-01T00:00:00Z',
                                            Value: true,
                                        },
                                        {
                                            Date: '2023-03-17T07:02:00Z',
                                            Value: false,
                                        },
                                        {
                                            Date: '2023-08-11T14:55:00Z',
                                            Value: true,
                                        },
                                        {
                                            Date: '2023-08-30T14:26:00Z',
                                            Value: true,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 273,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'calendar.dateProgram',
                                objectid: 35,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 35,
                        name: 'Calendar',
                        properties: [
                            {
                                id: 149,
                                pinid: 'b52f3381c31ccdb7a9672771e2251da0',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 5,
                        roomid: -1,
                        type: 4,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 274,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 36,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 275,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetActive',
                                objectid: 36,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 276,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualReset',
                                objectid: 36,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Presences: [
                                    {
                                        ID: 'gGP',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 46,
                                        MinDuration: 11,
                                        MinNumberActivations: 36,
                                        ObjID: 3,
                                        StartTime: '2000-01-02T20:50:00Z',
                                        StopTime: '2000-01-02T11:00:00Z',
                                    },
                                    {
                                        ID: 'jdj',
                                        MaxDuration: 2,
                                        MaxNumberActivations: 5,
                                        MinDuration: 1,
                                        MinNumberActivations: 4,
                                        ObjID: 16,
                                        StartTime: '2000-01-02T08:47:00Z',
                                        StopTime: '2000-01-02T08:57:00Z',
                                    },
                                    {
                                        ID: 'jUk',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 17,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'jUy',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 49,
                                        MinDuration: 15,
                                        MinNumberActivations: 15,
                                        ObjID: 18,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'jUM',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 19,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'jV0',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 20,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'krJ',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 21,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'krU',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 22,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'ks5',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 23,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'ksg',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 24,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'ksz',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 25,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'ksS',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 26,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'ktb',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 45,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 27,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'kEw',
                                        MaxDuration: 2,
                                        MaxNumberActivations: 4,
                                        MinDuration: 1,
                                        MinNumberActivations: 3,
                                        ObjID: 31,
                                        StartTime: '2000-01-02T09:56:00Z',
                                        StopTime: '2000-01-02T10:06:00Z',
                                    },
                                    {
                                        ID: 'kEP',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        ObjID: 32,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'cNr',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        MultiServerData: {
                                            DevID: 14,
                                            Off: 536,
                                            On: 535,
                                        },
                                        ObjID: 58,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'dXh',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        MultiServerData: {
                                            DevID: 14,
                                            Off: 544,
                                            On: 545,
                                        },
                                        ObjID: 59,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'eCO',
                                        MaxDuration: 45,
                                        MaxNumberActivations: 4,
                                        MinDuration: 15,
                                        MinNumberActivations: 1,
                                        MultiServerData: {
                                            DevID: 14,
                                            Off: 549,
                                            On: 550,
                                        },
                                        ObjID: 60,
                                        StartTime: '2000-01-02T18:00:00Z',
                                        StopTime: '2000-01-02T22:00:00Z',
                                    },
                                    {
                                        ID: 'gd1',
                                        MaxDuration: 7,
                                        MaxNumberActivations: 5,
                                        MinDuration: 1,
                                        MinNumberActivations: 3,
                                        MultiServerData: {
                                            DevID: 14,
                                            Off: 568,
                                            On: 567,
                                        },
                                        ObjID: 63,
                                        StartTime: '2000-01-02T22:04:00Z',
                                        StopTime: '2000-01-02T22:07:00Z',
                                    },
                                ],
                                customname: '',
                                id: 277,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 36,
                                range: [],
                                remote: true,
                                type: 26,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 36,
                        name: 'Presence simulation',
                        properties: [
                            {
                                id: 150,
                                pinid: '73f240c55ab991718976c8b02c9aa637',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 5,
                        roomid: -1,
                        type: 18,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 119,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarm',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 120,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualFireAlarm',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 121,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSmokeAlarm',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 122,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualWaterAlarm',
                                objectid: 37,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 37,
                        name: 'Fire and water alarm system',
                        properties: [
                            {
                                id: 46,
                                pinid: '58179165fe8a7a0d486fbd362620c890',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 47,
                                pinid: '0e70a48fd2fdd929a1a92dbd87d283e4',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 15,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 123,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 38,
                                range: ['6', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 124,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualEnable',
                                objectid: 38,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 125,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualCalibration',
                                objectid: 38,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 126,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualProportionalAmplification',
                                objectid: 38,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: 1.5,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 127,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetpoint',
                                objectid: 38,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: 100,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 128,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 38,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 38,
                        name: 'Constant Light Controller',
                        properties: [
                            {
                                id: 48,
                                pinid: '8d7b2cc20c6aa2ed3eb4054e64457d44',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 49,
                                pinid: '2959565f83c93d0583b8463b5d9eb4b4',
                                readonly: false,
                                value: '6',
                            },
                            {
                                id: 50,
                                pinid: '90188727255b8ce6e0d19314257dde28',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 51,
                                pinid: '958e8d9d4636f3f0226946e264c0863e',
                                readonly: false,
                                value: '1.5',
                            },
                            {
                                id: 52,
                                pinid: 'b412096df832c1c66cfa7b85a720cb8c',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 53,
                                pinid: 'd1ebbadf9698cd1ab70c6fc0dc4eb7df',
                                readonly: false,
                                value: 'false',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 33,
                        visualisationpw: false,
                    },
                    {
                        category: 3,
                        datapoints: [
                            {
                                customname: '',
                                id: 337,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerOpen',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 338,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerClose',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 339,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPosition',
                                objectid: 39,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 340,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsMoving',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 341,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 39,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 39,
                        name: 'Gate',
                        properties: [
                            {
                                id: 204,
                                pinid: 'b780ddd106ab95a5e42cf60fde71eb6f',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 205,
                                pinid: '7ba0af0f96b341184d22fc868717937e',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 206,
                                pinid: '4d4f4632abfa5edaad4c46dfa9cc89b9',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 207,
                                pinid: '5bc979396db5154a1987975c040362f4',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 208,
                                pinid: 'bd4ee83aa7d412ab669b3b39c1c6af83',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 209,
                                pinid: 'c4187fd5723c6a556daff8c9eb589461',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 210,
                                pinid: 'e5e1aabb049e6accdf4a5b975488e411',
                                readonly: false,
                                value: '3',
                            },
                            {
                                id: 211,
                                pinid: '966c9d30ff4e91775c58b78b2b07d5a3',
                                readonly: false,
                                value: 'true',
                            },
                            {
                                id: 212,
                                pinid: '25a7abf3b503c2f920c34dcbbbaad8ca',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 16,
                        visualisationpw: false,
                    },
                    {
                        category: 3,
                        datapoints: [
                            {
                                customname: '',
                                id: 342,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDoorBell',
                                objectid: 40,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 40,
                        name: 'Door controller',
                        properties: [
                            {
                                id: 213,
                                pinid: 'eb8f2f52ad4e0607d8c9cbd87fa417e8',
                                readonly: false,
                                value: '30',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 13,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 343,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerOpen',
                                objectid: 41,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 344,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerClose',
                                objectid: 41,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 345,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 41,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 41,
                        name: 'Central gate',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 7,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 346,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 42,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 347,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 42,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 348,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadowing',
                                objectid: 42,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 349,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralBlindsStop',
                                objectid: 42,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 42,
                        name: 'Central blinds',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 6,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 350,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfOpen',
                                objectid: 43,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 351,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfClosed',
                                objectid: 43,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 2,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 352,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfTiledWindows',
                                objectid: 43,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                deviceid: 11,
                                id: 353,
                                ioid: 74,
                                isconfiguration: false,
                                name: 'Door/Window binary sensor',
                                objectid: 43,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                deviceid: 12,
                                id: 354,
                                ioid: 77,
                                isconfiguration: false,
                                name: 'Door/Window binary sensor',
                                objectid: 43,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 43,
                        name: 'Window and door monitoring',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 11,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 355,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualButton',
                                objectid: 44,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 356,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 44,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 357,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 44,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 358,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 44,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 44,
                        name: 'Button',
                        properties: [
                            {
                                id: 214,
                                pinid: '1f187bbedb315b05694392f348c8fcf6',
                                readonly: false,
                                value: '10',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 19,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 359,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualButton',
                                objectid: 45,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 360,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 45,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: true,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 361,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 45,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 362,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 45,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 45,
                        name: 'Button switch',
                        properties: [
                            {
                                id: 215,
                                pinid: 'f5a2e49e93e9df1b78863fc81d65f007',
                                readonly: false,
                                value: '30',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 20,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 363,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIncreaseButton',
                                objectid: 46,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 364,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 46,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 365,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDecreaseButton',
                                objectid: 46,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 46,
                        name: 'Selection button +/-',
                        properties: [
                            {
                                id: 216,
                                pinid: 'dc69db02b3dca8e0ce1b1d9c082c517e',
                                readonly: false,
                                value: '-6',
                            },
                            {
                                id: 217,
                                pinid: 'd2e0cdc9069752464c57f94212a0b579',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 218,
                                pinid: '94b2bdae3b8bd951dfc8e7fc2fbeeadc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 219,
                                pinid: 'd32197522c4b2a6328d1168c1b061693',
                                readonly: false,
                                value: '3',
                            },
                            {
                                id: 220,
                                pinid: '27fb4d07177ef8167d9d8a2bcc557d5f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 221,
                                pinid: 'd037bfb9af6ecbf735e05cacf43427b7',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 2,
                        roomid: -1,
                        type: 23,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 366,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 47,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 367,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 47,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 368,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 47,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 47,
                        name: 'Switch',
                        properties: [
                            {
                                id: 222,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                Floors: [
                                    {
                                        FloorImage: {
                                            Height: 1001,
                                            Name: '75699385a885b0c7ca2deaa5909d1137.png',
                                            Width: 1961,
                                        },
                                        ID: 1,
                                        Name: 'Floor 1',
                                        Objects: [
                                            {
                                                Height: 32,
                                                IOID: 7,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1477,
                                                Y: 78,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 8,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 10,
                                                Unit: 'test',
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 9,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 11,
                                                Unit: '°C',
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 12,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 13,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 17,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 19,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 14,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 15,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 16,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 18,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 20,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 21,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 22,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 23,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 24,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 25,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 26,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 27,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 28,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 29,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 30,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 31,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 32,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 33,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 34,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 35,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 36,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 37,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 38,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 39,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 46,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 47,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 48,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 210,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 49,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 50,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 51,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 52,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 53,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 54,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 55,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 56,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 57,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 58,
                                                Unit: '°C',
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 59,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 68,
                                                Unit: 'ABCD',
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 310,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 69,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 70,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 71,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 61,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 62,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 63,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 64,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 65,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 66,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 67,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 40,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 41,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 410,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 42,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 43,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 44,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 45,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 72,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 73,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 74,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 75,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 76,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 77,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 1,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 11,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 510,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 12,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 4,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 5,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 13,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 6,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 37,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 38,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1077,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 7,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 8,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 610,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 10,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 710,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 2,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 710,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 3,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 710,
                                            },
                                            {
                                                DpName: 'light.value0',
                                                Height: 12,
                                                ObjID: 3,
                                                WebDesc: true,
                                                Width: 120,
                                                X: 732,
                                                Y: 710,
                                            },
                                            {
                                                DpName: 'light.value1',
                                                Height: 32,
                                                ObjID: 3,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 935,
                                                Y: 710,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 16,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1050,
                                                Y: 710,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 17,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1165,
                                                Y: 710,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 18,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1280,
                                                Y: 710,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 19,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 20,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 21,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 22,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 23,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 24,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 25,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 26,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 27,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 34,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1192,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 35,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1307,
                                                Y: 810,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 36,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1542,
                                                Y: 854,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 29,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1535,
                                                Y: 774,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 30,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1537,
                                                Y: 695,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 31,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1538,
                                                Y: 633,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 32,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1540,
                                                Y: 557,
                                            },
                                            {
                                                DpName: 'light.value0',
                                                Height: 12,
                                                ObjID: 32,
                                                WebDesc: true,
                                                Width: 120,
                                                X: 1680,
                                                Y: 759,
                                            },
                                            {
                                                DpName: 'light.value1',
                                                Height: 12,
                                                ObjID: 32,
                                                WebDesc: true,
                                                Width: 120,
                                                X: 1677,
                                                Y: 693,
                                            },
                                            {
                                                DpName: 'light.value2',
                                                Height: 12,
                                                ObjID: 32,
                                                WebDesc: true,
                                                Width: 120,
                                                X: 1677,
                                                Y: 630,
                                            },
                                            {
                                                DpName: 'light.value3',
                                                Height: 12,
                                                ObjID: 32,
                                                WebDesc: true,
                                                Width: 120,
                                                X: 1676,
                                                Y: 568,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 39,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 40,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 41,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 42,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 43,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 44,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 45,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 46,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 847,
                                                Y: 1010,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 47,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 962,
                                                Y: 1010,
                                            },
                                            {
                                                DpName: 'light.value2',
                                                Height: 12,
                                                ObjID: 3,
                                                WebDesc: true,
                                                Width: 120,
                                                X: 42,
                                                Y: 10,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 601,
                                            Name: '393a777e59a020ef1ddb43c37d30516b.jpg',
                                            Width: 900,
                                        },
                                        ID: 2,
                                        Name: 'Floor 2',
                                        Objects: [
                                            {
                                                DpName: '1',
                                                Height: 32,
                                                ObjID: 49,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 59,
                                                Y: 132,
                                            },
                                            {
                                                DpName: '2',
                                                Height: 32,
                                                ObjID: 49,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 182,
                                                Y: 153,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 52,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 42,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 4,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 157,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 6,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 272,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 37,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 387,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 38,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 502,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 7,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 617,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 8,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 732,
                                                Y: 10,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 43,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 42,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 50,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 157,
                                                Y: 110,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 51,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 272,
                                                Y: 110,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 695,
                                            Name: '847413ba38088716e0e35e0559a33029.png',
                                            Width: 1476,
                                        },
                                        ID: 3,
                                        Name: 'Floor 3',
                                        Objects: [
                                            {
                                                Height: 32,
                                                IOID: 58,
                                                Unit: '°C',
                                                WebDesc: true,
                                                Width: 32,
                                                X: 101,
                                                Y: 78,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 68,
                                                Unit: 'ABCD',
                                                WebDesc: true,
                                                Width: 32,
                                                X: 298,
                                                Y: 57,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 7,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 116,
                                                Y: 292,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 600,
                                            Name: '4c9253fbb0b0f5f227d083e4e87a2ba7.jpg',
                                            Width: 800,
                                        },
                                        ID: 4,
                                        Name: 'small flat',
                                        Objects: [
                                            {
                                                Height: 32,
                                                ObjID: 50,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 290,
                                                Y: 76,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 55,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 611,
                                                Y: 119,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 64,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 652,
                                                Y: 396,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 65,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 73,
                                                Y: 133,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 1617,
                                            Name: '96e9fbb2943cf580e88f0ec1f3529ebc.jpg',
                                            Width: 1957,
                                        },
                                        ID: 5,
                                        Name: 'Big plan',
                                        Objects: [
                                            {
                                                Height: 32,
                                                ObjID: 40,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 331,
                                                Y: 353,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 44,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1149,
                                                Y: 622,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 47,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 281,
                                                Y: 1151,
                                            },
                                            {
                                                DpName: '1',
                                                Height: 32,
                                                ObjID: 49,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1039,
                                                Y: 245,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 56,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 866,
                                                Y: 1097,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 1191,
                                            Name: '5e51804c08126e58db25314caf5932f8.jpg',
                                            Width: 1323,
                                        },
                                        ID: 6,
                                        Name: 'slave 1',
                                        Objects: [
                                            {
                                                Height: 32,
                                                ObjID: 57,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 1072,
                                                Y: 681,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 58,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 276,
                                                Y: 241,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 59,
                                                WebDesc: true,
                                                Width: 32,
                                                X: 692,
                                                Y: 411,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 60,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1020,
                                                Y: 342,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 61,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 199,
                                                Y: 495,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 62,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1204,
                                                Y: 612,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 63,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 780,
                                                Y: 218,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 77,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 422,
                                                Y: 733,
                                            },
                                            {
                                                DpName: 'light.value0',
                                                Height: 12,
                                                ObjID: 77,
                                                WebDesc: false,
                                                Width: 120,
                                                X: 327,
                                                Y: 449,
                                            },
                                            {
                                                DpName: 'light.value1',
                                                Height: 32,
                                                ObjID: 77,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 1100,
                                                Y: 170,
                                            },
                                            {
                                                DpName: 'light.value2',
                                                Height: 12,
                                                ObjID: 77,
                                                WebDesc: false,
                                                Width: 120,
                                                X: 461,
                                                Y: 209,
                                            },
                                            {
                                                DpName: 'light.value3',
                                                Height: 12,
                                                ObjID: 77,
                                                WebDesc: false,
                                                Width: 120,
                                                X: 394,
                                                Y: 1002,
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 369,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'floorPlan.floorConfig',
                                objectid: 48,
                                range: [],
                                remote: true,
                                type: 32,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 48,
                        name: 'Floor plan',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 32,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                Cameras: [
                                    {
                                        CameraType: 1,
                                        IP: '192.168.0.74',
                                        ImageCommand: 'ISAPI/Streaming/channels/101/picture',
                                        ImagePort: 80,
                                        ImageProtocol: 'HTTP',
                                        Name: 'Camera1',
                                        Password:
                                            'FjQ8oAsuXhkzg9itkSM0geEEKMX6nF2FXvHNM1zp1jpHj+jsTYBEG6PsN+iicFgaL8LIXhqD9oDShdZWOZznkA737XKlvHBrClRjQk5zHDBeYsF614dU2R1u05RjbFVcNGoA0JmkhvbAGovU2O7FHW7Hgf3bJOqY8gHWD8jBC1eUC2FgwfYUVs2aoi8vTlAXH4nuTUbf30DEHdmQoVa09FyJtv+BHUOqy5v+Zq7zB3gFG69mnn84JTc3ahJyKw07urHMSWhFU+fFe7eSSdpnJ23jhdBaFwqlf3LVVAc66aTNFSirq5B66e0IhfIXOcMPIPWq5Nox5ouuLYeyLORPJaCyB6zgmWuCjxW2KCps9hhCre+ELE4IEHCkinYDjIM8+e1eJ+QvLIZGYh8H/CI5vZLShpCPT/DR+09LXOAEyrNnOR/Ke+9lfy089VwPYDi8ydWaTeeBJsuhxODG+b8vHTTS0eX0on7Bj5enNZUROBtC6pLrS38Bu+6SwvZQ74CmK+PzOGERor0oiK044I3lq/XWSUVobLS4YblANa5Hr1R7Tz0MIROTtdJmS5M7vqBeP40WJ95TMx9xD2j1MoCXW0cMCSBsrxgW+gy8ORESs/EplD/cFWkjvTWxfz3lJ5KbWZZl29mKXpLGUP9L+vcv8xmw0Mf/JplTCR+9EjxC/k4=',
                                        StreamCommand: '',
                                        StreamPort: 554,
                                        StreamProtocol: 'RTSP',
                                        UserName: 'admin',
                                        http: '',
                                        image: 'aHR0cDovL2FkbWluOkNhbWVyYTIwMTlAMTkyLjE2OC4wLjc0OjgwL0lTQVBJL1N0cmVhbWluZy9jaGFubmVscy8xMDEvcGljdHVyZQ==',
                                        rtsp: 'cnRzcDovL2FkbWluOkNhbWVyYTIwMTlAMTkyLjE2OC4wLjc0OjU1NC8=',
                                    },
                                    {
                                        CameraType: 1,
                                        IP: 'tretr',
                                        ImageCommand: 'ISAPI/Streaming/channels/101/picture',
                                        ImagePort: 80,
                                        ImageProtocol: 'HTTP',
                                        Name: 'Camera2',
                                        Password:
                                            'L+LqdVjrAVsQacIKk1s3mXgECxeP+cMVYiKap4KaULaVb3SFT3cTKj11kyK64Q71cLrijsbAXGI/gkCCaY8wLCrVowF9GOZorKEIH+gas4hrNM/khn0BDrcFojq7l7CTuTZhGyZV6yzhzK8eHJPyZHiEm0RE9QxlgCNnHwCc2g71XGORhchzpes2MfXkuFFTUGbCup26QDwydt1D+Grl3yQZBFeD2nsRCikHba272k1sgAeWfUGoWDhQ8ele7jzdg7NFXWHpYTjHkpG+rJ/56A0oiaaBeTEwLvs2Ssd5ADjK55NGPrDMPEUeFFeKXMZCm50pacxltnEHHfrePxUBluT+OUPGAkMkMyhb2GsOAkKRL57TKVbOToGZkfCJaK/vZqHAGGtwdxIFCbhOe08waIdnRzHOh0HzDKysVEpe4gMSBIAhrL1YXIxcLJkoBMSB1eDX5VyNRV/tJSNoObrCAlv6nDmeq4oreV0peigeY6TEAnZeu62zzv0/F4pdv9XFRA2+kYOAN8+3dbV7KQkY+Tj2EmUC5e5kAQDfOQxSVjjpiDHVjayuqOY53hH4V9X53b0T09Q5ukOOhq++N/P/oJoLNMnKJDyqsFGXHc6mnR7x2iWtvxBSSN7F6hmRQ9U4nKA7KBIZejao1j2X7Ij6iMCgzOUDbrPmD+6EZ2NjVJU=',
                                        StreamCommand: '',
                                        StreamPort: 554,
                                        StreamProtocol: 'RTSP',
                                        UserName: 'gdg',
                                        http: '',
                                        image: 'aHR0cDovL2dkZzpzZ2ZnZmRAdHJldHI6ODAvSVNBUEkvU3RyZWFtaW5nL2NoYW5uZWxzLzEwMS9waWN0dXJl',
                                        rtsp: 'cnRzcDovL2dkZzpzZ2ZnZmRAdHJldHI6NTU0Lw==',
                                    },
                                ],
                                customname: '',
                                id: 370,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'camera.cameraConfig',
                                objectid: 49,
                                range: [],
                                remote: true,
                                type: 28,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 49,
                        name: 'Camera',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 30,
                        visualisationpw: false,
                    },
                    {
                        category: 4,
                        datapoints: [
                            {
                                customname: '',
                                id: 371,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 50,
                                range: ['1', '3', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 372,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeHeating',
                                objectid: 50,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 7,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 373,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsRoomTemperature',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: 2,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 374,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRelativeHumidity',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: 19,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 375,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 50,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 376,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeCooling',
                                objectid: 50,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 377,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsHoliday',
                                objectid: 50,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 378,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsEconomyMode',
                                objectid: 50,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 379,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutdoorTemperature',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 380,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyHeatingTemperature',
                                objectid: 50,
                                range: ['0', '40'],
                                remote: true,
                                type: -2,
                                value: 16,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 381,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyCoolingTemperature',
                                objectid: 50,
                                range: ['15', '40'],
                                remote: true,
                                type: -2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 382,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualSetpoint',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 19.7,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 383,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRequiredOpMode',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 1,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 384,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDewpointTemperature',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 385,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualValvePositionCont',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 386,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualOpMode',
                                objectid: 50,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: false,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Heating: 4,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Heating: 24,
                                                    Time: '15:52',
                                                },
                                                {
                                                    Heating: 38,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 6],
                                        },
                                        {
                                            Name: 'TimeProgram2',
                                            SwitchPoints: [
                                                {
                                                    Heating: 27,
                                                    Time: '04:04',
                                                },
                                                {
                                                    Heating: 38,
                                                    Time: '12:05',
                                                },
                                                {
                                                    Heating: 19.7,
                                                    Time: '12:06',
                                                },
                                            ],
                                            days: [2, 3, 4],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 387,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tph.timeProgram',
                                objectid: 50,
                                range: [],
                                remote: true,
                                type: 8,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 50,
                        name: 'Room controller 2',
                        properties: [
                            {
                                id: 223,
                                pinid: 'a70e5f4a2254aaf3a903b53ff91cc84b',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 224,
                                pinid: '6d6e20e2d6a7e82386ef15930d41824e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 225,
                                pinid: '405502632d68e338630ad9729e626c57',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 226,
                                pinid: '39fa7456dcd04f3d3731db82167564ff',
                                readonly: false,
                                value: '17',
                            },
                            {
                                id: 227,
                                pinid: '7e18b8e617aeae482e9f731784a2811a',
                                readonly: false,
                                value: '22',
                            },
                            {
                                id: 228,
                                pinid: 'a24eef4850f0f8094196540b46e01d76',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 229,
                                pinid: 'a61f83aa5674dfddcfcad8c6d242f657',
                                readonly: false,
                                value: '24',
                            },
                            {
                                id: 230,
                                pinid: '1dc82f53ee242ebd9018c9fc26ccfb71',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 231,
                                pinid: '66e5d781fdd48c9fef7e5adc2d0f9338',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 232,
                                pinid: '682d434e1d70ccc758edb3dddc34e38b',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 233,
                                pinid: '61e0bf0decc47bfdd5f48ac97b441d83',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 234,
                                pinid: '9f3851d9ef370707f676dfa658a01eb7',
                                readonly: false,
                                value: '40',
                            },
                            {
                                id: 235,
                                pinid: 'eb142acaf5672a835394b7ac965edfc3',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 236,
                                pinid: '79f85df54d84e2d4cf9914ebe095f758',
                                readonly: false,
                                value: '40',
                            },
                            {
                                id: 237,
                                pinid: '8ef87a38a326aee9a7da85eed7b98932',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 238,
                                pinid: '893e7553c75522fd9b60a0ccc1317848',
                                readonly: false,
                                value: '40',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 21,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 388,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 51,
                                range: ['1', '3', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 389,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetpointManualModeHeating',
                                objectid: 51,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 7,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 390,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 51,
                                range: ['5', '15'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 391,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetpointManualModeCooling',
                                objectid: 51,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: false,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Heating: 4,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Heating: 24,
                                                    Time: '15:52',
                                                },
                                                {
                                                    Heating: 38,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 6],
                                        },
                                        {
                                            Name: 'TimeProgram2',
                                            SwitchPoints: [
                                                {
                                                    Heating: 27,
                                                    Time: '04:04',
                                                },
                                                {
                                                    Heating: 38,
                                                    Time: '12:05',
                                                },
                                                {
                                                    Heating: 19.7,
                                                    Time: '12:06',
                                                },
                                            ],
                                            days: [2, 3, 4],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 392,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.program',
                                objectid: 51,
                                range: [],
                                remote: true,
                                type: 22,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 51,
                        name: 'Central room controller',
                        properties: [
                            {
                                id: 239,
                                pinid: 'c82066a98f701e45ac4fe896fdeb5a9e',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 240,
                                pinid: '73e8b884c436053e700b70a2106c9c4c',
                                readonly: false,
                                value: '28',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 10,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                Monitor: [
                                    {
                                        Configuration: {},
                                        IOid: 7,
                                        Log: true,
                                        Name: ' - color 1 - ',
                                        Unit: '%',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 8,
                                        Log: true,
                                        Name: 'Digital output DO2 NEW@',
                                        Unit: ' ABC * ! @',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 10,
                                        Log: true,
                                        Name: 'AC unit',
                                        Unit: 'kW',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 9,
                                        Log: true,
                                        Name: 'AC unit ',
                                        Unit: 'kWh',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 11,
                                        Log: true,
                                        Name: 'test2 Room controller Room temperature',
                                        Unit: '°C',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 12,
                                        Log: true,
                                        Name: 'test2 Room controller Relative humidity room air',
                                        Unit: '%',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 68,
                                        Log: true,
                                        Name: 'Channel R',
                                        Unit: 'mm/h',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 69,
                                        Log: true,
                                        Name: 'Channel G',
                                        Unit: '°',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 70,
                                        Log: true,
                                        Name: 'Channel B',
                                        Unit: ' !@#$%^&*()',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 71,
                                        Log: true,
                                        Name: 'Channel W',
                                        Unit: '',
                                        View: true,
                                    },
                                ],
                                customname: '',
                                id: 20,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'monitor.monitorConfig',
                                objectid: 52,
                                range: [],
                                remote: true,
                                type: 31,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 52,
                        name: 'Monitor 4 units',
                        properties: [],
                        ranking: 5,
                        roomid: -1,
                        type: 31,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 133,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualCentralSchedulerOn',
                                objectid: 53,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgramList: [
                                    {
                                        Name: 'TimeProgram1',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: false,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: false,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: false,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: false,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: false,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: false,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: false,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: false,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: false,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: false,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: false,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: false,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: true,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: true,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: true,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: true,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: false,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: false,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: false,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: false,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: false,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: false,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: false,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: false,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: false,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: false,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: false,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: false,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: false,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '12:00',
                                            },
                                        ],
                                        days: [0, 1, 2, 3, 4, 5, 6],
                                    },
                                ],
                                customname: '',
                                id: 134,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 53,
                                range: [],
                                remote: true,
                                type: 27,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 53,
                        name: 'Central timer switch 2',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 29,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 135,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualCentralSchedulerOn',
                                objectid: 54,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgramList: [
                                    {
                                        Name: 'TimeProgram1',
                                        SwitchPoints: [
                                            {
                                                FB: [
                                                    {
                                                        ID: 'c',
                                                        IsSelected: false,
                                                        ObjectID: 1,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNu',
                                                        IsSelected: true,
                                                        ObjectID: 12,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGa',
                                                        IsSelected: false,
                                                        ObjectID: 2,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gGP',
                                                        IsSelected: false,
                                                        ObjectID: 3,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jV0',
                                                        IsSelected: false,
                                                        ObjectID: 20,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krJ',
                                                        IsSelected: true,
                                                        ObjectID: 21,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'krU',
                                                        IsSelected: false,
                                                        ObjectID: 22,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ks5',
                                                        IsSelected: false,
                                                        ObjectID: 23,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksg',
                                                        IsSelected: false,
                                                        ObjectID: 24,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksz',
                                                        IsSelected: false,
                                                        ObjectID: 25,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ksS',
                                                        IsSelected: false,
                                                        ObjectID: 26,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktb',
                                                        IsSelected: true,
                                                        ObjectID: 27,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'ktu',
                                                        IsSelected: false,
                                                        ObjectID: 34,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kD7',
                                                        IsSelected: false,
                                                        ObjectID: 29,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kDN',
                                                        IsSelected: false,
                                                        ObjectID: 30,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEw',
                                                        IsSelected: true,
                                                        ObjectID: 31,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kEP',
                                                        IsSelected: false,
                                                        ObjectID: 32,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm85',
                                                        IsSelected: false,
                                                        ObjectID: 55,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3b',
                                                        IsSelected: false,
                                                        ObjectID: 44,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm3g',
                                                        IsSelected: false,
                                                        ObjectID: 45,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'm53',
                                                        IsSelected: false,
                                                        ObjectID: 47,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'c',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 521,
                                                            IDOff: 527,
                                                            IDOn: 526,
                                                        },
                                                        ObjectID: 57,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'cNr',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 533,
                                                        },
                                                        ObjectID: 58,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'dXh',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 544,
                                                            IDOn: 545,
                                                        },
                                                        ObjectID: 59,
                                                        Type: 24,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'eCO',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 549,
                                                            IDOn: 550,
                                                        },
                                                        ObjectID: 60,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'gd1',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            IDOff: 568,
                                                            IDOn: 567,
                                                        },
                                                        ObjectID: 63,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mbK',
                                                        IsSelected: false,
                                                        ObjectID: 65,
                                                        Type: 2,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jdj',
                                                        IsSelected: false,
                                                        ObjectID: 16,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUk',
                                                        IsSelected: false,
                                                        ObjectID: 17,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUy',
                                                        IsSelected: false,
                                                        ObjectID: 18,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jUM',
                                                        IsSelected: false,
                                                        ObjectID: 19,
                                                        Type: 27,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j0Q',
                                                        IsSelected: false,
                                                        ObjectID: 68,
                                                        Type: 19,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j2B',
                                                        IsSelected: false,
                                                        ObjectID: 69,
                                                        Type: 20,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'j4M',
                                                        IsSelected: false,
                                                        ObjectID: 73,
                                                        Type: 25,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'jd3',
                                                        IsSelected: false,
                                                        ObjectID: 74,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'glo',
                                                        IsSelected: false,
                                                        MultiServerData: {
                                                            DevID: 14,
                                                            ID: 570,
                                                        },
                                                        ObjectID: 77,
                                                        Type: 17,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'kFs',
                                                        IsSelected: false,
                                                        ObjectID: 87,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mfK',
                                                        IsSelected: false,
                                                        ObjectID: 97,
                                                        Type: 22,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgo',
                                                        IsSelected: false,
                                                        ObjectID: 98,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mgH',
                                                        IsSelected: false,
                                                        ObjectID: 99,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                    {
                                                        ID: 'mh0',
                                                        IsSelected: false,
                                                        ObjectID: 100,
                                                        Type: 12,
                                                        Value: 'Ignore',
                                                    },
                                                ],
                                                Time: '12:00',
                                            },
                                        ],
                                        days: [0, 1, 2, 3, 4, 5, 6],
                                    },
                                ],
                                customname: '',
                                id: 136,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 54,
                                range: [],
                                remote: true,
                                type: 27,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 54,
                        name: 'Central timer switch 3',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 29,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 393,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 55,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 394,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 55,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 395,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 55,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 396,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 55,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '06:00',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '22:00',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 397,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 55,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 55,
                        name: 'Timer switch lights',
                        properties: [
                            {
                                id: 241,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 5,
                        roomid: -1,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                Monitor: [
                                    {
                                        Configuration: {},
                                        IOid: 78,
                                        Log: true,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 79,
                                        Log: true,
                                        Name: 'Tilt binary sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 80,
                                        Log: true,
                                        Name: 'Lock access control notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 81,
                                        Log: true,
                                        Name: 'Burglar notification',
                                        Unit: '',
                                        View: true,
                                    },
                                ],
                                customname: '',
                                id: 409,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'monitor.monitorConfig',
                                objectid: 56,
                                range: [],
                                remote: true,
                                type: 31,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 56,
                        name: 'Monitor window handle',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 31,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 535,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 57,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 536,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 57,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 537,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 57,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 538,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 57,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 539,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 57,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 540,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 57,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 541,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 57,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 542,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 57,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 543,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 57,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 544,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 57,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 545,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 57,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 546,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 57,
                                range: ['0', '1', '2', '3', '4', '5', '6'],
                                remote: true,
                                type: -2,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 547,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 57,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 57,
                        name: 'Blinds s2',
                        properties: [
                            {
                                id: 317,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 318,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 319,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 320,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 321,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 322,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 323,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 324,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 325,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 326,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 327,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 328,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 329,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 330,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 331,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '1',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 548,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 58,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 549,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 58,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 550,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 58,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 551,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 58,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 552,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 58,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 553,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 58,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 554,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 58,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 555,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 58,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 58,
                        name: 'Dimmer s2',
                        properties: [
                            {
                                id: 332,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 333,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 334,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 335,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 336,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 337,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 338,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 339,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 556,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartTimer',
                                objectid: 59,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 557,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 59,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 558,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 59,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 559,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCountdown',
                                objectid: 59,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 560,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 59,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 561,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 59,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 562,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 59,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 59,
                        name: 'Staircase light switch s2',
                        properties: [
                            {
                                id: 340,
                                pinid: '8cc630e7a9a6ba6a9b7813c7679ad377',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 341,
                                pinid: 'c88c9061436916a3ef3e6393fb0cd661',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 342,
                                pinid: '0cb41e1f95ddb7ac731db1d7e2afa555',
                                readonly: false,
                                value: '0.5',
                            },
                            {
                                id: 343,
                                pinid: '8d30c2134835fc4edef49119345fa139',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 24,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 563,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 60,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 564,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 60,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 565,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 60,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 566,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 60,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '06:00',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '22:00',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 567,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 60,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 60,
                        name: 'Timer switch s2',
                        properties: [
                            {
                                id: 344,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 11,
                        datapoints: [
                            {
                                customname: '',
                                id: 568,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmAlarmSystem',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 569,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmAlarmSystemDelayed',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 570,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmWithoutPresenceDetectors',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 571,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDisarmAlarmSystem',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 572,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarmSystemIsArmed',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 573,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualArmedWithoutPresenceDetectors',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 574,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarm',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 575,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAcivationCountdown',
                                objectid: 61,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 576,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsAlarm',
                                objectid: 61,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 61,
                        name: 'Alarm system s2',
                        properties: [
                            {
                                id: 345,
                                pinid: '5e2481a0a4b3dcd154e1a3400a519457',
                                readonly: false,
                                value: '60',
                            },
                            {
                                id: 346,
                                pinid: '7ee2bc6a7b7dd741baee80b5537ef898',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 347,
                                pinid: '6ddccfc197b1766a550c7537ab175ca8',
                                readonly: false,
                                value: '3',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 3,
                        visualisationpw: false,
                    },
                    {
                        category: 3,
                        datapoints: [
                            {
                                customname: '',
                                id: 577,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerOpen',
                                objectid: 62,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 578,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerClose',
                                objectid: 62,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 579,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPosition',
                                objectid: 62,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 580,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsMoving',
                                objectid: 62,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 581,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 62,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: '',
                                writeprotect: false,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 62,
                        name: 'Gate s2',
                        properties: [
                            {
                                id: 348,
                                pinid: 'b780ddd106ab95a5e42cf60fde71eb6f',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 349,
                                pinid: '7ba0af0f96b341184d22fc868717937e',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 350,
                                pinid: '4d4f4632abfa5edaad4c46dfa9cc89b9',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 351,
                                pinid: '5bc979396db5154a1987975c040362f4',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 352,
                                pinid: 'bd4ee83aa7d412ab669b3b39c1c6af83',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 353,
                                pinid: 'c4187fd5723c6a556daff8c9eb589461',
                                readonly: false,
                                value: '1',
                            },
                            {
                                id: 354,
                                pinid: 'e5e1aabb049e6accdf4a5b975488e411',
                                readonly: false,
                                value: '3',
                            },
                            {
                                id: 355,
                                pinid: '966c9d30ff4e91775c58b78b2b07d5a3',
                                readonly: false,
                                value: 'true',
                            },
                            {
                                id: 356,
                                pinid: '25a7abf3b503c2f920c34dcbbbaad8ca',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 16,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 582,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 63,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 583,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 63,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 584,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 63,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: false,
                        id: 63,
                        name: 'Switch s2',
                        properties: [
                            {
                                id: 357,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 14,
                        datapoints: [
                            {
                                customname: '',
                                id: 398,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualQuitAlarmClock',
                                objectid: 64,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 399,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStartSnoozeTimer',
                                objectid: 64,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 400,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsAlarm',
                                objectid: 64,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 401,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsSnooze',
                                objectid: 64,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 402,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSnoozeIntervalDuration',
                                objectid: 64,
                                range: ['0', '30'],
                                remote: true,
                                type: 7,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    Alarms: [
                                        {
                                            Active: true,
                                            Description: 'New alarm',
                                            Friday: false,
                                            Monday: false,
                                            Saturday: false,
                                            Sunday: false,
                                            Thursday: false,
                                            Time: '00:00',
                                            Tuesday: false,
                                            Wednesday: false,
                                        },
                                        {
                                            Active: false,
                                            Description: 'work wake up',
                                            Friday: false,
                                            Monday: false,
                                            Saturday: false,
                                            Sunday: false,
                                            Thursday: true,
                                            Time: '08:00',
                                            Tuesday: true,
                                            Wednesday: true,
                                        },
                                        {
                                            Active: true,
                                            Description: 'New alarm 3',
                                            Friday: false,
                                            Monday: false,
                                            Saturday: false,
                                            Sunday: false,
                                            Thursday: false,
                                            Time: '11:48',
                                            Tuesday: true,
                                            Wednesday: true,
                                        },
                                        {
                                            Active: true,
                                            Description: 't1',
                                            Friday: false,
                                            Monday: true,
                                            Saturday: true,
                                            Sunday: true,
                                            Thursday: false,
                                            Time: '12:28',
                                            Tuesday: false,
                                            Wednesday: false,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 403,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'alarm.alarmProgram',
                                objectid: 64,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 64,
                        name: 'Alarm clock',
                        properties: [
                            {
                                id: 242,
                                pinid: '82cbb2b6dcbc77e13dd2fc67c8bffd6e',
                                readonly: false,
                                value: '60',
                            },
                        ],
                        ranking: 5,
                        roomid: -1,
                        type: 1,
                        visualisationpw: false,
                    },
                    {
                        category: 2,
                        datapoints: [
                            {
                                customname: '',
                                id: 416,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualBlindsPosition',
                                objectid: 65,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 417,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlatsPosition',
                                objectid: 65,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 418,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualShadingOn',
                                objectid: 65,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 419,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetBlinds',
                                objectid: 65,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 420,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSetSlats',
                                objectid: 65,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 421,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerDown',
                                objectid: 65,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 422,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualTriggerUp',
                                objectid: 65,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 423,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralUp',
                                objectid: 65,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 424,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralDown',
                                objectid: 65,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 425,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualStop',
                                objectid: 65,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 426,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStartPositionCalibration',
                                objectid: 65,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 427,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualKeyAssignmentLocalControl',
                                objectid: 65,
                                range: ['0', '1', '2', '3', '4', '5', '6'],
                                remote: true,
                                type: -2,
                                value: 2,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 428,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSlatsMoveDuration',
                                objectid: 65,
                                range: ['0', ''],
                                remote: true,
                                type: -2,
                                value: 10,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 65,
                        name: 'Blinds',
                        properties: [
                            {
                                id: 245,
                                pinid: '26a654cd2d6555619fd9df2a8ed9adcd',
                                readonly: true,
                                value: '30',
                            },
                            {
                                id: 246,
                                pinid: 'b9b5edb2a1cadae4c1e7de1294b76650',
                                readonly: true,
                                value: '30',
                            },
                            {
                                id: 247,
                                pinid: 'd3f6261eb9a9b7e18f8c5c8605ec5daa',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 248,
                                pinid: '5e5f7cc01317f6b90257826c13412006',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 249,
                                pinid: '07f3cd80a5b4116750631c37c55552d9',
                                readonly: false,
                                value: '0.15',
                            },
                            {
                                id: 250,
                                pinid: '2c28a842ef27f21d5026e9862c17405c',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 251,
                                pinid: '8034d9ebe33d2b694e0a2249f787a113',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 252,
                                pinid: 'c3766ade44c6d7d55a3b2193d198c473',
                                readonly: false,
                                value: '24000',
                            },
                            {
                                id: 253,
                                pinid: '8545d1880abca539e3c35681ec6cb3b0',
                                readonly: false,
                                value: '12000',
                            },
                            {
                                id: 254,
                                pinid: '000d7ece69ae138614310db85e53b235',
                                readonly: false,
                                value: '300',
                            },
                            {
                                id: 255,
                                pinid: 'a25f5f2d4d8446776abe6b8e94b077fc',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 256,
                                pinid: '6f27de40abaa538e26a4bf0676bda4b1',
                                readonly: false,
                                value: '2',
                            },
                            {
                                id: 257,
                                pinid: '351ff701cae805e1053d2017982800f0',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 258,
                                pinid: 'fc010f478aa45c3c167c26c36197c1de',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 259,
                                pinid: '206743340215c5c704aa9367b23f83e2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 4,
                        type: 2,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 137,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualComingHome',
                                objectid: 66,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 138,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSleep',
                                objectid: 66,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 139,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualLeavingHome',
                                objectid: 66,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                CominghomeConfig: {
                                    Buttons: [
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'c',
                                            LeavingHome: 'CompletelyDown',
                                            ObjectID: 1,
                                            Sleep: 'CompletelyDown',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: 'ActivateWithoutPresence',
                                            ID: 'e3W',
                                            LeavingHome: 'ActivateWithoutPresence',
                                            ObjectID: 5,
                                            Sleep: 'ActivateDelayed',
                                            Type: 3,
                                        },
                                        {
                                            ComingHome: 'Shadowing',
                                            ID: 'gGa',
                                            LeavingHome: 'CompletelyUp',
                                            ObjectID: 2,
                                            Sleep: 'CompletelyDown',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: '1',
                                            ID: 'gGP',
                                            LeavingHome: '6',
                                            ObjectID: 3,
                                            Sleep: '0',
                                            Type: 17,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'jdj',
                                            LeavingHome: 'Off',
                                            ObjectID: 16,
                                            Sleep: 'On',
                                            Type: 27,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'jUk',
                                            LeavingHome: 'Off',
                                            ObjectID: 17,
                                            Sleep: 'Off',
                                            Type: 27,
                                        },
                                        {
                                            ComingHome: 'Off',
                                            ID: 'jUy',
                                            LeavingHome: 'Off',
                                            ObjectID: 18,
                                            Sleep: 'Off',
                                            Type: 27,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'jUM',
                                            LeavingHome: 'On',
                                            ObjectID: 19,
                                            Sleep: 'On',
                                            Type: 27,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'jV0',
                                            LeavingHome: 'On',
                                            ObjectID: 20,
                                            Sleep: 'On',
                                            Type: 24,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'krJ',
                                            LeavingHome: 'Off',
                                            ObjectID: 21,
                                            Sleep: 'Off',
                                            Type: 24,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'krU',
                                            LeavingHome: 'On',
                                            ObjectID: 22,
                                            Sleep: 'Off',
                                            Type: 24,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'ks5',
                                            LeavingHome: 'Off',
                                            ObjectID: 23,
                                            Sleep: 'Off',
                                            Type: 24,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'ksg',
                                            LeavingHome: '52',
                                            ObjectID: 24,
                                            Sleep: '40',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: '84',
                                            ID: 'ksz',
                                            LeavingHome: '7',
                                            ObjectID: 25,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: '100',
                                            ID: 'ksS',
                                            LeavingHome: '0',
                                            ObjectID: 26,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: '0',
                                            ID: 'ktb',
                                            LeavingHome: '100',
                                            ObjectID: 27,
                                            Sleep: '3',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'Disabled',
                                            ID: 'kAO',
                                            LeavingHome: 'Activate',
                                            ObjectID: 36,
                                            Sleep: 'Disabled',
                                            Type: 18,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'kD7',
                                            LeavingHome: 'CompletelyUp',
                                            ObjectID: 29,
                                            Sleep: 'Shadowing',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'kDN',
                                            LeavingHome: 'Shadowing',
                                            ObjectID: 30,
                                            Sleep: 'Shadowing',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: '21',
                                            ID: 'kEw',
                                            LeavingHome: '21',
                                            ObjectID: 31,
                                            Sleep: 'Ignore',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: '4',
                                            ID: 'kEP',
                                            LeavingHome: '7',
                                            ObjectID: 32,
                                            Sleep: '5',
                                            Type: 17,
                                        },
                                        {
                                            ComingHome: 'Open',
                                            ID: 'kGB',
                                            LeavingHome: 'CloseDelay',
                                            ObjectID: 39,
                                            Sleep: 'Close',
                                            Type: 16,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'm3b',
                                            LeavingHome: 'On',
                                            ObjectID: 44,
                                            Sleep: 'Off',
                                            Type: 19,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'm3g',
                                            LeavingHome: 'Off',
                                            ObjectID: 45,
                                            Sleep: 'Off',
                                            Type: 20,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'm53',
                                            LeavingHome: 'On',
                                            ObjectID: 47,
                                            Sleep: 'Off',
                                            Type: 25,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'm85',
                                            LeavingHome: 'Off',
                                            ObjectID: 55,
                                            Sleep: 'Off',
                                            Type: 22,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'mbK',
                                            LeavingHome: 'CompletelyUp',
                                            ObjectID: 65,
                                            Sleep: 'CompletelyDown',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: 'CompletelyUp',
                                            ID: 'c',
                                            LeavingHome: 'Shadowing',
                                            MultiServerData: {
                                                DevID: 14,
                                                ID: 521,
                                                IDOff: 527,
                                                IDOn: 526,
                                            },
                                            ObjectID: 57,
                                            Sleep: 'Ignore',
                                            Type: 2,
                                        },
                                        {
                                            ComingHome: '11',
                                            ID: 'cNr',
                                            LeavingHome: '15',
                                            MultiServerData: {
                                                DevID: 14,
                                                ID: 533,
                                            },
                                            ObjectID: 58,
                                            Sleep: '3',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'dXh',
                                            LeavingHome: 'Off',
                                            MultiServerData: {
                                                DevID: 14,
                                                IDOff: 544,
                                                IDOn: 545,
                                            },
                                            ObjectID: 59,
                                            Sleep: 'Off',
                                            Type: 24,
                                        },
                                        {
                                            ComingHome: 'Disabled',
                                            ID: 'eMJ',
                                            LeavingHome: 'ActivateDelayed',
                                            MultiServerData: {
                                                ArmAlarmDelayed: 553,
                                                DevID: 14,
                                                ID: 554,
                                                IDOff: 555,
                                                IDOn: 552,
                                            },
                                            ObjectID: 61,
                                            Sleep: 'ActivateWithoutPresence',
                                            Type: 3,
                                        },
                                        {
                                            ComingHome: 'Open',
                                            ID: 'eUA',
                                            LeavingHome: 'Close',
                                            MultiServerData: {
                                                DevID: 14,
                                                IDOff: 562,
                                                IDOn: 561,
                                            },
                                            ObjectID: 62,
                                            Sleep: 'Close',
                                            Type: 16,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'gd1',
                                            LeavingHome: 'On',
                                            MultiServerData: {
                                                DevID: 14,
                                                IDOff: 568,
                                                IDOn: 567,
                                            },
                                            ObjectID: 63,
                                            Sleep: 'Off',
                                            Type: 25,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'j0Q',
                                            LeavingHome: 'On',
                                            ObjectID: 68,
                                            Sleep: 'Off',
                                            Type: 19,
                                        },
                                        {
                                            ComingHome: 'On',
                                            ID: 'j2B',
                                            LeavingHome: 'On',
                                            ObjectID: 69,
                                            Sleep: 'Off',
                                            Type: 20,
                                        },
                                        {
                                            ComingHome: 'Off',
                                            ID: 'j4M',
                                            LeavingHome: 'On',
                                            ObjectID: 73,
                                            Sleep: 'Off',
                                            Type: 25,
                                        },
                                        {
                                            ComingHome: 'Off',
                                            ID: 'jd3',
                                            LeavingHome: 'On',
                                            ObjectID: 74,
                                            Sleep: 'Off',
                                            Type: 22,
                                        },
                                        {
                                            ComingHome: '2',
                                            ID: 'glo',
                                            LeavingHome: '1',
                                            MultiServerData: {
                                                DevID: 14,
                                                ID: 570,
                                            },
                                            ObjectID: 77,
                                            Sleep: '0',
                                            Type: 17,
                                        },
                                        {
                                            ComingHome: '53',
                                            ID: 'kFs',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 87,
                                            Sleep: '0',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'mgo',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 98,
                                            Sleep: 'Ignore',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'mgH',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 99,
                                            Sleep: 'Ignore',
                                            Type: 12,
                                        },
                                        {
                                            ComingHome: 'Ignore',
                                            ID: 'mh0',
                                            LeavingHome: 'Ignore',
                                            ObjectID: 100,
                                            Sleep: 'Ignore',
                                            Type: 12,
                                        },
                                    ],
                                },
                                customname: '',
                                id: 140,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.config',
                                objectid: 66,
                                range: [],
                                remote: true,
                                type: 23,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 66,
                        name: 'Central push button functions',
                        properties: [
                            {
                                id: 54,
                                pinid: 'c291bcd3f48cff2b46f523e3e2fd2f90',
                                readonly: false,
                                value: '90',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 8,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 167,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualButton',
                                objectid: 68,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 168,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 68,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 169,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 68,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 170,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 68,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 68,
                        name: 'Button',
                        properties: [
                            {
                                id: 80,
                                pinid: '1f187bbedb315b05694392f348c8fcf6',
                                readonly: false,
                                value: '60',
                            },
                        ],
                        ranking: 5,
                        roomid: -1,
                        type: 19,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 171,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualButton',
                                objectid: 69,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 172,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 69,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 173,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 69,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 174,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 69,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 69,
                        name: 'Button switch',
                        properties: [
                            {
                                id: 81,
                                pinid: 'f5a2e49e93e9df1b78863fc81d65f007',
                                readonly: false,
                                value: '60',
                            },
                        ],
                        ranking: 3,
                        roomid: -1,
                        type: 20,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 175,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 73,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 176,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 73,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 177,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 73,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 73,
                        name: 'Switch light',
                        properties: [
                            {
                                id: 82,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 6,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 178,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 74,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 179,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 74,
                                range: ['', ''],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 180,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 74,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 181,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 74,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '17:39',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '17:40',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 182,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 74,
                                range: [],
                                remote: true,
                                type: 5,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: true,
                        id: 74,
                        name: 'Car charger',
                        properties: [
                            {
                                id: 83,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: 6,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 404,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 75,
                                range: ['0', '1', '2', '3', '4', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 405,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetpointManualModeHeating',
                                objectid: 75,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 22,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 406,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 75,
                                range: ['5', '15'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 407,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetpointManualModeCooling',
                                objectid: 75,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: true,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Cooling: 23,
                                                    Heating: 22,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Cooling: 28,
                                                    Heating: 16,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 408,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'centralObj.program',
                                objectid: 75,
                                range: [],
                                remote: true,
                                type: 22,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 75,
                        name: 'Central room controller 2',
                        properties: [
                            {
                                id: 243,
                                pinid: 'c82066a98f701e45ac4fe896fdeb5a9e',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 244,
                                pinid: '73e8b884c436053e700b70a2106c9c4c',
                                readonly: false,
                                value: '28',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 10,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 585,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 586,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 77,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 587,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 588,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 589,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 590,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 591,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                Scenes: [
                                    {
                                        Name: 'Off',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 0,
                                                Description: 'b1',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#000000000000000000',
                                            },
                                            {
                                                Description: 's1',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: false,
                                            },
                                            {
                                                Description: 'd1',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                            {
                                                Brightness: 0,
                                                Description: 'l1',
                                                LightType: 6,
                                                Name: 'AQ4',
                                                Value: '#000000000000000000',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'New scene',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'b1',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#640000000000FFFFFF',
                                            },
                                            {
                                                Description: 's1',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Description: 'd1',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                            {
                                                Brightness: 100,
                                                Description: 'l1',
                                                LightType: 6,
                                                Name: 'AQ4',
                                                Value: '#640000000000FFFFFF',
                                            },
                                        ],
                                    },
                                    {
                                        Name: 'New scene1',
                                        PresenceDetector: false,
                                        values: [
                                            {
                                                Brightness: 100,
                                                Description: 'b1',
                                                LightType: 4,
                                                Name: 'AQ1',
                                                Value: '#640000000000FF1C1E',
                                            },
                                            {
                                                Description: 's1',
                                                LightType: 0,
                                                Name: 'AQ2',
                                                Value: true,
                                            },
                                            {
                                                Description: 'd1',
                                                LightType: 1,
                                                Name: 'AQ3',
                                                Value: '55',
                                            },
                                            {
                                                Brightness: 32,
                                                Description: 'l1',
                                                LightType: 6,
                                                Name: 'AQ4',
                                                Value: '#200000000000FF2CA3',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 592,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'light.sceneConfig',
                                objectid: 77,
                                range: [],
                                remote: true,
                                type: 9,
                                writeprotect: false,
                            },
                            {
                                customname: 'b1',
                                id: 593,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value0',
                                objectid: 77,
                                range: ['10', '100'],
                                remote: true,
                                type: 3,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: 's1',
                                id: 594,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value1',
                                objectid: 77,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: 'd1',
                                id: 595,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value2',
                                objectid: 77,
                                range: ['10', '100'],
                                remote: true,
                                type: 4,
                                value: '',
                                writeprotect: false,
                            },
                            {
                                customname: 'l1',
                                id: 596,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'light.value3',
                                objectid: 77,
                                range: ['10', '100'],
                                remote: true,
                                type: 29,
                                value: '',
                                writeprotect: false,
                            },
                        ],
                        deviceid: 14,
                        externalaccess: true,
                        favourite: true,
                        id: 77,
                        name: 'Light controller s2',
                        properties: [
                            {
                                id: 358,
                                pinid: 'fc0f688783acc959f0fc678eed9a35c5',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 359,
                                pinid: '9ab0c26420545b5e89b4db810aa66bca',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 360,
                                pinid: 'be0dd8d2546f42b7f97d941e238b8e23',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 361,
                                pinid: '312a6c543cc5aa30a0374ab3f2cc55df',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 362,
                                pinid: '4305706ea659aa3d5d3d12ffedeb5855',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 17,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                Monitor: [
                                    {
                                        Configuration: {},
                                        IOid: 104,
                                        Log: true,
                                        Name: 'CO2-level multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 105,
                                        Log: true,
                                        Name: 'Volatile Organic Compound multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 106,
                                        Log: true,
                                        Name: 'Home health notification',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 107,
                                        Log: true,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 108,
                                        Log: true,
                                        Name: 'Dew point multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 109,
                                        Log: true,
                                        Name: 'Relative humidity multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                ],
                                customname: '',
                                id: 21,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'monitor.monitorConfig',
                                objectid: 78,
                                range: [],
                                remote: true,
                                type: 31,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 78,
                        name: 'Monitor co2 new',
                        properties: [],
                        ranking: 4,
                        roomid: -1,
                        type: 31,
                        visualisationpw: false,
                    },
                    {
                        category: 4,
                        datapoints: [
                            {
                                customname: '',
                                id: 429,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualOperationMode',
                                objectid: 79,
                                range: ['1', '3', '5'],
                                remote: true,
                                type: 16,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 430,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeHeating',
                                objectid: 79,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 20,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 431,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsRoomTemperature',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: 2,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 433,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualStandbyTemperature',
                                objectid: 79,
                                range: ['0', '40'],
                                remote: true,
                                type: 2,
                                value: 10,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 434,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualManualModeCooling',
                                objectid: 79,
                                range: ['15', '40'],
                                remote: true,
                                type: 2,
                                value: 23,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 435,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsHoliday',
                                objectid: 79,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 436,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIsEconomyMode',
                                objectid: 79,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 437,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutdoorTemperature',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -32768,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 438,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyHeatingTemperature',
                                objectid: 79,
                                range: ['0', '40'],
                                remote: true,
                                type: -2,
                                value: 16,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 439,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualEconomyCoolingTemperature',
                                objectid: 79,
                                range: ['15', '40'],
                                remote: true,
                                type: -2,
                                value: 28,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 440,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualSetpoint',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 22,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 441,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualRequiredOpMode',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 1,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 442,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDewpointTemperature',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 443,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualValvePositionCont',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: 100,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 444,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualActualOpMode',
                                objectid: 79,
                                range: ['', ''],
                                remote: true,
                                type: -1,
                                value: -1,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    CoolingSupport: false,
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Heating: 22,
                                                    Time: '06:00',
                                                },
                                                {
                                                    Heating: 16,
                                                    Time: '22:00',
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 445,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tph.timeProgram',
                                objectid: 79,
                                range: [],
                                remote: true,
                                type: 8,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 79,
                        name: 'Room controller valve comet new',
                        properties: [
                            {
                                id: 260,
                                pinid: 'a70e5f4a2254aaf3a903b53ff91cc84b',
                                readonly: false,
                                value: '16',
                            },
                            {
                                id: 261,
                                pinid: '6d6e20e2d6a7e82386ef15930d41824e',
                                readonly: false,
                                value: '28',
                            },
                            {
                                id: 262,
                                pinid: '405502632d68e338630ad9729e626c57',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 263,
                                pinid: '39fa7456dcd04f3d3731db82167564ff',
                                readonly: false,
                                value: '17',
                            },
                            {
                                id: 264,
                                pinid: '7e18b8e617aeae482e9f731784a2811a',
                                readonly: false,
                                value: '22',
                            },
                            {
                                id: 265,
                                pinid: 'a24eef4850f0f8094196540b46e01d76',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 266,
                                pinid: 'a61f83aa5674dfddcfcad8c6d242f657',
                                readonly: false,
                                value: '24',
                            },
                            {
                                id: 267,
                                pinid: '1dc82f53ee242ebd9018c9fc26ccfb71',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 268,
                                pinid: '66e5d781fdd48c9fef7e5adc2d0f9338',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 269,
                                pinid: '682d434e1d70ccc758edb3dddc34e38b',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 270,
                                pinid: '61e0bf0decc47bfdd5f48ac97b441d83',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 271,
                                pinid: '9f3851d9ef370707f676dfa658a01eb7',
                                readonly: false,
                                value: '40',
                            },
                            {
                                id: 272,
                                pinid: 'eb142acaf5672a835394b7ac965edfc3',
                                readonly: false,
                                value: '15',
                            },
                            {
                                id: 273,
                                pinid: '79f85df54d84e2d4cf9914ebe095f758',
                                readonly: false,
                                value: '40',
                            },
                            {
                                id: 274,
                                pinid: '8ef87a38a326aee9a7da85eed7b98932',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 275,
                                pinid: '893e7553c75522fd9b60a0ccc1317848',
                                readonly: false,
                                value: '40',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 21,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                Floors: [
                                    {
                                        FloorImage: {
                                            Height: 661,
                                            Name: '03526824fb7cfabf467fde57003863c9.png',
                                            Width: 881,
                                        },
                                        ID: 1,
                                        Name: 'Floor 1',
                                        Objects: [
                                            {
                                                Height: 32,
                                                IOID: 7,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 71,
                                                Y: 203,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 8,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 68,
                                                Y: 351,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 58,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 316,
                                                Y: 369,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 59,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 321,
                                                Y: 201,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 81,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 514,
                                                Y: 209,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 82,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 682,
                                                Y: 203,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 83,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 690,
                                                Y: 332,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 826,
                                            Name: '75699385a885b0c7ca2deaa5909d1137.png',
                                            Width: 1651,
                                        },
                                        ID: 2,
                                        Name: 'Floor 2',
                                        Objects: [
                                            {
                                                DpName: 'VirtualComingHome',
                                                Height: 32,
                                                ObjID: 66,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 209,
                                                Y: 178,
                                            },
                                            {
                                                DpName: 'VirtualSleep',
                                                Height: 32,
                                                ObjID: 66,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 666,
                                                Y: 224,
                                            },
                                            {
                                                DpName: 'VirtualLeavingHome',
                                                Height: 32,
                                                ObjID: 66,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 210,
                                                Y: 582,
                                            },
                                        ],
                                    },
                                    {
                                        FloorImage: {
                                            Height: 260,
                                            Name: '25488f998bcb49f150813cb473b68ea9.jpg',
                                            Width: 555,
                                        },
                                        ID: 3,
                                        Name: 'Floor 3',
                                        Objects: [],
                                    },
                                ],
                                customname: '',
                                id: 446,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'floorPlan.floorConfig',
                                objectid: 80,
                                range: [],
                                remote: true,
                                type: 32,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 80,
                        name: 'Floor plan 2 ',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 32,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 447,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualButton',
                                objectid: 81,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 448,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 81,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: false,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 449,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 81,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 450,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 81,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 81,
                        name: 'Button switch',
                        properties: [
                            {
                                id: 276,
                                pinid: 'f5a2e49e93e9df1b78863fc81d65f007',
                                readonly: false,
                                value: '0.3',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 20,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 451,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualIncreaseButton',
                                objectid: 82,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 452,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 82,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 453,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDecreaseButton',
                                objectid: 82,
                                range: ['false', 'true'],
                                remote: true,
                                type: 1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 82,
                        name: 'Selection button +/-',
                        properties: [
                            {
                                id: 277,
                                pinid: 'dc69db02b3dca8e0ce1b1d9c082c517e',
                                readonly: false,
                                value: '-18',
                            },
                            {
                                id: 278,
                                pinid: 'd2e0cdc9069752464c57f94212a0b579',
                                readonly: false,
                                value: '34',
                            },
                            {
                                id: 279,
                                pinid: '94b2bdae3b8bd951dfc8e7fc2fbeeadc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 280,
                                pinid: 'd32197522c4b2a6328d1168c1b061693',
                                readonly: false,
                                value: '3',
                            },
                            {
                                id: 281,
                                pinid: '27fb4d07177ef8167d9d8a2bcc557d5f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 282,
                                pinid: 'd037bfb9af6ecbf735e05cacf43427b7',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 23,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 454,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualConnection',
                                objectid: 83,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 455,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 83,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 456,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 83,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 83,
                        name: 'Switch',
                        properties: [
                            {
                                id: 283,
                                pinid: 'e9367fbb272e93fe99fdc6910301ba91',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 25,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                customname: '',
                                id: 410,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfOpen',
                                objectid: 84,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 411,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfClosed',
                                objectid: 84,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 3,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 412,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNumberOfTiledWindows',
                                objectid: 84,
                                range: ['0', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                deviceid: 11,
                                id: 413,
                                ioid: 74,
                                isconfiguration: false,
                                name: 'Door/Window binary sensor',
                                objectid: 84,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                deviceid: 12,
                                id: 414,
                                ioid: 77,
                                isconfiguration: false,
                                name: 'Door/Window binary sensor',
                                objectid: 84,
                                range: ['false', 'true'],
                                remote: true,
                                type: 10,
                                value: '',
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                deviceid: 23,
                                id: 415,
                                ioid: 134,
                                isconfiguration: false,
                                name: 'Radio eHandle ConnectSense 1',
                                objectid: 84,
                                range: [],
                                remote: true,
                                type: 34,
                                value: '',
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 84,
                        name: 'Window and door monitoring',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 11,
                        visualisationpw: false,
                    },
                    {
                        category: 6,
                        datapoints: [
                            {
                                Floors: [
                                    {
                                        FloorImage: {
                                            Height: 661,
                                            Name: '03526824fb7cfabf467fde57003863c9.png',
                                            Width: 881,
                                        },
                                        ID: 1,
                                        Name: 'Floor 1',
                                        Objects: [
                                            {
                                                Height: 32,
                                                IOID: 7,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 71,
                                                Y: 203,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 8,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 68,
                                                Y: 351,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 58,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 316,
                                                Y: 369,
                                            },
                                            {
                                                Height: 32,
                                                IOID: 59,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 321,
                                                Y: 201,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 81,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 514,
                                                Y: 209,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 82,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 682,
                                                Y: 203,
                                            },
                                            {
                                                Height: 32,
                                                ObjID: 83,
                                                WebDesc: false,
                                                Width: 32,
                                                X: 690,
                                                Y: 332,
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 457,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'floorPlan.floorConfig',
                                objectid: 86,
                                range: [],
                                remote: true,
                                type: 32,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 86,
                        name: 'Floor plan 2 ',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 32,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 324,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 87,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 325,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 87,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 326,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 87,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 327,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 87,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 328,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 87,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 329,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 87,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 330,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 87,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 331,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 87,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 87,
                        name: 'Dimmer TEST drawing connectors',
                        properties: [
                            {
                                id: 194,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 195,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 196,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 197,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 198,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 199,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 200,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 201,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 332,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 88,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 333,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 88,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 334,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 88,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Configs: [
                                    {
                                        Description: 'dimm2 value',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ1',
                                        ReadOnly: true,
                                        Step: '1',
                                        Type: 2,
                                    },
                                ],
                                Modes: [
                                    {
                                        ID: 1,
                                        Name: 'New mode 1',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '60',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 335,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'opMode.config',
                                objectid: 88,
                                range: [],
                                remote: true,
                                type: 35,
                                writeprotect: false,
                            },
                            {
                                customname: 'dimm2 value',
                                id: 336,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value0',
                                objectid: 88,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 88,
                        name: 'Visualization block',
                        properties: [
                            {
                                id: 202,
                                pinid: 'a541c6d63588e698034ba382fd03b63f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 203,
                                pinid: 'f51f291f2bea7b4ee41eeea1751de39f',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 35,
                        visualisationpw: false,
                    },
                    {
                        category: 0,
                        datapoints: [
                            {
                                customname: '',
                                id: 22,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 89,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 23,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 89,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 24,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 89,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Configs: [
                                    {
                                        Description: 'a',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ1',
                                        ReadOnly: false,
                                        Step: '1',
                                        Type: 2,
                                    },
                                    {
                                        Description: 'b',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ2',
                                        ReadOnly: false,
                                        Step: '1',
                                        Type: 2,
                                    },
                                    {
                                        Description: 'c',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ3',
                                        ReadOnly: false,
                                        Step: '1',
                                        Type: 2,
                                    },
                                ],
                                Modes: [
                                    {
                                        ID: 0,
                                        Name: 'off',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 1,
                                        Name: 'all on',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '100',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '100',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 2,
                                        Name: '50',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '50',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '50',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '50',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 25,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'opMode.config',
                                objectid: 89,
                                range: [],
                                remote: true,
                                type: 35,
                                writeprotect: false,
                            },
                            {
                                customname: 'a',
                                id: 26,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value0',
                                objectid: 89,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'b',
                                id: 27,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value1',
                                objectid: 89,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'c',
                                id: 28,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value2',
                                objectid: 89,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 89,
                        name: 'Visualization block',
                        properties: [
                            {
                                id: 17,
                                pinid: 'a541c6d63588e698034ba382fd03b63f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 18,
                                pinid: 'f51f291f2bea7b4ee41eeea1751de39f',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: 2,
                        type: 35,
                        visualisationpw: false,
                    },
                    {
                        category: 5,
                        datapoints: [
                            {
                                customname: '',
                                id: 57,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice1',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 58,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice2',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 59,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice3',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 68,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice12',
                                objectid: 90,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 69,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice1',
                                objectid: 90,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 6,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 70,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice2',
                                objectid: 90,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 2,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 71,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice3',
                                objectid: 90,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                EnergyManagementConfig: {
                                    Devices: [
                                        {
                                            L1: true,
                                            L2: true,
                                            L3: true,
                                            MaxEnergy: 9,
                                            MaxPower: 6,
                                            MinPower: 3,
                                            MinRuntime: 39,
                                            Name: 'Car charger',
                                            PreparationTime: 9,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: ['2023-09-20T15:33:00Z'],
                                                    FinishTimeScheduler: [
                                                        75780, 83160, 4920, 36420, 19320, 71700, 7560,
                                                    ],
                                                    Hysteresis: 2,
                                                    Level: 0,
                                                    Mode: 1,
                                                },
                                            ],
                                        },
                                        {
                                            L1: true,
                                            L2: false,
                                            L3: false,
                                            MaxEnergy: 8,
                                            MaxPower: 2,
                                            MinPower: 0,
                                            MinRuntime: 2,
                                            Name: 'Air condition',
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [
                                                        14640, 75900, 39900, 7320, 10980, 15000, 11220,
                                                    ],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                    Priority: 1,
                                                },
                                            ],
                                        },
                                        {
                                            L1: true,
                                            L2: true,
                                            L3: false,
                                            MaxEnergy: 10,
                                            MaxPower: 80,
                                            MinPower: 60,
                                            MinRuntime: 21,
                                            Name: 'Garden water system',
                                            PreparationTime: 15,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: ['2023-09-12T16:20:00Z'],
                                                    FinishTimeScheduler: [
                                                        77400, 33000, 40080, 33600, 79500, 60900, 71400,
                                                    ],
                                                    Hysteresis: 7,
                                                    Level: 0,
                                                    Mode: 3,
                                                    Priority: 3,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                    Priority: 4,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 81,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'manager.config',
                                objectid: 90,
                                range: [],
                                remote: true,
                                type: 37,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 90,
                        name: 'Energy Manager',
                        properties: [
                            {
                                id: 25,
                                pinid: '286415d1e4b39c86e6ad09b40b8a902e',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 26,
                                pinid: '7e054ba83ac8126bed1d5f82e74be4f8',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 5,
                        roomid: 6,
                        type: 36,
                        visualisationpw: false,
                    },
                    {
                        category: 12,
                        datapoints: [
                            {
                                customname: '',
                                id: 458,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 91,
                                range: ['0', ''],
                                remote: false,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 459,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 91,
                                range: ['false', 'true'],
                                remote: false,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 460,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 91,
                                range: ['false', 'true'],
                                remote: false,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Configs: [
                                    {
                                        Description: 'a',
                                        Name: 'AQ1',
                                        ReadOnly: true,
                                        Type: 1,
                                    },
                                    {
                                        Description: 'b',
                                        Maximum: '389',
                                        Minimum: '55',
                                        Name: 'AQ2',
                                        ReadOnly: false,
                                        Step: '7',
                                        Type: 2,
                                        Unit: 'mbar',
                                    },
                                    {
                                        Description: 'c',
                                        DropdownList: [
                                            {
                                                Name: 'red',
                                                Value: 4,
                                            },
                                            {
                                                Name: 'blue',
                                                Value: 11,
                                            },
                                            {
                                                Name: 'yellow',
                                                Value: 22,
                                            },
                                            {
                                                Name: 'green',
                                                Value: 33,
                                            },
                                        ],
                                        Name: 'AQ3',
                                        ReadOnly: false,
                                        Type: 3,
                                    },
                                ],
                                Modes: [
                                    {
                                        ID: 0,
                                        Name: 'default',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: true,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '55',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '4',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 1,
                                        Name: 'New mode 1',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: false,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '132',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '33',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 2,
                                        Name: 'New mode 2',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: true,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '231',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '11',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 3,
                                        Name: 'New mode 3',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: false,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '87',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '22',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 461,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'opMode.config',
                                objectid: 91,
                                range: [],
                                remote: false,
                                type: 35,
                                writeprotect: false,
                            },
                            {
                                customname: 'a',
                                id: 462,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value0',
                                objectid: 91,
                                range: ['false', 'true'],
                                remote: false,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: 'b',
                                id: 463,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value1',
                                objectid: 91,
                                range: ['55', '389'],
                                remote: false,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'c',
                                id: 464,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value2',
                                objectid: 91,
                                range: [],
                                remote: false,
                                type: 7,
                                value: 4,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: false,
                        favourite: false,
                        id: 91,
                        name: 'Visualization block 3 local',
                        properties: [
                            {
                                id: 284,
                                pinid: 'a541c6d63588e698034ba382fd03b63f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 285,
                                pinid: 'f51f291f2bea7b4ee41eeea1751de39f',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 35,
                        visualisationpw: false,
                    },
                    {
                        category: 12,
                        datapoints: [
                            {
                                customname: '',
                                id: 465,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice1',
                                objectid: 94,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 469,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice5',
                                objectid: 94,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 470,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice6',
                                objectid: 94,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 471,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualModeDevice7',
                                objectid: 94,
                                range: ['0', '1', '2', '3', '4'],
                                remote: true,
                                type: 36,
                                value: 1,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 477,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutputDevice1',
                                objectid: 94,
                                range: ['', ''],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                EnergyManagementConfig: {
                                    Devices: [
                                        {
                                            L1: true,
                                            L2: true,
                                            L3: false,
                                            MaxEnergy: 2,
                                            MaxPower: 0.4,
                                            MinPower: 0.1,
                                            MinRuntime: 3,
                                            Name: 'dev4',
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            MinRuntime: 1,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                        {
                                            L1: false,
                                            L2: false,
                                            L3: false,
                                            PreparationTime: 1,
                                            Priorities: [
                                                {
                                                    FinishTimeCalendar: [],
                                                    FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
                                                    Hysteresis: 1,
                                                    Level: 0,
                                                    Mode: 2,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 489,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'manager.config',
                                objectid: 94,
                                range: [],
                                remote: true,
                                type: 37,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 94,
                        name: 'EnergyManager 2',
                        properties: [
                            {
                                id: 286,
                                pinid: '286415d1e4b39c86e6ad09b40b8a902e',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 287,
                                pinid: '7e054ba83ac8126bed1d5f82e74be4f8',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 36,
                        visualisationpw: false,
                    },
                    {
                        category: 3,
                        datapoints: [
                            {
                                Monitor: [
                                    {
                                        Configuration: {},
                                        IOid: 136,
                                        Log: false,
                                        Name: 'Battery level',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 137,
                                        Log: false,
                                        Name: 'Valveposition multilevel switch',
                                        Unit: '',
                                        View: true,
                                    },
                                    {
                                        Configuration: {},
                                        IOid: 138,
                                        Log: false,
                                        Name: 'Temperature multilevel sensor',
                                        Unit: '',
                                        View: true,
                                    },
                                ],
                                customname: '',
                                id: 490,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'monitor.monitorConfig',
                                objectid: 96,
                                range: [],
                                remote: true,
                                type: 31,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 96,
                        name: 'Monitor 5',
                        properties: [],
                        ranking: 0,
                        roomid: -1,
                        type: 31,
                        visualisationpw: false,
                    },
                    {
                        category: 12,
                        datapoints: [
                            {
                                customname: '',
                                id: 491,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 97,
                                range: ['false', 'true'],
                                remote: false,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 492,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSchedulerOn',
                                objectid: 97,
                                range: ['', ''],
                                remote: false,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 493,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 97,
                                range: ['false', 'true'],
                                remote: false,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 494,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 97,
                                range: ['false', 'true'],
                                remote: false,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                TimeProgram: {
                                    TimeProgramList: [
                                        {
                                            Name: 'TimeProgram1',
                                            SwitchPoints: [
                                                {
                                                    Time: '06:00',
                                                    Value: true,
                                                },
                                                {
                                                    Time: '22:00',
                                                    Value: false,
                                                },
                                            ],
                                            days: [0, 1, 2, 3, 4, 5, 6],
                                        },
                                    ],
                                },
                                customname: '',
                                id: 495,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'tp.timeProgram',
                                objectid: 97,
                                range: [],
                                remote: false,
                                type: 5,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: false,
                        favourite: false,
                        id: 97,
                        name: 'Timer switch 5',
                        properties: [
                            {
                                id: 288,
                                pinid: 'c5062afccca37597e0b031d3554c6db7',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 22,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 504,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 98,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 505,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 98,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 506,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 98,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 507,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 98,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 508,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 98,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 509,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 98,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 510,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 98,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 511,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 98,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 98,
                        name: 'Dimmer 1',
                        properties: [
                            {
                                id: 291,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 292,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 293,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 294,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 295,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 296,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 297,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 298,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 512,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 99,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 513,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 99,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 514,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 99,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 515,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 99,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 516,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 99,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 517,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 99,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 518,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 99,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 519,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 99,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 99,
                        name: 'Dimmer 2',
                        properties: [
                            {
                                id: 299,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 300,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 301,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 302,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 303,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 304,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 305,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 306,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 520,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSwitch',
                                objectid: 100,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 521,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 100,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 522,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 100,
                                range: ['', ''],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: true,
                            },
                            {
                                customname: '',
                                id: 523,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOn',
                                objectid: 100,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 524,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualCentralOff',
                                objectid: 100,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 525,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingUp',
                                objectid: 100,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 526,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualDimmingDown',
                                objectid: 100,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 527,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualAlarm',
                                objectid: 100,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 100,
                        name: 'Dimmer 3',
                        properties: [
                            {
                                id: 307,
                                pinid: '2552a9e4aa396e0b5e0fb69362553a24',
                                readonly: false,
                                value: '19',
                            },
                            {
                                id: 308,
                                pinid: 'c77c556afb644e82b30d630304d66986',
                                readonly: false,
                                value: '25',
                            },
                            {
                                id: 309,
                                pinid: '1145e3b5f9577dd7a918dc0625d214b0',
                                readonly: false,
                                value: '90',
                            },
                            {
                                id: 310,
                                pinid: '71a539346c269a860a339a7afc206edc',
                                readonly: false,
                                value: '50',
                            },
                            {
                                id: 311,
                                pinid: '64116f0b28a6f9a987408ef0c61f22a5',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 312,
                                pinid: '100797c13ab86f155a21490c65ab228e',
                                readonly: false,
                                value: '750',
                            },
                            {
                                id: 313,
                                pinid: 'ee99e5e2e7c09c3bdb535f259daa1ebc',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 314,
                                pinid: 'b5c511cfe23ef2c35ce43325e0d9cdf2',
                                readonly: false,
                                value: '2',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 12,
                        visualisationpw: false,
                    },
                    {
                        category: 12,
                        datapoints: [
                            {
                                customname: '',
                                id: 496,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 101,
                                range: ['0', ''],
                                remote: false,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 497,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 101,
                                range: ['false', 'true'],
                                remote: false,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 498,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 101,
                                range: ['false', 'true'],
                                remote: false,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Configs: [
                                    {
                                        Description: 'a',
                                        Name: 'AQ1',
                                        ReadOnly: true,
                                        Type: 1,
                                    },
                                    {
                                        Description: 'b',
                                        Maximum: '389',
                                        Minimum: '55',
                                        Name: 'AQ2',
                                        ReadOnly: false,
                                        Step: '7',
                                        Type: 2,
                                        Unit: '',
                                    },
                                    {
                                        Description: 'c',
                                        DropdownList: [
                                            {
                                                Name: 'red',
                                                Value: 4,
                                            },
                                            {
                                                Name: 'blue',
                                                Value: 11,
                                            },
                                            {
                                                Name: 'yellow',
                                                Value: 22,
                                            },
                                            {
                                                Name: 'green',
                                                Value: 33,
                                            },
                                        ],
                                        Name: 'AQ3',
                                        ReadOnly: false,
                                        Type: 3,
                                    },
                                    {
                                        Description: 'd',
                                        Name: 'AQ4',
                                        ReadOnly: false,
                                        Type: 1,
                                    },
                                ],
                                Modes: [
                                    {
                                        ID: 0,
                                        Name: 'default',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: true,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '55',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '4',
                                            },
                                            {
                                                Name: 'AQ4',
                                                Value: false,
                                            },
                                        ],
                                    },
                                    {
                                        ID: 1,
                                        Name: 'New mode 1',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: false,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '132',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '33',
                                            },
                                            {
                                                Name: 'AQ4',
                                                Value: false,
                                            },
                                        ],
                                    },
                                    {
                                        ID: 2,
                                        Name: 'New mode 2',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: true,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '231',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '11',
                                            },
                                            {
                                                Name: 'AQ4',
                                                Value: false,
                                            },
                                        ],
                                    },
                                    {
                                        ID: 3,
                                        Name: 'New mode 3',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: false,
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '87',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '22',
                                            },
                                            {
                                                Name: 'AQ4',
                                                Value: false,
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 499,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'opMode.config',
                                objectid: 101,
                                range: [],
                                remote: false,
                                type: 35,
                                writeprotect: false,
                            },
                            {
                                customname: 'a',
                                id: 500,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value0',
                                objectid: 101,
                                range: ['false', 'true'],
                                remote: false,
                                type: 6,
                                value: true,
                                writeprotect: false,
                            },
                            {
                                customname: 'b',
                                id: 501,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value1',
                                objectid: 101,
                                range: ['55', '389'],
                                remote: false,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'c',
                                id: 502,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value2',
                                objectid: 101,
                                range: [],
                                remote: false,
                                type: 7,
                                value: 4,
                                writeprotect: false,
                            },
                            {
                                customname: 'd',
                                id: 503,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value3',
                                objectid: 101,
                                range: ['false', 'true'],
                                remote: false,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: false,
                        favourite: false,
                        id: 101,
                        name: 'Visualization block 4 copy local',
                        properties: [
                            {
                                id: 289,
                                pinid: 'a541c6d63588e698034ba382fd03b63f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 290,
                                pinid: 'f51f291f2bea7b4ee41eeea1751de39f',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 35,
                        visualisationpw: false,
                    },
                    {
                        category: 5,
                        datapoints: [
                            {
                                customname: '',
                                id: 528,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSceneSelection',
                                objectid: 102,
                                range: ['0', ''],
                                remote: true,
                                type: 17,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 529,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualNextScene',
                                objectid: 102,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 530,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualPreviousScene',
                                objectid: 102,
                                range: ['false', 'true'],
                                remote: true,
                                type: -1,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                Configs: [
                                    {
                                        Description: 'a',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ1',
                                        ReadOnly: false,
                                        Step: '1',
                                        Type: 2,
                                        Unit: '',
                                    },
                                    {
                                        Description: 'b',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ2',
                                        ReadOnly: false,
                                        Step: '1',
                                        Type: 2,
                                        Unit: '',
                                    },
                                    {
                                        Description: 'c',
                                        Maximum: '100',
                                        Minimum: '0',
                                        Name: 'AQ3',
                                        ReadOnly: false,
                                        Step: '1',
                                        Type: 2,
                                        Unit: '',
                                    },
                                ],
                                Modes: [
                                    {
                                        ID: 0,
                                        Name: 'off',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '0',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '0',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '0',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 1,
                                        Name: 'all on',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '100',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '100',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '100',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 2,
                                        Name: '50',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '50',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '50',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '50',
                                            },
                                        ],
                                    },
                                    {
                                        ID: 3,
                                        Name: 'user mode',
                                        values: [
                                            {
                                                Name: 'AQ1',
                                                Value: '22',
                                            },
                                            {
                                                Name: 'AQ2',
                                                Value: '33',
                                            },
                                            {
                                                Name: 'AQ3',
                                                Value: '44',
                                            },
                                        ],
                                    },
                                ],
                                customname: '',
                                id: 531,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'opMode.config',
                                objectid: 102,
                                range: [],
                                remote: true,
                                type: 35,
                                writeprotect: false,
                            },
                            {
                                customname: 'a',
                                id: 532,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value0',
                                objectid: 102,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'b',
                                id: 533,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value1',
                                objectid: 102,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: 'c',
                                id: 534,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'opMode.value2',
                                objectid: 102,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: false,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 102,
                        name: 'Visualization block 2 copy',
                        properties: [
                            {
                                id: 315,
                                pinid: 'a541c6d63588e698034ba382fd03b63f',
                                readonly: false,
                                value: 'false',
                            },
                            {
                                id: 316,
                                pinid: 'f51f291f2bea7b4ee41eeea1751de39f',
                                readonly: false,
                                value: '0',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 35,
                        visualisationpw: false,
                    },
                    {
                        category: 1,
                        datapoints: [
                            {
                                customname: '',
                                id: 29,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualSlider',
                                objectid: 103,
                                range: ['0', '100'],
                                remote: true,
                                type: 4,
                                value: 0,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 30,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualEnable',
                                objectid: 103,
                                range: ['false', 'true'],
                                remote: true,
                                type: 6,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 31,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualCalibration',
                                objectid: 103,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: false,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 32,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualProportionalAmplification',
                                objectid: 103,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: 0.2,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 33,
                                ioid: -1,
                                isconfiguration: true,
                                name: 'VirtualSetpoint',
                                objectid: 103,
                                range: ['', ''],
                                remote: true,
                                type: -2,
                                value: 100,
                                writeprotect: false,
                            },
                            {
                                customname: '',
                                id: 34,
                                ioid: -1,
                                isconfiguration: false,
                                name: 'VirtualOutput',
                                objectid: 103,
                                range: ['0', '100'],
                                remote: true,
                                type: 7,
                                value: 0,
                                writeprotect: true,
                            },
                        ],
                        deviceid: 1,
                        externalaccess: true,
                        favourite: false,
                        id: 103,
                        name: 'Constant Light Controller',
                        properties: [
                            {
                                id: 19,
                                pinid: '8d7b2cc20c6aa2ed3eb4054e64457d44',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 20,
                                pinid: '2959565f83c93d0583b8463b5d9eb4b4',
                                readonly: false,
                                value: '0',
                            },
                            {
                                id: 21,
                                pinid: '90188727255b8ce6e0d19314257dde28',
                                readonly: false,
                                value: '100',
                            },
                            {
                                id: 22,
                                pinid: '958e8d9d4636f3f0226946e264c0863e',
                                readonly: false,
                                value: '0.2',
                            },
                            {
                                id: 23,
                                pinid: 'b412096df832c1c66cfa7b85a720cb8c',
                                readonly: false,
                                value: '10',
                            },
                            {
                                id: 24,
                                pinid: 'd1ebbadf9698cd1ab70c6fc0dc4eb7df',
                                readonly: false,
                                value: 'false',
                            },
                        ],
                        ranking: 0,
                        roomid: -1,
                        type: 33,
                        visualisationpw: false,
                    },
                ],
            },
            rooms: [
                {
                    category: 3,
                    id: 1,
                    name: 'Bedroom',
                    ranking: 0,
                },
                {
                    category: 16,
                    id: 2,
                    name: 'Central',
                    ranking: 0,
                },
                {
                    category: 4,
                    id: 3,
                    name: 'salon',
                    ranking: 1,
                },
                {
                    category: 13,
                    id: 4,
                    name: 'Balcony',
                    ranking: 4,
                },
                {
                    category: 6,
                    id: 5,
                    name: 'Hobby room',
                    ranking: 0,
                },
                {
                    category: 2,
                    id: 6,
                    name: 'Energy demo',
                    ranking: 0,
                },
            ],
            time: {
                datetime: '2023-10-20T13:49:18Z',
                timezone: 'Europe/Warsaw',
            },
            token: {
                token: 'Fl8uv6dqX40il9YxKlPbXvMShssR0P',
            },
            update: {
                checker: {
                    error: {},
                    policy: 'image',
                    timestamp: '2023-10-19T14:35:39Z',
                },
                state: 'NoImageExist',
                update: {
                    error: null,
                    peer: {
                        current: {
                            dependencies: {
                                mobileapp: '>=v2.40.0',
                                progtool: '>=v2.36.0',
                            },
                            name: 'radioserver',
                            sid: '001-alpha',
                            version: 'v2.22.0-alpha.31',
                        },
                        latest: {
                            dependencies: {},
                            name: 'radioserver',
                            version: '*',
                        },
                    },
                    sender: {
                        current: {
                            dependencies: {
                                mobileapp: '>=v2.40.0',
                                progtool: '>=v2.36.0',
                            },
                            name: 'radioserver',
                            sid: '001-alpha',
                            version: 'v2.22.0-alpha.31',
                        },
                        latest: {
                            dependencies: {
                                mobileapp: '>=v2.40.0',
                                progtool: '>=v2.36.0',
                            },
                            name: 'radioserver',
                            sid: '001-alpha',
                            version: 'v2.22.0-alpha.31',
                        },
                    },
                    summary: {
                        available: false,
                        compatible: true,
                        url: '',
                    },
                },
            },
            users: [
                {
                    deletable: false,
                    id: 1,
                    isadmin: true,
                    name: 'admin',
                    password:
                        'Mbf7HaS66BkbnaisY+6ZtLCLPwIwWfRAY7H5h4qcMfduUo93hKMuHrt8ytlNuULo65sE1MNwbNInzBoax9dTw/rKpZsBpkOS7/Y1RKZiluAqg+pf5EjhAFeUO5eyOxrt4tyOuuDFTuYHsI4+Zv0/7Df24NyZw1kiRDSJI7nd+ZvPEDNPBclujw4klIpGExD7B6D0Ive+BVjNSPVh75O0UZoPAlZCGIur0dj1PpanRAEUL1yqzYYCQEjR4H+DyAg/b4twXs3xFj2p58zIXsjDBD2QUxXwgUfWcb/O0Si1SMBpl/31Qgh/5t0yfiTn2tuD5sj9QZOtf9JQGEY6udfDkLHucNwqPMasEFNEyreipRrTf4LKL7DHiIs/YszLRN768Yr/njES1RRgE24BGlIZvea6RBg5VnoE0e07df6UbYvLjlVmICexCBxjl2L9vHxWhw6XexSMuBXdmaByVMawDnUozAfXbRn+3/DHsJjMjfiEgqjZzk0hPJmGYFtjDMkLoGrDiXWWhhiDp0EkQAy2XMvClcn87eGNLiTYUhw40qfLuXiDXn/TGjcHd7y/48wkIxRahp8pG++y1SYenXMRWaqBZUREpMEip+0Y83vfzooYOWCzGpKSubm2zA9YNVx0TT1auCRX72mmjOl26RlpDXzou/p6kHo25UER9+pVC4I=',
                    usergroups: [],
                },
                {
                    deletable: true,
                    id: 2,
                    isadmin: false,
                    name: 'Tom',
                    password:
                        'txiS1F1WgPGhEF40uBBgNt262XOVPLq9O40uWRPw67FFxBGpGCRdrRv1KociGAbPzObaB9R04AawxDlqijf/gQlg+Yn9r2fs9ObnCunSJq+R5pRcVoKcf7nBxNalNfc1uyiJmnMxhPQbO7WLkmUZDOMNRv1el0Vq0PYGitc8VH6Q3S754NE4FatCK7u4ZN2RCu2989qiUN9T9hxYhamBvKaMj7WgmWyiLcofslCOGYOoUBPxXtg3s4qf6rQzN2KbTzvCEpB6l007ZOpZYVqkgysl3ASn5OhRbL2asatSMitPlFpCW12dAFRoBO/38E1xu8VUhMAtkx7kEgDT3CTOoq74gXnH7jJ0bywYQgI4mUxH9qgbPYvD5adkgjmTylJYpS4RPLgXKjk7tWbyCjpWNFIvHEiepWfbft8HOvzBvrcX12jw4F0eiN0amZuqrXqaa+q8YK8ZXQ8/DkCIvnsCOEoOwwuTSuNt5f0I4gIp7KmZmpIapLGisI3FJet+fJJmV5PRWiNln6racbqf3rOPS9AoYJpkoht6yPZ6ldNcQlvmq5osgrnv9ig+b7wHqkglcb7Uhm/x12zHP8eUYtM60ZvXeeltXkKGBgFqGTirw41A1oVQjD/7k4X+BCqIAmMEXbuzaWGUwU74vT+vx9tiG0mYNJgFhfSWjbA+gj7vjqE=',
                    usergroups: [],
                },
                {
                    deletable: true,
                    id: 3,
                    isadmin: true,
                    name: 'AuntHelga',
                    password:
                        'ECXkGDY2gNwCUSi66EA2lDXR0CVdEWHin5VybiIyVj4AnwOt5hJLdiyJ/U9zCg3awQ55BvkHmIiA0b+7bETPt6H30s9TYl8h734gWs8gbRRtac2eLe1iIaLG+nav9yDln3u4ir/SHRKp1jEbKoBXBnZJiRqZZWiNGe4jbQHNZJdjNoz4bBEIyVkrcLALQYGBg3bpzCzClO5dOgAY03qBmSCMNTd9NZWk+sJ0HEfZSmqG+RnaGs1twCqk3ufKLFK4AdGEr+zAurxmTubdlI7kPKDkZFLCpFt3QcZSwKXXWlSiBOy9Hdu0R/RlG562Eio+CegoGvB/bkX5UdoN5GqaLJTj4gUsOpUnYh2+rdvJ5MOxoxMH8CJ6L6Ky2FKvUii5ao0/y3GYJb8Dc5/fwSwvRJGrM6xgpMEv40v/vIdI3V8nmu4cYq8UlnqrKLB9YJflxvvAZmdMHYyiHDZ86GPrPLXonK2HbhEWFF9SLqdnIg81xJLX4lUicCNHA1P4Phu3LbeMp2+Oy5i3l3T+P9u+2Be2u48Z5xzORdkrBWoAet3PYaQPqMwuIYcVn2Ia/x06Ca26H1rL+bz8kGEHdJD/fstnFuixuKBYeDx0abjwETKhqVemp4rs8YEA2PyTw6rmuY68WPqk1rtxPsGsNFAB6h23RkHBInH14LtR1nJsxNg=',
                    usergroups: [],
                },
                {
                    deletable: true,
                    id: 4,
                    isadmin: false,
                    name: 'Bob',
                    password:
                        'hydwkQcf6UJORzZa9QHqOL1tGC5vwYKWumpzrmG/SUj2op/8H0IqlqsJAH4dj/Y5jmIgTEkZmZW7wZPOyFeKLm3hPzSCY0/6wOJWcYxc8JRDi/jD5wMNSU6nvdh9bp2m/63FCpdP2DnySrsB+iJ0FwVC4TGbnatFVazVfRoogxx7YyWRA34vI9jOXx82XR2679Y6GlSCK8CKacXTCiNblv0Y842Vko/sCtpHJKnJZD7oTHNw1/FvSxaaCWgk6jPfVb2bFL81n02Hny0R30JiZFmIQ90+dEAGxBlynEEkSwSVRGArP7/81Aw625PuY7U+/Hw+nh6xzJiaJU3C1XgkxDcffXtBe7NTbycO3V5bCJsN7qxWhVc9oq9x9MzQDt/YszC4tlp2JlmzQdVQUR7B1mNzPoezCdiDasebi3spnaXR3xD//HAXd3CPIEIrfoJn1GgNc5Dnd2RKw/rz+7d2Htux6bqIbtYbczmOMMhce6zmxDI1hVEaiTyQYVPhd0C3Wfi7oo+uNHhUbB6Ogj/t8TkA24B1/fq7z9+nwPXuwiEsFvcsDaz+LyYmxz75Zd25gPbodgiZtDRHc7n+4Vb9VjL+T52UYIa/zgRcFd7hwSXp1sGlQ44cAmmvl3Nof/hc9+ygl4NwbyupJqyYZYfaZufanA+IkAetjXQOEB6gbX0=',
                    usergroups: [],
                },
                {
                    deletable: true,
                    id: 5,
                    isadmin: false,
                    name: 'Ann',
                    password:
                        'lIsIILAPlHeRjzPiFtvxDR2BUiPiD8UVNNEcug3HSiKZ4JW8JrdJ4MmKlONGc8ayWbC7QyiIYwugQds3NifTlhs4aoQ6eJHzMcibszOTPZeuYyqKx41VvKLGKAkYUizooXZSSgsd2GmZAeNBRbO4XiurC9pNeFV53et2ZJfFH7pZwi3iDtCq4N298PH2aIoSRyS7tkB3o6cYMTOZCrykWcN6sWZBQD50RUCcNhNcW1Q3YzqUsSCUWK2JKCE2w/mtNF5P9zocXguEgjlsfxmkkp4k1c+fOOtVvgQ4d48spiJiQBO60T8FNMD92WUIgU3fybUAOxGnSjt1NJm1UCzkPXaLi79rR9vUz619BPLtZ1PXmr8IfEkX5ptt62Tgg+M+xZ1lQHJDfI+zf7UJ4KMTIdRwzOiSPlS++4PttTHtt2bY1mxdQlWJQoPLTv77BsC0JlgqUc8DnV2RAlP+hlA8QiNEoh0qLufe80QEoOZ/vyqhGG3SYVs7iql7nPVtILTf8w3O7DS2rtXJrQO0jxRDpOzpN6fv14T0lgNzxqsmq6XMElDW69baC+CUSIPDe+jwP/8kvBP1jZS2W3zIYH1ns7uQFeTtpqPoFpi0gbZVoAs7nSrNkIHw7AWh5s6Ij8hCHEiO8fW75ngUkx3gi/gpR6tGUD15s2Hfmr+k25PP+KY=',
                    usergroups: [],
                },
            ],
        }),
    ) as SetupInfo;
