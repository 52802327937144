import { ThemedButton, ThemedInput, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { isEmpty } from 'helpers/StringHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Styled from './InviteUserModal.styles';

type Props = {
    onCancel: () => void;
    onRefresh: () => void;
    onInvite: (
        email: string,
        name: string,
        url: string,
    ) => Promise<{
        code: number;
    }>;
};

const defaultValue = { email: '', name: '' };

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const reactRedirectUri = window.location.hostname.includes('localhost')
    ? 'http://localhost:3000/accept/'
    : `https://${window.location.hostname}/accept/`;

const InviteUserModal = ({ onRefresh, onCancel, onInvite }: Props) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState<{ email: string; name: string }>(defaultValue);

    const onInviteUser = async () => {
        setLoading(true);

        const result = await onInvite(inputValue.email, inputValue.name, reactRedirectUri);

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(false);
            return;
        }

        setLoading(false);
        onCancel();
        toast.success(t('general.invitationHasBeenSendToUser'));
        onRefresh();
    };

    return (
        <ThemedModal
            width={300}
            title={t('general.inviteUser')}
            open
            onCancel={onCancel}
            style={{ top: 200 }}
            footer={
                <Footer>
                    <ThemedButton onClick={onCancel} $version="2" disabled={loading}>
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton
                        $version="2"
                        disabled={
                            isEmpty(inputValue.email) || isEmpty(inputValue.name) || !emailRegex.test(inputValue.email)
                        }
                        loading={loading}
                        onClick={onInviteUser}
                    >
                        {t('general.send')}
                    </ThemedButton>
                </Footer>
            }
        >
            <Styled.Title>{t('general.email')}</Styled.Title>
            <ThemedInput
                $width="100%"
                status={isEmpty(inputValue.email) || !emailRegex.test(inputValue.email) ? 'error' : undefined}
                onChange={(value) => {
                    setInputValue((prev) => ({ ...prev, email: value.currentTarget.value }));
                }}
                value={inputValue.email}
                placeholder={t('general.email')}
            />
            {isEmpty(inputValue.email) && <Styled.Error>{t('errors.fieldCannotBeEmpty')}</Styled.Error>}
            {!isEmpty(inputValue.email) && !emailRegex.test(inputValue.email) && (
                <Styled.Error>{t('errors.emailIsNotValid')}</Styled.Error>
            )}
            <Styled.Space />
            <Styled.Title>{t('general.name')}</Styled.Title>
            <ThemedInput
                $width="100%"
                status={isEmpty(inputValue.name) ? 'error' : undefined}
                onChange={(value) => {
                    setInputValue((prev) => ({ ...prev, name: value.currentTarget.value }));
                }}
                value={inputValue.name}
                placeholder={t('general.name')}
            />
            {isEmpty(inputValue.name) && <Styled.Error>{t('errors.fieldCannotBeEmpty')}</Styled.Error>}
        </ThemedModal>
    );
};

export default InviteUserModal;
