import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 20px 0;
`;

export const UpdateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const CurrentVersion = styled.div`
    margin-top: 10px;
    color: #e74c3c;
`;

export const NewVersion = styled.div`
    color: #07bc0c;
`;

export const UpdateButton = styled(ThemedButton)`
    margin-top: 10px;
`;

export const UpdateUpToDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const UpToDateVersion = styled.div`
    margin-top: 10px;
    color: #07bc0c;
`;

export const MissingSdCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

export const MissingSdCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5px;
`;

export const RefreshButton = styled(ThemedButton)`
    margin-left: 10px;
`;

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;
`;

export const Progress = styled.div`
    margin-top: 10px;
    font-weight: 500;
`;
