import { routes } from 'App';
import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedCheckableTag, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { saveAs } from 'file-saver';
import useGoBack from 'hooks/useGoBack';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuRefreshCcw } from 'react-icons/lu';
import { MdDownload } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import * as Styled from './CameraPage.styles';
import demoImage from './demoImage.png';

const CameraPage = () => {
    const { isDemo } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { serverConfigLoading, currentServer, currentVirtualDevice } = useServerConfig();
    const { cameraIndex, licenseId } = useParams<{ cameraIndex: string; licenseId: string }>();
    const { apiServerFetch } = useServerApi();
    const { onGoBack, state } = useGoBack();
    const [config, setConfig] = useState<{ image?: string; loading: boolean }>({ loading: true });

    const camerasDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.CameraConfig),
        [currentVirtualDevice?.datapoints],
    );

    const currentCamera = useMemo(
        () => camerasDatapoint?.Cameras?.[Number(cameraIndex ?? 0)],
        [camerasDatapoint?.Cameras, cameraIndex],
    );

    const fetchImage = async () => {
        if (isDemo) {
            setConfig({ loading: false, image: demoImage });
            return;
        }

        if (!currentCamera) {
            return;
        }
        setConfig({ loading: true });

        const url = btoa(atob(currentCamera.image).replace('https', 'http').replace('443', '80'));

        const result = await apiServerFetch<{ data: string }>('camera', `?url=${url}`);

        if (!result.result?.data) {
            setConfig({ loading: false });
            return;
        }

        setConfig({ loading: false, image: result.result.data });
    };

    useEffect(() => {
        fetchImage();
    }, [currentCamera]);

    const navigateToCamera = (index: number) => {
        if (Number(cameraIndex) === index) {
            return;
        }

        if (location.pathname.startsWith(routes.groupActions)) {
            return navigate(
                {
                    pathname: `${routes.groupActions}/${licenseId}/${currentServer?.id}/camera/${currentVirtualDevice?.id}/${index}`,
                },
                { state: state },
            );
        }

        navigate(
            {
                pathname: `/server/${currentServer?.id}/camera/${currentVirtualDevice?.id}/${index}`,
            },
            { state: state },
        );
    };

    const saveFile = async () => {
        if (!config.image) {
            return;
        }

        const imageTitle = `${currentCamera?.Name}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.png`;

        const imgBlob = await (await fetch(isDemo ? config.image : `data:image/png;base64, ${config.image}`)).blob();
        saveAs(imgBlob, imageTitle);
    };

    if (serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!currentVirtualDevice || !camerasDatapoint) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Space wrap>
                    {camerasDatapoint.Cameras?.map((camera, index) => (
                        <ThemedCheckableTag
                            key={index}
                            onClick={() => navigateToCamera(index)}
                            checked={Number(cameraIndex) === index}
                        >
                            <Styled.TagWrapper>{camera.Name}</Styled.TagWrapper>
                        </ThemedCheckableTag>
                    ))}
                </Space>
                <Styled.ButtonWrapper size={20} wrap>
                    <ThemedButton loading={config.loading} icon={<LuRefreshCcw />} onClick={fetchImage}>
                        {t('general.refresh')}
                    </ThemedButton>
                    <ThemedButton disabled={config.loading || !config.image} icon={<MdDownload />} onClick={saveFile}>
                        {t('general.download')}
                    </ThemedButton>
                    <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
                </Styled.ButtonWrapper>
            </Styled.HeaderWrapper>
            {config.loading && <ThemedSpin size="large" />}
            {!config.loading && config.image && (
                <img src={isDemo ? config.image : `data:image/png;base64, ${config.image}`} />
            )}
            {!config.loading && !config.image && <EmptyError title={t('errors.couldNotLoadImage')} />}
        </Styled.MainWrapper>
    );
};

export default CameraPage;
