import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import HidingView from '../HidingView/HidingView';
import * as Styled from './AlarmClock.styles';

const AlarmClock = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints, category } = virtualDevice;

    const isAlarmDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualIsAlarm),
        [datapoints],
    );
    const isSnoozeDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualIsSnooze),
        [datapoints],
    );
    const snoozeIntervalDurationDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSnoozeIntervalDuration,
            ),
        [datapoints],
    );
    const quiteAlarmDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarmClock),
        [datapoints],
    );
    const startSnoozeDatapoint = useMemo(
        () =>
            datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStartSnoozeTimer),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {isAlarmDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={isAlarmDatapoint} />}
            {isSnoozeDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={isSnoozeDatapoint} />}
            {snoozeIntervalDurationDatapoint && (
                <DatapointValueView sufix=" min" valueOrDp={snoozeIntervalDurationDatapoint} />
            )}
            <HidingView numberOfVisible={0} category={category}>
                {snoozeIntervalDurationDatapoint && (
                    <DatapointSliderView
                        hideValueView
                        sufix=" min"
                        datapoint={snoozeIntervalDurationDatapoint}
                        virtualDevice={virtualDevice}
                    />
                )}
                {quiteAlarmDatapoint && <DatapointButtonView datapoint={quiteAlarmDatapoint} />}
                {startSnoozeDatapoint && <DatapointButtonView datapoint={startSnoozeDatapoint} />}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default AlarmClock;
