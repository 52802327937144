import EmptyError from 'components/EmptyError/EmptyError';
import { ControlsSpace } from 'components/ServerControls/ServerControls.styles';
import { ThemedCheckableTag, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { useServerConfig } from 'hooks/useServerConfig';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline } from 'react-icons/io5';
import { MdPeopleOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import * as Styled from './SettingsPage.styles';

const iconStyle: CSSProperties = { fontSize: 23, minWidth: 23 };

const SettingsPage = () => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const { currentServer, serverConfigLoading, currentUser } = useServerConfig();

    const settingsRoutesConfigs = useMemo(
        () => [
            {
                title: t('settings.usersConfiguration.title'),
                description: t('settings.usersConfiguration.description'),
                icon: <MdPeopleOutline style={iconStyle} />,
                path: 'users',
                visible: currentUser?.isadmin,
            },
            {
                title: t('settings.advancedProperties.title'),
                description: t('settings.advancedProperties.description'),
                icon: <IoAddCircleOutline style={iconStyle} />,
                path: 'advanced',
                visible: currentUser?.isadmin,
            },
        ],
        [currentUser],
    );

    const filteredVisibleConfigs = useMemo(
        () => settingsRoutesConfigs.filter((x) => x.visible),
        [settingsRoutesConfigs],
    );

    if (serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (filteredVisibleConfigs.length === 0) {
        return <EmptyError title={t('errors.noAvailableSettings')} />;
    }

    return (
        <Styled.MainWrapper>
            <ControlsSpace size={20} wrap>
                {filteredVisibleConfigs.map((setting, index) => (
                    <ThemedCheckableTag
                        key={index}
                        checked={false}
                        onClick={() => navigation(`/server/${currentServer.id}/settings/${setting.path}`)}
                    >
                        <Styled.SettingWrapper>
                            <Styled.Title>
                                {setting.icon}
                                <div>{setting.title}</div>
                            </Styled.Title>
                            {setting.description}
                        </Styled.SettingWrapper>
                    </ThemedCheckableTag>
                ))}
            </ControlsSpace>
        </Styled.MainWrapper>
    );
};

export default SettingsPage;
