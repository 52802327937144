import styled from 'styled-components';

export const TagTitle = styled.div`
    padding: 4px 8px;
`;

export const MainWrapper = styled.div`
    margin: 20px 0 40px;
`;

export const TagWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const OverrideWrapper = styled.div`
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;
`;
