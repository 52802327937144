import {
    ThemedButton,
    ThemedInput,
    ThemedInputPassword,
    ThemedModal,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { encryptRSA } from 'helpers/RSAHelper';
import { useServerApi } from 'hooks/useServerApi';
import { User } from 'models/server/User';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getValidationNameError, isEmpty, validatePassword } from '../../helpers/StringHelper';
import * as Styled from './AddUserModal.styles';

interface Props {
    users: User[];
    onFinished?: () => void;
    onCancel?: () => void;
}

const AddUserModal = (props: Props): JSX.Element => {
    const { users, onFinished, onCancel } = props;
    const { t } = useTranslation();
    const { apiServerFetch } = useServerApi();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const usernameError = useMemo(() => getValidationNameError(username), [username]);

    const isError =
        isEmpty(username) ||
        users?.some((x) => x.name === username) ||
        isEmpty(password) ||
        !validatePassword(password) ||
        !!usernameError;

    const onAdd = async () => {
        if (!users) {
            return;
        }
        try {
            setIsLoading(true);

            const result = await apiServerFetch('users', undefined, 'POST', {
                users: [
                    {
                        name: username,
                        password: await encryptRSA(password),
                        deletable: true,
                        id: users?.length + 2,
                        isadmin: false,
                    },
                ],
            });

            if (result.code !== 200) {
                showError();
                return;
            }

            onFinished?.();
        } catch {
        } finally {
            setIsLoading(false);
        }
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    return (
        <ThemedModal
            width={300}
            title={t('addUserModal.addUser')}
            open={true}
            onCancel={onCancel}
            style={{ top: 200 }}
            closable={false}
            maskClosable={false}
            footer={
                <Footer>
                    <ThemedButton onClick={onCancel} $version="2" disabled={isLoading}>
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton $version="2" disabled={isError} onClick={onAdd} loading={isLoading}>
                        {t('general.add')}
                    </ThemedButton>
                </Footer>
            }
        >
            <ThemedInput
                $width="100%"
                autoComplete="new-password"
                status={
                    isEmpty(username) || users?.some((x) => x.name === username) || usernameError ? 'error' : undefined
                }
                onChange={(value) => {
                    setUsername(value.currentTarget.value);
                }}
                value={username}
                placeholder={t('usersConfiguration.userName')}
            />
            {users?.some((x) => x.name === username) && <Styled.Error>{t('errors.NameAlreadyExist')}</Styled.Error>}
            {isEmpty(username) && <Styled.Error>{t('errors.fieldCannotBeEmpty')}</Styled.Error>}
            {!isEmpty(username) && usernameError && <Styled.Error>{usernameError}</Styled.Error>}
            <ThemedInputPassword
                $marginTop={10}
                autoComplete="new-password"
                status={isError ? 'error' : undefined}
                onChange={(value) => {
                    setPassword(value.currentTarget.value);
                }}
                value={password}
                placeholder={t('usersConfiguration.userPassword')}
            />
            {isEmpty(password) && <Styled.Error>{t('errors.fieldCannotBeEmpty')}</Styled.Error>}
            {!isEmpty(password) && !validatePassword(password) && (
                <Styled.Error>{t('errors.passwordNotValid')}</Styled.Error>
            )}
        </ThemedModal>
    );
};

export default AddUserModal;
