import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './StaircaseLight.styles';

const StaircaseLight = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { t } = useTranslation();
    const { datapoints } = virtualDevice;

    const mainSwitchDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
            ),
        [datapoints],
    );
    const activeDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualOutput,
            ),
        [datapoints],
    );
    const countdownDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualCountdown,
            ),
        [datapoints],
    );
    const startCountdownDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStartTimer,
            ),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {mainSwitchDatapoint && (
                <DatapointSwitchView datapoint={mainSwitchDatapoint} virtualDevice={virtualDevice} />
            )}
            {activeDatapoint && (
                <DatapointValueView
                    title={t('datapointNames.VirtualLight')}
                    showOnOffAsBoolValue
                    valueOrDp={activeDatapoint}
                />
            )}
            {countdownDatapoint && <DatapointValueView sufix="s" valueOrDp={countdownDatapoint} />}
            {startCountdownDatapoint && <DatapointButtonView datapoint={startCountdownDatapoint} />}
        </Styled.MainWrapper>
    );
};

export default StaircaseLight;
