import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 25px;
    gap: 15px;
    justify-content: flex-end;

    @media (max-width: 750px) {
        flex-direction: column-reverse;
    }
`;

export const HeaderTagWrapper = styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const TagImg = styled.img`
    object-fit: scale-down;
    max-width: 25px;
`;
