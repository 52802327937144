import { isEmpty } from 'helpers/StringHelper';
import { ioDashboardValueState } from 'pages/HomePage/ServerView/ServerView';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

type Props = {
    unit: string;
    ioId: number;
    serialNumber: string;
};

const IoValueFloorControlView = ({ unit, ioId, serialNumber }: Props) => {
    const { t } = useTranslation();
    const [dashboardIoValues] = useRecoilState(ioDashboardValueState);

    const currentIoValue = useMemo(
        () => dashboardIoValues.find((x) => x.ioId === ioId && x.serialNumber === serialNumber)?.value,
        [dashboardIoValues, ioId, serialNumber],
    );

    return isEmpty(currentIoValue?.toString()) ? (
        <div>{t('monitor.invalid')}</div>
    ) : (
        <div>{`${currentIoValue} ${unit ?? ''}`}</div>
    );
};

export default IoValueFloorControlView;
