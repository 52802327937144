import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const FloorTag = styled(ThemedCheckableTag)`
    padding: 8px 16px;
`;

export const ControlsWrapper = styled.div<{ $width: number; $height: number }>`
    height: ${(props) => props.$height}px;
    width: ${(props) => props.$width}px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: red;
    z-index: 1;
`;

export const FloorWrapper = styled.div`
    position: relative;
`;

export const WholePageEmptyError = styled(EmptyError)`
    height: 100%;
`;
