import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './ToiletVentilation.styles';

const ToiletVentilation = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const mainSwitchDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualTrigger),
        [datapoints],
    );
    const lightValueDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualLight),
        [datapoints],
    );
    const fanValueDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualFan),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {mainSwitchDatapoint && (
                <DatapointSwitchView isMainSwitch datapoint={mainSwitchDatapoint} virtualDevice={virtualDevice} />
            )}
            {lightValueDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={lightValueDatapoint} />}
            {fanValueDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={fanValueDatapoint} />}
        </Styled.MainWrapper>
    );
};

export default ToiletVentilation;
