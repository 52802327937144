import { Collapse, Space } from 'antd';
import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
`;

export const NoServersWrapper = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

export const NoServersTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
`;

export const HomePageHeader = styled(Space)`
    display: flex;
    justify-content: space-between;
`;

export const HomePageHeaderLeftSide = styled(Space)`
    flex: 1;
    display: flex;
    gap: 20px;
`;

export const ClosedWrapper = styled(ThemedCheckableTag)`
    align-self: center;
    width: 80px;
    margin-top: -3px;
    display: flex;
    justify-content: center;
    padding: 6px 0 3px;
`;

export const MapWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MapCollaps = styled(Collapse)`
    .ant-collapse-header {
        align-items: center !important;
    }

    .ant-collapse-expand-icon {
        height: 0 !important;
        width: 0;
        padding-inline-end: 0 !important;
    }
`;
