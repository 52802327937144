import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const TagWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0;
    min-width: 50px;
    min-height: 50px;
    max-width: 120px;
`;

export const TagImg = styled.img`
    object-fit: scale-down;
    max-width: 20px;
`;

export const TagTitle = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    font-weight: 500;
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Row = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`;

export const SelectWrapper = styled.div`
    margin-left: auto;
    width: 250px;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        gap: 20px;
        margin-left: 0;
    }
`;

export const CategoryTagWrapper = styled(TagWrapper)`
    min-width: 45px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CheckTag = styled(ThemedCheckableTag)<{ $color?: string }>`
    padding: 0;
    border: 1px solid ${(props) => props.$color};
`;
