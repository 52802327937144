import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const Row = styled.div`
    display: flex;
    gap: 10px;
`;

export const Empty = styled.div`
    text-align: center;
    color: ${(props) => props.theme.colors.error};
`;
