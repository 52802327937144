import { ButtonProps } from 'antd';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { getVirtualDatapointNameTranslation } from 'helpers/StringHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import { Datapoint } from 'models/server/Datapoint';
import { useMemo, useState } from 'react';
import EnterPinModal from '../AlphatronicControl/EnterPinModal/EnterPinModal';

export type DatapointButtonViewProps = ButtonProps & {
    $width?: number | undefined;
} & {
    datapoint: Datapoint;
    useCustomName?: boolean;
    hideName?: boolean;
    fill?: boolean;
    codeConfig?: { codeRequired: boolean; color: string };
};

const DatapointButtonView = ({
    datapoint,
    useCustomName,
    hideName,
    fill,
    codeConfig,
    ...rest
}: DatapointButtonViewProps) => {
    const { datapointLoading, onDatapointButtonClick } = useDatapoint();

    const [codeModalVisible, setCodeModalVisible] = useState(false);

    const name = useMemo(() => {
        if (useCustomName) {
            return datapoint.customname;
        }

        return getVirtualDatapointNameTranslation(datapoint.name);
    }, [datapoint, useCustomName]);

    const onButtonClicked = () => {
        if (codeConfig?.codeRequired) {
            setCodeModalVisible(true);
            return;
        }

        onDatapointButtonClick(datapoint.id);
    };

    return (
        <>
            <ThemedButton
                {...rest}
                $version="virtualDevice"
                $fill={fill}
                onClick={onButtonClicked}
                loading={datapointLoading === datapoint.id}
            >
                {!hideName && name}
            </ThemedButton>
            {codeConfig && codeModalVisible && (
                <EnterPinModal
                    datapoint={datapoint}
                    onCancel={() => setCodeModalVisible(false)}
                    color={codeConfig.color}
                />
            )}
        </>
    );
};

export default DatapointButtonView;
