import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { CreateView } from '../CreateWpcProjectPage';

import { routes } from 'App';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import useLocalServerApi from 'hooks/useLocalServerApi';
import { t } from 'i18next';
import { WpcAuthContext } from 'pages/Wpc/contexts/WpcAuthContext';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import DraggableSchemeElements from './DraggableSchemeElements/DraggableSchemeElements';
import * as Styled from './WpcSchemeEdit.styles';
import WpcSelectElementModal from './WpcSelectElementModal/WpcSelectElementModal';
import { WpcElementType } from '../../WpcDashboardPage/WpcDashboardPage';
import ElementsScheme from './ElementsScheme/ElementsScheme';

type Props = {
    schemesToSelect: (SchemeConfig & { title: string })[];
    selectedScheme?: number;
    onSetCurrentView: Dispatch<SetStateAction<CreateView>>;
};

export type WpcElement = {
    type: WpcElementType;
    name: string;
    icon: JSX.Element;
    removable: boolean;
    schemeImage: string;
};

export type SchemeConfig = {
    schemeId: number;
    elements: number[];
};

const removeAt = <T,>(arr: T[], i: number): T[] => [...arr.slice(0, i), ...arr.slice(i + 1)];

const changeAt = <T,>(arr: T[], i: number, newValue: T): T[] => [...arr.slice(0, i), newValue, ...arr.slice(i + 1)];

const WpcSchemeEdit = ({ selectedScheme, schemesToSelect, onSetCurrentView }: Props) => {
    const { localServerFetch } = useLocalServerApi();
    const navigate = useNavigate();
    const { scheme, refech } = useContext(WpcAuthContext);

    const [loading, setLoading] = useState(false);
    const [addElementVisible, setAddElementVisible] = useState(false);
    const [selectedElements, setSelectedElements] = useState<number[]>(schemesToSelect[selectedScheme ?? 0].elements);

    const handleSave = async () => {
        if (selectedScheme === undefined) {
            return;
        }

        setLoading(true);

        const payload: SchemeConfig = {
            schemeId: schemesToSelect[selectedScheme].schemeId,
            elements: selectedElements,
        };
        const { code } = await localServerFetch({
            api: 'storage',
            params: scheme ? `?id=${scheme.id}` : undefined,
            method: scheme ? 'PUT' : 'POST',
            body: { payload },
        });

        if (code !== 200) {
            setLoading(false);
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }
        await refech();
        navigate(routes.home);
    };

    const handleGoBack = () => {
        onSetCurrentView('schemeSelection');
    };

    const handleDuplicate = (index: number) => {
        setSelectedElements((prev) => [...prev, prev[index]]);
    };

    const handleRemove = (index: number) => {
        setSelectedElements((prev) => removeAt(prev, index));
    };

    const handleAddElement = (type: number) => {
        setSelectedElements((prev) => [...prev, type]);
    };

    const handleChangeType = (index: number) => {
        if (
            selectedElements[index] !== WpcElementType.DirectHeatingCircuit &&
            selectedElements[index] !== WpcElementType.MixedHeatingCircuit
        ) {
            return;
        }

        setSelectedElements((prev) =>
            changeAt(
                prev,
                index,
                selectedElements[index] === WpcElementType.DirectHeatingCircuit
                    ? WpcElementType.MixedHeatingCircuit
                    : WpcElementType.DirectHeatingCircuit,
            ),
        );
    };

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Styled.Title>{t('wpc.editScheme')}</Styled.Title>
                <Styled.Buttons>
                    <ThemedButton disabled={loading} onClick={handleGoBack}>
                        {t('general.goBack')}
                    </ThemedButton>
                    <ThemedButton
                        $action
                        disabled={selectedElements.length === 0 || selectedScheme === undefined}
                        onClick={handleSave}
                        loading={loading}
                    >
                        {t('general.save')}
                    </ThemedButton>
                </Styled.Buttons>
            </Styled.Header>
            <Styled.SelectedImageWrapper checked={false}>
                <ElementsScheme elements={selectedElements} />
            </Styled.SelectedImageWrapper>
            <div>
                <ThemedButton disabled={loading} onClick={() => setAddElementVisible(true)}>
                    {t('general.addElement')}
                </ThemedButton>
            </div>
            <DraggableSchemeElements
                selectedElements={selectedElements}
                setSelectedElements={setSelectedElements}
                onRemove={handleRemove}
                onDuplicate={handleDuplicate}
                onChangeType={handleChangeType}
            />
            <WpcSelectElementModal
                visible={addElementVisible}
                onClose={() => setAddElementVisible(false)}
                onElementSelected={handleAddElement}
            />
        </Styled.Wrapper>
    );
};

export default WpcSchemeEdit;
