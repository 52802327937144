import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './EnergyMonitor.styles';

const EnergyMonitor = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const energyHourDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.EnergyStatistics_Hour && x.name == DatapointNames.VirtualHourEnergy,
            ),
        [datapoints],
    );
    const energyDayDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.EnergyStatistics_Day && x.name == DatapointNames.VirtualDayEnergy,
            ),
        [datapoints],
    );
    const energyMonthDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.EnergyStatistics_Month && x.name == DatapointNames.VirtualMonthEnergy,
            ),
        [datapoints],
    );
    const energyYearDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.EnergyStatistics_Year && x.name == DatapointNames.VirtualYearEnergy,
            ),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {energyHourDatapoint && <DatapointValueView rounding={2} valueOrDp={energyHourDatapoint} sufix=" kWh" />}
            {energyDayDatapoint && <DatapointValueView rounding={2} valueOrDp={energyDayDatapoint} sufix=" kWh" />}
            {energyMonthDatapoint && <DatapointValueView rounding={2} valueOrDp={energyMonthDatapoint} sufix=" kWh" />}
            {energyYearDatapoint && <DatapointValueView rounding={2} valueOrDp={energyYearDatapoint} sufix=" kWh" />}
        </Styled.MainWrapper>
    );
};

export default EnergyMonitor;
