import { ButtonProps } from 'antd';
import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import { useImages } from 'hooks/useImages';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import * as Styled from './CentralHomeButtons.styles';

const buttonConfig: ButtonProps & {
    $width?: number | undefined;
} = {
    shape: 'round',
    $width: 55,
};

const CentralHomeButtons = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;
    const { in_home, away_from_home, sleep } = useImages();
    const { onDatapointButtonClick, datapointLoading } = useDatapoint();

    const commingHomeDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualComingHome),
        [datapoints],
    );
    const sleepDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualSleep),
        [datapoints],
    );
    const leavingHomeDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualLeavingHome),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {commingHomeDatapoint && (
                <ThemedButton
                    loading={datapointLoading === commingHomeDatapoint.id}
                    onClick={() => onDatapointButtonClick(commingHomeDatapoint.id)}
                    {...buttonConfig}
                    icon={<Styled.ButtonImage src={in_home} />}
                />
            )}
            {leavingHomeDatapoint && (
                <ThemedButton
                    loading={datapointLoading === leavingHomeDatapoint.id}
                    onClick={() => onDatapointButtonClick(leavingHomeDatapoint.id)}
                    {...buttonConfig}
                    icon={<Styled.ButtonImage src={away_from_home} />}
                />
            )}
            {sleepDatapoint && (
                <ThemedButton
                    loading={datapointLoading === sleepDatapoint.id}
                    onClick={() => onDatapointButtonClick(sleepDatapoint.id)}
                    {...buttonConfig}
                    icon={<Styled.ButtonImage src={sleep} />}
                />
            )}
        </Styled.MainWrapper>
    );
};

export default CentralHomeButtons;
