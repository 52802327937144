import { Space } from 'antd';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { SchemeConfig } from '../../CreateWpcProjectPage/WpcSchemeEdit/WpcSchemeEdit';
import WpcSchemeElement from '../../CreateWpcProjectPage/WpcSchemeEdit/WpcSchemeElement/WpcSchemeElement';
import * as Styled from './SelectedScheme.styles';
import { elementsConfig, schemeConfigs } from '../../CreateWpcProjectPage/CreateWpcProjectPage';
import ElementsScheme from '../../CreateWpcProjectPage/WpcSchemeEdit/ElementsScheme/ElementsScheme';
import useLocalServerApi from 'hooks/useLocalServerApi';
import { WpcAuthContext } from 'pages/Wpc/contexts/WpcAuthContext';
import { toast } from 'react-toastify';

type Props = {
    config: SchemeConfig;
};

const SelectedScheme = ({ config }: Props) => {
    const { localServerFetch } = useLocalServerApi();
    const { scheme, refech } = useContext(WpcAuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const schemeConfig = useMemo(() => schemeConfigs.find((x) => x.schemeId === config.schemeId), [config]);

    const handleEdit = () => {
        navigate('/setup');
    };

    const handleDelete = async () => {
        if (!scheme) {
            return;
        }

        const { code } = await localServerFetch({
            api: 'storage',
            params: `?id=${scheme.id}`,
            method: 'DELETE',
        });

        if (code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }
        await refech();
    };

    if (!schemeConfig) {
        return <>Missing scheme config</>;
    }

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <ThemedButton danger onClick={handleDelete}>
                    {t('general.delete')}
                </ThemedButton>
                <ThemedButton onClick={handleEdit}>{t('general.edit')}</ThemedButton>
            </Styled.Header>
            <Styled.SelectedImageWrapper checked={false}>
                <ElementsScheme elements={config.elements} />
            </Styled.SelectedImageWrapper>
            <Space wrap size={10}>
                {config.elements.map((e, index) => (
                    <WpcSchemeElement element={elementsConfig(t).find((x) => x.type === e)} key={index} />
                ))}
            </Space>
        </Styled.Wrapper>
    );
};

export default SelectedScheme;
