import { useMemo } from 'react';
import 'reactflow/dist/style.css';
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import { useTheme } from 'styled-components';
import useWindowDimensions from './useWindowDimensions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderActiveShape = (props: any, width: number, textColor: string) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + (width < 800 ? 5 : 30)) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            {(payload.names as string[]).map((x, index) => (
                <text
                    key={index}
                    x={ex + (cos >= 0 ? 1 : -1) * 10}
                    y={ey - index * 16}
                    dy={-18}
                    textAnchor={textAnchor}
                    fill={fill}
                >
                    {x}
                </text>
            ))}
            <text x={ex + (cos >= 0 ? 1 : -1) * 10} y={ey} textAnchor={textAnchor} fill={textColor}>{`${value.toFixed(
                3,
            )} kWh (${(percent * 100).toFixed(2)}%)`}</text>
        </g>
    );
};

type Props = {
    data: { name: string; value: number; color: string }[];
};

const CustomPie = ({ data }: Props): JSX.Element => {
    const { windowWidth } = useWindowDimensions();
    const preparedData = useMemo(() => {
        const resultData = data
            .filter((x) => x.value !== 0)
            .map((x) => ({ ...x, value: x.value < 0 ? -x.value : x.value, names: [x.name] }));

        const max = Math.max(...resultData.map((x) => x.value));
        const smallValues = resultData.filter((x) => x.value < max * 0.05).sort((a, b) => (a.value > b.value ? 1 : -1));
        const properValues = resultData.filter((x) => x.value >= max * 0.05);

        if (smallValues.length === 0) {
            return properValues;
        }

        const sum = Number(resultData.reduce((sum, curr) => sum + curr.value, 0).toFixed(3));

        const smallValuesSummary = smallValues.reduce(
            (prev, curr) => {
                return {
                    value: prev.value + curr.value,
                    names: [
                        ...prev.names,
                        `${curr.name}${
                            smallValues.length > 1
                                ? ` - ${curr.value.toFixed(3)} kWh (${((curr.value / sum) * 100).toFixed(2)}%)`
                                : ''
                        }`,
                    ],
                    color: prev.color,
                };
            },
            { value: 0, color: smallValues?.[smallValues.length - 1]?.color ?? '', names: [] as string[] },
        );

        return [...properValues, smallValuesSummary];
    }, [data]);
    const { colors } = useTheme();
    return (
        <ResponsiveContainer height={windowWidth < 700 ? 300 : 400}>
            <PieChart>
                <Pie
                    activeIndex={preparedData.map((_, index) => index)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    activeShape={(props: any) => renderActiveShape(props, windowWidth, colors.text)}
                    data={preparedData}
                    cx={'50%'}
                    cy={'50%'}
                    innerRadius={windowWidth < 700 ? '10%' : '45%'}
                    outerRadius={windowWidth < 700 ? '25%' : '65%'}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {preparedData.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPie;
