import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSearch, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { isEmpty } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import { sortBy } from 'lodash';
import { PortalUser } from 'models/User';
import { UserRoleType } from 'models/server/enums/UserRoleType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';
import AdminUserView from './AdminUserView/AdminUserView';
import * as Styled from './AdminUsersEditPage.styles';
import InviteUserModal from './InviteUserModal/InviteUserModal';

const AdminUsersEditPage = () => {
    const { t } = useTranslation();
    const { apiFetch } = useApi();
    const { servers } = useContext(AuthContext);

    const [users, setUsers] = useState<PortalUser[]>([]);
    const [search, setSearch] = useState<string>('');
    const [addUserModalVisible, setAddUserModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);

    const filteredUsers = useMemo(() => {
        if (isEmpty(search)) {
            return users;
        }

        return users.filter(
            (x) =>
                x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                x.eMail.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                (x.role === UserRoleType.Admin
                    ? servers
                          .map((x) => x.name.toLocaleLowerCase())
                          .some((x) => x.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                    : x.userServerAssignments
                          .map((x) => servers.find((z) => z.id === x.serverId)?.name ?? '')
                          .some((x) => x.toLocaleLowerCase().includes(search.toLocaleLowerCase()))),
        );
    }, [search, users]);

    const init = async () => {
        setLoading(true);
        const result = await apiFetch<PortalUser[]>('Users');

        if (!result.result) {
            setLoading(false);
            return;
        }

        setLoading(false);
        setUsers(sortBy(result.result, (x) => x.name.toLocaleLowerCase()));
    };

    useEffect(() => {
        init();
    }, []);

    const onInviteUser = (email: string, name: string, url: string) => {
        return apiFetch(
            'Invitations/Send',
            'POST',
            `?email=${email}&name=${name}&acceptURL=${encodeURIComponent(url)}`,
        );
    };

    if (loading) {
        return <ThemedSpin size="large" />;
    }

    if (users.length === 0) {
        return <EmptyError title={t('errors.noUsers')} />;
    }

    return (
        <Styled.Wrapper>
            <Space size={20} wrap>
                <ThemedSearch
                    value={search}
                    onChange={(v) => setSearch(v.currentTarget.value)}
                    prefix={<IoSearchOutline />}
                />
                <ThemedButton onClick={() => setAddUserModalVisible(true)}>{t('general.inviteUser')}</ThemedButton>
            </Space>
            <Space size={20} wrap>
                {filteredUsers.map((user) => (
                    <AdminUserView user={user} key={user.id} onReloadUsers={init} />
                ))}
            </Space>
            {addUserModalVisible && (
                <InviteUserModal
                    onInvite={onInviteUser}
                    onRefresh={init}
                    onCancel={() => setAddUserModalVisible(false)}
                />
            )}
        </Styled.Wrapper>
    );
};

export default AdminUsersEditPage;
