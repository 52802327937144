import styled from 'styled-components';

export const MainWrapper = styled.div`
    position: relative;
`;

export const PickerWrapper = styled.div`
    position: absolute;
    z-index: 9999999;
    right: -15px;
    top: -15px;
`;
