import { AccountInfo, Configuration, PublicClientApplication } from '@azure/msal-browser';
import { routes } from 'App';
import { User } from 'models/User';
import { useNavigate, useParams } from 'react-router-dom';
import useCookie, { getCookieValue } from './useCookie';

const tenantName = 'temb2c';
const signInPolicy = 'B2C_1A_portal_signup_signin';
const applicationID = '08f75a7f-9842-42f7-ad63-528955a00c23';
const reactRedirectUri = window.location.hostname.includes('localhost')
    ? 'http://localhost:3000/login/'
    : `https://${window.location.hostname}/login/`;
const AuthorityUrl = `https://${tenantName}.b2clogin.com/tfp/${tenantName}.onmicrosoft.com/${signInPolicy}`;
const trused = `${tenantName}.b2clogin.com`;

const configuration: Configuration = {
    auth: {
        clientId: applicationID,
        authority: AuthorityUrl,
        knownAuthorities: [trused],
        redirectUri: reactRedirectUri,
        navigateToLoginRequestUrl: false,
    },
};
const msalInstance = new PublicClientApplication(configuration);
const loginRequest = {
    scopes: ['https://temb2c.onmicrosoft.com/homeapi/read'],
};

export const initUser: User = {
    username: '',
    password: '',
    isLocalUser: false,
    isPortalAdmin: false,
    portalUsers: [],
};

const useAuth = () => {
    const {
        value: authUser,
        updateCookie: updateAuthUser,
        deleteCookie: deleteAuthUser,
    } = useCookie<User>('user', initUser);
    const navigate = useNavigate();
    const { serverId } = useParams<{ serverId: string }>();

    const getApiAuthHeader = async (customPortalId?: string) => {
        const authCookie = getCookieValue<User>('user', initUser);
        const authHeader = { Authorization: `Bearer ${await getUserToken()}`, 'Content-Type': 'application/json' };

        if (!authCookie) {
            return authHeader;
        }

        if (Number(serverId) !== 0) {
            if (serverId) {
                return { ...authHeader, portalServerId: customPortalId ?? serverId };
            }

            return customPortalId ? { ...authHeader, portalServerId: customPortalId } : authHeader;
        }

        return { ...authHeader, username: authCookie.username, password: authCookie.password };
    };

    const getAccount = async (): Promise<AccountInfo | undefined> => {
        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
        const accounts = await msalInstance.getAllAccounts();

        return accounts?.[0];
    };

    const getUserToken = async () => {
        try {
            await msalInstance.initialize();
            const accounts = await msalInstance.getAllAccounts();

            if (accounts.length === 0) {
                await logout();
                navigate(routes.login);
                return;
            }

            const tokens = await msalInstance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });

            if (!tokens?.accessToken) {
                await logout();
                navigate(routes.login);
                return;
            }

            return tokens.accessToken;
        } catch {
            await logout();
            navigate(routes.login);
        }
    };

    const getIsLoggedIn = async () => {
        try {
            await msalInstance.initialize();
            await msalInstance.handleRedirectPromise();
            const accounts = await msalInstance.getAllAccounts();

            if (accounts.length === 0) {
                return false;
            }
            const account = accounts[0];

            const tokens = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });

            return !!tokens?.accessToken;
        } catch {
            return false;
        }
    };

    const login = async () => {
        await msalInstance.initialize();
        const result = await msalInstance.handleRedirectPromise();

        if (!result) {
            msalInstance.loginRedirect({
                ...loginRequest,
            });
            return false;
        }

        return true;
    };

    const logout = async () => {
        await msalInstance.initialize();
        deleteAuthUser();
        msalInstance.logoutRedirect();
    };

    return { authUser, getApiAuthHeader, updateAuthUser, login, logout, getUserToken, getIsLoggedIn, getAccount };
};

export default useAuth;
