import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { ServerContext } from 'contexts/ServerContext';
import { useImages } from 'hooks/useImages';
import Server from 'models/Server';
import { Room } from 'models/server/Room';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useMemo } from 'react';
import { BsBarChart } from 'react-icons/bs';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import VirtualDeviceViewContent from '../VirtualDeviceViewContent/VirtualDeviceViewContent';
import FavoriteView from './FavoriteView/FavoriteView';
import * as Styled from './VirtualDeviceView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    id: number;
    showServerName?: boolean;
    rooms: Room[];
    currentServer: Server;
    navigationPath?: string;
    serverSerialNumber: string;
};

const VirtualDeviceView = ({
    virtualDevice,
    id,
    showServerName,
    rooms,
    currentServer,
    navigationPath = '/server',
    serverSerialNumber,
}: Props) => {
    const { category, name } = virtualDevice;
    const { images, getCategorySettings } = useImages();
    const navigate = useNavigate();

    const categorySettings = useMemo(() => getCategorySettings(category), [category, images]);

    const currentRoom = useMemo(() => rooms?.find((x) => x.id === virtualDevice.roomid), [rooms, virtualDevice]);

    const editPagePath = useMemo(() => {
        switch (virtualDevice.type) {
            case VirtualDeviceType.CentralScheduler:
                return 'scheduler';
            case VirtualDeviceType.Calendar:
                return 'calendar';
            case VirtualDeviceType.PresenceSimulation:
                return 'presence';
            case VirtualDeviceType.AlarmClock:
                return 'alarmclock';
            case VirtualDeviceType.CentralHomeButtons:
                return 'buttons';
            case VirtualDeviceType.OperationalModeSelector:
                return 'visualization';
            case VirtualDeviceType.Schedule:
                return 'timer';
            case VirtualDeviceType.AstroFunction:
                return 'astro';
            case VirtualDeviceType.Monitor:
                return 'monitor';
            case VirtualDeviceType.TotalEnergyMonitor:
                return 'energy';
            case VirtualDeviceType.EnergyManagement:
                return 'energymanager';
        }
    }, [virtualDevice]);

    const isEditPagePathsChart =
        virtualDevice.type === VirtualDeviceType.Monitor || virtualDevice.type === VirtualDeviceType.TotalEnergyMonitor;

    const onNavigate = (path: string) => {
        navigate(
            {
                pathname: `${navigationPath}/${currentServer?.id}/${path}/${virtualDevice.id}`,
            },
            { state: { path: window.location.pathname, search: window.location.search } },
        );
    };

    return (
        <ServerContext.Provider
            value={{ server: currentServer, navigationPath: navigationPath, serverSerialNumber: serverSerialNumber }}
        >
            <Styled.MainWrapper id={id.toString()} $categoryColor={categorySettings.color}>
                <Styled.HeaderWrapper>
                    <Styled.Image src={categorySettings.img} />
                    <Styled.HeaderTitle>{`${showServerName ? `${currentServer?.name} - ` : ''}${
                        currentRoom ? currentRoom?.name + ' - ' : ''
                    }${name}`}</Styled.HeaderTitle>
                    {editPagePath && (
                        <ThemedButton
                            onClick={() => onNavigate(editPagePath)}
                            $width={30}
                            $version="virtualDevice"
                            shape="round"
                            icon={isEditPagePathsChart ? <BsBarChart size={16} /> : <MdOutlineEdit size={16} />}
                        />
                    )}
                    <FavoriteView virtualDevice={virtualDevice} />
                </Styled.HeaderWrapper>
                <VirtualDeviceViewContent virtualDevice={virtualDevice} />
            </Styled.MainWrapper>
        </ServerContext.Provider>
    );
};

export default VirtualDeviceView;
