import { ThemeType } from 'components/PageLayout/PageLayout';
import { UserCredential } from 'models/server/UserCredential';
import { createContext } from 'react';
import { SchemeBase } from '../layouts/WpcProtectedRoute/WpcProtectedRoute';

export interface WpcAuthContext {
    theme: ThemeType;
    user: UserCredential;
    scheme?: SchemeBase;
    loading: boolean;
    setTheme?: (theme: ThemeType) => void;
    refech: () => Promise<void>;
}

export const WpcAuthContext = createContext<WpcAuthContext>({
    theme: 'dark',
    user: { serverName: '', save: false },
    refech: async () => undefined,
    loading: true,
});
