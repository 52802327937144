import { menuItems, routes } from 'App';
import { Switch } from 'antd';
import { AuthContext } from 'contexts/AuthContext';
import { useServerConfig } from 'hooks/useServerConfig';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdHome, IoMdMoon } from 'react-icons/io';
import { IoSunny } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './Header.styles';

const Header = () => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { theme, setTheme, servers } = useContext(AuthContext);
    const { currentServer } = useServerConfig();

    const iconSettings = {
        fontSize: 22,
        color: colors.text,
        cursor: 'pointer',
    };

    const pageTitle = useMemo(() => {
        const routeTitle = menuItems[location.pathname];

        if (routeTitle) {
            return t(routeTitle);
        }

        if (currentServer && location.pathname.startsWith(routes.server)) {
            return `${t(menuItems[routes.server])} / ${servers.find((x) => x.id === currentServer.id)?.name ?? ''}`;
        }

        const including = Object.keys(menuItems)
            .reverse()
            .find((x) => location.pathname.startsWith(x));

        if (including && including !== '/') {
            return t(menuItems[including]);
        }
    }, [location.pathname, menuItems, servers]);

    const onGoToHomePage = () => {
        navigate(routes.home);
    };

    const onThemeChange = (v: boolean) => {
        setTheme?.(v ? 'light' : 'dark');
    };

    return (
        <Styled.MainHeader>
            <Styled.TitleWrapper>
                <IoMdHome onClick={onGoToHomePage} {...iconSettings} />
                <div>/</div>
                {pageTitle}
            </Styled.TitleWrapper>
            <Switch
                onChange={onThemeChange}
                checked={theme === 'light'}
                checkedChildren={<IoSunny />}
                unCheckedChildren={<IoMdMoon />}
            />
        </Styled.MainHeader>
    );
};

export default Header;
