import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const BackButtonRow = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;
