import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import { useContext } from 'react';
import * as Styled from './AlarmsPage.styles';
import NotificationsServerView from './NotificationsServerView/NotificationsServerView';

const AlarmsPage = () => {
    const { configs } = useContext(GroupedServersConfigContext);

    return (
        <Styled.Wrapper>
            {configs.map((server) => (
                <NotificationsServerView key={server.server.id} config={server} />
            ))}
        </Styled.Wrapper>
    );
};

export default AlarmsPage;
