import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
`;

export const DpName = styled.div`
    flex: 1;
`;
