import { routes } from 'App';
import { Form } from 'antd';
import { ThemedButton, ThemedInput, ThemedInputPassword } from 'components/ThemedComponents/ThemedComponents.styles';
import useAuth from 'hooks/useAuth';
import { useServerApi } from 'hooks/useServerApi';
import { User } from 'models/User';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Styled from './ServerLoginPage.styles';

enum FormFields {
    serverLogin = 'serverLogin',
    serverPassword = 'serverPassword',
}

const ServerLoginPage = () => {
    const { t } = useTranslation();
    const { authUser, updateAuthUser } = useAuth();
    const { testServerConnection } = useServerApi();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const onSave = async ({ serverLogin, serverPassword }: { serverLogin: string; serverPassword: string }) => {
        if (!authUser) {
            return;
        }

        setLoading(true);

        const serverUser: User = {
            ...authUser,
            username: serverLogin,
            password: serverPassword,
        };
        const info = await testServerConnection(serverUser);

        if (info.code === 200) {
            updateAuthUser(serverUser);
            navigate(`${routes.server}/0`);
            return;
        }

        toast.error(t('errors.connectionFailed'));
        setLoading(false);
    };

    return (
        <Styled.AddForm onFinish={onSave}>
            <div>
                <Styled.ItemTitle>{`${t('general.serverLogin')}:`}</Styled.ItemTitle>
                <Form.Item
                    name={FormFields.serverLogin}
                    rules={[
                        {
                            required: true,
                            message: t('errors.fieldCannotBeEmpty'),
                        },
                    ]}
                >
                    <ThemedInput $version="1" />
                </Form.Item>
            </div>
            <div>
                <Styled.ItemTitle>{`${t('general.serverPassword')}:`}</Styled.ItemTitle>
                <Form.Item
                    name={FormFields.serverPassword}
                    rules={[
                        {
                            required: true,
                            message: t('errors.fieldCannotBeEmpty'),
                        },
                    ]}
                >
                    <ThemedInputPassword />
                </Form.Item>
            </div>
            <ThemedButton htmlType="submit" $version="1" loading={loading} $width={300}>
                {t('loginPage.login')}
            </ThemedButton>
        </Styled.AddForm>
    );
};

export default ServerLoginPage;
