import { Form } from 'antd';
import styled from 'styled-components';

export const AddForm = styled(Form)``;

export const ItemTitle = styled.div`
    color: ${(props) => props.theme.colors.text};
    margin-left: 5px;
`;

export const MapWrapper = styled.div`
    height: 300px;
    margin-bottom: 20px;
`;
