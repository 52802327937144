import { routes } from 'App';
import { Tooltip } from 'antd';
import IoValueCard from 'components/IoValueCard/IoValueCard';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { useImages } from 'hooks/useImages';
import Server from 'models/Server';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosSettings } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { atom } from 'recoil';
import { useTheme } from 'styled-components';
import * as Styled from './ServerView.styles';

type Props = {
    server: Server;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ioDashboardValueState = atom<{ serialNumber: string; ioId: number; value?: any }[]>({
    key: 'ioValueState',
    default: [],
});

const ServerView = ({ server }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { darkImages } = useImages();
    const { colors } = useTheme();

    const { dashboardConfigs } = useContext(AuthContext);

    const currentDashboardConfig = useMemo(
        () => dashboardConfigs?.find((x) => x.serverId === server.id),
        [dashboardConfigs, server],
    );

    const hasIos = useMemo(() => !!currentDashboardConfig?.monitorIos?.length, [currentDashboardConfig?.monitorIos]);

    const onServerSelected = () => {
        navigate(`${routes.server}/${server.id}`);
    };

    const handleSettingsButtonClick = () => {
        navigate(`${routes.server}/${server.id}/dashboard-settings`);
    };

    return (
        <>
            <Tooltip
                color={server.connected ? colors.success : colors.error}
                title={server.connected ? t('general.serverConnected') : t('general.notConnected')}
            >
                <Styled.MainWrapper onClick={onServerSelected} hasIos={hasIos}>
                    <Styled.HeadWrapper>
                        <Styled.IconWrapper $isConnected={server.connected}>
                            <Styled.ServerImage src={darkImages.server} />
                        </Styled.IconWrapper>
                        <Styled.ServerName>{`${server.name}${
                            server.address ? ` - ${server.address}` : ''
                        }`}</Styled.ServerName>
                        {server.id !== 0 && (
                            <ThemedButton
                                onClick={(e) => {
                                    handleSettingsButtonClick();
                                    e.stopPropagation();
                                }}
                                $width={25}
                                $height={28}
                                disabled={!server.connected}
                                icon={<IoIosSettings fontSize={20} />}
                            />
                        )}
                    </Styled.HeadWrapper>
                </Styled.MainWrapper>
            </Tooltip>
            {hasIos && (
                <Styled.IosWrapper wrap size={[0, 10]} align="start">
                    {currentDashboardConfig?.monitorIos?.map((x) => (
                        <IoValueCard
                            key={x.ioId}
                            ioId={x.ioId}
                            title={x.name}
                            configuration={x.configuration}
                            serialNumber={currentDashboardConfig.serialNumber}
                            unit={x.unit}
                        />
                    ))}
                </Styled.IosWrapper>
            )}
        </>
    );
};

export default ServerView;
