import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
`;

export const TagWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
    min-width: 50px;
    max-width: 120px;
`;

export const TagImg = styled.img`
    object-fit: scale-down;
    max-width: 18px;
`;

export const TagTitle = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
`;
