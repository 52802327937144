import useAuth from './useAuth';
import { useAzureApi } from './useAzureApi';
import usePortalUser from './usePortalUser';

const baseApiUrl = 'https://dtem-homesrv-apis-portal.azurewebsites.net/api/';

type Api =
    | 'User'
    | 'Servers'
    | 'Users'
    | 'Invitations/Send'
    | 'Invitations/Resend'
    | 'Invitations/Accept'
    | 'Invitations/Status'
    | 'Invitations/SendEmailCode'
    | 'Invitations/VerifyEmailCode'
    | 'UserServerAssignments'
    | 'Licenses';

type ApiMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export const useApi = () => {
    const { getApiAuthHeader } = useAuth();
    const { azureFetch } = useAzureApi();
    const { portalUser } = usePortalUser();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiFetch = async <T>(api: Api, method?: ApiMethod, params?: string, body?: any) => {
        return await azureFetch<T>(`${baseApiUrl}${portalUser?.licenseId}/${api}${params ?? ''}`, method, {
            headers: {
                ...JSON.parse(JSON.stringify(await getApiAuthHeader())),
            },
            body: body ? JSON.stringify(body) : undefined,
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiWithoutLicenseFetch = async <T>(api: Api, method?: ApiMethod, params?: string, body?: any) => {
        return await azureFetch<T>(`${baseApiUrl}${api}${params ?? ''}`, method, {
            headers: {
                ...JSON.parse(JSON.stringify(await getApiAuthHeader())),
            },
            body: body ? JSON.stringify(body) : undefined,
        });
    };

    const apiWithCustomLicenseFetch = async <T>(
        licenseId: number,
        api: Api,
        method?: ApiMethod,
        params?: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body?: any,
    ) => {
        return await azureFetch<T>(`${baseApiUrl}${licenseId}/${api}${params ?? ''}`, method, {
            headers: {
                ...JSON.parse(JSON.stringify(await getApiAuthHeader())),
            },
            body: body ? JSON.stringify(body) : undefined,
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiPublicFetch = async <T>(api: Api, method?: ApiMethod, params?: string, body?: any) => {
        return await azureFetch<T>(`${baseApiUrl}${api}${params ?? ''}`, method, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: body ? JSON.stringify(body) : undefined,
        });
    };

    return { apiFetch, apiPublicFetch, apiWithoutLicenseFetch, apiWithCustomLicenseFetch };
};
