import styled from 'styled-components';

export const MainWrapper = styled.div<{ $categoryColor: string }>`
    width: 300px;
    background-color: ${(props) => props.theme.colors.virtualDevicesBackground};
    padding: 15px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.$categoryColor};
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0 8px 20px 0 ${(props) => props.theme.colors.text + '1A'};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Image = styled.img`
    object-fit: scale-down;
    max-width: 25px;
`;

export const HeaderTitle = styled.div`
    flex: 1;
    font-weight: 500;
`;

export const FavImage = styled(Image)`
    cursor: pointer;
`;
