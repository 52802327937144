import styled, { css } from 'styled-components';

export const MainWrapper = styled.div<{ $minWidth?: number }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${(props) =>
        props.$minWidth &&
        css`
            min-width: ${props.$minWidth}px;
        `};
`;
