import { routes } from 'App';
import { Space } from 'antd';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import VirtualDeviceView from 'components/ServerControls/VirtualDeviceView/VirtualDeviceView';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import { useDatapoint } from 'hooks/useDatapoint';
import { Datapoint } from 'models/server/Datapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ServerControlsSelectionWrapper from '../ServerControlsSelectionWrapper/ServerControlsSelectionWrapper';
import ServerOptionsController, {
    getServerControllerOptions,
} from '../ServerOptionsController/ServerOptionsController';
import * as Styled from './ServersControls.styles';

type Props = {
    type: VirtualDeviceType;
};

export type ServersCheckedControlsProps = {
    vdId: number;
    serverId: number;
    loading: boolean;
    error: boolean;
    warning: boolean;
};

const ServersControls = ({ type }: Props) => {
    const { configs } = useContext(GroupedServersConfigContext);
    const { onDatapointButtonClick, onDatapointValueChange } = useDatapoint();
    const { licenseId } = useParams<{ licenseId: string }>();

    const [checkedVd, setCheckedVd] = useState<ServersCheckedControlsProps[]>([]);
    const [isEditMode, setIsEditMode] = useState(false);

    const selectionVisible = useMemo(() => !!getServerControllerOptions(type).length, [type]);

    const virtualDevices = useMemo(
        () =>
            configs
                .map((x) => ({
                    ...x,
                    virtualDevices: x.setupInfo?.objects.items.filter((x) => x.type === type),
                }))
                .filter((x) => x.virtualDevices?.length)
                .flatMap((z) => (z.virtualDevices as VirtualDevice[])?.map((x) => ({ ...z, ...x }))),
        [configs],
    );

    useEffect(() => {
        if (!isEditMode) {
            setCheckedVd([]);
        }
    }, [isEditMode]);

    const onControlChecked = (vdId: number, serverId: number) => {
        setCheckedVd((prev) => {
            const currentVd = prev.find((x) => x.vdId === vdId && x.serverId === serverId);

            if (currentVd) {
                return prev.filter((z) => z !== currentVd);
            }

            return [...prev, { vdId, serverId, loading: false, error: false, warning: false }];
        });
    };

    const onLoading = (serverId: number, vdId: number) => {
        setCheckedVd((prev) =>
            prev.map((x) => (x.serverId === serverId && x.vdId === vdId ? { ...x, error: false, loading: true } : x)),
        );
    };

    const onSuccess = (serverId: number, vdId: number) => {
        setCheckedVd((prev) =>
            prev.map((x) => (x.serverId === serverId && x.vdId === vdId ? { ...x, error: false, loading: false } : x)),
        );
    };

    const onWarning = (serverId: number, vdId: number) => {
        setCheckedVd((prev) =>
            prev.map((x) =>
                x.serverId === serverId && x.vdId === vdId ? { ...x, warning: true, loading: false, error: false } : x,
            ),
        );
    };

    const onError = (serverId: number, vdId: number) => {
        setCheckedVd((prev) =>
            prev.map((x) => (x.serverId === serverId && x.vdId === vdId ? { ...x, error: true, loading: false } : x)),
        );
    };

    const onSelectAll = () => {
        setCheckedVd(
            virtualDevices.map((x) => ({
                vdId: x.id,
                serverId: x.server.id,
                loading: false,
                error: false,
                warning: false,
            })),
        );
    };

    const onSendButton = async (serverId: number, vdId: number, dpFiler: (dp: Datapoint) => boolean) => {
        onLoading(serverId, vdId);

        const vd = virtualDevices.find((x) => x.server.id === serverId && x.id === vdId);
        const dp = vd?.datapoints?.find(dpFiler);

        if (!dp || dp.writeprotect) {
            onWarning(serverId, vdId);
            return;
        }

        const result = await onDatapointButtonClick(dp.id, undefined, serverId);

        if (!result) {
            onError(serverId, vdId);
            return;
        }

        onSuccess(serverId, vdId);
    };

    const onGroupedButtonClicked = async (dpFiler: (dp: Datapoint) => boolean) => {
        await Promise.all(checkedVd.map((v) => onSendButton(v.serverId, v.vdId, dpFiler)));
    };

    const onSendSliderValue = async (
        serverId: number,
        vdId: number,
        dpFiler: (dp: Datapoint) => boolean,
        value: number | boolean,
        disabledFilter?: (dp: Datapoint) => boolean,
    ) => {
        const vd = virtualDevices.find((x) => x.server.id === serverId && x.id === vdId);
        const dp = vd?.datapoints?.find(dpFiler);
        const disabledDp = disabledFilter ? vd?.datapoints?.find(disabledFilter) : undefined;

        if (!dp || dp.writeprotect || (disabledDp && disabledDp.writeprotect)) {
            onWarning(serverId, vdId);
            return;
        }

        if (dp.value === value) {
            onSuccess(serverId, vdId);
            return;
        }

        onLoading(serverId, vdId);

        const result = await onDatapointValueChange(dp.id, value, serverId);

        if (!result) {
            onError(serverId, vdId);
            return;
        }

        onSuccess(serverId, vdId);
    };

    const onSliderValueChanged = async (dpFiler: (dp: Datapoint) => boolean, value: number) => {
        await Promise.all(checkedVd.map((v) => onSendSliderValue(v.serverId, v.vdId, dpFiler, value)));
    };

    const onSwitchValueChanged = async (
        dpFiler: (dp: Datapoint) => boolean,
        value: boolean,
        disabledFilter?: (dp: Datapoint) => boolean,
    ) => {
        await Promise.all(checkedVd.map((v) => onSendSliderValue(v.serverId, v.vdId, dpFiler, value, disabledFilter)));
    };

    return (
        <Styled.Wrapper>
            <Space size={10} wrap>
                {virtualDevices.map((x) => (
                    <ServerControlsSelectionWrapper
                        visible={selectionVisible && isEditMode}
                        onCheckChanged={() => onControlChecked(x.id, x.server.id)}
                        checked={checkedVd.some((cvd) => cvd.vdId === x.id && cvd.serverId === x.server.id)}
                        key={`${x.server.id}-${x.id}`}
                        error={!!checkedVd.find((cvd) => cvd.vdId === x.id && cvd.serverId === x.server.id)?.error}
                        loading={!!checkedVd.find((cvd) => cvd.vdId === x.id && cvd.serverId === x.server.id)?.loading}
                        warning={!!checkedVd.find((cvd) => cvd.vdId === x.id && cvd.serverId === x.server.id)?.warning}
                    >
                        <VirtualDeviceView
                            currentServer={x.server}
                            rooms={x.setupInfo?.rooms ?? []}
                            showServerName
                            id={x.id}
                            virtualDevice={x}
                            navigationPath={`${routes.groupActions}/${licenseId}`}
                            serverSerialNumber={x.setupInfo?.info?.serialnumber ?? ''}
                        />
                    </ServerControlsSelectionWrapper>
                ))}
                {selectionVisible && (
                    <ServerOptionsController
                        type={type}
                        disabled={checkedVd.some((x) => x.loading)}
                        checkedControls={checkedVd}
                        isEditMode={isEditMode}
                        onChangeEditMode={() => setIsEditMode((prev) => !prev)}
                        virtualDevices={virtualDevices}
                        onSelectAll={onSelectAll}
                        onGroupedButtonClicked={onGroupedButtonClicked}
                        onSliderValueChanged={onSliderValueChanged}
                        onSwitchValueChanged={onSwitchValueChanged}
                    />
                )}
            </Space>
            <Line />
        </Styled.Wrapper>
    );
};

export default ServersControls;
