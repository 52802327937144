import { useImages } from 'hooks/useImages';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppstoreOutlined, HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import { routes } from 'App';
import { MenuProps } from 'antd';
import { AuthContext } from 'contexts/AuthContext';
import useAuth from 'hooks/useAuth';
import usePortalUser from 'hooks/usePortalUser';
import Server from 'models/Server';
import { UserRoleType } from 'models/server/enums/UserRoleType';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaLayerGroup, FaRegCalendarAlt } from 'react-icons/fa';
import { LuBellRing, LuLineChart } from 'react-icons/lu';
import { MdOutlineHomeWork } from 'react-icons/md';
import { RiExpandLeftLine, RiExpandRightLine } from 'react-icons/ri';
import { SlEnergy } from 'react-icons/sl';
import * as Styled from './SideMenu.styles';

type MenuItem = Required<MenuProps>['items'][number];

const SideMenu = () => {
    const [sideMenuVisible, setSideMenuVisible] = useState(true);
    const { authUser, authUser: user, logout } = useAuth();
    const { isDemo } = useContext(AuthContext);
    const { t } = useTranslation();
    const { logo } = useImages();
    const navigate = useNavigate();
    const location = useLocation();
    const { serverId } = useParams<{ serverId: string }>();
    const [openKeys, setOpenKeys] = useState<string[]>(
        authUser ? [`${authUser?.portalUsers?.[0]?.licenseName}${authUser?.portalUsers?.[0]?.licenseId}`] : [],
    );
    const { servers } = useContext(AuthContext);
    const { portalUser } = usePortalUser();

    const selectedKey = useMemo(() => {
        if (location.pathname === routes.home) {
            return routes.home;
        }

        if (location.pathname === `${routes.server}/login`) {
            return '0';
        }

        if (location.pathname.startsWith(routes.server) && serverId) {
            return serverId;
        }

        if (location.pathname.startsWith(routes.headAdmin)) {
            return routes.headAdmin;
        }

        if (location.pathname.startsWith(routes.groupActions) && location.pathname.includes('floorplan')) {
            return `${routes.groupActions}/floorplans`;
        }

        if (
            portalUser &&
            location.pathname.startsWith(routes.admin) &&
            location.pathname.includes(portalUser.licenseId.toString())
        ) {
            return `admin${portalUser.licenseId}`;
        }

        return location.pathname;
    }, [location, serverId, portalUser]);

    const getServerItems = (servers: Server[]): MenuItem[] => {
        return servers.map((x) => ({
            label: x.name,
            key: x.id.toString(),
            onClick: () => onServerSelected(x.id.toString()),
            icon: <Styled.Dot $isConnected={x.connected} />,
        }));
    };

    const onGoToHomePage = () => {
        navigate(routes.home);
    };

    const onGoToPage = (path: string) => {
        navigate(path);
    };

    const onLogout = () => {
        logout();
    };

    const items: MenuItem[] = useMemo(() => {
        const logoutMenu = { label: t('general.logout'), key: 'logout', icon: <LogoutOutlined />, onClick: onLogout };

        if (authUser?.isPortalAdmin) {
            return [
                {
                    label: t('general.headAdminDashboard'),
                    key: routes.headAdmin,
                    icon: <HomeOutlined />,
                },
                logoutMenu,
            ];
        }

        const i: MenuItem[] = [
            { label: t('general.home'), key: routes.home, icon: <HomeOutlined />, onClick: onGoToHomePage },
        ];

        const localServer = servers?.find((x) => x.id === 0);
        if (localServer) {
            i.push({
                label: localServer.name,
                key: localServer.id.toString(),
                onClick: () => onServerSelected(localServer.id.toString()),
                icon: <Styled.Dot $isConnected={localServer.connected} />,
            });
        }

        for (const pu of user?.portalUsers ?? []) {
            if (isDemo) {
                continue;
            }

            const menuServers: MenuItem[] = [];

            if (pu.role === UserRoleType.Admin) {
                const route = `${routes.admin}/${pu.licenseId}/users`;
                menuServers.push({
                    label: t('general.adminDashboard'),
                    key: `admin${pu.licenseId}`,
                    icon: <HomeOutlined />,
                    onClick: () => onGoToPage(route),
                });
            }

            const licenseServers = servers.filter((x) => x.licenseId === pu.licenseId);

            if (licenseServers.length > 0) {
                menuServers.push({
                    label: t('general.floorPlans'),
                    icon: <MdOutlineHomeWork />,
                    key: `${routes.groupActions}/floorplans`,
                    onClick: () =>
                        onGoToPage(`${routes.groupActions}/${pu.licenseId}/${licenseServers[0].id}/floorplan/0/0`),
                });
                menuServers.push({
                    label: t('general.groupedActions'),
                    icon: <FaLayerGroup />,
                    key: `grouped${pu.licenseId}`,
                    children: [
                        {
                            label: t('general.notifications'),
                            icon: <LuBellRing />,
                            key: `${routes.groupActions}/${pu.licenseId}/notifications`,
                            onClick: () => onGoToPage(`${routes.groupActions}/${pu.licenseId}/notifications`),
                        },
                        {
                            label: t('general.groupedControl'),
                            icon: <AppstoreOutlined />,
                            key: `${routes.groupActions}/${pu.licenseId}/controls`,
                            onClick: () => onGoToPage(`${routes.groupActions}/${pu.licenseId}/controls`),
                        },
                        {
                            label: t('general.energyGraphs'),
                            icon: <LuLineChart />,
                            key: `${routes.groupActions}/${pu.licenseId}/energygraphs`,
                            onClick: () => onGoToPage(`${routes.groupActions}/${pu.licenseId}/energygraphs`),
                        },
                        {
                            label: t('general.energyReport'),
                            icon: <SlEnergy />,
                            key: `${routes.groupActions}/${pu.licenseId}/energy`,
                            onClick: () => onGoToPage(`${routes.groupActions}/${pu.licenseId}/energy`),
                        },
                        {
                            label: t('general.calendars'),
                            icon: <FaRegCalendarAlt />,
                            key: `${routes.groupActions}/${pu.licenseId}/calendars`,
                            onClick: () => onGoToPage(`${routes.groupActions}/${pu.licenseId}/calendars`),
                        },
                    ],
                });
            }

            menuServers.push(...getServerItems(licenseServers));

            i.push({
                label: pu.licenseName,
                key: pu.licenseName + pu.licenseId,
                icon: <AppstoreOutlined />,
                children: menuServers,
            });
        }

        i.push(logoutMenu);

        return i;
    }, [routes, user, servers, isDemo]);

    const onServerSelected = (id: string) => {
        navigate(`${routes.server}/${id}`);
    };

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        const licensesKeys = user?.portalUsers?.map((x) => `${x.licenseName}${x.licenseId}`);

        if (latestOpenKey && licensesKeys?.includes(latestOpenKey)) {
            setOpenKeys([...keys.filter((x) => !licensesKeys.includes(x)), latestOpenKey]);
            return;
        }

        setOpenKeys(keys);
    };

    return (
        <>
            {sideMenuVisible ? (
                <Styled.SideMenu>
                    <Styled.CloseWrapper>
                        <RiExpandLeftLine size={20} onClick={() => setSideMenuVisible(false)} />
                    </Styled.CloseWrapper>
                    <Styled.Logo src={logo} onClick={onGoToHomePage} />
                    <Styled.Line />
                    <Styled.ItemsMenu
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        items={items}
                    />
                </Styled.SideMenu>
            ) : (
                <Styled.ClosedWrapper checked={false} onClick={() => setSideMenuVisible(true)}>
                    <RiExpandRightLine />
                </Styled.ClosedWrapper>
            )}
        </>
    );
};

export default SideMenu;
