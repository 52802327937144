import axios, { AxiosError, AxiosResponse } from 'axios';
import { ServerApi } from './useServerApi';

export const defaultServer = '192.168.100.17';
const hostname = window.location.hostname.includes('localhost') ? defaultServer : window.location.hostname;

export type LocalFetchProps = {
    api: ServerApi;
    params?: string;
    method?: 'POST' | 'PUT' | 'DELETE';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
    authToken?: string;
};

const useLocalApi = () => {
    const localFetch = async <T>({ api, params, method, body, authToken }: LocalFetchProps) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let result: AxiosResponse<T, any>;
            const url = `http://${hostname}/api/web/${api}${params ?? ''}`;
            const config = { headers: { Authorization: `Bearer ${authToken}` } };

            switch (method) {
                case 'PUT':
                    result = await axios.put<T>(url, body, config);
                    break;
                case 'POST':
                    result = await axios.post<T>(url, body, config);
                    break;
                case 'DELETE':
                    result = await axios.delete<T>(url, config);
                    break;
                default:
                    result = await axios.get<T>(url, config);
                    break;
            }

            if (result?.status !== 200) {
                return { result: undefined, code: result?.status ?? 0 };
            }

            return { result: result?.data, code: result?.status };
        } catch (e) {
            const axiosError = e as AxiosError;
            return { code: axiosError?.response?.status ?? 0, result: undefined };
        }
    };
    return { localFetch };
};

export default useLocalApi;
