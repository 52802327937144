import { routes } from 'App';
import { Space } from 'antd';
import { AuthContext } from 'contexts/AuthContext';
import { FloorTag } from 'pages/FloorplanPage/FloorplanPage.styles';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import * as Styled from './FloorPlanServerSelectPage.styles';
import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';

const FloorPlanServerSelectPage = () => {
    const { licenseId, serverId } = useParams<{ licenseId: string; serverId: string }>();
    const { servers } = useContext(AuthContext);
    const navigate = useNavigate();

    const [selectedTag, setSelectedTag] = useState<string>();

    const currentServer = useMemo(() => servers.find((x) => x.id === Number(serverId)), [servers, serverId]);

    const tags = useMemo(() => {
        const allTags = servers.flatMap((z) => z.tags).map((x) => x.value);
        return allTags.filter((c, index) => {
            return allTags.indexOf(c) === index;
        });
    }, []);

    const licenseServers = useMemo(() => {
        const lServers = servers.filter((x) => x.licenseId === Number(licenseId));

        if (selectedTag) {
            return lServers.filter((x) => x.tags.some((z) => z.value === selectedTag));
        }

        return lServers;
    }, [licenseId, servers, selectedTag]);

    useEffect(() => {
        if (!licenseServers.some((x) => x.id === currentServer?.id)) {
            navigateToFloorPlan(licenseServers?.[0]?.id ?? 0);
        }
    }, [licenseServers]);

    const navigateToFloorPlan = (serverId: number) => {
        navigate(`${routes.groupActions}/${licenseId}/${serverId}/floorplan/0/0`);
    };

    const onTagClicked = (tag: string) => {
        if (tag === selectedTag) {
            setSelectedTag(undefined);
            return;
        }

        setSelectedTag(tag);
    };

    return (
        <Styled.MainWrapper>
            <Space size={[0, 8]} wrap>
                {tags.map((tag, index) => (
                    <ThemedCheckableTag onClick={() => onTagClicked(tag)} checked={tag === selectedTag} key={index}>
                        {tag}
                    </ThemedCheckableTag>
                ))}
            </Space>
            <Space wrap>
                {licenseServers.map((server, index) => (
                    <FloorTag
                        key={index}
                        checked={server.id === Number(serverId)}
                        onClick={() => navigateToFloorPlan(server.id)}
                    >
                        {server.name}
                    </FloorTag>
                ))}
            </Space>
            <Outlet />
        </Styled.MainWrapper>
    );
};

export default FloorPlanServerSelectPage;
