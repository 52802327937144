import { Form } from 'antd';
import styled from 'styled-components';

export const AddForm = styled(Form)``;

export const ItemTitle = styled.div`
    color: ${(props) => props.theme.colors.text};
    margin-left: 5px;
`;

export const Row = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    margin-bottom: 12px;

    .ant-form-item {
        margin: 0;
    }
`;
