import NotificationTemplate from 'components/NotificationTemplate/NotificationTemplate';
import { AuthContext } from 'contexts/AuthContext';
import { HeaderConfigContext } from 'contexts/HeaderContext';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { lastWebsocketMessageState } from 'hooks/useSignalRConfig';
import { TFunction } from 'i18next';
import { Device } from 'models/server/Device';
import { Room } from 'models/server/Room';
import { ServerNotification } from 'models/server/ServerNotification';
import { SystemNotification } from 'models/server/SystemNotification';
import { NotificationType } from 'models/server/enums/NotificationType';
import { RoomType } from 'models/server/enums/RoomType';
import { WebsocketCode } from 'models/server/enums/WebsocketCode';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import NotificationView from './NotificationView/NotificationView';
import * as Styled from './Notifications.styles';

export type NotificationInfo = {
    title: string;
    message: string;
    type: 'error' | 'info';
    notificationType: NotificationType;
    virtualDeviceId: number;
    id: number;
};

export type NotificationInfoTyped = ServerNotification & { isWebsocket: boolean; isSystem: boolean };

export const getAlarmText = (
    t: TFunction,
    type: NotificationType,
    deviceName: string,
    roomName: string,
    notificationText: string,
    virtualDeviceId: number,
    id: number,
): NotificationInfo | undefined => {
    const props = { id, virtualDeviceId, notificationType: type };
    switch (type) {
        case NotificationType.UnableToSetAlarm:
            return {
                title: t('notifiactions.unableToSetAlarm'),
                message: `${t('notifiactions.issueReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.Battery:
            return {
                title: t('notifiactions.lowBatteryLevel'),
                message: `${t('notifiactions.lowBatteryLevelIn')} ${deviceName} ${t(
                    'notifiactions.inRoom',
                )} ${roomName}`,
                type: 'info',
                ...props,
            };
        case NotificationType.Custom:
            return {
                title: t('notifiactions.notification'),
                message: notificationText,
                type: 'info',
                ...props,
            };
        case NotificationType.Energy:
            return {
                title: t('notifiactions.energyManager'),
                message: `${t('notifiactions.device')} ${deviceName} ${t('notifiactions.cannotBeSwitchedOn')}`,
                type: 'error',
                ...props,
            };
        case NotificationType.SmokeAlarm:
            return {
                title: t('notifiactions.smokeAlarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.WaterAlarm:
            return {
                title: t('notifiactions.waterAlarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.FireAlarm:
            return {
                title: t('notifiactions.fireAlarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.SystemAlarm:
            return {
                title: t('notifiactions.alarm'),
                message: `${t('notifiactions.alarmReportedBy')} ${deviceName} ${t('notifiactions.inRoom')} ${roomName}`,
                type: 'error',
                ...props,
            };
        case NotificationType.DeviceNotResponding:
            return {
                title: t('notifiactions.deviceNotResponding'),
                message: `${deviceName} ${t('notifiactions.notResponingInRoom')} ${roomName}`,
                type: 'info',
                ...props,
            };
    }
};

export const getAlarmConfig = (notification: ServerNotification, devices: Device[], rooms: Room[], t: TFunction) => {
    const device = devices.find((x) => x.id === notification.deviceid);
    const deviceName = device?.name ?? t('general.unknownDevice') ?? '';
    const room = rooms.find((x) => x.id === (device?.roomid ?? 0));
    const roomName = room?.name ?? t('general.unassigned') ?? '';

    const notificationConfig = getAlarmText(
        t,
        notification.type,
        deviceName,
        roomName,
        notification.text,
        notification.objectId,
        notification.id,
    );

    if (!notificationConfig) {
        return;
    }

    return notificationConfig;
};

const Notifications = (): JSX.Element => {
    const { isDemo } = useContext(AuthContext);
    const { notificationsVisible, setHeaderConfig } = useContext(HeaderConfigContext);
    const [lastWebsocketMessage] = useRecoilState(lastWebsocketMessageState);
    const { rooms, devices, notifications, currentUser, currentServer } = useServerConfig();
    const { apiServerFetch } = useServerApi();
    const { t } = useTranslation();

    const [init, setInit] = useState(false);
    const [allNotifications, setAllNotifications] = useState<NotificationInfoTyped[]>([]);

    useEffect(() => {
        setHeaderConfig?.((prev) => ({ ...prev, nOfNotifications: allNotifications.length }));
    }, [allNotifications]);

    const setNotificationsVisible = (v: boolean) => {
        setHeaderConfig?.((prev) => ({ ...prev, notificationsVisible: v }));
    };

    const allNotificationsConfigs = useMemo(
        () => allNotifications?.map((x) => getAlarmConfig(x, devices, rooms, t)) ?? [],
        [allNotifications],
    );

    const onShow = (notification: ServerNotification) => {
        if (
            !notification.ugpushconf.Users ||
            notification.ugpushconf.Users.length === 0 ||
            (currentUser && !notification.ugpushconf.Users.includes(currentUser.id))
        ) {
            return;
        }

        const notificationConfig = getAlarmConfig(notification, devices, rooms, t);

        if (!notificationConfig) {
            return;
        }

        showNotification(notificationConfig);

        try {
            if (
                Notification.permission === 'granted' &&
                (notificationConfig.notificationType === NotificationType.FireAlarm ||
                    notificationConfig.notificationType === NotificationType.SmokeAlarm ||
                    notificationConfig.notificationType === NotificationType.WaterAlarm ||
                    notificationConfig.notificationType === NotificationType.SystemAlarm)
            ) {
                const options = {
                    body: notificationConfig.message,
                    icon: 'https://web.mytem-smarthome.com/logo512.png',
                };
                new Notification(notificationConfig.title, options);
            }
        } catch {}
    };

    const showNotification = ({ title, message, type }: NotificationInfo) => {
        const body = <NotificationTemplate title={title} description={message} />;
        type === 'info'
            ? toast.info(body, { onClick: () => setNotificationsVisible(true) })
            : toast.error(body, { onClick: () => setNotificationsVisible(true) });
    };

    const initNotifications = async () => {
        if (notifications === undefined) {
            return;
        }

        const systemNotificationsResponse = await apiServerFetch<{ systemnotifications: SystemNotification[] }>(
            'systemnotification',
        );

        if (!systemNotificationsResponse.result) {
            return;
        }

        const serverNotifications: NotificationInfoTyped[] = notifications.map((x) => ({
            ...x,
            isWebsocket: false,
            isSystem: false,
        }));
        const systemNotifications: NotificationInfoTyped[] = [];

        const nonEmptyDeviceNotifications = systemNotificationsResponse.result.systemnotifications.filter(
            (x) =>
                !!x.deviceid &&
                (x.code === WebsocketCode.DeviceNotResponding || x.code === WebsocketCode.DeviceAvailable),
        );
        for (const deviceId of nonEmptyDeviceNotifications
            .map((x) => x.deviceid)
            .filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            })) {
            const deviceNotifications = nonEmptyDeviceNotifications.filter((x) => x.deviceid === deviceId);
            const lastNotification = deviceNotifications[deviceNotifications.length - 1];

            if (lastNotification?.code === WebsocketCode.DeviceNotResponding) {
                const systemNotification: NotificationInfoTyped = {
                    deviceid: lastNotification.deviceid ?? 0,
                    id: lastNotification.id,
                    objectId: 0,
                    room: '',
                    roomcategory: RoomType.Undefined,
                    text: '',
                    type: NotificationType.DeviceNotResponding,
                    ugpushconf: {
                        Groups: [],
                        Users: [currentUser?.id ?? 0],
                    },
                    isWebsocket: false,
                    isSystem: true,
                };

                systemNotifications.push(systemNotification);
            }
        }

        const allNotificationsToShow = [...serverNotifications, ...systemNotifications].filter(
            (not) =>
                !(
                    !not.ugpushconf.Users ||
                    not.ugpushconf.Users.length === 0 ||
                    (currentUser && !not.ugpushconf.Users.includes(currentUser.id))
                ),
        );

        setAllNotifications(allNotificationsToShow);

        for (const notification of allNotificationsToShow) {
            onShow(notification);
        }
    };

    useEffect(() => {
        setInit(false);
    }, [notifications]);

    useEffect(() => {
        if (notifications === undefined || init || isDemo) {
            return;
        }

        setInit(true);
        initNotifications();
    }, [notifications, init]);

    useEffect(() => {
        if (!lastWebsocketMessage) {
            return;
        }

        if (lastWebsocketMessage?.header === 'notification') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any

            if (allNotifications?.some((x) => x.id === lastWebsocketMessage.id && !x.isSystem)) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const newNotification = lastWebsocketMessage as any as ServerNotification;
            onShow(newNotification);
            setAllNotifications((prev) => [
                ...(prev ?? []),
                { ...newNotification, isWebsocket: true, isSystem: false },
            ]);
        }

        if (lastWebsocketMessage.code === WebsocketCode.DeviceNotResponding) {
            if (allNotifications?.some((x) => x.id === lastWebsocketMessage.id && x.isSystem)) {
                return;
            }

            const newNotification = {
                deviceid: lastWebsocketMessage.deviceid ?? 0,
                id: Number(lastWebsocketMessage.id.toString() + lastWebsocketMessage.code.toString()),
                objectId: 0,
                room: '',
                roomcategory: RoomType.Undefined,
                text: '',
                type: NotificationType.DeviceNotResponding,
                ugpushconf: {
                    Groups: [],
                    Users: [0],
                },
                isWebsocket: false,
                isSystem: true,
            };

            onShow(newNotification);
            setAllNotifications((prev) => [...(prev ?? []), { ...newNotification, isWebsocket: true }]);
        }
    }, [lastWebsocketMessage]);

    const onToggleModal = () => {
        setNotificationsVisible(!notificationsVisible);
        document.getElementsByTagName('body')[0].style.overflowY = notificationsVisible ? 'auto' : 'hidden';
    };

    const onRemoveNotification = (index: number) => {
        setAllNotifications((prev) => prev?.filter((x) => x !== prev[index]));
    };

    return (
        <>
            <Styled.BlurWrapper $visible={notificationsVisible} onClick={onToggleModal} />
            <Styled.MainModalWrapper $visible={notificationsVisible}>
                <Styled.ModalContentWrapper $visible={notificationsVisible}>
                    {allNotificationsConfigs.length === 0 && (
                        <Styled.EmptyWrapper>
                            <Styled.IconWrapper>
                                {Icons.success({
                                    type: 'success',
                                    theme: 'light',
                                })}
                            </Styled.IconWrapper>
                            <Styled.EmptyTitle>{t('notifiactions.noNotifications')}</Styled.EmptyTitle>
                        </Styled.EmptyWrapper>
                    )}
                    {allNotificationsConfigs?.map((not, index) => (
                        <div key={index}>
                            {not && currentServer && (
                                <NotificationView
                                    notification={not}
                                    index={index}
                                    onRemoveNotification={onRemoveNotification}
                                    server={currentServer}
                                />
                            )}
                        </div>
                    ))}
                </Styled.ModalContentWrapper>
            </Styled.MainModalWrapper>
        </>
    );
};

export default Notifications;
