import { ThemeType } from 'components/PageLayout/PageLayout';
import { Colors } from 'models/server/constants/Colors';
import { RoomType } from 'models/server/enums/RoomType';
import { VirtualDeviceCategory } from 'models/server/enums/VirtualDeviceCategory';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const darkImages = {
    empty: require('../images/oldImages/empty.png'),
    activitySimulation: require('../images/oldImages/activity_simulation.png'),
    server: require('../images/oldImages/server.png'),
    starEmpty: require('../images/oldImages/favorite_empty.png'),
    rooms: require('../images/oldImages/rooms.png'),
    category: require('../images/oldImages/category_icon.png'),
    settings: require('../images/oldImages/settings.png'),
    back_arrow: require('../images/oldImages/back_arrow.png'),
    unknown: require('../images/unknown.svg'),

    balcony: require('../images/oldImages/balkony.png'),
    bathroom: require('../images/oldImages/bathroom.png'),
    bedroom: require('../images/oldImages/bedroom.png'),
    central: require('../images/oldImages/central.png'),
    corridor: require('../images/oldImages/corridor.png'),
    dining_room: require('../images/oldImages/dining_room.png'),
    dressing_room: require('../images/oldImages/dressing_room.png'),
    garage: require('../images/oldImages/garage.png'),
    hobby_room: require('../images/oldImages/hobby_room.png'),
    holidays: require('../images/oldImages/holidays.png'),
    kids_bedroom: require('../images/oldImages/kids_bedroom.png'),
    kitchen: require('../images/oldImages/kitchen.png'),
    living_room: require('../images/oldImages/living_room.png'),
    office: require('../images/oldImages/office.png'),
    toilet: require('../images/oldImages/toilet.png'),
    washroom: require('../images/oldImages/washroom.png'),
    cellar: require('../images/oldImages/cellar.png'),
    room: require('../images/oldImages/room_icon.png'),

    airing: require('../images/oldImages/airing.png'),
    battery: require('../images/oldImages/battery.png'),
    blinds: require('../images/oldImages/blinds.png'),
    energy: require('../images/oldImages/energy_measurement.png'),
    heating: require('../images/oldImages/heating.png'),
    light: require('../images/oldImages/light.png'),
    radio_motion: require('../images/oldImages/radio_motion.png'),
    radio_smoke: require('../images/oldImages/radio_smoke.png'),
    security: require('../images/oldImages/security.png'),
    siren: require('../images/oldImages/siren.png'),
    timer: require('../images/oldImages/timer.png'),
    water: require('../images/oldImages/water.png'),
    window_door: require('../images/oldImages/radio_window_door_contact.png'),
    presence_simulation: require('../images/oldImages/presence_simulation.png'),
    photo_camera: require('../images/oldImages/photo_camera.png'),

    add: require('../images/oldImages/add.png'),
    user: require('../images/oldImages/user.png'),
    users_configuration: require('../images/oldImages/users_configuration.png'),
    cloud: require('../images/oldImages/cloud.png'),
    theme: require('../images/oldImages/theme.png'),
    update: require('../images/oldImages/update.png'),
    backup: require('../images/oldImages/backup.png'),
    info: require('../images/oldImages/info.png'),

    downArrow: require('../images/oldImages/right_arrow.png'),
    gate_close: require('../images/oldImages/gate_close.png'),
    gate_open: require('../images/oldImages/gate_open.png'),

    away_from_home: require('../images/oldImages/away_from_home.png'),
    in_home: require('../images/oldImages/in_home.png'),
    sleep: require('../images/oldImages/sleep.png'),

    favorite_active: require('../images/oldImages/favorite_active.png'),
    favorite_empty: require('../images/oldImages/favorite_empty.png'),

    theme_black_en: require('../images/oldImages/theme_black_en.jpg'),
    theme_white_en: require('../images/oldImages/theme_white_en.jpg'),

    radio_touch: require('../images/oldImages/radio_touch.png'),
    socket: require('../images/oldImages/socket.png'),
    radio_bulb: require('../images/oldImages/radio_bulb.png'),
    radio_valve: require('../images/oldImages/radio_valve.png'),

    socket_instruction: require('../images/oldImages/socket_instruction.png'),
    rojaflex_instruction: require('../images/oldImages/rojaflex_instruction.png'),
    radio_windowdoor_instruction: require('../images/oldImages/radio_windowdoor_instruction.png'),
    radio_switch_dual_instruction: require('../images/oldImages/radio_switch_dual_instruction.png'),
    radio_switch_dual_plus_instruction: require('../images/oldImages/radio_switch_dual_plus_instruction.png'),
    radio_valve_instruction: require('../images/oldImages/radio_valve_instruction.png'),
    radio_multisensor_instruction: require('../images/oldImages/radio_multisensor_instruction.png'),
    radio_siren_instruction: require('../images/oldImages/radio_siren_instruction.png'),
    radio_smoke_instruction: require('../images/oldImages/radio_smoke_instruction.png'),
    radio_motion_instruction: require('../images/oldImages/radio_motion_instruction.png'),
    radio_leakage_instruction: require('../images/oldImages/radio_leakage_instruction.png'),
    radio_bulb_instruction: require('../images/oldImages/radio_bulb_instruction.png'),

    configuration_error: require('../images/oldImages/configuration_error.png'),
    configuration_incomplete: require('../images/oldImages/configuration_incomplete.png'),

    loupe: require('../images/oldImages/loupe.png'),

    logo: require('../images/tem_smarthome_logo.png'),

    house: require('../images/oldImages/house.png'),
};

const lightImages = {
    empty: require('../images/oldImages/empty_light.png'),
    activitySimulation: require('../images/oldImages/activity_simulation_light.png'),
    server: require('../images/oldImages/server_light.png'),
    starEmpty: require('../images/oldImages/favorite_empty_light.png'),
    rooms: require('../images/oldImages/rooms_light.png'),
    category: require('../images/oldImages/category_icon_light.png'),
    settings: require('../images/oldImages/settings_light.png'),
    back_arrow: require('../images/oldImages/back_arrow_light.png'),
    unknown: require('../images/unknown_light.svg'),

    balcony: require('../images/oldImages/balkony_light.png'),
    bathroom: require('../images/oldImages/bathroom_light.png'),
    bedroom: require('../images/oldImages/bedroom_light.png'),
    central: require('../images/oldImages/central_light.png'),
    corridor: require('../images/oldImages/corridor_light.png'),
    dining_room: require('../images/oldImages/dining_room_light.png'),
    dressing_room: require('../images/oldImages/dressing_room_light.png'),
    garage: require('../images/oldImages/garage_light.png'),
    hobby_room: require('../images/oldImages/hobby_room_light.png'),
    holidays: require('../images/oldImages/holidays_light.png'),
    kids_bedroom: require('../images/oldImages/kids_bedroom_light.png'),
    kitchen: require('../images/oldImages/kitchen_light.png'),
    living_room: require('../images/oldImages/living_room_light.png'),
    office: require('../images/oldImages/office_light.png'),
    toilet: require('../images/oldImages/toilet_light.png'),
    washroom: require('../images/oldImages/washroom_light.png'),
    cellar: require('../images/oldImages/cellar_light.png'),
    room: require('../images/oldImages/room_icon_light.png'),

    airing: require('../images/oldImages/airing_light.png'),
    battery: require('../images/oldImages/battery_light.png'),
    blinds: require('../images/oldImages/blinds_light.png'),
    energy: require('../images/oldImages/energy_measurement_light.png'),
    heating: require('../images/oldImages/heating_light.png'),
    light: require('../images/oldImages/light_light.png'),
    radio_motion: require('../images/oldImages/radio_motion_light.png'),
    radio_smoke: require('../images/oldImages/radio_smoke_light.png'),
    security: require('../images/oldImages/security_light.png'),
    siren: require('../images/oldImages/siren_light.png'),
    timer: require('../images/oldImages/timer_light.png'),
    water: require('../images/oldImages/water_light.png'),
    window_door: require('../images/oldImages/radio_window_door_contact_light.png'),
    presence_simulation: require('../images/oldImages/presence_simulation_light.png'),
    photo_camera: require('../images/oldImages/photo_camera_light.png'),

    add: require('../images/oldImages/add_light.png'),
    user: require('../images/oldImages/user_light.png'),
    users_configuration: require('../images/oldImages/users_configuration_light.png'),
    cloud: require('../images/oldImages/cloud_light.png'),
    theme: require('../images/oldImages/theme_light.png'),
    update: require('../images/oldImages/update_light.png'),
    backup: require('../images/oldImages/backup_light.png'),
    info: require('../images/oldImages/info_light.png'),

    downArrow: require('../images/oldImages/right_arrow_light.png'),
    gate_close: require('../images/oldImages/gate_close_light.png'),
    gate_open: require('../images/oldImages/gate_open_light.png'),

    away_from_home: require('../images/oldImages/away_from_home_light.png'),
    in_home: require('../images/oldImages/in_home_light.png'),
    sleep: require('../images/oldImages/sleep_light.png'),

    favorite_active: require('../images/oldImages/favorite_active_light.png'),
    favorite_empty: require('../images/oldImages/favorite_empty_light.png'),

    theme_black_en: require('../images/oldImages/theme_black_en.jpg'),
    theme_white_en: require('../images/oldImages/theme_white_en.jpg'),

    radio_touch: require('../images/oldImages/radio_touch_light.png'),
    socket: require('../images/oldImages/socket_light.png'),
    radio_bulb: require('../images/oldImages/radio_bulb_light.png'),
    radio_valve: require('../images/oldImages/radio_valve_light.png'),

    socket_instruction: require('../images/oldImages/socket_instruction_light.png'),
    rojaflex_instruction: require('../images/oldImages/rojaflex_instruction_light.png'),
    radio_windowdoor_instruction: require('../images/oldImages/radio_windowdoor_instruction_light.png'),
    radio_switch_dual_instruction: require('../images/oldImages/radio_switch_dual_instruction_light.png'),
    radio_switch_dual_plus_instruction: require('../images/oldImages/radio_switch_dual_plus_instruction_light.png'),
    radio_valve_instruction: require('../images/oldImages/radio_valve_instruction_light.png'),
    radio_multisensor_instruction: require('../images/oldImages/radio_multisensor_instruction_light.png'),
    radio_siren_instruction: require('../images/oldImages/radio_siren_instruction_light.png'),
    radio_smoke_instruction: require('../images/oldImages/radio_smoke_instruction_light.png'),
    radio_motion_instruction: require('../images/oldImages/radio_motion_instruction_light.png'),
    radio_leakage_instruction: require('../images/oldImages/radio_leakage_instruction_light.png'),
    radio_bulb_instruction: require('../images/oldImages/radio_bulb_instruction_light.png'),

    configuration_error: require('../images/oldImages/configuration_error.png'),
    configuration_incomplete: require('../images/oldImages/configuration_incomplete.png'),

    loupe: require('../images/oldImages/loupe_light.png'),

    logo: require('../images/tem_smarthome_logo_light.png'),

    house: require('../images/oldImages/house_light.png'),
};

export const useImages = () => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const images = theme === 'light' ? lightImages : darkImages;

    const getRoomCategoryImage = (category: RoomType): string => {
        switch (category) {
            case RoomType.Bathroom:
                return images.bathroom;
            case RoomType.Room:
                return images.room;
            case RoomType.Bedroom:
                return images.bedroom;
            case RoomType.LivingRoom:
                return images.living_room;
            case RoomType.Kitchen:
                return images.kitchen;
            case RoomType.HobbyRoom:
                return images.hobby_room;
            case RoomType.DiningRoom:
                return images.dining_room;
            case RoomType.DressingRoom:
                return images.dressing_room;
            case RoomType.Garage:
                return images.garage;
            case RoomType.KidsBedroom:
                return images.kids_bedroom;
            case RoomType.Office:
                return images.office;
            case RoomType.Washroom:
                return images.washroom;
            case RoomType.Balcony:
                return images.balcony;
            case RoomType.Cellar:
                return images.cellar;
            case RoomType.Toilet:
                return images.toilet;
            case RoomType.Central:
                return images.central;
            default:
                return images.empty;
        }
    };

    const getCategorySettings = (category: VirtualDeviceCategory, theme?: ThemeType) => {
        const currentImages = theme ? (theme === 'light' ? darkImages : lightImages) : images;
        const config: {
            category: VirtualDeviceCategory;
            color: string;
            colorImage: 'light' | 'dark';
            img: string;
            name?: string;
        }[] = [
            {
                category: VirtualDeviceCategory.Wrong,
                color: Colors.undefinedCategory,
                colorImage: 'light',
                img: images.unknown.default,
            },
            {
                category: VirtualDeviceCategory.Undefined,
                color: Colors.undefinedCategory,
                colorImage: 'light',
                img: images.unknown.default,
            },
            {
                category: VirtualDeviceCategory.LightingCategory,
                color: Colors.lightCategory,
                colorImage: 'light',
                img: currentImages.radio_bulb,
                name: t('virtualDeviceCategorySettings.light'),
            },
            {
                category: VirtualDeviceCategory.Blinds,
                color: Colors.blindsCategory,
                colorImage: 'light',
                img: currentImages.blinds,
                name: t('virtualDeviceCategorySettings.blinds'),
            },
            {
                category: VirtualDeviceCategory.WindowsAndDoors,
                color: Colors.windowsAndDoorsCategory,
                colorImage: 'light',
                img: currentImages.window_door,
                name: t('virtualDeviceCategorySettings.windowsAndDoors'),
            },
            {
                category: VirtualDeviceCategory.Heating,
                color: Colors.heatingCategory,
                colorImage: 'light',
                img: currentImages.heating,
                name: t('virtualDeviceCategorySettings.heating'),
            },
            {
                category: VirtualDeviceCategory.Energy,
                color: Colors.energyCategory,
                colorImage: 'light',
                img: currentImages.energy,
                name: t('virtualDeviceCategorySettings.energy'),
            },
            {
                category: VirtualDeviceCategory.Central,
                color: Colors.centralCategory,
                colorImage: 'light',
                img: currentImages.central,
                name: t('virtualDeviceCategorySettings.central'),
            },
            {
                category: VirtualDeviceCategory.Airing,
                color: Colors.airingCategory,
                colorImage: 'light',
                img: currentImages.airing,
                name: t('virtualDeviceCategorySettings.airing'),
            },
            {
                category: VirtualDeviceCategory.PresenceDetector,
                color: Colors.presenceDetectorCategory,
                colorImage: 'light',
                img: currentImages.radio_motion,
                name: t('virtualDeviceCategorySettings.presenceDetector'),
            },
            {
                category: VirtualDeviceCategory.Water,
                color: Colors.waterCategory,
                colorImage: 'light',
                img: currentImages.water,
                name: t('virtualDeviceCategorySettings.water'),
            },
            {
                category: VirtualDeviceCategory.Smoke,
                color: Colors.smokeCategory,
                colorImage: 'light',
                img: currentImages.radio_smoke,
                name: t('virtualDeviceCategorySettings.smoke'),
            },
            {
                category: VirtualDeviceCategory.Security,
                color: Colors.securityCategory,
                colorImage: 'light',
                img: currentImages.security,
                name: t('virtualDeviceCategorySettings.security'),
            },
            {
                category: VirtualDeviceCategory.Battery,
                color: Colors.batteryCategory,
                colorImage: 'light',
                img: currentImages.battery,
                name: t('virtualDeviceCategorySettings.battery'),
            },
            {
                category: VirtualDeviceCategory.Siren,
                color: Colors.sirenCategory,
                colorImage: 'light',
                img: currentImages.siren,
                name: t('virtualDeviceCategorySettings.siren'),
            },
            {
                category: VirtualDeviceCategory.PowerSocket,
                color: Colors.powerSocketCategory,
                colorImage: 'light',
                img: currentImages.timer,
                name: t('virtualDeviceCategorySettings.powerSocket'),
            },
        ];

        return config.find((x) => x.category === category) ?? config[0];
    };

    return { ...images, images, darkImages, lightImages, getRoomCategoryImage, getCategorySettings };
};
