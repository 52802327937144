import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import * as Styled from './Timer.styles';

const Timer = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const mainSwitchDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch),
        [datapoints],
    );
    const schedulerOnDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSchedulerOn),
        [datapoints],
    );
    const timeProgramDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.TimeProgram),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {mainSwitchDatapoint && (
                <DatapointSwitchView datapoint={mainSwitchDatapoint} virtualDevice={virtualDevice} />
            )}
            {schedulerOnDatapoint && (
                <DatapointSwitchView
                    datapoint={schedulerOnDatapoint}
                    virtualDevice={virtualDevice}
                    disabled={timeProgramDatapoint?.writeprotect ?? false}
                />
            )}
        </Styled.MainWrapper>
    );
};

export default Timer;
