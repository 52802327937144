import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ThemedCheckboxGroup, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { PropsWithChildren } from 'react';
import { MdErrorOutline, MdWarningAmber } from 'react-icons/md';
import { useTheme } from 'styled-components';
import * as Styled from './ServerControlsSelectionWrapper.styles';

type Props = PropsWithChildren & {
    checked: boolean;
    loading: boolean;
    error: boolean;
    visible: boolean;
    warning: boolean;
    onCheckChanged: (v: boolean) => void;
};

const ServerControlsSelectionWrapper = ({
    children,
    checked,
    loading,
    error,
    warning,
    visible,
    onCheckChanged,
}: Props) => {
    const { colors } = useTheme();
    return (
        <Styled.Wrapper>
            {visible && (
                <Styled.HeaderWrapper onClick={() => !loading && onCheckChanged(!checked)}>
                    {loading && <ThemedSpin />}
                    {error && <MdErrorOutline size={20} color={colors.error} />}
                    {warning && <MdWarningAmber size={20} color={colors.pending} />}
                    <ThemedCheckboxGroup
                        options={[{ label: '', value: 'selected' }]}
                        value={[checked ? 'selected' : undefined] as CheckboxValueType[]}
                        $version="2"
                    />
                </Styled.HeaderWrapper>
            )}
            {children}
        </Styled.Wrapper>
    );
};

export default ServerControlsSelectionWrapper;
