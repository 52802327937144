import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './FireAndWaterAlarm.styles';

const FireAndWaterAlarm = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const fireAlarmDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualFireAlarm),
        [datapoints],
    );
    const smokeAlarmDatapoint = useMemo(
        () =>
            datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualSmokeAlarm),
        [datapoints],
    );
    const waterAlarmDatapoint = useMemo(
        () =>
            datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualWaterAlarm),
        [datapoints],
    );
    const quiteAlarmButtonDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarm),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {fireAlarmDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={fireAlarmDatapoint} />}
            {smokeAlarmDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={smokeAlarmDatapoint} />}
            {waterAlarmDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={waterAlarmDatapoint} />}
            {quiteAlarmButtonDatapoint && <DatapointButtonView datapoint={quiteAlarmButtonDatapoint} />}
        </Styled.MainWrapper>
    );
};

export default FireAndWaterAlarm;
