import { Datapoint } from 'models/server/Datapoint';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useServerApi } from './useServerApi';
import { ServerContext } from 'contexts/ServerContext';
import { useParams } from 'react-router-dom';

export const useDatapoint = () => {
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const { t } = useTranslation();
    const [datapointLoading, setDatapointLoading] = useState<number>();
    const serverContext = useContext(ServerContext);
    const { serverId } = useParams<{ serverId: string }>();

    const checkError = (code: number) => {
        if (code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
        }
    };

    const onDatapointChange = async (datapoint: Datapoint, customServerId?: number) => {
        setDatapointLoading(datapoint.id);

        const result = await apiServerFetchWithCustomPortalId(
            customServerId ?? serverContext?.server?.id ?? Number(serverId ?? 0),
            'datapoint',
            `?id=${datapoint.id}`,
            'PUT',
            {
                ...datapoint,
                header: 'dprequest',
            },
        );

        setDatapointLoading(undefined);

        checkError(result.code);

        return result.code === 200;
    };

    const onDatapointValueChange = async (
        datapointId: number,
        value: string | boolean | number,
        customServerId?: number,
    ) => {
        setDatapointLoading(datapointId);

        const params = `?id=${datapointId}&value=${value}`;

        const result = await apiServerFetchWithCustomPortalId(
            customServerId ?? serverContext?.server?.id ?? Number(serverId ?? 0),
            'datapoint',
            params,
            'PUT',
        );

        setDatapointLoading(undefined);

        checkError(result.code);

        return result.code === 200;
    };

    const onDatapointButtonClick = async (
        datapointId: number,
        code?: string,
        customServerId?: number,
    ): Promise<boolean> => {
        setDatapointLoading(datapointId);

        const body = {
            value: true,
            tag: 'auto_off',
            code: code,
        };
        const params = `?id=${datapointId}`;

        const result = await apiServerFetchWithCustomPortalId(
            customServerId ?? serverContext?.server?.id ?? Number(serverId ?? 0),
            'datapoint',
            params,
            'PUT',
            body,
        );

        setDatapointLoading(undefined);

        checkError(result.code);

        return result.code === 200;
    };

    return { datapointLoading, onDatapointButtonClick, onDatapointValueChange, onDatapointChange };
};
