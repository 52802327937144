import { ThemedSlider } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div<{ $hasPostionDatapoint: boolean }>`
    display: flex;
    gap: 30px;
    ${(props) => props.$hasPostionDatapoint && `margin-right: -15px;`}
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
`;

export const CentralContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 15px;
`;

export const ValueSlider = styled(ThemedSlider)<{ $color?: string }>`
    cursor: default !important;

    .ant-slider-handle::after,
    .ant-slider-handle::before {
        display: none;
    }
`;
