import { Space } from 'antd';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import { ThemedButton, ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import dayjs from 'dayjs';
import { CalendarSwitchPoint } from 'models/server/CalendarSwitchPoint';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { serverDateFormat } from 'pages/CalendarEditPage/CalendarEditPage';
import CalendarGroupView from 'pages/CalendarEditPage/CalendarGroupView/CalendarGroupView';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './CalendarGroupedPage.styles';
import SendValuesModal from './SendValuesModal/SendValuesModal';

const sortFn = (a: CalendarSwitchPoint, b: CalendarSwitchPoint) =>
    dayjs(a.Date, serverDateFormat).isAfter(dayjs(b.Date, serverDateFormat)) ? 1 : -1;

const CalendarGroupedPage = () => {
    const { t } = useTranslation();
    const { configs } = useContext(GroupedServersConfigContext);

    const [sendValuesModalVisible, setSendValuesModalVisible] = useState(false);
    const [selectedCalendar, setSelectedCalendar] = useState<{ serverId: number; objectId: number }>();

    const allCalendars = useMemo(
        () =>
            configs.map((x) => ({
                server: x.server,
                calendars: x.setupInfo?.objects?.items?.filter((x) => x.type === VirtualDeviceType.Calendar),
            })),
        [configs],
    );

    const calendarSelected = useMemo(
        () =>
            allCalendars
                ? {
                      server: allCalendars.find((x) => x.server.id === selectedCalendar?.serverId)?.server,
                      calendar: allCalendars
                          .find((x) => x.server.id === selectedCalendar?.serverId)
                          ?.calendars?.find((x) => x.id === selectedCalendar?.objectId),
                  }
                : undefined,
        [allCalendars, selectedCalendar],
    );

    const timeProgramDatapoint = useMemo(
        () => calendarSelected?.calendar?.datapoints?.find((x) => x.type == DatapointType.TimeProgram),
        [calendarSelected?.calendar?.datapoints],
    );

    const [calendarSwitchPoints, setCalendarSwitchPoints] = useState<CalendarSwitchPoint[]>(
        timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints?.sort(sortFn) ?? [],
    );

    useEffect(() => {
        setCalendarSwitchPoints(timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints?.sort(sortFn) ?? []);
    }, [timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints]);

    const pastSwitchpoints = useMemo(
        () =>
            calendarSwitchPoints.filter((sp) =>
                dayjs(sp.Date, { utc: true, format: serverDateFormat }).isBefore(dayjs()),
            ),
        [calendarSwitchPoints],
    );

    const futureSwitchpoins = useMemo(
        () =>
            calendarSwitchPoints.filter((sp) => {
                const day = dayjs(sp.Date, { utc: true, format: serverDateFormat });
                return day.isAfter(dayjs()) || day.isSame(dayjs());
            }),
        [calendarSwitchPoints],
    );

    const handleCalendarClick = (serverId: number, objectId: number) => {
        if (selectedCalendar?.serverId === serverId && objectId === selectedCalendar.objectId) {
            setSelectedCalendar(undefined);
            return;
        }
        setSelectedCalendar({ serverId, objectId });
    };

    const onRemove = (sp: CalendarSwitchPoint) => {
        setCalendarSwitchPoints((prev) => prev.filter((x) => x !== sp));
    };

    const onSwitchChanged = (sp: CalendarSwitchPoint, value: boolean) => {
        setCalendarSwitchPoints(
            calendarSwitchPoints.map((x) =>
                sp === x
                    ? {
                          ...x,
                          Value: value,
                      }
                    : x,
            ),
        );
    };

    const onDateChanged = (sp: CalendarSwitchPoint, date: string) => {
        setCalendarSwitchPoints(
            calendarSwitchPoints
                .map((x) =>
                    sp === x
                        ? {
                              ...x,
                              Date: date,
                          }
                        : x,
                )
                .sort(sortFn),
        );
    };

    const addSwitchPoint = () => {
        const now = dayjs();
        const newSwitchPoint: CalendarSwitchPoint = {
            Date: now.subtract(now.utcOffset(), 'minutes').add(5, 'minutes').format(serverDateFormat),
            Value: true,
        };

        setCalendarSwitchPoints((prev) => [...prev, newSwitchPoint].sort(sortFn));
    };

    return (
        <Styled.Wrapper>
            {t('general.selectBaseCalendar')}
            <Space wrap>
                <ThemedCheckableTag
                    checked={selectedCalendar === undefined}
                    onClick={() => setSelectedCalendar(undefined)}
                >
                    <Styled.TagTitle>{t('general.empty')}</Styled.TagTitle>
                </ThemedCheckableTag>
                {allCalendars.map(
                    (x) =>
                        x.calendars?.map((z) => (
                            <ThemedCheckableTag
                                checked={
                                    selectedCalendar?.objectId === z.id && selectedCalendar?.serverId === x.server.id
                                }
                                key={`${x.server.id}${z.id}`}
                                onClick={() => handleCalendarClick(x.server.id, z.id)}
                            >
                                <Styled.TagTitle>{`${x.server.name} - ${z.name}`}</Styled.TagTitle>
                            </ThemedCheckableTag>
                        )),
                )}
            </Space>
            <Line />
            <Styled.NextButtonWrapper>
                <ThemedButton
                    disabled={calendarSwitchPoints.length === 0}
                    $action
                    onClick={() => setSendValuesModalVisible(true)}
                >
                    {t('general.next')}
                </ThemedButton>
            </Styled.NextButtonWrapper>
            <Styled.GroupsWrapper>
                {pastSwitchpoints.length > 0 && (
                    <CalendarGroupView
                        showAddButton={false}
                        title={t('general.past')}
                        calendarSwitchPoints={pastSwitchpoints}
                        disabled={timeProgramDatapoint?.writeprotect ?? false}
                        showRemove={calendarSwitchPoints.length > 1}
                        onRemove={onRemove}
                        onSwitchChanged={onSwitchChanged}
                        onDateChanged={onDateChanged}
                        onAddSwitchpoint={addSwitchPoint}
                    />
                )}
                <CalendarGroupView
                    showAddButton
                    title={t('general.future')}
                    calendarSwitchPoints={futureSwitchpoins}
                    disabled={timeProgramDatapoint?.writeprotect ?? false}
                    showRemove={calendarSwitchPoints.length > 1}
                    onRemove={onRemove}
                    onSwitchChanged={onSwitchChanged}
                    onDateChanged={onDateChanged}
                    onAddSwitchpoint={addSwitchPoint}
                />
            </Styled.GroupsWrapper>
            {sendValuesModalVisible && (
                <SendValuesModal
                    allCalendars={allCalendars}
                    newPoints={calendarSwitchPoints}
                    onCancel={() => setSendValuesModalVisible(false)}
                />
            )}
        </Styled.Wrapper>
    );
};

export default CalendarGroupedPage;
