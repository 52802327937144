import {
    ThemedButton,
    ThemedInput,
    ThemedInputPassword,
    ThemedModal,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidationNameError, isEmpty, validatePassword } from '../../helpers/StringHelper';
import * as Styled from './SingleInputModal.styles';

interface Props {
    isVisible: boolean;
    yesButtonText?: string;
    noButtonText?: string;
    defaultValue?: string;
    title?: string;
    description?: string;
    yesDanger?: boolean;
    placeholder?: string;
    valueOtherThan?: string[];
    isLoading?: boolean;
    isPassword?: boolean;
    doNameValidation?: boolean;
    onAddClicked?: (value: string) => void;
    onCancelClicked?: () => void;
}

const SingleInputModal = (props: Props): JSX.Element => {
    const {
        isVisible,
        yesButtonText,
        noButtonText,
        title,
        placeholder,
        valueOtherThan,
        defaultValue,
        isLoading,
        isPassword,
        doNameValidation,
        onAddClicked,
        onCancelClicked,
    } = props;
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState(defaultValue ?? '');

    const inputError = doNameValidation ? useMemo(() => getValidationNameError(inputValue), [inputValue]) : undefined;

    return (
        <ThemedModal
            width={300}
            title={title ?? ''}
            open={isVisible}
            onCancel={onCancelClicked}
            style={{ top: 200 }}
            footer={
                <Footer>
                    <ThemedButton onClick={onCancelClicked} $version="2" disabled={isLoading}>
                        {noButtonText ?? t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton
                        $version="2"
                        disabled={
                            isEmpty(inputValue) ||
                            valueOtherThan?.some((x) => x === inputValue) ||
                            !!inputError ||
                            (!isEmpty(inputValue) && isPassword && !validatePassword(inputValue))
                        }
                        onClick={() => onAddClicked?.(inputValue)}
                        loading={isLoading}
                    >
                        {yesButtonText ?? t('general.add')}
                    </ThemedButton>
                </Footer>
            }
        >
            {isPassword ? (
                <ThemedInputPassword
                    status={
                        isEmpty(inputValue) ||
                        valueOtherThan?.some((x) => x === inputValue) ||
                        (!isEmpty(inputValue) && isPassword && !validatePassword(inputValue)) ||
                        inputError
                            ? 'error'
                            : undefined
                    }
                    onChange={(value) => {
                        setInputValue(value.currentTarget.value);
                    }}
                    value={inputValue}
                    placeholder={placeholder}
                />
            ) : (
                <ThemedInput
                    $width="100%"
                    status={
                        isEmpty(inputValue) ||
                        valueOtherThan?.some((x) => x === inputValue) ||
                        (!isEmpty(inputValue) && isPassword && !validatePassword(inputValue)) ||
                        inputError
                            ? 'error'
                            : undefined
                    }
                    onChange={(value) => {
                        setInputValue(value.currentTarget.value);
                    }}
                    value={inputValue}
                    placeholder={placeholder}
                />
            )}
            {valueOtherThan?.some((x) => x === inputValue) && (
                <Styled.Error>{t('errors.NameAlreadyExist')}</Styled.Error>
            )}
            {isEmpty(inputValue) && <Styled.Error>{t('errors.fieldCannotBeEmpty')}</Styled.Error>}
            {!isEmpty(inputValue) && isPassword && !validatePassword(inputValue) && (
                <Styled.Error>{t('errors.passwordNotValid')}</Styled.Error>
            )}
            {!isEmpty(inputValue) && inputError && <Styled.Error>{inputError}</Styled.Error>}
        </ThemedModal>
    );
};

export default SingleInputModal;
