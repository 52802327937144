import { Space } from 'antd';
import { WholePageSpace } from 'pages/SceneEditPage/SceneEditPage.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const NoServersWrapper = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

export const NoServersTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
`;

export const HomePageHeader = styled(Space)`
    display: flex;
    margin: 0 0 30px;
    justify-content: space-between;
`;

export const HomePageHeaderLeftSide = styled(Space)`
    flex: 1;
    display: flex;
    gap: 20px;
`;

export const Row = styled.div`
    display: flex;
    gap: 20px;
`;

export const ServerViewSpace = styled(WholePageSpace)`
    margin-bottom: 10px;
`;
