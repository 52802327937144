import { Space, Tooltip } from 'antd';
import {
    ColorStateButton,
    ThemedInput,
    ThemedInputNumber,
    ThemedSlider,
    ThemedTimePicker,
} from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { useImages } from 'hooks/useImages';
import { SwitchPoint } from 'models/server/SwitchPoint';
import { TimeProgram } from 'models/server/TimeProgram';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { timeFormat } from 'pages/SchedulerEditPage/SchedulerEditPage';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { useTheme } from 'styled-components';
import * as Styled from './TimeProgramView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    timeProgram: TimeProgram;
    currentError?: string;
    disabledDays: number[];
    coolingSupport: boolean;
    onChangeName: (v: string) => void;
    onChangeDays: (v: number[]) => void;
    addSwitchPoint: () => SwitchPoint;
    removeSwithPoint: (i: number) => void;
    onChangeSwitchpointTime: (i: number, time: string) => void;
    onSwitchpointChanged: (switchpointIndex: number, switchpoint: SwitchPoint) => void;
};

const order = [1, 2, 3, 4, 5, 6, 0];

const TimeProgramView = ({
    timeProgram,
    virtualDevice,
    currentError,
    disabledDays,
    coolingSupport,
    onChangeName,
    onChangeDays,
    addSwitchPoint,
    removeSwithPoint,
    onChangeSwitchpointTime,
    onSwitchpointChanged,
}: Props) => {
    const { t } = useTranslation();
    const { getCategorySettings } = useImages();
    const { colors } = useTheme();
    const { SwitchPoints } = timeProgram;

    const manualCoolingDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) =>
                    x.type == DatapointType.Temperature &&
                    (x.name == DatapointNames.VirtualManualModeCooling ||
                        x.name == DatapointNames.VirtualSetpointManualModeCooling),
            ),
        [virtualDevice?.datapoints],
    );

    const manualHeatingDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) =>
                    x.type == DatapointType.Temperature &&
                    (x.name == DatapointNames.VirtualManualModeHeating ||
                        x.name == DatapointNames.VirtualSetpointManualModeHeating),
            ),
        [virtualDevice?.datapoints],
    );

    const categorySettings = useMemo(() => getCategorySettings(virtualDevice.category), [virtualDevice]);

    const daysConfig: Record<number, string> = useMemo(
        () => ({
            1: t('timeProgramEdit.mo'),
            2: t('timeProgramEdit.tu'),
            3: t('timeProgramEdit.we'),
            4: t('timeProgramEdit.th'),
            5: t('timeProgramEdit.fr'),
            6: t('timeProgramEdit.sa'),
            0: t('timeProgramEdit.su'),
        }),
        [],
    );

    const onDayClicked = (day: number) => {
        if (timeProgram.days.includes(Number(day))) {
            onChangeDays(timeProgram.days.filter((x) => x !== day));
            return;
        }

        onChangeDays([...timeProgram.days, day]);
    };

    const addNewSwitchpoint = () => {
        addSwitchPoint();
    };

    const onRemoveSwithPoint = (index: number) => {
        removeSwithPoint(index);
    };

    const onHeatingValueChanged = (spIndex: number, value: number) => {
        onSwitchpointChanged(spIndex, { ...SwitchPoints[spIndex], Heating: Number(value.toFixed(1)) });
    };

    const onCoolingValueChanged = (spIndex: number, value: number) => {
        onSwitchpointChanged(spIndex, { ...SwitchPoints[spIndex], Cooling: Number(value.toFixed(1)) });
    };

    return (
        <>
            <Styled.HeaderWrapper size={20} wrap>
                <Tooltip open={!!currentError} title={currentError} color={colors.error}>
                    <ThemedInput value={timeProgram.Name} onChange={(v) => onChangeName(v.currentTarget.value)} />
                </Tooltip>
                <Space size={10}>
                    {Object.keys(daysConfig)
                        .sort((a, b) => order.indexOf(Number(a)) - order.indexOf(Number(b)))
                        .map((day) => (
                            <ColorStateButton
                                shape="round"
                                key={day}
                                $color={categorySettings.color}
                                $width={40}
                                $isActive={timeProgram.days.includes(Number(day))}
                                disabled={disabledDays.includes(Number(day))}
                                onClick={() => onDayClicked(Number(day))}
                            >
                                {daysConfig[Number(day)]}
                            </ColorStateButton>
                        ))}
                </Space>
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {SwitchPoints.map((sp, index) => (
                    <Styled.TimeThemedCheckableTag key={index}>
                        <Styled.IconWrapper>
                            {SwitchPoints.length > 1 && (
                                <IoMdCloseCircleOutline
                                    onClick={(e) => {
                                        onRemoveSwithPoint(index);
                                        e.stopPropagation();
                                    }}
                                    size={17}
                                />
                            )}
                        </Styled.IconWrapper>
                        <Styled.Column>
                            <Styled.TimeInputWrapper>
                                <ThemedTimePicker
                                    value={dayjs(sp.Time, 'HH:mm')}
                                    format={timeFormat}
                                    changeOnBlur
                                    allowClear={false}
                                    onChange={(v) => v && onChangeSwitchpointTime(index, v.format(timeFormat))}
                                />
                            </Styled.TimeInputWrapper>
                            <Styled.Row>
                                <Styled.SliderWrapper>
                                    <ThemedSlider
                                        value={sp?.Heating}
                                        min={Number(manualHeatingDatapoint?.range?.[0] ?? '0')}
                                        max={Number(manualHeatingDatapoint?.range?.[1] ?? '40')}
                                        $color="#ea567f"
                                        onChange={(v) => onHeatingValueChanged(index, v)}
                                    />
                                </Styled.SliderWrapper>
                                <Styled.InputWrapper>
                                    <ThemedInputNumber
                                        addonAfter="°C"
                                        step={0.1}
                                        value={sp?.Heating}
                                        min={manualHeatingDatapoint?.range?.[0] ?? '0'}
                                        max={manualHeatingDatapoint?.range?.[1] ?? '40'}
                                        onChange={(v) => v && onHeatingValueChanged(index, Number(v))}
                                    />
                                </Styled.InputWrapper>
                            </Styled.Row>
                            {coolingSupport && (
                                <Styled.Row>
                                    <Styled.SliderWrapper>
                                        <ThemedSlider
                                            value={sp?.Cooling}
                                            min={Number(manualCoolingDatapoint?.range?.[0] ?? '0')}
                                            max={Number(manualCoolingDatapoint?.range?.[1] ?? '40')}
                                            $color="#56b2ea"
                                            onChange={(v) => onCoolingValueChanged(index, v)}
                                        />
                                    </Styled.SliderWrapper>
                                    <Styled.InputWrapper>
                                        <ThemedInputNumber
                                            addonAfter="°C"
                                            step="0.1"
                                            value={sp?.Cooling?.toString()}
                                            min={manualCoolingDatapoint?.range?.[0] ?? '0'}
                                            max={manualCoolingDatapoint?.range?.[1] ?? '40'}
                                            onChange={(v) => v && onCoolingValueChanged(index, Number(v))}
                                        />
                                    </Styled.InputWrapper>
                                </Styled.Row>
                            )}
                        </Styled.Column>
                    </Styled.TimeThemedCheckableTag>
                ))}
                {SwitchPoints.length < 6 && (
                    <Styled.AddThemedCheckableTag checked={false} $dashed onClick={addNewSwitchpoint}>
                        <MdAdd fontSize={25} />
                    </Styled.AddThemedCheckableTag>
                )}
            </Space>
        </>
    );
};

export default TimeProgramView;
