import { AuthContext } from 'contexts/AuthContext';
import { User } from 'models/User';
import { Info } from 'models/server/Info';
import { useContext } from 'react';
import useAuth from './useAuth';
import { useAzureApi } from './useAzureApi';

const baseApiUrl = 'https://dtem-homesrv-apis-homeserver-testing.azurewebsites.net/api/';

export type ServerApi =
    | 'info'
    | 'bulk'
    | 'datapoint'
    | 'objects'
    | 'systemnotification'
    | 'notification'
    | 'webimages'
    | 'datalog'
    | 'camera'
    | 'netatmo'
    | 'usergroups'
    | 'assignments'
    | 'users'
    | 'update'
    | 'objectproperties'
    | 'propertytemplates'
    | 'statistic'
    | 'token'
    | 'storage';

export const useServerApi = () => {
    const { getApiAuthHeader, getUserToken } = useAuth();
    const { isDemo } = useContext(AuthContext);
    const { azureFetch } = useAzureApi();

    const apiServerFetch = async <T>(
        api: ServerApi,
        params?: string,
        method?: 'POST' | 'PUT' | 'DELETE',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body?: any,
    ) => {
        if (isDemo) {
            return { result: undefined, code: 200 };
        }

        return await azureFetch<T>(`${baseApiUrl}${api}${params ?? ''}`, method, {
            headers: {
                ...JSON.parse(JSON.stringify(await getApiAuthHeader())),
            },
            body: body ? JSON.stringify(body) : undefined,
        });
    };

    const apiServerFetchWithCustomPortalId = async <T>(
        portalServerId: number,
        api: ServerApi,
        params?: string,
        method?: 'POST' | 'PUT' | 'DELETE',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body?: any,
    ) => {
        if (isDemo) {
            return { result: undefined, code: 200 };
        }

        return await azureFetch<T>(`${baseApiUrl}${api}${params ?? ''}`, method, {
            headers: {
                ...JSON.parse(JSON.stringify(await getApiAuthHeader(portalServerId.toString()))),
            },
            body: body ? JSON.stringify(body) : undefined,
        });
    };

    const testServerConnection = async ({ username, password }: User) => {
        return await azureFetch<Info>(`${baseApiUrl}${'info'}`, undefined, {
            headers: { username: username, password: password, Authorization: `Bearer ${await getUserToken()}` },
        });
    };

    return { apiServerFetch, apiServerFetchWithCustomPortalId, testServerConnection };
};
