import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
`;

export const TagTitle = styled.div`
    padding: 4px 8px;
`;

export const GroupsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const NextButtonWrapper = styled.div`
    margin-left: auto;
`;
