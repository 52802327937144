import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
    cursor: pointer;
    gap: 10px;

    &:hover {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        background-color: ${(props) => props.theme.colors.backgroundLayer2 + '80'};
    }
`;
