import { ReactNode, useState } from 'react';
import { ThemedButton, ThemedButtonProps } from 'components/ThemedComponents/ThemedComponents.styles';
import { ButtonProps } from 'antd';
import YesNoModal, { YesNoModalProps } from 'components/YesNoModal/YesNoModal';

type Props = ButtonProps &
    ThemedButtonProps & {
        children?: ReactNode;
        yesNoModalProps: Omit<YesNoModalProps, 'onYesClicked' | 'onNoClicked'>;
    };

const ConfirmButton = ({ children, onClick, yesNoModalProps, ...rest }: Props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const onClicked = () => {
        setModalVisible(true);
    };

    return (
        <>
            <ThemedButton {...rest} onClick={onClicked}>
                {children}
            </ThemedButton>
            {modalVisible && (
                <YesNoModal {...yesNoModalProps} onYesClicked={onClick} onNoClicked={() => setModalVisible(false)} />
            )}
        </>
    );
};

export default ConfirmButton;
