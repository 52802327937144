import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedCheckableTag, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { getNewTimeProgramName, isEmpty } from 'helpers/StringHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { SwitchPoint } from 'models/server/SwitchPoint';
import { TimeProgram } from 'models/server/TimeProgram';
import { DatapointType } from 'models/server/enums/DatapointType';
import { timeFormat } from 'pages/SchedulerEditPage/SchedulerEditPage';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import TimeProgramView from './TimeProgramView/TimeProgramView';
import * as Styled from './TimerEditPage.styles';

const TimerEditPage = () => {
    const { t } = useTranslation();

    const { currentServer, currentVirtualDevice, serverConfigLoading } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { onGoBack } = useGoBack();

    const timeProgramDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.TimeProgram),
        [currentVirtualDevice?.datapoints],
    );

    const [timePrograms, setTimePrograms] = useState<TimeProgram[]>(
        timeProgramDatapoint?.TimeProgram.TimeProgramList ?? [],
    );
    const [selectedTimeProgramIndex, setSelectedTimeProgramIndex] = useState<number>(0);

    const currentTimeProgram = useMemo(
        () => timePrograms[selectedTimeProgramIndex],
        [timePrograms, selectedTimeProgramIndex],
    );
    console.log(timeProgramDatapoint);
    const saveDisabled = useMemo(() => {
        const names = timePrograms.map((x) => x.Name);
        return names.some((name) => isEmpty(name)) || names.some((item, index) => names.indexOf(item) !== index);
    }, [timePrograms]);

    useEffect(() => {
        setTimePrograms(timeProgramDatapoint?.TimeProgram?.TimeProgramList ?? []);
    }, [timeProgramDatapoint?.TimeProgram?.TimeProgramList]);

    useEffect(() => {
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex
                    ? {
                          ...timeProgram,
                          SwitchPoints: timeProgram.SwitchPoints.sort((a, b) =>
                              dayjs(a.Time, timeFormat).isAfter(dayjs(b.Time, timeFormat)) ? 1 : -1,
                          ),
                      }
                    : timeProgram,
            ),
        );
    }, [currentTimeProgram?.SwitchPoints]);

    const hasError = (scene: TimeProgram) => {
        return isEmpty(scene.Name) || timePrograms.filter((x) => x.Name === scene.Name).length > 1;
    };

    const currentError = useMemo(() => {
        if (!currentTimeProgram) {
            return;
        }

        if (isEmpty(currentTimeProgram.Name)) {
            return t('error.nameCannotBeEmpty');
        }

        if (timePrograms.filter((x) => x.Name === currentTimeProgram.Name).length > 1) {
            return t('error.nameMustBeUnique');
        }
    }, [currentTimeProgram, timePrograms]);

    const onRemove = (index: number) => {
        setTimePrograms((prev) => prev.filter((scene) => scene !== prev[index]));
        setSelectedTimeProgramIndex((prev) => (prev === 0 ? prev : prev - 1));
    };

    const addTimeProgram = () => {
        const newTimeProgram: TimeProgram = {
            Name: getNewTimeProgramName(timePrograms),
            days: [],
            SwitchPoints: [
                {
                    Value: false,
                    Time: '00:00',
                },
            ],
        };

        const newTimePrograms = [...timePrograms, newTimeProgram];

        setTimePrograms(newTimePrograms);
    };

    const addSwitchPoint = () => {
        const newSp: SwitchPoint = {
            Value: true,
            Time: '00:00',
        };
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex
                    ? {
                          ...timeProgram,
                          SwitchPoints: [newSp, ...timeProgram.SwitchPoints],
                      }
                    : timeProgram,
            ),
        );

        return newSp;
    };

    const removeSwithPoint = (switchpointIndex: number) => {
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex
                    ? {
                          ...timeProgram,
                          SwitchPoints: timeProgram.SwitchPoints.filter(
                              (x) => x !== timeProgram.SwitchPoints[switchpointIndex],
                          ),
                      }
                    : timeProgram,
            ),
        );
    };

    const onChangeSwitchpointTime = (switchpointIndex: number, time: string) => {
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex
                    ? {
                          ...timeProgram,
                          SwitchPoints: timeProgram.SwitchPoints.map((x, spIndex) =>
                              switchpointIndex === spIndex ? { ...x, Time: time } : x,
                          ),
                      }
                    : timeProgram,
            ),
        );
    };

    const onSave = async () => {
        if (!timeProgramDatapoint) {
            return;
        }

        const filteredTimePrograms: TimeProgram[] = [];

        timePrograms.forEach((timeProgram) => {
            const filteredSwitchPoints: SwitchPoint[] = [];

            timeProgram.SwitchPoints.forEach((point) => {
                if (!filteredSwitchPoints.find((x) => x.Time === point.Time && x.Value === point.Value)) {
                    filteredSwitchPoints.push(point);
                }
            });

            filteredTimePrograms.push({ ...timeProgram, SwitchPoints: filteredSwitchPoints });
        });

        const newTimeProgramDatapoint = {
            ...timeProgramDatapoint,
            TimeProgram: { ...timeProgramDatapoint.TimeProgram, TimeProgramList: timePrograms },
        };

        const result = await onDatapointChange(newTimeProgramDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onChangeName = (v: string) => {
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex ? { ...timeProgram, Name: v } : timeProgram,
            ),
        );
    };

    const onChangeDays = (v: number[]) => {
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex ? { ...timeProgram, days: v } : timeProgram,
            ),
        );
    };

    const onSwitchpointChanged = (switchpointIndex: number, switchpoint: SwitchPoint) => {
        setTimePrograms((prev) =>
            prev.map((timeProgram, index) =>
                index === selectedTimeProgramIndex
                    ? {
                          ...timeProgram,
                          SwitchPoints: timeProgram.SwitchPoints.map((x, spIndex) =>
                              switchpointIndex === spIndex ? switchpoint : x,
                          ),
                      }
                    : timeProgram,
            ),
        );
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!timeProgramDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (timeProgramDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.WholePageSpace size={[0, 8]} wrap>
                    {timePrograms?.map((timeProgram, index) => (
                        <ThemedCheckableTag
                            $error={hasError(timeProgram)}
                            key={index}
                            onClick={() => setSelectedTimeProgramIndex(index)}
                            checked={selectedTimeProgramIndex === index}
                        >
                            <Styled.TagWrapper>
                                <Styled.TagTitle>{timeProgram.Name}</Styled.TagTitle>
                                {timePrograms.length > 1 && (
                                    <Styled.IconWrapper>
                                        <IoMdCloseCircleOutline
                                            onClick={(e) => {
                                                onRemove(index);
                                                e.stopPropagation();
                                            }}
                                            size={15}
                                        />
                                    </Styled.IconWrapper>
                                )}
                            </Styled.TagWrapper>
                        </ThemedCheckableTag>
                    ))}
                    {timePrograms.length < 7 && (
                        <ThemedCheckableTag onClick={addTimeProgram} checked={false} $dashed>
                            <Styled.TagWrapper>
                                <MdAdd fontSize={25} />
                            </Styled.TagWrapper>
                        </ThemedCheckableTag>
                    )}
                </Styled.WholePageSpace>
                <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                <ThemedButton $action onClick={onSave} disabled={saveDisabled}>
                    {t('general.save')}
                </ThemedButton>
            </Styled.HeaderWrapper>
            {currentTimeProgram && (
                <TimeProgramView
                    virtualDevice={currentVirtualDevice}
                    timeProgram={currentTimeProgram}
                    currentError={currentError}
                    onChangeName={onChangeName}
                    onChangeDays={onChangeDays}
                    addSwitchPoint={addSwitchPoint}
                    removeSwithPoint={removeSwithPoint}
                    onChangeSwitchpointTime={onChangeSwitchpointTime}
                    onSwitchpointChanged={onSwitchpointChanged}
                    disabledDays={timePrograms.filter((tp) => tp !== currentTimeProgram).flatMap((tp) => tp.days)}
                />
            )}
        </Styled.MainWrapper>
    );
};

export default TimerEditPage;
