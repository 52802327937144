import { ThemedButton, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { NetatmoValidation } from 'models/server/NetatmoValidation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Icons, toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import * as Styled from './NetatmoValidator.styles';

const ClientId = '5e171567c52009f6d9481c94';
const AuthURL = 'https://api.netatmo.com/oauth2/authorize';
const Scopes = 'read_station read_thermostat write_thermostat read_homecoach';
const redirectUri = window.location.hostname.includes('localhost')
    ? 'http://localhost:3000/'
    : `https://${window.location.hostname}/`;
const redirectURI = (deviceId: number, serverId: number) =>
    `${redirectUri}server/${serverId}?netatmoDevice=${deviceId}`;

const NetatmoValidator = (): JSX.Element => {
    const { t } = useTranslation();
    const [init, setInit] = useState(false);
    const { devices, currentServer } = useServerConfig();
    const { apiServerFetch } = useServerApi();
    const { theme } = useTheme();

    const [validationDevices, setValidationDevices] = useState<NetatmoValidation[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const locationNetatmoDevice = searchParams.get('netatmoDevice');
    const locationCode = searchParams.get('code');

    const fetchDevices = async () => {
        const result = await apiServerFetch<{ devices: NetatmoValidation[] }>('netatmo');

        if (!result.result) {
            return [];
        }

        setValidationDevices(result.result.devices);

        return result.result.devices;
    };

    const initValidation = async () => {
        const result = await fetchDevices();

        if (!result) {
            return;
        }

        setModalVisible(result.some((x) => !x.refreshTokenValid));

        if (locationNetatmoDevice && locationCode) {
            setIsLoading(true);

            const refreshResult = await apiServerFetch(
                'netatmo',
                `?id=${Number(locationNetatmoDevice)}&code=${locationCode}&redirectUri=${redirectURI(
                    Number(locationNetatmoDevice),
                    currentServer?.id ?? 0,
                )}`,
                'PUT',
            );

            if (refreshResult.code !== 200) {
                toast.error(t('errors.netatmoConnectionError'));
                setIsLoading(false);
                setSearchParams((params) => {
                    params.delete('code');
                    params.delete('netatmoDevice');
                    return params;
                });
                return;
            }

            setSearchParams((params) => {
                params.delete('code');
                params.delete('netatmoDevice');
                return params;
            });

            await fetchDevices();

            setIsLoading(false);
        }
    };

    useEffect(() => {
        setInit(false);
    }, [devices]);

    useEffect(() => {
        if (devices === undefined || init) {
            return;
        }

        setInit(true);
        initValidation();
    }, [devices, init]);

    const onClose = () => {
        setModalVisible(false);
    };

    const onReconnect = (device: NetatmoValidation) => {
        const authorizationRequest = `${AuthURL}?client_id=${encodeURIComponent(
            ClientId,
        )}&redirect_uri=${encodeURIComponent(
            redirectURI(device.id, currentServer?.id ?? 0),
        )}&scope=${encodeURIComponent(Scopes)}&response_type=code`;

        window.open(authorizationRequest, '_self');
    };

    return (
        <>
            {modalVisible && (
                <ThemedModal
                    title={t('netatmoModal.netatmoDevices')}
                    open={true}
                    onCancel={onClose}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    width={450}
                    footer={
                        <Styled.Footer>
                            <ThemedButton $version="2" onClick={onClose}>
                                {t('general.close')}
                            </ThemedButton>
                        </Styled.Footer>
                    }
                >
                    <Styled.NetatmoDevices>
                        {validationDevices.map((dev, index) => (
                            <Styled.NetatmoDevice key={index}>
                                <Styled.DeviceTitle $connected={dev.refreshTokenValid}>{dev.name}</Styled.DeviceTitle>
                                {dev.refreshTokenValid ? (
                                    <Styled.StatusWrapper>
                                        {Icons.success({
                                            type: 'success',
                                            theme: theme,
                                            height: 16,
                                        })}
                                        {t('general.connected')}
                                    </Styled.StatusWrapper>
                                ) : (
                                    <ThemedButton
                                        loading={isLoading}
                                        danger
                                        type="primary"
                                        onClick={() => onReconnect(dev)}
                                    >
                                        {t('general.reconnect')}
                                    </ThemedButton>
                                )}
                            </Styled.NetatmoDevice>
                        ))}
                    </Styled.NetatmoDevices>
                </ThemedModal>
            )}
        </>
    );
};

export default NetatmoValidator;
