import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import HidingView from '../HidingView/HidingView';
import * as Styled from './Monitor.styles';
import MonitorView from './MonitorView/MonitorView';

const Monitor = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const monitorConfigDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.MonitorConfig),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            <HidingView category={virtualDevice.category} numberOfVisible={4}>
                {monitorConfigDatapoint?.Monitor?.filter((x) => x.View)?.map((dp) => (
                    <MonitorView key={dp.IOid} dp={dp} />
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default Monitor;
