import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import ServerSelectCard from 'components/ServerSelect/ServerSelectCard/ServerSelectCard';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import dayjs from 'dayjs';
import useCookie from 'hooks/useCookie';
import { useServerApi } from 'hooks/useServerApi';
import Server from 'models/Server';
import { EnergyStatistic } from 'models/server/EnergyStatistic';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import EnergyFilters from 'pages/EnergyMonitorChartsPage/EnergyFilters/EnergyFilters';
import {
    EnergyConfig,
    energyDateFormat,
    initEnergyConfig,
} from 'pages/EnergyMonitorChartsPage/EnergyMonitorChartsPage';
import { getDateFormat, serverFormat } from 'pages/EnergyMonitorChartsPage/MainChart/MainChart';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnergyReportConfig, EnergyReportVDConfig } from '../EnergyReportPage/EnergyReportPage';
import { EnergyReportVirtualDevices } from '../EnergyReportPage/EnergyReportVirtualDevices/EnergyReportVirtualDevices';
import * as Styled from './EneregyGraphsPage.styles';
import EnergyGraphsChartView from './EnergyGraphsChartView/EnergyGraphsChartView';

export type TotalEnergyStats = { name: string; 0?: number };

const EneregyGraphsPage = () => {
    const { t } = useTranslation();
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const { configs } = useContext(GroupedServersConfigContext);

    const [config, setConfig] = useState<EnergyReportConfig[]>(
        configs.map((x) => ({ server: x.server, selectedVirtualDevices: [] })),
    );
    const [currentView, setCurrentView] = useState<'energyMonitors' | 'charts'>('energyMonitors');

    const { value: configValue, updateCookie } = useCookie<EnergyConfig>('energy-config-old', initEnergyConfig);
    const energyConfig = configValue ?? initEnergyConfig;

    const { startDate, endDate, selectedFrameOption } = energyConfig;
    const isVirtualDeviceLoading = config.some((x) => x.selectedVirtualDevices?.some?.((z) => z.loading));
    const isEmpty = !configs.some(
        (x) => x.setupInfo && x.setupInfo.objects.items.some((x) => x.type === VirtualDeviceType.TotalEnergyMonitor),
    );

    useEffect(() => {
        if (currentView === 'energyMonitors') {
            return;
        }

        onDownloadCharts(false);
    }, [energyConfig]);

    const onSetVirtualDeviceConfig = (
        serverId: number,
        currentVirtualDeviceId: number,
        config: EnergyReportVDConfig,
    ) => {
        setConfig((prev) =>
            prev.map((x) =>
                x.server.id === serverId
                    ? {
                          ...x,
                          selectedVirtualDevices: x.selectedVirtualDevices?.map((z) =>
                              z.id === currentVirtualDeviceId ? config : z,
                          ),
                      }
                    : x,
            ),
        );
    };

    const getStats = async (serverId: number, currentVirtualDeviceId: number) => {
        onSetVirtualDeviceConfig(serverId, currentVirtualDeviceId, {
            id: currentVirtualDeviceId,
            loading: true,
            error: false,
            energy: [],
            power: [],
        });

        const currentConfig = configs.find((x) => x.server.id === serverId);

        const result = await apiServerFetchWithCustomPortalId<{ statistic: EnergyStatistic }>(
            serverId,
            'statistic',
            undefined,
            'POST',
            {
                payload: {
                    startDate: dayjs(startDate, energyDateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                    endDate: dayjs(endDate, energyDateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                    monitorObjectId: currentVirtualDeviceId,
                    type: selectedFrameOption,
                    maxCount: 60,
                },
            },
        );

        if (!result.result?.statistic || result.code !== 200 || !currentConfig?.setupInfo) {
            onSetVirtualDeviceConfig(serverId, currentVirtualDeviceId, {
                id: currentVirtualDeviceId,
                loading: false,
                error: true,
                energy: [],
                power: [],
            });
            return;
        }

        const energyData = (result.result.statistic.energyStats ?? []).map((point) => {
            return {
                name: dayjs(point.date, serverFormat).format(getDateFormat(selectedFrameOption)),
                0: Number((point.total / 1000).toFixed(4)),
            };
        });

        const powerData = (result.result.statistic.powerStats ?? []).map((point) => {
            return {
                name: dayjs(point.date, serverFormat).format(getDateFormat(selectedFrameOption)),
                0: Number((point.total / 1000).toFixed(4)),
            };
        });

        onSetVirtualDeviceConfig(serverId, currentVirtualDeviceId, {
            id: currentVirtualDeviceId,
            loading: false,
            error: false,
            energy: energyData,
            power: powerData,
        });
    };

    const onDownloadCharts = async (failed: boolean) => {
        Promise.all(
            config
                .filter((x) => x.selectedVirtualDevices?.length)
                .flatMap(
                    (x) =>
                        x.selectedVirtualDevices
                            ?.filter((x) => x.error === failed)
                            ?.map((z) => ({ serverId: x.server.id, virtualDeviceId: z.id }))
                            .map((x) => getStats(x.serverId, x.virtualDeviceId)),
                ),
        );
        setCurrentView('charts');
    };

    const updateConfig = (config: EnergyConfig) => {
        updateCookie(config, { expires: 365 });
    };

    const getCharts = (selectedServers: Server[], type: 'energy' | 'power') => {
        const vds = config.flatMap(
            (x) =>
                x.selectedVirtualDevices
                    ?.filter((d) => !d.error && selectedServers.some((z) => x.server.id === z.id))
                    ?.map((z) => ({
                        ...z,
                        vd: configs
                            .find((c) => c.server.id === x.server.id)
                            ?.setupInfo?.objects?.items?.find((y) => y.id === z.id),
                        server: x.server,
                    })),
        );

        if (!vds.length) {
            return;
        }

        const points = type === 'energy' ? vds[0]?.energy?.map((x) => x.name) : vds[0]?.power?.map((x) => x.name);

        if (!points) {
            return;
        }

        const data = points.map((point) => {
            const vdsData = vds.reduce((prev, curr) => {
                if (!curr) {
                    return prev;
                }

                return {
                    ...prev,
                    [`${curr.server.id}-${curr.id}`]:
                        type === 'energy'
                            ? curr.energy.find((x) => x.name === point)?.[0]
                            : curr.power.find((x) => x.name === point)?.[0],
                };
            }, {});
            return {
                name: point,
                ...vdsData,
            };
        });

        return data;
    };

    const handleSelectAll = () => {
        setConfig((prev) =>
            prev.map((x) => {
                const serverConfig = configs.find((z) => x.server.id === z.server.id);

                return {
                    ...x,
                    selectedVirtualDevices:
                        serverConfig?.setupInfo?.objects?.items
                            ?.filter((x) => x.type === VirtualDeviceType.TotalEnergyMonitor)
                            ?.map((z) => ({
                                id: z.id,
                                loading: false,
                                error: false,
                                energy: [],
                                power: [],
                                hide: false,
                            })) ?? [],
                };
            }),
        );
    };

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Space size={20} wrap>
                    {currentView === 'charts' &&
                        config
                            .flatMap(
                                (x) =>
                                    x.selectedVirtualDevices?.map((z) => ({
                                        ...z,
                                        vd: configs
                                            .find((c) => c.server.id === x.server.id)
                                            ?.setupInfo?.objects?.items?.find((y) => y.id === z.id),
                                        server: x.server,
                                    })),
                            )
                            .filter((x) => x !== undefined && configs.some((z) => z.server.id === x.server.id))
                            .map((x) => (
                                <ServerSelectCard
                                    key={x?.id}
                                    name={`${x?.server.name} - ${x?.vd?.name ?? ''}`}
                                    loading={x?.loading ?? false}
                                    error={x?.error ?? false}
                                />
                            ))}
                </Space>
                <Styled.ButtonsWrapper>
                    {currentView === 'energyMonitors' && (
                        <ThemedButton disabled={isEmpty} onClick={handleSelectAll}>
                            {t('general.selectAll')}
                        </ThemedButton>
                    )}
                    <ThemedButton
                        disabled={
                            isVirtualDeviceLoading ||
                            (currentView === 'energyMonitors' &&
                                (isEmpty || !config.some((x) => x.selectedVirtualDevices?.length)))
                        }
                        $action
                        onClick={() =>
                            currentView === 'charts' ? setCurrentView('energyMonitors') : onDownloadCharts(false)
                        }
                    >
                        {currentView === 'charts' ? t('general.changeEnergyMonitors') : t('general.showChart')}
                    </ThemedButton>
                </Styled.ButtonsWrapper>
            </Styled.HeaderWrapper>
            {currentView === 'energyMonitors' && (
                <Styled.ContentWrapper>
                    {isEmpty && <EmptyError title={t('errors.noEnergyMonitorsToSelect')} />}
                    <Space direction="vertical" size={20}>
                        {config.map((ser) => (
                            <EnergyReportVirtualDevices
                                key={ser.server.id}
                                server={ser.server}
                                config={ser}
                                setConfig={setConfig}
                                serverConfig={configs.find((x) => x.server.id === ser.server.id)}
                            />
                        ))}
                    </Space>
                </Styled.ContentWrapper>
            )}
            {currentView === 'charts' && (
                <Styled.ContentWrapper>
                    {!isEmpty && (
                        <EnergyFilters
                            isLoading={isVirtualDeviceLoading}
                            config={energyConfig}
                            updateConfig={updateConfig}
                        />
                    )}
                    <Styled.Title>
                        {t('energyManagement.powerHistoricalValues')}
                        <Tooltip title={t('energyManagement.valuesAbove')}>
                            <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer' }} />
                        </Tooltip>
                    </Styled.Title>
                    <EnergyGraphsChartView
                        data={getCharts(
                            configs.map((x) => x.server),
                            'power',
                        )}
                        unit="kW"
                        config={config.filter((x) => configs.map((x) => x.server).some((z) => z.id === x.server.id))}
                        onSetVirtualDeviceConfig={onSetVirtualDeviceConfig}
                        serverConfigs={configs}
                    />
                    <Styled.Title>
                        {t('energyManagement.energyHistoricalValues')}
                        <Tooltip title={t('energyManagement.valuesAbove')}>
                            <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer' }} />
                        </Tooltip>
                    </Styled.Title>
                    <EnergyGraphsChartView
                        data={getCharts(
                            configs.map((x) => x.server),
                            'energy',
                        )}
                        unit="kWh"
                        config={config.filter((x) => configs.map((x) => x.server).some((z) => z.id === x.server.id))}
                        serverConfigs={configs}
                        onSetVirtualDeviceConfig={onSetVirtualDeviceConfig}
                    />
                </Styled.ContentWrapper>
            )}
        </Styled.MainWrapper>
    );
};

export default EneregyGraphsPage;
