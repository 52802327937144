import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SelectRowWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const SelectTitleWrapper = styled.div`
    flex: 1;
`;

export const SelectWrapper = styled.div`
    flex: 1;
`;
