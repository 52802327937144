import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { ThemedButton, ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { ServerContext } from 'contexts/ServerContext';
import {
    getColorStringWithChangedColorToSend,
    getColorStringWithChangedValueToSend,
    getLightColor,
    getLightValue,
} from 'helpers/DatapointHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import { useServerConfig } from 'hooks/useServerConfig';
import { Datapoint } from 'models/server/Datapoint';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useContext, useMemo } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import ColorSelectionButton from '../ColorSelectionButton/ColorSelectionButton';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import HidingView from '../HidingView/HidingView';
import * as Styled from './Lights.styles';

const Lights = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { category, datapoints } = virtualDevice;
    const navigate = useNavigate();
    const { currentServer } = useServerConfig();
    const { datapointLoading, onDatapointValueChange } = useDatapoint();
    const { navigationPath } = useContext(ServerContext);

    const sceneConfigDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.ScenesLight),
        [datapoints],
    );
    const sceneSelectionDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.SceneSelection),
        [datapoints],
    );
    const mainSwitchDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch),
        [datapoints],
    );
    const lightsColorDatapoints = useMemo(
        () =>
            datapoints?.filter(
                (x) =>
                    (x.type == DatapointType.Color ||
                        x.type == DatapointType.Color_strip ||
                        x.type == DatapointType.Brightness ||
                        x.type == DatapointType.Switch) &&
                    x.name.includes('light'),
            ),
        [datapoints],
    );
    const allScenes = useMemo(() => {
        return sceneConfigDatapoint?.Scenes?.map((x, index) => ({ value: index, label: x.Name }));
    }, [sceneConfigDatapoint]);

    const selectedScene = useMemo(() => {
        const selectedSceneIndex = Number(sceneSelectionDatapoint?.value ?? 0);
        return allScenes?.[selectedSceneIndex];
    }, [allScenes, sceneSelectionDatapoint?.value]);

    const onEditScenes = () => {
        navigate(
            {
                pathname: `${navigationPath}/${currentServer?.id}/scenes/${virtualDevice.id}`,
            },
            { state: { path: window.location.pathname, search: window.location.search } },
        );
    };

    const onModeChanged = (v: unknown) => {
        if (!sceneSelectionDatapoint) {
            return;
        }

        onDatapointValueChange(sceneSelectionDatapoint.id, v as number);
    };

    const onColorChange = (dp: Datapoint, value: string) => {
        onDatapointValueChange(dp.id, getColorStringWithChangedColorToSend(value, dp.value.toString()));
    };

    const onBrightnessChange = async (dp: Datapoint, value: number) => {
        return await onDatapointValueChange(dp.id, getColorStringWithChangedValueToSend(value, dp.value.toString()));
    };

    return (
        <Styled.MainWrapper>
            {mainSwitchDatapoint && (
                <DatapointSwitchView datapoint={mainSwitchDatapoint} virtualDevice={virtualDevice} />
            )}
            {sceneSelectionDatapoint && (
                <Styled.SceneWrapper>
                    <ThemedSelect
                        value={selectedScene}
                        options={allScenes}
                        onChange={onModeChanged}
                        $version="virtualDevice"
                        loading={datapointLoading === sceneSelectionDatapoint.id}
                    />
                    <ThemedButton
                        onClick={onEditScenes}
                        $width={32}
                        $version="virtualDevice"
                        shape="round"
                        icon={<MdOutlineEdit size={18} />}
                    />
                </Styled.SceneWrapper>
            )}
            <HidingView numberOfVisible={0} category={category}>
                {lightsColorDatapoints.map((dp, index) => (
                    <div key={index}>
                        {(dp.type == DatapointType.Color || dp.type == DatapointType.Color_strip) && (
                            <Styled.ColorDatapointWrapper>
                                <DatapointSliderView
                                    hideValue
                                    useCustomName
                                    datapoint={dp}
                                    color={getLightColor(dp.value.toString())}
                                    virtualDevice={virtualDevice}
                                    customOnChange={(v) => onBrightnessChange(dp, v)}
                                    customValue={getLightValue(dp.value.toString(), dp.range)}
                                />
                                <ColorSelectionButton
                                    color={getLightColor(dp.value.toString())}
                                    onChange={(v) => onColorChange(dp, v)}
                                    loading={datapointLoading === dp.id}
                                />
                            </Styled.ColorDatapointWrapper>
                        )}
                        {dp.type == DatapointType.Brightness && (
                            <DatapointSliderView useCustomName datapoint={dp} virtualDevice={virtualDevice} />
                        )}
                        {dp.type == DatapointType.Switch && (
                            <DatapointSwitchView useCustomName datapoint={dp} virtualDevice={virtualDevice} />
                        )}
                    </div>
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default Lights;
