import { Space, Tooltip } from 'antd';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import SingleInputModal from 'components/SingleInputModal/SingleInputModal';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import YesNoModal from 'components/YesNoModal/YesNoModal';
import { AuthContext } from 'contexts/AuthContext';
import { useApi } from 'hooks/useApi';
import { useImages } from 'hooks/useImages';
import usePortalUser from 'hooks/usePortalUser';
import { PortalUser } from 'models/User';
import { UserRoleType } from 'models/server/enums/UserRoleType';
import { UserStatus } from 'models/server/enums/UserStatus';
import { AddButton, ThemedTagWrapper } from 'pages/Admin/AdminServersEditPage/AdminServerView/AdminServerView.styles';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { reactRedirectUri } from '../InviteUserModal/InviteUserModal';
import * as Styled from './AdminUserView.styles';
import AssignServerModal from './AssignServerModal/AssignServerModal';

type Props = {
    user: PortalUser;
    onReloadUsers: () => void;
};

const AdminUserView = ({ user, onReloadUsers }: Props) => {
    const { t } = useTranslation();
    const { apiFetch } = useApi();
    const { colors } = useTheme();
    const { servers } = useContext(AuthContext);
    const { darkImages } = useImages();
    const { portalUser } = usePortalUser();

    const [loading, setLoading] = useState<'status' | 'role' | 'delete' | 'edit' | 'resend'>();
    const [modalVisible, setModalVisible] = useState<'edit' | 'delete' | 'assignServer'>();

    const color = useMemo(() => {
        switch (user.status) {
            case UserStatus.enabled:
                return colors.success;
            case UserStatus.invited:
                return colors.pending;
            default:
                return colors.error;
        }
    }, []);

    const userTitle = useMemo(() => {
        switch (user.status) {
            case UserStatus.enabled:
                return t('general.active');
            case UserStatus.invited:
                return t('general.pendingInvitation');
            default:
                return t('general.inactive');
        }
    }, []);

    const onEditUserName = async (value: string) => {
        setLoading('edit');

        const result = await apiFetch('Users', 'PATCH', undefined, { id: user.id, name: value });

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(undefined);
            return;
        }

        onReloadUsers();
        setModalVisible(undefined);
        setLoading(undefined);
    };

    const changeActivity = async () => {
        setLoading('status');

        const result = await apiFetch('Users', 'PATCH', undefined, {
            id: user.id,
            status: user.status === UserStatus.disabled ? UserStatus.enabled : UserStatus.disabled,
        });

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(undefined);
            return;
        }

        onReloadUsers();
        setLoading(undefined);
    };

    const changeRole = async () => {
        setLoading('role');

        const result = await apiFetch('Users', 'PATCH', undefined, {
            id: user.id,
            role: user.role === UserRoleType.Admin ? UserRoleType.User : UserRoleType.Admin,
        });

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(undefined);
            return;
        }

        onReloadUsers();
        setLoading(undefined);
    };

    const onDelete = async () => {
        setLoading('delete');

        const result = await apiFetch('Users', 'DELETE', `/${user.id}`);

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(undefined);
            return;
        }

        onReloadUsers();
        setLoading(undefined);
    };

    const onResend = async () => {
        setLoading('resend');

        const result = await apiFetch(
            'Invitations/Resend',
            'PUT',
            `?userId=${user.id}&acceptURL=${encodeURIComponent(reactRedirectUri)}`,
        );

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(undefined);
            return;
        }

        toast.success(t('general.invitationResendedToUser'));
        setLoading(undefined);
    };

    return (
        <>
            <Tooltip color={color} title={userTitle}>
                <Styled.MainWrapper>
                    <Styled.HeadWrapper>
                        <div>
                            <Styled.IconWrapper $color={color}>
                                <Styled.ServerImage src={darkImages.user} />
                            </Styled.IconWrapper>
                            {user.role === UserRoleType.Admin && (
                                <Styled.AdminInfo>{t('general.admin')}</Styled.AdminInfo>
                            )}
                        </div>
                        <Styled.NamesWrapper>
                            <Styled.ServerName>{user.name}</Styled.ServerName>
                            <Styled.UserEmail>{user.eMail}</Styled.UserEmail>
                        </Styled.NamesWrapper>
                    </Styled.HeadWrapper>
                    <Line />
                    {user.role === UserRoleType.Admin ? (
                        <Styled.AdminServersInfo>{t('general.adminUserAccessToAllServers')}</Styled.AdminServersInfo>
                    ) : (
                        <Space size={10} wrap>
                            {user.userServerAssignments.map((usa, index) => (
                                <ThemedTagWrapper key={index} color={colors.backgroundLayer2}>
                                    {servers.find((x) => x.id === usa.serverId)?.name}
                                </ThemedTagWrapper>
                            ))}
                            <Tooltip title={t('general.editServerAssignement')}>
                                <AddButton checked={false} $dashed onClick={() => setModalVisible('assignServer')}>
                                    <MdEdit fontSize={25} />
                                </AddButton>
                            </Tooltip>
                        </Space>
                    )}
                    <Line />
                    <Space size={20} wrap>
                        {user.status === UserStatus.invited && (
                            <ThemedButton loading={loading === 'resend'} onClick={onResend}>
                                {t('general.resendInvitation')}
                            </ThemedButton>
                        )}
                        <ThemedButton
                            onClick={() => setModalVisible('edit')}
                            disabled={user.status === UserStatus.invited}
                        >
                            {t('general.edit')}
                        </ThemedButton>
                        {portalUser?.id !== user.id && (
                            <>
                                <ThemedButton
                                    loading={loading === 'status'}
                                    disabled={user.status === UserStatus.invited}
                                    onClick={changeActivity}
                                >
                                    {user.status === UserStatus.enabled
                                        ? t('general.deactivate')
                                        : t('general.activate')}
                                </ThemedButton>
                                <ThemedButton danger loading={loading === 'role'} onClick={changeRole}>
                                    {user.role === UserRoleType.Admin
                                        ? t('general.removeAdmin')
                                        : t('general.addAdmin')}
                                </ThemedButton>
                                {user.status === UserStatus.invited && (
                                    <ThemedButton
                                        danger
                                        loading={loading === 'delete'}
                                        onClick={() => setModalVisible('delete')}
                                    >
                                        {t('general.delete')}
                                    </ThemedButton>
                                )}
                            </>
                        )}
                    </Space>
                </Styled.MainWrapper>
            </Tooltip>
            {modalVisible === 'edit' && (
                <SingleInputModal
                    isLoading={loading === 'edit'}
                    isVisible={true}
                    defaultValue={user.name}
                    title={t('usersConfiguration.editUser')}
                    placeholder={t('usersConfiguration.userName')}
                    onCancelClicked={() => setModalVisible(undefined)}
                    onAddClicked={(value) => onEditUserName(value)}
                    yesButtonText={t('general.save')}
                />
            )}
            {modalVisible === 'delete' && (
                <YesNoModal
                    description={`${t('general.areYouSureYouWantDeleteUser')} ${user.name}?`}
                    onYesClicked={onDelete}
                    onNoClicked={() => setModalVisible(undefined)}
                    yesDanger
                />
            )}
            {modalVisible === 'assignServer' && (
                <AssignServerModal onRefetch={onReloadUsers} user={user} onCancel={() => setModalVisible(undefined)} />
            )}
        </>
    );
};

export default AdminUserView;
