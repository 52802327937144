import { Space } from 'antd';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
`;

export const TagWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    min-width: 50px;
    max-width: 140px;
    min-height: 40px;
`;

export const TagTitle = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 25px;
    gap: 15px;

    @media (max-width: 750px) {
        flex-direction: column-reverse;
    }
`;

export const WholePageSpace = styled(Space)`
    flex: 1;
`;

export const IconWrapper = styled.div`
    min-width: 15px;
    display: flex;
    align-items: center;
`;
