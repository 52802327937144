import FileSaver from 'file-saver';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { utils, write } from 'xlsx';

import {
    ThemedButton,
    ThemedModal,
    ThemedSelect,
    ThemedSwitch,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import dayjs from 'dayjs';
import useCookie from 'hooks/useCookie';
import { useDatapoint } from 'hooks/useDatapoint';
import { useServerConfig } from 'hooks/useServerConfig';
import { Datapoint } from 'models/server/Datapoint';
import { Device } from 'models/server/Device';
import { GraphType, IoConfig } from 'models/server/EnergyConfig';
import { EnergyStatisticItem } from 'models/server/EnergyStatistic';
import { Info } from 'models/server/Info';
import { Ios } from 'models/server/Ios';
import { Room } from 'models/server/Room';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { DeviceType } from 'models/server/enums/DeviceType';
import { useTheme } from 'styled-components';
import { FrameSelectionOption } from '../EnergyMonitorChartsPage';
import { getEnergyBulk } from '../getEnergyBulk';
import * as Styled from './MainChart.styles';

type Props = {
    stats: EnergyStatisticItem[];
    frame: FrameSelectionOption;
    endDate: string;
    iosConfig: IoConfig[];
    isLoading: boolean;
    unit: string;
    downloadTitle: string;
    virtualDevice: VirtualDevice;
};

export const serverFormat = 'YYYY-MM-DDTHH:mm:ss';

const getName = (ioId: number, ios: Ios[], devices: Device[], rooms: Room[], info?: Info) => {
    const connectedIos = ios?.find((x) => x.id === ioId);

    if (info?.starterkit) {
        const device = devices?.find((x) => x.id === connectedIos?.device);

        if (device) {
            const room = rooms?.find((x) => x.id === device.roomid);

            let deviceName = device.name;

            if (device.type === DeviceType.RadioSwitchDual || device.type === DeviceType.RadioSwitchDualPlus) {
                if (connectedIos?.name?.includes('EI1')) {
                    deviceName += ' (EI1)';
                } else if (connectedIos?.name?.includes('EI2')) {
                    deviceName += ' (EI2)';
                } else if (connectedIos?.name?.includes('PI1')) {
                    deviceName += ' (PI1)';
                } else if (connectedIos?.name?.includes('PI2')) {
                    deviceName += ' (PI2)';
                }
            }

            if (room) {
                return `${deviceName} - ${room.name}`;
            }
        }
    }

    return connectedIos?.name;
};

export const getDateFormat = (frame: FrameSelectionOption) => {
    switch (frame) {
        case FrameSelectionOption.hourly:
            return 'DD.MM.YYYY HH:mm';
        case FrameSelectionOption.daily:
            return 'DD.MM.YYYY';
        case FrameSelectionOption.monthly:
            return 'MM.YYYY';
        case FrameSelectionOption.yearly:
            return 'YYYY';
        default:
            return 'DD.MM.YYYY HH:mm:ss';
    }
};

export const getFormattedData = (
    stats: EnergyStatisticItem[],
    ios: Ios[],
    devices: Device[],
    rooms: Room[],
    frame: FrameSelectionOption,
    endDate: string,
    info?: Info,
) => {
    if (!stats?.length) {
        return { chart: [], download: [] };
    }

    const formated = stats.map((point) => {
        const data = point.items.reduce((prev, curr) => {
            return {
                ...prev,
                [curr.ioid]: Number((curr.value / 1000).toFixed(4)),
            };
        }, {});

        if (point.total !== undefined && point.total !== null) {
            return {
                name: dayjs(point.date, serverFormat).format(getDateFormat(frame)),
                ...data,
                0: Number((point.total / 1000).toFixed(4)),
            };
        }

        return {
            name: dayjs(point.date, serverFormat).format(getDateFormat(frame)),
            ...data,
        };
    });

    const formatedDownload = stats.map((point, index) => {
        const data = point.items.reduce((prev, curr) => {
            return {
                ...prev,
                [getName(curr.ioid, ios, devices, rooms, info) ?? '']: (curr.value / 1000).toFixed(4),
            };
        }, {});

        return {
            'From date': dayjs(point.date, serverFormat).format(getDateFormat(frame)),
            'To date':
                index === stats.length - 1
                    ? dayjs(endDate, 'YYYY-MM-DD HH:mm').format(getDateFormat(frame))
                    : dayjs(stats[index + 1].date, serverFormat).format(getDateFormat(frame)),
            ...data,
            Total: point.total ? (point.total / 1000).toFixed(4) : 0,
        };
    });

    return { chart: formated, download: formatedDownload };
};

const MainChart = ({
    stats,
    frame,
    iosConfig: propsIosConfig,
    isLoading,
    endDate,
    unit,
    downloadTitle,
    virtualDevice,
}: Props): JSX.Element => {
    const { isDemo } = useContext(AuthContext);
    const { onDatapointChange } = useDatapoint();
    const {
        ios: serverIos,
        rooms: serverRooms,
        devices: serverDevices,
        info: serverInfo,
        onRefreshConfig,
    } = useServerConfig();
    const { colors } = useTheme();
    const { t } = useTranslation();

    const { ios, rooms, devices, info } = isDemo
        ? getEnergyBulk()
        : { ios: serverIos, rooms: serverRooms, devices: serverDevices, info: serverInfo };

    const [demoIosConfig, setDemoIosConfig] = useState(propsIosConfig);

    const iosConfig = useMemo(() => (isDemo ? demoIosConfig : propsIosConfig), [propsIosConfig, isDemo, demoIosConfig]);

    const lineIos = useMemo(
        () => iosConfig?.filter((x) => x.Graph === GraphType.Line || x.Graph === undefined),
        [iosConfig],
    );
    const areaIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Area), [iosConfig]);
    const beamIos = useMemo(() => iosConfig?.filter((x) => x.Graph === GraphType.Beam), [iosConfig]);

    const [isModalLoading, setIsModalLoading] = useState(false);

    const { value: chartsProps, updateCookie: setChartsProps } = useCookie<{ id: number; hide: boolean }[]>(
        btoa(info?.serialnumber ?? 'serial') + `-${unit}` + '-chartprops-old',
        [
            { id: 0, hide: false },
            ...(iosConfig
                ?.filter((x) => x.Graph === GraphType.Line || x.Graph === GraphType.Area || x.Graph === GraphType.Beam)
                ?.map((x) => ({ id: x.Id, hide: false })) ?? []),
        ],
    );

    const [selectedChartIo, setSelectedChartIo] = useState<number>();

    const chartTypes = useMemo(
        () => [
            { value: GraphType.Line, label: t('general.line'), disabled: selectedChartIo === 0 },
            { value: GraphType.Area, label: t('general.area'), disabled: selectedChartIo === 0 },
            { value: GraphType.Beam, label: t('general.beam'), disabled: selectedChartIo === 0 },
        ],
        [selectedChartIo],
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectBar = (e: any) => {
        setSelectedChartIo(e.dataKey);
    };

    const formatedLogs = useMemo(
        () => getFormattedData(stats, ios, devices, rooms, frame, endDate, info),
        [stats, frame],
    );

    const onDownload = () => {
        const workbook = utils.book_new();
        const dataSheet = utils.json_to_sheet(formatedLogs.download);
        utils.book_append_sheet(workbook, dataSheet, downloadTitle.replace('/', ''));
        const excel = write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const finalData = new Blob([excel]);
        FileSaver.saveAs(finalData, downloadTitle + '.xlsx');
    };

    return (
        <Styled.Wrapper>
            {formatedLogs?.download?.length > 0 && (
                <ThemedButton
                    onClick={onDownload}
                    disabled={isLoading}
                    style={{ width: 'fit-content', marginLeft: 'auto' }}
                >
                    {t('energyManagement.downloadDataSheet')}
                </ThemedButton>
            )}
            <ResponsiveContainer height={400}>
                <ComposedChart data={formatedLogs.chart}>
                    <CartesianGrid stroke={colors.text} />
                    <XAxis dataKey="name" tick={{ fill: colors.text }} />
                    <YAxis
                        width={100}
                        tickFormatter={(label) => {
                            return label + ` ${unit}`;
                        }}
                        tick={{ fill: colors.text }}
                    />
                    <Tooltip
                        formatter={(label) => label + ` ${unit}`}
                        contentStyle={{ backgroundColor: colors.backgroundLayer2 }}
                    />
                    <Legend onClick={selectBar} />
                    <ReferenceLine y={0} stroke={colors.text} strokeDasharray="3 3" />
                    {areaIos?.map((x) => (
                        <Area
                            key={x.Id}
                            type="step"
                            dataKey={x.Id}
                            name={getName(x.Id, ios, devices, rooms, info)}
                            fill={x.Color}
                            fillOpacity={0.3}
                            stroke={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                            dot={false}
                        />
                    ))}
                    {beamIos?.map((x) => (
                        <Bar
                            key={x.Id}
                            dataKey={x.Id}
                            name={getName(x.Id, ios, devices, rooms, info)}
                            barSize={20}
                            fill={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                        />
                    ))}
                    {lineIos?.map((x) => (
                        <Line
                            dot={false}
                            key={x.Id}
                            dataKey={x.Id}
                            name={getName(x.Id, ios, devices, rooms, info)}
                            type="step"
                            stroke={x.Color}
                            hide={chartsProps?.find((z) => z.id === x.Id)?.hide}
                        />
                    ))}
                    <Line
                        key={0}
                        dataKey={0}
                        name={'Total'}
                        type="step"
                        stroke={'#000000'}
                        hide={chartsProps?.find((z) => z.id === 0)?.hide}
                        dot={false}
                    />
                </ComposedChart>
            </ResponsiveContainer>
            {selectedChartIo !== undefined && (
                <ThemedModal
                    title={
                        getName(selectedChartIo, ios, devices, rooms, info) ?? (selectedChartIo === 0 ? 'Total' : '')
                    }
                    width={300}
                    style={{ top: '25%' }}
                    onCancel={() => setSelectedChartIo(undefined)}
                    open={true}
                    closable={!isModalLoading}
                    footer={null}
                >
                    <ThemedSelect
                        loading={isModalLoading}
                        showSearch={false}
                        onChange={async (v: unknown) => {
                            if (isDemo) {
                                setDemoIosConfig((prev) =>
                                    prev.map((x) => (x.Id === selectedChartIo ? { ...x, Graph: v as GraphType } : x)),
                                );
                                return;
                            }

                            const dp = virtualDevice?.datapoints?.find(
                                (x) => x.type === DatapointType.CentralEnergyConfig,
                            );
                            const connectedConfig = dp?.EnergyConfig?.IOConfigs.find((x) => x.Id === selectedChartIo);
                            if (!connectedConfig || !dp?.EnergyConfig?.IOConfigs || !v) {
                                return;
                            }
                            setIsModalLoading(true);
                            const newDatapoint: Datapoint = {
                                ...dp,
                                EnergyConfig: {
                                    ...dp.EnergyConfig,
                                    IOConfigs: dp.EnergyConfig.IOConfigs.map((x) =>
                                        x.Id === selectedChartIo ? { ...connectedConfig, Graph: v as GraphType } : x,
                                    ),
                                },
                            };

                            await onDatapointChange(newDatapoint);

                            await onRefreshConfig?.();

                            setIsModalLoading(false);
                            setSelectedChartIo(undefined);
                        }}
                        value={
                            selectedChartIo === 0
                                ? chartTypes.find((x) => x.value === GraphType.Line)
                                : chartTypes.find(
                                      (x) => x.value === iosConfig.find((x) => x.Id === selectedChartIo)?.Graph,
                                  )
                        }
                        options={chartTypes}
                        disabled={isLoading}
                    />
                    <Styled.EditModalWrapper>
                        {t('general.visible')}
                        <ThemedSwitch
                            onChange={(value) =>
                                setChartsProps(
                                    chartsProps?.map((x) =>
                                        x.id === selectedChartIo ? { ...x, hover: false, hide: !value } : x,
                                    ) ?? [],
                                    { expires: 365 },
                                )
                            }
                            checked={!chartsProps?.find((x) => x.id === selectedChartIo)?.hide}
                        />
                    </Styled.EditModalWrapper>
                </ThemedModal>
            )}
        </Styled.Wrapper>
    );
};

export default MainChart;
