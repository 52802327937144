import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
`;

export const ButtonsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 20px;
    justify-items: center;
`;

export const RoundButton = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &:active {
        opacity: 0.5;
    }
`;

export const Footer = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
    justify-content: flex-end;
`;
