import { Space, Tooltip } from 'antd';
import {
    ColorStateButton,
    ThemedButton,
    ThemedInput,
    ThemedTimePicker,
} from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { useImages } from 'hooks/useImages';
import { SwitchPoint } from 'models/server/SwitchPoint';
import { TimeProgram } from 'models/server/TimeProgram';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { VirtualDeviceConfigPoint } from 'models/server/VirtualDeviceConfigPoint';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd, MdOutlineEdit } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { timeFormat } from '../SchedulerEditPage';
import SwitchpointDeviceView from '../SwitchpointDeviceView/SwitchpointDeviceView';
import * as Styled from './TimeProgramView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    timeProgram: TimeProgram;
    currentError?: string;
    disabledDays: number[];
    currentTimeProgramIndex: number;
    onChangeName: (v: string) => void;
    onChangeDays: (v: number[]) => void;
    addSwitchPoint: () => SwitchPoint;
    removeSwithPoint: (i: number) => void;
    onChangeSwitchpointTime: (i: number, time: string) => void;
    onChangeSwitchPoint: (i: number, sp: SwitchPoint) => void;
};

const order = [1, 2, 3, 4, 5, 6, 0];

const TimeProgramView = ({
    timeProgram,
    virtualDevice,
    currentError,
    disabledDays,
    currentTimeProgramIndex,
    onChangeName,
    onChangeDays,
    addSwitchPoint,
    removeSwithPoint,
    onChangeSwitchPoint,
    onChangeSwitchpointTime,
}: Props) => {
    const { t } = useTranslation();
    const { getCategorySettings } = useImages();
    const { colors } = useTheme();
    const { SwitchPoints } = timeProgram;

    const [currentSwitchpoint, setCurrentSwitchpoint] = useState<SwitchPoint>(SwitchPoints[0]);

    const categorySettings = useMemo(() => getCategorySettings(virtualDevice.category), [virtualDevice]);

    const daysConfig: Record<number, string> = useMemo(
        () => ({
            1: t('timeProgramEdit.mo'),
            2: t('timeProgramEdit.tu'),
            3: t('timeProgramEdit.we'),
            4: t('timeProgramEdit.th'),
            5: t('timeProgramEdit.fr'),
            6: t('timeProgramEdit.sa'),
            0: t('timeProgramEdit.su'),
        }),
        [],
    );

    const onDayClicked = (day: number) => {
        if (timeProgram.days.includes(Number(day))) {
            onChangeDays(timeProgram.days.filter((x) => x !== day));
            return;
        }

        onChangeDays([...timeProgram.days, day]);
    };

    const addNewSwitchpoint = () => {
        const newSp = addSwitchPoint();
        setCurrentSwitchpoint(newSp);
    };

    useMemo(() => {
        setCurrentSwitchpoint(SwitchPoints[0]);
    }, [currentTimeProgramIndex]);

    const onChangeDeviceValue = (config: VirtualDeviceConfigPoint, value: string) => {
        const newSwitchpoint: SwitchPoint = {
            ...currentSwitchpoint,
            FB: currentSwitchpoint.FB?.map((fb) => (config === fb ? { ...fb, Value: value } : fb)),
        };
        onChangeSwitchPoint(SwitchPoints.indexOf(currentSwitchpoint), newSwitchpoint);
        setCurrentSwitchpoint(newSwitchpoint);
    };

    const onRemoveSwithPoint = (index: number) => {
        if (SwitchPoints.indexOf(currentSwitchpoint) === index) {
            setCurrentSwitchpoint(index === 0 ? SwitchPoints[1] : SwitchPoints[index - 1]);
        }
        removeSwithPoint(index);
    };

    return (
        <>
            <Styled.HeaderWrapper size={20} wrap>
                <Tooltip open={!!currentError} title={currentError} color={colors.error}>
                    <ThemedInput value={timeProgram.Name} onChange={(v) => onChangeName(v.currentTarget.value)} />
                </Tooltip>
                <Space size={10}>
                    {Object.keys(daysConfig)
                        .sort((a, b) => order.indexOf(Number(a)) - order.indexOf(Number(b)))
                        .map((day) => (
                            <ColorStateButton
                                shape="round"
                                key={day}
                                $color={categorySettings.color}
                                $width={40}
                                $isActive={timeProgram.days.includes(Number(day))}
                                disabled={disabledDays.includes(Number(day))}
                                onClick={() => onDayClicked(Number(day))}
                            >
                                {daysConfig[Number(day)]}
                            </ColorStateButton>
                        ))}
                </Space>
            </Styled.HeaderWrapper>
            <Styled.BodyWrapper>
                <Styled.SwitchPointsWrapper>
                    {SwitchPoints.map((sp, index) => (
                        <Styled.TimeThemedCheckableTag
                            key={index}
                            checked={index === SwitchPoints.indexOf(currentSwitchpoint)}
                        >
                            <Styled.IconWrapper>
                                {SwitchPoints.length > 1 && (
                                    <IoMdCloseCircleOutline
                                        onClick={(e) => {
                                            onRemoveSwithPoint(index);
                                            e.stopPropagation();
                                        }}
                                        size={17}
                                    />
                                )}
                            </Styled.IconWrapper>
                            <ThemedTimePicker
                                value={dayjs(sp.Time, 'HH:mm')}
                                format={timeFormat}
                                changeOnBlur
                                allowClear={false}
                                onChange={(v) => v && onChangeSwitchpointTime(index, v.format(timeFormat))}
                            />
                            <ThemedButton
                                $width={32}
                                $version="virtualDevice"
                                shape="round"
                                icon={<MdOutlineEdit size={18} />}
                                onClick={() => setCurrentSwitchpoint(sp)}
                            />
                        </Styled.TimeThemedCheckableTag>
                    ))}
                    <Styled.AddThemedCheckableTag checked={false} $dashed onClick={addNewSwitchpoint}>
                        <MdAdd fontSize={25} />
                    </Styled.AddThemedCheckableTag>
                </Styled.SwitchPointsWrapper>
                <Styled.VerticalLine />
                <Styled.FitContentSpace size={20} wrap>
                    {currentSwitchpoint.FB?.filter((x) => x.IsSelected)?.map((fb, index) => (
                        <SwitchpointDeviceView
                            ObjectID={fb.ObjectID}
                            currentValue={fb.Value}
                            key={index}
                            onChangeDeviceValue={(v) => onChangeDeviceValue(fb, v)}
                        />
                    ))}
                </Styled.FitContentSpace>
            </Styled.BodyWrapper>
        </>
    );
};

export default TimeProgramView;
