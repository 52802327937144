import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './GenericControl.styles';
import { useTranslation } from 'react-i18next';

const GenericControl = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { t } = useTranslation();

    const labels = useMemo(
        () =>
            virtualDevice.datapoints.filter(
                (x) => (x.type == DatapointType.Number && x.writeprotect) || x.type == DatapointType.StatusLight,
            ),
        [virtualDevice.datapoints],
    );
    const sliders = useMemo(
        () =>
            virtualDevice.datapoints.filter(
                (x) => (x.type == DatapointType.Number && !x.writeprotect) || x.type == DatapointType.Brightness,
            ),
        [virtualDevice.datapoints],
    );
    const switches = useMemo(
        () => virtualDevice.datapoints.filter((x) => x.type == DatapointType.Switch),
        [virtualDevice.datapoints],
    );
    const buttons = useMemo(
        () => virtualDevice.datapoints.filter((x) => x.type == DatapointType.Button),
        [virtualDevice.datapoints],
    );

    return (
        <Styled.MainWrapper>
            {labels.map((labelDp, index) => (
                <DatapointValueView
                    validateValue={(dp) =>
                        t(`general.${dp.value.toString()}`).startsWith('general.')
                            ? dp.value.toString()
                            : t(`general.${dp.value.toString()}`)
                    }
                    valueOrDp={labelDp}
                    key={index}
                />
            ))}
            {buttons.length > 0 &&
                buttons.map((buttonDp, index) => <DatapointButtonView datapoint={buttonDp} key={index} />)}
            {sliders.map((sliderdp, index) => (
                <DatapointSliderView virtualDevice={virtualDevice} datapoint={sliderdp} key={index} />
            ))}
            {switches.map((switchDp, index) => (
                <DatapointSwitchView virtualDevice={virtualDevice} datapoint={switchDp} key={index} />
            ))}
        </Styled.MainWrapper>
    );
};

export default GenericControl;
