import { routes } from 'App';
import { ButtonProps, Space } from 'antd';
import { ThemedButton, ThemedCheckableTag, ThemedSearch } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import useAuth from 'hooks/useAuth';
import usePortalUser from 'hooks/usePortalUser';
import { isEmpty } from 'lodash';
import Server from 'models/Server';
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiRefreshCw } from 'react-icons/fi';
import { IoSearchOutline } from 'react-icons/io5';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './ServerSelect.styles';
import ServerSelectCard from './ServerSelectCard/ServerSelectCard';
import ServerSelectView from './ServerSelectView/ServerSelectView';

export type ServerSelectProps = {
    servers: Server[];
    serversView: (errorIds: number[], loadingIds: number[]) => ReactElement;
    changeButton: (props: ButtonProps, onClick?: () => void) => ReactElement;
};

type Props = {
    children: (props: ServerSelectProps) => ReactElement;
};

const ServerSelect = ({ children }: Props) => {
    const { t } = useTranslation();
    const { authUser: user } = useAuth();
    const { licenseId } = usePortalUser();
    const { servers: userServers, onRefetchServers } = useContext(AuthContext);
    const { colors } = useTheme();
    const navigate = useNavigate();
    const servers = userServers.filter((x) => x.licenseId === Number(licenseId));
    const { serverId } = useParams<{ serverId: string }>();

    const [editServersMode, setEditServersMode] = useState(true);
    const [selectedServers, setSelectedServers] = useState<number[]>([]);
    const [search, setSearch] = useState<string>('');
    const [selectedTag, setSelectedTag] = useState<string>();

    const tags = useMemo(() => {
        const allTags = servers.flatMap((z) => z.tags).map((x) => x.value);
        return allTags.filter((c, index) => {
            return allTags.indexOf(c) === index;
        });
    }, [servers]);

    const selectedMappedServers = useMemo(
        () => servers.filter((x) => selectedServers.includes(x.id)),
        [servers, selectedServers],
    );

    useEffect(() => {
        setSelectedServers([]);
        setEditServersMode(true);
    }, [licenseId]);

    useEffect(() => {
        if (serverId && selectedServers.length === 0) {
            setSelectedServers([Number(serverId)]);
            setEditServersMode(false);
        }
    }, [serverId]);

    useEffect(() => {
        if (user?.isPortalAdmin) {
            navigate(`${routes.headAdmin}/licenses`);
        }
    }, []);

    const filterByTag = (servers: Server[], tag?: string) => {
        if (!tag) {
            return servers;
        }

        return servers.filter((x) => x.tags.some((x) => x.value === tag));
    };

    const filterByString = (servers: Server[], searchString: string) => {
        if (isEmpty(searchString)) {
            return servers;
        }

        const smallSearch = searchString.toLowerCase();
        return servers.filter(
            (serv) =>
                serv?.name?.toLowerCase()?.includes(smallSearch) ||
                serv?.address?.toLowerCase()?.includes(smallSearch) ||
                serv?.tags?.some((z) => z.value.toLowerCase()?.includes(smallSearch)),
        );
    };

    const searchServers = useMemo(() => {
        const filteredByString = filterByString(servers, search);
        const filteredByTag = filterByTag(filteredByString, selectedTag);

        return filteredByTag;
    }, [servers, search, selectedTag]);

    const onTagClicked = (tag: string) => {
        if (tag === selectedTag) {
            setSelectedTag(undefined);
            return;
        }

        setSelectedTag(tag);
    };

    const selectAll = () => {
        setSelectedServers((prev) => [
            ...prev,
            ...searchServers.filter((x) => x.connected && !prev.includes(x.id)).map((x) => x.id),
        ]);
    };

    if (editServersMode) {
        return (
            <Styled.MainWrapper>
                <Styled.HomePageHeader size={20} wrap>
                    <Styled.HomePageHeaderLeftSide size={20} wrap>
                        <ThemedSearch
                            value={search}
                            onChange={(v) => setSearch(v.currentTarget.value)}
                            prefix={<IoSearchOutline />}
                        />
                        <Space size={[0, 8]} wrap>
                            {tags.map((tag, index) => (
                                <ThemedCheckableTag
                                    onClick={() => onTagClicked(tag)}
                                    checked={tag === selectedTag}
                                    key={index}
                                >
                                    {tag}
                                </ThemedCheckableTag>
                            ))}
                        </Space>
                    </Styled.HomePageHeaderLeftSide>
                    <Styled.Row>
                        <ThemedButton onClick={selectAll}>{t('general.selectAll')}</ThemedButton>
                        <ThemedButton onClick={onRefetchServers} icon={<FiRefreshCw />}>
                            {t('reload')}
                        </ThemedButton>
                        <ThemedButton
                            $action
                            disabled={selectedServers.length === 0}
                            onClick={() => setEditServersMode(false)}
                        >
                            {t('general.next')}
                        </ThemedButton>
                    </Styled.Row>
                </Styled.HomePageHeader>
                {searchServers.length === 0 && (
                    <Styled.NoServersWrapper>
                        <MdErrorOutline color={colors.error} fontSize={80} />
                        <Styled.NoServersTitle>{t('errors.serversNotFound')}</Styled.NoServersTitle>
                    </Styled.NoServersWrapper>
                )}
                <Space size={[40, 20]} wrap>
                    {searchServers.map((server, index) => (
                        <ServerSelectView
                            server={server}
                            key={index}
                            selected={selectedServers.includes(server.id)}
                            onSelect={(id) =>
                                setSelectedServers((prev) => {
                                    if (prev.includes(id)) {
                                        return prev.filter((x) => x !== id);
                                    }

                                    return [...prev, id];
                                })
                            }
                        />
                    ))}
                </Space>
            </Styled.MainWrapper>
        );
    }

    return children({
        servers: selectedMappedServers,
        changeButton: (props, onClick) =>
            !serverId ? (
                <ThemedButton
                    {...props}
                    onClick={() => {
                        setEditServersMode(true);
                        onClick?.();
                    }}
                >
                    {t('general.changeServers')}
                </ThemedButton>
            ) : (
                <></>
            ),
        serversView: (errorIds, loadingIds) => (
            <Styled.ServerViewSpace wrap>
                {selectedMappedServers
                    .filter((x) => (serverId ? x.id === Number(serverId) : true))
                    .map((x) => (
                        <ServerSelectCard
                            key={x.id}
                            error={errorIds.includes(x.id)}
                            name={x.name}
                            loading={loadingIds.includes(x.id)}
                        />
                    ))}
            </Styled.ServerViewSpace>
        ),
    });
};

export default ServerSelect;
