import sha256 from 'crypto-js/sha256';
import { encryptRSA } from 'helpers/HttpRSAHelper';
import { AuthPayload } from 'models/server/AuthPayload';
import { AuthResponse } from 'models/server/AuthResponse';
import { UserCredential } from 'models/server/UserCredential';
import useCookie from './useCookie';
import useLocalApi from './useLocalApi';

const useLocalAuth = () => {
    const {
        value: authUser,
        updateCookie: updateAuthUser,
        deleteCookie: deleteAuthUser,
    } = useCookie<UserCredential>('local-user', { save: false, serverName: '' });
    const { localFetch } = useLocalApi();

    const refreshToken = async (): Promise<{
        result: AuthResponse | undefined;
        code: number;
    }> => {
        if (!authUser) {
            return { code: 401, result: undefined };
        }

        const result = await getTokens({
            payload: { refresh_token: authUser.refresh_token, scope: authUser.scope },
        });

        const d = new Date();
        d.setHours(d.getHours(), d.getMinutes() + 10, d.getSeconds(), d.getMilliseconds());

        updateAuthUser({
            ...authUser,
            ...result.result,
            accessTokenExpire: d,
        });

        return result;
    };

    const getNonce = async (): Promise<AuthResponse | undefined> => {
        const { result } = await localFetch<AuthResponse>({ api: 'token', method: 'POST' });
        return result;
    };

    const getTokens = async (
        payload: AuthPayload,
    ): Promise<{
        result: AuthResponse | undefined;
        code: number;
    }> => {
        return await localFetch<AuthResponse>({ api: 'token', method: 'POST', body: payload });
    };

    const authenticate = async (credentials: UserCredential): Promise<number> => {
        const nonce = (await getNonce())?.nonce;

        if (!nonce) {
            return 0;
        }

        const newPassword = sha256(credentials.password + nonce).toString();
        const encryptedPassword = await encryptRSA(newPassword);
        const { result: tokens, code } = await getTokens({
            payload: { nonce: nonce, name: credentials.username, password: encryptedPassword },
        });

        if (code !== 200) {
            return code;
        }

        if (!tokens) {
            return 0;
        }

        const d = new Date();
        d.setHours(d.getHours(), d.getMinutes() + 10, d.getSeconds(), d.getMilliseconds());
        updateAuthUser({
            username: credentials.username,
            accessTokenExpire: d,
            ...tokens,
            save: credentials.save,
            serverName: credentials.serverName,
        });

        return code;
    };

    return { authUser, authenticate, logout: deleteAuthUser, refreshToken };
};

export default useLocalAuth;
