import { Dispatch, SetStateAction } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { TbArrowsMoveHorizontal } from 'react-icons/tb';
import { useTheme } from 'styled-components';
import WpcSchemeElement from '../WpcSchemeElement/WpcSchemeElement';
import * as Styled from './DraggableSchemeElements.styles';
import { elementsConfig } from '../../CreateWpcProjectPage';
import { useTranslation } from 'react-i18next';

type Props = {
    selectedElements: number[];
    setSelectedElements: Dispatch<SetStateAction<number[]>>;
    onDuplicate: (index: number) => void;
    onRemove: (index: number) => void;
    onChangeType: (index: number) => void;
};

const DraggableSchemeElements = ({
    selectedElements,
    setSelectedElements,
    onDuplicate,
    onRemove,
    onChangeType,
}: Props) => {
    const { t } = useTranslation();
    const { colors } = useTheme();

    const reorder = (list: number[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(selectedElements, result.source.index, result.destination.index);
        setSelectedElements(items);
    };

    return (
        <Styled.MainWrapper>
            {!!selectedElements.length && (
                <Styled.DragIconWrapper>
                    <TbArrowsMoveHorizontal color={colors.text} fontSize={22} />
                </Styled.DragIconWrapper>
            )}
            <Styled.DndWrapper>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <Styled.ValuesGrid {...provided.droppableProps} ref={provided.innerRef}>
                                {selectedElements.map((e, index) => (
                                    <Draggable key={index} draggableId={index.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <WpcSchemeElement
                                                    draggable
                                                    onRemove={() => onRemove(index)}
                                                    onDuplicate={() => onDuplicate(index)}
                                                    onChangeType={() => onChangeType(index)}
                                                    element={elementsConfig(t).find((x) => x.type === e)}
                                                    key={index}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Styled.ValuesGrid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Styled.DndWrapper>
        </Styled.MainWrapper>
    );
};

export default DraggableSchemeElements;
