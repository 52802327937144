import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ViewPicker = styled(ThemedCheckableTag)`
    padding: 10px 20px;
`;
