import { Space } from 'antd';
import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const HeaderWrapper = styled(Space)`
    display: flex;
    gap: 10px;
`;

export const BodyWrapper = styled.div`
    display: flex;
    height: 100%;
    gap: 20px;

    @media (max-width: 750px) {
        flex-direction: column;
    }
`;

export const SwitchPointsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
`;

export const VerticalLine = styled.div`
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        ${(props) => props.theme.colors.text + '40'},
        rgba(255, 255, 255, 0)
    ) !important;
    border: 0px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;
    min-width: 2px;
    height: 100%;
`;

export const TimeThemedCheckableTag = styled(ThemedCheckableTag)`
    padding: 8px 10px;
    display: flex;
    gap: 17px;
    align-items: center;
    width: 225px;
`;

export const IconWrapper = styled.div`
    width: 15px;
    display: flex;
    align-items: center;
`;

export const AddThemedCheckableTag = styled(ThemedCheckableTag)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    max-width: 225px;
`;

export const FitContentSpace = styled(Space)`
    height: fit-content;
    align-items: flex-start;
`;
