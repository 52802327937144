import { routes } from 'App';
import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import usePortalUser from 'hooks/usePortalUser';
import { UserRoleType } from 'models/server/enums/UserRoleType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as Styled from './AdminDashboard.styles';

const AdminDashboard = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { portalUser, licenseId } = usePortalUser();

    const config = useMemo(
        () => [
            { path: 'users', title: t('general.users') },
            { path: 'servers', title: t('general.servers') },
        ],
        [],
    );

    if (!portalUser) {
        return <EmptyError title={t('errors.pageNotFound')} />;
    }

    if (portalUser.role !== UserRoleType.Admin) {
        return <EmptyError title={t('errors.modifiactionNotAllowed')} />;
    }

    const onPathChanged = (path: string) => {
        navigate(`${routes.admin}/${licenseId}/${path}`);
    };

    return (
        <Styled.MainWrapper key={portalUser.id}>
            <Space size={20} wrap>
                {config.map((c) => (
                    <Styled.ViewPicker
                        key={c.path}
                        checked={location.pathname.endsWith(c.path)}
                        onClick={() => onPathChanged(c.path)}
                    >
                        {c.title}
                    </Styled.ViewPicker>
                ))}
            </Space>
            <Line />
            <Outlet />
        </Styled.MainWrapper>
    );
};

export default AdminDashboard;
