import EmptyError from 'components/EmptyError/EmptyError';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import * as Styled from './HeadAdminDashboard.styles';

const HeadAdminDashboard = () => {
    const { t } = useTranslation();
    const { authUser: user } = useAuth();

    if (!user?.isPortalAdmin) {
        return <EmptyError title={t('errors.modifiactionNotAllowed')} />;
    }

    return (
        <Styled.MainWrapper>
            <Outlet />
        </Styled.MainWrapper>
    );
};

export default HeadAdminDashboard;
