import Cookies from 'js-cookie';
import { useCallback, useState } from 'react';

const b64EncodeUnicode = (str: string) => {
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        }),
    );
};

const b64DecodeUnicode = (str: string) => {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );
};

const cookieVersion = '1.0';

export const getCookieValue = <T>(cookieName: string, defaultValue: T) => {
    const name = `${cookieVersion}-${cookieName}`;
    const cookie = Cookies.get(name);

    if (cookie !== undefined) {
        try {
            return JSON.parse(b64DecodeUnicode(cookie)) as T;
        } catch {
            Cookies.set(name, b64EncodeUnicode(JSON.stringify(defaultValue)));
            return defaultValue;
        }
    }

    Cookies.set(name, b64EncodeUnicode(JSON.stringify(defaultValue)));
    return defaultValue;
};

const useCookie = <T>(cookieName: string, defaultValue: T) => {
    const name = `${cookieVersion}-${cookieName}`;

    const [value, setValue] = useState<T | null>(getCookieValue(cookieName, defaultValue));

    const updateCookie = useCallback(
        (newValue: T, options?: Cookies.CookieAttributes) => {
            Cookies.set(name, b64EncodeUnicode(JSON.stringify(newValue)), options);
            setValue(newValue);
        },
        [name],
    );

    const deleteCookie = useCallback(() => {
        Cookies.remove(name);
        setValue(null);
    }, [name]);

    return { value, updateCookie, deleteCookie };
};

export default useCookie;
