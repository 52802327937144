import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import useCookie from 'hooks/useCookie';
import { useServerApi } from 'hooks/useServerApi';
import Server from 'models/Server';
import { EnergyStatistic } from 'models/server/EnergyStatistic';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import EnergyFilters from 'pages/EnergyMonitorChartsPage/EnergyFilters/EnergyFilters';
import {
    EnergyConfig,
    energyDateFormat,
    initEnergyConfig,
} from 'pages/EnergyMonitorChartsPage/EnergyMonitorChartsPage';
import { getFormattedData } from 'pages/EnergyMonitorChartsPage/MainChart/MainChart';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { utils, write } from 'xlsx';
import { TotalEnergyStats } from '../EneregyGraphsPage/EneregyGraphsPage';
import * as Styled from './EnergyReportPage.styles';
import { EnergyReportVirtualDevices } from './EnergyReportVirtualDevices/EnergyReportVirtualDevices';

export type EnergyReportVDConfig = {
    id: number;
    loading: boolean;
    error: boolean;
    energy: TotalEnergyStats[];
    power: TotalEnergyStats[];
    hide?: boolean;
};

export type EnergyReportConfig = {
    server: Server;
    selectedVirtualDevices?: EnergyReportVDConfig[];
};

const EnergyReportPage = () => {
    const { t } = useTranslation();
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const { configs } = useContext(GroupedServersConfigContext);

    const [config, setConfig] = useState<EnergyReportConfig[]>(
        configs.map((x) => ({ server: x.server, selectedVirtualDevices: [] })),
    );

    const { value: configValue, updateCookie } = useCookie<EnergyConfig>('energy-config-old', initEnergyConfig);
    const energyConfig = configValue ?? initEnergyConfig;

    const { startDate, endDate, selectedFrameOption } = energyConfig;
    const isLoading = config.some((x) => x.selectedVirtualDevices?.some?.((z) => z.loading));
    const isEmpty = !configs.some(
        (x) => x.setupInfo && x.setupInfo.objects.items.some((x) => x.type === VirtualDeviceType.TotalEnergyMonitor),
    );

    const onSetVirtualDeviceConfig = (
        serverId: number,
        currentVirtualDeviceId: number,
        config: { id: number; loading: boolean; error: boolean; power: TotalEnergyStats[]; energy: TotalEnergyStats[] },
    ) => {
        setConfig((prev) =>
            prev.map((x) =>
                x.server.id === serverId
                    ? {
                          ...x,
                          selectedVirtualDevices: x.selectedVirtualDevices?.map((z) =>
                              z.id === currentVirtualDeviceId ? config : z,
                          ),
                      }
                    : x,
            ),
        );
    };

    const getStats = async (serverId: number, currentVirtualDeviceId: number) => {
        onSetVirtualDeviceConfig(serverId, currentVirtualDeviceId, {
            id: currentVirtualDeviceId,
            loading: true,
            error: false,
            power: [],
            energy: [],
        });

        const currentConfig = configs.find((x) => x.server.id === serverId);

        const result = await apiServerFetchWithCustomPortalId<{ statistic: EnergyStatistic }>(
            serverId,
            'statistic',
            undefined,
            'POST',
            {
                payload: {
                    startDate: dayjs(startDate, energyDateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                    endDate: dayjs(endDate, energyDateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                    monitorObjectId: currentVirtualDeviceId,
                    type: selectedFrameOption,
                    maxCount: 60,
                },
            },
        );

        if (!result.result?.statistic || result.code !== 200 || !currentConfig?.setupInfo) {
            onSetVirtualDeviceConfig(serverId, currentVirtualDeviceId, {
                id: currentVirtualDeviceId,
                loading: false,
                error: true,
                power: [],
                energy: [],
            });
            return;
        }

        onSetVirtualDeviceConfig(serverId, currentVirtualDeviceId, {
            id: currentVirtualDeviceId,
            loading: false,
            error: false,
            power: [],
            energy: [],
        });

        const { ios, devices, rooms, info } = currentConfig.setupInfo;

        return {
            serverId,
            currentVirtualDeviceId,
            energy: getFormattedData(
                result.result.statistic.energyStats ?? [],
                ios,
                devices,
                rooms,
                selectedFrameOption,
                endDate,
                info,
            ),
            power: getFormattedData(
                result.result.statistic.powerStats ?? [],
                ios,
                devices,
                rooms,
                selectedFrameOption,
                endDate,
                info,
            ),
        };
    };

    const onDownload = async () => {
        const result = await Promise.all(
            config
                .filter((x) => x.selectedVirtualDevices?.length)
                .flatMap(
                    (x) =>
                        x.selectedVirtualDevices
                            ?.map((z) => ({ serverId: x.server.id, virtualDeviceId: z.id }))
                            .map((x) => getStats(x.serverId, x.virtualDeviceId)),
                ),
        );

        const workbook = utils.book_new();

        for (const device of result) {
            if (!device) {
                continue;
            }
            const currentConfig = configs.find((x) => x.server.id === device.serverId);
            const currentVd = currentConfig?.setupInfo?.objects?.items?.find(
                (x) => x.id === device.currentVirtualDeviceId,
            );

            let eName = `E-${currentConfig?.server.name.substring(0, 13)}-${currentVd?.name.substring(0, 14)}`.replace(
                '/',
                '',
            );

            let eIndex = 1;

            while (workbook.SheetNames.some((x) => x === eName)) {
                eName = `E${eIndex}-${currentConfig?.server.name.substring(0, 13)}-${currentVd?.name.substring(
                    0,
                    14,
                )}`.replace('/', '');
                eIndex += 1;
            }

            const energySheet = utils.json_to_sheet(device.energy.download);
            utils.book_append_sheet(workbook, energySheet, eName);

            let pName = `P-${currentConfig?.server.name.substring(0, 13)}-${currentVd?.name.substring(0, 14)}`.replace(
                '/',
                '',
            );
            let pIndex = 1;

            while (workbook.SheetNames.some((x) => x === pName)) {
                pName = `P${pIndex}-${currentConfig?.server.name.substring(0, 13)}-${currentVd?.name.substring(
                    0,
                    14,
                )}`.replace('/', '');
                pIndex += 1;
            }

            const powerSheet = utils.json_to_sheet(device.power.download);
            utils.book_append_sheet(workbook, powerSheet, pName);
        }

        const excel = write(workbook, { type: 'buffer', bookType: 'xlsx' });
        const finalData = new Blob([excel]);
        FileSaver.saveAs(finalData, t('general.groupedEnergyReport') + '.xlsx');
    };

    const updateConfig = (config: EnergyConfig) => {
        updateCookie(config, { expires: 365 });
    };

    const handleSelectAll = () => {
        setConfig((prev) =>
            prev.map((x) => {
                const serverConfig = configs.find((z) => x.server.id === z.server.id);

                return {
                    ...x,
                    selectedVirtualDevices:
                        serverConfig?.setupInfo?.objects?.items
                            ?.filter((x) => x.type === VirtualDeviceType.TotalEnergyMonitor)
                            ?.map((z) => ({
                                id: z.id,
                                loading: false,
                                error: false,
                                energy: [],
                                power: [],
                                hide: false,
                            })) ?? [],
                };
            }),
        );
    };

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.Row wrap>
                    {!isEmpty && (
                        <EnergyFilters isLoading={isLoading} config={energyConfig} updateConfig={updateConfig} />
                    )}
                    <Styled.ButtonsWrapper>
                        <ThemedButton disabled={isLoading || isEmpty} onClick={handleSelectAll}>
                            {t('general.selectAll')}
                        </ThemedButton>
                        <ThemedButton
                            disabled={isLoading || isEmpty || !config.some((x) => x.selectedVirtualDevices?.length)}
                            $action
                            onClick={onDownload}
                        >
                            {t('general.download')}
                        </ThemedButton>
                    </Styled.ButtonsWrapper>
                </Styled.Row>
            </Styled.HeaderWrapper>
            <Styled.ContentWrapper>
                {isLoading && <ThemedSpin />}
                {!isLoading && isEmpty && <EmptyError title={t('errors.noEnergyMonitorsToSelect')} />}

                <Space direction="vertical" size={20}>
                    {configs.map((ser) => (
                        <EnergyReportVirtualDevices
                            key={ser.server.id}
                            server={ser.server}
                            serverConfig={configs.find((x) => x.server.id === ser.server.id)}
                            config={config.find((x) => x.server.id === ser.server.id)}
                            setConfig={setConfig}
                        />
                    ))}
                </Space>
            </Styled.ContentWrapper>
        </Styled.MainWrapper>
    );
};

export default EnergyReportPage;
