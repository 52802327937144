import { routes } from 'App';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const useGoBack = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { serverId, licenseId } = useParams<{ serverId: string; licenseId: string }>();

    const onGoBack = () => {
        if (location.pathname.startsWith(routes.groupActions)) {
            return navigate({
                pathname: `${routes.groupActions}/${licenseId}/controls`,
                search: location?.state?.search,
            });
        }

        if (!serverId) {
            return navigate({
                pathname: `/`,
            });
        }

        if (!location.state) {
            return navigate({
                pathname: `${routes.server}/${serverId}`,
            });
        }

        navigate({
            pathname: location.state.path,
            search: location.state.search,
        });
    };

    return { onGoBack, state: location.state };
};

export default useGoBack;
