import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const DpName = styled.div`
    flex: 1;
`;
