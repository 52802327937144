import { useImages } from 'hooks/useImages';
import { useLocation, useNavigate } from 'react-router-dom';

import { HomeOutlined, LogoutOutlined } from '@ant-design/icons';
import { routes } from 'App';
import { MenuProps } from 'antd';
import useLocalAuth from 'hooks/useLocalAuth';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiExpandLeftLine, RiExpandRightLine } from 'react-icons/ri';
import * as Styled from './WpcSideMenu.styles';

type MenuItem = Required<MenuProps>['items'][number];

const WpcSideMenu = () => {
    const [sideMenuVisible, setSideMenuVisible] = useState(true);
    const { logout } = useLocalAuth();
    const { t } = useTranslation();
    const { logo } = useImages();
    const navigate = useNavigate();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    const selectedKey = useMemo(() => {
        if (location.pathname === routes.home) {
            return routes.home;
        }

        if (location.pathname === `${routes.server}/login`) {
            return '0';
        }

        return location.pathname;
    }, [location]);

    const onGoToHomePage = () => {
        navigate(routes.home);
    };

    const onLogout = () => {
        logout();
        navigate(routes.login);
    };

    const items: MenuItem[] = useMemo(() => {
        const logoutMenu = { label: t('general.logout'), key: 'logout', icon: <LogoutOutlined />, onClick: onLogout };

        const i: MenuItem[] = [
            { label: t('general.home'), key: routes.home, icon: <HomeOutlined />, onClick: onGoToHomePage },
        ];

        i.push(logoutMenu);

        return i;
    }, [routes]);

    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (latestOpenKey) {
            setOpenKeys([...keys, latestOpenKey]);
            return;
        }

        setOpenKeys(keys);
    };

    return (
        <>
            {sideMenuVisible ? (
                <Styled.SideMenu>
                    <Styled.CloseWrapper>
                        <RiExpandLeftLine size={20} onClick={() => setSideMenuVisible(false)} />
                    </Styled.CloseWrapper>
                    <Styled.Logo src={logo} onClick={onGoToHomePage} />
                    <Styled.Line />
                    <Styled.ItemsMenu
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        openKeys={openKeys}
                        onOpenChange={onOpenChange}
                        items={items}
                    />
                </Styled.SideMenu>
            ) : (
                <Styled.ClosedWrapper checked={false} onClick={() => setSideMenuVisible(true)}>
                    <RiExpandRightLine />
                </Styled.ClosedWrapper>
            )}
        </>
    );
};

export default WpcSideMenu;
