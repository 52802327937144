import styled, { css } from 'styled-components';

export const MainWrapper = styled.div<{ $mode?: 'error' | 'info' }>`
    padding: 15px 25px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
    margin-bottom: 10px;
    background-color: ${(props) => props.theme.colors.virtualDevicesBackground};

    @media (max-width: 750px) {
        padding: 5px 10px;
    }

    ${(props) =>
        props.$mode === 'error' &&
        css`
            border-left: 5px solid #e74c3c;
        `}

    ${(props) =>
        props.$mode === 'info' &&
        css`
            border-left: 5px solid #3498db;
        `}
`;

export const IconWrapper = styled.div`
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.div`
    font-weight: bold;
`;

export const NotificationButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
    gap: 10px;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;

    @media (max-width: 750px) {
        top: 8px;
        right: 8px;
    }
`;
