import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { VirtualDeviceType } from '../enums/VirtualDeviceType';

export const useVirtualDeviceSettings = (t: TFunction) => {
    const settings = useMemo(
        () => [
            {
                name: VirtualDeviceType.AlarmClock,
                translation: t('virtualDeviceNames.AlarmClock'),
            },
            {
                name: VirtualDeviceType.Alphatronics,
                translation: t('virtualDeviceNames.Alphatronics'),
            },
            {
                name: VirtualDeviceType.AstroFunction,
                translation: t('virtualDeviceNames.AstroFunction'),
            },
            {
                name: VirtualDeviceType.Blinds,
                translation: t('virtualDeviceNames.Blinds'),
            },
            {
                name: VirtualDeviceType.BurglarAlarm,
                translation: t('virtualDeviceNames.BurglarAlarm'),
            },
            {
                name: VirtualDeviceType.Calendar,
                translation: t('virtualDeviceNames.Calendar'),
            },
            {
                name: VirtualDeviceType.Camera,
                translation: t('virtualDeviceNames.Camera'),
            },
            {
                name: VirtualDeviceType.CentralBattery,
                translation: t('virtualDeviceNames.CentralBattery'),
            },
            {
                name: VirtualDeviceType.CentralBlindsController,
                translation: t('virtualDeviceNames.CentralBlindsController'),
            },
            {
                name: VirtualDeviceType.CentralGarageGate,
                translation: t('virtualDeviceNames.CentralGarageGate'),
            },
            {
                name: VirtualDeviceType.CentralHomeButtons,
                translation: t('virtualDeviceNames.CentralHomeButtons'),
            },
            {
                name: VirtualDeviceType.CentralLightController,
                translation: t('virtualDeviceNames.CentralLightController'),
            },
            {
                name: VirtualDeviceType.CentralRoomControl,
                translation: t('virtualDeviceNames.CentralRoomControl'),
            },
            {
                name: VirtualDeviceType.CentralScheduler,
                translation: t('virtualDeviceNames.CentralScheduler'),
            },
            {
                name: VirtualDeviceType.CentralWindowsAndDoors,
                translation: t('virtualDeviceNames.CentralWindowsAndDoors'),
            },
            {
                name: VirtualDeviceType.ConstantLightController,
                translation: t('virtualDeviceNames.ConstantLightController'),
            },
            {
                name: VirtualDeviceType.Dimmer,
                translation: t('virtualDeviceNames.Dimmer'),
            },
            {
                name: VirtualDeviceType.DoorController,
                translation: t('virtualDeviceNames.DoorController'),
            },
            {
                name: VirtualDeviceType.EnergyManagement,
                translation: t('virtualDeviceNames.EnergyManagement'),
            },
            {
                name: VirtualDeviceType.EnergyMonitor,
                translation: t('virtualDeviceNames.EnergyMonitor'),
            },
            {
                name: VirtualDeviceType.FireAndWaterAlarm,
                translation: t('virtualDeviceNames.FireAndWaterAlarm'),
            },
            {
                name: VirtualDeviceType.FloorPlan,
                translation: t('virtualDeviceNames.FloorPlan'),
            },
            {
                name: VirtualDeviceType.GarageGate,
                translation: t('virtualDeviceNames.GarageGate'),
            },
            {
                name: VirtualDeviceType.LightController,
                translation: t('virtualDeviceNames.LightController'),
            },
            {
                name: VirtualDeviceType.Monitor,
                translation: t('virtualDeviceNames.Monitor'),
            },
            {
                name: VirtualDeviceType.OperationalModeSelector,
                translation: t('virtualDeviceNames.OperationalModeSelector'),
            },
            {
                name: VirtualDeviceType.PresenceSimulation,
                translation: t('virtualDeviceNames.PresenceSimulation'),
            },
            {
                name: VirtualDeviceType.PushButton,
                translation: t('virtualDeviceNames.PushButton'),
            },
            {
                name: VirtualDeviceType.PushSwitch,
                translation: t('virtualDeviceNames.PushSwitch'),
            },
            {
                name: VirtualDeviceType.RoomController,
                translation: t('virtualDeviceNames.RoomController'),
            },
            {
                name: VirtualDeviceType.Schedule,
                translation: t('virtualDeviceNames.Schedule'),
            },
            {
                name: VirtualDeviceType.SelectionSwitch,
                translation: t('virtualDeviceNames.SelectionSwitch'),
            },
            {
                name: VirtualDeviceType.StaircaseTimer,
                translation: t('virtualDeviceNames.StaircaseTimer'),
            },
            {
                name: VirtualDeviceType.Switch,
                translation: t('virtualDeviceNames.Switch'),
            },
            {
                name: VirtualDeviceType.TemperatureController,
                translation: t('virtualDeviceNames.TemperatureController'),
            },
            {
                name: VirtualDeviceType.ToiletVentilationController,
                translation: t('virtualDeviceNames.ToiletVentilationController'),
            },
            {
                name: VirtualDeviceType.TotalEnergyMonitor,
                translation: t('virtualDeviceNames.TotalEnergyMonitor'),
            },
            {
                name: VirtualDeviceType.Undefined,
                translation: t('general.undefined'),
            },
        ],
        [],
    );

    return { settings };
};
