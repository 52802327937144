import { GroupedServerConfig } from 'components/ServerSelectRoute/ServerSelectBulkLoadingProvider/ServerSelectBulkLoadingProvider';
import { SetupInfo } from 'models/server/SetupInfo';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { createContext } from 'react';

export type ServerConfig = {
    virtualDevices: VirtualDevice[];
    setupInfo?: SetupInfo;
    loading: boolean;
    error?: number;
    configs: GroupedServerConfig[];
    onRefreshConfig?: () => void;
    reconnect?: () => void;
    onRefetchOne?: (serverId: number) => void;
};

export const ServerConfigContext = createContext<ServerConfig>({
    virtualDevices: [],
    loading: true,
    setupInfo: undefined,
    configs: [],
});
