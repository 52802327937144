import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { useImages } from 'hooks/useImages';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import * as Styled from './Gate.styles';

const Gate = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints, category } = virtualDevice;

    const { getCategorySettings } = useImages();
    const { color } = useMemo(() => getCategorySettings(category), [category]);

    const triggerOpenDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerOpen),
        [datapoints],
    );
    const triggerCloseDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerClose),
        [datapoints],
    );
    const stopDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStop),
        [datapoints],
    );
    const positionDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualPosition),
        [datapoints],
    );
    const postionValue = useMemo(() => {
        const v = Number(positionDatapoint?.value ?? 0);
        return isNaN(v) ? 0 : v;
    }, [positionDatapoint?.value]);

    return (
        <Styled.MainWrapper $hasPostionDatapoint={!!positionDatapoint}>
            <Styled.CentralContentWrapper>
                <Styled.ButtonsWrapper>
                    {triggerOpenDatapoint && (
                        <DatapointButtonView
                            hideName
                            datapoint={triggerOpenDatapoint}
                            $width={50}
                            shape="round"
                            icon={<FaChevronUp size={20} />}
                        />
                    )}
                    {triggerCloseDatapoint && (
                        <DatapointButtonView
                            hideName
                            datapoint={triggerCloseDatapoint}
                            $width={50}
                            shape="round"
                            icon={<FaChevronDown size={20} />}
                        />
                    )}
                </Styled.ButtonsWrapper>
                {stopDatapoint && <DatapointButtonView datapoint={stopDatapoint} />}
            </Styled.CentralContentWrapper>
            {positionDatapoint && (
                <div>
                    <Styled.ValueSlider
                        $color={color}
                        reverse
                        value={100 - postionValue}
                        tooltip={{ open: true, formatter: (v) => 100 - (v ?? 0), placement: 'left' }}
                        min={0}
                        max={100}
                        vertical
                    />
                </div>
            )}
        </Styled.MainWrapper>
    );
};

export default Gate;
