import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { MdOutlineEdit } from 'react-icons/md';
import * as Styled from './ColorSelectionButton.styles';

type Props = {
    disabled?: boolean;
    color: string;
    onChange: (v: string) => void;
    loading?: boolean;
};

const ColorSelectionButton = ({ color, disabled, loading, onChange }: Props) => {
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [currentColor, setCurrentColor] = useState<string>(color);

    useEffect(() => {
        setCurrentColor(color);
    }, [color]);

    return (
        <Styled.MainWrapper onMouseLeave={() => setColorPickerVisible(false)}>
            <ThemedButton
                disabled={disabled}
                onClick={() => setColorPickerVisible(true)}
                $width={32}
                $version="virtualDevice"
                shape="round"
                icon={<MdOutlineEdit size={18} />}
                loading={loading}
            />
            {colorPickerVisible && (
                <Styled.PickerWrapper>
                    <SketchPicker
                        onChangeComplete={(color) => onChange(color.hex)}
                        onChange={(color) => setCurrentColor(color.hex)}
                        color={currentColor}
                    />
                </Styled.PickerWrapper>
            )}
        </Styled.MainWrapper>
    );
};

export default ColorSelectionButton;
