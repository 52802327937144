import { Space } from 'antd';
import { ThemedCard, ThemedSwitch } from 'components/ThemedComponents/ThemedComponents.styles';
import DashboardConfig from 'models/DashboardConfig';
import { MonitorDatapoint } from 'models/server/MonitorDatapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { Dispatch, SetStateAction, useMemo } from 'react';
import * as Styled from './MonitorSelectionView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    currentConfig: DashboardConfig;
    setCurrentConfig: Dispatch<SetStateAction<DashboardConfig>>;
};

const MonitorSelectionView = ({ virtualDevice, currentConfig, setCurrentConfig }: Props) => {
    const { datapoints } = virtualDevice;

    const monitorConfigDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.MonitorConfig),
        [datapoints],
    );

    const monitorValues = useMemo(() => {
        const viewElements = monitorConfigDatapoint?.Monitor?.filter((x) => x.View);

        if (!viewElements) {
            return [];
        }

        return viewElements.reduce((resultArray: MonitorDatapoint[][], item, index) => {
            const chunkIndex = Math.floor(index / 1);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [];
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);
    }, [monitorConfigDatapoint?.Monitor]);

    return (
        <ThemedCard checked={false}>
            <Styled.Title>{virtualDevice.name}</Styled.Title>
            <Space wrap align="start" size={[10, 0]}>
                {monitorValues.map((arr, index) => (
                    <Styled.MainWrapper key={index} direction="vertical" wrap>
                        {arr.map((dp) => (
                            <Styled.Wrapper key={dp.IOid}>
                                <Styled.DpName>{dp?.Name}</Styled.DpName>
                                <ThemedSwitch
                                    checked={currentConfig.monitorIos.some((x) => x.ioId === dp.IOid)}
                                    onChange={(v) => {
                                        if (v) {
                                            setCurrentConfig((prev) => ({
                                                ...prev,
                                                monitorIos: [
                                                    ...prev.monitorIos,
                                                    {
                                                        name: dp?.Name,
                                                        unit: dp.Unit,
                                                        ioId: dp.IOid,
                                                        configuration: dp.Configuration,
                                                    },
                                                ],
                                            }));
                                        } else {
                                            setCurrentConfig((prev) => ({
                                                ...prev,
                                                monitorIos: prev.monitorIos.filter((x) => x.ioId !== dp.IOid),
                                            }));
                                        }
                                    }}
                                />
                            </Styled.Wrapper>
                        ))}
                    </Styled.MainWrapper>
                ))}
            </Space>
        </ThemedCard>
    );
};

export default MonitorSelectionView;
