import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { getBooleanValue } from 'helpers/DatapointHelper';
import { useImages } from 'hooks/useImages';
import { AlphatronicConfig, AlphatronicGroup } from 'models/server/AlphatronicConfig';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useEffect, useMemo, useState } from 'react';
import DatapointButtonView from '../../DatapointButtonView/DatapointButtonView';
import DatapointValueView from '../../DatapointValueView/DatapointValueView';
import * as Styled from './AlphatronicControl.styles';

type Props = {
    config: AlphatronicConfig;
    group: AlphatronicGroup;
    virtualDevice: VirtualDevice;
};

const AlphatronicGroupView = (props: Props): JSX.Element => {
    const { virtualDevice, group, config } = props;
    const { getCategorySettings } = useImages();
    const [sectionVisible, setSectionVisible] = useState(group.Groups.length > 0);

    const labels = useMemo(
        () =>
            virtualDevice.datapoints
                .filter(
                    (x) => (x.type == DatapointType.Number && x.writeprotect) || x.type == DatapointType.StatusLight,
                )
                .filter((x) => group.Datapoints.includes(x.id))
                .filter((x) => !(config.HideWhenFalse.includes(x.id) && !getBooleanValue(x.value?.toString()))),
        [virtualDevice, group],
    );

    const alarmDp = useMemo(() => labels.find((x) => x.name === 'IsAlarm'), [labels]);

    const buttons = useMemo(
        () =>
            virtualDevice.datapoints
                .filter((x) => x.type == DatapointType.Button)
                .filter((x) => group.Datapoints.includes(x.id)),
        [virtualDevice, group],
    );

    useEffect(() => {
        if (alarmDp?.value) {
            setSectionVisible(true);
        }
    }, [alarmDp]);

    if (labels.length === 0 && buttons.length === 0) {
        return <></>;
    }

    return (
        <Styled.MainWrapper>
            <Styled.GroupNameWrapper
                onClick={(e) => {
                    if (group.Groups.length > 0) {
                        return;
                    }
                    setSectionVisible((prev) => !prev);
                    e.preventDefault();
                }}
            >
                <div>{group.Name}</div>
                {group.Groups.length === 0 && (
                    <Styled.ArrwoWrapper>{!sectionVisible ? <DownOutlined /> : <UpOutlined />}</Styled.ArrwoWrapper>
                )}
            </Styled.GroupNameWrapper>
            {sectionVisible && (
                <>
                    {labels &&
                        labels.map((dp) => (
                            <DatapointValueView
                                showYesNoAsBoolValue={dp.name === 'VirtualAlarmIsArmed'}
                                showOnOffAsBoolValue={alarmDp === dp}
                                key={dp.id}
                                valueOrDp={dp}
                            />
                        ))}
                    <Styled.ButtonsWrapper>
                        {buttons &&
                            buttons.map((dp) => (
                                <DatapointButtonView
                                    codeConfig={{
                                        codeRequired: config.CodeRequired.includes(dp.id),
                                        color: getCategorySettings(virtualDevice.category).color,
                                    }}
                                    fill
                                    key={dp.id}
                                    datapoint={dp}
                                />
                            ))}
                    </Styled.ButtonsWrapper>
                </>
            )}
            {group.Groups.length > 0 &&
                group.Groups.map((z, index) => <AlphatronicGroupView key={z.Name + index} {...props} group={z} />)}
        </Styled.MainWrapper>
    );
};

export default AlphatronicGroupView;
