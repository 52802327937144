import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { MonitorConfigurationDatapoint } from 'models/server/MonitorDatapoint';
import { ioDashboardValueState } from 'pages/HomePage/ServerView/ServerView';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import * as Styled from './IoValueCard.styles';

type Props = {
    ioId: number;
    title: string;
    configuration: MonitorConfigurationDatapoint;
    unit?: string;
    draggable?: boolean;
    serialNumber: string;
};

const IoValueCard = ({ ioId, serialNumber, title, configuration, unit = '', draggable }: Props) => {
    const { t } = useTranslation();

    const [dashboardIoValues] = useRecoilState(ioDashboardValueState);

    const value = useMemo(
        () => dashboardIoValues.find((x) => x.serialNumber === serialNumber && x.ioId == ioId)?.value?.toString(),
        [dashboardIoValues, serialNumber, ioId],
    );

    const getMoniorValue = (
        ioValue: string | number | boolean | undefined,
        configuration: MonitorConfigurationDatapoint,
        unit: string,
    ): string => {
        if (ioValue === undefined) {
            return t('monitor.waitingForData');
        }

        if (ioValue === '') {
            return t('monitor.invalid');
        }

        if (!Number(ioValue)) {
            return `${ioValue}${unit}`;
        }

        const value = Number(ioValue);
        const offset = Number(configuration.Offset);
        const lowerLimit = Number(configuration.LowerLimit);
        const lowerLimitCorrection = Number(configuration.LowerLimitCorrection);
        const upperLimit = Number(configuration.UpperLimit);
        const upperLimitCorrection = Number(configuration.UpperLimitCorrection);

        if (!value || !offset || !lowerLimit || !lowerLimitCorrection || !upperLimit || !upperLimitCorrection) {
            return `${ioValue}${unit}`;
        }

        const diff = upperLimit - lowerLimit;
        const m = diff == 0 ? 0 : (upperLimitCorrection - lowerLimitCorrection) / diff;
        const b = upperLimitCorrection - m * upperLimit;

        return ((value + offset) * m - b).toString();
    };

    const currentValue = useMemo(() => getMoniorValue(value, configuration, unit), [value, configuration, unit]);

    return (
        <Styled.Wrapper checked draggable={draggable}>
            {value !== undefined ? <Styled.Value>{currentValue}</Styled.Value> : <ThemedSpin />}
            <Styled.Title>{title}</Styled.Title>
        </Styled.Wrapper>
    );
};

export default IoValueCard;
