import { Tooltip } from 'antd';
import { ThemedCard, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { useTranslation } from 'react-i18next';
import { MdErrorOutline } from 'react-icons/md';
import { useTheme } from 'styled-components';
import * as Styled from './ServerSelectCard.styles';

type Props = {
    error: boolean;
    name: string;
    loading: boolean;
};

const ServerSelectCard = ({ error, name, loading }: Props) => {
    const { t } = useTranslation();
    const { colors } = useTheme();

    return (
        <Tooltip color={colors.error} title={error ? t('errors.couldNotGetDataFromServer') : undefined}>
            <ThemedCard checked={false}>
                <Styled.Row>
                    {name}
                    {loading && <ThemedSpin />}
                    {error && <MdErrorOutline size={20} color={colors.error} />}
                </Styled.Row>
            </ThemedCard>
        </Tooltip>
    );
};

export default ServerSelectCard;
