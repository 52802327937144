import { Space, Tooltip } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import {
    ThemedButton,
    ThemedCheckableTag,
    ThemedInput,
    ThemedSpin,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { isEmpty } from 'helpers/StringHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { AstroFunctionBlock } from 'models/server/AstroFunctionBlock';
import { CominghomeBlock } from 'models/server/CominghomeBlock';
import { Datapoint } from 'models/server/Datapoint';
import { Light } from 'models/server/Light';
import { Scene } from 'models/server/Scene';
import { SwitchPoint } from 'models/server/SwitchPoint';
import { DatapointType } from 'models/server/enums/DatapointType';
import { LightType } from 'models/server/enums/LightType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './SceneEditPage.styles';
import SceneView from './SceneView/SceneView';

const ignore = 'Ignore';

const SceneEditPage = () => {
    const { t } = useTranslation();
    const { currentServer, virtualDevices, serverConfigLoading } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { virtualDeviceId } = useParams<{ serverId: string; virtualDeviceId: string }>();
    const { colors } = useTheme();
    const { onGoBack } = useGoBack();

    const currentVirtualDevice = useMemo(
        () => virtualDevices?.find((x) => x.id === Number(virtualDeviceId)),
        [virtualDevices, virtualDeviceId],
    );
    const sceneConfigDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.ScenesLight),
        [currentVirtualDevice?.datapoints],
    );
    const [scenes, setScenes] = useState<Scene[]>(sceneConfigDatapoint?.Scenes ?? []);
    const [selectedSceneIndex, setSelectedSceneIndex] = useState<number>(0);

    const currentScene = useMemo(() => scenes[selectedSceneIndex], [scenes, selectedSceneIndex]);

    useEffect(() => {
        setScenes(sceneConfigDatapoint?.Scenes ?? []);
    }, [sceneConfigDatapoint?.Scenes]);

    const onChangeName = (v: string) => {
        setScenes((prev) => prev.map((scene, index) => (index === selectedSceneIndex ? { ...scene, Name: v } : scene)));
    };

    const saveDisabled = useMemo(() => {
        const names = scenes.map((x) => x.Name);
        return names.some((name) => isEmpty(name)) || names.some((item, index) => names.indexOf(item) !== index);
    }, [scenes]);

    const hasError = (scene: Scene) => {
        return isEmpty(scene.Name) || scenes.filter((x) => x.Name === scene.Name).length > 1;
    };

    const currentError = useMemo(() => {
        if (!currentScene) {
            return;
        }

        if (isEmpty(currentScene.Name)) {
            return t('error.nameCannotBeEmpty');
        }

        if (scenes.filter((x) => x.Name === currentScene.Name).length > 1) {
            return t('error.nameMustBeUnique');
        }
    }, [currentScene, scenes]);

    const onLightValueChanged = (v: string, lightIndex: number) => {
        setScenes((prev) =>
            prev.map((scene, index) =>
                index === selectedSceneIndex
                    ? {
                          ...scene,
                          values: scene.values.map((val, valIndex) =>
                              valIndex === lightIndex ? { ...val, Value: v } : val,
                          ),
                      }
                    : scene,
            ),
        );
    };

    const onRemoveScene = (index: number) => {
        setScenes((prev) => prev.filter((scene) => scene !== prev[index]));
        setSelectedSceneIndex((prev) => (prev === 0 ? prev : prev - 1));
    };

    const getNewSceneName = () => {
        const newSceneTranslation = t('sceneEdit.newScene');
        if (!scenes.find((x) => x.Name == newSceneTranslation)) {
            return newSceneTranslation;
        }

        let i = 1;
        while (scenes.find((x) => x.Name == `${newSceneTranslation} ${i}`)) {
            i++;
        }

        return `${newSceneTranslation} ${i}`;
    };

    const addScene = async () => {
        const newScene: Scene = {
            Name: getNewSceneName(),
            PresenceDetector: false,
            values: scenes[0].values.map((value) => ({ ...value, Value: getDefaultLightValue(value) })),
        };

        const newScenes = [...scenes, newScene];

        setScenes(newScenes);
        setSelectedSceneIndex(newScenes.length - 1);
    };

    const getDefaultLightValue = (light: Light) => {
        switch (light.LightType) {
            case LightType.Color:
            case LightType.Color_Strip:
                return '#640000000000FFFFFF';
            case LightType.Switch:
                return 'true';
            default:
                return '100';
        }
    };

    const onSave = async () => {
        if (!sceneConfigDatapoint || !currentVirtualDevice) {
            return;
        }

        const newSceneDatapoint = { ...sceneConfigDatapoint, Scenes: scenes };

        const result = await onDatapointChange(newSceneDatapoint);

        if (!result) {
            return;
        }

        for (const vd of virtualDevices?.filter(
            (x) =>
                x.type === VirtualDeviceType.AstroFunction ||
                x.type === VirtualDeviceType.CentralHomeButtons ||
                x.type === VirtualDeviceType.CentralScheduler,
        ) ?? []) {
            if (vd.type === VirtualDeviceType.AstroFunction) {
                const dp = vd.datapoints.find((x) => x.type === DatapointType.AstroFunctionConfig);
                const connectedVirtualDeviceBlock = dp?.AstroConfig?.FunctionBlocks?.find(
                    (x) => x.ObjectID === currentVirtualDevice.id,
                );

                if (
                    connectedVirtualDeviceBlock &&
                    dp?.AstroConfig &&
                    ((connectedVirtualDeviceBlock.Dawn !== ignore &&
                        Number(connectedVirtualDeviceBlock.Dawn) > scenes.length - 1) ||
                        (connectedVirtualDeviceBlock.Sunrise !== ignore &&
                            Number(connectedVirtualDeviceBlock.Sunrise) > scenes.length - 1) ||
                        (connectedVirtualDeviceBlock.Sunset !== ignore &&
                            Number(connectedVirtualDeviceBlock.Sunset) > scenes.length - 1) ||
                        (connectedVirtualDeviceBlock.Twilight !== ignore &&
                            Number(connectedVirtualDeviceBlock.Twilight) > scenes.length - 1))
                ) {
                    const newFb: AstroFunctionBlock = {
                        ...connectedVirtualDeviceBlock,
                        Dawn:
                            connectedVirtualDeviceBlock.Dawn !== ignore &&
                            Number(connectedVirtualDeviceBlock.Dawn) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.Dawn,
                        Sunrise:
                            connectedVirtualDeviceBlock.Sunrise !== ignore &&
                            Number(connectedVirtualDeviceBlock.Sunrise) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.Sunrise,
                        Sunset:
                            connectedVirtualDeviceBlock.Sunset !== ignore &&
                            Number(connectedVirtualDeviceBlock.Sunset) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.Sunset,
                        Twilight:
                            connectedVirtualDeviceBlock.Twilight !== ignore &&
                            Number(connectedVirtualDeviceBlock.Twilight) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.Twilight,
                    };
                    const newDp: Datapoint = {
                        ...dp,
                        AstroConfig: {
                            ...dp?.AstroConfig,
                            FunctionBlocks:
                                dp?.AstroConfig?.FunctionBlocks?.map((x) =>
                                    x === connectedVirtualDeviceBlock ? newFb : x,
                                ) ?? [],
                        },
                    };

                    const result = await onDatapointChange(newDp);

                    if (!result) {
                        return;
                    }
                }
            }

            if (vd.type === VirtualDeviceType.CentralHomeButtons) {
                const dp = vd.datapoints.find((x) => x.type === DatapointType.CentralComingHomeConfig);
                const connectedVirtualDeviceBlock = dp?.CominghomeConfig?.Buttons?.find(
                    (x) => x.ObjectID === currentVirtualDevice.id,
                );

                if (
                    connectedVirtualDeviceBlock &&
                    dp?.CominghomeConfig?.Buttons &&
                    ((connectedVirtualDeviceBlock.ComingHome !== ignore &&
                        Number(connectedVirtualDeviceBlock.ComingHome) > scenes.length - 1) ||
                        (connectedVirtualDeviceBlock.LeavingHome !== ignore &&
                            Number(connectedVirtualDeviceBlock.LeavingHome) > scenes.length - 1) ||
                        (connectedVirtualDeviceBlock.Sleep !== ignore &&
                            Number(connectedVirtualDeviceBlock.Sleep) > scenes.length - 1))
                ) {
                    const newFb: CominghomeBlock = {
                        ...connectedVirtualDeviceBlock,
                        ComingHome:
                            connectedVirtualDeviceBlock.ComingHome !== ignore &&
                            Number(connectedVirtualDeviceBlock.ComingHome) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.ComingHome,
                        LeavingHome:
                            connectedVirtualDeviceBlock.LeavingHome !== ignore &&
                            Number(connectedVirtualDeviceBlock.LeavingHome) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.LeavingHome,
                        Sleep:
                            connectedVirtualDeviceBlock.Sleep !== ignore &&
                            Number(connectedVirtualDeviceBlock.Sleep) > scenes.length - 1
                                ? ignore
                                : connectedVirtualDeviceBlock.Sleep,
                    };
                    const newDp: Datapoint = {
                        ...dp,
                        CominghomeConfig: {
                            ...dp?.CominghomeConfig,
                            Buttons:
                                dp?.CominghomeConfig?.Buttons?.map((x) =>
                                    x === connectedVirtualDeviceBlock ? newFb : x,
                                ) ?? [],
                        },
                    };

                    const result = await onDatapointChange(newDp);

                    if (!result) {
                        return;
                    }
                }
            }

            if (vd.type === VirtualDeviceType.CentralScheduler) {
                const dp = vd.datapoints.find((x) => x.type === DatapointType.CentralSchedulerConfig);

                let anyChange = false;
                let timeProgramList = dp?.TimeProgramList;

                for (const tp of dp?.TimeProgramList ?? []) {
                    for (const sp of tp.SwitchPoints) {
                        const connectedVirtualDeviceBlock = sp.FB?.find((x) => x.ObjectID === currentVirtualDevice.id);

                        if (
                            connectedVirtualDeviceBlock &&
                            sp.FB &&
                            connectedVirtualDeviceBlock.Value !== ignore &&
                            Number(connectedVirtualDeviceBlock.Value) > scenes.length - 1
                        ) {
                            const newFb: SwitchPoint = {
                                ...sp,
                                FB: sp.FB.map((fb) =>
                                    fb === connectedVirtualDeviceBlock
                                        ? {
                                              ...fb,
                                              Value:
                                                  connectedVirtualDeviceBlock.Value !== ignore &&
                                                  Number(connectedVirtualDeviceBlock.Value) > scenes.length - 1
                                                      ? ignore
                                                      : connectedVirtualDeviceBlock.Value,
                                          }
                                        : fb,
                                ),
                            };
                            timeProgramList = timeProgramList?.map((tpx) =>
                                tpx === tp
                                    ? {
                                          ...tpx,
                                          SwitchPoints: tpx?.SwitchPoints?.map((x) => (x === sp ? newFb : x)) ?? [],
                                      }
                                    : tpx,
                            );
                            anyChange = true;
                        }
                    }
                }

                if (anyChange && timeProgramList && dp) {
                    const newDp: Datapoint = {
                        ...dp,
                        TimeProgramList: timeProgramList,
                    };
                    const result = await onDatapointChange(newDp);

                    if (!result) {
                        return;
                    }
                }
            }
        }

        onGoBack();
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!sceneConfigDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (sceneConfigDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.WholePageSpace size={[0, 8]} wrap>
                    {scenes?.map((scene, index) => (
                        <ThemedCheckableTag
                            $error={hasError(scene)}
                            key={index}
                            onClick={() => setSelectedSceneIndex(index)}
                            checked={selectedSceneIndex === index}
                        >
                            <Styled.TagWrapper>
                                <Styled.TagTitle>{scene.Name}</Styled.TagTitle>
                                {index !== 0 && (
                                    <Styled.IconWrapper>
                                        <IoMdCloseCircleOutline
                                            onClick={(e) => {
                                                onRemoveScene(index);
                                                e.stopPropagation();
                                            }}
                                            size={15}
                                        />
                                    </Styled.IconWrapper>
                                )}
                            </Styled.TagWrapper>
                        </ThemedCheckableTag>
                    ))}
                    <ThemedCheckableTag onClick={addScene} checked={false} $dashed>
                        <Styled.TagWrapper>
                            <MdAdd fontSize={25} />
                        </Styled.TagWrapper>
                    </ThemedCheckableTag>
                </Styled.WholePageSpace>
                <Space size={20} wrap>
                    <ThemedButton $width={100} onClick={onGoBack}>
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton $action $width={100} onClick={onSave} disabled={saveDisabled}>
                        {t('general.save')}
                    </ThemedButton>
                </Space>
            </Styled.HeaderWrapper>
            {currentScene && (
                <>
                    <Tooltip open={!!currentError} title={currentError} color={colors.error}>
                        <ThemedInput
                            disabled={selectedSceneIndex === 0}
                            value={currentScene.Name}
                            onChange={(v) => onChangeName(v.currentTarget.value)}
                        />
                    </Tooltip>
                    <SceneView
                        onLightValueChanged={onLightValueChanged}
                        isOffScene={selectedSceneIndex === 0}
                        virtualDevice={currentVirtualDevice}
                        scene={currentScene}
                    />
                </>
            )}
        </Styled.MainWrapper>
    );
};

export default SceneEditPage;
