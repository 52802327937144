import { Select, Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSelect, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { getLightValue } from 'helpers/DatapointHelper';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { groupBy } from 'lodash';
import { Ios } from 'models/server/Ios';
import { DatapointType } from 'models/server/enums/DatapointType';
import { ioDashboardValueState } from 'pages/HomePage/ServerView/ServerView';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlinePlayCircle, MdPauseCircleOutline } from 'react-icons/md';
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useRecoilState } from 'recoil';
import { useTheme } from 'styled-components';
import * as Styled from './MonitorChartsPage.styles';

const { Option } = Select;

const secondFormat = 'YYYY-MM-DD HH:mm:ss';

const chartColors = [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
];

const MonitorChartsPage = () => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const { currentServer, serverConfigLoading, ios, virtualDevices, currentVirtualDevice, info } = useServerConfig();
    const { onGoBack } = useGoBack();
    const [dashboardIoValues] = useRecoilState(ioDashboardValueState);

    const [dataLogs, setDataLogs] = useState<{ date: string; ios: Ios }[][]>([[]]);
    const [time, setTime] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const [init, setInit] = useState(false);

    const connectedIosValues = useMemo(
        () => dashboardIoValues.filter((x) => x.serialNumber === info?.serialnumber),
        [dashboardIoValues, info],
    );

    const monitorConfig = useMemo(() => {
        if (!currentVirtualDevice) {
            return;
        }
        const monitor = virtualDevices?.find((x) => x.id === currentVirtualDevice.id);
        const datapoint = monitor?.datapoints?.find((x) => x.type === DatapointType.MonitorConfig);
        return datapoint;
    }, []);

    const supportedIos: Ios[] = useMemo(() => {
        if (!currentVirtualDevice || !monitorConfig) {
            return [];
        }

        const ioIds = monitorConfig.Monitor.filter((x) => x.Log).map((x) => x.IOid);

        return ios.filter((x) => ioIds.some((y) => y === x.id));
    }, [virtualDevices]);

    const [chartsProps, setChartsProps] = useState<{ id: number; hide: boolean }[]>();

    const [filters, setFilters] = useState<{ selectedIos: string[] }>({
        selectedIos: [],
    });

    const options = useMemo(
        () =>
            supportedIos?.map((x, index) => (
                <Option value={x.id.toString()} key={`(${index + 1}) ${x.name}`}>{`(${index + 1}) ${x.name}`}</Option>
            )),
        [supportedIos],
    );

    useEffect(() => {
        if (init) {
            return;
        }
        setInit(true);
        setFilters({
            selectedIos: supportedIos.slice(0, 20).map((x) => x.id.toString()),
        });
        setChartsProps(supportedIos?.map((x) => ({ id: Number(x.id), hide: false })));
    }, [supportedIos]);

    useEffect(() => {
        const timer = window.setInterval(() => {
            setTime((prev) => prev + 1);
        }, 10000);
        return () => {
            window.clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (isPaused || connectedIosValues.every((x) => x.value === undefined)) {
            return;
        }

        setDataLogs((prev) => [
            ...prev,
            ios
                .filter((x) => supportedIos.some((y) => x.id === y.id))
                .map((x) => ({ date: dayjs().format(secondFormat), ios: x })),
        ]);
    }, [time]);

    const getNumber = (value: string) => {
        if (value.startsWith('#')) {
            return getLightValue(value, ['0', '99999']);
        }

        if (value.toString() === 'true') {
            return 1;
        } else if (value.toString() === 'false') {
            return 0;
        }

        const num = Number(value);

        if (isNaN(num)) {
            return 0;
        }

        return num;
    };

    const formattedData = useMemo(() => {
        if (!dataLogs?.length) {
            return [];
        }

        const grouped = groupBy(
            dataLogs
                .flatMap((x) => x)
                .map((x) => ({
                    date: dayjs(x.date).valueOf(),
                    [x.ios.id]: getNumber(
                        connectedIosValues.find((ci) => ci.ioId === x.ios.id)?.value?.toString() ?? '0',
                    ),
                })),
            (x) => x.date,
        );
        const formatted = Object.keys(grouped).map((x) => ({
            ...grouped[x].reduce((prev, curr) => {
                return {
                    ...prev,
                    ...curr,
                };
            }),
        }));

        return formatted;
    }, [dataLogs]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectBar = (e: any) => {
        setChartsProps(
            (prev) => prev?.map((x) => (x.id === Number(e.dataKey) ? { ...x, hover: false, hide: !x.hide } : x)),
        );
    };

    if (serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    return (
        <Styled.MainWrapper>
            <Styled.BackButtonRow>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </Styled.BackButtonRow>
            <Space size={20} wrap>
                <ThemedSelect
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={33}
                    allowClear={false}
                    style={{ width: 350, maxHeight: 35 }}
                    value={filters.selectedIos}
                    onChange={(v: string[]) => setFilters((prev) => ({ ...prev, selectedIos: v }))}
                >
                    {options}
                </ThemedSelect>
                <ThemedButton
                    onClick={() => setIsPaused((prev) => !prev)}
                    icon={isPaused ? <MdOutlinePlayCircle fontSize={20} /> : <MdPauseCircleOutline fontSize={20} />}
                >
                    {isPaused ? t('general.start') : t('general.stop')}
                </ThemedButton>
                <ThemedButton
                    onClick={() => {
                        setDataLogs([]);
                        setIsPaused(false);
                    }}
                >
                    {t('general.reset')}
                </ThemedButton>
            </Space>
            {formattedData.length === 0 && <ThemedSpin size="large" />}
            {formattedData.length > 0 && (
                <ResponsiveContainer height={700}>
                    <ComposedChart data={formattedData}>
                        <CartesianGrid stroke={colors.text} />
                        <XAxis
                            tick={{ fill: colors.text }}
                            dataKey="date"
                            type="number"
                            tickFormatter={(label) => {
                                return dayjs(label).format(secondFormat);
                            }}
                            domain={formattedData.map((x) => x.date)}
                        />
                        <YAxis tick={{ fill: colors.text }} />
                        <Tooltip
                            contentStyle={{
                                backgroundColor: colors.backgroundLayer2,
                            }}
                            labelFormatter={(label: string) => {
                                return dayjs(label).format(secondFormat);
                            }}
                            formatter={(v, name, item) => {
                                return `${item.value} ${
                                    monitorConfig?.Monitor.find((x) => x.IOid === Number(item.dataKey))?.Unit ?? ''
                                }`;
                            }}
                        />
                        <Legend onClick={selectBar} />
                        <ReferenceLine y={0} stroke={colors.text} strokeDasharray="3 3" />
                        {filters.selectedIos.map((x, index) => (
                            <Line
                                dot={false}
                                key={x}
                                dataKey={x}
                                name={supportedIos.find((z) => z.id === Number(x))?.name}
                                type="stepAfter"
                                stroke={chartColors[index > 19 ? Number((Math.random() * 19).toFixed(0)) : index]}
                                hide={chartsProps?.find((z) => z.id === Number(x))?.hide}
                            />
                        ))}
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </Styled.MainWrapper>
    );
};

export default MonitorChartsPage;
