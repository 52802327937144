import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './Blinds.styles';

const Blinds = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const blindsPositionDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualBlindsPosition),
        [datapoints],
    );
    const slatsPositionDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSlatsPosition),
        [datapoints],
    );
    const setBlindsDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSetBlinds),
        [datapoints],
    );
    const setSlatsDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSetSlats),
        [datapoints],
    );
    const shadingDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualShadingOn),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {blindsPositionDatapoint && <DatapointValueView sufix="%" valueOrDp={blindsPositionDatapoint} />}
            {setBlindsDatapoint && (
                <DatapointSliderView hideValueView datapoint={setBlindsDatapoint} virtualDevice={virtualDevice} />
            )}
            {slatsPositionDatapoint && <DatapointValueView sufix="%" valueOrDp={slatsPositionDatapoint} />}
            {setSlatsDatapoint && (
                <DatapointSliderView hideValueView datapoint={setSlatsDatapoint} virtualDevice={virtualDevice} />
            )}
            {shadingDatapoint && <DatapointButtonView datapoint={shadingDatapoint} />}
        </Styled.MainWrapper>
    );
};

export default Blinds;
