import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    border-radius: 8px;
    padding: 10px 20px;
    align-items: center;
`;

export const Title = styled.div`
    font-weight: 500;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const OptionWrapper = styled.div`
    width: 100%;
`;

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const SwitchWrapper = styled.div`
    display: flex;
    gap: 10px;
`;
