import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const HeaderTag = styled(ThemedCheckableTag)`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
`;

export const MainHeader = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const NotificationWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const NOfNotificationsWrapper = styled.div`
    position: absolute;
    top: -5px;
    right: 0;
    line-height: 12px;
    background-color: #e74c3c;
    font-size: 11px;
    width: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${(props) => props.theme.colors.text};
`;
