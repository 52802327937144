import { VirtualDevice } from 'models/server/VirtualDevice';

export const getMockEnergyVirtualDevice = (): VirtualDevice =>
    JSON.parse(
        JSON.stringify({
            category: 5,
            datapoints: [
                {
                    customname: '',
                    id: 35,
                    ioid: -1,
                    isconfiguration: false,
                    name: 'VirtualHourEnergy',
                    objectid: 4,
                    range: ['', ''],
                    remote: true,
                    type: 11,
                    value: 0.0007499999999999999,
                    writeprotect: true,
                },
                {
                    customname: '',
                    id: 36,
                    ioid: -1,
                    isconfiguration: false,
                    name: 'VirtualDayEnergy',
                    objectid: 4,
                    range: ['', ''],
                    remote: true,
                    type: 12,
                    value: 0.6433134610000011,
                    writeprotect: true,
                },
                {
                    customname: '',
                    id: 37,
                    ioid: -1,
                    isconfiguration: false,
                    name: 'VirtualMonthEnergy',
                    objectid: 4,
                    range: ['', ''],
                    remote: true,
                    type: 13,
                    value: 14.712156281299716,
                    writeprotect: true,
                },
                {
                    customname: '',
                    id: 38,
                    ioid: -1,
                    isconfiguration: false,
                    name: 'VirtualYearEnergy',
                    objectid: 4,
                    range: ['', ''],
                    remote: true,
                    type: 14,
                    value: 36.840589693797696,
                    writeprotect: true,
                },
                {
                    customname: '',
                    id: 39,
                    ioid: -1,
                    isconfiguration: false,
                    name: 'VirtualReset',
                    objectid: 4,
                    range: ['false', 'true'],
                    remote: true,
                    type: 1,
                    value: false,
                    writeprotect: false,
                },
                {
                    customname: 'AC unit ',
                    deviceid: 3,
                    id: 40,
                    ioid: 9,
                    isconfiguration: false,
                    name: 'EnergyStatisticDay',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 12,
                    value: 0,
                    writeprotect: true,
                },
                {
                    customname: 'AC unit ',
                    deviceid: 3,
                    id: 41,
                    ioid: 9,
                    isconfiguration: false,
                    name: 'EnergyStatisticHour',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 11,
                    value: 0,
                    writeprotect: true,
                },
                {
                    customname: 'AC unit ',
                    deviceid: 3,
                    id: 42,
                    ioid: 9,
                    isconfiguration: false,
                    name: 'EnergyStatisticMonth',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 13,
                    value: 0,
                    writeprotect: true,
                },
                {
                    customname: 'AC unit ',
                    deviceid: 3,
                    id: 43,
                    ioid: 9,
                    isconfiguration: false,
                    name: 'EnergyStatisticYear',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 14,
                    value: 7,
                    writeprotect: true,
                },
                {
                    customname: 'Lights',
                    deviceid: 28,
                    id: 44,
                    ioid: 64,
                    isconfiguration: false,
                    name: 'EnergyStatisticDay',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 12,
                    value: 0.52518769,
                    writeprotect: true,
                },
                {
                    customname: 'Lights',
                    deviceid: 28,
                    id: 45,
                    ioid: 64,
                    isconfiguration: false,
                    name: 'EnergyStatisticHour',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 11,
                    value: 0,
                    writeprotect: true,
                },
                {
                    customname: 'Lights',
                    deviceid: 28,
                    id: 46,
                    ioid: 64,
                    isconfiguration: false,
                    name: 'EnergyStatisticMonth',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 13,
                    value: 12.51269271099999,
                    writeprotect: true,
                },
                {
                    customname: 'Lights',
                    deviceid: 28,
                    id: 47,
                    ioid: 64,
                    isconfiguration: false,
                    name: 'EnergyStatisticYear',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 14,
                    value: 100.4507765999999,
                    writeprotect: true,
                },
                {
                    customname: 'Solars',
                    deviceid: 29,
                    id: 48,
                    ioid: 67,
                    isconfiguration: false,
                    name: 'EnergyStatisticDay',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 12,
                    value: 0.10237177100000001,
                    writeprotect: true,
                },
                {
                    customname: 'Solars',
                    deviceid: 29,
                    id: 49,
                    ioid: 67,
                    isconfiguration: false,
                    name: 'EnergyStatisticHour',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 11,
                    value: 0,
                    writeprotect: true,
                },
                {
                    customname: 'Solars',
                    deviceid: 29,
                    id: 50,
                    ioid: 67,
                    isconfiguration: false,
                    name: 'EnergyStatisticMonth',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 13,
                    value: 1.7985325703,
                    writeprotect: true,
                },
                {
                    customname: 'Solars',
                    deviceid: 29,
                    id: 51,
                    ioid: 67,
                    isconfiguration: false,
                    name: 'EnergyStatisticYear',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 14,
                    value: 9.5268943657,
                    writeprotect: true,
                },
                {
                    customname: 'Car charger',
                    deviceid: 17,
                    id: 52,
                    ioid: 98,
                    isconfiguration: false,
                    name: 'EnergyStatisticDay',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 12,
                    value: 0.015754,
                    writeprotect: true,
                },
                {
                    customname: 'Car charger',
                    deviceid: 17,
                    id: 53,
                    ioid: 98,
                    isconfiguration: false,
                    name: 'EnergyStatisticHour',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 11,
                    value: 0.0007499999999999999,
                    writeprotect: true,
                },
                {
                    customname: 'Car charger',
                    deviceid: 17,
                    id: 54,
                    ioid: 98,
                    isconfiguration: false,
                    name: 'EnergyStatisticMonth',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 13,
                    value: 0.40093099999998366,
                    writeprotect: true,
                },
                {
                    customname: 'Car charger',
                    deviceid: 17,
                    id: 55,
                    ioid: 98,
                    isconfiguration: false,
                    name: 'EnergyStatisticYear',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 14,
                    value: 31.98585100000351,
                    writeprotect: true,
                },
                {
                    EnergyConfig: {
                        IOConfigs: [
                            {
                                Color: '#3366CC',
                                Diagram: 4,
                                FlowChart: false,
                                Graph: 3,
                                HistoricalChart: true,
                                Id: 9,
                                Invert: false,
                                Role: 2,
                                Unit: 'kWh',
                            },
                            {
                                Color: '#DC3912',
                                Diagram: 3,
                                FlowChart: false,
                                Graph: 2,
                                HistoricalChart: true,
                                Id: 64,
                                Invert: false,
                                Role: 2,
                                Unit: 'kWh',
                            },
                            {
                                Color: '#FF9900',
                                Diagram: 2,
                                FlowChart: false,
                                Graph: 1,
                                HistoricalChart: true,
                                Id: 67,
                                Invert: false,
                                Role: 1,
                                Unit: 'kWh',
                            },
                            {
                                Color: '#109618',
                                Diagram: 4,
                                FlowChart: false,
                                Graph: 3,
                                HistoricalChart: true,
                                Id: 98,
                                Invert: false,
                                Role: 2,
                                Unit: 'kWh',
                            },
                            {
                                Color: '#990099',
                                Diagram: 6,
                                FlowChart: true,
                                Graph: 3,
                                HistoricalChart: true,
                                Id: 10,
                                Invert: false,
                                Role: 2,
                                Unit: 'W',
                            },
                            {
                                Color: '#FF9900',
                                Diagram: 1,
                                FlowChart: true,
                                Graph: 1,
                                HistoricalChart: true,
                                Id: 63,
                                Invert: false,
                                Role: 2,
                                Unit: 'W',
                            },
                            {
                                Color: '#E67300',
                                Diagram: 4,
                                FlowChart: true,
                                Graph: 1,
                                HistoricalChart: true,
                                Id: 66,
                                Invert: false,
                                Role: 1,
                                Unit: 'W',
                            },
                            {
                                Color: '#DD4477',
                                Diagram: 2,
                                FlowChart: true,
                                Graph: 3,
                                HistoricalChart: true,
                                Id: 97,
                                Invert: false,
                                Role: 2,
                                Unit: 'W',
                            },
                        ],
                        SingleEnergy: [
                            {
                                day: {
                                    id: 40,
                                    type: 12,
                                    value: '0',
                                },
                                hour: {
                                    id: 41,
                                    type: 11,
                                    value: '0',
                                },
                                ioId: 9,
                                month: {
                                    id: 42,
                                    type: 13,
                                    value: '0',
                                },
                                year: {
                                    id: 43,
                                    type: 14,
                                    value: '7',
                                },
                            },
                            {
                                day: {
                                    id: 44,
                                    type: 12,
                                    value: '0.52518769',
                                },
                                hour: {
                                    id: 45,
                                    type: 11,
                                    value: '0',
                                },
                                ioId: 64,
                                month: {
                                    id: 46,
                                    type: 13,
                                    value: '12.51269271099999',
                                },
                                year: {
                                    id: 47,
                                    type: 14,
                                    value: '100.4507765999999',
                                },
                            },
                            {
                                day: {
                                    id: 52,
                                    type: 12,
                                    value: '0.015754',
                                },
                                hour: {
                                    id: 53,
                                    type: 11,
                                    value: '0.0007499999999999999',
                                },
                                ioId: 98,
                                month: {
                                    id: 54,
                                    type: 13,
                                    value: '0.40093099999998366',
                                },
                                year: {
                                    id: 55,
                                    type: 14,
                                    value: '31.98585100000351',
                                },
                            },
                            {
                                day: {
                                    id: 48,
                                    type: 12,
                                    value: '0.10237177100000001',
                                },
                                hour: {
                                    id: 49,
                                    type: 11,
                                    value: '0',
                                },
                                ioId: 67,
                                month: {
                                    id: 50,
                                    type: 13,
                                    value: '1.7985325703',
                                },
                                year: {
                                    id: 51,
                                    type: 14,
                                    value: '9.5268943657',
                                },
                            },
                        ],
                        SinglePower: [97, 10, 63, 66],
                        TotalEnergy: {
                            Inputs: [9, 64, 67, 98],
                            day: {
                                id: 36,
                                type: 12,
                                value: '0.6433134610000011',
                            },
                            hour: {
                                id: 35,
                                type: 11,
                                value: '0.0007499999999999999',
                            },
                            month: {
                                id: 37,
                                type: 13,
                                value: '14.712156281299716',
                            },
                            year: {
                                id: 38,
                                type: 14,
                                value: '36.840589693797696',
                            },
                        },
                        TotalPower: [97, 10, 63, 66],
                        guid: 'a00f7439-b4d3-4106-a898-b327b29b01ea',
                    },
                    customname: '',
                    id: 56,
                    ioid: -1,
                    isconfiguration: true,
                    name: 'centralObj.config',
                    objectid: 4,
                    range: [],
                    remote: true,
                    type: 25,
                    writeprotect: true,
                },
            ],
            deviceid: 1,
            externalaccess: true,
            favourite: true,
            id: 4,
            name: 'Energy monitor',
            properties: [],
            ranking: 4,
            roomid: 6,
            type: 28,
            visualisationpw: false,
        }),
    ) as VirtualDevice;
