import { useMemo } from 'react';

import { EnergyManagementDevice, EnergyManagementDeviceProperty } from 'models/server/EnergyManagementDevice';
import { getFreePriorities } from 'pages/EnergyManagerEditPage/EnergyManagerDeviceView/EnergyManagerDeviceScheduleView/EnergyManagerDeviceScheduleView';
import EditableCell from './EditableCell/EditableCell';
import EditableModeCell from './EditableModeCell/EditableModeCell';
import * as Styled from './SummaryDevice.styles';

type Props = {
    allDevices: EnergyManagementDevice[];
    device: EnergyManagementDevice;
    allPriority: EnergyManagementDeviceProperty[];
    priority: EnergyManagementDeviceProperty;
    onPropertyChanged: (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => void;
};

const SummaryDevice = ({ allDevices, device, priority, allPriority, onPropertyChanged }: Props): JSX.Element => {
    const sortedDevices = useMemo(() => {
        const devicePrios = allPriority.filter((x) => device.Priorities.some((z) => z === x));
        return devicePrios
            .filter((x) => device.Priorities.some((z) => z === x))
            .map((sprio, index) => ({
                ...sprio,
                maxLevel: devicePrios.length - 1 === index ? '+' : ' - ' + devicePrios[index + 1].Level,
            }));
    }, [allPriority]);
    const block =
        sortedDevices.find(
            (x) => x.Level === priority.Level && x.Mode === priority.Mode && x.Priority === priority.Priority,
        ) ?? sortedDevices[0];

    return (
        <Styled.Wrapper>
            <Styled.NameWrapper>{device.Name}</Styled.NameWrapper>
            <Styled.LevelsWrapper>
                <Styled.BlockWrapper>
                    <Styled.PropWrapper>
                        {block.Priority && block.Mode !== 0 && block.Mode !== 1 ? (
                            <EditableCell
                                value={block.Priority}
                                onChange={(v) =>
                                    onPropertyChanged(device, priority, {
                                        ...priority,
                                        Priority: v,
                                    })
                                }
                                takenNumbers={allPriority
                                    .map((x) => x.Priority ?? 0)
                                    .filter((x) => x !== priority.Priority)}
                            />
                        ) : (
                            block.Priority
                        )}
                    </Styled.PropWrapper>
                    <Styled.PropWrapper>{block.Level + block.maxLevel}</Styled.PropWrapper>
                    <Styled.PropLastWrapper>
                        <EditableModeCell
                            value={block.Mode}
                            onChange={(v) =>
                                onPropertyChanged(device, priority, {
                                    ...priority,
                                    Mode: v,
                                    Priority:
                                        v === 0 || v === 1
                                            ? undefined
                                            : priority.Priority
                                            ? priority.Priority
                                            : getFreePriorities(allDevices)[0],
                                })
                            }
                        />
                    </Styled.PropLastWrapper>
                </Styled.BlockWrapper>
            </Styled.LevelsWrapper>
            <Styled.NameWrapper>{device.MinPower ?? '-'}</Styled.NameWrapper>
            <Styled.NameWrapper>{device.MaxPower ?? '-'}</Styled.NameWrapper>
            <Styled.NameWrapper>{device.MaxEnergy ?? '-'}</Styled.NameWrapper>
            <Styled.NameWrapper>{device.PreparationTime ?? '-'}</Styled.NameWrapper>
            <Styled.NameLastWrapper>{device.MinRuntime ?? '-'}</Styled.NameLastWrapper>
        </Styled.Wrapper>
    );
};

export default SummaryDevice;
