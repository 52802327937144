import { Space } from 'antd';
import EmptySuccess from 'components/EmptySuccess/EmptySuccess';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import NotificationView from 'components/ServerConfigRoute/Notifications/NotificationView/NotificationView';
import { NotificationInfoTyped, getAlarmConfig } from 'components/ServerConfigRoute/Notifications/Notifications';
import { GroupedServerConfig } from 'components/ServerSelectRoute/ServerSelectBulkLoadingProvider/ServerSelectBulkLoadingProvider';
import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import { useServerApi } from 'hooks/useServerApi';
import { SystemNotification } from 'models/server/SystemNotification';
import { NotificationType } from 'models/server/enums/NotificationType';
import { RoomType } from 'models/server/enums/RoomType';
import { WebsocketCode } from 'models/server/enums/WebsocketCode';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './NotificationsServerView.styles';

type Props = {
    config: GroupedServerConfig;
};

const NotificationsServerView = ({ config }: Props) => {
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const { onRefetchOne } = useContext(GroupedServersConfigContext);
    const { server, setupInfo } = config;
    const { t } = useTranslation();

    const [serverSystemNotifications, setServerSystemNotifications] = useState<SystemNotification[]>([]);
    const [loading, setLoading] = useState(true);

    const allNotificationsConfigs = useMemo(() => {
        const notifications =
            setupInfo?.notifications?.map((x) =>
                getAlarmConfig(x, setupInfo?.devices ?? [], setupInfo?.rooms ?? [], t),
            ) ?? [];
        const nonEmptyDeviceNotifications =
            serverSystemNotifications?.filter(
                (x) =>
                    !!x.deviceid &&
                    (x.code === WebsocketCode.DeviceNotResponding || x.code === WebsocketCode.DeviceAvailable),
            ) ?? [];

        const systemNotifications: NotificationInfoTyped[] = [];

        for (const deviceId of nonEmptyDeviceNotifications
            .map((x) => x.deviceid)
            .filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            })) {
            const deviceNotifications = nonEmptyDeviceNotifications.filter((x) => x.deviceid === deviceId);
            const lastNotification = deviceNotifications[deviceNotifications.length - 1];

            if (lastNotification?.code === WebsocketCode.DeviceNotResponding) {
                const systemNotification: NotificationInfoTyped = {
                    deviceid: lastNotification.deviceid ?? 0,
                    id: lastNotification.id,
                    objectId: 0,
                    room: '',
                    roomcategory: RoomType.Undefined,
                    text: '',
                    type: NotificationType.DeviceNotResponding,
                    ugpushconf: {
                        Groups: [],
                        Users: [],
                    },
                    isWebsocket: false,
                    isSystem: true,
                };

                systemNotifications.push(systemNotification);
            }
        }

        const systemNotificationsTyped = systemNotifications.map((x) =>
            getAlarmConfig(x, setupInfo?.devices ?? [], setupInfo?.rooms ?? [], t),
        );

        return [...notifications, ...systemNotificationsTyped];
    }, [setupInfo?.notifications, setupInfo?.devices, setupInfo?.rooms, serverSystemNotifications]);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const systemNotificationsResponse = await apiServerFetchWithCustomPortalId<{
            systemnotifications: SystemNotification[];
        }>(server.id, 'systemnotification');

        if (!systemNotificationsResponse.result || systemNotificationsResponse.code !== 200) {
            setLoading(false);
            return;
        }

        setServerSystemNotifications(systemNotificationsResponse.result.systemnotifications);
        setLoading(false);
    };

    return (
        <Styled.Wrapper>
            <Styled.Title>{server.name}</Styled.Title>
            <Line />
            {loading ? (
                <ThemedSpin />
            ) : (
                <>
                    {allNotificationsConfigs.length === 0 && (
                        <EmptySuccess fontSize={30} title={t('notifiactions.noNotifications')} />
                    )}
                    <Space size={10} wrap>
                        {allNotificationsConfigs?.map((not, index) => (
                            <Styled.NotificationWrapper key={index}>
                                {not && (
                                    <NotificationView
                                        notification={not}
                                        index={index}
                                        onRemoveNotification={() => onRefetchOne?.(server.id)}
                                        server={server}
                                        closable={false}
                                    />
                                )}
                            </Styled.NotificationWrapper>
                        ))}
                    </Space>
                </>
            )}
        </Styled.Wrapper>
    );
};

export default NotificationsServerView;
