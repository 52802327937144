import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import * as Styled from './CentralBlinds.styles';

const CentralBlinds = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const triggerUpDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerUp,
            ),
        [datapoints],
    );
    const triggerDownDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerDown,
            ),
        [datapoints],
    );
    const stopDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualCentralBlindsStop,
            ),
        [datapoints],
    );
    const shadowingDatapoint = useMemo(
        () =>
            virtualDevice?.datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualShadowing,
            ),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            <Styled.ButtonsWrapper>
                {triggerUpDatapoint && (
                    <DatapointButtonView
                        hideName
                        datapoint={triggerUpDatapoint}
                        $width={50}
                        shape="round"
                        icon={<FaChevronUp size={20} />}
                    />
                )}
                {triggerDownDatapoint && (
                    <DatapointButtonView
                        hideName
                        datapoint={triggerDownDatapoint}
                        $width={50}
                        shape="round"
                        icon={<FaChevronDown size={20} />}
                    />
                )}
            </Styled.ButtonsWrapper>
            {stopDatapoint && <DatapointButtonView datapoint={stopDatapoint} />}
            {shadowingDatapoint && <DatapointButtonView datapoint={shadowingDatapoint} />}
        </Styled.MainWrapper>
    );
};

export default CentralBlinds;
