import { routes } from 'App';
import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { FloorplanContext } from 'contexts/FloorplanContext';
import { useServerApi } from 'hooks/useServerApi';
import { Floor } from 'models/server/Floor';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import floorplanBackground1 from './DemoFloorplanImg1.jpeg';
import floorplanBackground2 from './DemoFloorplanImg2.png';
import * as Styled from './FloorplanPage.styles';
import useFloorPlanConfig from './useFloorPlanConfig';

export interface PreloadedImg {
    img: string;
    imageName: string;
}

const FloorplanPage = () => {
    const { t } = useTranslation();
    const { isDemo } = useContext(AuthContext);
    const location = useLocation();
    const { apiServerFetch } = useServerApi();
    const {
        floorPlansVirtualDevices,
        serverConfigLoading,
        currentServer,
        currentFloorPlanVirtualDevice,
        floorPlanIndex,
        floorPlans,
        currentFloor,
    } = useFloorPlanConfig();
    const navigate = useNavigate();
    const { licenseId } = useParams<{ licenseId: string }>();

    const isGrouped = location.pathname.startsWith(routes.groupActions);

    const [preloadedImagesConfig, setPreloadedImagesConfig] = useState<{ images: PreloadedImg[]; loading: boolean }>({
        images: [],
        loading: true,
    });

    const init = async () => {
        setPreloadedImagesConfig((prev) => ({ ...prev, loading: true }));

        const floorPlans = floorPlansVirtualDevices.map((x) =>
            x.datapoints.find((dp) => dp.type === DatapointType.FloorConfig),
        );

        const floorplansList: Floor[] = [];
        floorPlans.forEach((dp) => {
            if (dp?.Floors) {
                floorplansList.push(...dp?.Floors);
            }
        });
        const images = floorplansList
            .map((x) => x.FloorImage)
            .filter((val, id, array) => {
                return array.findIndex((z) => val.Name === z.Name) == id;
            });

        const result = await Promise.all<PreloadedImg>(
            images?.map(async (x) => ({
                img:
                    (await apiServerFetch<{ data: string }>('webimages', `?image=${btoa(x.Name)}`))?.result?.data ?? '',
                imageName: x.Name,
            })) ?? [],
        );

        setPreloadedImagesConfig({ images: result, loading: false });
    };

    useLayoutEffect(() => {
        if (floorPlanIndex === undefined && currentServer && currentFloorPlanVirtualDevice) {
            if (isGrouped) {
                navigate(
                    `${routes.groupActions}/${licenseId}/${currentServer?.id}/floorplan/${currentFloorPlanVirtualDevice?.id}/0`,
                );
                return;
            }

            navigate(`${routes.server}/${currentServer.id}/floorplan/${currentFloorPlanVirtualDevice.id}/0`);
        }
    }, [currentServer, currentFloorPlanVirtualDevice]);

    useEffect(() => {
        if (serverConfigLoading) {
            return;
        }

        if (isDemo) {
            setPreloadedImagesConfig({
                loading: false,
                images: [
                    {
                        imageName: 'floorplan1.jpeg',
                        img: floorplanBackground1,
                    },
                    {
                        imageName: 'd86dcde6922c5666715580cba6c449d8.PNG',
                        img: floorplanBackground2,
                    },
                ],
            });
            return;
        }

        init();
    }, [serverConfigLoading, currentServer]);

    const navigateToVirtualDevice = (vd: VirtualDevice) => {
        if (vd.id === currentFloorPlanVirtualDevice?.id) {
            return;
        }

        if (isGrouped) {
            navigate(`${routes.groupActions}/${licenseId}/${currentServer?.id}/floorplan/${vd?.id}/0`);
            return;
        }

        navigate(`${routes.server}/${currentServer?.id}/floorplan/${vd?.id}/0`);
    };

    const navigateToFloorPlan = (floorIndex: number) => {
        if (floorIndex === Number(floorPlanIndex ?? 0)) {
            return;
        }

        if (isGrouped) {
            navigate(
                `${routes.groupActions}/${licenseId}/${currentServer?.id}/floorplan/${currentFloorPlanVirtualDevice?.id}/${floorIndex}`,
            );
            return;
        }

        navigate(`${routes.server}/${currentServer?.id}/floorplan/${currentFloorPlanVirtualDevice?.id}/${floorIndex}`);
    };

    if (serverConfigLoading || preloadedImagesConfig.loading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!floorPlansVirtualDevices || floorPlansVirtualDevices.length === 0 || !currentFloorPlanVirtualDevice) {
        return <EmptyError title={t('errors.floorplanNotFound')} />;
    }

    return (
        <Styled.MainWrapper>
            <Space wrap>
                {floorPlansVirtualDevices.map((floorVd, index) => (
                    <Styled.FloorTag
                        key={index}
                        checked={floorVd.id === currentFloorPlanVirtualDevice.id}
                        onClick={() => navigateToVirtualDevice(floorVd)}
                    >
                        {floorVd.name}
                    </Styled.FloorTag>
                ))}
            </Space>
            <Space wrap>
                {floorPlans.map((floor, index) => (
                    <Styled.FloorTag
                        key={index}
                        checked={currentFloor === floor}
                        onClick={() => navigateToFloorPlan(index)}
                    >
                        {floor.Name}
                    </Styled.FloorTag>
                ))}
            </Space>
            <FloorplanContext.Provider value={{ floorplanImages: preloadedImagesConfig.images }}>
                <Outlet />
            </FloorplanContext.Provider>
        </Styled.MainWrapper>
    );
};

export default FloorplanPage;
