import { RefSelectProps } from 'antd';
import { ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './EditableModeCell.style';

type Props = {
    value: number;
    onChange: (v: number) => void;
};

const EditableModeCell = ({ value, onChange }: Props): JSX.Element => {
    const [inputVisible, setInputVisible] = useState(false);
    const [currentValue, setCurrentValue] = useState<number>(value);
    const ref = useRef<RefSelectProps>(null);
    const { t } = useTranslation();

    const selectOptions = useMemo(
        () => [
            {
                value: 0,
                label: t('general.off'),
            },
            {
                value: 1,
                label: t('general.on'),
            },
            {
                value: 2,
                label: t('general.solar'),
            },
            {
                value: 3,
                label: t('general.solarAndLowPrice'),
            },
        ],
        [],
    );

    const onBlur = () => {
        setInputVisible(false);
        onChange(currentValue);
    };

    useEffect(() => {
        if (!inputVisible) {
            return;
        }
        ref.current?.focus();
    }, [inputVisible]);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    if (!inputVisible) {
        return (
            <div
                style={{ width: '100%', cursor: 'pointer' }}
                onClick={() => {
                    setInputVisible(true);
                }}
            >
                {selectOptions.find((x) => x.value === value)?.label}
            </div>
        );
    }

    return (
        <Styled.Wrapper>
            <ThemedSelect
                ref={ref}
                style={{ width: '100%', maxWidth: 110 }}
                value={currentValue}
                options={selectOptions}
                onBlur={onBlur}
                onChange={(v) => {
                    setCurrentValue(v);
                }}
            />
        </Styled.Wrapper>
    );
};

export default EditableModeCell;
