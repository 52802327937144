import { Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import 'reactflow/dist/style.css';

import { InfoCircleOutlined } from '@ant-design/icons';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSpin, ThemedSwitch } from 'components/ThemedComponents/ThemedComponents.styles';
import YesNoModal from 'components/YesNoModal/YesNoModal';
import { AuthContext } from 'contexts/AuthContext';
import dayjs from 'dayjs';
import useCookie from 'hooks/useCookie';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { EnergyStatistic } from 'models/server/EnergyStatistic';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { DeviceType } from 'models/server/enums/DeviceType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactFlowProvider } from 'reactflow';
import CustomPie from './CustomPie/CustomPie';
import EnergyFilters, { DateSelectOption, getEnergyFiltersDates } from './EnergyFilters/EnergyFilters';
import * as Styled from './EnergyMonitorChartsPage.styles';
import Flowchart from './FlowChart/FlowChart';
import MainChart from './MainChart/MainChart';
import { getEnergyBulk } from './getEnergyBulk';
import { getEnergyStatistics } from './getEnergyStatistics';
import { getMockEnergyVirtualDevice } from './getMockEnergyVirtualDevice';

export const energyDateFormat = 'YYYY-MM-DD HH:mm';

export enum FrameSelectionOption {
    auto = 0,
    hourly = 1,
    daily = 2,
    monthly = 3,
    yearly = 4,
}

export interface ChartItem {
    ioid: number;
    date: string;
    value: string;
    isEnergy: boolean;
}

export interface EnergyConfig {
    startDate: string;
    endDate: string;
    selectedDateOption: DateSelectOption;
    selectedFrameOption: FrameSelectionOption;
}

export const initEnergyConfig: EnergyConfig = {
    startDate: dayjs().add(-1, 'd').format(energyDateFormat),
    endDate: dayjs().format(energyDateFormat),
    selectedDateOption: DateSelectOption.last24Hour,
    selectedFrameOption: FrameSelectionOption.auto,
};

const stats: EnergyStatistic = getEnergyStatistics().statistic;
const mappedDemoStats: EnergyStatistic = {
    ...stats,
    energyStats: stats.energyStats.map((x) => ({
        ...x,
        total: x.total ? x.total * 100 : 0,
        items: x.items.map((z) => ({ ...z, value: z.value * 100 })),
    })),
    powerStats: stats.powerStats.map((x) => ({
        ...x,
        total: x.total ? x.total * 100 : 0,
        items: x.items.map((z) => ({ ...z, value: z.value * 100 })),
    })),
    producersTotalEnergy: stats.producersTotalEnergy.map((z) => ({ ...z, value: z.value * 100 })),
    consumersTotalEnergy: stats.consumersTotalEnergy.map((z) => ({ ...z, value: z.value * 100 })),
};

const EnergyMonitorChartsPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { isDemo } = useContext(AuthContext);
    const { apiServerFetch } = useServerApi();
    const { onDatapointButtonClick, datapointLoading } = useDatapoint();
    const {
        currentUser,
        currentVirtualDevice: serverCurrentVirtualDevice,
        serverConfigLoading,
        currentServer,
        ios: serverIos,
        rooms: serverRooms,
        devices: serverDevices,
        info: serverInfo,
        users: serverUsers,
    } = useServerConfig();
    const { onGoBack } = useGoBack();

    const { ios, rooms, devices, info, users } = isDemo
        ? getEnergyBulk()
        : { ios: serverIos, rooms: serverRooms, devices: serverDevices, info: serverInfo, users: serverUsers };
    const currentVirtualDevice = isDemo ? getMockEnergyVirtualDevice() : serverCurrentVirtualDevice;

    const { value: configValue, updateCookie } = useCookie<EnergyConfig>('energy-config-old', initEnergyConfig);
    const config = configValue ?? initEnergyConfig;

    const isAdmin = isDemo || users?.find((x) => x.name === currentUser?.name)?.isadmin;

    const resetDatapoint = currentVirtualDevice?.datapoints?.find(
        (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualReset,
    );

    const [isLoading, setIsLoading] = useState(false);
    const [resetConfirmationVisible, setResetConfirmationVisible] = useState(false);
    const [energyStatistic, setEnergyStatistic] = useState<EnergyStatistic | undefined>(
        isDemo ? mappedDemoStats : undefined,
    );
    const [init, setInit] = useState(false);

    const { startDate, endDate, selectedDateOption, selectedFrameOption } = config;

    const [seconds, setSeconds] = useState(60);
    const [showCurrentDate, setShowCurrentDate] = useState(true);

    const updateConfig = (config: EnergyConfig) => {
        updateCookie(config, { expires: 365 });
    };

    const energyConfig = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type === DatapointType.CentralEnergyConfig)?.EnergyConfig,
        [currentVirtualDevice],
    );

    const iosConfig = useMemo(() => energyConfig?.IOConfigs, [energyConfig]);

    const flowChartIos = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter(
                (x) => ios?.filter((x) => energyConfig.SinglePower.includes(x.id))?.some((z) => z.id === x.Id),
            ) ?? [],
        [energyConfig],
    );

    const getName = (ioId: number) => {
        const connectedIos = ios?.find((x) => x.id === ioId);

        if (info?.starterkit) {
            const device = devices?.find((x) => x.id === connectedIos?.device);

            if (device) {
                const room = rooms?.find((x) => x.id === device.roomid);

                let deviceName = device.name;

                if (device.type === DeviceType.RadioSwitchDual || device.type === DeviceType.RadioSwitchDualPlus) {
                    if (connectedIos?.name?.includes('EI1')) {
                        deviceName += ' (EI1)';
                    } else if (connectedIos?.name?.includes('EI2')) {
                        deviceName += ' (EI2)';
                    } else if (connectedIos?.name?.includes('PI1')) {
                        deviceName += ' (PI1)';
                    } else if (connectedIos?.name?.includes('PI2')) {
                        deviceName += ' (PI2)';
                    }
                }

                if (room) {
                    return `${deviceName} - ${room.name}`;
                }
            }
        }

        return connectedIos?.name ?? '';
    };

    const energyIosConfig = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter(
                (x) =>
                    ios
                        ?.filter((x) => energyConfig.SingleEnergy.some((z) => z.ioId === x.id))
                        ?.some((z) => z.id === x.Id),
            ).filter((x) => x.HistoricalChart) ?? [],
        [energyConfig],
    );

    const powerIosConfig = useMemo(
        () =>
            energyConfig?.IOConfigs?.filter(
                (x) => ios?.filter((x) => energyConfig.SinglePower.includes(x.id))?.some((z) => z.id === x.Id),
            ).filter((x) => x.HistoricalChart) ?? [],
        [energyConfig],
    );

    useEffect(() => {
        if (!init || isDemo) {
            return;
        }

        getDataLogs();
    }, [iosConfig, config]);

    useEffect(() => {
        if (isDemo) {
            return;
        }
        setInit(true);
        updateConfig({
            ...config,
            ...getEnergyFiltersDates(config.selectedDateOption),
        });
    }, []);

    const getDataLogs = async () => {
        if (!iosConfig || iosConfig.length === 0 || !currentVirtualDevice) {
            return;
        }

        setIsLoading(true);

        const result = await apiServerFetch<{ statistic: EnergyStatistic }>('statistic', undefined, 'POST', {
            payload: {
                startDate: dayjs(startDate, energyDateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                endDate: dayjs(endDate, energyDateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                monitorObjectId: currentVirtualDevice.id,
                type: selectedFrameOption,
                maxCount: 60,
            },
        });

        if (!result.result?.statistic) {
            toast.error(t('errors.errorWhileSendingValue'));
            setIsLoading(false);
            return;
        }

        setEnergyStatistic(result.result.statistic);

        setIsLoading(false);
    };

    useEffect(() => {
        if (isDemo) {
            return;
        }
        const interval = setInterval(() => {
            setSeconds((p) => {
                if (p < 1) {
                    return 60;
                }
                return p - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (seconds === 0 && showCurrentDate && selectedDateOption !== DateSelectOption.custom) {
            updateConfig({
                ...config,
                ...getEnergyFiltersDates(config.selectedDateOption),
            });
        }
    }, [seconds]);

    const productionData = useMemo(() => {
        return energyStatistic?.producersTotalEnergy
            .map((x) => {
                const connectedIos = iosConfig?.find((io) => io.Id === x.ioid);
                return {
                    color: connectedIos?.Color ?? '',
                    name: getName(x.ioid),
                    value: Number((x.value / 1000).toFixed(4)),
                };
            })
            .filter((x) => x.value !== 0);
    }, [iosConfig, energyStatistic]);

    const consumptionData = useMemo(() => {
        return energyStatistic?.consumersTotalEnergy
            .map((x) => {
                const connectedIos = iosConfig?.find((io) => io.Id === x.ioid);
                return {
                    color: connectedIos?.Color ?? '',
                    name: getName(x.ioid),
                    value: Number((x.value / 1000).toFixed(4)),
                };
            })
            .filter((x) => x.value !== 0);
    }, [iosConfig, energyStatistic]);

    if (serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    return (
        <Styled.MainContainer>
            <Styled.Buttons>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
                {resetDatapoint && isAdmin && !isDemo && (
                    <ThemedButton
                        onClick={() => setResetConfirmationVisible(true)}
                        danger
                        style={{ width: 'fit-content', marginLeft: 'auto' }}
                        loading={datapointLoading === resetDatapoint.id}
                    >
                        {t('energyMonitor.reset')}
                    </ThemedButton>
                )}
            </Styled.Buttons>

            {currentVirtualDevice && flowChartIos.length > 0 && (
                <ReactFlowProvider>
                    <Flowchart virtualDevice={currentVirtualDevice} />
                </ReactFlowProvider>
            )}
            {currentVirtualDevice && flowChartIos.length > 0 && <Styled.Title />}
            <Space size={20} wrap>
                <EnergyFilters isLoading={isLoading} config={config} updateConfig={updateConfig} />
                <Styled.Row>
                    <ThemedSwitch
                        disabled={selectedDateOption === DateSelectOption.custom || isLoading || isDemo}
                        checked={showCurrentDate && selectedDateOption !== DateSelectOption.custom}
                        onChange={(e) => setShowCurrentDate(e)}
                    />
                    <Styled.CheckboxTitle>{t('general.showLatestData')}</Styled.CheckboxTitle>
                    {showCurrentDate && selectedDateOption !== DateSelectOption.custom && <div>{`${seconds}s`}</div>}
                </Styled.Row>
            </Space>
            {isLoading && (
                <Styled.SpinWrapper>
                    <ThemedSpin size="large" />
                </Styled.SpinWrapper>
            )}
            {powerIosConfig && powerIosConfig.length > 0 && (
                <>
                    <Styled.Title>
                        {t('energyManagement.powerHistoricalValues')}
                        <Tooltip title={t('energyManagement.valuesAbove')}>
                            <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer' }} />
                        </Tooltip>
                    </Styled.Title>
                </>
            )}
            {powerIosConfig &&
                powerIosConfig.length > 0 &&
                energyStatistic &&
                energyStatistic?.powerStats.length === 0 &&
                !isLoading && <Styled.NoDataError>{t('energyManagement.noDataForFrame')}</Styled.NoDataError>}
            {currentVirtualDevice &&
                powerIosConfig &&
                energyStatistic?.powerStats &&
                powerIosConfig?.length > 0 &&
                energyStatistic?.powerStats.length > 0 && (
                    <MainChart
                        virtualDevice={currentVirtualDevice}
                        stats={energyStatistic?.powerStats}
                        endDate={endDate}
                        frame={selectedFrameOption}
                        iosConfig={powerIosConfig}
                        isLoading={isLoading}
                        unit="kW"
                        downloadTitle={t('energyManagement.powerValues')}
                    />
                )}
            {energyIosConfig && energyIosConfig.length > 0 && (
                <Styled.Title>
                    {t('energyManagement.energyHistoricalValues')}
                    <Tooltip title={t('energyManagement.valuesAbove')}>
                        <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer' }} />
                    </Tooltip>
                </Styled.Title>
            )}
            {energyIosConfig &&
                energyIosConfig.length > 0 &&
                energyStatistic?.energyStats &&
                energyStatistic?.energyStats.length === 0 &&
                !isLoading && <Styled.NoDataError>{t('energyManagement.noDataForFrame')}</Styled.NoDataError>}
            {currentVirtualDevice &&
                energyIosConfig &&
                energyStatistic?.energyStats &&
                energyIosConfig?.length > 0 &&
                energyStatistic?.energyStats.length > 0 && (
                    <MainChart
                        virtualDevice={currentVirtualDevice}
                        stats={energyStatistic?.energyStats}
                        endDate={endDate}
                        frame={selectedFrameOption}
                        iosConfig={energyIosConfig}
                        isLoading={isLoading}
                        unit="kWh"
                        downloadTitle={t('energyManagement.energyValues')}
                    />
                )}
            {productionData && productionData.length > 0 && (
                <Styled.Title>{t('energyManagement.production')}</Styled.Title>
            )}
            {productionData && productionData.length > 0 && <CustomPie data={productionData} />}
            {consumptionData && consumptionData.length > 0 && (
                <Styled.Title>{t('energyManagement.consumption')}</Styled.Title>
            )}
            {consumptionData && consumptionData.length > 0 && <CustomPie data={consumptionData} />}
            {resetConfirmationVisible && resetDatapoint && (
                <YesNoModal
                    onYesClicked={async () => {
                        setResetConfirmationVisible(false);
                        await onDatapointButtonClick(resetDatapoint.id);
                        onGoBack();
                    }}
                    onNoClicked={() => setResetConfirmationVisible(false)}
                    description={t('energyManagement.resetWarning')}
                    yesDanger
                />
            )}
        </Styled.MainContainer>
    );
};

export default EnergyMonitorChartsPage;
