import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    gap: 10px;
`;
