import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const FavImage = styled.img`
    object-fit: scale-down;
    max-width: 25px;
    cursor: pointer;
    z-index: 2;
    position: relative;
`;

export const MainWrapper = styled.div`
    position: relative;
    padding: 5px;
    display: flex;
`;

export const RankingWrapper = styled.div`
    box-shadow: rgb(20 35 85 / 22%) 0px 2px 5px 0px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1} !important;
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 45px;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    gap: 5px;
`;

export const Circle = styled.div<{ $filled: boolean }>`
    width: 18px;
    height: 18px;
    border: 1px solid ${(props) => props.theme.colors.text};
    border-radius: 9px;
    cursor: pointer;
    background-color: ${(props) => (props.$filled ? props.theme.colors.text : 'transparent')};
`;

export const LoadingSpin = styled(ThemedSpin)`
    height: 35px;
    width: 35px;
`;
