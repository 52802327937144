import { Space } from 'antd';
import { ControlsSpace } from 'components/ServerControls/ServerControls.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const SettingWrapper = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 330px;
    font-size: 14px;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    width: 100%;
    font-size: 14px;
`;

export const TopWrapper = styled(ControlsSpace)`
    display: flex;
    gap: 20px;
`;

export const Row = styled(Space)`
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;

    @media (max-width: 750px) {
        flex-direction: column;
        gap: 8px;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
`;

export const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
