import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const RowWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const TitleWrapper = styled.div`
    flex: 1;
`;

export const SelectWrapper = styled.div`
    width: 100px;
`;

export const ValueWrapper = styled.div`
    width: 60px;
    text-align: end;
`;
