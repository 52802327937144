import { PreloadedImg } from 'pages/FloorplanPage/FloorplanPage';
import { createContext } from 'react';

export type FloorplanConfig = {
    floorplanImages: PreloadedImg[];
};

export const initConfig: FloorplanConfig = {
    floorplanImages: [],
};

export const FloorplanContext = createContext<FloorplanConfig>(initConfig);
