import { ThemedSwitch } from 'components/ThemedComponents/ThemedComponents.styles';
import { getBooleanValue } from 'helpers/DatapointHelper';
import { getVirtualDatapointNameTranslation } from 'helpers/StringHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import { useImages } from 'hooks/useImages';
import { Datapoint } from 'models/server/Datapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './DatapointSwitchView.styles';

type Props = {
    useCustomName?: boolean;
    isMainSwitch?: boolean;
    virtualDevice: VirtualDevice;
    datapoint: Datapoint;
    hideName?: boolean;
    disabled?: boolean;
};

const DatapointSwitchView = ({ datapoint, virtualDevice, useCustomName, hideName, isMainSwitch, disabled }: Props) => {
    const { t } = useTranslation();
    const { images, getCategorySettings } = useImages();
    const { datapointLoading, onDatapointValueChange } = useDatapoint();
    const category = useMemo(() => getCategorySettings(virtualDevice.category), [images, virtualDevice]);

    const name = useMemo(() => {
        if (isMainSwitch) {
            return t('general.mainSwitch');
        }

        if (useCustomName) {
            return datapoint.customname;
        }

        return getVirtualDatapointNameTranslation(datapoint.name);
    }, [datapoint, useCustomName, isMainSwitch]);

    const onNewValue = (v: boolean) => {
        onDatapointValueChange(datapoint.id, v);
    };

    if (hideName) {
        return (
            <ThemedSwitch
                onChange={onNewValue}
                checked={getBooleanValue(datapoint.value?.toString())}
                $color={category.color}
                loading={datapointLoading === datapoint.id}
                disabled={disabled}
            />
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.DpName>{name}</Styled.DpName>
            <ThemedSwitch
                onChange={onNewValue}
                checked={getBooleanValue(datapoint.value?.toString())}
                $color={category.color}
                loading={datapointLoading === datapoint.id}
                disabled={disabled}
            />
        </Styled.MainWrapper>
    );
};

export default DatapointSwitchView;
