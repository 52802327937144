import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SelectWrapper = styled.div`
    flex: 1;
    display: flex;
    gap: 10px;
`;
