import { Space, Tooltip } from 'antd';
import {
    ColorStateButton,
    ThemedInput,
    ThemedSwitch,
    ThemedTimePicker,
} from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { useImages } from 'hooks/useImages';
import { SwitchPoint } from 'models/server/SwitchPoint';
import { TimeProgram } from 'models/server/TimeProgram';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { timeFormat } from 'pages/SchedulerEditPage/SchedulerEditPage';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { useTheme } from 'styled-components';
import * as Styled from './TimeProgramView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    timeProgram: TimeProgram;
    currentError?: string;
    disabledDays: number[];
    onChangeName: (v: string) => void;
    onChangeDays: (v: number[]) => void;
    addSwitchPoint: () => SwitchPoint;
    removeSwithPoint: (i: number) => void;
    onChangeSwitchpointTime: (i: number, time: string) => void;
    onSwitchpointChanged: (switchpointIndex: number, switchpoint: SwitchPoint) => void;
};

const order = [1, 2, 3, 4, 5, 6, 0];

const TimeProgramView = ({
    timeProgram,
    virtualDevice,
    currentError,
    disabledDays,
    onChangeName,
    onChangeDays,
    addSwitchPoint,
    removeSwithPoint,
    onChangeSwitchpointTime,
    onSwitchpointChanged,
}: Props) => {
    const { t } = useTranslation();
    const { getCategorySettings } = useImages();
    const { colors } = useTheme();
    const { SwitchPoints } = timeProgram;

    const categorySettings = useMemo(() => getCategorySettings(virtualDevice.category), [virtualDevice]);

    const daysConfig: Record<number, string> = useMemo(
        () => ({
            1: t('timeProgramEdit.mo'),
            2: t('timeProgramEdit.tu'),
            3: t('timeProgramEdit.we'),
            4: t('timeProgramEdit.th'),
            5: t('timeProgramEdit.fr'),
            6: t('timeProgramEdit.sa'),
            0: t('timeProgramEdit.su'),
        }),
        [],
    );

    const onDayClicked = (day: number) => {
        if (timeProgram.days.includes(Number(day))) {
            onChangeDays(timeProgram.days.filter((x) => x !== day));
            return;
        }

        onChangeDays([...timeProgram.days, day]);
    };

    const addNewSwitchpoint = () => {
        addSwitchPoint();
    };

    const onRemoveSwithPoint = (index: number) => {
        removeSwithPoint(index);
    };

    const onValueChanged = (spIndex: number, value: boolean) => {
        onSwitchpointChanged(spIndex, { ...SwitchPoints[spIndex], Value: value });
    };

    return (
        <>
            <Styled.HeaderWrapper>
                <Tooltip open={!!currentError} title={currentError} color={colors.error}>
                    <ThemedInput value={timeProgram.Name} onChange={(v) => onChangeName(v.currentTarget.value)} />
                </Tooltip>
                <Space size={10}>
                    {Object.keys(daysConfig)
                        .sort((a, b) => order.indexOf(Number(a)) - order.indexOf(Number(b)))
                        .map((day) => (
                            <ColorStateButton
                                shape="round"
                                key={day}
                                $color={categorySettings.color}
                                $width={40}
                                $isActive={timeProgram.days.includes(Number(day))}
                                disabled={disabledDays.includes(Number(day))}
                                onClick={() => onDayClicked(Number(day))}
                            >
                                {daysConfig[Number(day)]}
                            </ColorStateButton>
                        ))}
                </Space>
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {SwitchPoints.map((sp, index) => (
                    <Styled.TimeThemedCheckableTag key={index}>
                        <Styled.IconWrapper>
                            {SwitchPoints.length > 1 && (
                                <IoMdCloseCircleOutline
                                    onClick={(e) => {
                                        onRemoveSwithPoint(index);
                                        e.stopPropagation();
                                    }}
                                    size={17}
                                />
                            )}
                        </Styled.IconWrapper>
                        <Styled.InputWrapper>
                            <ThemedTimePicker
                                value={dayjs(sp.Time, 'HH:mm')}
                                format={timeFormat}
                                changeOnBlur
                                allowClear={false}
                                onChange={(v) => v && onChangeSwitchpointTime(index, v.format(timeFormat))}
                            />
                        </Styled.InputWrapper>
                        <ThemedSwitch
                            $color={categorySettings.color}
                            checked={sp.Value}
                            onChange={(v) => onValueChanged(index, v)}
                        />
                    </Styled.TimeThemedCheckableTag>
                ))}
                <Styled.AddThemedCheckableTag checked={false} $dashed onClick={addNewSwitchpoint}>
                    <MdAdd fontSize={25} />
                </Styled.AddThemedCheckableTag>
            </Space>
        </>
    );
};

export default TimeProgramView;
