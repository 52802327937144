import { Form } from 'antd';
import { ThemedButton, ThemedInput, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { useApi } from 'hooks/useApi';
import License from 'models/License';
import { ItemTitle } from 'pages/Admin/AdminServersEditPage/AdminServerView/AddServerModal/AddServerModal.styles';
import { AddForm } from 'pages/Admin/AdminServersEditPage/AdminServerView/EditServerModal/EditServerModal.styles';
import { emailRegex } from 'pages/Admin/AdminUsersEditPage/InviteUserModal/InviteUserModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

enum FormFields {
    name = 'name',
    streetAddress = 'streetAddress',
    postalCode = 'postalCode',
    city = 'city',
    country = 'country',
    taxNumber = 'taxNumber',
    contactName = 'contactName',
    contactEmail = 'contactEmail',
}

interface Props {
    license?: License;
    onCancel: () => void;
    reload: () => void;
}

const AddEditLicenseModal = ({ license, onCancel, reload }: Props): JSX.Element => {
    const { t } = useTranslation();
    const { apiWithoutLicenseFetch } = useApi();

    const [loading, setLoading] = useState(false);

    const onAddLicense = async (props: {
        name: string;
        streetAddress: string;
        postalCode: string;
        city: string;
        country: string;
        taxNumber: string;
        contactName: string;
        contactEmail: string;
    }) => {
        setLoading(true);

        const result = await apiWithoutLicenseFetch('Licenses', license ? 'PATCH' : 'POST', undefined, {
            ...props,
            id: license?.id,
        });

        if (result.code !== 200) {
            setLoading(false);
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }

        await reload();
        onCancel();
    };

    return (
        <ThemedModal
            width={350}
            title={license ? t('general.editLicense') : t('general.addLicense')}
            open
            onCancel={onCancel}
            style={{ top: 50 }}
            footer={null}
        >
            <AddForm onFinish={onAddLicense} initialValues={license}>
                <div>
                    <ItemTitle>{`${t('general.licenseName')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.name}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.streetAddress')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.streetAddress}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.city')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.city}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.postalCode')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.postalCode}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.country')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.country}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.taxNumber')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.taxNumber}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.contactName')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.contactName}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <ItemTitle>{`${t('general.contactEmail')}:`}</ItemTitle>
                    <Form.Item
                        name={FormFields.contactEmail}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                            {
                                pattern: emailRegex,
                                message: t('errors.emailIsNotValid'),
                            },
                        ]}
                    >
                        <ThemedInput $version="2" />
                    </Form.Item>
                </div>
                <Footer>
                    <ThemedButton onClick={onCancel} $version="2" disabled={loading}>
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton htmlType="submit" $version="2" loading={loading}>
                        {license ? t('general.save') : t('general.add')}
                    </ThemedButton>
                </Footer>
            </AddForm>
        </ThemedModal>
    );
};

export default AddEditLicenseModal;
