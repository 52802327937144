import styled from 'styled-components';

export const MainWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
`;

export const ControlWrapper = styled.div`
    height: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.text};
    border-radius: 3px;
    padding: 5px 8px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 8px 0px;
    font-size: 11px;
    gap: 5px;

    .ant-slider {
        margin: 0 !important;
    }
`;

export const Title = styled.div`
    font-weight: bold;
    max-width: 80px;
    text-align: center;
`;

export const ObjectView = styled.div<{ $color: string; $backgroundFilled: boolean }>`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.$backgroundFilled ? props.$color : props.theme.colors.backgroundLayer1)};
    border: ${(props) => `2px solid ${props.$color}`};
`;

export const ObjectImage = styled.img`
    width: 24px;
    height: 24px;
`;

export const BlindsPosition = styled.div<{ $backgroundColor: string; $height: number }>`
    height: ${(props) => props.$height}px;
    background-color: ${(props) => props.$backgroundColor};
    top: 0;
    position: absolute;
    opacity: 0.6;
    width: 40px;
    border-radius: 5px;
`;

export const VirtualDeviceViewWrapper = styled.div`
    z-index: 998;
    position: absolute;
`;

export const ButtonImage = styled.img`
    object-fit: scale-down;
    max-width: 20px;
`;
