import { Form } from 'antd';
import loginBackgroundImg from 'images/loginBackgroundImg.jpg';
import logoImg from 'images/tem_smarthome_logo_light.png';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Styled from './WpcLoginPage.styles';
import useLocalAuth from 'hooks/useLocalAuth';
import { routes } from 'App';

enum FormFields {
    username = 'username',
    password = 'password',
}

const WpcLoginPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { authenticate } = useLocalAuth();

    const [isLoading, setIsLoading] = useState(false);

    const onServerLogin = async ({ username, password }: { username: string; password: string }) => {
        setIsLoading(true);

        const code = await authenticate({ username, password, save: false, serverName: '' });

        if (code === 200) {
            navigate(routes.home);
            return;
        }
        console.log(code);
        switch (code) {
            case 401:
                toast.error(t('errors.wrongLoginOrPassword'));
                break;
            default:
                toast.error(t('errors.connectionFailed'));
                break;
        }

        setIsLoading(false);
    };

    return (
        <Styled.MainWrapper>
            <ToastContainer style={{ zIndex: 999999998 }} />
            <Styled.BackgroundImage src={loginBackgroundImg} />
            <Styled.ContentWrapper>
                <Styled.LogoImage src={logoImg} />
                {isLoading ? (
                    <Styled.ButtonWrapper>
                        <Styled.ColorSpin size="large" />
                    </Styled.ButtonWrapper>
                ) : (
                    <Styled.ButtonWrapper>
                        <Styled.LoginForm form={form} onFinish={onServerLogin}>
                            <Styled.Hello>{`${t('loginPage.serverUsername')}:`}</Styled.Hello>
                            <Form.Item
                                name={FormFields.username}
                                rules={[
                                    {
                                        required: true,
                                        message: t('errors.fieldCannotBeEmpty'),
                                    },
                                ]}
                            >
                                <Styled.UsernameInput />
                            </Form.Item>
                            <Styled.Hello>{`${t('loginPage.serverPassword')}:`}</Styled.Hello>
                            <Form.Item
                                name={FormFields.password}
                                rules={[
                                    {
                                        required: true,
                                        message: t('errors.fieldCannotBeEmpty'),
                                    },
                                ]}
                            >
                                <Styled.PasswordInput />
                            </Form.Item>
                            <Styled.LoginButton type="primary" htmlType="submit">
                                {t('loginPage.login')}
                            </Styled.LoginButton>
                        </Styled.LoginForm>
                    </Styled.ButtonWrapper>
                )}
            </Styled.ContentWrapper>
        </Styled.MainWrapper>
    );
};

export default WpcLoginPage;
