import styled from 'styled-components';

export const MainWrapper = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

export const MainTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
`;
