import { CheckCircleOutlined, CloudDownloadOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import {
    ThemedButton,
    ThemedModal,
    ThemedProgress,
    ThemedSpin,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { lastWebsocketMessageState } from 'hooks/useSignalRConfig';
import { UpdateInfo } from 'models/server/UpdateInfo';
import { UpdateVersionInfo } from 'models/server/UpdateVersionInfo';
import { WebsocketCode } from 'models/server/enums/WebsocketCode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { Footer } from '../NetatmoValidator/NetatmoValidator.styles';
import * as Styled from './UpdateValidator.styles';

const updateDependencies = {
    check: 1,
    ci: {
        sid: '001',
        version: '2.44.0',
        name: 'mobileapp',
        dependencies: {
            radioserver: '>=2.16.0',
            smartserver: '>=2.16.0',
        },
    },
};

const UpdateValidator = (): JSX.Element => {
    const { t } = useTranslation();
    const { apiServerFetch } = useServerApi();
    const [lastWebsocketMessage] = useRecoilState(lastWebsocketMessageState);
    const { info, update, serverId, onRefreshConfig } = useServerConfig();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
    const [updateDetails, setUpdateDetails] = useState<UpdateVersionInfo>();
    const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [isReconnecting, setIsReconnecting] = useState(false);
    let interval: NodeJS.Timeout;

    useEffect(() => {
        if (update && !updateDetails) {
            if (update?.state?.includes('%')) {
                setIsLoading(false);
                setIsUpdateInProgress(true);
                setOpen(true);
                return;
            }

            getUpdateDetails();
        }
    }, [update]);

    useEffect(() => {
        if (updateDetails) {
            getUpdateDetails();
        }
    }, [serverId]);

    useEffect(() => {
        if (lastWebsocketMessage && lastWebsocketMessage.code === WebsocketCode.UpdateProcessStarted) {
            getUpdateInfo();
        }
    }, [lastWebsocketMessage]);

    const getUpdateDetails = async () => {
        setIsLoading(true);
        const info = await apiServerFetch<UpdateVersionInfo>('update', undefined, 'POST', updateDependencies);
        setUpdateDetails(info.result);

        if (info?.result?.summary?.available && info?.result?.summary?.url) {
            setIsUpdateAvailable(true);
            setOpen(true);
        } else {
            setIsUpdateAvailable(false);
        }

        setIsLoading(false);
    };

    const getUpdateInfo = async () => {
        const info = await apiServerFetch<UpdateInfo>('update');

        if (info.code !== 200 || !info.result) {
            if (isUpdateInProgress) {
                setIsReconnecting(true);
            }
            return;
        }

        if (isReconnecting) {
            await getUpdateDetails();
            await refreshServerInfo();
            setCurrentProgress(0);
            setIsUpdateInProgress(false);
            setIsReconnecting(false);
            return;
        }

        const state = info.result.state;

        if (state.includes('%')) {
            setOpen(true);
            setIsUpdateInProgress(true);

            if (state.includes('Prepare')) {
                setCurrentProgress(Number(state.replace('Prepare', '').replace(' ', '').replace('%', '')) + 100);
            } else if (state.includes('Download')) {
                setCurrentProgress(Number(state.replace('Download', '').replace(' ', '').replace('%', '')));
            }
        }
    };

    const onUpdate = async () => {
        if (!updateDetails?.summary?.url) {
            return;
        }
        const startUpdateResult = await apiServerFetch('update', undefined, 'POST', { url: updateDetails.summary.url });

        if (startUpdateResult.code !== 200) {
            toast.error(t('errors.errorWhileStartingUpdate'));
            return;
        }

        setIsUpdateInProgress(true);
    };

    useEffect(() => {
        function startTimer() {
            clearInterval(interval);
            interval = setInterval(() => {
                getUpdateInfo();
            }, 6000);
        }

        if (isReconnecting) {
            clearInterval(interval);
        }

        if (isUpdateInProgress) {
            startTimer();
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isUpdateInProgress, isReconnecting]);

    const refreshServerInfo = async () => {
        setIsLoading(true);
        await onRefreshConfig?.();
        setIsLoading(false);
    };

    return (
        <ThemedModal
            width="fit-content"
            title={t('general.serverUpdate')}
            open={open}
            style={{ top: 200 }}
            closable={false}
            maskClosable={false}
            footer={
                <Footer>
                    <ThemedButton disabled={isUpdateInProgress} $version="2" onClick={() => setOpen(false)}>
                        {t('general.close')}
                    </ThemedButton>
                </Footer>
            }
        >
            <Styled.MainContainer>
                {isLoading && !isUpdateInProgress && <ThemedSpin size="large" />}
                {isUpdateInProgress && (
                    <>
                        {!isReconnecting && (
                            <Styled.ProgressContainer>
                                <ThemedSpin size="large" />
                                <ThemedProgress percent={Math.floor(currentProgress / 2)} status="active" />
                            </Styled.ProgressContainer>
                        )}
                        {isReconnecting && (
                            <Styled.ProgressContainer>
                                <ThemedSpin size="large" />
                                <Styled.Progress>{t('updateModal.reconnecting')}</Styled.Progress>
                            </Styled.ProgressContainer>
                        )}
                    </>
                )}
                {!isLoading && !isUpdateInProgress && (
                    <>
                        {isUpdateAvailable && (
                            <>
                                {info?.sdcard && (
                                    <Styled.UpdateContainer>
                                        <CloudDownloadOutlined
                                            style={{ fontSize: 50, color: '#07bc0c', justifyContent: 'center' }}
                                        />
                                        <Styled.CurrentVersion>
                                            {`${t('updateModal.currentVersion')}: ` + info?.version}
                                        </Styled.CurrentVersion>
                                        <Styled.NewVersion>
                                            {`${t('updateModal.newVersion')}: ` +
                                                updateDetails?.sender?.latest?.version}
                                        </Styled.NewVersion>
                                        <Styled.UpdateButton onClick={onUpdate}>
                                            {t('updateModal.update')}
                                        </Styled.UpdateButton>
                                    </Styled.UpdateContainer>
                                )}
                                {!info?.sdcard && (
                                    <Styled.MissingSdCardContainer>
                                        <ExclamationCircleOutlined
                                            style={{ fontSize: 50, color: '#e74c3c', justifyContent: 'center' }}
                                        />
                                        <Styled.CurrentVersion>
                                            {`${t('updateModal.currentVersion')}: ` + info?.version}
                                        </Styled.CurrentVersion>
                                        <Styled.NewVersion>
                                            {`${t('updateModal.newVersion')}: ` +
                                                updateDetails?.sender?.latest?.version}
                                        </Styled.NewVersion>
                                        <Styled.MissingSdCard>
                                            <div>{t('updateModal.missingSdCard')}</div>
                                            <Styled.RefreshButton
                                                onClick={refreshServerInfo}
                                                shape="round"
                                                $width={40}
                                                icon={<SyncOutlined />}
                                            />
                                        </Styled.MissingSdCard>
                                    </Styled.MissingSdCardContainer>
                                )}
                            </>
                        )}
                        {!isUpdateAvailable && (
                            <Styled.UpdateUpToDateContainer>
                                <CheckCircleOutlined
                                    style={{ fontSize: 50, color: '#07bc0c', justifyContent: 'center' }}
                                />
                                <Styled.UpToDateVersion>{info?.version}</Styled.UpToDateVersion>
                                {t('updateModal.versionUpToDate')}
                            </Styled.UpdateUpToDateContainer>
                        )}
                    </>
                )}
            </Styled.MainContainer>
        </ThemedModal>
    );
};

export default UpdateValidator;
