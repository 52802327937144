import { routes } from 'App';
import EmptyError from 'components/EmptyError/EmptyError';
import { ControlsSpace } from 'components/ServerControls/ServerControls.styles';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { ServerConfigContext } from 'contexts/ServerConfigContext';
import { useApi } from 'hooks/useApi';
import DashboardConfig from 'models/DashboardConfig';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Styled from './DashboardServerSettings.styles';
import DraggableMonitorValues from './DraggableMonitorValues/DraggableMonitorValues';
import MonitorSelectionView from './MonitorSelectionView/MonitorSelectionView';

const DashboardServerSettings = () => {
    const { apiWithCustomLicenseFetch } = useApi();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { serverId } = useParams<{ serverId: string }>();
    const { dashboardConfigs, servers, onRefetchServers } = useContext(AuthContext);
    const { virtualDevices, loading: configLoading, setupInfo } = useContext(ServerConfigContext);

    const [loading, setLoading] = useState(false);

    const currentDashboardConfig = useMemo(
        () => dashboardConfigs.find((x) => x.serverId === Number(serverId)),
        [dashboardConfigs, serverId],
    );

    const [currentConfig, setCurrentConfig] = useState<DashboardConfig>({
        serverId: Number(serverId),
        monitorIos: currentDashboardConfig?.monitorIos ?? [],
        serialNumber: setupInfo?.info?.serialnumber ?? '',
        licenseId: servers.find((x) => x.id === Number(serverId))?.licenseId ?? 0,
    });

    const monitorVirtualDevices = useMemo(
        () => virtualDevices?.filter((x) => x.type === VirtualDeviceType.Monitor),
        [virtualDevices],
    );

    useEffect(() => {
        setCurrentConfig({
            serverId: Number(serverId),
            monitorIos: currentDashboardConfig?.monitorIos ?? [],
            serialNumber: setupInfo?.info?.serialnumber ?? '',
            licenseId: servers.find((x) => x.id === Number(serverId))?.licenseId ?? 0,
        });
    }, [serverId, setupInfo?.info?.serialnumber]);

    const onSave = async () => {
        setLoading(true);

        const currentServer = servers.find((x) => x.id === Number(serverId));

        if (!currentServer) {
            setLoading(false);
            return;
        }

        const result = await apiWithCustomLicenseFetch(currentServer.licenseId, 'User', 'PATCH', undefined, {
            id: currentServer.id,
            name: currentServer.name,
            configuration: {
                dashboard: [
                    ...dashboardConfigs
                        .filter((x) => x.licenseId === servers.find((x) => x.id === Number(serverId))?.licenseId ?? 0)
                        .filter((x) => x.serverId !== currentServer.id),
                    {
                        ...currentConfig,
                        monitorIos: currentConfig.monitorIos,
                    },
                ],
            },
        });

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            setLoading(false);
            return;
        }

        await onRefetchServers?.();
        setLoading(false);
        navigate(routes.home);
    };

    const onGoBack = () => {
        navigate(routes.home);
    };

    if (loading || configLoading) {
        return <ThemedSpin size="large" />;
    }

    if (monitorVirtualDevices.length === 0) {
        return (
            <EmptyError title={t('errors.noMonitorsToShow')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.Wrapper>
            <Styled.HeaderWrapper>
                <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                <ThemedButton $action onClick={onSave}>
                    {t('general.save')}
                </ThemedButton>
            </Styled.HeaderWrapper>
            <DraggableMonitorValues config={currentConfig} setCurrentConfig={setCurrentConfig} />
            <ControlsSpace wrap size={10}>
                {monitorVirtualDevices.map((vd) => (
                    <MonitorSelectionView
                        key={vd.id}
                        virtualDevice={vd}
                        currentConfig={currentConfig}
                        setCurrentConfig={setCurrentConfig}
                    />
                ))}
            </ControlsSpace>
        </Styled.Wrapper>
    );
};

export default DashboardServerSettings;
