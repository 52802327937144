import EmptyError from 'components/EmptyError/EmptyError';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
    const { t } = useTranslation();

    return <EmptyError title={t('errors.pageNotFound')} />;
};

export default PageNotFound;
