import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './Dimmer.styles';

const Dimmer = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const sliderDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Brightness && x.name == DatapointNames.VirtualSlider),
        [datapoints],
    );
    const mainSwitchDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) =>
                    x.type == DatapointType.Switch &&
                    (x.name == DatapointNames.VirtualSwitch || x.name == DatapointNames.VirtualEnable),
            ),
        [datapoints],
    );
    const valueDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) =>
                    (x.type == DatapointType.Brightness && x.name == DatapointNames.VirtualOutput) ||
                    (x.type == DatapointType.Number && x.name == DatapointNames.VirtualOutput),
            ),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {valueDatapoint && <DatapointValueView sufix="%" valueOrDp={valueDatapoint} />}
            <Styled.Row>
                {sliderDatapoint && (
                    <DatapointSliderView hideValueView virtualDevice={virtualDevice} datapoint={sliderDatapoint} />
                )}
                {mainSwitchDatapoint && (
                    <DatapointSwitchView hideName datapoint={mainSwitchDatapoint} virtualDevice={virtualDevice} />
                )}
            </Styled.Row>
        </Styled.MainWrapper>
    );
};

export default Dimmer;
