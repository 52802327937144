import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: -12px;
`;

export const NotificationWrapper = styled.div`
    width: 400px;
`;
