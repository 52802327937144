import { GroupedServerConfig } from 'components/ServerSelectRoute/ServerSelectBulkLoadingProvider/ServerSelectBulkLoadingProvider';
import { chartColors } from 'pages/DataloggerPage/DataloggerPage';
import { EnergyReportConfig, EnergyReportVDConfig } from 'pages/GroupedActions/EnergyReportPage/EnergyReportPage';
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

type Props = {
    data?: {
        name: string;
    }[];
    unit: string;
    config: EnergyReportConfig[];
    serverConfigs: GroupedServerConfig[];
    onSetVirtualDeviceConfig: (serverId: number, vdId: number, config: EnergyReportVDConfig) => void;
};

const EnergyGraphsChartView = ({ data = [], unit, config, serverConfigs, onSetVirtualDeviceConfig }: Props) => {
    const { colors } = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectBar = (e: any) => {
        const splited = (e.dataKey as string).split('-');
        const serverId = splited[0];
        const vdId = splited[1];

        const serverConfig = config.find((x) => x.server.id === Number(serverId));
        const vdConfig = serverConfig?.selectedVirtualDevices?.find((x) => x.id === Number(vdId));

        if (!vdConfig) {
            return;
        }

        onSetVirtualDeviceConfig(Number(serverId), Number(vdId), {
            ...vdConfig,
            hide: !vdConfig?.hide,
        });
    };

    return (
        <ResponsiveContainer height={400}>
            <ComposedChart data={data}>
                <CartesianGrid stroke={colors.text} />
                <XAxis dataKey="name" tick={{ fill: colors.text }} />
                <YAxis
                    width={100}
                    tickFormatter={(label) => {
                        return label + ` ${unit}`;
                    }}
                    tick={{ fill: colors.text }}
                />
                <Tooltip
                    formatter={(label) => label + ` ${unit}`}
                    contentStyle={{ backgroundColor: colors.backgroundLayer2 }}
                />
                <Legend onClick={selectBar} />
                <ReferenceLine y={0} stroke={colors.text} strokeDasharray="3 3" />
                {config
                    .flatMap(
                        (x) =>
                            x.selectedVirtualDevices?.map((z) => ({
                                ...z,
                                vd: serverConfigs
                                    .find((sc) => sc.server.id === x.server.id)
                                    ?.setupInfo?.objects?.items?.find((y) => y.id === z.id),
                                server: x.server,
                            })),
                    )
                    .filter((x) => !!x)
                    ?.map((x, index) =>
                        x ? (
                            <Line
                                dot={false}
                                key={`${x.server.id}-${x.id}`}
                                dataKey={`${x.server.id}-${x.id}`}
                                name={`${x.server.name} - ${x.vd?.name}`}
                                type="step"
                                stroke={chartColors[index > 19 ? Number((Math.random() * 19).toFixed(0)) : index]}
                                hide={x.hide}
                            />
                        ) : (
                            <></>
                        ),
                    )}
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default EnergyGraphsChartView;
