import { overflowXScrollbarStyle } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const Overflow = styled.div`
    overflow-x: auto;
    ${overflowXScrollbarStyle}
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;

    border: 1px solid #dbdbdb;
    border-radius: 4px;
`;

export const Header = styled.div`
    width: 120px;
    border-right: 1px solid #dbdbdb;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    white-space: break-spaces;
`;

export const LastHeader = styled.div`
    width: 120px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
`;
