import { Dispatch, SetStateAction, createContext } from 'react';

export type HeaderConfig = {
    notificationsVisible: boolean;
    nOfNotifications: number;
};

export const initHeaderConfig: HeaderConfig = {
    notificationsVisible: false,
    nOfNotifications: 0,
};

export const HeaderConfigContext = createContext<
    HeaderConfig & { setHeaderConfig?: Dispatch<SetStateAction<HeaderConfig>> }
>(initHeaderConfig);
