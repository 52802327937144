import ServerSelect from 'components/ServerSelect/ServerSelect';
import ServerSelectBulkLoadingProvider from './ServerSelectBulkLoadingProvider/ServerSelectBulkLoadingProvider';

const ServerSelectRoute = () => {
    return (
        <ServerSelect>
            {({ servers, changeButton, serversView }) => (
                <ServerSelectBulkLoadingProvider
                    servers={servers}
                    changeButton={changeButton}
                    serversView={serversView}
                />
            )}
        </ServerSelect>
    );
};

export default ServerSelectRoute;
