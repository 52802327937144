import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
`;

export const ButtonImage = styled.img`
    object-fit: scale-down;
    max-width: 30px;
`;
