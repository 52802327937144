import { Space } from 'antd';
import { ThemedCheckableTag, ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { useImages } from 'hooks/useImages';
import { useServerConfig } from 'hooks/useServerConfig';
import { ControlsSortingMode } from 'models/server/enums/ControlsSortingMode';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Filters } from '../ServerPage';
import * as Styled from './ServerPageTags.styles';

type Props = {
    selectedTags: Filters;
    setSelectedTags: (filters: Filters) => void;
    controlsSortingMode?: ControlsSortingMode;
    setControlsSortingMode?: (mode: ControlsSortingMode) => void;
};

const ServerPageTags = ({ selectedTags, setSelectedTags, controlsSortingMode, setControlsSortingMode }: Props) => {
    const { t } = useTranslation();
    const { virtualDevices, rooms } = useServerConfig();
    const { favorite_active, house, getRoomCategoryImage, getCategorySettings } = useImages();

    const categories = useMemo(() => {
        const duplicatedCategories = virtualDevices?.map((vd) => vd.category) ?? [];
        return duplicatedCategories
            .filter((c, index) => {
                return duplicatedCategories.indexOf(c) === index;
            })
            .sort((a, b) => a - b);
    }, [virtualDevices]);

    const allModes = useMemo(
        () => [
            {
                value: ControlsSortingMode.Ranking,
                label: t('mainControls.rankingSort'),
            },
            {
                value: ControlsSortingMode.TypeThanByRanking,
                label: t('mainControls.typeThanRankingSort'),
            },
        ],
        [t],
    );

    return (
        <Styled.MainWrapper>
            <Styled.Row>
                <Space size={[0, 8]} wrap>
                    <ThemedCheckableTag
                        onClick={() => setSelectedTags({ filter: 'all' })}
                        checked={selectedTags.filter === 'all'}
                    >
                        <Styled.TagWrapper>
                            <Styled.TagImg src={house} />
                            <Styled.TagTitle>{t('general.all')}</Styled.TagTitle>
                        </Styled.TagWrapper>
                    </ThemedCheckableTag>
                    <ThemedCheckableTag
                        onClick={() => setSelectedTags({ filter: 'favorites' })}
                        checked={selectedTags.filter === 'favorites'}
                    >
                        <Styled.TagWrapper>
                            <Styled.TagImg src={favorite_active} />
                            <Styled.TagTitle>{t('general.favorites')}</Styled.TagTitle>
                        </Styled.TagWrapper>
                    </ThemedCheckableTag>
                    {rooms?.map((room, index) => (
                        <ThemedCheckableTag
                            key={index}
                            onClick={() => setSelectedTags({ filter: 'room', id: room.id })}
                            checked={selectedTags.filter === 'room' && selectedTags.id === room.id}
                        >
                            <Styled.TagWrapper>
                                <Styled.TagImg src={getRoomCategoryImage(room.category)} />
                                <Styled.TagTitle>{room.name}</Styled.TagTitle>
                            </Styled.TagWrapper>
                        </ThemedCheckableTag>
                    ))}
                </Space>
                {controlsSortingMode !== undefined && setControlsSortingMode && (
                    <Styled.SelectWrapper>
                        <ThemedSelect
                            options={allModes}
                            value={controlsSortingMode}
                            onChange={(v) => setControlsSortingMode(v)}
                        />
                    </Styled.SelectWrapper>
                )}
            </Styled.Row>
            <Space size={[0, 8]} wrap>
                {categories?.map((category, index) => (
                    <Styled.CheckTag
                        $color={getCategorySettings(category).color}
                        key={index}
                        onClick={() => setSelectedTags({ filter: 'category', id: category })}
                        checked={selectedTags.filter === 'category' && selectedTags.id === category}
                    >
                        <Styled.CategoryTagWrapper>
                            <Styled.TagImg src={getCategorySettings(category).img} />
                        </Styled.CategoryTagWrapper>
                    </Styled.CheckTag>
                ))}
            </Space>
        </Styled.MainWrapper>
    );
};

export default ServerPageTags;
