import AddUserModal from 'components/AddUserModal/AddUserModal';
import ConfirmButton from 'components/ConfirmButton/ConfirmButton';
import EmptyError from 'components/EmptyError/EmptyError';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import SingleInputModal from 'components/SingleInputModal/SingleInputModal';
import {
    ThemedButton,
    ThemedCard,
    ThemedSelect,
    ThemedSpin,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { encryptRSA } from 'helpers/RSAHelper';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { isEqual } from 'lodash';
import { Assignment } from 'models/server/Assignment';
import { User } from 'models/server/User';
import { UserGroup } from 'models/server/UserGroup';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Styled from './UsersConfigurationPage.styles';

type Config = {
    assignments: Assignment[];
    userGroups: UserGroup[];
    fetchedAssignments: Assignment[];
    fetchedUserGroups: UserGroup[];
    loading: boolean;
};

const initConfig: Config = {
    assignments: [],
    userGroups: [],
    fetchedUserGroups: [],
    fetchedAssignments: [],
    loading: true,
};

const adminGroupId = 1;

const UsersConfigurationPage = () => {
    const { t } = useTranslation();
    const { currentServer, serverConfigLoading, users, currentUser, rooms, virtualDevices, onRefreshConfig } =
        useServerConfig();
    const { apiServerFetch } = useServerApi();

    const [config, setConfig] = useState<Config>(initConfig);
    const [isUpdating, setIsUpdating] = useState(false);

    const [addGroupVisible, setAddGroupVisible] = useState(false);
    const [groupToEdit, setGroupToEdit] = useState<UserGroup>();
    const [userToEdit, setUserToEdit] = useState<User>();
    const [userToPasswordEdit, setUserToPasswordEdit] = useState<User>();
    const [addUserVisible, setAddUserVisible] = useState(false);

    const init = async () => {
        setConfig(initConfig);

        const groups = await apiServerFetch<{ usergroups: UserGroup[] }>('usergroups');

        if (!groups.result?.usergroups) {
            setConfig((prev) => ({ ...prev, loading: false }));
            return;
        }

        const assignments = await apiServerFetch<{ assignments: Assignment[] }>('assignments');

        if (!assignments.result?.assignments) {
            setConfig((prev) => ({ ...prev, loading: false }));
            return;
        }

        const filledGroups = groups.result.usergroups.map((ug) => ({ ...ug, user_ids: ug.user_ids ?? [] }));
        const filledAssignements = assignments.result.assignments.map((ass) => ({
            ...ass,
            usergroupids: ass.usergroupids ?? [],
            userids: ass.userids ?? [],
        }));

        setConfig({
            userGroups: filledGroups,
            assignments: filledAssignements,
            fetchedUserGroups: filledGroups,
            fetchedAssignments: filledAssignements,
            loading: false,
        });
    };

    useEffect(() => {
        if (serverConfigLoading) {
            setConfig(initConfig);
            return;
        }

        init();
    }, [serverConfigLoading]);

    const getObjectById = (id: number): VirtualDevice | undefined => {
        return virtualDevices.find((x) => x.id === id);
    };

    const getRoomNameByObjectId = (id: number): string => {
        return rooms?.find((room) => room.id === getObjectById(id)?.roomid)?.name ?? t('general.unassigned');
    };

    const onUsersChanged = (ids: number[], group: UserGroup) => {
        setConfig((prev) => ({
            ...prev,
            userGroups: prev.userGroups.map((x) => (x.id === group.id ? { ...x, user_ids: ids } : x)),
        }));
    };

    const onAssignementChanged = (usersIds: number[], groupIds: number[], assignment: Assignment) => {
        setConfig((prev) => ({
            ...prev,
            assignments: prev.assignments.map((x) =>
                x.objectid === assignment.objectid ? { ...x, userids: usersIds, usergroupids: groupIds } : x,
            ),
        }));
    };

    const showError = () => {
        toast.error(t('errors.errorWhileSendingValue'));
    };

    const onRemoveUser = async (user: User) => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('users', undefined, 'DELETE', { users: [user] });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onRemoveUserGroup = async (userGroup: UserGroup) => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('usergroups', undefined, 'DELETE', { usergroups: [userGroup] });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onSaveUserGroups = async () => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('usergroups', undefined, 'PUT', { usergroups: config.userGroups });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditUserPassword = async (user: User, password: string) => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('users', undefined, 'PUT', {
                users: [{ ...user, password: await encryptRSA(password) }],
            });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
            setUserToPasswordEdit(undefined);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditUserName = async (user: User, userName: string) => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('users', undefined, 'PUT', { users: [{ ...user, name: userName }] });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
            setUserToEdit(undefined);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onEditGroupName = async (userGroup: UserGroup, groupName: string) => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('usergroups', undefined, 'PUT', {
                usergroups: [{ ...userGroup, name: groupName }],
            });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
            setGroupToEdit(undefined);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onAddGroup = async (groupName: string) => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('usergroups', undefined, 'POST', {
                usergroups: [
                    {
                        name: groupName,
                        deletable: false,
                        user_ids: [],
                        id: (config.userGroups?.length ?? 0) + 2,
                    },
                ],
            });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
            setAddGroupVisible(false);
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const saveAssignements = async () => {
        try {
            setIsUpdating(true);

            const result = await apiServerFetch('assignments', undefined, 'PUT', {
                assignments: config.assignments,
            });

            if (result.code !== 200) {
                showError();
                return;
            }

            await onRefreshConfig?.();
        } catch {
        } finally {
            setIsUpdating(false);
        }
    };

    const onAddFinished = async () => {
        setIsUpdating(true);
        setAddUserVisible(false);
        await onRefreshConfig?.();
        setIsUpdating(false);
    };

    if (serverConfigLoading || config.loading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!currentUser?.isadmin) {
        return <EmptyError title={t('errors.noPermission')} />;
    }

    return (
        <Styled.MainWrapper>
            <Styled.TopWrapper wrap>
                <ThemedCard checked={false} $width="100%">
                    <Styled.Column>
                        <Styled.Row>
                            <Styled.Title>{t('general.groups')}</Styled.Title>
                            <ThemedButton
                                onClick={() => setAddGroupVisible(true)}
                                disabled={isUpdating}
                                icon={<MdAdd />}
                            >
                                {t('general.add')}
                            </ThemedButton>
                            <ThemedButton
                                loading={isUpdating}
                                onClick={onSaveUserGroups}
                                $action
                                disabled={isEqual(config.fetchedUserGroups, config.userGroups)}
                            >
                                {t('general.save')}
                            </ThemedButton>
                        </Styled.Row>
                        <Line />
                        <Styled.ItemsWrapper>
                            {config.userGroups.map((group, index) => (
                                <Styled.Row wrap key={index}>
                                    {group.name}
                                    <ThemedSelect
                                        disabled={isUpdating}
                                        style={{ width: '100%' }}
                                        mode="multiple"
                                        showSearch={false}
                                        options={users?.map((x) => ({
                                            label: x.name,
                                            value: x.id,
                                            disabled:
                                                (group.id === 1 && x.id === 1) ||
                                                (group.id === 1 && x.name === currentUser?.name),
                                        }))}
                                        value={group.user_ids}
                                        onChange={(ids: number[]) => onUsersChanged(ids, group)}
                                    />
                                    {group.id !== adminGroupId && (
                                        <ThemedButton onClick={() => setGroupToEdit(group)} disabled={isUpdating}>
                                            {t('general.edit')}
                                        </ThemedButton>
                                    )}
                                    {group.id !== adminGroupId && (
                                        <ConfirmButton
                                            loading={isUpdating}
                                            danger
                                            onClick={() => onRemoveUserGroup(group)}
                                            yesNoModalProps={{
                                                description: `${t('usersConfiguration.removeUsergroup')} ${
                                                    group.name
                                                }?`,
                                                yesDanger: true,
                                                loading: isUpdating,
                                            }}
                                        >
                                            {t('general.remove')}
                                        </ConfirmButton>
                                    )}
                                </Styled.Row>
                            ))}
                        </Styled.ItemsWrapper>
                    </Styled.Column>
                </ThemedCard>
                <ThemedCard checked={false}>
                    <Styled.Row>
                        <Styled.Title>{t('general.users')}</Styled.Title>
                        <ThemedButton onClick={() => setAddUserVisible(true)} disabled={isUpdating} icon={<MdAdd />}>
                            {t('general.add')}
                        </ThemedButton>
                    </Styled.Row>
                    <Line />
                    <Styled.ItemsWrapper>
                        {users.map((user, index) => (
                            <Styled.Row wrap key={index}>
                                {user.name}
                                {user.id !== adminGroupId && (
                                    <Styled.Buttons>
                                        <ThemedButton
                                            onClick={() => setUserToEdit(user)}
                                            loading={isUpdating}
                                            disabled={user.name === currentUser?.name}
                                        >
                                            {t('general.edit')}
                                        </ThemedButton>
                                        <ThemedButton
                                            onClick={() => setUserToPasswordEdit(user)}
                                            loading={isUpdating}
                                            disabled={user.name === currentUser?.name}
                                        >
                                            {t('general.changePassword')}
                                        </ThemedButton>
                                        <ConfirmButton
                                            disabled={!user.deletable || user.name === currentUser?.name}
                                            onClick={() => onRemoveUser(user)}
                                            loading={isUpdating}
                                            danger
                                            yesNoModalProps={{
                                                description: `${t('usersConfiguration.removeUser')} ${user.name}?`,
                                                yesDanger: true,
                                                loading: isUpdating,
                                            }}
                                        >
                                            {t('general.remove')}
                                        </ConfirmButton>
                                    </Styled.Buttons>
                                )}
                            </Styled.Row>
                        ))}
                    </Styled.ItemsWrapper>
                </ThemedCard>
            </Styled.TopWrapper>
            <ThemedCard checked={false} $width="100%">
                <Styled.Row>
                    <Styled.Title>{t('general.functionsAssignement')}</Styled.Title>
                    <ThemedButton
                        loading={isUpdating}
                        onClick={saveAssignements}
                        $action
                        disabled={isEqual(config.fetchedAssignments, config.assignments)}
                    >
                        {t('general.save')}
                    </ThemedButton>
                </Styled.Row>
                <Line />
                <Styled.ItemsWrapper>
                    {config.assignments.map((assignement, index) => (
                        <Styled.Row wrap key={index}>
                            {`${assignement.objectname} | ${getRoomNameByObjectId(assignement.objectid)}`}
                            <ThemedSelect
                                disabled={isUpdating}
                                style={{ width: '100%' }}
                                mode="multiple"
                                showSearch={false}
                                options={users.map((x) => ({
                                    label: x.name,
                                    value: x.id,
                                }))}
                                value={assignement.userids}
                                onChange={(ids) => onAssignementChanged(ids, assignement.usergroupids, assignement)}
                            />
                            <ThemedSelect
                                disabled={isUpdating}
                                style={{ width: '100%' }}
                                mode="multiple"
                                showSearch={false}
                                options={config.userGroups.map((x) => ({
                                    label: x.name,
                                    value: x.id,
                                    disabled: x.id === adminGroupId,
                                }))}
                                value={assignement.usergroupids}
                                onChange={(ids: number[]) =>
                                    onAssignementChanged(assignement.userids, ids, assignement)
                                }
                            />
                            <Styled.Buttons>
                                <ThemedButton
                                    loading={isUpdating}
                                    onClick={() =>
                                        onAssignementChanged(
                                            users?.map((x) => x.id),
                                            config.userGroups?.map((x) => x.id),
                                            assignement,
                                        )
                                    }
                                >
                                    {t('general.assigneToAll')}
                                </ThemedButton>
                            </Styled.Buttons>
                        </Styled.Row>
                    ))}
                </Styled.ItemsWrapper>
            </ThemedCard>
            {userToPasswordEdit && (
                <SingleInputModal
                    isLoading={isUpdating}
                    isVisible={true}
                    isPassword={true}
                    title={t('general.changePassword')}
                    placeholder={t('usersConfiguration.userPassword')}
                    onCancelClicked={() => setUserToPasswordEdit(undefined)}
                    onAddClicked={(value) => onEditUserPassword(userToPasswordEdit, value)}
                    yesButtonText={t('general.save')}
                />
            )}
            {userToEdit && (
                <SingleInputModal
                    isLoading={isUpdating}
                    isVisible={true}
                    doNameValidation={true}
                    defaultValue={userToEdit.name}
                    title={t('usersConfiguration.editUser')}
                    placeholder={t('usersConfiguration.userName')}
                    valueOtherThan={users?.map((x) => x.name).filter((x) => x !== userToEdit?.name)}
                    onCancelClicked={() => setUserToEdit(undefined)}
                    onAddClicked={(value) => onEditUserName(userToEdit, value)}
                    yesButtonText={t('general.save')}
                />
            )}
            {groupToEdit && (
                <SingleInputModal
                    isLoading={isUpdating}
                    isVisible={true}
                    doNameValidation={true}
                    defaultValue={groupToEdit.name}
                    title={t('usersConfiguration.editUsergroup')}
                    placeholder={t('usersConfiguration.usergroupName')}
                    valueOtherThan={config.userGroups.map((x) => x.name).filter((x) => x !== groupToEdit?.name)}
                    onCancelClicked={() => setGroupToEdit(undefined)}
                    onAddClicked={(value) => onEditGroupName(groupToEdit, value)}
                    yesButtonText={t('general.save')}
                />
            )}
            {addGroupVisible && (
                <SingleInputModal
                    isLoading={isUpdating}
                    isVisible={true}
                    doNameValidation={true}
                    title={t('usersConfiguration.addUsergroup')}
                    placeholder={t('usersConfiguration.usergroupName')}
                    valueOtherThan={config.userGroups.map((x) => x.name)}
                    onCancelClicked={() => setAddGroupVisible(false)}
                    onAddClicked={onAddGroup}
                />
            )}
            {addUserVisible && (
                <AddUserModal users={users} onFinished={onAddFinished} onCancel={() => setAddUserVisible(false)} />
            )}
        </Styled.MainWrapper>
    );
};

export default UsersConfigurationPage;
