export const getChartDemoData = () => [
    {
        data: 5,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 32,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 41,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 39,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 42,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 35,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 31,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 22,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 88,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 89,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 89,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 89,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 89,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
    {
        data: 33,
        category: '(10) Living_room Timer switch Output',
        unit: '',
    },
];
