import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { HeaderConfig, HeaderConfigContext, initHeaderConfig } from 'contexts/HeaderContext';
import { scrollParam } from 'pages/ServerPage/ServerPage';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Header from './Header/Header';
import * as Styled from './PageLayout.styles';
import SideMenu from './SideMenu/SideMenu';

export type ThemeType = 'light' | 'dark';

const darkTheme = {
    colors: {
        backgroundLayer0: '#373737',
        backgroundLayer1: '#555555',
        backgroundLayer2: '#6E6E6E',
        controlBackgroundLayer0: '#464646',
        controlBackgroundLayer1: '#6E6E6E',
        controlBackgroundLayer2: '#7D7D7D',
        titleText: '#FFFFFF',
        text: '#E2E2E2',
        error: '#e74c3c',
        success: '#07bc0c',
        pending: '#F7CB73',
        virtualDevicesBackground: '#555555',
        virtualDeviceButtonBackground: '#6E6E6E',
    },
    theme: 'dark',
};

const lightTheme = {
    colors: {
        backgroundLayer0: '#E6E6E6',
        backgroundLayer1: '#d6d6d6',
        backgroundLayer2: '#c9c9c9',
        controlBackgroundLayer0: '#BEBEBE',
        controlBackgroundLayer1: '#C8C8C8',
        controlBackgroundLayer2: '#F0F0F0',
        titleText: '#505050',
        text: '#505050',
        error: '#e74c3c',
        success: '#07bc0c',
        pending: '#F7CB73',
        virtualDevicesBackground: '#F0F0F0',
        virtualDeviceButtonBackground: '#E6E6E6',
    },
    theme: 'light',
};

const PageLayout = () => {
    const { theme, isLoading } = useContext(AuthContext);
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const ref = useRef<HTMLDivElement>(null);

    const [headerConfig, setHeaderConfig] = useState<HeaderConfig>(initHeaderConfig);

    useLayoutEffect(() => {
        if (searchParams.get(scrollParam) !== null) {
            return;
        }

        ref?.current?.scrollTo(0, 0);
    }, [pathname]);

    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <HeaderConfigContext.Provider value={{ ...headerConfig, setHeaderConfig: setHeaderConfig }}>
                <Styled.MainLayout>
                    <SideMenu />
                    <Styled.ContentLayout ref={ref}>
                        <Header />
                        <Styled.MainContent>{isLoading ? <ThemedSpin size="large" /> : <Outlet />}</Styled.MainContent>
                        <Styled.MainFooter>{`Copyright © ${new Date().getFullYear()} by TEM AG. All rights reserved.`}</Styled.MainFooter>
                    </Styled.ContentLayout>
                </Styled.MainLayout>
            </HeaderConfigContext.Provider>
        </ThemeProvider>
    );
};

export default PageLayout;
