import { ioDashboardValueState } from 'pages/HomePage/ServerView/ServerView';
import { useContext, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useServerConfig } from './useServerConfig';
import { ServerContext } from 'contexts/ServerContext';

const useIoValues = () => {
    const { info } = useServerConfig();
    const { serverSerialNumber } = useContext(ServerContext);
    const [dashboardIoValues] = useRecoilState(ioDashboardValueState);

    const serverIosValues = useMemo(
        () => dashboardIoValues.filter((x) => x.serialNumber === (info?.serialnumber ?? serverSerialNumber)),
        [info, dashboardIoValues],
    );

    return { serverIosValues };
};

export default useIoValues;
