import { ThemedCard } from 'components/ThemedComponents/ThemedComponents.styles';
import styled, { keyframes } from 'styled-components';

const shakeAnimation = keyframes`
    0% { transform: rotate(0deg); }
    25% { transform: rotate(1deg); }
    50% { transform: rotate(0eg); }
    75% { transform: rotate(-1deg); }
    100% { transform: rotate(0deg); }
  `;

export const Wrapper = styled(ThemedCard)<{ draggable?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    cursor: ${(props) => (props.draggable ? '-webkit-grab' : 'normal')};
    animation-name: ${(props) => (props.draggable ? shakeAnimation : undefined)};
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
`;

export const Value = styled.div`
    font-weight: bold;
    font-size: 16px;
`;

export const Title = styled.div`
    width: 80px;
    text-align: center;
    overflow-wrap: break-word;
    white-space: normal;
    line-height: 1.2;
`;
