import EmptyError from 'components/EmptyError/EmptyError';
import { ControlsSpace } from 'components/ServerControls/ServerControls.styles';
import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { ServerConfigContext } from 'contexts/ServerConfigContext';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { PropertyTemplate } from 'models/server/PropertyTemplate';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { Filters } from 'pages/ServerPage/ServerPage';
import ServerPageTags from 'pages/ServerPage/ServerPageTags/ServerPageTags';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './AdvancedPropertiesEditPage.styles';
import AdvancedVirtualDeviceProperty from './AdvancedVirtualDeviceProperty/AdvancedVirtualDeviceProperty';

const AdvancedPropertiesEditPage = () => {
    const { t } = useTranslation();
    const { onRefetchOne } = useContext(ServerConfigContext);
    const { currentServer, serverConfigLoading, virtualDevices, serverId } = useServerConfig();
    const { apiServerFetch } = useServerApi();

    const [filters, setFilters] = useState<Filters>({ filter: 'all' });
    const [propertyTemplates, setPropertyTemplates] = useState<PropertyTemplate[]>();
    const [isLoading, setIsLoading] = useState(false);

    const getAdvancedProperties = async () => {
        setIsLoading(true);

        await onRefetchOne?.(serverId);

        const result = await apiServerFetch<{ propertytemplates: PropertyTemplate[] }>('propertytemplates');

        if (!result.result) {
            setIsLoading(false);
            return;
        }

        setPropertyTemplates(result.result?.propertytemplates);
        setIsLoading(false);
    };

    useEffect(() => {
        getAdvancedProperties();
    }, []);

    const visibleVirtualDevices = useMemo(() => {
        let vdToReturn: VirtualDevice[] = virtualDevices.filter((x) => x.properties.length > 0);

        if (filters.filter === 'favorites') {
            vdToReturn = vdToReturn.filter((vd) => vd.favourite);
        }

        if (filters.filter === 'room') {
            vdToReturn = vdToReturn.filter((vd) => vd.roomid === filters.id);
        }

        if (filters.filter === 'category') {
            vdToReturn = vdToReturn.filter((vd) => vd.category === filters.id);
        }

        return vdToReturn.sort((n1, n2) => n2.properties.length - n1.properties.length);
    }, [virtualDevices, filters]);

    if (serverConfigLoading || isLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (virtualDevices.length === 0 || !propertyTemplates || propertyTemplates.length === 0) {
        return <EmptyError title={t('errors.noAdvancedProperitesToChange')} />;
    }

    return (
        <Styled.MainWrapper>
            <ServerPageTags selectedTags={filters} setSelectedTags={(v) => setFilters(v)} />
            <ControlsSpace size={20} wrap>
                {visibleVirtualDevices.map((virtualDevice) => (
                    <AdvancedVirtualDeviceProperty
                        key={virtualDevice.id}
                        virtualDevice={virtualDevice}
                        propertyTemplates={propertyTemplates}
                    />
                ))}
            </ControlsSpace>
            {visibleVirtualDevices.length === 0 && <EmptyError title={t('errors.noAdvancedProperitesToChange')} />}
        </Styled.MainWrapper>
    );
};

export default AdvancedPropertiesEditPage;
