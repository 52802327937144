import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { isEmpty } from 'helpers/StringHelper';
import { Datapoint } from 'models/server/Datapoint';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import HidingView from '../HidingView/HidingView';
import * as Styled from './Battery.styles';

const Battery = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { t } = useTranslation();
    const { datapoints, category } = virtualDevice;

    const lowBatteryLimitDatapoint = useMemo(
        () =>
            datapoints?.find((x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualLowBatteryLimit),
        [datapoints],
    );
    const valuesDatapoints = useMemo(
        () => datapoints?.filter((x) => x.type == DatapointType.Number && x.writeprotect),
        [datapoints],
    );

    const validate = (dp: Datapoint) => {
        if (isEmpty(dp.value.toString())) {
            return t('general.noData');
        }

        if (Number(dp.value) > 100) {
            return t('general.changeBattery');
        }
    };

    return (
        <Styled.MainWrapper>
            {lowBatteryLimitDatapoint && (
                <DatapointSliderView datapoint={lowBatteryLimitDatapoint} virtualDevice={virtualDevice} />
            )}
            <HidingView category={category} numberOfVisible={2}>
                {valuesDatapoints?.map((dp, index) => (
                    <DatapointValueView validateValue={validate} valueOrDp={dp} key={index} sufix="%" />
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default Battery;
