import styled from 'styled-components';

export const Error = styled.div`
    margin-left: 5px;
    color: red;
    font-size: 11px;
    font-weight: 500;
`;

export const Space = styled.div`
    height: 8px;
`;

export const Title = styled.div`
    margin-left: 5px;
`;
