import { Dispatch, SetStateAction } from 'react';
import { CreateView } from '../CreateWpcProjectPage';

import { routes } from 'App';
import { Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { ThemedButton, ThemedCheckboxGroup } from 'components/ThemedComponents/ThemedComponents.styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Styled from './WpcSchemeSelection.styles';
import ElementsScheme from '../WpcSchemeEdit/ElementsScheme/ElementsScheme';
import { SchemeConfig } from '../WpcSchemeEdit/WpcSchemeEdit';

type Props = {
    schemesToSelect: (SchemeConfig & { title: string })[];
    selectedScheme?: number;
    onSelectScheme: (scheme: number) => void;
    onSetCurrentView: Dispatch<SetStateAction<CreateView>>;
};

const WpcSchemeSelection = ({ selectedScheme, schemesToSelect, onSelectScheme, onSetCurrentView }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate(routes.home);
    };

    const handleNext = () => {
        onSetCurrentView('schemeEdit');
    };

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Styled.Title>{t('wpc.selectScheme')}</Styled.Title>
                <Styled.Buttons>
                    <ThemedButton onClick={handleCancel}>{t('general.cancel')}</ThemedButton>
                    <ThemedButton $action disabled={selectedScheme === undefined} onClick={handleNext}>
                        {t('general.next')}
                    </ThemedButton>
                </Styled.Buttons>
            </Styled.Header>
            <Space size={10} wrap>
                {schemesToSelect.map(({ elements, title }, index) => (
                    <Styled.ImageWrapper
                        key={index}
                        checked={selectedScheme === index}
                        onClick={() => onSelectScheme(index)}
                    >
                        <Styled.ImageHeader>
                            <Styled.Title>{title}</Styled.Title>
                            <ThemedCheckboxGroup
                                value={[selectedScheme === index ? 'selected' : undefined] as CheckboxValueType[]}
                                options={[{ value: 'selected', label: '' }]}
                            />
                        </Styled.ImageHeader>
                        <ElementsScheme elements={elements} />
                    </Styled.ImageWrapper>
                ))}
            </Space>
        </Styled.Wrapper>
    );
};

export default WpcSchemeSelection;
