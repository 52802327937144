import { HubConnectionState } from '@microsoft/signalr';
import { GroupedServerConfig } from 'components/ServerSelectRoute/ServerSelectBulkLoadingProvider/ServerSelectBulkLoadingProvider';
import { getDemoBulk } from 'components/demo/getDemoBulk';
import { AuthContext } from 'contexts/AuthContext';
import { updateDatapointWebsocket } from 'helpers/DatapointHelper';
import Server from 'models/Server';
import { Datapoint } from 'models/server/Datapoint';
import { SetupInfo } from 'models/server/SetupInfo';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { WebsocketMessage } from 'models/server/WebsocketMessage';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { ioDashboardValueState } from 'pages/HomePage/ServerView/ServerView';
import { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { atom, useSetRecoilState } from 'recoil';
import { useServerApi } from './useServerApi';
import useSignalR from './useSignalR';
import useStateRef from './useStateRef';

export const lastWebsocketMessageState = atom<WebsocketMessage | undefined>({
    key: 'lastWebsocketMessageState',
    default: undefined,
});

export type VirtualDeviceWebsocketChange = { favourite: boolean; objId: number; ranking: number };

const useSignalRConfig = () => {
    const { servers: userServers, isDemo, dashboardConfigs } = useContext(AuthContext);
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const { serverId } = useParams<{ serverId: string }>();
    const setDashboardIoValues = useSetRecoilState(ioDashboardValueState);

    const [serversConfig, setServersConfig, serversConfigRef] = useStateRef<GroupedServerConfig[]>(
        userServers.map((x) => ({ server: x, loading: true, error: false })),
    );

    const currentServerConfig = useMemo(
        () => serversConfig?.find((x) => x.server.id === Number(serverId)),
        [serversConfig, serverId],
    );

    const onSetDatapoint = (serverId: string, newDp: Datapoint) => {
        const currentServer = serversConfigRef.current?.find((x) => x.setupInfo?.info.serialnumber === serverId);

        if (!currentServer) {
            return;
        }

        setServersConfig((prev) =>
            prev.map((s) => {
                if (!s.setupInfo || s.setupInfo.info.serialnumber !== serverId) {
                    return s;
                }

                const newItems = s.setupInfo.objects.items.map((vd) => {
                    if (!vd.datapoints.some((x) => x.id === newDp.id)) {
                        return vd;
                    }

                    return {
                        ...vd,
                        datapoints: vd.datapoints.map((dp) => (dp.id === newDp.id ? newDp : dp)),
                    };
                });

                return {
                    ...s,
                    setupInfo: {
                        ...s.setupInfo,
                        objects: {
                            more: false,
                            items: newItems,
                        },
                    },
                };
            }),
        );
    };

    const onVirtualDeviceChanged = (serverId: string, { objId, favourite, ranking }: VirtualDeviceWebsocketChange) => {
        setServersConfig((prev) =>
            prev.map((x) =>
                x.setupInfo && x.setupInfo.info.serialnumber === serverId
                    ? {
                          ...x,
                          setupInfo: {
                              ...x.setupInfo,
                              objects: {
                                  items: x.setupInfo.objects.items.map((vd) =>
                                      vd.id === objId ? { ...vd, ranking: ranking, favourite: favourite } : vd,
                                  ),
                                  more: false,
                              },
                          },
                      }
                    : x,
            ),
        );
    };

    const onNewMessage = (serverId: string, message: WebsocketMessage) => {
        switch (message.header) {
            case 'deviceupdate':
            case 'deviceresponse':
                if (message.ioid && message.value) {
                    const newValue = message.value as {
                        value: string | number | boolean;
                    };

                    setDashboardIoValues((prev) => {
                        if (prev.some((x) => x.serialNumber === serverId && x.ioId === message.ioid)) {
                            return prev.map((x) =>
                                x.serialNumber === serverId && x.ioId === message.ioid
                                    ? { ...x, value: newValue?.value?.toString() }
                                    : x,
                            );
                        }

                        return [
                            ...prev,
                            { serialNumber: serverId, ioId: message.ioid ?? 0, value: newValue?.value?.toString() },
                        ];
                    });
                }
        }

        const serverConfig = serversConfigRef?.current?.find((x) => x.setupInfo?.info?.serialnumber === serverId);
        if (!serverConfig) {
            return;
        }

        switch (message.header) {
            case 'dpupdate': {
                const newDp = updateDatapointWebsocket(
                    serverConfig.setupInfo?.objects?.items
                        ?.flatMap((x) => x.datapoints)
                        ?.find((z) => z.id === message.id),
                    message,
                );

                if (!newDp) {
                    return;
                }

                onSetDatapoint(serverId, newDp);
                break;
            }
            case 'dpconfigupdate': {
                const newDp = updateDatapointWebsocket(
                    serverConfig.setupInfo?.objects?.items
                        ?.flatMap((x) => x.datapoints)
                        ?.find((z) => z.id === message.id),
                    message,
                );

                if (!newDp) {
                    return;
                }

                onSetDatapoint(serverId, newDp);
                break;
            }
            case 'configupdate':
                if (message?.payload?.access) {
                    if (message.api == 'objects') {
                        const object: VirtualDeviceWebsocketChange = message?.payload?.access?.[0];
                        onVirtualDeviceChanged(serverId, object);
                    }
                }
                break;
        }
    };

    const { connectionRef, connection, connectSignalR } = useSignalR({
        serverIds: userServers.map((x) => x.id),
        onNewMessage,
    });

    const connect = async () => {
        const connection = await connectSignalR();

        if (!connection) {
            return;
        }

        dashboardConfigs.forEach((element) => {
            const req = JSON.stringify({
                header: 'devicerequest',
                cmd: 'GET',
                cache: true,
                observe: true,
                ios: element.monitorIos.map((x) => x.ioId),
            });
            connection.send('SendMessageToServerWithServerId', req, '', '', element.serverId);
        });
    };

    useEffect(() => {
        if (isDemo) {
            return;
        }

        connect();
    }, []);

    useEffect(() => {
        if (isDemo && currentServerConfig && !currentServerConfig.setupInfo) {
            setServerConfig({ ...currentServerConfig, setupInfo: getDemoBulk(), loading: false });
            return;
        }
    }, [isDemo, currentServerConfig]);

    useEffect(() => {
        if (connection?.state === HubConnectionState.Connected && !currentServerConfig?.setupInfo) {
            onRefetch();
        }
    }, [connection?.state, serverId]);

    const subscribeIos = async (config: GroupedServerConfig) => {
        try {
            if (!connectionRef.current || !config.setupInfo) {
                return;
            }

            const ios: number[] = [];

            config.setupInfo.objects.items
                .filter((x) => x.type == VirtualDeviceType.Monitor)
                .forEach((x) => {
                    x.datapoints
                        .find((y) => y.type == DatapointType.MonitorConfig)
                        ?.Monitor?.forEach((z) => ios.push(z.IOid));
                });
            config.setupInfo.objects.items
                .filter((x) => x.type == VirtualDeviceType.TotalEnergyMonitor)
                .forEach(
                    (x) =>
                        x.datapoints
                            .find((y) => y.type == DatapointType.CentralEnergyConfig)
                            ?.EnergyConfig?.IOConfigs?.forEach((z) => ios.push(z.Id)),
                );

            const req = JSON.stringify({
                header: 'devicerequest',
                cmd: 'GET',
                cache: true,
                observe: true,
                ios: ios.filter((io, index, self) => index === self.indexOf(io)),
            });

            await connectionRef.current.send('SendMessageToServerWithServerId', req, '', '', config.server.id);
        } catch (ex) {
            if (process.env.NODE_ENV === 'development') {
                console.log(ex);
            }
        }
    };

    const setServerConfig = (config: GroupedServerConfig) => {
        setServersConfig((prev) => {
            if (!prev.some((x) => x.server.id === config.server.id)) {
                return [...prev, config];
            }

            return prev.map((x) => (x.server.id === config.server.id ? config : x));
        });
    };

    const getStats = async (server: Server) => {
        const currentServerConfig = serversConfig.find((x) => x.server.id === server.id);

        const config = currentServerConfig
            ? { ...currentServerConfig, server: server, loading: true, error: false }
            : { server: server, loading: true, error: false };
        setServerConfig(config);

        let setupInfo: SetupInfo | undefined = config.setupInfo;
        const bulk = await apiServerFetchWithCustomPortalId<SetupInfo>(server.id, 'bulk');

        if (!bulk.result?.objects || bulk.code !== 200) {
            setServerConfig({ ...config, error: true, loading: false });
            return;
        }

        let objects: VirtualDevice[] = bulk.result?.objects.items ?? [];

        try {
            if (bulk.result?.objects.more) {
                let getNext;
                do {
                    const objResult = await apiServerFetchWithCustomPortalId<{
                        objects: VirtualDevice[];
                        more: boolean;
                    }>(server.id, 'objects', '?startid=' + (objects[objects.length - 1].id + 1));
                    if (!objResult.result || bulk.code !== 200) {
                        setServerConfig({ ...config, error: true, loading: false });
                        return;
                    }
                    objects = [...objects, ...(objResult.result.objects ?? [])];
                    getNext = objResult.result.more;
                } while (getNext);
            }
        } catch {}

        setupInfo = {
            ...bulk.result,
            objects: { items: objects, more: false },
        };

        const newConfig = {
            ...config,
            error: false,
            loading: false,
            setupInfo: setupInfo,
        };

        setServerConfig(newConfig);

        return newConfig;
    };

    const onRefetch = async () => {
        if (!userServers.find((x) => x.id === Number(serverId))?.connected) {
            return;
        }

        const config = serversConfigRef?.current?.find((x) => x.server.id === Number(serverId));

        if (!config) {
            return;
        }

        const result = await getStats(config.server);

        if (!result) {
            return;
        }

        await subscribeIos(result);
    };

    const onRefetchOne = async (serverId: number) => {
        const config = serversConfigRef?.current?.find((x) => x.server.id === serverId);

        if (!config) {
            return;
        }

        const result = await getStats(config.server);

        if (!result) {
            return;
        }

        await subscribeIos(result);
    };

    return {
        configs: serversConfig,
        serverConfig: currentServerConfig,
        connection: connectionRef?.current,
        isDemo,
        onRefetch,
        onRefetchOne,
    };
};

export default useSignalRConfig;
