import { useServerConfig } from 'hooks/useServerConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useFloorPlanConfig = () => {
    const { currentServer, virtualDevices, serverConfigLoading, currentVirtualDevice } = useServerConfig();
    const { floorPlanIndex } = useParams<{ floorPlanIndex: string }>();

    const floorPlansVirtualDevices = useMemo(
        () => virtualDevices?.filter((x) => x.type == VirtualDeviceType.FloorPlan),
        [virtualDevices],
    );

    const currentFloorPlanVirtualDevice = useMemo(
        () => currentVirtualDevice ?? floorPlansVirtualDevices[0],
        [floorPlansVirtualDevices, currentVirtualDevice],
    );

    const floorPlans = useMemo(
        () => currentFloorPlanVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.FloorConfig)?.Floors ?? [],
        [currentFloorPlanVirtualDevice?.datapoints],
    );

    const currentFloor = useMemo(() => floorPlans?.[Number(floorPlanIndex ?? 0)], [floorPlans, floorPlanIndex]);

    return {
        serverConfigLoading,
        floorPlansVirtualDevices,
        currentFloorPlanVirtualDevice,
        currentServer,
        currentFloor,
        floorPlans,
        floorPlanIndex: floorPlanIndex,
    };
};

export default useFloorPlanConfig;
