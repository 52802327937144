import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { WpcAuthContext } from 'pages/Wpc/contexts/WpcAuthContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCreateOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import SelectedScheme from './SelectedScheme/SelectedScheme';
import * as Styled from './WpcDashboardPage.styles';

export enum WpcElementType {
    HeatGenerator,
    Buffer,
    HotWater,
    MixedHeatingCircuit,
    DirectHeatingCircuit,
}

const WpcDashboardPage = () => {
    const { colors } = useTheme();
    const { t } = useTranslation();
    const { scheme, loading } = useContext(WpcAuthContext);
    const navigate = useNavigate();

    const handleCreateProject = () => {
        navigate(`/setup`);
    };

    if (loading) {
        return <ThemedSpin size="large" />;
    }

    if (scheme) {
        return <SelectedScheme config={scheme.payload} />;
    }

    return (
        <Styled.MainWrapper>
            <IoCreateOutline color={colors.text} fontSize={70} />
            <Styled.Description>{t('wpc.projectNotCreated')}</Styled.Description>
            <ThemedButton onClick={handleCreateProject}>{t('general.create')}</ThemedButton>
        </Styled.MainWrapper>
    );
};

export default WpcDashboardPage;
