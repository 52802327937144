import { Space } from 'antd';
import { ThemedButton, ThemedSearch, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { isEmpty } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import License from 'models/License';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import AddEditLicenseModal from './AddEditLicenseModal/AddEditLicenseModal';
import LicenseView from './LicenseView/LicenseView';
import * as Styled from './LicensesEditPage.styles';

const LicensesEditPage = () => {
    const { apiWithoutLicenseFetch } = useApi();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [licenses, setLicenses] = useState<License[]>([]);
    const [search, setSearch] = useState<string>('');
    const [addModalVisible, setAddModalVisible] = useState(false);

    const filteredLicenses = useMemo(() => {
        if (isEmpty(search)) {
            return licenses;
        }

        return licenses.filter(
            (x) =>
                x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                x.licenseKey.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        );
    }, [search, licenses]);

    const init = async () => {
        setLoading(true);
        const result = await apiWithoutLicenseFetch<License[]>('Licenses');

        if (!result.result) {
            setLoading(false);
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }

        setLicenses(result.result);
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    if (loading) {
        return <ThemedSpin size="large" />;
    }

    return (
        <Styled.MainWrapper>
            <Space size={20} wrap>
                <ThemedSearch
                    value={search}
                    onChange={(v) => setSearch(v.currentTarget.value)}
                    prefix={<IoSearchOutline />}
                />
                <ThemedButton onClick={() => setAddModalVisible(true)}>{t('general.addLicense')}</ThemedButton>
            </Space>
            <Space size={20} wrap>
                {filteredLicenses.map((x) => (
                    <LicenseView license={x} key={x.id} onReloadLicenses={init} />
                ))}
            </Space>
            {addModalVisible && <AddEditLicenseModal reload={init} onCancel={() => setAddModalVisible(false)} />}
        </Styled.MainWrapper>
    );
};

export default LicensesEditPage;
