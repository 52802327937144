import { menuItems, routes } from 'App';
import { Switch } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdHome, IoMdMoon } from 'react-icons/io';
import { IoSunny } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './WpcHeader.styles';
import { WpcAuthContext } from 'pages/Wpc/contexts/WpcAuthContext';

const WpcHeader = () => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const { theme, setTheme } = useContext(WpcAuthContext);

    const iconSettings = {
        fontSize: 22,
        color: colors.text,
        cursor: 'pointer',
    };

    const pageTitle = useMemo(() => {
        const routeTitle = menuItems[location.pathname];

        if (location.pathname.endsWith('setup')) {
            return t('wpc.projectSetup');
        }

        if (routeTitle) {
            return t(routeTitle);
        }

        const including = Object.keys(menuItems)
            .reverse()
            .find((x) => location.pathname.startsWith(x));

        if (including && including !== '/') {
            return t(menuItems[including]);
        }
    }, [location.pathname, menuItems]);

    const onGoToHomePage = () => {
        navigate(routes.home);
    };

    const onThemeChange = (v: boolean) => {
        setTheme?.(v ? 'light' : 'dark');
    };

    return (
        <Styled.MainHeader>
            <Styled.TitleWrapper>
                <IoMdHome onClick={onGoToHomePage} {...iconSettings} />
                <div>/</div>
                {pageTitle}
            </Styled.TitleWrapper>
            <Switch
                onChange={onThemeChange}
                checked={theme === 'light'}
                checkedChildren={<IoSunny />}
                unCheckedChildren={<IoMdMoon />}
            />
        </Styled.MainHeader>
    );
};

export default WpcHeader;
