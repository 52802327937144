const publicKey =
    'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAubCo6OCk3/gtsVS6ChACWDqdB6+No3DTccKJal2h82aPYQv7+C9ekHQ9dW+GGDeKW7E+UrYtG4Mcr57JrxlLVQ9M31TumTn9DrbhcJufusDG2Us+rj7HSMIkwDO7hA77Tn38ZTHS16hVXxnMRoAQq4EFTjNr9o6AQeQqEpc0weq7Zu/584dnKRlVSpMVTpYasx8nvpjyEB4esk0zULR/VzqecxrlBTDswQ4Msnu2f9OgZqELe1CBRCZ7PhHHBD7JQhdRs2E+yg/dF2V0LaoAxf6tXMCDGJRpkWc04ZCM1I/r5sBou8wnQvueuaTwAQtJlXQY02hxMU94ScpvZemkI8hUAWDzvUAUxFcQc6gyNAbwg4JJ7/F8VwuwIWiBrw8J/tUgM5HDy8bl9uDO58xxBIkDvWSFmlt8csMO9SMVloYuzC81o08ceLgIhqgG6rHX2O5LGeX3ORJzko89X/24j7nkVEcSLToft7LKBwi20wlvdvevd3H5w49X3i70h7gh4z2C1ge9L9s59PKN1/1yVJKDqfuDTUH9ZwntAVH56H3mFotTbUDAloswsvbY92AfnsO5E3+5qqanSVpQxW0UBzk7C7fyxSMszi0RWcbjptzxNd5WLS6m3pUxsLXH2OaftAUo9F4SEOvdxhoGdQfTuSJgAQjvgPHpmK2Bd0oWN88CAwEAAQ==';

async function importPublicKey() {
    try {
        const binaryDerString = atob(publicKey);
        return await crypto.subtle.importKey(
            'spki',
            str2ab(binaryDerString),
            {
                name: 'RSA-OAEP',
                hash: 'SHA-1',
            },
            true,
            ['encrypt'],
        );
    } catch (e) {
        return null;
    }
}

function str2ab(str: string) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const encryptRSA = async (text: string): Promise<string> => {
    const pub = await importPublicKey();
    if (!pub) return '';
    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
        },
        pub,
        new TextEncoder().encode(text),
    );
    const encryptedBase64 = arrayBufferToBase64(encrypted);
    return encryptedBase64 ? encryptedBase64 : '';
};
