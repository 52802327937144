import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import { OpeartionModeConfigType } from 'models/server/OperationModeConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointSwitchView from '../DatapointSwitchView/DatapointSwitchView';
import HidingView from '../HidingView/HidingView';
import * as Styled from './OperationalModeSelector.styles';

const OperationalModeSelector = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints, category } = virtualDevice;
    const { datapointLoading, onDatapointValueChange } = useDatapoint();

    const sceneSelection = useMemo(() => datapoints?.find((x) => x.type == DatapointType.SceneSelection), [datapoints]);
    const modesConfig = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.VisualizationBlockConfig),
        [datapoints],
    );
    const lightsColorDatapoints = useMemo(
        () => datapoints?.filter((x) => x.name.includes('opMode.value')),
        [datapoints],
    );
    const modesOptions = useMemo(
        () => modesConfig?.Modes?.map((x) => ({ label: x.Name, value: x.ID })),
        [modesConfig?.Modes],
    );
    const configs = useMemo(() => modesConfig?.Configs, [modesConfig]);
    const currentMode = useMemo(
        () => modesOptions?.find((x) => x.value === Number(sceneSelection?.value)),
        [sceneSelection, modesOptions],
    );

    const getConfigForName = (name: string) => {
        return configs?.find((x) => x.Description === name);
    };

    const onModeChanged = (dpId: number, v: unknown) => {
        onDatapointValueChange(dpId, v as number);
    };

    return (
        <Styled.MainWrapper>
            {(modesOptions?.length ?? 0) > 1 && sceneSelection && (
                <ThemedSelect
                    loading={datapointLoading === sceneSelection.id}
                    onChange={(v: unknown) => onModeChanged(sceneSelection?.id, v)}
                    value={currentMode}
                    options={modesOptions}
                    $version="virtualDevice"
                />
            )}
            <HidingView numberOfVisible={2} category={category}>
                {lightsColorDatapoints?.map((dp, index) => (
                    <div key={index}>
                        {getConfigForName(dp.customname)?.Type === OpeartionModeConfigType.Switch && (
                            <DatapointSwitchView
                                disabled={getConfigForName(dp.customname)?.ReadOnly ?? false}
                                useCustomName
                                datapoint={dp}
                                virtualDevice={virtualDevice}
                            />
                        )}
                        {getConfigForName(dp.customname)?.Type === OpeartionModeConfigType.Dimmer && (
                            <DatapointSliderView
                                useCustomName
                                datapoint={{ ...dp, writeprotect: getConfigForName(dp.customname)?.ReadOnly ?? false }}
                                virtualDevice={virtualDevice}
                                sufix={` ${getConfigForName(dp.customname)?.Unit ?? ''}`}
                                customStep={
                                    getConfigForName(dp.customname)?.Step
                                        ? Number(getConfigForName(dp.customname)?.Step)
                                        : 1
                                }
                                rounding={undefined}
                            />
                        )}
                        {getConfigForName(dp.customname)?.Type === OpeartionModeConfigType.Dropdown && (
                            <Styled.SelectRowWrapper>
                                <Styled.SelectTitleWrapper>{dp.customname}</Styled.SelectTitleWrapper>
                                <Styled.SelectWrapper>
                                    <ThemedSelect
                                        value={Number(dp.value)}
                                        options={getConfigForName(dp.customname)?.DropdownList?.map((x) => ({
                                            label: x.Name,
                                            value: Number(x.Value),
                                        }))}
                                        loading={datapointLoading === dp.id}
                                        onChange={(v: unknown) => onModeChanged(dp.id, v)}
                                        $version="virtualDevice"
                                        disabled={getConfigForName(dp.customname)?.ReadOnly ?? false}
                                    />
                                </Styled.SelectWrapper>
                            </Styled.SelectRowWrapper>
                        )}
                    </div>
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default OperationalModeSelector;
