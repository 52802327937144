import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const GroupNameWrapper = styled.div`
    display: flex;
    margin-top: 15px;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    position: relative;
    font-weight: bold;
`;

export const ArrwoWrapper = styled.div`
    top: 0;
    right: 0;
    position: absolute;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
`;
