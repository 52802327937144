import styled, { css } from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    gap: 10px;
`;

export const HiddenWrapper = styled.div<{ $categoryColor?: string }>`
    position: absolute;
    top: calc(100% - 20px);
    left: 0;
    background-color: ${(props) => props.theme.colors.virtualDevicesBackground};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: calc(100% + 32px);
    margin: 0 -16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    padding-top: 0;
    ${(props) =>
        props.$categoryColor &&
        css`
            border: 1px solid ${props.$categoryColor};
            border-top: none;
        `};
    box-shadow: 0 22px 24px -5px rgba(20, 35, 85, 0.2);
    z-index: 99;
`;

export const DotsWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
`;
