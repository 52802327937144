import { Space } from 'antd';
import styled from 'styled-components';

export const MainWrapper = styled(Space)``;

export const Wrapper = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    border-right: 1px solid ${(props) => props.theme.colors.text + '33'};
    padding: 5px 20px 5px 0px;
    margin-right: 5px;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
`;

export const DpName = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
`;
