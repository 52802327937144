import { Space } from 'antd';
import BoxWithImage from 'components/BoxWithImage/BoxWithImage';
import { ThemedTag } from 'components/ThemedComponents/ThemedComponents.styles';
import { useImages } from 'hooks/useImages';
import Server from 'models/Server';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

type Props = {
    server: Server;
    selected: boolean;
    onSelect: (serverId: number) => void;
};

const ServerSelectView = ({ server, selected, onSelect }: Props) => {
    const { t } = useTranslation();
    const { darkImages } = useImages();
    const { colors } = useTheme();

    return (
        <BoxWithImage
            title={`${server.name}${server.address ? ` - ${server.address}` : ''}`}
            selectable={{ onSelect: onSelect, selected: selected, id: server.id }}
            image={darkImages.server}
            error={server.connected ? undefined : { tooltipTitle: t('general.notConnected') }}
        >
            <Space size={[0, 8]} wrap>
                {server.tags.map((tag, index) => (
                    <ThemedTag key={index} color={colors.backgroundLayer2}>
                        {tag.value}
                    </ThemedTag>
                ))}
            </Space>
        </BoxWithImage>
    );
};

export default ServerSelectView;
