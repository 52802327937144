import { Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import { PropsWithChildren } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './BoxWithImage.styles';

type Props = {
    title: string;
    selectable?: {
        id: number;
        selected: boolean;
        onSelect: (id: number) => void;
    };
    error?: {
        tooltipTitle: string;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    image: any;
} & PropsWithChildren;

const BoxWithImage = ({ error, selectable, title, image, children }: Props) => {
    const { colors } = useTheme();

    const onSelect = () => {
        !error && selectable && selectable.onSelect(selectable.id);
    };

    return (
        <Tooltip color={colors.error} title={error ? error.tooltipTitle : undefined}>
            <Styled.MainWrapper
                $connected={!error}
                onClick={onSelect}
                $selectable={!!selectable}
                $selected={selectable?.selected}
            >
                <Styled.HeadWrapper>
                    <Styled.IconWrapper $isConnected={!error}>
                        <Styled.ServerImage src={image} />
                    </Styled.IconWrapper>
                    <Styled.ServerName>{title}</Styled.ServerName>
                    {selectable && (
                        <Styled.SelectCheckbox
                            options={[{ label: '', value: 'selected' }]}
                            value={[selectable.selected ? 'selected' : undefined] as CheckboxValueType[]}
                            $version="2"
                            disabled={!!error}
                        />
                    )}
                </Styled.HeadWrapper>
                <Line />
                {children}
            </Styled.MainWrapper>
        </Tooltip>
    );
};

export default BoxWithImage;
