import {
    ColorStateButton,
    ThemedInput,
    ThemedSwitch,
    ThemedTag,
    ThemedTimePicker,
} from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs, { Dayjs } from 'dayjs';
import { useServerConfig } from 'hooks/useServerConfig';
import { AlarmTimeProgram } from 'models/server/AlarmTimeProgram';
import { timeFormat } from 'pages/SchedulerEditPage/SchedulerEditPage';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { useTheme } from 'styled-components';
import * as Styled from './AlarmClockView.styles';

type Props = {
    program: AlarmTimeProgram;
    onAlarmChanged: (alarm: AlarmTimeProgram) => void;
    onRemove: () => void;
};

const daysKeys: Record<number, 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'> = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    0: 'Sunday',
};

const order = [1, 2, 3, 4, 5, 6, 0];

const AlarmClockView = ({ program, onAlarmChanged, onRemove }: Props) => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const { categorySettings } = useServerConfig();

    const daysConfig: Record<number, string> = useMemo(
        () => ({
            1: t('timeProgramEdit.mo'),
            2: t('timeProgramEdit.tu'),
            3: t('timeProgramEdit.we'),
            4: t('timeProgramEdit.th'),
            5: t('timeProgramEdit.fr'),
            6: t('timeProgramEdit.sa'),
            0: t('timeProgramEdit.su'),
        }),
        [],
    );

    const onTimeChanged = (time: Dayjs) => {
        onAlarmChanged({ ...program, Time: time.format(timeFormat) });
    };

    const onDayClicked = (day: number) => {
        const currentValue = program[daysKeys[day]];
        onAlarmChanged({ ...program, [daysKeys[day]]: !currentValue });
    };

    const onDescriptionChanged = (desc: string) => {
        onAlarmChanged({ ...program, Description: desc });
    };

    const onActiveChanged = (active: boolean) => {
        onAlarmChanged({ ...program, Active: active });
    };

    return (
        <ThemedTag color={colors.backgroundLayer1}>
            <Styled.TagWrapper>
                <Styled.IconWrapper>
                    <IoMdCloseCircleOutline onClick={onRemove} size={18} />
                </Styled.IconWrapper>
                <Styled.Column>
                    <Styled.Row wrap>
                        <ThemedTimePicker
                            value={dayjs(program.Time, timeFormat)}
                            format={'HH:mm'}
                            changeOnBlur
                            allowClear={false}
                            onChange={(v) => v && onTimeChanged(v)}
                        />
                        <ThemedInput
                            $width={200}
                            value={program.Description}
                            onChange={(v) => onDescriptionChanged(v.currentTarget.value)}
                        />
                    </Styled.Row>
                    <Styled.Row wrap>
                        {Object.keys(daysConfig)
                            .sort((a, b) => order.indexOf(Number(a)) - order.indexOf(Number(b)))
                            .map((day) => (
                                <ColorStateButton
                                    shape="round"
                                    key={day}
                                    $color={categorySettings?.color}
                                    $width={40}
                                    $isActive={program[daysKeys[Number(day)]]}
                                    onClick={() => onDayClicked(Number(day))}
                                >
                                    {daysConfig[Number(day)]}
                                </ColorStateButton>
                            ))}
                    </Styled.Row>
                </Styled.Column>
                <ThemedSwitch
                    $color={categorySettings?.color}
                    checked={program.Active}
                    onChange={(v) => onActiveChanged(v)}
                />
            </Styled.TagWrapper>
        </ThemedTag>
    );
};

export default AlarmClockView;
