import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { ServerContext } from 'contexts/ServerContext';
import { useImages } from 'hooks/useImages';
import { useServerConfig } from 'hooks/useServerConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import HidingView from '../HidingView/HidingView';
import * as Styled from './Camera.styles';

const Camera = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints, category } = virtualDevice;
    const { photo_camera } = useImages();
    const navigate = useNavigate();
    const { currentServer } = useServerConfig();
    const { navigationPath } = useContext(ServerContext);

    const camerasDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.CameraConfig)?.Cameras,
        [datapoints],
    );

    const onNavigate = (cameraIndex: number) => {
        navigate(
            {
                pathname: `${navigationPath}/${currentServer?.id}/camera/${virtualDevice.id}/${cameraIndex}`,
            },
            { state: { path: window.location.pathname, search: window.location.search } },
        );
    };

    return (
        <Styled.MainWrapper>
            <HidingView numberOfVisible={2} category={category}>
                {camerasDatapoint?.map((dp, index) => (
                    <Styled.CameraWrapper key={index}>
                        <Styled.CameraTitle>{dp.Name}</Styled.CameraTitle>
                        {/*<ThemedButton
                            $width={50}
                            shape="round"
                            icon={<Styled.ButtonImage src={activitySimulation} />}
                        />*/}
                        <ThemedButton
                            onClick={() => onNavigate(index)}
                            $width={50}
                            shape="round"
                            icon={<Styled.ButtonImage src={photo_camera} />}
                        />
                    </Styled.CameraWrapper>
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default Camera;
