import { Space } from 'antd';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
`;

export const TagWrapper = styled.div`
    padding: 8px;
`;

export const ButtonWrapper = styled(Space)`
    margin-left: auto;
    display: flex;
    gap: 10px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 750px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`;
