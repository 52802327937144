import { useTranslation } from 'react-i18next';
import { elementsConfig } from '../../CreateWpcProjectPage';

import * as Styled from './ElementsScheme.styles';

type Props = {
    elements: number[];
};

const ElementsScheme = ({ elements }: Props) => {
    const { t } = useTranslation();
    return (
        <Styled.Wrapper>
            {elements.map((e, index) => (
                <img key={index} src={elementsConfig(t).find((x) => x.type === e)?.schemeImage} />
            ))}
        </Styled.Wrapper>
    );
};

export default ElementsScheme;
