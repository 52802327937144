import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedCheckableTag, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { isEmpty } from 'lodash';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from 'models/server/EnergyManagementDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { TagWrapper } from 'pages/SceneEditPage/SceneEditPage.styles';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EnergyManagerDeviceView from './EnergyManagerDeviceView/EnergyManagerDeviceView';
import * as Styled from './EnergyManagerEditPage.styles';
import EnergyManagerSummary from './EnergyManagerSummary/EnergyManagerSummary';

const EnergyManagerEditPage = () => {
    const { t } = useTranslation();
    const { currentServer, virtualDevices, serverConfigLoading } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { virtualDeviceId } = useParams<{ serverId: string; virtualDeviceId: string }>();
    const { onGoBack } = useGoBack();

    const [selectedTabIndex, setSelectedTabIndex] = useState(-1);

    const currentVirtualDevice = useMemo(
        () => virtualDevices?.find((x) => x.id === Number(virtualDeviceId)),
        [virtualDevices, virtualDeviceId],
    );
    const energyConfigDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.EnergyManagementConfig),
        [currentVirtualDevice?.datapoints],
    );
    const [energyDevices, setEnergyDevices] = useState<EnergyManagementDevice[]>(
        energyConfigDatapoint?.EnergyManagementConfig?.Devices ?? [],
    );

    useEffect(() => {
        setEnergyDevices(energyConfigDatapoint?.EnergyManagementConfig?.Devices?.filter((x) => !isEmpty(x.Name)) ?? []);
    }, [energyConfigDatapoint?.EnergyManagementConfig?.Devices]);

    const onSave = async () => {
        if (!energyConfigDatapoint || !currentVirtualDevice) {
            return;
        }

        const newSceneDatapoint = { ...energyConfigDatapoint, EnergyManagementConfig: { Devices: energyDevices } };

        const result = await onDatapointChange(newSceneDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onDeviceChanged = (device: EnergyManagementDevice, newDevice: EnergyManagementDevice) => {
        setEnergyDevices((prev) => prev.map((x) => (x === device ? newDevice : x)));
    };

    const onPropertyChanged = (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => {
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: device.Priorities.map((x) => (x === property ? newProperty : x)),
        };
        onDeviceChanged(device, newDevice);
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.WholePageSpace size={[0, 8]} wrap>
                    <ThemedCheckableTag checked={selectedTabIndex === -1} onClick={() => setSelectedTabIndex(-1)}>
                        <TagWrapper>{t('general.summary')}</TagWrapper>
                    </ThemedCheckableTag>
                    {energyDevices.map((dev, index) => (
                        <ThemedCheckableTag
                            key={index}
                            checked={selectedTabIndex === index}
                            onClick={() => setSelectedTabIndex(index)}
                        >
                            <TagWrapper>{dev.Name}</TagWrapper>
                        </ThemedCheckableTag>
                    ))}
                </Styled.WholePageSpace>
                <Space size={20} wrap>
                    <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                    <ThemedButton $action onClick={onSave}>
                        {t('general.save')}
                    </ThemedButton>
                </Space>
            </Styled.HeaderWrapper>
            {selectedTabIndex >= 0 ? (
                <EnergyManagerDeviceView
                    energyDevices={energyDevices}
                    device={energyDevices[selectedTabIndex]}
                    onDeviceChanged={onDeviceChanged}
                />
            ) : (
                <EnergyManagerSummary functionBlocks={energyDevices} onPropertyChanged={onPropertyChanged} />
            )}
        </Styled.MainWrapper>
    );
};

export default EnergyManagerEditPage;
