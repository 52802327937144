import { useState } from 'react';

const getStorageValue = <T>(key: string, defaultValue: T) => {
    const saved = localStorage.getItem(key);

    if (!saved) {
        return defaultValue;
    }

    const initial: T = JSON.parse(saved);
    return initial || defaultValue;
};

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
    const [value, setLocalValue] = useState<T>(getStorageValue(key, defaultValue));

    const setValue = (value: T) => {
        localStorage.setItem(key, JSON.stringify(value));
        setLocalValue(value);
    };

    return { value, setValue };
};
