import IoValueCard from 'components/IoValueCard/IoValueCard';
import DashboardConfig, { DashboardConfigMonitorIo } from 'models/DashboardConfig';
import { SetStateAction } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { TbArrowsMoveHorizontal } from 'react-icons/tb';
import { useTheme } from 'styled-components';
import * as Styled from './DraggableMonitorValues.styles';

type Props = {
    config: DashboardConfig;
    setCurrentConfig: (value: SetStateAction<DashboardConfig>) => void;
};

const DraggableMonitorValues = ({ config, setCurrentConfig }: Props) => {
    const { colors } = useTheme();
    const reorder = (list: DashboardConfigMonitorIo[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(config.monitorIos, result.source.index, result.destination.index);
        setCurrentConfig((prev) => ({ ...prev, monitorIos: items }));
    };

    return (
        <Styled.MainWrapper>
            {!!config.monitorIos.length && (
                <Styled.DragIconWrapper>
                    <TbArrowsMoveHorizontal color={colors.text} fontSize={22} />
                </Styled.DragIconWrapper>
            )}
            <Styled.DndWrapper>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <Styled.ValuesGrid {...provided.droppableProps} ref={provided.innerRef}>
                                {config.monitorIos.map((io, index) => (
                                    <Draggable key={io.ioId} draggableId={io.ioId.toString()} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <IoValueCard
                                                    draggable={!snapshot.isDraggingOver}
                                                    title={io.name}
                                                    configuration={io.configuration}
                                                    unit={io.unit}
                                                    ioId={io.ioId}
                                                    serialNumber={config.serialNumber ?? ''}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Styled.ValuesGrid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Styled.DndWrapper>
        </Styled.MainWrapper>
    );
};

export default DraggableMonitorValues;
