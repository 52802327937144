import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import HidingView from '../HidingView/HidingView';
import * as Styled from './EnergyConfiguration.styles';
import { useDatapoint } from 'hooks/useDatapoint';

const EnergyConfiguration = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { t } = useTranslation();
    const { datapoints, category } = virtualDevice;
    const { datapointLoading, onDatapointValueChange } = useDatapoint();

    const values2Datapoints = useMemo(() => datapoints?.filter((x) => x.type == DatapointType.Number), [datapoints]);

    const valuesDatapoints = useMemo(
        () =>
            datapoints
                ?.filter((x) => x.type == DatapointType.EnergyManagementDeviceMode)
                .filter((x, index) => !!values2Datapoints[index]),
        [datapoints, values2Datapoints],
    );
    const energyConfigurationDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.EnergyManagementConfig),
        [datapoints],
    );

    const selectOptions = useMemo(
        () => [
            {
                value: 0,
                label: t('general.off'),
            },
            {
                value: 1,
                label: t('general.auto'),
            },
            {
                value: 2,
                label: t('general.on'),
            },
            {
                value: 3,
                label: t('general.solar'),
            },
            {
                value: 4,
                label: t('general.solarAndLowPrice'),
            },
        ],
        [],
    );

    const getValue = (index: number) => {
        const dp = values2Datapoints[index];
        const config = energyConfigurationDatapoint?.EnergyManagementConfig?.Devices?.[index];

        if (dp?.value === undefined) {
            return '';
        }

        if (typeof dp.value === 'boolean') {
            return dp.value ? `${config?.MinPower?.toFixed(1) ?? '0.0'} kW` : `0.0 kW`;
        }

        if (dp.value.toString() === 'false') {
            return `0.0 kW`;
        }

        if (dp.value.toString() === 'true') {
            return `${config?.MinPower?.toFixed(1) ?? '0.0'} kW`;
        }

        const numberValue = Number(dp.value);

        if (!isNaN(numberValue)) {
            return `${numberValue.toFixed(1)} kW`;
        }

        return dp.value.toString();
    };

    const onModeChanged = (dpId: number, v: unknown) => {
        onDatapointValueChange(dpId, v as number);
    };

    return (
        <Styled.MainWrapper>
            <HidingView category={category} numberOfVisible={4}>
                {valuesDatapoints?.map((dp, index) => (
                    <Styled.RowWrapper key={index}>
                        <Styled.TitleWrapper>
                            {energyConfigurationDatapoint?.EnergyManagementConfig?.Devices?.[index]?.Name}
                        </Styled.TitleWrapper>
                        <Styled.SelectWrapper>
                            <ThemedSelect
                                disabled={dp.writeprotect}
                                value={Number(dp.value)}
                                options={selectOptions}
                                $version="virtualDevice"
                                loading={datapointLoading === dp.id}
                                onChange={(v: unknown) => onModeChanged(dp.id, v)}
                            />
                        </Styled.SelectWrapper>
                        <Styled.ValueWrapper>{getValue(index)}</Styled.ValueWrapper>
                    </Styled.RowWrapper>
                ))}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default EnergyConfiguration;
