import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

export const ImageWrapper = styled(ThemedCheckableTag)`
    padding: 10px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
`;

export const ImageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
`;

export const Header = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-between;
`;

export const Buttons = styled.div`
    display: flex;
    gap: 10px;
`;
