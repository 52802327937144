import { Space } from 'antd';
import styled from 'styled-components';

export const TagWrapper = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
`;

export const IconWrapper = styled.div`
    min-width: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const Row = styled(Space)`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
`;
