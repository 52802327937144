import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import {
    ThemedButton,
    ThemedCard,
    ThemedCheckableTag,
    ThemedSpin,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import i18n from 'i18next';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { AstroFunctionBlock } from 'models/server/AstroFunctionBlock';
import { Datapoint } from 'models/server/Datapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { WholePageSpace } from 'pages/SceneEditPage/SceneEditPage.styles';
import SwitchpointDeviceView from 'pages/SchedulerEditPage/SwitchpointDeviceView/SwitchpointDeviceView';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSunrise, BsSunset } from 'react-icons/bs';
import { MdOutlineEdit, MdOutlineHome } from 'react-icons/md';
import { TbSunMoon } from 'react-icons/tb';
import * as Styled from './AstroEditPage.styles';
import AstroMap from './AstroMap/AstroMap';

const configItems: (0 | 1 | 2 | 3)[] = [0, 1, 2, 3];
const iconProps = { fontSize: 22, style: { minWidth: 22 } };
const provider = new OpenStreetMapProvider({ params: { 'accept-language': i18n.resolvedLanguage ?? 'en' } });

const AstroEditPage = () => {
    const { t } = useTranslation();
    const { currentServer, currentVirtualDevice, serverConfigLoading, virtualDevices } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();

    const ignore = { label: t('general.ignore'), value: 'Ignore' };
    const { onGoBack } = useGoBack();

    const config: Record<
        number,
        {
            title: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            img: JSX.Element;
            prop: 'Sunrise' | 'Sunset' | 'Dawn' | 'Twilight';
        }
    > = useMemo(
        () => ({
            0: {
                title: t('general.sunrise'),
                img: <BsSunrise {...iconProps} />,
                prop: 'Sunrise',
            },
            1: {
                title: t('general.sunset'),
                img: <BsSunset {...iconProps} />,
                prop: 'Sunset',
            },
            2: {
                title: t('general.dawn'),
                img: <TbSunMoon {...iconProps} />,
                prop: 'Dawn',
            },
            3: {
                title: t('general.twilight'),
                img: <TbSunMoon {...iconProps} style={{ transform: 'rotate(180deg)' }} />,
                prop: 'Twilight',
            },
        }),
        [],
    );

    const timeProgramDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.AstroFunctionConfig),
        [currentVirtualDevice?.datapoints],
    );

    const [mapVisible, setMapVisible] = useState(false);
    const [currentPosition, setCurrentPosition] = useState({
        lat: timeProgramDatapoint?.AstroConfig?.Latitude ?? '49',
        lng: timeProgramDatapoint?.AstroConfig?.Longitude ?? '9',
    });
    const [currentPositionLabel, setCurrentPositionLabel] = useState<{ label?: string; loading: boolean }>({
        loading: true,
    });

    const getPositionLabel = async () => {
        setCurrentPositionLabel({ loading: true });
        const r = await provider.search({
            query: `${Number(currentPosition.lat).toFixed(14)}, ${Number(currentPosition.lng).toFixed(14)}`,
        });

        setCurrentPositionLabel({ label: r?.[0]?.label ?? '', loading: false });
    };

    useEffect(() => {
        getPositionLabel();
    }, [currentPosition]);

    const mapVirtualDevicesToAstroFunctionBlocks = (virtualDevices: VirtualDevice[]) => {
        return (
            virtualDevices
                ?.filter(
                    (vd) =>
                        vd.type === VirtualDeviceType.Dimmer ||
                        vd.type === VirtualDeviceType.LightController ||
                        vd.type === VirtualDeviceType.Blinds ||
                        vd.type === VirtualDeviceType.StaircaseTimer ||
                        vd.type === VirtualDeviceType.ToiletVentilationController ||
                        vd.type === VirtualDeviceType.Schedule ||
                        vd.type === VirtualDeviceType.BurglarAlarm ||
                        vd.type === VirtualDeviceType.PresenceSimulation ||
                        vd.type === VirtualDeviceType.PushButton ||
                        vd.type === VirtualDeviceType.PushSwitch ||
                        vd.type === VirtualDeviceType.Switch,
                )
                ?.map((x) => ({
                    ID:
                        timeProgramDatapoint?.AstroConfig?.FunctionBlocks?.find((z) => z.ObjectID === x.id)?.ID ??
                        `_${x.id}`,
                    ObjectID: x.id,
                    Type: x.type,
                    Dawn:
                        timeProgramDatapoint?.AstroConfig?.FunctionBlocks?.find((z) => z.ObjectID === x.id)?.Dawn ??
                        ignore.value,
                    DawnDelay: 0,
                    Sunrise:
                        timeProgramDatapoint?.AstroConfig?.FunctionBlocks?.find((z) => z.ObjectID === x.id)?.Sunrise ??
                        ignore.value,
                    SunriseDelay: 0,
                    Sunset:
                        timeProgramDatapoint?.AstroConfig?.FunctionBlocks?.find((z) => z.ObjectID === x.id)?.Sunset ??
                        ignore.value,
                    SunsetDelay: 0,
                    Twilight:
                        timeProgramDatapoint?.AstroConfig?.FunctionBlocks?.find((z) => z.ObjectID === x.id)?.Twilight ??
                        ignore.value,
                    TwilightDelay: 0,
                })) ?? []
        );
    };

    const [currentModeIndex, setCurrentModeIndex] = useState<0 | 1 | 2 | 3>(0);
    const [blocks, setBlocks] = useState<AstroFunctionBlock[]>(mapVirtualDevicesToAstroFunctionBlocks(virtualDevices));

    useEffect(() => {
        setBlocks(mapVirtualDevicesToAstroFunctionBlocks(virtualDevices));
        setCurrentPosition({
            lat: timeProgramDatapoint?.AstroConfig?.Latitude ?? '49',
            lng: timeProgramDatapoint?.AstroConfig?.Longitude ?? '9',
        });
    }, [timeProgramDatapoint?.AstroConfig]);
    console.log(blocks);
    const onSave = async () => {
        if (!timeProgramDatapoint) {
            return;
        }

        const newDatapoint: Datapoint = {
            ...timeProgramDatapoint,
            AstroConfig: {
                Latitude: currentPosition.lat,
                Longitude: currentPosition.lng,
                FunctionBlocks: blocks,
            },
        };

        const result = await onDatapointChange(newDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onValueChange = (block: AstroFunctionBlock, value: string) => {
        setBlocks((prev) =>
            prev.map((prevBlock) =>
                prevBlock === block ? { ...prevBlock, [config[currentModeIndex].prop]: value } : prevBlock,
            ),
        );
    };

    const onEditLocation = () => {
        setMapVisible(true);
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!timeProgramDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (timeProgramDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.Column>
                    <ThemedCard checked={false}>
                        <Styled.HeaderTagWrapper>
                            <MdOutlineHome {...iconProps} />
                            {currentPositionLabel.loading ? (
                                <ThemedSpin />
                            ) : (
                                `${currentPositionLabel.label ?? ''}, Latitude: ${Number(currentPosition.lat).toFixed(
                                    4,
                                )}, Longitude: ${Number(currentPosition.lng).toFixed(4)}`
                            )}
                            <ThemedButton
                                $width={32}
                                $version="2"
                                shape="round"
                                icon={<MdOutlineEdit size={18} />}
                                onClick={onEditLocation}
                            />
                        </Styled.HeaderTagWrapper>
                    </ThemedCard>
                    <WholePageSpace size={20} wrap>
                        {configItems.map((type) => (
                            <ThemedCheckableTag
                                onClick={() => setCurrentModeIndex(type)}
                                key={type}
                                checked={currentModeIndex === type}
                            >
                                <Styled.HeaderTagWrapper>
                                    {config[type].img}
                                    {config[type].title}
                                </Styled.HeaderTagWrapper>
                            </ThemedCheckableTag>
                        ))}
                        <div />
                    </WholePageSpace>
                </Styled.Column>
                <Space size={20} wrap>
                    <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                    <ThemedButton $action onClick={onSave}>
                        {t('general.save')}
                    </ThemedButton>
                </Space>
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {blocks.map((block, index) => (
                    <SwitchpointDeviceView
                        ObjectID={block.ObjectID}
                        currentValue={block[config[currentModeIndex].prop]}
                        key={index}
                        onChangeDeviceValue={(v) => onValueChange(block, v)}
                    />
                ))}
            </Space>
            {mapVisible && (
                <AstroMap
                    position={currentPosition}
                    onSave={(position) => {
                        setCurrentPosition(position);
                        setMapVisible(false);
                    }}
                    onCloseRequested={() => setMapVisible(false)}
                />
            )}
        </Styled.MainWrapper>
    );
};

export default AstroEditPage;
