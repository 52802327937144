import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const SettingWrapper = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 330px;
    font-size: 14px;
    white-space: break-spaces;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
`;
