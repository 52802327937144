import { Progress } from 'antd';
import styled from 'styled-components';

export const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    margin-left: 5px;
`;

export const SmallProgress = styled(Progress)`
    .ant-progress-inner {
        width: 50px !important;
        height: 50px !important;
        font-size: 7px !important;
    }
`;
