import { useImages } from 'hooks/useImages';
import { VirtualDeviceCategory } from 'models/server/enums/VirtualDeviceCategory';
import { ReactNode, useMemo, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import * as Styled from './HidingView.styles';

type Props = {
    children: ReactNode | ReactNode[];
    category: VirtualDeviceCategory;
    numberOfVisible?: number;
};

const HidingView = ({ children, category, numberOfVisible = 3 }: Props) => {
    const { getCategorySettings } = useImages();

    const [isVisible, setIsVisible] = useState(false);

    const categorySettings = useMemo(() => getCategorySettings(category), [category]);
    const elements = useMemo(() => (Array.isArray(children) ? children : [children]), [children]);

    const numberOfVisibleElements = useMemo(
        () => (numberOfVisible + 1 === elements.length ? numberOfVisible + 1 : numberOfVisible),
        [numberOfVisible, elements.length],
    );

    return (
        <Styled.MainWrapper
            onMouseLeave={() => elements.length > numberOfVisibleElements && setIsVisible(false)}
            onMouseEnter={() => elements.length > numberOfVisibleElements && setIsVisible(true)}
        >
            {elements.slice(0, numberOfVisibleElements)}
            {elements.length > numberOfVisibleElements && (
                <Styled.DotsWrapper>
                    <HiDotsHorizontal cursor="pointer" size={22} />
                </Styled.DotsWrapper>
            )}
            {isVisible && (
                <Styled.HiddenWrapper $categoryColor={categorySettings.color}>
                    {elements.slice(numberOfVisibleElements)}
                </Styled.HiddenWrapper>
            )}
        </Styled.MainWrapper>
    );
};

export default HidingView;
