import { routes } from 'App';
import { UserCredential } from 'models/server/UserCredential';
import { useNavigate } from 'react-router-dom';
import { getCookieValue } from './useCookie';
import useLocalApi, { LocalFetchProps } from './useLocalApi';
import useLocalAuth from './useLocalAuth';

const useLocalServerApi = () => {
    const { localFetch } = useLocalApi();
    const { refreshToken, logout } = useLocalAuth();
    const navigate = useNavigate();

    const handleUnauthicated = async () => {
        await logout();
        navigate(routes.login);
    };

    const localServerFetch = async <T>(props: Omit<LocalFetchProps, 'authToken'>) => {
        const authUser = getCookieValue<UserCredential>('local-user', { save: false, serverName: '' });

        if (!authUser?.access_token || !authUser?.refresh_token || !authUser?.accessTokenExpire) {
            handleUnauthicated();
            return { code: 401, result: undefined };
        }

        let accessToken = authUser?.access_token;

        if (new Date(authUser.accessTokenExpire) < new Date()) {
            const { result, code } = await refreshToken();

            if (code !== 200 || !result?.access_token) {
                handleUnauthicated();
                return { code: 401, result: undefined };
            }

            accessToken = result.access_token;
        }

        return localFetch<T>({ ...props, authToken: accessToken });
    };

    return { localServerFetch };
};

export default useLocalServerApi;
