import { HubConnectionState } from '@microsoft/signalr';
import { routes } from 'App';
import EmptyError from 'components/EmptyError/EmptyError';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { ServerConfigContext } from 'contexts/ServerConfigContext';
import { isEmpty } from 'helpers/StringHelper';
import useAuth, { initUser } from 'hooks/useAuth';
import { getCookieValue } from 'hooks/useCookie';
import useSignalRConfig from 'hooks/useSignalRConfig';
import { User } from 'models/User';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import NetatmoValidator from './NetatmoValidator/NetatmoValidator';
import Notifications from './Notifications/Notifications';
import * as Styled from './ServerConfigRoute.styles';
import ServerHeader from './ServerHeader/ServerHeader';
import UpdateValidator from './UpdateValidator/UpdateValidator';

const ServerConfigRoute = () => {
    const { t } = useTranslation();
    const { serverId } = useParams<{ serverId: string }>();
    const { authUser: user } = useAuth();
    const { servers, onRefetchServers } = useContext(AuthContext);
    const navigation = useNavigate();
    const location = useLocation();

    const isGrouped = location.pathname.startsWith(routes.groupActions);
    const isDashboardSettings = location.pathname.endsWith('dashboard-settings');

    const { serverConfig, configs, connection, isDemo, onRefetch, onRefetchOne } = useSignalRConfig();

    const currentServer = useMemo(() => servers.find((x) => x.id === Number(serverId)), [serverId, servers]);

    const hasMissingPassword =
        !isDemo && Number(serverId) === 0 && isEmpty(getCookieValue<User>('user', initUser)?.password);
    const hasNoServerId = serverId === null || serverId === undefined;

    const onRefresh = async () => {
        await onRefetchServers?.();
        await onRefetch();
    };

    const providerValues = useMemo(
        () => ({
            loading: serverConfig?.loading ?? false,
            setupInfo: serverConfig?.setupInfo,
            virtualDevices: serverConfig?.setupInfo?.objects?.items ?? [],
            configs: configs,
            onRefreshConfig: onRefresh,
            reconnect: onRefresh,
            onRefetchOne,
        }),
        [serverConfig, configs],
    );

    useEffect(() => {
        if (hasMissingPassword) {
            navigation(`${routes.server}/login`);
            return;
        }
    }, [serverId, user]);

    if ((!currentServer || !serverConfig) && !hasNoServerId) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (serverConfig?.error && serverConfig?.errorCode === 401) {
        return <EmptyError title={t('errors.wrongLoginOrPasswordChangeInAdmin')} />;
    }

    if (serverConfig?.error) {
        return <EmptyError title={t('errors.errorWhileGettingDataFromServer')} />;
    }

    if (
        (!currentServer?.connected ||
            (!serverConfig?.loading &&
                !isDemo &&
                connection?.state !== HubConnectionState.Connected &&
                connection?.state !== HubConnectionState.Connecting)) &&
        !hasNoServerId
    ) {
        return (
            <EmptyError title={t('errors.serverNotConnected')}>
                <ThemedButton onClick={onRefresh}>{t('general.reconnect')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <ServerConfigContext.Provider value={providerValues}>
            <>
                {!hasNoServerId && !isGrouped && !isDashboardSettings && (
                    <>
                        {!serverConfig?.loading && (
                            <>
                                <Notifications />
                                {!isDemo && <NetatmoValidator />}
                            </>
                        )}
                        {!isDemo && <UpdateValidator />}
                    </>
                )}
                <Styled.Wrapper>
                    {!hasMissingPassword && !isGrouped && !hasNoServerId && !isDashboardSettings && (
                        <>
                            <ServerHeader />
                            <Line />
                        </>
                    )}
                    <Outlet />
                </Styled.Wrapper>
            </>
        </ServerConfigContext.Provider>
    );
};

export default ServerConfigRoute;
