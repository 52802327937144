import ServerControls from 'components/ServerControls/ServerControls';
import { ThemedSearch, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { isEmpty } from 'helpers/StringHelper';
import useCookie from 'hooks/useCookie';
import { useServerConfig } from 'hooks/useServerConfig';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { ControlsSortingMode } from 'models/server/enums/ControlsSortingMode';
import { useContext, useEffect, useMemo } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import * as Styled from './ServerPage.styles';
import ServerPageTags from './ServerPageTags/ServerPageTags';

type Filter = 'all' | 'favorites' | 'room' | 'category';

const typeParam = 'type';
const idParam = 'id';
const searchParam = 'search';
export const scrollParam = 'scroll';

export type Filters = { filter: Filter; id?: number; search?: string };

const ServerPage = () => {
    const { isLoading } = useContext(AuthContext);
    const { virtualDevices, serverConfigLoading, rooms } = useServerConfig();

    const [searchParams, setSearchParams] = useSearchParams();

    const { value: sortingMode, updateCookie: updateSortingMode } = useCookie<ControlsSortingMode>(
        'control-sorting-mode',
        ControlsSortingMode.Ranking,
    );

    const currentFilters = useMemo(() => {
        const paramsType = searchParams.get(typeParam) as Filter | null;
        const paramsId = searchParams.get(idParam);
        const paramsSearch = searchParams.get(searchParam);

        const filters: Filters = {
            filter: paramsType === null ? 'all' : paramsType,
            id: paramsId === null ? undefined : Number(paramsId),
            search: paramsSearch === null ? '' : paramsSearch,
        };
        return filters;
    }, [searchParams]);

    const visibleVirtualDevices = useMemo(() => {
        let vdToReturn: VirtualDevice[] = virtualDevices;

        if (currentFilters.filter === 'favorites') {
            vdToReturn = virtualDevices.filter((vd) => vd.favourite);
        }

        if (currentFilters.filter === 'room') {
            vdToReturn = virtualDevices.filter((vd) => vd.roomid === currentFilters.id);
        }

        if (currentFilters.filter === 'category') {
            vdToReturn = virtualDevices.filter((vd) => vd.category === currentFilters.id);
        }

        if (!isEmpty(currentFilters.search) && currentFilters.search) {
            const lowerSearch = currentFilters.search.toLowerCase();
            vdToReturn = vdToReturn.filter(
                (vd) =>
                    vd.name.toLowerCase().includes(lowerSearch) ||
                    vd.datapoints.some(
                        (z) =>
                            z.type > 0 &&
                            z.name !== 'centralObj.config' &&
                            (z.name.toLowerCase().includes(lowerSearch) ||
                                z.customname.toLowerCase().includes(lowerSearch)),
                    ) ||
                    rooms
                        .find((x) => x.id === vd.roomid)
                        ?.name?.toLocaleLowerCase()
                        ?.includes(lowerSearch),
            );
        }

        return sortingMode === ControlsSortingMode.Ranking
            ? orderBy(vdToReturn, (x) => x.ranking, 'desc')
            : vdToReturn.concat().sort((x, y) => x.category - y.category || y.ranking - x.ranking);
    }, [virtualDevices, currentFilters, sortingMode]);

    const onSetFilers = (filters: Filters) => {
        setSearchParams((params) => {
            params.set(typeParam, filters.filter);
            filters.id !== undefined ? params.set(idParam, filters.id.toString()) : params.delete(idParam);
            if (filters.search !== undefined) {
                isEmpty(filters.search) ? params.delete(searchParam) : params.set(searchParam, filters.search);
            }
            return params;
        });
    };

    useEffect(() => {
        if (searchParams.get(typeParam) === null) {
            onSetFilers({ filter: 'all' });
        }
    }, []);

    useEffect(() => {
        const scrollId = searchParams.get(scrollParam);
        if (scrollId !== null) {
            const element = document.getElementById(scrollId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            setSearchParams((prev) => {
                prev.delete(scrollParam);
                return prev;
            });
        }
    }, []);

    if (isLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    return (
        <Styled.MainWrapper>
            <ServerPageTags
                selectedTags={currentFilters}
                setSelectedTags={(v) => onSetFilers(v)}
                controlsSortingMode={sortingMode ?? ControlsSortingMode.Ranking}
                setControlsSortingMode={(mode) => updateSortingMode(mode, { expires: 365 })}
            />
            <ThemedSearch
                defaultValue={currentFilters.search}
                onChange={debounce(
                    (v) =>
                        onSetFilers({
                            filter: currentFilters.filter,
                            id: currentFilters.id,
                            search: v.currentTarget.value,
                        }),
                    300,
                )}
                prefix={<IoSearchOutline />}
            />
            <ServerControls virtualDevices={visibleVirtualDevices} />
        </Styled.MainWrapper>
    );
};

export default ServerPage;
