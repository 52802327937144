import { VirtualDevice } from 'models/server/VirtualDevice';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import AlarmClock from '../Controls/AlarmClock/AlarmClock';
import AlarmSystem from '../Controls/AlarmSystem/AlarmSystem';
import AlphatronicControl from '../Controls/AlphatronicControl/AlphatronicControl';
import Battery from '../Controls/Battery/Battery';
import Blinds from '../Controls/Blinds/Blinds';
import Camera from '../Controls/Camera/Camera';
import CentralBlinds from '../Controls/CentralBlinds/CentralBlinds';
import CentralHomeButtons from '../Controls/CentralHomeButtons/CentralHomeButtons';
import Dimmer from '../Controls/Dimmer/Dimmer';
import EnergyConfiguration from '../Controls/EnergyConfiguration/EnergyConfiguration';
import EnergyMonitor from '../Controls/EnergyMonitor/EnergyMonitor';
import FireAndWaterAlarm from '../Controls/FireAndWaterAlarm/FireAndWaterAlarm';
import Gate from '../Controls/Gate/Gate';
import GenericControl from '../Controls/GenericControl/GenericControl';
import Lights from '../Controls/Lights/Lights';
import Monitor from '../Controls/Monitor/Monitor';
import OperationalModeSelector from '../Controls/OperationalModeSelector/OperationalModeSelector';
import RoomController from '../Controls/RoomController/RoomController';
import StaircaseLight from '../Controls/StaircaseLight/StaircaseLight';
import Timer from '../Controls/Timer/Timer';
import ToiletVentilation from '../Controls/ToiletVentilation/ToiletVentilation';
import WindowsAndDoors from '../Controls/WindowsAndDoors/WindowsAndDoors';

export type VirtualDeviceViewContentViewProps = {
    virtualDevice: VirtualDevice;
};

const VirtualDeviceViewContent = (props: VirtualDeviceViewContentViewProps) => {
    switch (props.virtualDevice.type) {
        case VirtualDeviceType.LightController:
            return <Lights {...props} />;
        case VirtualDeviceType.Dimmer:
        case VirtualDeviceType.ConstantLightController:
            return <Dimmer {...props} />;
        case VirtualDeviceType.Schedule:
            return <Timer {...props} />;
        case VirtualDeviceType.ToiletVentilationController:
            return <ToiletVentilation {...props} />;
        case VirtualDeviceType.FireAndWaterAlarm:
            return <FireAndWaterAlarm {...props} />;
        case VirtualDeviceType.BurglarAlarm:
            return <AlarmSystem {...props} />;
        case VirtualDeviceType.AlarmClock:
            return <AlarmClock {...props} />;
        case VirtualDeviceType.GarageGate:
        case VirtualDeviceType.CentralGarageGate:
            return <Gate {...props} />;
        case VirtualDeviceType.StaircaseTimer:
            return <StaircaseLight {...props} />;
        case VirtualDeviceType.Blinds:
            return <Blinds {...props} />;
        case VirtualDeviceType.RoomController:
        case VirtualDeviceType.CentralRoomControl:
            return <RoomController {...props} />;
        case VirtualDeviceType.Monitor:
            return <Monitor {...props} />;
        case VirtualDeviceType.CentralBattery:
            return <Battery {...props} />;
        case VirtualDeviceType.CentralHomeButtons:
            return <CentralHomeButtons {...props} />;
        case VirtualDeviceType.CentralWindowsAndDoors:
            return <WindowsAndDoors {...props} />;
        case VirtualDeviceType.TotalEnergyMonitor:
            return <EnergyMonitor {...props} />;
        case VirtualDeviceType.CentralBlindsController:
            return <CentralBlinds {...props} />;
        case VirtualDeviceType.Camera:
            return <Camera {...props} />;
        case VirtualDeviceType.EnergyManagement:
            return <EnergyConfiguration {...props} />;
        case VirtualDeviceType.OperationalModeSelector:
            return <OperationalModeSelector {...props} />;
        case VirtualDeviceType.Alphatronics:
            return <AlphatronicControl {...props} />;
        default:
            return <GenericControl {...props} />;
    }
};

export default VirtualDeviceViewContent;
