import { CloseOutlined } from '@ant-design/icons';
import { ThemedButton, ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { useDatapoint } from 'hooks/useDatapoint';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import Server from 'models/Server';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { NotificationType } from 'models/server/enums/NotificationType';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from 'react-toastify';
import { NotificationInfo } from '../Notifications';
import * as Styled from './NotificationView.styles';

type Props = {
    notification: NotificationInfo;
    onRemoveNotification: (index: number) => void;
    index: number;
    server: Server;
    closable?: boolean;
};

enum DateSelectOption {
    hour = 60,
    hour6 = 360,
    hour12 = 720,
    day1 = 1440,
    day3 = 4320,
    week = 10080,
    never = 1000000,
}

const NotificationView = ({
    notification,
    index,
    server,
    onRemoveNotification,
    closable = true,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const { virtualDevices } = useServerConfig(server.id);
    const { apiServerFetchWithCustomPortalId } = useServerApi();
    const { onDatapointButtonClick } = useDatapoint();
    const [isLoading, setIsLoading] = useState(false);
    const [postopneOption, setPostponeOption] = useState<DateSelectOption>(DateSelectOption.hour);

    const isAlarm =
        notification.notificationType === NotificationType.FireAlarm ||
        notification.notificationType === NotificationType.SmokeAlarm ||
        notification.notificationType === NotificationType.WaterAlarm ||
        notification.notificationType === NotificationType.SystemAlarm;

    const canPostpone =
        notification.notificationType === NotificationType.DeviceNotResponding ||
        notification.notificationType === NotificationType.Custom ||
        notification.notificationType === NotificationType.Energy ||
        notification.notificationType === NotificationType.Battery;

    const dateSelectOptions = useMemo(
        () => [
            { value: DateSelectOption.hour, label: t('general.1hour') },
            { value: DateSelectOption.hour6, label: t('general.6hours') },
            { value: DateSelectOption.hour12, label: t('general.12hours') },
            { value: DateSelectOption.day1, label: t('general.1day') },
            { value: DateSelectOption.day3, label: t('general.3days') },
            { value: DateSelectOption.week, label: t('general.week') },
            { value: DateSelectOption.never, label: t('general.never') },
        ],
        [],
    );

    const onDisarm = async () => {
        const vd = virtualDevices?.find((x) => x.id === notification.virtualDeviceId);

        if (!vd) {
            return;
        }

        const quitDatapoint = vd.datapoints.find((x) => x.name === DatapointNames.VirtualQuitAlarm);

        if (!quitDatapoint) {
            return;
        }

        setIsLoading(true);

        const result = await onDatapointButtonClick(quitDatapoint.id, undefined, server.id);

        if (result) {
            onRemoveNotification(index);
        }

        setIsLoading(false);
    };

    const onDelay = async () => {
        setIsLoading(true);

        await apiServerFetchWithCustomPortalId(
            server.id,
            notification.notificationType === NotificationType.DeviceNotResponding
                ? 'systemnotification'
                : 'notification',
            undefined,
            'POST',
            {
                id: notification.id,
                postpone: dayjs().add(postopneOption, 'minute').format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T') + 'Z',
            },
        );

        onRemoveNotification(index);

        setIsLoading(false);
    };

    return (
        <Styled.MainWrapper>
            <Styled.IconWrapper>
                {notification.type === 'error'
                    ? Icons.error({
                          type: 'error',
                          theme: 'light',
                      })
                    : Icons.info({
                          type: 'info',
                          theme: 'light',
                      })}
            </Styled.IconWrapper>
            <Styled.TitleWrapper>
                <Styled.Title>{notification.title}</Styled.Title>
                <div>{notification.message}</div>
                {isAlarm && (
                    <Styled.NotificationButtonWrapper>
                        {closable && (
                            <ThemedButton disabled={isLoading} onClick={() => onRemoveNotification(index)}>
                                {t('general.close')}
                            </ThemedButton>
                        )}
                        <ThemedButton loading={isLoading} onClick={onDisarm}>
                            {t('alarmSystem.quiteAlarm')}
                        </ThemedButton>
                    </Styled.NotificationButtonWrapper>
                )}
                {canPostpone && (
                    <Styled.NotificationButtonWrapper>
                        <ThemedSelect
                            onChange={(v: unknown) => {
                                if (!v) {
                                    return;
                                }
                                setPostponeOption(v as DateSelectOption);
                            }}
                            dropdownStyle={{ zIndex: 999999999999999 }}
                            value={dateSelectOptions.find((x) => x.value === postopneOption)}
                            options={dateSelectOptions}
                            disabled={isLoading}
                        />
                        <ThemedButton $width={180} loading={isLoading} onClick={onDelay}>
                            {t('alarmSystem.postpone')}
                        </ThemedButton>
                    </Styled.NotificationButtonWrapper>
                )}
            </Styled.TitleWrapper>
            {!isAlarm && closable && (
                <Styled.CloseIconWrapper onClick={() => onRemoveNotification(index)}>
                    <CloseOutlined style={{ fontSize: 16 }} />
                </Styled.CloseIconWrapper>
            )}
        </Styled.MainWrapper>
    );
};

export default NotificationView;
