import { ThemedCheckboxGroup } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div<{ $connected: boolean }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 400px;
    min-width: 300px;
    height: 80px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    border-radius: 12px;
    margin-top: 15px;
    padding: 10px 15px;
    cursor: ${(props) => (props.$connected ? 'pointer' : 'not-allowed')};

    &:hover {
        background-color: ${(props) => props.theme.colors.backgroundLayer1 + 'CC'};
    }
`;

export const HeadWrapper = styled.div`
    display: flex;
`;

export const IconWrapper = styled.div<{ $isConnected: boolean }>`
    height: 50px;
    width: 50px;
    background: ${(props) => {
        const color = props.$isConnected ? props.theme.colors.success : props.theme.colors.error;
        return `linear-gradient(
            195deg,
            ${color},
            ${color + '99'}
        )`;
    }};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow:
        rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
        rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
    margin: -25px 0 0;
`;

export const ServerImage = styled.img`
    object-fit: scale-down;
    max-width: 28px;
`;

export const ServerName = styled.div`
    margin: 0 10px;
    font-weight: 500;
    flex: 1;
`;

export const SelectCheckbox = styled(ThemedCheckboxGroup)`
    margin-right: -15px;
`;

export const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
`;
