import { Space } from 'antd';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import { hasAnyControlsToShow } from 'components/ServerControls/ServerControls';
import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import { useVirtualDeviceSettings } from 'models/server/constants/useVirtualDeviceSettings';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as Styled from './ControlsPage.styles';
import ServersControls from './ServersControls/ServersControls';

const typeParam = 'type';

const ControlsPage = () => {
    const { configs } = useContext(GroupedServersConfigContext);
    const { t } = useTranslation();
    const { settings } = useVirtualDeviceSettings(t);

    const [searchParams, setSearchParams] = useSearchParams();

    const selectedType = useMemo(() => {
        const paramsType = searchParams.get(typeParam);

        return paramsType === null ? undefined : (Number(paramsType) as VirtualDeviceType);
    }, [searchParams]);

    const virtualDeviceTypes = useMemo(
        () =>
            Object.keys(VirtualDeviceType)
                .filter((x) => !isNaN(Number(x)))
                .map((x) => Number(x) as VirtualDeviceType)
                .filter((x) =>
                    configs
                        .flatMap((x) => x.setupInfo?.objects?.items ?? [])
                        .some((z) => z.type === x && hasAnyControlsToShow(z)),
                )
                .map((x) => ({ type: x, label: settings.find((z) => z.name === x)?.translation ?? '' }))
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                }),
        [configs],
    );

    const virtualDeviceTypesToShow = useMemo(() => {
        if (selectedType === undefined) {
            return virtualDeviceTypes.map((x) => x.type);
        }

        return [selectedType];
    }, [virtualDeviceTypes, selectedType]);

    useEffect(() => {
        if (
            selectedType &&
            !configs
                .flatMap((x) => x.setupInfo?.objects?.items ?? [])
                .some((z) => z.type === selectedType && hasAnyControlsToShow(z))
        ) {
            setSearchParams((params) => {
                params.delete(typeParam);
                return params;
            });
        }
    }, [virtualDeviceTypesToShow]);

    const onTagClicked = (tag: VirtualDeviceType) => {
        setSearchParams((params) => {
            if (selectedType === tag) {
                params.delete(typeParam);
            } else {
                params.set(typeParam, tag.toString());
            }
            return params;
        });
    };

    return (
        <Styled.Wrapper>
            <Space wrap>
                {virtualDeviceTypes.map((x) => (
                    <ThemedCheckableTag
                        checked={selectedType === x.type}
                        key={x.type}
                        onClick={() => onTagClicked(x.type)}
                    >
                        <Styled.TagTitle>{x.label}</Styled.TagTitle>
                    </ThemedCheckableTag>
                ))}
            </Space>
            <Line />
            {virtualDeviceTypesToShow.map((x) => (
                <ServersControls key={x} type={x} />
            ))}
        </Styled.Wrapper>
    );
};

export default ControlsPage;
