import { Space, Tooltip } from 'antd';
import BoxWithImage from 'components/BoxWithImage/BoxWithImage';
import { GroupedServerConfig } from 'components/ServerSelectRoute/ServerSelectBulkLoadingProvider/ServerSelectBulkLoadingProvider';
import { ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { useImages } from 'hooks/useImages';
import Server from 'models/Server';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdErrorOutline } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { EnergyReportConfig } from '../EnergyReportPage';
import * as Styled from './EnergyReportVirtualDevices.styles';

type Props = {
    server: Server;
    serverConfig?: GroupedServerConfig;
    config?: EnergyReportConfig;
    setConfig: Dispatch<SetStateAction<EnergyReportConfig[]>>;
};

export const EnergyReportVirtualDevices = ({ server, serverConfig, config, setConfig }: Props) => {
    const { darkImages } = useImages();
    const { t } = useTranslation();
    const { colors } = useTheme();

    const energyMonitors = useMemo(
        () => serverConfig?.setupInfo?.objects?.items?.filter((x) => x.type === VirtualDeviceType.TotalEnergyMonitor),
        [serverConfig],
    );

    const onChangeServerConfig = (config: EnergyReportConfig) => {
        setConfig((prev) => {
            if (prev.some((x) => x.server.id === server.id)) {
                return prev.map((x) => (x.server.id === server.id ? config : x));
            }

            return [...prev, config];
        });
    };

    const onSelect = (vdId: number) => {
        if (!config) {
            return;
        }

        if (config?.selectedVirtualDevices?.some((x) => x.id === vdId)) {
            onChangeServerConfig({
                ...config,
                selectedVirtualDevices: config.selectedVirtualDevices?.filter((x) => x.id !== vdId),
            });
            return;
        }

        onChangeServerConfig({
            ...config,
            selectedVirtualDevices: [
                ...(config?.selectedVirtualDevices ?? []),
                { id: vdId, loading: false, error: false, power: [], energy: [] },
            ],
        });
    };

    if (!energyMonitors?.length) {
        return <></>;
    }

    return (
        <>
            <Styled.Title>{server.name}</Styled.Title>
            <Space size={10} wrap>
                {energyMonitors?.map((energy) => (
                    <Tooltip
                        key={energy.id}
                        color={colors.error}
                        title={
                            config?.selectedVirtualDevices?.find((x) => x.id === energy.id)?.error
                                ? t('errors.couldNotGetDataFromServer')
                                : undefined
                        }
                    >
                        <BoxWithImage
                            title={`${energy.name} - ${
                                serverConfig?.setupInfo?.rooms?.find((r) => r.id === energy.roomid)?.name ??
                                t('general.unassigned')
                            }`}
                            selectable={{
                                onSelect: onSelect,
                                selected: !!config?.selectedVirtualDevices?.some((x) => x.id === energy.id),
                                id: energy.id,
                            }}
                            image={darkImages.energy}
                        >
                            {config?.selectedVirtualDevices?.find((x) => x.id === energy.id)?.loading && <ThemedSpin />}
                            {config?.selectedVirtualDevices?.find((x) => x.id === energy.id)?.error && (
                                <MdErrorOutline size={60} color={colors.error} />
                            )}
                        </BoxWithImage>
                    </Tooltip>
                ))}
            </Space>
        </>
    );
};
