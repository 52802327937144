import { ReactNode } from 'react';
import { MdErrorOutline } from 'react-icons/md';
import * as Styled from './EmptyError.styles';

type Props = {
    title: string;
    children?: ReactNode;
    className?: string;
    fontSize?: number;
};

const EmptyError = ({ title, children, className, fontSize = 80 }: Props) => {
    return (
        <Styled.MainWrapper className={className}>
            <MdErrorOutline color="#e74c3c" fontSize={fontSize} />
            <Styled.MainTitle>{title}</Styled.MainTitle>
            {children}
        </Styled.MainWrapper>
    );
};

export default EmptyError;
