import { routes } from 'App';
import WholePageSpiner from 'components/WholePageSpiner/WholePageSpiner';
import { useApi } from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import { useThemeStorage } from 'hooks/useThemeStorage';
import DashboardConfig from 'models/DashboardConfig';
import Server from 'models/Server';
import { PortalUser } from 'models/User';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../../contexts/AuthContext';

export const demoTypeSearch = 'type';
export const demoTypeSearchValue = 'demo';

const ProtectedRoute = () => {
    const { t } = useTranslation();

    const { authUser, getIsLoggedIn } = useAuth();
    const { apiWithCustomLicenseFetch } = useApi();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { currentTheme, setCurrentTheme } = useThemeStorage();

    const [config, setConfig] = useState<{ servers: Server[]; isDemo: boolean; dashboardConfigs: DashboardConfig[] }>();
    const [isLoading, setIsLoading] = useState(false);

    const onDemo = () =>
        setConfig({
            isDemo: true,
            servers: [
                {
                    id: 0,
                    name: t('general.demoServer'),
                    tags: [
                        { id: 0, serverId: 0, value: t('general.mainOffice') },
                        { id: 1, serverId: 0, value: t('general.demoServer') },
                    ],
                    connected: true,
                    address: '',
                    login: t('general.demoUser'),
                    licenseId: 0,
                    latitude: '50.110924',
                    longitude: '8.682127',
                },
            ],
            dashboardConfigs: [],
        });

    const checkServers = async () => {
        try {
            if (searchParams.get(demoTypeSearch) === demoTypeSearchValue) {
                onDemo();
                return;
            }

            setIsLoading(true);
            const result = await getIsLoggedIn();

            if (!result || !authUser) {
                navigate(routes.login);
                return;
            }

            const currentServers: Server[] = [];

            if (authUser.isLocalUser) {
                currentServers.push({
                    id: 0,
                    name: t('general.myServer'),
                    tags: [],
                    connected: true,
                    address: '',
                    login: authUser.username,
                    licenseId: 0,
                    latitude: '',
                    longitude: '',
                });
            }

            const userServerConfigs: DashboardConfig[] = [];

            for (const portalUser of authUser.portalUsers) {
                const servers = await apiWithCustomLicenseFetch<Server[]>(portalUser.licenseId, 'Servers');

                if (!servers.result || servers.code !== 200) {
                    continue;
                }

                currentServers.push(...servers.result.map((x) => ({ ...x, licenseId: portalUser.licenseId })));

                const userServer = await apiWithCustomLicenseFetch<PortalUser>(portalUser.licenseId, 'User');

                if (!userServer.result || userServer.code !== 200) {
                    continue;
                }

                userServerConfigs.push(...(userServer.result.configuration?.dashboard ?? []));
            }

            setConfig({
                isDemo: false,
                servers: currentServers,
                dashboardConfigs: userServerConfigs.map((x) => ({
                    ...x,
                    monitorIos: x.monitorIos.map((m) => ({
                        ...m,
                    })),
                })),
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkServers();
    }, []);

    if (!config) {
        return <WholePageSpiner />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...config,
                theme: currentTheme,
                isLoading: isLoading,
                setTheme: (theme) => setCurrentTheme(theme),
                onRefetchServers: checkServers,
            }}
        >
            <ToastContainer style={{ zIndex: 999999998 }} theme={currentTheme} />
            <Outlet />
        </AuthContext.Provider>
    );
};

export default ProtectedRoute;
