export const getEnergyStatistics = () =>
    JSON.parse(
        JSON.stringify({
            statistic: {
                powerStats: [
                    {
                        date: '2023-10-19T15:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T15:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T15:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T16:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T16:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T17:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T17:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T17:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T18:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T18:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T19:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T19:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T19:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T20:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T20:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T21:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -1.7382166666666667,
                            },
                            {
                                ioid: 66,
                                value: 0.36436805555555557,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -2.3738486111111112,
                    },
                    {
                        date: '2023-10-19T21:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -47,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -38,
                    },
                    {
                        date: '2023-10-19T21:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.75868333333333,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.75868333333333,
                    },
                    {
                        date: '2023-10-19T22:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.207916666666669,
                            },
                            {
                                ioid: 66,
                                value: 9.4764194444444438,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.731497222222224,
                    },
                    {
                        date: '2023-10-19T22:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43,
                            },
                            {
                                ioid: 66,
                                value: 9.2974125,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.7025875,
                    },
                    {
                        date: '2023-10-19T23:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.207875,
                            },
                            {
                                ioid: 66,
                                value: 9.96899513888889,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.238879861111116,
                    },
                    {
                        date: '2023-10-19T23:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.445829861111108,
                            },
                            {
                                ioid: 66,
                                value: 9,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -35.445829861111108,
                    },
                    {
                        date: '2023-10-19T23:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -47.559003472222223,
                            },
                            {
                                ioid: 66,
                                value: 9.7487708333333334,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -38.810232638888891,
                    },
                    {
                        date: '2023-10-20T00:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -45.552888888888887,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -36.552888888888887,
                    },
                    {
                        date: '2023-10-20T00:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43,
                            },
                            {
                                ioid: 66,
                                value: 9.024338888888888,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.975661111111108,
                    },
                    {
                        date: '2023-10-20T01:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.312940972222222,
                            },
                            {
                                ioid: 66,
                                value: 9.6943222222222225,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.618618749999996,
                    },
                    {
                        date: '2023-10-20T01:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.832288194444445,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.832288194444445,
                    },
                    {
                        date: '2023-10-20T01:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.533279166666667,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.533279166666667,
                    },
                    {
                        date: '2023-10-20T02:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34,
                    },
                    {
                        date: '2023-10-20T02:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.208166666666664,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.208166666666664,
                    },
                    {
                        date: '2023-10-20T03:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.416680555555558,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.416680555555558,
                    },
                    {
                        date: '2023-10-20T03:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -42.461386111111111,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -33.461386111111111,
                    },
                    {
                        date: '2023-10-20T03:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.141657638888887,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.141657638888887,
                    },
                    {
                        date: '2023-10-20T04:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.250695833333332,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.250695833333332,
                    },
                    {
                        date: '2023-10-20T04:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43,
                            },
                            {
                                ioid: 66,
                                value: 9.3120833333333337,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.687916666666666,
                    },
                    {
                        date: '2023-10-20T05:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.249766666666666,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.249766666666666,
                    },
                    {
                        date: '2023-10-20T05:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34,
                    },
                    {
                        date: '2023-10-20T05:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.385489583333332,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.385489583333332,
                    },
                    {
                        date: '2023-10-20T06:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.18181527777778,
                            },
                            {
                                ioid: 66,
                                value: 9.4269180555555554,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.754897222222226,
                    },
                    {
                        date: '2023-10-20T06:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -42.3867375,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -33.3867375,
                    },
                    {
                        date: '2023-10-20T07:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.2909375,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.2909375,
                    },
                    {
                        date: '2023-10-20T07:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.208020833333336,
                            },
                            {
                                ioid: 66,
                                value: 9.6765097222222227,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.531511111111115,
                    },
                    {
                        date: '2023-10-20T07:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.286520833333334,
                            },
                            {
                                ioid: 66,
                                value: 9.6896006944444437,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.596920138888891,
                    },
                    {
                        date: '2023-10-20T08:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.729121527777778,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.729121527777778,
                    },
                    {
                        date: '2023-10-20T08:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.937107638888889,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.937107638888889,
                    },
                    {
                        date: '2023-10-20T09:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -44.12425625,
                            },
                            {
                                ioid: 66,
                                value: 9.5476388888888888,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -35.576617361111111,
                    },
                    {
                        date: '2023-10-20T09:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -43.832489583333334,
                            },
                            {
                                ioid: 66,
                                value: 9.8678930555555553,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -34.964596527777779,
                    },
                    {
                        date: '2023-10-20T09:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -46.721325,
                            },
                            {
                                ioid: 66,
                                value: 10,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -37.721325,
                    },
                    {
                        date: '2023-10-20T10:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: -46.128743055555553,
                            },
                            {
                                ioid: 66,
                                value: 3.0324479166666665,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -44.096295138888884,
                    },
                    {
                        date: '2023-10-20T10:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: -40.720375694444442,
                            },
                            {
                                ioid: 66,
                                value: 0.31963888888888886,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -41.400736805555553,
                    },
                    {
                        date: '2023-10-20T11:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: -40.413133333333334,
                            },
                            {
                                ioid: 66,
                                value: 1.0639444444444444,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -40.349188888888889,
                    },
                    {
                        date: '2023-10-20T11:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: -42.457904166666665,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -43.457904166666665,
                    },
                    {
                        date: '2023-10-20T11:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: -20.891730555555554,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -21.891730555555554,
                    },
                    {
                        date: '2023-10-20T12:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T12:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T13:00:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T13:24:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T13:48:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T14:12:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T14:36:00',
                        items: [
                            {
                                ioid: 63,
                                value: 0,
                            },
                            {
                                ioid: 66,
                                value: 0,
                            },
                            {
                                ioid: 97,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                ],
                energyStats: [
                    {
                        date: '2023-10-19T15:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T16:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T17:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T18:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T19:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-19T20:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1.003,
                            },
                        ],
                        total: -1.003,
                    },
                    {
                        date: '2023-10-19T21:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -26.3094,
                            },
                            {
                                ioid: 67,
                                value: 5.5427599999999995,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -21.766640000000002,
                    },
                    {
                        date: '2023-10-19T22:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -40.5043,
                            },
                            {
                                ioid: 67,
                                value: 9.9163500000000013,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -31.58795,
                    },
                    {
                        date: '2023-10-19T23:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -43.5355,
                            },
                            {
                                ioid: 67,
                                value: 7.77544,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -36.760059999999996,
                    },
                    {
                        date: '2023-10-20T00:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -44.1642,
                            },
                            {
                                ioid: 67,
                                value: 9.5502899999999986,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -35.613910000000004,
                    },
                    {
                        date: '2023-10-20T01:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -41.670100000000005,
                            },
                            {
                                ioid: 67,
                                value: 9.91154,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -32.75856,
                    },
                    {
                        date: '2023-10-20T02:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -44.6633,
                            },
                            {
                                ioid: 67,
                                value: 9.68022,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -35.98308,
                    },
                    {
                        date: '2023-10-20T03:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -40.1938,
                            },
                            {
                                ioid: 67,
                                value: 9.1533,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -32.0405,
                    },
                    {
                        date: '2023-10-20T04:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -43.161,
                            },
                            {
                                ioid: 67,
                                value: 9.89978,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -34.26122,
                    },
                    {
                        date: '2023-10-20T05:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -42.0344,
                            },
                            {
                                ioid: 67,
                                value: 9.90602,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -33.12838,
                    },
                    {
                        date: '2023-10-20T06:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -43.538000000000004,
                            },
                            {
                                ioid: 67,
                                value: 9.90189,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -34.63611,
                    },
                    {
                        date: '2023-10-20T07:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -42.112700000000004,
                            },
                            {
                                ioid: 67,
                                value: 9.980080000000001,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -33.13262,
                    },
                    {
                        date: '2023-10-20T08:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -44.7652,
                            },
                            {
                                ioid: 67,
                                value: 9.95445,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -35.81075,
                    },
                    {
                        date: '2023-10-20T09:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -41.1673,
                            },
                            {
                                ioid: 67,
                                value: 9.71997,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -32.447329999999994,
                    },
                    {
                        date: '2023-10-20T10:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -46.442499999999995,
                            },
                            {
                                ioid: 67,
                                value: 4.07303,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -43.369469999999993,
                    },
                    {
                        date: '2023-10-20T11:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -43.1319,
                            },
                            {
                                ioid: 67,
                                value: 0.641201,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -43.490699,
                    },
                    {
                        date: '2023-10-20T12:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: -8.14326,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -9.14326,
                    },
                    {
                        date: '2023-10-20T13:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1.004,
                            },
                        ],
                        total: -1.004,
                    },
                    {
                        date: '2023-10-20T14:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -1,
                            },
                        ],
                        total: -1,
                    },
                    {
                        date: '2023-10-20T15:00:00',
                        items: [
                            {
                                ioid: 9,
                                value: 0,
                            },
                            {
                                ioid: 64,
                                value: 0,
                            },
                            {
                                ioid: 67,
                                value: 0,
                            },
                            {
                                ioid: 98,
                                value: -0.91699999999999982,
                            },
                        ],
                        total: -0.91699999999999982,
                    },
                ],
                producersTotalEnergy: [
                    {
                        ioid: 67,
                        value: 125.60632099999998,
                    },
                ],
                consumersTotalEnergy: [
                    {
                        ioid: 64,
                        value: 635.53686,
                    },
                    {
                        ioid: 98,
                        value: 24.924,
                    },
                ],
            },
        }),
    );
