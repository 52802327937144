import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
`;
