import { Space } from 'antd';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    margin: 20px 0;

    @media (max-width: 500px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`;

export const MainWrapper = styled.div`
    width: 100%;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
`;

export const Row = styled(Space)`
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
`;
