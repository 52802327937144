import { Space, Tooltip } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import {
    ThemedButton,
    ThemedCheckableTag,
    ThemedInput,
    ThemedSpin,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { isEmpty } from 'helpers/StringHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { Datapoint } from 'models/server/Datapoint';
import { OpeartionModeConfigType, OperationMode } from 'models/server/OperationModeConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { useTheme } from 'styled-components';
import BlockValueView from './BlockValueView/BlockValueView';
import * as Styled from './VisualizationBlockEditPage.styles';

const VisualizationBlockEditPage = () => {
    const { t } = useTranslation();
    const { currentServer, currentVirtualDevice, serverConfigLoading } = useServerConfig();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { colors } = useTheme();
    const { onGoBack } = useGoBack();

    const modeConfigDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.VisualizationBlockConfig),
        [currentVirtualDevice?.datapoints],
    );
    const [modes, setModes] = useState<OperationMode[]>(modeConfigDatapoint?.Modes ?? []);
    const [selectedModeIndex, setSelectedModeIndex] = useState<number>(0);

    const currentMode = useMemo(() => modes[selectedModeIndex], [modes, selectedModeIndex]);

    const onChangeName = (v: string) => {
        setModes((prev) => prev.map((scene, index) => (index === selectedModeIndex ? { ...scene, Name: v } : scene)));
    };

    useEffect(() => {
        setModes(modeConfigDatapoint?.Modes ?? []);
    }, [modeConfigDatapoint?.Modes]);

    const saveDisabled = useMemo(() => {
        const names = modes.map((x) => x.Name);
        return names.some((name) => isEmpty(name)) || names.some((item, index) => names.indexOf(item) !== index);
    }, [modes]);

    const hasError = (scene: OperationMode) => {
        return isEmpty(scene.Name) || modes.filter((x) => x.Name === scene.Name).length > 1;
    };

    const currentError = useMemo(() => {
        if (!currentMode) {
            return;
        }

        if (isEmpty(currentMode.Name)) {
            return t('error.nameCannotBeEmpty');
        }

        if (modes.filter((x) => x.Name === currentMode.Name).length > 1) {
            return t('error.nameMustBeUnique');
        }
    }, [currentMode, modes]);

    const onValueChanged = (v: string, valueIndex: number) => {
        setModes((prev) =>
            prev.map((mode, index) =>
                index === selectedModeIndex
                    ? {
                          ...mode,
                          values: mode.values.map((val, valIndex) =>
                              valIndex === valueIndex ? { ...val, Value: v } : val,
                          ),
                      }
                    : mode,
            ),
        );
    };

    const onRemoveScene = (index: number) => {
        setModes((prev) => prev.filter((scene) => scene !== prev[index]));
        setSelectedModeIndex((prev) => (index > prev ? prev : prev - 1));
    };

    const getNewModeName = () => {
        const newSceneTranslation = t('sceneEdit.newMode');
        if (!modes.find((x) => x.Name == newSceneTranslation)) {
            return newSceneTranslation;
        }

        let i = 1;
        while (modes.find((x) => x.Name == `${newSceneTranslation} ${i}`)) {
            i++;
        }

        return `${newSceneTranslation} ${i}`;
    };

    const getDefaultModeValue = (valueName: string) => {
        const config = modeConfigDatapoint?.Configs?.find((x) => x.Name === valueName);

        switch (config?.Type) {
            case OpeartionModeConfigType.Switch:
                return true;
            case OpeartionModeConfigType.Dropdown:
                return config.DropdownList[0].Value.toString();
            default:
                return '100';
        }
    };

    const addMode = async () => {
        const newMode: OperationMode = {
            ID: Math.max(...modes.map((x) => x.ID)) + 1,
            Name: getNewModeName(),
            values: modes[0].values.map((value) => ({ ...value, Value: getDefaultModeValue(value.Name) })),
        };

        const newModes = [...modes, newMode];

        setModes(newModes);
        setSelectedModeIndex(newModes.length - 1);
    };

    const onSave = async () => {
        if (!modeConfigDatapoint) {
            return;
        }

        const newDatapoint: Datapoint = {
            ...modeConfigDatapoint,
            Modes: modes,
        };

        const result = await onDatapointChange(newDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!modeConfigDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (modeConfigDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.WholePageSpace size={[0, 8]} wrap>
                    {modes?.map((mode, index) => (
                        <ThemedCheckableTag
                            $error={hasError(mode)}
                            key={index}
                            onClick={() => setSelectedModeIndex(index)}
                            checked={selectedModeIndex === index}
                        >
                            <Styled.TagWrapper>
                                <Styled.TagTitle>{mode.Name}</Styled.TagTitle>
                                {index !== 0 && (
                                    <Styled.IconWrapper>
                                        <IoMdCloseCircleOutline
                                            onClick={(e) => {
                                                onRemoveScene(index);
                                                e.stopPropagation();
                                            }}
                                            size={15}
                                        />
                                    </Styled.IconWrapper>
                                )}
                            </Styled.TagWrapper>
                        </ThemedCheckableTag>
                    ))}
                    <ThemedCheckableTag onClick={addMode} checked={false} $dashed>
                        <Styled.TagWrapper>
                            <MdAdd fontSize={25} />
                        </Styled.TagWrapper>
                    </ThemedCheckableTag>
                </Styled.WholePageSpace>
                <Space size={20} wrap>
                    <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                    <ThemedButton $action onClick={onSave} disabled={saveDisabled}>
                        {t('general.save')}
                    </ThemedButton>
                </Space>
            </Styled.HeaderWrapper>
            {currentMode && (
                <>
                    <Tooltip open={!!currentError} title={currentError} color={colors.error}>
                        <ThemedInput value={currentMode.Name} onChange={(v) => onChangeName(v.currentTarget.value)} />
                    </Tooltip>
                    <Space size={20} wrap>
                        {currentMode.values.map((value, index) => (
                            <BlockValueView
                                key={index}
                                mode={value}
                                config={modeConfigDatapoint?.Configs?.find((x) => x.Name === value.Name)}
                                virtualDevice={currentVirtualDevice}
                                isOffScene={false}
                                onValueChanged={(v) => onValueChanged(v, index)}
                            />
                        ))}
                    </Space>
                </>
            )}
        </Styled.MainWrapper>
    );
};

export default VisualizationBlockEditPage;
