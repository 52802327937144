import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import DatapointButtonView from '../DatapointButtonView/DatapointButtonView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import HidingView from '../HidingView/HidingView';
import * as Styled from './AlarmSystem.styles';

const AlarmSystem = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints, category } = virtualDevice;

    const alarmIsArmedDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualAlarmSystemIsArmed,
            ),
        [datapoints],
    );
    const alarmWithoutPresenceDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) =>
                    x.type == DatapointType.StatusLight &&
                    x.name == DatapointNames.VirtualArmedWithoutPresenceDetectors,
            ),
        [datapoints],
    );
    const isAlarmDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.StatusLight && x.name == DatapointNames.VirtualIsAlarm),
        [datapoints],
    );
    const activationCountdownDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualAcivationCountdown,
            ),
        [datapoints],
    );
    const quitAlarmDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarm),
        [datapoints],
    );
    const armAlarmDatapoint = useMemo(
        () => datapoints?.find((x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmAlarmSystem),
        [datapoints],
    );
    const armAlarmDelayedDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmAlarmSystemDelayed,
            ),
        [datapoints],
    );
    const armWithoutPresenceDetectorsDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmWithoutPresenceDetectors,
            ),
        [datapoints],
    );
    const disarmDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualDisarmAlarmSystem,
            ),
        [datapoints],
    );

    const datapointButtons = [
        armAlarmDatapoint,
        armWithoutPresenceDetectorsDatapoint,
        armAlarmDelayedDatapoint,
        disarmDatapoint,
        quitAlarmDatapoint,
    ];

    return (
        <Styled.MainWrapper>
            {alarmIsArmedDatapoint && <DatapointValueView showYesNoAsBoolValue valueOrDp={alarmIsArmedDatapoint} />}
            {alarmWithoutPresenceDatapoint && (
                <DatapointValueView showYesNoAsBoolValue valueOrDp={alarmWithoutPresenceDatapoint} />
            )}
            {isAlarmDatapoint && <DatapointValueView showOnOffAsBoolValue valueOrDp={isAlarmDatapoint} />}
            {activationCountdownDatapoint && <DatapointValueView sufix="s" valueOrDp={activationCountdownDatapoint} />}
            <HidingView category={category} numberOfVisible={0}>
                {datapointButtons.map((dp, index) => dp && <DatapointButtonView key={index} datapoint={dp} />)}
            </HidingView>
        </Styled.MainWrapper>
    );
};

export default AlarmSystem;
