import { Spin } from 'antd';
import { ThemedButton, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import i18n from 'i18next';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { useEffect, useRef, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import * as Styled from './AstroMap.styles';

interface LocationMarkerProps {
    positionClicked?: (position: { lat: number; lng: number }) => void;
}

const provider = new OpenStreetMapProvider({ params: { 'accept-language': i18n.resolvedLanguage ?? 'en' } });

const Search = () => {
    const map = useMap();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const searchControl = new GeoSearchControl({
            provider,
            showMarker: false,
        });

        map.addControl(searchControl);
        return () => {
            map.removeControl(searchControl);
        };
    }, []);

    return null;
};

const LocationMarker = ({ positionClicked }: LocationMarkerProps) => {
    useMapEvents({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        click(e: any) {
            positionClicked?.(e.latlng);
        },
    });

    return null;
};

interface Props {
    onCloseRequested: () => void;
    onSave: (position: { lat: string; lng: string }) => void;
    position: { lat: string; lng: string };
}

export const getLabel = async (lat: number, lng: number): Promise<string> => {
    const result = await provider.search({
        query: `${lat.toFixed(14)}, ${lng.toFixed(14)}`,
    });

    return result?.[0]?.label ?? '?';
};

const AstroMap = (props: Props): JSX.Element => {
    const { position, onSave, onCloseRequested } = props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const markerRef = useRef<any>(null);

    const [isLabelLoading, setIsLabelLoading] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<[number, number]>([
        Number(position.lat),
        Number(position.lng),
    ]);
    const [zoom, setZoom] = useState<number>();
    const [label, setLabel] = useState('');

    useEffect(() => {
        (async () => {
            setIsLabelLoading(true);
            setLabel(await getLabel(Number(currentPosition[0]), Number(currentPosition[1])));
            markerRef?.current?.openPopup();
            setIsLabelLoading(false);
        })();
    }, [currentPosition]);

    useEffect(() => {
        setZoom(13);
    }, []);

    return (
        <ThemedModal
            open={true}
            onCancel={onCloseRequested}
            style={{ paddingLeft: 0, paddingRight: 0 }}
            width="80vw"
            footer={
                <Styled.Footer>
                    <ThemedButton
                        $version="2"
                        onClick={() =>
                            onSave({ lat: currentPosition[0].toString(), lng: currentPosition[1].toString() })
                        }
                    >
                        OK
                    </ThemedButton>
                </Styled.Footer>
            }
        >
            <div style={{ height: '60vh', padding: 15, paddingBottom: 0 }}>
                {zoom && (
                    <MapContainer style={{ height: '100%' }} center={currentPosition} zoom={zoom}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker positionClicked={(e) => setCurrentPosition([e.lat, e.lng])} />
                        <Search />
                        <Marker ref={markerRef} position={currentPosition}>
                            <Popup>{isLabelLoading ? <Spin /> : label}</Popup>
                        </Marker>
                    </MapContainer>
                )}
            </div>
        </ThemedModal>
    );
};

export default AstroMap;
