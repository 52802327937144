import { Space, Tooltip } from 'antd';
import { Line } from 'components/PageLayout/SideMenu/SideMenu.styles';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import YesNoModal from 'components/YesNoModal/YesNoModal';
import { AuthContext } from 'contexts/AuthContext';
import { useApi } from 'hooks/useApi';
import { useImages } from 'hooks/useImages';
import Server from 'models/Server';
import { IconWrapper } from 'pages/SceneEditPage/SceneEditPage.styles';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import AddTagModal from './AddTagModal/AddTagModal';
import * as Styled from './AdminServerView.styles';
import EditServerModal from './EditServerModal/EditServerModal';

type Props = {
    server: Server;
    servers: Server[];
    onReloadServers: () => void;
};

const AdminServerView = ({ server, servers, onReloadServers }: Props) => {
    const { t } = useTranslation();
    const { darkImages } = useImages();
    const { colors } = useTheme();
    const { apiFetch } = useApi();
    const { onRefetchServers } = useContext(AuthContext);

    const [currentModal, setCurrentModal] = useState<'addTag' | 'removeServer' | 'edit'>();
    const [loadingTag, setLoadingTag] = useState<number | undefined>();

    const tagsToAdd = useMemo(() => {
        const tags = servers
            .flatMap((x) => x.tags)
            .map((x) => x.value)
            .filter((x) => !server.tags.some((z) => z.value === x));

        return tags.filter((c, index) => {
            return tags.indexOf(c) === index;
        });
    }, [servers, server]);

    const onAddTag = () => {
        setCurrentModal('addTag');
    };

    const onRemoveServer = () => {
        setCurrentModal('removeServer');
    };

    const onEditServer = () => {
        setCurrentModal('edit');
    };

    const onCloseModal = () => {
        setCurrentModal(undefined);
    };

    const onDelete = async () => {
        const result = await apiFetch('Servers', 'DELETE', `/${server.id}/Delete`);

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }

        onReloadServers();
        onRefetchServers?.();
    };

    const onRemoveTag = async (tagId: number) => {
        setLoadingTag(tagId);
        const result = await apiFetch('Servers', 'DELETE', `/${server.id}/DeleteTag?tagId=${tagId}`);

        if (result.code !== 200) {
            toast.error(t('errors.errorWhileSendingValue'));
            return;
        }

        onReloadServers();
        setLoadingTag(undefined);
    };

    return (
        <Tooltip
            color={server.connected ? colors.success : colors.error}
            title={server.connected ? t('general.serverConnected') : t('general.notConnected')}
        >
            <Styled.MainWrapper>
                <Styled.HeadWrapper>
                    <Styled.IconWrapper $isConnected={server.connected}>
                        <Styled.ServerImage src={darkImages.server} />
                    </Styled.IconWrapper>
                    <Styled.ServerName>{`${server.name}${
                        server.address ? ` - ${server.address}` : ''
                    }`}</Styled.ServerName>
                </Styled.HeadWrapper>
                <Line />
                <Space size={[0, 8]} wrap>
                    {server.tags.map((tag, index) => (
                        <Styled.ThemedTagWrapper key={index} color={colors.backgroundLayer2}>
                            {tag.value}
                            {loadingTag === tag.id && <ThemedSpin size="small" />}
                            {loadingTag !== tag.id && (
                                <IconWrapper $disabled={!!loadingTag}>
                                    <IoMdCloseCircleOutline
                                        onClick={() => !loadingTag && onRemoveTag(tag.id)}
                                        size={15}
                                    />
                                </IconWrapper>
                            )}
                        </Styled.ThemedTagWrapper>
                    ))}
                    <Tooltip title={t('general.addTag')}>
                        <Styled.AddButton checked={false} $dashed onClick={onAddTag}>
                            <MdAdd fontSize={25} />
                        </Styled.AddButton>
                    </Tooltip>
                </Space>
                <Line />
                <Space size={10} wrap>
                    <ThemedButton onClick={onEditServer}>{t('general.edit')}</ThemedButton>
                    <ThemedButton danger onClick={onRemoveServer}>
                        {t('general.delete')}
                    </ThemedButton>
                </Space>
            </Styled.MainWrapper>
            {currentModal === 'addTag' && (
                <AddTagModal
                    otherTags={tagsToAdd}
                    serverId={server.id}
                    onCancel={onCloseModal}
                    onRefresh={onReloadServers}
                />
            )}
            {currentModal === 'removeServer' && (
                <YesNoModal
                    description={`${t('general.areYouSureYouWantDeleteServer')} ${server.name}?`}
                    onYesClicked={onDelete}
                    onNoClicked={onCloseModal}
                    yesDanger
                />
            )}
            {currentModal === 'edit' && (
                <EditServerModal server={server} onCancel={onCloseModal} onRefresh={onReloadServers} />
            )}
        </Tooltip>
    );
};

export default AdminServerView;
