import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { ThemedButton, ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { ServerContext } from 'contexts/ServerContext';
import { useDatapoint } from 'hooks/useDatapoint';
import { useServerConfig } from 'hooks/useServerConfig';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { RoomControllerMode } from 'models/server/enums/RoomControllerMode';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import DatapointSliderView from '../DatapointSliderView/DatapointSliderView';
import DatapointValueView from '../DatapointValueView/DatapointValueView';
import * as Styled from './RoomController.styles';

const RoomController = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { t } = useTranslation();
    const { datapoints } = virtualDevice;
    const navigate = useNavigate();
    const { currentServer } = useServerConfig();
    const { datapointLoading, onDatapointValueChange } = useDatapoint();
    const { navigationPath } = useContext(ServerContext);

    const currentTemperatureDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Temperature && x.name == DatapointNames.VirtualIsRoomTemperature,
            ),
        [datapoints],
    );
    const humidityDatapoint = useMemo(() => datapoints?.find((x) => x.type == DatapointType.Humidity), [datapoints]);
    const modeDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) =>
                    x.type == DatapointType.RoomControllerOperationMode &&
                    x.name == DatapointNames.VirtualOperationMode,
            ),
        [datapoints],
    );
    const manualCoolingDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) =>
                    x.type == DatapointType.Temperature &&
                    (x.name == DatapointNames.VirtualManualModeCooling ||
                        x.name == DatapointNames.VirtualSetpointManualModeCooling),
            ),
        [datapoints],
    );
    const manualHeatingDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) =>
                    x.type == DatapointType.Temperature &&
                    (x.name == DatapointNames.VirtualManualModeHeating ||
                        x.name == DatapointNames.VirtualSetpointManualModeHeating),
            ),
        [datapoints],
    );
    const standbyDatapoint = useMemo(
        () =>
            datapoints?.find(
                (x) => x.type == DatapointType.Temperature && x.name == DatapointNames.VirtualStandbyTemperature,
            ),
        [datapoints],
    );

    const modes = [
        {
            id: RoomControllerMode.FullAutomatic,
            name: t('roomController.fullAutomatic'),
        },
        {
            id: RoomControllerMode.AutomaticHeating,
            name: t('roomController.automaticHeating'),
        },
        {
            id: RoomControllerMode.AutomaticCooling,
            name: t('roomController.automaticCooling'),
        },
        {
            id: RoomControllerMode.ManualHeating,
            name: t('roomController.manualHeating'),
        },
        {
            id: RoomControllerMode.ManualCooling,
            name: t('roomController.manualCooling'),
        },
        {
            id: RoomControllerMode.Standby,
            name: t('roomController.standby'),
        },
    ];

    const getModeForId = (id: number) => {
        return modes.find((x) => x.id == id);
    };

    const currentModes = useMemo(() => {
        return modeDatapoint?.range?.map((x) => ({
            value: Number(x),
            label: getModeForId(Number(x))?.name ?? '',
        }));
    }, [modeDatapoint]);

    const selectedMode = useMemo(() => {
        return currentModes?.find((x) => x.value === Number(modeDatapoint?.value ?? 0));
    }, [modeDatapoint, currentModes]);

    const onEditTimeProgram = () => {
        navigate(
            {
                pathname: `${navigationPath}/${currentServer?.id}/heating/${virtualDevice.id}`,
            },
            { state: { path: window.location.pathname, search: window.location.search } },
        );
    };

    const onModeChanged = (v: unknown) => {
        if (!modeDatapoint) {
            return;
        }

        onDatapointValueChange(modeDatapoint.id, v as number);
    };

    return (
        <Styled.MainWrapper>
            {currentTemperatureDatapoint && (
                <DatapointValueView rounding={1} valueOrDp={currentTemperatureDatapoint} sufix="°C" />
            )}
            {humidityDatapoint && <DatapointValueView valueOrDp={humidityDatapoint} sufix="%" />}
            {modeDatapoint && (
                <Styled.SelectWrapper>
                    <ThemedSelect
                        disabled={modeDatapoint.writeprotect}
                        value={selectedMode}
                        options={currentModes}
                        onChange={onModeChanged}
                        $version="virtualDevice"
                        loading={datapointLoading === modeDatapoint.id}
                    />
                    <ThemedButton
                        $width={32}
                        $version="virtualDevice"
                        shape="round"
                        icon={<MdOutlineEdit size={18} />}
                        onClick={onEditTimeProgram}
                    />
                </Styled.SelectWrapper>
            )}
            {selectedMode?.value === RoomControllerMode.ManualHeating && manualHeatingDatapoint && (
                <DatapointSliderView datapoint={manualHeatingDatapoint} virtualDevice={virtualDevice} sufix="°C" />
            )}
            {selectedMode?.value === RoomControllerMode.ManualCooling && manualCoolingDatapoint && (
                <DatapointSliderView datapoint={manualCoolingDatapoint} virtualDevice={virtualDevice} sufix="°C" />
            )}
            {selectedMode?.value === RoomControllerMode.Standby && standbyDatapoint && (
                <DatapointSliderView datapoint={standbyDatapoint} virtualDevice={virtualDevice} sufix="°C" />
            )}
        </Styled.MainWrapper>
    );
};

export default RoomController;
