import { ThemedSelect, ThemedSlider } from 'components/ThemedComponents/ThemedComponents.styles';
import { useImages } from 'hooks/useImages';
import { useServerConfig } from 'hooks/useServerConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './SwitchpointDeviceView.styles';

type Props = {
    ObjectID: number;
    currentValue: string;
    onChangeDeviceValue: (value: string) => void;
};

const SwitchpointDeviceView = ({ ObjectID, currentValue, onChangeDeviceValue }: Props) => {
    const { t } = useTranslation();
    const { getCategorySettings, images } = useImages();
    const { virtualDevices, rooms } = useServerConfig();

    const ignore = useMemo(() => ({ label: t('general.ignore'), value: 'Ignore' }), []);

    const configVirtualDevice = useMemo(
        () => virtualDevices?.find((x) => x.id === ObjectID),
        [virtualDevices, ObjectID],
    );

    const configVirtualDeviceSettings = useMemo(
        () => (configVirtualDevice ? getCategorySettings(configVirtualDevice.category) : undefined),
        [configVirtualDevice?.category, images],
    );

    const currentRoom = useMemo(
        () => rooms?.find((x) => x.id === configVirtualDevice?.roomid),
        [configVirtualDevice?.category, images, rooms],
    );

    const options = useMemo(() => {
        if (!configVirtualDevice) {
            return [];
        }

        if (configVirtualDevice.type === VirtualDeviceType.LightController) {
            return [
                ignore,
                ...(configVirtualDevice?.datapoints
                    ?.find((x) => x.type === DatapointType.ScenesLight)
                    ?.Scenes?.map((x, index) => ({ label: x.Name, value: index.toString() })) ?? []),
            ];
        }

        if (configVirtualDevice.type === VirtualDeviceType.Blinds) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.completelyUp'), value: 'CompletelyUp' },
                { label: t('centralTimerProgramEdit.completelyDown'), value: 'CompletelyDown' },
                { label: t('centralTimerProgramEdit.shadowing'), value: 'Shadowing' },
            ];
        }

        if (configVirtualDevice.type === VirtualDeviceType.PresenceSimulation) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.activate'), value: 'Activate' },
                { label: t('centralTimerProgramEdit.disabled'), value: 'Disabled' },
            ];
        }

        if (configVirtualDevice.type === VirtualDeviceType.BurglarAlarm) {
            return [
                ignore,
                { label: t('centralTimerProgramEdit.activate'), value: 'Activate' },
                { label: t('centralTimerProgramEdit.activateDelayed'), value: 'ActivateDelayed' },
                { label: t('centralTimerProgramEdit.activateWithoutPresence'), value: 'ActivateWithoutPresence' },
                { label: t('centralTimerProgramEdit.disabled'), value: 'Disabled' },
            ];
        }

        if (configVirtualDevice.type === VirtualDeviceType.GarageGate) {
            return [
                ignore,
                { label: t('general.open'), value: 'Open' },
                { label: t('general.close'), value: 'Close' },
                { label: t('centralTimerProgramEdit.closeWithDelay'), value: 'CloseDelay' },
            ];
        }

        if (
            configVirtualDevice.type === VirtualDeviceType.Schedule ||
            configVirtualDevice.type === VirtualDeviceType.ToiletVentilationController ||
            configVirtualDevice.type === VirtualDeviceType.StaircaseTimer ||
            configVirtualDevice.type === VirtualDeviceType.PushButton ||
            configVirtualDevice.type === VirtualDeviceType.PushSwitch ||
            configVirtualDevice.type === VirtualDeviceType.Switch
        ) {
            return [ignore, { label: t('general.off'), value: 'Off' }, { label: t('general.on'), value: 'On' }];
        }

        if (configVirtualDevice.type === VirtualDeviceType.Dimmer) {
            return [ignore, { label: '0-100%', value: '100' }];
        }

        return [];
    }, [configVirtualDevice]);

    const currentOption = useMemo(() => {
        if (options.length === 0) {
            return;
        }

        if (currentValue === ignore.value) {
            return options[0];
        }

        if (configVirtualDevice?.type === VirtualDeviceType.Dimmer) {
            return options[1];
        }

        const numberValue = Number(currentValue);
        if (!isNaN(numberValue)) {
            return options[numberValue + 1];
        }

        return options.find((x) => x.value === currentValue);
    }, [options, currentValue, configVirtualDevice]);

    const onValueChange = (value: string) => {
        onChangeDeviceValue(value);
    };

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.IconWrapper $color={configVirtualDeviceSettings?.color ?? ''}>
                    <Styled.ServerImage src={configVirtualDeviceSettings?.img} />
                </Styled.IconWrapper>
                <Styled.ServerName>{`${
                    currentRoom ? `${currentRoom.name} - ` : ''
                }${configVirtualDevice?.name}`}</Styled.ServerName>
            </Styled.HeaderWrapper>
            {options?.length > 0 && (
                <ThemedSelect
                    onChange={(v) => (typeof v === 'string' ? onValueChange(v) : onValueChange(v.value))}
                    value={currentOption}
                    options={options}
                />
            )}
            {configVirtualDevice?.type === VirtualDeviceType.Dimmer && currentValue !== ignore.value && (
                <ThemedSlider
                    onChange={(v) => onValueChange(v.toString())}
                    min={0}
                    max={100}
                    value={Number(currentValue)}
                />
            )}
        </Styled.MainWrapper>
    );
};

export default SwitchpointDeviceView;
