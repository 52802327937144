import { ConfigProvider, Spin } from 'antd';
import deDe from 'antd/es/locale/de_DE';
import enUS from 'antd/es/locale/en_US';
import frFr from 'antd/es/locale/fr_FR';
import itIt from 'antd/es/locale/it_IT';
import plPL from 'antd/es/locale/pl_PL';
import PageLayout from 'components/PageLayout/PageLayout';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import ServerConfigRoute from 'components/ServerConfigRoute/ServerConfigRoute';
import ServerSelectRoute from 'components/ServerSelectRoute/ServerSelectRoute';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import AcceptInvitationPage from 'pages/AcceptInvitationPage/AcceptInvitationPage';
import AdminDashboard from 'pages/Admin/AdminDashboard/AdminDashboard';
import AdminServersEditPage from 'pages/Admin/AdminServersEditPage/AdminServersEditPage';
import AdminUsersEditPage from 'pages/Admin/AdminUsersEditPage/AdminUsersEditPage';
import AdvancedPropertiesEditPage from 'pages/AdvancedPropertiesEditPage/AdvancedPropertiesEditPage';
import AlarmClockEditPage from 'pages/AlarmClockEditPage/AlarmClockEditPage';
import AstroEditPage from 'pages/AstroEditPage/AstroEditPage';
import CalendarEditPage from 'pages/CalendarEditPage/CalendarEditPage';
import CameraPage from 'pages/CameraPage/CameraPage';
import DashboardServerSettings from 'pages/DashboardServerSettings/DashboardServerSettings';
import DataloggerPage from 'pages/DataloggerPage/DataloggerPage';
import EnergyManagerEditPage from 'pages/EnergyManagerEditPage/EnergyManagerEditPage';
import EnergyMonitorChartsPage from 'pages/EnergyMonitorChartsPage/EnergyMonitorChartsPage';
import FloorplanPage from 'pages/FloorplanPage/FloorplanPage';
import FloorplanView from 'pages/FloorplanPage/FloorplanView/FloorplanView';
import AlarmsPage from 'pages/GroupedActions/AlarmsPage/AlarmsPage';
import CalendarGroupedPage from 'pages/GroupedActions/CalendarGroupedPage/CalendarGroupedPage';
import ControlsPage from 'pages/GroupedActions/ControlsPage/ControlsPage';
import EneregyGraphsPage from 'pages/GroupedActions/EneregyGraphsPage/EneregyGraphsPage';
import EnergyReportPage from 'pages/GroupedActions/EnergyReportPage/EnergyReportPage';
import FloorPlanServerSelectPage from 'pages/GroupedActions/FloorPlanServerSelectPage/FloorPlanServerSelectPage';
import HeadAdminDashboard from 'pages/HeadAdmin/HeadAdminDashboard/HeadAdminDashboard';
import LicensesEditPage from 'pages/HeadAdmin/LicensesEditPage/LicensesEditPage';
import HomeButtonsEditPage from 'pages/HomeButtonsEditPage/HomeButtonsEditPage';
import HomePage from 'pages/HomePage/HomePage';
import LoginPage from 'pages/LoginPage/LoginPage';
import MonitorChartsPage from 'pages/MonitorChartsPage/MonitorChartsPage';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import PresenceSimulationEditPage from 'pages/PresenceSimulationEditPage/PresenceSimulationEditPage';
import RoomControllerEditPage from 'pages/RoomControllerEditPage/RoomControllerEditPage';
import SceneEditPage from 'pages/SceneEditPage/SceneEditPage';
import SchedulerEditPage from 'pages/SchedulerEditPage/SchedulerEditPage';
import ServerLoginPage from 'pages/ServerLoginPage/ServerLoginPage';
import ServerPage from 'pages/ServerPage/ServerPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import TimerEditPage from 'pages/TimerEditPage/TimerEditPage';
import UsersConfigurationPage from 'pages/UsersConfigurationPage/UsersConfigurationPage';
import VisualizationBlockEditPage from 'pages/VisualizationBlockEditPage/VisualizationBlockEditPage';
import WpcPageLayout from 'pages/Wpc/layouts/WpcPageLayout/WpcPageLayout';
import WpcProtectedRoute from 'pages/Wpc/layouts/WpcProtectedRoute/WpcProtectedRoute';
import CreateWpcProjectPage from 'pages/Wpc/pages/CreateWpcProjectPage/CreateWpcProjectPage';
import WpcDashboardPage from 'pages/Wpc/pages/WpcDashboardPage/WpcDashboardPage';
import WpcLoginPage from 'pages/Wpc/pages/WpcLoginPage/WpcLoginPage';
import { useEffect, useMemo, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';

export const routes = {
    home: '/',
    login: '/login',
    accept: '/accept',
    server: '/server',
    serverAll: '/server/all',
    admin: '/admin',
    headAdmin: '/headadmin',
    groupActions: '/grouped',
};

export const menuItems: Record<string, string> = {
    [routes.home]: 'general.home',
    [routes.server]: 'general.servers',
    [routes.admin]: 'general.adminDashboard',
    [routes.headAdmin]: 'general.headAdminDashboard',
    [routes.groupActions]: 'general.groupedActions',
};

export const routePaths = {
    floorplan: `${routes.server}/:serverId/floorplan`,
    floorplanView: `${routes.server}/:serverId/floorplan/:virtualDeviceId/:floorPlanIndex`,
    datalogger: `${routes.server}/:serverId/datalogger`,
    camera: `${routes.server}/:serverId/camera/:virtualDeviceId/:cameraIndex`,
    settings: `${routes.server}/:serverId/settings`,
    groupedFloorplan: `${routes.groupActions}/:licenseId/:serverId/floorplan`,
    groupedFloorplanView: `${routes.groupActions}/:licenseId/:serverId/floorplan/:virtualDeviceId/:floorPlanIndex`,
};

const wpcRouter = createBrowserRouter([
    {
        path: routes.login,
        element: <WpcLoginPage />,
    },
    {
        element: <WpcProtectedRoute />,
        children: [
            {
                element: <WpcPageLayout />,
                children: [
                    { path: routes.home, element: <WpcDashboardPage /> },
                    {
                        path: `${routes.home}/setup`,
                        element: <CreateWpcProjectPage />,
                    },
                ],
            },
        ],
    },
    { path: '*', element: <PageNotFound /> },
]);

const router = createBrowserRouter([
    {
        path: routes.login,
        element: <LoginPage />,
    },
    {
        path: routes.accept,
        element: <AcceptInvitationPage />,
    },
    {
        element: <ProtectedRoute />,
        children: [
            {
                element: <PageLayout />,
                children: [
                    {
                        element: <ServerConfigRoute />,
                        children: [
                            {
                                path: routes.home,
                                element: <HomePage />,
                            },
                            {
                                path: routes.serverAll,
                                element: <HomePage />,
                            },
                            {
                                path: `${routes.server}/login`,
                                element: <ServerLoginPage />,
                            },
                            {
                                path: `${routes.server}/:serverId`,
                                element: <ServerPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/dashboard-settings`,
                                element: <DashboardServerSettings />,
                            },
                            {
                                path: `${routes.server}/:serverId/scenes/:virtualDeviceId`,
                                element: <SceneEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/scheduler/:virtualDeviceId`,
                                element: <SchedulerEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/calendar/:virtualDeviceId`,
                                element: <CalendarEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/presence/:virtualDeviceId`,
                                element: <PresenceSimulationEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/alarmclock/:virtualDeviceId`,
                                element: <AlarmClockEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/buttons/:virtualDeviceId`,
                                element: <HomeButtonsEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/visualization/:virtualDeviceId`,
                                element: <VisualizationBlockEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/heating/:virtualDeviceId`,
                                element: <RoomControllerEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/timer/:virtualDeviceId`,
                                element: <TimerEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/astro/:virtualDeviceId`,
                                element: <AstroEditPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/monitor/:virtualDeviceId`,
                                element: <MonitorChartsPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/energy/:virtualDeviceId`,
                                element: <EnergyMonitorChartsPage />,
                            },
                            {
                                path: `${routes.server}/:serverId/energymanager/:virtualDeviceId`,
                                element: <EnergyManagerEditPage />,
                            },
                            {
                                path: routePaths.floorplan,
                                element: <FloorplanPage />,
                                children: [
                                    {
                                        path: routePaths.floorplanView,
                                        element: <FloorplanView />,
                                    },
                                ],
                            },
                            {
                                path: routePaths.datalogger,
                                element: <DataloggerPage />,
                            },
                            {
                                path: routePaths.camera,
                                element: <CameraPage />,
                            },
                            {
                                path: routePaths.settings,
                                element: <SettingsPage />,
                            },
                            {
                                path: `${routePaths.settings}/users`,
                                element: <UsersConfigurationPage />,
                            },
                            {
                                path: `${routePaths.settings}/advanced`,
                                element: <AdvancedPropertiesEditPage />,
                            },
                            {
                                path: `${routes.groupActions}/:licenseId/`,
                                element: <ServerSelectRoute />,
                                children: [
                                    {
                                        path: `controls`,
                                        element: <ControlsPage />,
                                    },
                                    {
                                        path: `notifications`,
                                        element: <AlarmsPage />,
                                    },
                                    {
                                        path: `energy`,
                                        element: <EnergyReportPage />,
                                    },
                                    {
                                        path: `energygraphs`,
                                        element: <EneregyGraphsPage />,
                                    },
                                    {
                                        path: `calendars`,
                                        element: <CalendarGroupedPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/scenes/:virtualDeviceId`,
                                        element: <SceneEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/scheduler/:virtualDeviceId`,
                                        element: <SchedulerEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/calendar/:virtualDeviceId`,
                                        element: <CalendarEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/presence/:virtualDeviceId`,
                                        element: <PresenceSimulationEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/alarmclock/:virtualDeviceId`,
                                        element: <AlarmClockEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/buttons/:virtualDeviceId`,
                                        element: <HomeButtonsEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/visualization/:virtualDeviceId`,
                                        element: <VisualizationBlockEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/heating/:virtualDeviceId`,
                                        element: <RoomControllerEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/timer/:virtualDeviceId`,
                                        element: <TimerEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/astro/:virtualDeviceId`,
                                        element: <AstroEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/monitor/:virtualDeviceId`,
                                        element: <MonitorChartsPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/energy/:virtualDeviceId`,
                                        element: <EnergyMonitorChartsPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/energymanager/:virtualDeviceId`,
                                        element: <EnergyManagerEditPage />,
                                    },
                                    {
                                        path: `${routes.groupActions}/:licenseId/:serverId/camera/:virtualDeviceId/:cameraIndex`,
                                        element: <CameraPage />,
                                    },
                                ],
                            },
                            {
                                path: `${routes.groupActions}/:licenseId/`,
                                children: [
                                    {
                                        element: <FloorPlanServerSelectPage />,
                                        children: [
                                            {
                                                path: routePaths.groupedFloorplan,
                                                element: <FloorplanPage />,
                                                children: [
                                                    {
                                                        path: routePaths.groupedFloorplanView,
                                                        element: <FloorplanView />,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                element: <AdminDashboard />,
                                path: `${routes.admin}/:licenseId/`,
                                children: [
                                    {
                                        path: `users`,
                                        element: <AdminUsersEditPage />,
                                    },
                                    {
                                        path: `servers`,
                                        element: <AdminServersEditPage />,
                                    },
                                ],
                            },
                            {
                                element: <HeadAdminDashboard />,
                                path: `${routes.headAdmin}/`,
                                children: [
                                    {
                                        path: `licenses`,
                                        element: <LicensesEditPage />,
                                    },
                                ],
                            },
                            { path: '*', element: <PageNotFound /> },
                        ],
                    },
                ],
            },
        ],
    },
]);

function App() {
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        i18n.use(HttpBackend)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init(
                {
                    fallbackLng: 'en',
                    interpolation: { escapeValue: false },
                    backend: {
                        requestOptions: {
                            cache: 'no-store',
                        },
                    },
                },
                () => setIsInitialized(true),
            );
    }, []);

    const locale = useMemo(() => {
        if (i18n?.resolvedLanguage?.includes('fr')) {
            return frFr;
        }

        if (i18n?.resolvedLanguage?.includes('de')) {
            return deDe;
        }

        if (i18n?.resolvedLanguage?.includes('it')) {
            return itIt;
        }

        if (i18n?.resolvedLanguage?.includes('pl')) {
            return plPL;
        }

        return enUS;
    }, [i18n?.resolvedLanguage]);

    useEffect(() => {
        try {
            Notification.requestPermission();
        } catch {}
    }, []);

    return isInitialized ? (
        <RecoilRoot>
            <ConfigProvider locale={locale}>
                <RouterProvider router={process.env.REACT_APP_IS_WPC === 'true' ? wpcRouter : router} />
            </ConfigProvider>
        </RecoilRoot>
    ) : (
        <Spin size="large" style={{ position: 'absolute', top: '50%', left: '50%' }} />
    );
}

export default App;
