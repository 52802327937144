import { Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
    ThemedButton,
    ThemedCheckableTag,
    ThemedCheckboxGroup,
    ThemedModal,
    ThemedSpin,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import Server from 'models/Server';
import { CalendarSwitchPoint } from 'models/server/CalendarSwitchPoint';
import { Datapoint } from 'models/server/Datapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { MdErrorOutline } from 'react-icons/md';
import { useTheme } from 'styled-components';
import * as Styled from './SendValuesModal.styles';

type ConfigCalendar = {
    server: Server;
    calendars: VirtualDevice[] | undefined;
};

type Props = {
    newPoints: CalendarSwitchPoint[];
    allCalendars: ConfigCalendar[];
    onCancel: () => void;
};

const SendValuesModal = ({ allCalendars, newPoints, onCancel }: Props) => {
    const { colors } = useTheme();
    const { t } = useTranslation();
    const { onDatapointChange } = useDatapoint();

    const [selectedCalendars, setSelectedCalendars] = useState<
        { serverId: number; objectId: number; loading?: boolean; error?: boolean; success?: boolean }[]
    >([]);
    const [clearCalendars, setClearCalendars] = useState(false);

    const isLoading = selectedCalendars.some((x) => x.loading);

    const onSetConfig = (
        serverId: number,
        objectId: number,
        config: { serverId: number; objectId: number; loading?: boolean; error?: boolean; success?: boolean },
    ) => {
        setSelectedCalendars((prev) =>
            prev.map((x) => (x.serverId === serverId && x.objectId === objectId ? config : x)),
        );
    };

    const handleCalendarClick = (serverId: number, objectId: number) => {
        if (selectedCalendars.some((x) => x.serverId === serverId && objectId === x.objectId)) {
            setSelectedCalendars((prev) => prev.filter((x) => !(x.serverId === serverId && objectId === x.objectId)));
            return;
        }
        setSelectedCalendars((prev) => [...prev, { serverId, objectId }]);
    };

    const sendToServer = async (serverId: number, objectId: number) => {
        const calendar = selectedCalendars.find((x) => x.objectId === objectId && x.serverId === serverId);

        if (!calendar) {
            return;
        }

        onSetConfig(serverId, objectId, {
            ...calendar,
            error: false,
            loading: true,
            success: false,
        });

        const timeProgramDatapoint = allCalendars
            .find((x) => x.server.id === serverId)
            ?.calendars?.find((x) => x.id === objectId)
            ?.datapoints?.find((x) => x.type == DatapointType.TimeProgram);

        if (!timeProgramDatapoint) {
            onSetConfig(serverId, objectId, {
                ...calendar,
                error: true,
                loading: false,
                success: false,
            });
            return;
        }

        const allPoints = clearCalendars ? [] : timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints ?? [];
        console.log(allPoints, newPoints);
        const pointsToSend = [...allPoints, ...newPoints];
        const filteredSwitchPoints: CalendarSwitchPoint[] = [];

        pointsToSend.forEach((point) => {
            if (
                !filteredSwitchPoints.find(
                    (x) =>
                        (!x.Date?.includes('Z') ? x.Date + 'Z' : x.Date) ===
                            (!point.Date?.includes('Z') ? point.Date + 'Z' : point.Date) && x.Value === point.Value,
                )
            ) {
                filteredSwitchPoints.push({
                    ...point,
                    Date: !point.Date?.includes('Z') ? point.Date + 'Z' : point.Date,
                });
            }
        });

        const newTimeProgramDatapoint: Datapoint = {
            ...timeProgramDatapoint,
            TimeProgram: {
                CalendarSwitchPoints: filteredSwitchPoints,
            },
        };

        const result = await onDatapointChange(newTimeProgramDatapoint, serverId);

        if (!result) {
            onSetConfig(serverId, objectId, {
                ...calendar,
                error: true,
                loading: false,
                success: false,
            });
            return;
        }

        onSetConfig(serverId, objectId, {
            ...calendar,
            error: false,
            loading: false,
            success: true,
        });
    };

    const onSend = () => {
        Promise.all(selectedCalendars.map((x) => sendToServer(x.serverId, x.objectId)));
    };

    const onRerun = () => {
        Promise.all(selectedCalendars.filter((x) => x.error).map((x) => sendToServer(x.serverId, x.objectId)));
    };

    return (
        <ThemedModal
            width={550}
            title={t('general.sendCalendarToServers')}
            open
            onCancel={onCancel}
            closable={!isLoading}
            style={{ top: 200 }}
            $version="0"
            footer={
                <Footer>
                    <ThemedButton onClick={onCancel} $version="1" disabled={isLoading}>
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton
                        onClick={onSend}
                        $version="1"
                        $action
                        disabled={selectedCalendars.length === 0}
                        loading={isLoading}
                    >
                        {t('general.send')}
                    </ThemedButton>
                    {selectedCalendars.some((x) => x.error) && (
                        <ThemedButton onClick={onRerun} $version="1" $action loading={isLoading}>
                            {t('general.rerunFailed')}
                        </ThemedButton>
                    )}
                </Footer>
            }
        >
            <Styled.MainWrapper>
                <Styled.OverrideWrapper onClick={() => setClearCalendars((prev) => !prev)}>
                    <ThemedCheckboxGroup
                        options={[{ label: '', value: 'selected' }]}
                        value={[clearCalendars ? 'selected' : undefined] as CheckboxValueType[]}
                    />
                    {t('general.clearCalendars')}
                </Styled.OverrideWrapper>
                <Space size={10} wrap>
                    {allCalendars.map(
                        (x) =>
                            x.calendars?.map((z) => (
                                <ThemedCheckableTag
                                    checked={selectedCalendars.some(
                                        (sc) => x.server.id === sc.serverId && sc.objectId === z.id,
                                    )}
                                    key={`${x.server.id}${z.id}`}
                                    onClick={() => handleCalendarClick(x.server.id, z.id)}
                                >
                                    <Styled.TagWrapper>
                                        <Styled.TagTitle>{`${x.server.name} - ${z.name}`}</Styled.TagTitle>
                                        {!selectedCalendars.find(
                                            (sc) => x.server.id === sc.serverId && sc.objectId === z.id,
                                        )?.loading && (
                                            <ThemedCheckboxGroup
                                                options={[{ label: '', value: 'selected' }]}
                                                value={
                                                    [
                                                        selectedCalendars.some(
                                                            (sc) => x.server.id === sc.serverId && sc.objectId === z.id,
                                                        )
                                                            ? 'selected'
                                                            : undefined,
                                                    ] as CheckboxValueType[]
                                                }
                                            />
                                        )}
                                        {selectedCalendars.find(
                                            (sc) => x.server.id === sc.serverId && sc.objectId === z.id,
                                        )?.loading && <ThemedSpin />}
                                        {selectedCalendars.find(
                                            (sc) => x.server.id === sc.serverId && sc.objectId === z.id,
                                        )?.error && <MdErrorOutline size={16} color={colors.error} />}
                                        {selectedCalendars.find(
                                            (sc) => x.server.id === sc.serverId && sc.objectId === z.id,
                                        )?.success && <FaCheck size={16} color={colors.success} />}
                                    </Styled.TagWrapper>
                                </ThemedCheckableTag>
                            )),
                    )}
                </Space>
            </Styled.MainWrapper>
        </ThemedModal>
    );
};

export default SendValuesModal;
