import { ReactNode } from 'react';
import * as Styled from './EmptySuccess.styles';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

type Props = {
    title: string;
    children?: ReactNode;
    className?: string;
    fontSize?: number;
};

const EmptySuccess = ({ title, children, className, fontSize = 80 }: Props) => {
    return (
        <Styled.MainWrapper className={className}>
            <IoMdCheckmarkCircleOutline color="#07bc0c" fontSize={fontSize} />
            <Styled.MainTitle>{title}</Styled.MainTitle>
            {children}
        </Styled.MainWrapper>
    );
};

export default EmptySuccess;
