import * as Styled from './NotificationTemplate.styles';

type Props = {
    title: string;
    description: string;
};

const NotificationTemplate = ({ title, description }: Props) => {
    return (
        <Styled.MainWrapper>
            <Styled.TitleWrapper>{title}</Styled.TitleWrapper>
            <div>{description}</div>
        </Styled.MainWrapper>
    );
};

export default NotificationTemplate;
