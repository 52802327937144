import { ThemedSlider } from 'components/ThemedComponents/ThemedComponents.styles';
import { useDatapoint } from 'hooks/useDatapoint';
import { useImages } from 'hooks/useImages';
import { Datapoint } from 'models/server/Datapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { useEffect, useMemo, useState } from 'react';
import DatapointValueView, { DatapointValueViewProps } from '../DatapointValueView/DatapointValueView';
import * as Styled from './DatapointSliderView.styles';

type Props = Omit<DatapointValueViewProps, 'valueOrDp'> & {
    virtualDevice: VirtualDevice;
    datapoint: Datapoint;
    hideValueView?: boolean;
    color?: string;
    minWidth?: number;
    customValue?: number;
    customStep?: number;
    customOnChange?: (v: number) => Promise<boolean>;
};

const DatapointSliderView = (props: Props) => {
    const { virtualDevice, hideValueView, datapoint, color, customValue, minWidth, customStep, sufix, customOnChange } =
        props;
    const { images, getCategorySettings } = useImages();
    const { datapointLoading, onDatapointValueChange } = useDatapoint();

    const [currentValue, setCurrentValue] = useState(
        customValue ?? (isNaN(Number(datapoint.value)) ? 0 : Number(datapoint.value)),
    );

    const category = useMemo(() => getCategorySettings(virtualDevice.category), [images, virtualDevice]);

    const min = useMemo(() => Number(datapoint.range[0] ?? 0), [datapoint.range]);
    const max = useMemo(() => Number(datapoint.range[1] ?? 100), [datapoint.range]);

    useEffect(() => {
        setCurrentValue(customValue ?? (isNaN(Number(datapoint.value)) ? 0 : Number(datapoint.value)));
    }, [datapoint.value, customValue]);

    const onNewValue = async (v: number) => {
        const result = (await customOnChange?.(v)) ?? (await onDatapointValueChange(datapoint.id, v));

        if (!result) {
            setCurrentValue(customValue ?? (isNaN(Number(datapoint.value)) ? 0 : Number(datapoint.value)));
        }
    };

    return (
        <Styled.MainWrapper $minWidth={minWidth}>
            {!hideValueView && <DatapointValueView valueOrDp={datapoint} sufix={sufix ?? '%'} {...props} />}
            <ThemedSlider
                min={min}
                max={max}
                value={currentValue}
                step={customStep}
                onChange={(v) => setCurrentValue(v)}
                onAfterChange={onNewValue}
                $color={color ?? category.color}
                disabled={datapoint.writeprotect || datapointLoading === datapoint.id}
            />
        </Styled.MainWrapper>
    );
};

export default DatapointSliderView;
