import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
`;

export const CustomFinishLabel = styled.div`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
`;

export const Row = styled.div`
    display: flex;
    gap: 10px;
`;

export const RemoveButton = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
`;
