import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

export const TimeThemedCheckableTag = styled.div`
    padding: 10px 15px;
    display: flex;

    align-items: center;
    justify-content: space-between;
    width: 330px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    border-radius: 8px;
`;

export const IconWrapper = styled.div`
    width: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const AddThemedCheckableTag = styled(ThemedCheckableTag)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    width: 80px;
`;

export const InputWrapper = styled.div`
    width: 100px;
`;
