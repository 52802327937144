import { Layout } from 'antd';
import { overflowScrollbarStyle } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

const { Header, Footer, Content } = Layout;

export const MainLayout = styled(Layout)`
    background-color: ${(props) => props.theme.colors.backgroundLayer0};

    .ant-layout {
        background-color: ${(props) => props.theme.colors.backgroundLayer0};
    }

    & > * {
        color: ${(props) => props.theme.colors.text};
    }
`;

export const MainHeader = styled(Header)`
    background-color: transparent;
    display: flex;
    align-items: center;
`;

export const MainFooter = styled(Footer)`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    background-color: ${(props) => props.theme.colors.backgroundLayer0};
    color: ${(props) => props.theme.colors.text};
`;

export const ContentLayout = styled(Layout)`
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    ${overflowScrollbarStyle}
`;

export const MainContent = styled(Content)`
    min-height: unset !important;
    display: flex;
    padding: 20px;
    padding-right: 50px;

    @media (max-width: 750px) {
        padding-right: 10px;
    }
`;
