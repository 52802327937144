import { AuthContext } from 'contexts/AuthContext';
import { FloorplanContext } from 'contexts/FloorplanContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFloorPlanConfig from '../useFloorPlanConfig';
import FloorControl from './FloorControl/FloorControl';
import * as Styled from './FloorplanView.styles';

const FloorplanView = () => {
    const { currentFloor } = useFloorPlanConfig();
    const { floorplanImages } = useContext(FloorplanContext);
    const { isDemo } = useContext(AuthContext);
    const { t } = useTranslation();
    const [width, setWidth] = useState<number>(0);

    const currentImage = useMemo(
        () => floorplanImages?.find((x) => x.imageName === currentFloor?.FloorImage?.Name),
        [floorplanImages, currentFloor],
    );

    const mainWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mainWrapperRef.current) {
            return;
        }

        const observer = new ResizeObserver((entries) => {
            setWidth(entries[0].contentRect.width);
        });
        observer.observe(mainWrapperRef.current);
        return () => (mainWrapperRef.current ? observer.unobserve(mainWrapperRef.current) : undefined);
    }, [mainWrapperRef.current]);

    const currentProportion = useMemo(() => {
        if (!currentFloor) {
            return 1;
        }

        const floorWidth = currentFloor.FloorImage.Width;
        const newfloorWidth = floorWidth > width ? width : floorWidth;
        const proportion = newfloorWidth / floorWidth;
        return proportion;
    }, [width, currentFloor?.FloorImage?.Width]);

    return (
        <Styled.MainWrapper ref={mainWrapperRef}>
            {currentFloor && currentImage && (
                <Styled.FloorWrapper
                    style={{
                        width: currentFloor.FloorImage.Width * currentProportion,
                        height: currentFloor.FloorImage.Height * currentProportion,
                    }}
                >
                    {currentImage.img ? (
                        <img
                            width={currentFloor.FloorImage.Width * currentProportion}
                            height={currentFloor.FloorImage.Height * currentProportion}
                            src={isDemo ? currentImage.img : `data:image/png;base64, ${currentImage.img}`}
                        />
                    ) : (
                        <Styled.WholePageEmptyError title={t('errors.couldNotLoadImage')} />
                    )}
                    {currentFloor.Objects.map((obj, index) => (
                        <FloorControl
                            key={index}
                            floorObject={obj}
                            proportion={currentProportion}
                            width={width}
                            floor={currentFloor}
                        />
                    ))}
                </Styled.FloorWrapper>
            )}
        </Styled.MainWrapper>
    );
};

export default FloorplanView;
