import { ThemedCheckableTag } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const MainWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 25px;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    margin-bottom: 25px;
    gap: 15px;
    justify-content: flex-end;
`;

export const TagWrapper = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0;
    width: 300px;
    min-height: 40px;
`;

export const IconWrapper = styled.div`
    min-width: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const AddButton = styled(ThemedCheckableTag)`
    height: 44px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
