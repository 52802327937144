import { Space } from 'antd';
import { ThemedRangePicker, ThemedSelect } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EnergyConfig, FrameSelectionOption, energyDateFormat } from '../EnergyMonitorChartsPage';
import * as Styled from './EnergyFilters.styles';

export enum DateSelectOption {
    lastHour = 'lastHour',
    last6Hour = 'last6Hour',
    last12Hour = 'last12Hour',
    last24Hour = 'last24Hour',
    lastWeek = 'lastWeek',
    custom = 'custom',
}

type Props = {
    isLoading: boolean;
    config: EnergyConfig;
    updateConfig: (config: EnergyConfig) => void;
};

export const getEnergyFiltersDates = (selectedDateOption: DateSelectOption) => {
    switch (selectedDateOption) {
        case DateSelectOption.lastHour: {
            return {
                endDate: dayjs().format(energyDateFormat),
                startDate: dayjs().add(-1, 'h').format(energyDateFormat),
            };
        }
        case DateSelectOption.last6Hour: {
            return {
                endDate: dayjs().format(energyDateFormat),
                startDate: dayjs().add(-6, 'h').format(energyDateFormat),
            };
        }
        case DateSelectOption.last12Hour: {
            return {
                endDate: dayjs().format(energyDateFormat),
                startDate: dayjs().add(-12, 'h').format(energyDateFormat),
            };
        }
        case DateSelectOption.last24Hour: {
            return {
                endDate: dayjs().format(energyDateFormat),
                startDate: dayjs().add(-1, 'd').format(energyDateFormat),
            };
        }
        case DateSelectOption.lastWeek: {
            return {
                endDate: dayjs().format(energyDateFormat),
                startDate: dayjs().add(-7, 'd').format(energyDateFormat),
            };
        }
    }
};

const EnergyFilters = ({ config, updateConfig, isLoading }: Props) => {
    const { t } = useTranslation();
    const { isDemo } = useContext(AuthContext);
    const { startDate, endDate, selectedDateOption, selectedFrameOption } = config;

    const dateSelectOptions = useMemo(
        () => [
            { value: DateSelectOption.lastHour, label: t('general.lastHour') },
            { value: DateSelectOption.last6Hour, label: t('general.last6Hour') },
            { value: DateSelectOption.last12Hour, label: t('general.last12Hour') },
            { value: DateSelectOption.last24Hour, label: t('general.last24Hour') },
            { value: DateSelectOption.lastWeek, label: t('general.lastWeek') },
            { value: DateSelectOption.custom, label: t('general.custom') },
        ],
        [],
    );

    const frameSelectOptions = useMemo(
        () => [
            { value: FrameSelectionOption.auto, label: t('general.auto') },
            { value: FrameSelectionOption.hourly, label: t('general.hourly') },
            { value: FrameSelectionOption.daily, label: t('general.daily') },
            { value: FrameSelectionOption.monthly, label: t('general.monthly') },
            { value: FrameSelectionOption.yearly, label: t('general.yearly') },
        ],
        [],
    );

    const getShowTimeForFrame = (frame: FrameSelectionOption) => {
        if (frame === FrameSelectionOption.auto) {
            return { format: 'HH:mm' };
        }
        if (frame === FrameSelectionOption.hourly) {
            return { format: 'HH:00' };
        }

        return false;
    };

    const getDateFormatForFrame = (frame: FrameSelectionOption) => {
        if (frame === FrameSelectionOption.monthly) {
            return 'MM-YYYY';
        }
        if (frame === FrameSelectionOption.yearly) {
            return 'YYYY';
        }

        return undefined;
    };

    const getTypePickerForFrame = (
        frame: FrameSelectionOption,
    ): 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined => {
        switch (frame) {
            case FrameSelectionOption.monthly:
                return 'month';
            case FrameSelectionOption.yearly:
                return 'year';
        }
    };

    const onFrameTypeChanged = (frame: FrameSelectionOption) => {
        switch (frame) {
            case FrameSelectionOption.hourly: {
                const start = dayjs(startDate, energyDateFormat);
                const newStart = `${start.year()}-${
                    start.month() < 9 ? '0' + (start.month() + 1) : start.month() + 1
                }-${start.date() < 10 ? '0' + start.date() : start.date()} ${
                    start.hour() < 10 ? '0' + start.hour() : start.hour()
                }:00`;
                updateConfig({
                    ...config,
                    startDate: newStart,
                    endDate: dayjs().format(energyDateFormat),
                    selectedFrameOption: frame,
                });
                break;
            }
            case FrameSelectionOption.daily: {
                const start = dayjs(startDate, energyDateFormat);
                const newStart = `${start.year()}-${
                    start.month() < 9 ? '0' + (start.month() + 1) : start.month() + 1
                }-${start.date() < 10 ? '0' + start.date() : start.date()} 00:00`;
                updateConfig({
                    ...config,
                    startDate: newStart,
                    endDate: dayjs().format(energyDateFormat),
                    selectedFrameOption: frame,
                });
                break;
            }
            case FrameSelectionOption.monthly: {
                const start = dayjs(startDate, energyDateFormat);
                const newStart = `${start.year()}-${
                    start.month() < 9 ? '0' + (start.month() + 1) : start.month() + 1
                }-01 00:00`;

                updateConfig({
                    ...config,
                    startDate: newStart,
                    endDate: dayjs().format(energyDateFormat),
                    selectedFrameOption: frame,
                });
                break;
            }
            case FrameSelectionOption.yearly: {
                const start = dayjs(startDate, energyDateFormat);
                const newStart = `${start.year()}-01-01 00:00`;
                updateConfig({
                    ...config,
                    startDate: newStart,
                    endDate: dayjs().format(energyDateFormat),
                    selectedFrameOption: frame,
                });
                break;
            }
            case FrameSelectionOption.auto: {
                updateConfig({
                    ...config,
                    selectedFrameOption: frame,
                });
                break;
            }
        }
    };

    return (
        <Space size={20} wrap>
            <Styled.OptionSelectWrapper>
                <ThemedSelect
                    showSearch={false}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(v: any) => {
                        const newV = v;
                        const dates = getEnergyFiltersDates(newV);

                        if (!dates) {
                            updateConfig({
                                ...config,
                                selectedDateOption: newV,
                                selectedFrameOption:
                                    newV !== DateSelectOption.custom
                                        ? FrameSelectionOption.auto
                                        : config.selectedFrameOption,
                            });
                            return;
                        }

                        updateConfig({
                            ...config,
                            selectedDateOption: newV,
                            startDate: dates?.startDate,
                            endDate: dates?.endDate,
                            selectedFrameOption:
                                newV !== DateSelectOption.custom
                                    ? FrameSelectionOption.auto
                                    : config.selectedFrameOption,
                        });
                    }}
                    value={dateSelectOptions.find((x) => x.value === selectedDateOption)?.value}
                    options={dateSelectOptions}
                    disabled={isLoading || isDemo}
                />
            </Styled.OptionSelectWrapper>
            <ThemedRangePicker
                style={{ minWidth: 330 }}
                allowClear={false}
                disabled={selectedDateOption !== DateSelectOption.custom || isLoading || isDemo}
                value={[dayjs(startDate, energyDateFormat), dayjs(endDate, energyDateFormat)]}
                onChange={(v) => {
                    updateConfig({
                        ...config,
                        startDate: v?.[0]?.format(energyDateFormat) ?? dayjs().add(-1, 'd').format(energyDateFormat),
                        endDate: v?.[1]?.format(energyDateFormat) ?? dayjs().format(energyDateFormat),
                    });
                }}
                disabledDate={(current) => current > dayjs()}
                showNow={false}
                showTime={getShowTimeForFrame(selectedFrameOption)}
                format={getDateFormatForFrame(selectedFrameOption)}
                picker={getTypePickerForFrame(selectedFrameOption)}
            />
            <Styled.FrameSelectWrapper>
                <ThemedSelect
                    showSearch={false}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(value: any) => onFrameTypeChanged(value)}
                    value={frameSelectOptions.find((x) => x.value === selectedFrameOption)}
                    options={frameSelectOptions}
                    disabled={selectedDateOption !== DateSelectOption.custom || isLoading || isDemo}
                />
            </Styled.FrameSelectWrapper>
        </Space>
    );
};
export default EnergyFilters;
