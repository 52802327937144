import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const Row = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
`;
