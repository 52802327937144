import { VirtualDeviceViewContentViewProps } from 'components/ServerControls/VirtualDeviceViewContent/VirtualDeviceViewContent';
import { AlphatronicConfig } from 'models/server/AlphatronicConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useMemo } from 'react';
import * as Styled from './AlphatronicControl.styles';
import AlphatronicGroupView from './AlphatronicGroupView/AlphatronicGroupView';

const AlphatronicControl = ({ virtualDevice }: VirtualDeviceViewContentViewProps) => {
    const { datapoints } = virtualDevice;

    const config: AlphatronicConfig | undefined = useMemo(() => {
        try {
            return JSON.parse(
                datapoints.find((x) => x.type == DatapointType.AlphatronicUIConfig)?.value?.toString() ?? '',
            );
        } catch {
            return undefined;
        }
    }, [virtualDevice]);

    const controlsDatapoints = useMemo(
        () =>
            datapoints
                .filter(
                    (x) =>
                        ((x.type === DatapointType.Number && x.writeprotect) ||
                            x.type === DatapointType.StatusLight ||
                            x.type === DatapointType.Button) &&
                        x.customname === '',
                )
                .map((x) => x.id),
        [datapoints],
    );

    return (
        <Styled.MainWrapper>
            {config && (
                <AlphatronicGroupView
                    group={{ Groups: [], Datapoints: controlsDatapoints, Name: '' }}
                    config={config}
                    virtualDevice={virtualDevice}
                />
            )}
            {config &&
                config.Groups.length > 0 &&
                config.Groups.map((z, index) => (
                    <AlphatronicGroupView
                        key={z.Name + index}
                        group={z}
                        config={config}
                        virtualDevice={virtualDevice}
                    />
                ))}
        </Styled.MainWrapper>
    );
};

export default AlphatronicControl;
