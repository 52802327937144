import { Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { AlarmTimeProgram } from 'models/server/AlarmTimeProgram';
import { Datapoint } from 'models/server/Datapoint';
import { DatapointType } from 'models/server/enums/DatapointType';
import { timeFormat } from 'pages/SchedulerEditPage/SchedulerEditPage';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import * as Styled from './AlarmClockEditPage.styles';
import AlarmClockView from './AlarmClockView/AlarmClockView';

const sortFn = (a: AlarmTimeProgram, b: AlarmTimeProgram) =>
    dayjs(a.Time, timeFormat).isAfter(dayjs(b.Time, timeFormat)) ? 1 : -1;

const AlarmClockEditPage = () => {
    const { t } = useTranslation();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { currentServer, currentVirtualDevice, serverConfigLoading } = useServerConfig();
    const { onGoBack } = useGoBack();

    const timeProgramDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.TimeProgram),
        [currentVirtualDevice?.datapoints],
    );

    const [timePrograms, setTimePrograms] = useState<AlarmTimeProgram[]>(
        timeProgramDatapoint?.TimeProgram?.Alarms?.sort(sortFn) ?? [],
    );

    useEffect(() => {
        setTimePrograms(timeProgramDatapoint?.TimeProgram?.Alarms?.sort(sortFn) ?? []);
    }, [timeProgramDatapoint?.TimeProgram?.Alarms]);

    const onSave = async () => {
        if (!timeProgramDatapoint) {
            return;
        }

        const filteredAlarms: AlarmTimeProgram[] = [];

        timePrograms.forEach((alarm) => {
            if (
                !filteredAlarms.find(
                    (x) =>
                        x.Time === alarm.Time &&
                        x.Description === alarm.Description &&
                        x.Monday === alarm.Monday &&
                        x.Tuesday === alarm.Tuesday &&
                        x.Wednesday === alarm.Wednesday &&
                        x.Thursday === alarm.Thursday &&
                        x.Friday === alarm.Friday &&
                        x.Saturday === alarm.Saturday &&
                        x.Sunday === alarm.Sunday &&
                        x.Active === alarm.Active,
                )
            ) {
                filteredAlarms.push(alarm);
            }
        });

        const newTimeProgramDatapoint: Datapoint = {
            ...timeProgramDatapoint,
            TimeProgram: {
                Alarms: filteredAlarms,
            },
        };

        const result = await onDatapointChange(newTimeProgramDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onRemove = (index: number) => {
        setTimePrograms((prev) => prev.filter((alarm) => prev[index] !== alarm));
    };

    const onAddNew = () => {
        const newAlarm: AlarmTimeProgram = {
            Time: '00:00',
            Active: true,
            Monday: true,
            Tuesday: true,
            Wednesday: true,
            Thursday: true,
            Friday: true,
            Saturday: true,
            Sunday: true,
            Description: '',
        };

        setTimePrograms((prev) => [newAlarm, ...prev]);
    };

    const onAlarmChanged = (newAlarm: AlarmTimeProgram, index: number) => {
        setTimePrograms((prev) => prev.map((alarm, aIndex) => (index === aIndex ? newAlarm : alarm)).sort(sortFn));
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!timeProgramDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (timeProgramDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.AddButton checked={false} $dashed onClick={onAddNew}>
                    <MdAdd fontSize={25} />
                </Styled.AddButton>
                <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                <ThemedButton $action onClick={onSave}>
                    {t('general.save')}
                </ThemedButton>
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {timePrograms.map((sp, index) => (
                    <AlarmClockView
                        program={sp}
                        key={index}
                        onAlarmChanged={(alarm) => onAlarmChanged(alarm, index)}
                        onRemove={() => onRemove(index)}
                    />
                ))}
            </Space>
        </Styled.MainWrapper>
    );
};

export default AlarmClockEditPage;
