import { routePaths, routes } from 'App';
import { HeaderConfigContext } from 'contexts/HeaderContext';
import { useServerConfig } from 'hooks/useServerConfig';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { WholePageSpace } from 'pages/SchedulerEditPage/SchedulerEditPage.styles';
import { CSSProperties, useContext, useMemo } from 'react';
import { IoIosNotifications, IoIosSettings } from 'react-icons/io';
import { LuLineChart } from 'react-icons/lu';
import { MdDashboard, MdOutlineHomeWork } from 'react-icons/md';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './ServerHeader.styles';
import { useTranslation } from 'react-i18next';

const iconStyle: CSSProperties = {
    fontSize: 18,
};

const ServerHeader = () => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { currentServer, virtualDevices } = useServerConfig();
    const { nOfNotifications, setHeaderConfig } = useContext(HeaderConfigContext);

    const iconSettings = {
        fontSize: 22,
        color: colors.text,
        cursor: 'pointer',
    };

    const config = useMemo(
        () => [
            {
                name: t('general.controls'),
                icon: <MdDashboard style={iconStyle} />,
                path: [`${routes.server}/:serverId`],
                visible: true,
            },
            {
                name: t('general.floorPlan'),
                icon: <MdOutlineHomeWork style={iconStyle} />,
                path: [routePaths.floorplan, routePaths.floorplanView],
                visible: virtualDevices?.some((x) => x.type == VirtualDeviceType.FloorPlan),
            },
            {
                name: t('general.dataLogger'),
                icon: <LuLineChart style={iconStyle} />,
                path: [routePaths.datalogger],
                visible: virtualDevices.some(
                    (x) =>
                        x.type === VirtualDeviceType.Monitor &&
                        x.datapoints.find((x) => x.type === DatapointType.MonitorConfig)?.Monitor?.some((x) => x.Log),
                ),
            },
        ],
        [virtualDevices],
    );

    return (
        <Styled.MainHeader>
            <WholePageSpace wrap>
                {config
                    .filter((x) => x.visible)
                    .map((c, index) => (
                        <Styled.HeaderTag
                            key={index}
                            checked={!!c.path.some((z) => matchPath(z, location.pathname))}
                            onClick={() =>
                                navigate(c.path[0].replace(':serverId', currentServer?.id?.toString() ?? ''))
                            }
                        >
                            {c.icon}
                            {c.name}
                        </Styled.HeaderTag>
                    ))}
            </WholePageSpace>
            {currentServer && (
                <IoIosSettings
                    onClick={() => navigate(`${routes.server}/${currentServer.id}/settings`)}
                    {...iconSettings}
                />
            )}
            {currentServer && (
                <Styled.NotificationWrapper
                    onClick={() => setHeaderConfig?.((prev) => ({ ...prev, notificationsVisible: true }))}
                >
                    {nOfNotifications > 0 && <Styled.NOfNotificationsWrapper>!</Styled.NOfNotificationsWrapper>}
                    <IoIosNotifications {...iconSettings} />
                </Styled.NotificationWrapper>
            )}
        </Styled.MainHeader>
    );
};

export default ServerHeader;
