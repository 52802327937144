import { ThemeType } from 'components/PageLayout/PageLayout';
import DashboardConfig from 'models/DashboardConfig';
import Server from 'models/Server';
import { createContext } from 'react';

export interface AuthBase {
    servers: Server[];
    isLoading: boolean;
    theme: ThemeType;
    isDemo: boolean;
    dashboardConfigs: DashboardConfig[];

    setTheme?: (theme: ThemeType) => void;
    onRefetchServers?: () => void;
}

export const AuthContext = createContext<AuthBase>({
    servers: [],
    theme: 'dark',
    isLoading: false,
    isDemo: false,
    dashboardConfigs: [],
});
