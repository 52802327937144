import { Space } from 'antd';
import { ThemedDatePicker, ThemedSwitch, ThemedTag } from 'components/ThemedComponents/ThemedComponents.styles';
import { Dayjs } from 'dayjs';
import { getLocalFromUtc, getUtcFromLocal } from 'helpers/StringHelper';
import { useServerConfig } from 'hooks/useServerConfig';
import { CalendarSwitchPoint } from 'models/server/CalendarSwitchPoint';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { useTheme } from 'styled-components';
import { dateFormat, serverDateFormat } from '../CalendarEditPage';
import * as Styled from './CalendarGroupView.styles';

type Props = {
    calendarSwitchPoints: CalendarSwitchPoint[];
    disabled: boolean;
    title: string;
    showAddButton: boolean;
    showRemove: boolean;
    onRemove: (sp: CalendarSwitchPoint) => void;
    onSwitchChanged: (sp: CalendarSwitchPoint, value: boolean) => void;
    onDateChanged: (sp: CalendarSwitchPoint, date: string) => void;
    onAddSwitchpoint: () => void;
};

const CalendarGroupView = ({
    calendarSwitchPoints,
    disabled,
    title,
    showAddButton,
    showRemove,
    onRemove,
    onSwitchChanged,
    onDateChanged,
    onAddSwitchpoint,
}: Props) => {
    const { colors } = useTheme();
    const { categorySettings } = useServerConfig();

    const onDateTimeChanged = (v: Dayjs | null, sp: CalendarSwitchPoint) => {
        if (!v) {
            return;
        }
        onDateChanged(sp, getUtcFromLocal(v, serverDateFormat));
    };

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.Title>{title}</Styled.Title>
                {showAddButton && (
                    <Styled.AddButton checked={false} $dashed onClick={onAddSwitchpoint}>
                        <MdAdd fontSize={25} />
                    </Styled.AddButton>
                )}
            </Styled.HeaderWrapper>
            <Space size={20} wrap>
                {calendarSwitchPoints.map((sp, index) => (
                    <ThemedTag key={index} color={colors.backgroundLayer1}>
                        <Styled.TagWrapper>
                            {showRemove && (
                                <Styled.IconWrapper>
                                    <IoMdCloseCircleOutline
                                        onClick={(e) => {
                                            onRemove(sp);
                                            e.stopPropagation();
                                        }}
                                        size={18}
                                    />
                                </Styled.IconWrapper>
                            )}
                            <ThemedDatePicker
                                showTime
                                format={dateFormat}
                                value={getLocalFromUtc(sp.Date ?? '', dateFormat)}
                                allowClear={false}
                                changeOnBlur
                                onChange={(v) => onDateTimeChanged(v, sp)}
                            />
                            <ThemedSwitch
                                disabled={disabled}
                                $color={categorySettings?.color}
                                checked={sp?.Value ?? false}
                                onChange={(v) => onSwitchChanged(sp, v)}
                            />
                        </Styled.TagWrapper>
                    </ThemedTag>
                ))}
            </Space>
        </Styled.MainWrapper>
    );
};

export default CalendarGroupView;
