import { overflowScrollbarStyle } from 'components/ThemedComponents/ThemedComponents.styles';
import styled, { css } from 'styled-components';

export const BlurWrapper = styled.div<{ $visible: boolean }>`
    z-index: 999999998;
    position: fixed;
    backdrop-filter: blur(2px);
    top: 0px;
    bottom: 0px;
    right: 0px;
    opacity: 0;

    ${(props) =>
        props.$visible &&
        css`
            transition: opacity 0.2s linear;
            opacity: 1;
            left: 0px;
        `}
`;

export const MainModalWrapper = styled.div<{ $visible: boolean }>`
    z-index: 999999999;
    position: fixed;
    right: 10px;
    top: 10px;
    bottom: 10px;
    width: 0px;
    background-color: ${(props) => props.theme.colors.backgroundLayer0};
    box-shadow: rgb(20 35 85 / 40%) 0px 0px 18px 0px;
    border-radius: 8px;
    padding: 0;
    transition:
        width 0.1s linear 0.1s,
        padding 0.1s linear 0.1s;

    ${(props) =>
        props.$visible &&
        css`
            width: 450px;
            transition:
                width 0.1s linear,
                padding 0.1s linear;
            padding: 15px 0 15px 25px;

            @media (max-width: 450px) {
                width: 95%;
                height: 75%;
            }

            @media (max-width: 750px) {
                padding: 15px 0 15px 10px;
            }
        `}
`;

export const ModalContentWrapper = styled.div<{ $visible: boolean }>`
    opacity: 0;
    transition:
        opacity 0.1s linear 0.1s,
        padding 0.2s linear 0.2s;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 750px) {
        padding-right: 5px;
    }

    ${overflowScrollbarStyle}

    ${(props) =>
        props.$visible &&
        css`
            transition:
                opacity 0.1s linear 0.2s,
                padding 0s linear 0s;
            opacity: 1;
            padding-right: 15px;
        `}
`;

export const EmptyWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

export const IconWrapper = styled.div`
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
`;

export const EmptyTitle = styled.div`
    font-weight: bold;
`;
