import { Space } from 'antd';
import { ThemedButton, ThemedSearch, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { isEmpty } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import { sortBy } from 'lodash';
import Server from 'models/Server';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearchOutline } from 'react-icons/io5';
import AddServerModal from './AdminServerView/AddServerModal/AddServerModal';
import AdminServerView from './AdminServerView/AdminServerView';
import * as Styled from './AdminServersEditPage.styles';

const AdminServersEditPage = () => {
    const { apiFetch } = useApi();
    const { t } = useTranslation();

    const [servers, setServers] = useState<Server[]>([]);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [addServerModalVisible, setAddServerModalVisible] = useState(false);

    const filteredServers = useMemo(() => {
        if (isEmpty(search)) {
            return servers;
        }

        return servers.filter(
            (x) =>
                x.name?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
                x.address?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase()) ||
                x.tags?.some((z) => z.value?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())),
        );
    }, [search, servers]);

    const init = async () => {
        setLoading(true);
        const result = await apiFetch<Server[]>('Servers');

        if (!result.result) {
            setLoading(false);
            return;
        }

        setLoading(false);
        setServers(sortBy(result.result, (x) => x.name?.toLocaleLowerCase()));
    };

    useEffect(() => {
        init();
    }, []);

    if (loading) {
        return <ThemedSpin size="large" />;
    }

    return (
        <Styled.Wrapper>
            <Space size={20} wrap>
                <ThemedSearch
                    value={search}
                    onChange={(v) => setSearch(v.currentTarget.value)}
                    prefix={<IoSearchOutline />}
                />
                <ThemedButton onClick={() => setAddServerModalVisible(true)}>{t('general.addServer')}</ThemedButton>
            </Space>
            <Space size={20} wrap>
                {filteredServers.map((server) => (
                    <AdminServerView servers={servers} server={server} key={server.id} onReloadServers={init} />
                ))}
            </Space>
            {addServerModalVisible && (
                <AddServerModal onRefresh={init} onCancel={() => setAddServerModalVisible(false)} />
            )}
        </Styled.Wrapper>
    );
};

export default AdminServersEditPage;
