import { ThemedButton, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { useTranslation } from 'react-i18next';
import * as Styled from './YesNoModal.styles';

export type YesNoModalProps = {
    title?: string;
    description: string;
    onYesClicked?: (e: React.MouseEvent<HTMLElement>) => void;
    onNoClicked: () => void;
    yesDanger?: boolean;
    yesButtonText?: string;
    noButtonText?: string;
    loading?: boolean;
};

const YesNoModal = (props: YesNoModalProps): JSX.Element => {
    const { yesButtonText, noButtonText, title, description, yesDanger, loading, onYesClicked, onNoClicked } = props;
    const { t } = useTranslation();
    return (
        <ThemedModal
            width="fit-content"
            title={title ?? ''}
            open
            onCancel={onNoClicked}
            okButtonProps={{ danger: yesDanger }}
            style={{ top: 150 }}
            closable={false}
            maskClosable={false}
            footer={
                <Styled.Footer>
                    <ThemedButton onClick={onNoClicked} $version="2" disabled={loading}>
                        {noButtonText ?? t('general.no')}
                    </ThemedButton>
                    <ThemedButton $version="2" onClick={onYesClicked} danger={yesDanger} loading={loading}>
                        {yesButtonText ?? t('general.yes')}
                    </ThemedButton>
                </Styled.Footer>
            }
        >
            <div>{description}</div>
        </ThemedModal>
    );
};

export default YesNoModal;
