import { ServerSelectProps } from 'components/ServerSelect/ServerSelect';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import { ServerConfigContext } from 'contexts/ServerConfigContext';
import Server from 'models/Server';
import { SetupInfo } from 'models/server/SetupInfo';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import * as Styled from './ServerSelectBulkLoadingProvider.styles';

type Props = ServerSelectProps;

export type GroupedServerConfig = {
    server: Server;
    loading: boolean;
    error: boolean;
    errorCode?: number;
    setupInfo?: SetupInfo;
};

const ServerSelectBulkLoadingProvider = ({ servers, serversView, changeButton }: Props) => {
    const { t } = useTranslation();
    const { configs, onRefetchOne } = useContext(ServerConfigContext);

    const selectedConfigs = useMemo(
        () => configs.filter((x) => servers.some((z) => z.id === x.server.id)),
        [configs, servers],
    );

    const isLoading = selectedConfigs.some((x) => x.loading);
    const loadingIds = selectedConfigs.filter((x) => x.loading).map((x) => x.server.id);
    const errorIds = selectedConfigs.filter((x) => x.error).map((x) => x.server.id);

    const onRerunFailed = async () => {
        await Promise.all(selectedConfigs.filter((x) => x.error).map((z) => onRefetchOne?.(z.server.id)));
    };

    useEffect(() => {
        selectedConfigs.filter((x) => !x.setupInfo).map((z) => onRefetchOne?.(z.server.id));
    }, []);

    return (
        <Styled.MainContainer>
            <Styled.Header>
                {serversView(errorIds, loadingIds)}
                {errorIds.length > 0 && (
                    <ThemedButton danger onClick={onRerunFailed}>
                        {t('general.rerunFailed')}
                    </ThemedButton>
                )}
                {changeButton({ disabled: isLoading })}
            </Styled.Header>
            <GroupedServersConfigContext.Provider value={{ configs: selectedConfigs, onRefetchOne: onRefetchOne }}>
                {isLoading ? <ThemedSpin /> : <Outlet />}
            </GroupedServersConfigContext.Provider>
        </Styled.MainContainer>
    );
};

export default ServerSelectBulkLoadingProvider;
