export const useAzureApi = () => {
    const azureFetch = async <T>(
        input: RequestInfo | URL,
        method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH',
        init?: RequestInit | undefined,
    ): Promise<{ result: T | undefined; code: number }> => {
        try {
            const result = await fetch(input, { method: method, ...init });

            if (!result.ok) {
                return { result: undefined, code: result.status };
            }

            const json: T = await result.json();

            return { result: json, code: result.status };
        } catch (ex) {
            if (process.env.NODE_ENV === 'development') {
                console.log('Exception', ex);
            }

            return { code: 0, result: undefined };
        }
    };

    return { azureFetch };
};
