import { Select, Space } from 'antd';
import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedRangePicker, ThemedSelect, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import dayjs from 'dayjs';
import { getLightValue } from 'helpers/DatapointHelper';
import { useServerApi } from 'hooks/useServerApi';
import { useServerConfig } from 'hooks/useServerConfig';
import { groupBy } from 'lodash';
import { DatalogItem } from 'models/server/DatalogItem';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';
import * as Styled from './DataloggerPage.styles';
import { getChartDemoData } from './getChartDemoData';

const { Option } = Select;

const dateFormat = 'YYYY-MM-DD HH:mm';
const serverFormat = 'YYYY-MM-DDTHH:mm:ss';
const secondFormat = 'YYYY-MM-DD HH:mm:ss';

export const chartColors = [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
];

const DataloggerPage = () => {
    const { isDemo } = useContext(AuthContext);
    const { t } = useTranslation();
    const { colors } = useTheme();
    const { apiServerFetch } = useServerApi();
    const { currentServer, serverConfigLoading, ios, virtualDevices } = useServerConfig();

    const [isLoading, setIsLoading] = useState(true);

    const supportedIos = useMemo(() => {
        const monitors = virtualDevices?.filter((x) => x.type === VirtualDeviceType.Monitor);
        const datapoints = monitors
            .map((x) => x.datapoints)
            .flat()
            .filter((x) => x.type === DatapointType.MonitorConfig);
        const ioIds = datapoints
            .map((x) => x.Monitor)
            .flat()
            .filter((x) => x.Log)
            .map((x) => x.IOid);

        return ios.filter((x) => ioIds.some((y) => y === x.id));
    }, [serverConfigLoading]);

    const options = useMemo(
        () =>
            supportedIos?.map((x, index) => (
                <Option value={x.id.toString()} key={`(${index + 1}) ${x.name}`}>{`(${index + 1}) ${x.name}`}</Option>
            )),
        [supportedIos],
    );

    const [filters, setFilters] = useState<{ selectedIos: string[]; startDate: string; endDate: string }>({
        selectedIos: supportedIos[0] ? [supportedIos[0].id?.toString()] : [],
        startDate: dayjs().add(-1, 'd').format(dateFormat),
        endDate: dayjs().format(dateFormat),
    });
    const [formattedLogs, setFormattedLogs] = useState<{
        chart: {
            [x: number]: number;
            date: number;
        }[];
        download: {
            Timestamp: string;
        }[];
    }>();
    const [chartsProps, setChartsProps] = useState<{ id: number; hide: boolean }[] | undefined>([]);

    useEffect(() => {
        if (supportedIos?.length > 0) {
            const selected = [supportedIos[0].id?.toString()];
            setFilters({
                selectedIos: selected,
                startDate: dayjs().add(-1, 'd').format(dateFormat),
                endDate: dayjs().format(dateFormat),
            });
            setChartsProps(selected?.map((x) => ({ id: Number(x), hide: false })));
        }
    }, [supportedIos]);

    const getFilledData = (items: DatalogItem[]): DatalogItem[] => {
        try {
            const dates = items
                .map((x) => x.data.value.timestamp)
                .filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            const newItems: DatalogItem[] = [];

            filters.selectedIos.forEach((element) => {
                const iosItems = items.filter((x) => x.data.ioid === Number(element));

                if (iosItems.length === 0) {
                    return;
                }

                let currentValue = iosItems[0].data.value.value;
                const slicedDates = dates.slice(
                    dates.findIndex((x) => x === iosItems[0].data.value.timestamp),
                    dates.findIndex((x) => x === iosItems[iosItems.length - 1].data.value.timestamp) + 1,
                );

                slicedDates.forEach((date) => {
                    const item = iosItems.find((x) => x.data.value.timestamp === date);

                    if (item) {
                        newItems.push(item);
                        currentValue = item.data.value.value;
                    } else {
                        newItems.push({
                            ...iosItems[0],
                            data: {
                                ...iosItems[0].data,
                                value: { ...iosItems[0].data.value, value: currentValue, timestamp: date },
                            },
                        });
                    }
                });
            });

            return newItems;
        } catch {
            return items;
        }
    };

    const getDatalogs = async () => {
        if (filters.selectedIos.length === 0 || isDemo) {
            return [];
        }

        const result = await apiServerFetch<{ result: DatalogItem[]; more: boolean }>('datalog', undefined, 'POST', {
            startDate: dayjs(filters.startDate, dateFormat).format('YYYY-MM-DDTHH:mm:ss'),
            endDate: dayjs(filters.endDate, dateFormat).format('YYYY-MM-DDTHH:mm:ss'),
            maxSize: 128000,
            ios: filters.selectedIos.map((x) => Number(x)),
        });

        if (result.code !== 200 || !result.result) {
            toast.error(t('errors.errorWhileSendingValue'));
            setIsLoading(false);
            return;
        }

        let objects: DatalogItem[] = result?.result?.result ?? [];

        try {
            if (result?.result?.more) {
                let getNext;
                do {
                    const objResult = await apiServerFetch<{ result: DatalogItem[]; more: boolean }>(
                        'datalog',
                        undefined,
                        'POST',
                        {
                            startDate: dayjs(filters.startDate, dateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                            endDate: dayjs(filters.endDate, dateFormat).format('YYYY-MM-DDTHH:mm:ss'),
                            maxSize: 128000,
                            ios: filters.selectedIos.map((x) => Number(x)),
                            startId: objects[objects.length - 1].id + 1,
                        },
                    );
                    objects = [...objects, ...(objResult?.result?.result ?? [])];
                    getNext = objResult?.result?.more;
                } while (getNext);
            }
        } catch {}

        const removedMilisecondsData = objects
            .filter((x) => x.data.value.timestamp)
            .map((x) => ({
                ...x,
                data: {
                    ...x.data,
                    value: {
                        ...x.data.value,
                        timestamp: dayjs(x.data.value.timestamp).format(serverFormat),
                    },
                },
            }));

        const filtered = getFilledData(removedMilisecondsData).filter(
            (x) =>
                dayjs(x.data.value.timestamp) >= dayjs(filters.startDate, dateFormat) &&
                dayjs(x.data.value.timestamp) <= dayjs(filters.endDate, dateFormat),
        );

        return filtered;
    };

    const getNumber = (value: string) => {
        if (value.startsWith('#')) {
            return getLightValue(value, ['0', '99999']);
        }

        if (value.toString() === 'true') {
            return 1;
        } else if (value.toString() === 'false') {
            return 0;
        }

        const num = Number(value);

        if (isNaN(num)) {
            return 0;
        }

        return num;
    };

    const getFormatted = async () => {
        if (isDemo) {
            const formatted = getChartDemoData().map((x, index) => ({
                date: dayjs().valueOf() - index * 60 * 60 * 1000,
                [1]: x.data,
            }));
            setFormattedLogs({ chart: formatted, download: [] });
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        const dataLogs = await getDatalogs();

        if (!dataLogs?.length) {
            setIsLoading(false);
            return { chart: [], download: [] };
        }

        const grouped = groupBy(
            dataLogs.map((x) => ({
                date: dayjs(x.data.value.timestamp).valueOf(),
                [x.data.ioid]: getNumber(x.data.value.value),
            })),
            (x) => x.date,
        );
        const formatted = Object.keys(grouped).map((x) => ({
            ...grouped[x].reduce((prev, curr) => {
                return {
                    ...prev,
                    ...curr,
                };
            }),
        }));

        const groupedDownload = groupBy(
            dataLogs
                .sort((a, b) => (dayjs(a.data.value.timestamp).isBefore(dayjs(b.data.value.timestamp)) ? -1 : 1))
                .map((x) => ({
                    Timestamp: dayjs(x.data.value.timestamp).format(secondFormat),
                    [supportedIos.find((z) => z.id === x.data.ioid)?.name ?? '']: getNumber(x.data.value.value),
                })),
            (x) => x.Timestamp,
        );
        const formattedDownload = Object.keys(groupedDownload).map((x) => ({
            ...groupedDownload[x].reduce((prev, curr) => {
                return {
                    ...prev,
                    ...curr,
                };
            }),
        }));

        setFormattedLogs({ chart: formatted.sort((a, b) => a.date - b.date), download: formattedDownload });
        setIsLoading(false);
    };

    useEffect(() => {
        onRefresh();
    }, [filters.startDate, filters.endDate]);

    const onRefresh = () => {
        if (supportedIos.length > 0 && filters.selectedIos.length > 0) {
            getFormatted();
        } else {
            setFormattedLogs(undefined);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectBar = (e: any) => {
        setChartsProps(
            (prev) => prev?.map((x) => (x.id === Number(e.dataKey) ? { ...x, hover: false, hide: !x.hide } : x)),
        );
    };

    if (serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    return (
        <Styled.MainWrapper>
            <Space size={20} wrap>
                <ThemedSelect
                    loading={isLoading}
                    mode="multiple"
                    maxTagCount={1}
                    maxTagTextLength={33}
                    allowClear={false}
                    style={{ width: 350, maxHeight: 35 }}
                    value={filters.selectedIos}
                    onChange={(v: string[]) => {
                        setFilters((prev) => ({ ...prev, selectedIos: v }));
                        setChartsProps(
                            (prev) =>
                                v?.map((x) => ({
                                    id: Number(x),
                                    hide: prev?.find((o) => Number(x) == o.id)?.hide ?? false,
                                })),
                        );
                    }}
                    onBlur={onRefresh}
                    disabled={isDemo}
                >
                    {options}
                </ThemedSelect>
                <ThemedRangePicker
                    disabled={isLoading || isDemo}
                    style={{ minWidth: 350 }}
                    allowClear={false}
                    defaultValue={[dayjs(filters.startDate, dateFormat), dayjs(filters.endDate, dateFormat)]}
                    onChange={(v) => {
                        setFilters((prev) => ({
                            ...prev,
                            startDate: v?.[0]?.format(dateFormat) ?? dayjs().add(-1, 'd').format(dateFormat),
                            endDate: v?.[1]?.format(dateFormat) ?? dayjs().format(dateFormat),
                        }));
                    }}
                    showNow={false}
                    showTime={{ format: 'HH:mm' }}
                />
            </Space>
            {isLoading && <ThemedSpin size="large" />}
            {!formattedLogs && !isLoading && <EmptyError title={t('datalogger.noDataForSelectedTimeFrame')} />}
            {formattedLogs && !isLoading && (
                <ResponsiveContainer height={400}>
                    <ComposedChart data={formattedLogs.chart}>
                        <CartesianGrid stroke={colors.text} />
                        <XAxis
                            tick={{ fill: colors.text }}
                            dataKey="date"
                            type="number"
                            domain={[
                                dayjs(filters.startDate, dateFormat).valueOf(),
                                dayjs(filters.endDate, dateFormat).valueOf(),
                            ]}
                            tickFormatter={(label) => {
                                return dayjs(label).format(secondFormat);
                            }}
                        />
                        <YAxis tick={{ fill: colors.text }} />
                        <Tooltip
                            contentStyle={{ backgroundColor: colors.backgroundLayer2 }}
                            labelFormatter={(label: string) => {
                                return dayjs(label).format(secondFormat);
                            }}
                        />
                        <Legend onClick={selectBar} />
                        <ReferenceLine y={0} stroke={colors.text} strokeDasharray="3 3" />
                        {filters.selectedIos.map((x, index) => (
                            <Line
                                dot={false}
                                key={x}
                                dataKey={x}
                                name={supportedIos.find((z) => z.id === Number(x))?.name}
                                type="stepAfter"
                                stroke={chartColors[index > 19 ? 19 : index]}
                                hide={chartsProps?.find((z) => z.id === Number(x))?.hide}
                            />
                        ))}
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </Styled.MainWrapper>
    );
};

export default DataloggerPage;
