import { ButtonProps } from 'antd';
import DatapointButtonView from 'components/ServerControls/Controls/DatapointButtonView/DatapointButtonView';
import DatapointSliderView from 'components/ServerControls/Controls/DatapointSliderView/DatapointSliderView';
import DatapointSwitchView from 'components/ServerControls/Controls/DatapointSwitchView/DatapointSwitchView';
import VirtualDeviceView from 'components/ServerControls/VirtualDeviceView/VirtualDeviceView';
import { ThemedButton } from 'components/ThemedComponents/ThemedComponents.styles';
import {
    getBooleanDatapointValue,
    getColorStringWithChangedValueToSend,
    getLightColor,
    getLightValue,
    getNumberDatapointValue,
} from 'helpers/DatapointHelper';
import { isEmpty } from 'helpers/StringHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import { useImages } from 'hooks/useImages';
import { useServerConfig } from 'hooks/useServerConfig';
import { Datapoint } from 'models/server/Datapoint';
import { Floor } from 'models/server/Floor';
import { FloorObject } from 'models/server/FloorObject';
import { useDatapointSettings } from 'models/server/constants/useDatapointSettings';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceCategory } from 'models/server/enums/VirtualDeviceCategory';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Styled from './FloorControl.styles';
import IoValueFloorControlView from './IoValueFloorControlView/IoValueFloorControlView';

type Props = {
    floorObject: FloorObject;
    proportion: number;
    width: number;
    floor: Floor;
};

const homeButtonsConfig: ButtonProps & {
    $width?: number | undefined;
} = {
    shape: 'round',
    $width: 55,
};

const FloorControl = ({ floorObject, proportion, width, floor }: Props) => {
    const { t } = useTranslation();
    const { onDatapointValueChange } = useDatapoint();
    const { ios, virtualDevices, currentServer, rooms, info } = useServerConfig();
    const navigate = useNavigate();
    const { settings: datapointSettings } = useDatapointSettings(t);
    const { getCategorySettings, photo_camera, in_home, away_from_home, sleep } = useImages();
    const { IOID, ObjID, X, Y, DpName } = floorObject;

    const [mouseEnter, setMouseEnter] = useState(false);

    const currentIo = useMemo(() => ios.find((x) => x.id === IOID), [floorObject]);
    const currentVirtualDevice = useMemo(
        () => virtualDevices.find((vd) => vd.id === ObjID),
        [virtualDevices, floorObject],
    );

    const currentDatapoint = useMemo(
        () =>
            currentVirtualDevice?.datapoints?.find((dp) =>
                currentVirtualDevice.type === VirtualDeviceType.Camera
                    ? dp.type === DatapointType.CameraConfig
                    : dp.name == DpName,
            ),
        [currentVirtualDevice?.datapoints, floorObject],
    );

    const buttonIcon = useMemo(
        () => ({
            [DatapointNames.VirtualComingHome]: in_home,
            [DatapointNames.VirtualLeavingHome]: away_from_home,
            [DatapointNames.VirtualSleep]: sleep,
        }),
        [in_home, away_from_home, sleep],
    );

    const currentButtonIcon = useMemo(
        () =>
            currentDatapoint?.name === DatapointNames.VirtualComingHome ||
            currentDatapoint?.name === DatapointNames.VirtualLeavingHome ||
            currentDatapoint?.name === DatapointNames.VirtualSleep
                ? buttonIcon[currentDatapoint.name]
                : undefined,
        [buttonIcon, floorObject],
    );

    const isCentralHomeButtons = useMemo(
        () =>
            currentDatapoint?.name === DatapointNames.VirtualComingHome ||
            currentDatapoint?.name === DatapointNames.VirtualLeavingHome ||
            currentDatapoint?.name === DatapointNames.VirtualSleep,
        [currentDatapoint, floorObject],
    );

    const currentDatapointSettings = useMemo(() => datapointSettings.find((x) => x.name === DpName), [DpName]);

    const isNumberSlider = useMemo(() => {
        if (!currentDatapoint) {
            return false;
        }

        switch (currentDatapoint.type) {
            case DatapointType.Brightness:
                return currentDatapoint.name.includes('light');
            case DatapointType.Number:
                return !currentDatapoint.writeprotect;
            default:
                return false;
        }
    }, [currentDatapoint]);

    const isColorSlider = useMemo(() => {
        if (!currentDatapoint) {
            return false;
        }

        switch (currentDatapoint.type) {
            case DatapointType.Color_strip:
            case DatapointType.Color:
                return currentDatapoint.name.includes('light');
            default:
                return false;
        }
    }, [currentDatapoint]);

    const visibleName = useMemo(() => {
        if (currentIo && !currentVirtualDevice) {
            return currentIo.name;
        }

        if (currentVirtualDevice?.type === VirtualDeviceType.Camera) {
            return currentDatapoint?.Cameras?.[Number(DpName) - 1].Name;
        }

        if (currentDatapoint && currentVirtualDevice) {
            if (DpName.startsWith('light.value')) {
                return currentDatapoint.customname;
            }

            if (!isEmpty(currentDatapointSettings?.translation)) {
                return currentDatapointSettings?.translation;
            }

            return floorObject.DpName;
        }

        if (currentDatapoint && currentVirtualDevice) {
            return currentVirtualDevice.datapoints?.find((x) => x.type == DatapointType.CameraConfig)?.Cameras?.[
                Number(DpName) - 1
            ].Name;
        }

        if (currentVirtualDevice && !currentDatapoint) {
            return currentVirtualDevice.name;
        }
    }, [floorObject]);

    const onBrightnessChange = async (dp: Datapoint, value: number) => {
        return await onDatapointValueChange(dp.id, getColorStringWithChangedValueToSend(value, dp.value.toString()));
    };

    const getBlindsPosition = (): number => {
        const blindsPositionDp = currentVirtualDevice?.datapoints?.find(
            (x) => x.name === DatapointNames.VirtualBlindsPosition,
        );

        return (
            getNumberDatapointValue(blindsPositionDp, blindsPositionDp?.range) /
            (Number(blindsPositionDp?.range?.[1] ?? 100) - Number(blindsPositionDp?.range?.[0] ?? 0))
        );
    };

    const objectIsTurned = useMemo(
        () =>
            getBooleanDatapointValue(
                currentVirtualDevice?.datapoints?.find(
                    (x) =>
                        x.name === DatapointNames.VirtualSwitch ||
                        x.name === DatapointNames.VirtualEnable ||
                        x.name === DatapointNames.VirtualConnection,
                ),
            ),
        [currentVirtualDevice?.datapoints, floorObject],
    );

    const currentVirtualDeviceSettings = useMemo(
        () =>
            getCategorySettings(
                currentVirtualDevice?.category ?? VirtualDeviceCategory.Undefined,
                objectIsTurned ? 'dark' : undefined,
            ),
        [currentVirtualDevice?.category, objectIsTurned, floorObject],
    );

    const getControlLeft = (floorObject: FloorObject): number | undefined => {
        if (width < 500) {
            return -(floorObject.X * proportion);
        }

        if (floorObject.X < 250 && (proportion < 1 || width * proportion >= width - 250)) {
            return 0;
        }

        if (currentVirtualDevice?.type == VirtualDeviceType.Camera && floorObject.X < width - 250) {
            return 0;
        }

        return undefined;
    };

    const onNavigateToCamera = () => {
        navigate(
            {
                pathname: `/server/${currentServer?.id}/camera/${currentVirtualDevice?.id}/${Number(DpName) - 1}`,
            },
            { state: { path: window.location.pathname, search: window.location.search } },
        );
    };

    return (
        <Styled.MainWrapper
            style={{
                left: X * proportion,
                top: Y * proportion,
                transform: `scale(${proportion})`,
                zIndex: mouseEnter ? 2 : 0,
            }}
        >
            {ObjID && !DpName && (
                <div onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
                    <Styled.ObjectView $color={currentVirtualDeviceSettings.color} $backgroundFilled={objectIsTurned}>
                        {(currentVirtualDevice?.type === VirtualDeviceType.Blinds ||
                            currentVirtualDevice?.type === VirtualDeviceType.CentralBlindsController) &&
                            currentVirtualDeviceSettings && (
                                <Styled.BlindsPosition
                                    $backgroundColor={currentVirtualDeviceSettings.color}
                                    $height={40 * getBlindsPosition()}
                                />
                            )}
                        <Styled.ObjectImage
                            src={
                                currentVirtualDevice?.type == VirtualDeviceType.Camera
                                    ? photo_camera
                                    : currentVirtualDeviceSettings?.img
                            }
                        />
                    </Styled.ObjectView>
                    {currentVirtualDevice && mouseEnter && (
                        <Styled.VirtualDeviceViewWrapper
                            className="virtualDeviceView"
                            style={{
                                left: getControlLeft(floorObject),
                                right:
                                    floorObject.X * proportion > width - 250 &&
                                    (proportion < 1 || width * proportion >= width - 250)
                                        ? 0
                                        : undefined,
                                top:
                                    width < 500
                                        ? 0
                                        : floorObject.Y < 250
                                        ? 0
                                        : floorObject.Y > floor.FloorImage.Height - 250
                                        ? undefined
                                        : 0,
                                bottom: floorObject.Y > floor.FloorImage.Height - 250 ? 0 : undefined,
                            }}
                        >
                            {currentServer && (
                                <VirtualDeviceView
                                    rooms={rooms}
                                    currentServer={currentServer}
                                    id={currentVirtualDevice.id}
                                    virtualDevice={currentVirtualDevice}
                                    serverSerialNumber={info?.serialnumber ?? ''}
                                />
                            )}
                        </Styled.VirtualDeviceViewWrapper>
                    )}
                </div>
            )}

            {(currentDatapoint || currentIo || (visibleName && floorObject.WebDesc)) && (
                <Styled.ControlWrapper>
                    {floorObject.WebDesc && visibleName && <Styled.Title>{visibleName}</Styled.Title>}
                    {currentIo && !currentVirtualDevice && (
                        <IoValueFloorControlView
                            unit={floorObject.Unit}
                            ioId={currentIo.id}
                            serialNumber={info?.serialnumber ?? ''}
                        />
                    )}
                    {isNumberSlider && currentDatapoint && currentVirtualDevice && (
                        <DatapointSliderView
                            minWidth={150 * proportion}
                            hideValueView
                            datapoint={currentDatapoint}
                            virtualDevice={currentVirtualDevice}
                        />
                    )}
                    {isColorSlider && currentDatapoint && currentVirtualDevice && (
                        <DatapointSliderView
                            hideValueView
                            minWidth={150 * proportion}
                            datapoint={currentDatapoint}
                            virtualDevice={currentVirtualDevice}
                            color={getLightColor(currentDatapoint.value.toString())}
                            customOnChange={(v) => onBrightnessChange(currentDatapoint, v)}
                            customValue={getLightValue(currentDatapoint.value.toString(), currentDatapoint.range)}
                            useCustomName
                        />
                    )}
                    {currentDatapoint && currentVirtualDevice && currentDatapoint.type == DatapointType.Switch && (
                        <div>
                            <DatapointSwitchView
                                hideName
                                virtualDevice={currentVirtualDevice}
                                datapoint={currentDatapoint}
                            />
                        </div>
                    )}
                    {currentDatapoint && currentVirtualDevice && currentDatapoint.type == DatapointType.Button && (
                        <DatapointButtonView
                            {...(isCentralHomeButtons ? homeButtonsConfig : {})}
                            icon={currentButtonIcon ? <Styled.ButtonImage src={currentButtonIcon} /> : undefined}
                            hideName
                            $width={isCentralHomeButtons ? 36 : undefined}
                            datapoint={currentDatapoint}
                        />
                    )}
                    {currentVirtualDevice?.type === VirtualDeviceType.Camera && (
                        <ThemedButton
                            onClick={onNavigateToCamera}
                            $width={36}
                            shape="round"
                            icon={<Styled.ButtonImage src={photo_camera} />}
                        />
                    )}
                </Styled.ControlWrapper>
            )}
        </Styled.MainWrapper>
    );
};

export default FloorControl;
