import { AuthContext } from 'contexts/AuthContext';
import { GroupedServersConfigContext } from 'contexts/GroupedServersConfigContext';
import { ServerConfigContext } from 'contexts/ServerConfigContext';
import { ServerContext } from 'contexts/ServerContext';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from './useAuth';
import { useImages } from './useImages';

export const useServerConfig = (customServerId?: number) => {
    const { getCategorySettings } = useImages();
    const { authUser: user } = useAuth();
    const { servers } = useContext(AuthContext);
    const { configs, onRefetchOne } = useContext(GroupedServersConfigContext);
    const { server } = useContext(ServerContext);
    const {
        virtualDevices: serverVirtualDevices,
        setupInfo: serverSetupInfo,
        loading: serverConfigLoading,
        onRefreshConfig: onRefreshServerConfig,
    } = useContext(ServerConfigContext);
    const { serverId, virtualDeviceId } = useParams<{ virtualDeviceId: string; serverId: string }>();

    const currentServerId = useMemo(() => Number(customServerId ?? serverId), [customServerId, serverId]);

    const setupInfo = serverSetupInfo ?? configs.find((x) => x.server.id === currentServerId)?.setupInfo;
    const virtualDevices = serverSetupInfo
        ? serverVirtualDevices
        : configs.find((x) => x.server.id === currentServerId)?.setupInfo?.objects?.items ?? [];

    const currentServer = useMemo(
        () => servers.find((x) => x.id === currentServerId) ?? server,
        [servers, currentServerId, server],
    );

    const currentUser = useMemo(
        () => setupInfo?.users?.find((x) => x.name === currentServer?.login),
        [setupInfo?.users, user, currentServer],
    );

    const currentVirtualDevice: VirtualDevice | undefined = useMemo(
        () =>
            virtualDeviceId !== undefined ? virtualDevices?.find((x) => x.id === Number(virtualDeviceId)) : undefined,
        [virtualDeviceId, virtualDevices],
    );

    const categorySettings = useMemo(
        () => (currentVirtualDevice !== undefined ? getCategorySettings(currentVirtualDevice.category) : undefined),
        [currentVirtualDevice],
    );

    return {
        servers,
        currentServer,
        currentVirtualDevice,
        categorySettings,
        virtualDevices,
        serverConfigLoading: configs.length > 0 ? false : serverConfigLoading,
        currentUser,
        onRefreshConfig: onRefreshServerConfig ?? (() => onRefetchOne?.(currentServerId)),
        ...setupInfo,
        devices: setupInfo?.devices ?? [],
        users: setupInfo?.users ?? [],
        ios: setupInfo?.ios ?? [],
        rooms: setupInfo?.rooms ?? [],
        notifications: setupInfo?.notifications ?? [],
        serverId: currentServerId,
    };
};
