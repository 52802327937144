import useIoValues from 'hooks/useIoValues';
import { MonitorDatapoint } from 'models/server/MonitorDatapoint';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DatapointValueView from '../../DatapointValueView/DatapointValueView';

type Props = {
    dp: MonitorDatapoint;
};

const MonitorView = ({ dp }: Props) => {
    const { serverIosValues } = useIoValues();
    const { t } = useTranslation();

    const getMoniorValue = (ioValue: string | number | boolean | undefined, datapoint: MonitorDatapoint): string => {
        if (ioValue === undefined) {
            return t('monitor.noData');
        }

        if (ioValue === '') {
            return t('monitor.invalid');
        }

        if (!datapoint.Configuration || !Number(ioValue)) {
            return `${ioValue}${datapoint.Unit}`;
        }

        const value = Number(ioValue);
        const offset = Number(datapoint.Configuration.Offset);
        const lowerLimit = Number(datapoint.Configuration.LowerLimit);
        const lowerLimitCorrection = Number(datapoint.Configuration.LowerLimitCorrection);
        const upperLimit = Number(datapoint.Configuration.UpperLimit);
        const upperLimitCorrection = Number(datapoint.Configuration.UpperLimitCorrection);

        if (!value || !offset || !lowerLimit || !lowerLimitCorrection || !upperLimit || !upperLimitCorrection) {
            return `${ioValue}${datapoint.Unit}`;
        }

        const diff = upperLimit - lowerLimit;
        const m = diff == 0 ? 0 : (upperLimitCorrection - lowerLimitCorrection) / diff;
        const b = upperLimitCorrection - m * upperLimit;

        return ((value + offset) * m - b).toString();
    };

    const currentIo = useMemo(() => serverIosValues.find((x) => x.ioId === dp.IOid), [dp, serverIosValues]);

    const value = useMemo(() => getMoniorValue(currentIo?.value, dp), [currentIo?.value, dp]);

    return <DatapointValueView title={dp.Name} valueOrDp={value} />;
};

export default MonitorView;
