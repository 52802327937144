import { routes } from 'App';
import WholePageSpiner from 'components/WholePageSpiner/WholePageSpiner';
import useLocalAuth from 'hooks/useLocalAuth';
import useLocalServerApi from 'hooks/useLocalServerApi';
import { useThemeStorage } from 'hooks/useThemeStorage';
import { SchemeConfig } from 'pages/Wpc/pages/CreateWpcProjectPage/WpcSchemeEdit/WpcSchemeEdit';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WpcAuthContext } from '../../contexts/WpcAuthContext';

export const demoTypeSearch = 'type';
export const demoTypeSearchValue = 'demo';

export type SchemeBase = { id: number; payload: SchemeConfig };

const WpcProtectedRoute = () => {
    const { authUser } = useLocalAuth();
    const navigate = useNavigate();
    const { currentTheme, setCurrentTheme } = useThemeStorage();
    const { localServerFetch } = useLocalServerApi();

    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState<SchemeBase>();

    const goToLoginPage = () => {
        navigate(routes.login);
    };

    const init = async () => {
        setLoading(true);

        const { code, result } = await localServerFetch<{ result: { id: number; payload: SchemeConfig }[] }>({
            api: 'storage',
        });

        if (code === 401) {
            goToLoginPage();
            return;
        }

        if (code !== 200 || !result) {
            setLoading(false);
            setConfig(undefined);
            return;
        }

        setConfig(result.result?.[0]);
        setLoading(false);
    };

    useEffect(() => {
        if (!authUser || !authUser.access_token) {
            goToLoginPage();
            return;
        }

        init();
    }, [authUser]);

    if (!authUser) {
        return <WholePageSpiner />;
    }

    return (
        <WpcAuthContext.Provider
            value={{
                user: authUser,
                theme: currentTheme,
                scheme: config,
                loading: loading,
                refech: init,
                setTheme: (theme) => setCurrentTheme(theme),
            }}
        >
            <ToastContainer style={{ zIndex: 999999998 }} theme={currentTheme} />
            <Outlet />
        </WpcAuthContext.Provider>
    );
};

export default WpcProtectedRoute;
