import { Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
    ThemedCheckableTag,
    ThemedCheckboxGroup,
    ThemedInputNumber,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from 'models/server/EnergyManagementDevice';
import { TagWrapper } from 'pages/SceneEditPage/SceneEditPage.styles';
import { useTranslation } from 'react-i18next';
import { MdAdd } from 'react-icons/md';
import EnergyManagerDeviceScheduleView, {
    getFreePriorities,
} from './EnergyManagerDeviceScheduleView/EnergyManagerDeviceScheduleView';
import * as Styled from './EnergyManagerDeviceView.styles';

type Props = {
    device: EnergyManagementDevice;
    energyDevices: EnergyManagementDevice[];
    onDeviceChanged: (device: EnergyManagementDevice, newDevice: EnergyManagementDevice) => void;
};

const checkboxOptions = [
    { label: 'L1', value: 'L1' },
    { label: 'L2', value: 'L2' },
    { label: 'L3', value: 'L3' },
];

const EnergyManagerDeviceView = ({ device, energyDevices, onDeviceChanged }: Props) => {
    const { t } = useTranslation();
    const onCheckboxChange = (newValues: CheckboxValueType[]) => {
        const newDevice: EnergyManagementDevice = {
            ...device,
            L1: newValues.includes('L1'),
            L2: newValues.includes('L2'),
            L3: newValues.includes('L3'),
        };

        onDeviceChanged(device, newDevice);
    };

    const onPropertyChanged = (
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => {
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: device.Priorities.map((x) => (x === property ? newProperty : x)),
        };
        onDeviceChanged(device, newDevice);
    };

    const addProp = () => {
        const freePrio = getFreePriorities(energyDevices);
        const newProperty: EnergyManagementDeviceProperty = {
            FinishTimeScheduler: [-1, -1, -1, -1, -1, -1, -1],
            Level: Math.max(...device.Priorities.map((x) => x.Level)),
            Priority: freePrio[0],
            Mode: 0,
            Hysteresis: 1,
            FinishTimeCalendar: [],
        };
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: [...device.Priorities, newProperty],
        };
        onDeviceChanged(device, newDevice);
    };

    const onRemovePriority = (index: number) => {
        const calendarItems = [...device.Priorities];
        calendarItems.splice(index, 1);
        const newDevice: EnergyManagementDevice = {
            ...device,
            Priorities: calendarItems,
        };
        onDeviceChanged(device, newDevice);
    };

    return (
        <Styled.MainWrapper>
            <Space wrap size={20}>
                <ThemedCheckboxGroup
                    options={checkboxOptions}
                    value={
                        [
                            device.L1 ? 'L1' : undefined,
                            device.L2 ? 'L2' : undefined,
                            device.L3 ? 'L3' : undefined,
                        ].filter((x) => x !== undefined) as CheckboxValueType[]
                    }
                    onChange={(checkedValue: CheckboxValueType[]) => onCheckboxChange(checkedValue)}
                />
                <div>
                    <Styled.InputTitle>{t('energyManagementConfiguration.minPower') + ' (kW)'}</Styled.InputTitle>
                    <ThemedInputNumber
                        precision={1}
                        value={device.MinPower}
                        min={0}
                        max={device.MaxPower}
                        onChange={(value) =>
                            value !== null && onDeviceChanged(device, { ...device, MinPower: Number(value) })
                        }
                    />
                </div>
                <div>
                    <Styled.InputTitle>{t('energyManagementConfiguration.maxPower') + ' (kW)'}</Styled.InputTitle>
                    <ThemedInputNumber
                        precision={1}
                        value={device.MaxPower}
                        min={device.MinPower}
                        onChange={(value) =>
                            value !== null && onDeviceChanged(device, { ...device, MaxPower: Number(value) })
                        }
                    />
                </div>
                <div>
                    <Styled.InputTitle>{t('energyManagementConfiguration.maxEnergy') + ' (kWh)'}</Styled.InputTitle>
                    <ThemedInputNumber
                        value={device.MaxEnergy}
                        min={0}
                        onChange={(value) =>
                            value !== null && onDeviceChanged(device, { ...device, MaxEnergy: Number(value) })
                        }
                    />
                </div>
                <div>
                    <Styled.InputTitle>
                        {t('energyManagementConfiguration.preparationTime') + ' (min)'}
                    </Styled.InputTitle>
                    <ThemedInputNumber
                        value={device.PreparationTime}
                        min={0}
                        onChange={(value) =>
                            value !== null && onDeviceChanged(device, { ...device, PreparationTime: Number(value) })
                        }
                    />
                </div>
                <div>
                    <Styled.InputTitle>{t('energyManagementConfiguration.minRuntime') + ' (min)'}</Styled.InputTitle>
                    <ThemedInputNumber
                        value={device.MinRuntime}
                        min={0}
                        onChange={(value) =>
                            value !== null && onDeviceChanged(device, { ...device, MinRuntime: Number(value) })
                        }
                    />
                </div>
            </Space>
            <Styled.MainTitle>{t('energyManagementConfiguration.schedule')}</Styled.MainTitle>
            {device.Priorities.slice()
                .sort((a, b) => a.Level - b.Level)
                .map((p, index) => (
                    <EnergyManagerDeviceScheduleView
                        energyDevices={energyDevices}
                        property={p}
                        key={index}
                        index={index}
                        properties={device.Priorities}
                        onPropertyChanged={onPropertyChanged}
                        onRemovePriority={onRemovePriority}
                    />
                ))}
            {device.Priorities.length < 99 && (
                <Styled.AddButton>
                    <ThemedCheckableTag onClick={addProp} checked={false} $dashed>
                        <TagWrapper>
                            <MdAdd fontSize={25} />
                        </TagWrapper>
                    </ThemedCheckableTag>
                </Styled.AddButton>
            )}
        </Styled.MainWrapper>
    );
};

export default EnergyManagerDeviceView;
