import { useTranslation } from 'react-i18next';
import SummaryDevice from './SummaryDevice/SummaryDevice';

import { ThemedCard } from 'components/ThemedComponents/ThemedComponents.styles';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from 'models/server/EnergyManagementDevice';
import * as Styled from './EnergyManagerSummary.styles';

type Props = {
    functionBlocks: EnergyManagementDevice[];
    onPropertyChanged: (
        device: EnergyManagementDevice,
        property: EnergyManagementDeviceProperty,
        newProperty: EnergyManagementDeviceProperty,
    ) => void;
};

const EnergyManagerSummary = ({ functionBlocks, onPropertyChanged }: Props) => {
    const { t } = useTranslation();
    const allBlocks = functionBlocks.flatMap((x) => x.Priorities.map((z) => ({ block: x, priority: z })));
    const onBlocks = allBlocks.filter((x) => x.priority.Mode === 1);
    const offBlocks = allBlocks.filter((x) => x.priority.Mode === 0);
    const otherBlocks = allBlocks
        .filter((x) => x.priority.Mode !== 0 && x.priority.Mode !== 1)
        .sort((x, y) => (x.priority.Priority ?? 0) - (y.priority.Priority ?? 0));
    const blocksToShow = [...onBlocks, ...otherBlocks, ...offBlocks];
    return (
        <Styled.Overflow>
            <ThemedCard checked={false}>
                <Styled.Wrapper>
                    <Styled.HeaderWrapper>
                        <Styled.Header>{t('general.name')}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.priority')}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.level')}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.mode')}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.minPower') + ' (kW)'}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.maxPower') + ' (kW)'}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.maxEnergy') + ' (kWh)'}</Styled.Header>
                        <Styled.Header>{t('energyManagementConfiguration.preparationTime') + ' (min)'}</Styled.Header>
                        <Styled.LastHeader>
                            {t('energyManagementConfiguration.minRuntime') + ' (min)'}
                        </Styled.LastHeader>
                    </Styled.HeaderWrapper>
                    {blocksToShow.map(({ block, priority }, index) => (
                        <SummaryDevice
                            allDevices={functionBlocks}
                            allPriority={blocksToShow.map((x) => x.priority)}
                            onPropertyChanged={onPropertyChanged}
                            priority={priority}
                            key={index}
                            device={block}
                        />
                    ))}
                </Styled.Wrapper>
            </ThemedCard>
        </Styled.Overflow>
    );
};

export default EnergyManagerSummary;
