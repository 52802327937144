import { ThemedInputNumber, ThemedTimePicker } from 'components/ThemedComponents/ThemedComponents.styles';
import { Dayjs } from 'dayjs';
import { getLocalFromUtc, getUtcFromLocal } from 'helpers/StringHelper';
import { useImages } from 'hooks/useImages';
import { useServerConfig } from 'hooks/useServerConfig';
import { Presence } from 'models/server/Presence';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './PresenceView.styles';

type Props = {
    index: number;
    presence: Presence;
    onPresenceChanged: (index: number, presence: Presence) => void;
};

const timeFormat = 'YYYY-MM-DDTHH:mm:00';

const PresenceView = ({ presence, index, onPresenceChanged }: Props) => {
    const { t } = useTranslation();
    const { getCategorySettings, images } = useImages();
    const { virtualDevices, rooms } = useServerConfig();

    const configVirtualDevice = useMemo(
        () =>
            virtualDevices?.find(
                (x) => x.id === (presence.ObjID === 0 ? Number(presence.ID.replace('_', '')) : presence.ObjID),
            ),
        [virtualDevices, presence.ObjID],
    );

    const configVirtualDeviceSettings = useMemo(
        () => (configVirtualDevice ? getCategorySettings(configVirtualDevice.category) : undefined),
        [configVirtualDevice?.category, images],
    );

    const currentRoom = useMemo(
        () => rooms?.find((x) => x.id === configVirtualDevice?.roomid),
        [configVirtualDevice?.category, images, rooms],
    );

    const onStartTimeChanged = (date: Dayjs) => {
        const newTime = getUtcFromLocal(date, timeFormat);
        const newPresence: Presence = { ...presence, StartTime: newTime };
        onPresenceChanged(index, newPresence);
    };

    const onEndTimeChanged = (date: Dayjs) => {
        const newTime = getUtcFromLocal(date, timeFormat);
        const newPresence: Presence = { ...presence, StopTime: newTime };
        onPresenceChanged(index, newPresence);
    };

    const onMinDurationChanged = (value: number) => {
        const newPresence: Presence = { ...presence, MinDuration: value };
        onPresenceChanged(index, newPresence);
    };

    const onMaxDurationChanged = (value: number) => {
        const newPresence: Presence = { ...presence, MaxDuration: value };
        onPresenceChanged(index, newPresence);
    };

    const onMinActivationsChanged = (value: number) => {
        const newPresence: Presence = { ...presence, MinNumberActivations: value };
        onPresenceChanged(index, newPresence);
    };

    const onMaxActivationsChanged = (value: number) => {
        const newPresence: Presence = { ...presence, MaxNumberActivations: value };
        onPresenceChanged(index, newPresence);
    };

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <Styled.IconWrapper $color={configVirtualDeviceSettings?.color ?? ''}>
                    <Styled.ServerImage src={configVirtualDeviceSettings?.img} />
                </Styled.IconWrapper>
                <Styled.ServerName>{`${
                    currentRoom ? `${currentRoom.name} - ` : ''
                }${configVirtualDevice?.name}`}</Styled.ServerName>
            </Styled.HeaderWrapper>
            <Styled.Row size={20} wrap>
                <Styled.InputWrapper>
                    <Styled.InputTitle>{t('presenceSimulationProgramEdit.startTime')}</Styled.InputTitle>
                    <ThemedTimePicker
                        value={getLocalFromUtc(presence.StartTime, timeFormat)}
                        format={'HH:mm'}
                        changeOnBlur
                        allowClear={false}
                        onChange={(v) => v && onStartTimeChanged(v)}
                    />
                </Styled.InputWrapper>
                <Styled.InputWrapper>
                    <Styled.InputTitle>{t('presenceSimulationProgramEdit.stopTime')}</Styled.InputTitle>
                    <ThemedTimePicker
                        value={getLocalFromUtc(presence.StopTime, timeFormat)}
                        format={'HH:mm'}
                        changeOnBlur
                        allowClear={false}
                        onChange={(v) => v && onEndTimeChanged(v)}
                    />
                </Styled.InputWrapper>
            </Styled.Row>
            <Styled.Row size={20} wrap>
                <Styled.InputWrapper>
                    <Styled.InputTitle>{t('presenceSimulationProgramEdit.minDuration')}</Styled.InputTitle>
                    <ThemedInputNumber
                        value={presence.MinDuration}
                        status={presence.MinDuration > presence.MaxDuration ? 'error' : undefined}
                        onChange={(v) => v && onMinDurationChanged(v as number)}
                        precision={0}
                        min={1}
                        max={presence.MaxDuration}
                    />
                </Styled.InputWrapper>
                <Styled.InputWrapper>
                    <Styled.InputTitle>{t('presenceSimulationProgramEdit.maxDuration')}</Styled.InputTitle>
                    <ThemedInputNumber
                        value={presence.MaxDuration}
                        status={presence.MinDuration > presence.MaxDuration ? 'error' : undefined}
                        onChange={(v) => v && onMaxDurationChanged(v as number)}
                        precision={0}
                        min={presence.MinDuration}
                    />
                </Styled.InputWrapper>
            </Styled.Row>
            <Styled.Row size={20} wrap>
                <Styled.InputWrapper>
                    <Styled.InputTitle>{t('presenceSimulationProgramEdit.minNumberActivations')}</Styled.InputTitle>
                    <ThemedInputNumber
                        value={presence.MinNumberActivations}
                        onChange={(v) => v && onMinActivationsChanged(v as number)}
                        max={presence.MaxNumberActivations}
                        min={0}
                        precision={0}
                    />
                </Styled.InputWrapper>
                <Styled.InputWrapper>
                    <Styled.InputTitle>{t('presenceSimulationProgramEdit.maxNumberActivations')}</Styled.InputTitle>
                    <ThemedInputNumber
                        value={presence.MaxNumberActivations}
                        onChange={(v) => v && onMaxActivationsChanged(v as number)}
                        min={presence.MinNumberActivations}
                        precision={0}
                    />
                </Styled.InputWrapper>
            </Styled.Row>
        </Styled.MainWrapper>
    );
};

export default PresenceView;
