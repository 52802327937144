import { ThemedButton, ThemedInput, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { AuthContext } from 'contexts/AuthContext';
import { isEmpty } from 'helpers/StringHelper';
import { useApi } from 'hooks/useApi';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Styled from './AddTagModal.styles';

type Props = {
    otherTags: string[];
    serverId: number;
    onCancel: () => void;
    onRefresh: () => void;
};

const AddTagModal = ({ serverId, otherTags, onCancel, onRefresh }: Props) => {
    const { t } = useTranslation();
    const { onRefetchServers } = useContext(AuthContext);
    const { apiFetch } = useApi();

    const [tag, setTag] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<string | undefined>();

    const addTag = async (newTag: string) => {
        setLoading(newTag);

        const result = await apiFetch<{ code: number }>('Servers', 'POST', `/${serverId}/AddTag?value=${newTag}`);

        if (result.code !== 200) {
            switch (result.result?.code) {
                case 20:
                    toast.error(t('errors.tagIsAlreadyAddedToServer'));
                    break;
                default:
                    toast.error(t('errors.errorWhileSendingValue'));
            }
            setLoading(undefined);
            return;
        }

        onRefresh();
        onRefetchServers?.();
        onCancel();
    };

    return (
        <ThemedModal
            width={300}
            title={t('general.addTag')}
            open
            onCancel={onCancel}
            style={{ top: 200 }}
            footer={
                <Footer>
                    <ThemedButton onClick={onCancel} $version="2" disabled={!!loading}>
                        {t('general.cancel')}
                    </ThemedButton>
                </Footer>
            }
        >
            {otherTags.map((oTag) => (
                <Styled.TagWrapper key={oTag}>
                    {oTag}
                    <ThemedButton
                        $version="2"
                        loading={!!loading && loading === oTag}
                        disabled={!!loading && loading !== oTag}
                        onClick={() => addTag(oTag)}
                    >
                        {t('general.add')}
                    </ThemedButton>
                </Styled.TagWrapper>
            ))}
            <Styled.Row>
                <ThemedInput
                    $width="100%"
                    status={isEmpty(tag) ? 'error' : undefined}
                    onChange={(value) => {
                        setTag(value.currentTarget.value);
                    }}
                    value={tag}
                    disabled={!!loading}
                    placeholder={t('general.tag')}
                    maxLength={30}
                />
                <ThemedButton
                    $version="2"
                    disabled={isEmpty(tag) || (!!loading && loading !== tag)}
                    loading={!!loading && loading === tag}
                    onClick={() => tag && addTag(tag)}
                >
                    {t('general.add')}
                </ThemedButton>
            </Styled.Row>
        </ThemedModal>
    );
};

export default AddTagModal;
