import EmptyError from 'components/EmptyError/EmptyError';
import { ThemedButton, ThemedSpin } from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { useDatapoint } from 'hooks/useDatapoint';
import useGoBack from 'hooks/useGoBack';
import { useServerConfig } from 'hooks/useServerConfig';
import { CalendarSwitchPoint } from 'models/server/CalendarSwitchPoint';
import { Datapoint } from 'models/server/Datapoint';
import { DatapointType } from 'models/server/enums/DatapointType';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './CalendarEditPage.styles';
import CalendarGroupView from './CalendarGroupView/CalendarGroupView';

export const dateFormat = 'DD.MM.YYYY HH:mm';
export const serverDateFormat = 'YYYY-MM-DDTHH:mm:00';

const sortFn = (a: CalendarSwitchPoint, b: CalendarSwitchPoint) =>
    dayjs(a.Date, serverDateFormat).isAfter(dayjs(b.Date, serverDateFormat)) ? 1 : -1;

const CalendarEditPage = () => {
    const { t } = useTranslation();
    const { datapointLoading, onDatapointChange } = useDatapoint();
    const { currentServer, currentVirtualDevice, serverConfigLoading } = useServerConfig();
    const { onGoBack } = useGoBack();

    const timeProgramDatapoint = useMemo(
        () => currentVirtualDevice?.datapoints?.find((x) => x.type == DatapointType.TimeProgram),
        [currentVirtualDevice?.datapoints],
    );

    const [calendarSwitchPoints, setCalendarSwitchPoints] = useState<CalendarSwitchPoint[]>(
        timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints?.sort(sortFn) ?? [],
    );

    useEffect(() => {
        setCalendarSwitchPoints(timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints?.sort(sortFn) ?? []);
    }, [timeProgramDatapoint?.TimeProgram?.CalendarSwitchPoints]);

    const pastSwitchpoints = useMemo(
        () =>
            calendarSwitchPoints.filter((sp) =>
                dayjs(sp.Date, { utc: true, format: serverDateFormat }).isBefore(dayjs()),
            ),
        [calendarSwitchPoints],
    );

    const futureSwitchpoins = useMemo(
        () =>
            calendarSwitchPoints.filter((sp) => {
                const day = dayjs(sp.Date, { utc: true, format: serverDateFormat });
                return day.isAfter(dayjs()) || day.isSame(dayjs());
            }),
        [calendarSwitchPoints],
    );

    const onSave = async () => {
        if (!timeProgramDatapoint) {
            return;
        }

        const filteredSwitchPoints: CalendarSwitchPoint[] = [];

        calendarSwitchPoints.forEach((point) => {
            if (
                !filteredSwitchPoints.find(
                    (x) =>
                        (!x.Date?.includes('Z') ? x.Date + 'Z' : x.Date) ===
                            (!point.Date?.includes('Z') ? point.Date + 'Z' : point.Date) && x.Value === point.Value,
                )
            ) {
                filteredSwitchPoints.push({
                    ...point,
                    Date: !point.Date?.includes('Z') ? point.Date + 'Z' : point.Date,
                });
            }
        });

        const newTimeProgramDatapoint: Datapoint = {
            ...timeProgramDatapoint,
            TimeProgram: {
                CalendarSwitchPoints: filteredSwitchPoints,
            },
        };

        const result = await onDatapointChange(newTimeProgramDatapoint);

        if (!result) {
            return;
        }

        onGoBack();
    };

    const onRemove = (sp: CalendarSwitchPoint) => {
        setCalendarSwitchPoints((prev) => prev.filter((x) => x !== sp));
    };

    const onSwitchChanged = (sp: CalendarSwitchPoint, value: boolean) => {
        setCalendarSwitchPoints(
            calendarSwitchPoints.map((x) =>
                sp === x
                    ? {
                          ...x,
                          Value: value,
                      }
                    : x,
            ),
        );
    };

    const onDateChanged = (sp: CalendarSwitchPoint, date: string) => {
        setCalendarSwitchPoints(
            calendarSwitchPoints
                .map((x) =>
                    sp === x
                        ? {
                              ...x,
                              Date: date,
                          }
                        : x,
                )
                .sort(sortFn),
        );
    };

    const addSwitchPoint = () => {
        const now = dayjs();
        const newSwitchPoint: CalendarSwitchPoint = {
            Date: now.subtract(now.utcOffset(), 'minutes').add(5, 'minutes').format(serverDateFormat),
            Value: true,
        };

        setCalendarSwitchPoints((prev) => [...prev, newSwitchPoint].sort(sortFn));
    };

    if (datapointLoading || serverConfigLoading) {
        return <ThemedSpin size="large" />;
    }

    if (!currentServer) {
        return <EmptyError title={t('errors.serverNotFound')} />;
    }

    if (!timeProgramDatapoint || !currentVirtualDevice) {
        return <EmptyError title={t('errors.deviceNotFound')} />;
    }

    if (timeProgramDatapoint.writeprotect) {
        return (
            <EmptyError title={t('errors.modifiactionNotAllowed')}>
                <ThemedButton onClick={onGoBack}>{t('general.goBack')}</ThemedButton>
            </EmptyError>
        );
    }

    return (
        <Styled.MainWrapper>
            <Styled.HeaderWrapper>
                <ThemedButton onClick={onGoBack}>{t('general.cancel')}</ThemedButton>
                <ThemedButton $action onClick={onSave}>
                    {t('general.save')}
                </ThemedButton>
            </Styled.HeaderWrapper>
            {pastSwitchpoints.length > 0 && (
                <CalendarGroupView
                    showAddButton={false}
                    title={t('general.past')}
                    calendarSwitchPoints={pastSwitchpoints}
                    disabled={timeProgramDatapoint?.writeprotect ?? false}
                    showRemove={calendarSwitchPoints.length > 1}
                    onRemove={onRemove}
                    onSwitchChanged={onSwitchChanged}
                    onDateChanged={onDateChanged}
                    onAddSwitchpoint={addSwitchPoint}
                />
            )}
            <CalendarGroupView
                showAddButton
                title={t('general.future')}
                calendarSwitchPoints={futureSwitchpoins}
                disabled={timeProgramDatapoint?.writeprotect ?? false}
                showRemove={calendarSwitchPoints.length > 1}
                onRemove={onRemove}
                onSwitchChanged={onSwitchChanged}
                onDateChanged={onDateChanged}
                onAddSwitchpoint={addSwitchPoint}
            />
        </Styled.MainWrapper>
    );
};

export default CalendarEditPage;
