import { Form } from 'antd';
import {
    ThemedButton,
    ThemedInput,
    ThemedInputPassword,
    ThemedModal,
} from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { AuthContext } from 'contexts/AuthContext';
import { useApi } from 'hooks/useApi';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Styled from './AddServerModal.styles';
import PositionMap from 'components/PositionMap/PositionMap';

type Props = {
    onCancel: () => void;
    onRefresh: () => void;
};

enum FormFields {
    serverLicenseKey = 'serverLicenseKey',
    serverName = 'serverName',
    serverAddress = 'serverAddress',
    serverLogin = 'serverLogin',
    serverPassword = 'serverPassword',
}

const AddServerModal = ({ onCancel, onRefresh }: Props) => {
    const { t } = useTranslation();
    const { onRefetchServers } = useContext(AuthContext);
    const { apiFetch } = useApi();

    const [currentPosition, setCurrentPosition] = useState({
        lat: '49',
        lng: '9',
    });

    const [loading, setLoading] = useState(false);

    const onAdd = async ({
        serverLicenseKey,
        serverName,
        serverAddress,
        serverLogin,
        serverPassword,
    }: {
        serverLicenseKey: string;
        serverName: string;
        serverAddress: string;
        serverLogin: string;
        serverPassword: string;
    }) => {
        setLoading(true);

        const result = await apiFetch<{ code: number }>('Servers', 'POST', undefined, {
            serverLicenseKey: serverLicenseKey,
            name: serverName,
            address: serverAddress,
            latitude: currentPosition.lat,
            longitude: currentPosition.lng,
            login: serverLogin,
            password: serverPassword,
        });

        if (result.code !== 200) {
            switch (result.result?.code) {
                case 15:
                    toast.error(t('errors.serverNotExist'));
                    break;
                case 16:
                    toast.error(t('errors.serverIsAlreadyRegistered'));
                    break;
                default:
                    toast.error(t('errors.errorWhileSendingValue'));
            }

            setLoading(false);
            return;
        }

        onRefresh();
        onRefetchServers?.();
        onCancel();
    };

    return (
        <ThemedModal
            width={450}
            title={t('general.addServer')}
            open
            onCancel={onCancel}
            style={{ top: 100 }}
            maskClosable={false}
            footer={null}
        >
            <Styled.AddForm onFinish={onAdd}>
                <div>
                    <Styled.ItemTitle>{`${t('general.serverLicenseKey')}:`}</Styled.ItemTitle>
                    <Form.Item
                        name={FormFields.serverLicenseKey}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                            {
                                pattern: /^[A-Z0-9-]{39}$/,
                                message: t('errors.serverLicenseKeyIsWrong'),
                            },
                        ]}
                    >
                        <ThemedInput $width="100%" $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <Styled.ItemTitle>{`${t('general.serverName')}:`}</Styled.ItemTitle>
                    <Form.Item
                        name={FormFields.serverName}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $width="100%" $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <Styled.ItemTitle>{`${t('general.serverAddress')}:`}</Styled.ItemTitle>
                    <Form.Item
                        name={FormFields.serverAddress}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $width="100%" $version="2" />
                    </Form.Item>
                </div>
                <Styled.MapWrapper>
                    <PositionMap position={currentPosition} onChange={(v) => setCurrentPosition(v)} />
                </Styled.MapWrapper>
                <div>
                    <Styled.ItemTitle>{`${t('general.serverLogin')}:`}</Styled.ItemTitle>
                    <Form.Item
                        name={FormFields.serverLogin}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInput $width="100%" $version="2" />
                    </Form.Item>
                </div>
                <div>
                    <Styled.ItemTitle>{`${t('general.serverPassword')}:`}</Styled.ItemTitle>
                    <Form.Item
                        name={FormFields.serverPassword}
                        rules={[
                            {
                                required: true,
                                message: t('errors.fieldCannotBeEmpty'),
                            },
                        ]}
                    >
                        <ThemedInputPassword autoComplete="new-password" />
                    </Form.Item>
                </div>
                <Footer>
                    <ThemedButton onClick={onCancel} $version="2" disabled={loading}>
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton htmlType="submit" $version="2" loading={loading}>
                        {t('general.add')}
                    </ThemedButton>
                </Footer>
            </Styled.AddForm>
        </ThemedModal>
    );
};

export default AddServerModal;
