import { InputRef } from 'antd';
import { ThemedButton, ThemedInputPassword, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { encryptRSA } from 'helpers/RSAHelper';
import { useDatapoint } from 'hooks/useDatapoint';
import { Datapoint } from 'models/server/Datapoint';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TbNumber0,
    TbNumber1,
    TbNumber2,
    TbNumber3,
    TbNumber4,
    TbNumber5,
    TbNumber6,
    TbNumber7,
    TbNumber8,
    TbNumber9,
} from 'react-icons/tb';
import * as Styled from './EnterPinModal.styles';

interface Props {
    datapoint: Datapoint;
    onCancel: () => void;
    color: string;
}

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const numberIcons: Record<number, JSX.Element> = {
    0: <TbNumber0 size={18} />,
    1: <TbNumber1 size={18} />,
    2: <TbNumber2 size={18} />,
    3: <TbNumber3 size={18} />,
    4: <TbNumber4 size={18} />,
    5: <TbNumber5 size={18} />,
    6: <TbNumber6 size={18} />,
    7: <TbNumber7 size={18} />,
    8: <TbNumber8 size={18} />,
    9: <TbNumber9 size={18} />,
};

const EnterPinModal = ({ datapoint, onCancel, color }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [code, setCode] = useState<string>();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const { onDatapointButtonClick } = useDatapoint();

    useEffect(() => {
        if (inputRef) {
            inputRef.current?.focus();
        }
    }, [inputRef]);

    const onOk = async () => {
        if (!code) {
            return;
        }

        setLoading(true);
        const encryptedCode = await encryptRSA(code);
        const result = await onDatapointButtonClick(datapoint.id, encodeURIComponent(encryptedCode));

        if (result) {
            onCancel();
            setLoading(false);
            return;
        }

        setLoading(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onOk();
        }
    };

    const onButtonPress = (n: number) => {
        if (code && code.toString().length === 6) {
            return;
        }

        setCode((prev) => (prev ? prev + n.toString() : n.toString()));
    };

    return (
        <ThemedModal
            width="fit-content"
            title={t('enterPinModal.codeRequired')}
            open={true}
            onCancel={onCancel}
            closable={!loading}
            maskClosable={!loading}
            footer={
                <Styled.Footer>
                    <ThemedButton onClick={onCancel} disabled={loading} $version="2">
                        {t('general.cancel')}
                    </ThemedButton>
                    <ThemedButton $version="2" onClick={onOk} danger disabled={!code} loading={loading}>
                        OK
                    </ThemedButton>
                </Styled.Footer>
            }
            style={{ top: 150 }}
        >
            <Styled.MainWrapper>
                <ThemedInputPassword
                    ref={inputRef}
                    size="large"
                    min={0}
                    step={1}
                    autoComplete="new-password"
                    maxLength={6}
                    onKeyDown={handleKeyDown}
                    autoFocus={false}
                    visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                    value={code ?? ''}
                    onChange={(v) => {
                        const currentValue = v.currentTarget.value;

                        if (currentValue.length === 0 || !currentValue) {
                            setCode(undefined);
                        }

                        if (!/[0-9]/.test(currentValue)) {
                            v.preventDefault();
                            return;
                        }

                        setCode(currentValue);
                    }}
                />
                <Styled.ButtonsWrapper>
                    {numbers.map((n) => (
                        <Styled.RoundButton onClick={() => onButtonPress(n)} key={n} style={{ borderColor: color }}>
                            {numberIcons[n]}
                        </Styled.RoundButton>
                    ))}
                </Styled.ButtonsWrapper>
                <Styled.RoundButton onClick={() => onButtonPress(0)} style={{ borderColor: color }}>
                    {numberIcons[0]}
                </Styled.RoundButton>
            </Styled.MainWrapper>
        </ThemedModal>
    );
};

export default EnterPinModal;
