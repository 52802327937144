import { routes } from 'App';
import { Collapse, Space } from 'antd';
import MarkersMap from 'components/MarkersMap/MarkersMap';
import { ThemedButton, ThemedCheckableTag, ThemedSearch } from 'components/ThemedComponents/ThemedComponents.styles';
import { AuthContext } from 'contexts/AuthContext';
import { isEmpty } from 'helpers/StringHelper';
import useAuth from 'hooks/useAuth';
import useCookie from 'hooks/useCookie';
import Server from 'models/Server';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiRefreshCw } from 'react-icons/fi';
import { GrMapLocation } from 'react-icons/gr';
import { IoSearchOutline } from 'react-icons/io5';
import { MdErrorOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as Styled from './HomePage.styles';
import ServerView from './ServerView/ServerView';

const { Panel } = Collapse;

const HomePage = () => {
    const { t } = useTranslation();
    const { authUser: user } = useAuth();
    const { servers, dashboardConfigs, onRefetchServers } = useContext(AuthContext);
    const { colors } = useTheme();
    const navigate = useNavigate();

    const [search, setSearch] = useState<string>('');

    const { value: mapExpanded, updateCookie: setMapExpanded } = useCookie<boolean>('control-map-expanded', true);

    const tags = useMemo(() => {
        const allTags = servers.flatMap((z) => z.tags).map((x) => x.value);
        return allTags.filter((c, index) => {
            return allTags.indexOf(c) === index;
        });
    }, []);
    const [selectedTag, setSelectedTag] = useState<string>();

    useEffect(() => {
        if (user?.isPortalAdmin) {
            navigate(`${routes.headAdmin}/licenses`);
        }
    }, []);

    const filterByTag = (servers: Server[], tag?: string) => {
        if (!tag) {
            return servers;
        }

        return servers.filter((x) => x.tags.some((x) => x.value === tag));
    };

    const filterByString = (servers: Server[], searchString: string) => {
        if (isEmpty(searchString)) {
            return servers;
        }

        const smallSearch = searchString.toLowerCase();
        return servers.filter(
            (serv) =>
                serv?.name?.toLowerCase()?.includes(smallSearch) ||
                serv?.address?.toLowerCase()?.includes(smallSearch) ||
                serv?.tags?.some((z) => z.value.toLowerCase()?.includes(smallSearch)),
        );
    };

    const searchServers = useMemo(() => {
        const filteredByString = filterByString(servers, search);
        const filteredByTag = filterByTag(filteredByString, selectedTag);

        return filteredByTag;
    }, [servers, search, selectedTag]);

    const mapServers = useMemo(
        () => searchServers.filter((x) => !isEmpty(x.latitude) && !isEmpty(x.longitude)),
        [searchServers],
    );

    const serversWithConfig = useMemo(
        () => searchServers.filter((x) => !!dashboardConfigs.find((z) => z.serverId === x.id)?.monitorIos?.length),
        [searchServers, dashboardConfigs],
    );

    const serversWithoutConfig = useMemo(
        () => searchServers.filter((x) => !dashboardConfigs.find((z) => z.serverId === x.id)?.monitorIos?.length),
        [searchServers, dashboardConfigs],
    );

    const onTagClicked = (tag: string) => {
        if (tag === selectedTag) {
            setSelectedTag(undefined);
            return;
        }

        setSelectedTag(tag);
    };

    return (
        <Styled.MainWrapper>
            <Styled.MapCollaps bordered={false} activeKey={mapExpanded ? ['0'] : undefined} expandIcon={() => <></>}>
                <Panel
                    key={0}
                    collapsible="icon"
                    header={
                        <Styled.HomePageHeader size={20} wrap>
                            <Styled.HomePageHeaderLeftSide size={20} wrap>
                                <ThemedButton
                                    onClick={() => setMapExpanded(!mapExpanded, { expires: 365 })}
                                    $width={50}
                                    icon={<GrMapLocation fontSize={20} color={colors.text} />}
                                />
                                <ThemedSearch
                                    value={search}
                                    onChange={(v) => setSearch(v.currentTarget.value)}
                                    prefix={<IoSearchOutline />}
                                />
                                <Space size={[0, 8]} wrap>
                                    {tags.map((tag, index) => (
                                        <ThemedCheckableTag
                                            onClick={() => onTagClicked(tag)}
                                            checked={tag === selectedTag}
                                            key={index}
                                        >
                                            {tag}
                                        </ThemedCheckableTag>
                                    ))}
                                </Space>
                            </Styled.HomePageHeaderLeftSide>
                            <ThemedButton onClick={onRefetchServers} icon={<FiRefreshCw />}>
                                {t('reload')}
                            </ThemedButton>
                        </Styled.HomePageHeader>
                    }
                >
                    {mapServers.length > 0 && (
                        <Styled.MapWrapper>
                            <MarkersMap markers={mapServers} />
                        </Styled.MapWrapper>
                    )}
                </Panel>
            </Styled.MapCollaps>
            {searchServers.length === 0 && (
                <Styled.NoServersWrapper>
                    <MdErrorOutline color={colors.error} fontSize={80} />
                    <Styled.NoServersTitle>{t('errors.serversNotFound')}</Styled.NoServersTitle>
                </Styled.NoServersWrapper>
            )}
            <Space size={[40, 20]} direction="vertical">
                {serversWithConfig.map((server, index) => (
                    <ServerView server={server} key={index} />
                ))}
            </Space>
            <Space size={[40, 20]} wrap align="start">
                {serversWithoutConfig.map((server, index) => (
                    <ServerView server={server} key={index} />
                ))}
            </Space>
        </Styled.MainWrapper>
    );
};

export default HomePage;
