import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    margin: 20px 0;
    width: 100%;

    @media (max-width: 500px) {
        flex-direction: column-reverse;
        gap: 20px;
    }
`;

export const MainWrapper = styled.div`
    width: 100%;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Row = styled.div`
    display: flex;
    gap: 10px;
`;

export const Title = styled.div`
    padding: 20px 0 10px;
    font-weight: 500;
    font-size: 16px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 10px;
    margin-left: auto;
`;
