import { Space } from 'antd';
import ColorSelectionButton from 'components/ServerControls/Controls/ColorSelectionButton/ColorSelectionButton';
import { ThemedSlider, ThemedSwitch } from 'components/ThemedComponents/ThemedComponents.styles';
import {
    getBooleanValue,
    getColorStringWithChangedColor,
    getColorStringWithChangedValue,
    getLightColor,
    getLightValue,
} from 'helpers/DatapointHelper';
import { useImages } from 'hooks/useImages';
import { Light } from 'models/server/Light';
import { Scene } from 'models/server/Scene';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { LightType } from 'models/server/enums/LightType';
import { useMemo } from 'react';
import * as Styled from './SceneView.styles';

type Props = {
    virtualDevice: VirtualDevice;
    scene: Scene;
    isOffScene: boolean;
    onLightValueChanged: (v: string, lightIndex: number) => void;
};

const SceneView = ({ scene, virtualDevice, isOffScene, onLightValueChanged }: Props) => {
    const { radio_bulb, socket, getCategorySettings } = useImages();

    const lightsNames = useMemo(
        () => virtualDevice.datapoints.filter((x) => x.name.startsWith('light.value'))?.map((x) => x.customname) ?? [],
        [virtualDevice.datapoints],
    );

    const categorySettings = useMemo(() => getCategorySettings(virtualDevice.category), [virtualDevice]);

    const getImageForLight = (light: Light) => {
        switch (light.LightType) {
            case LightType.Color:
            case LightType.Color_Strip:
            case LightType.DimmerPercent:
            case LightType.DimmerVoltage0:
            case LightType.DimmerVoltage1:
                return radio_bulb;
            case LightType.Switch:
                return socket;
        }
    };

    return (
        <Space size={[20, 10]} wrap>
            {scene.values.map((light, index) => (
                <Styled.LightWrapper key={index}>
                    <Styled.HeaderWrapper>
                        <Styled.IconWrapper $color={categorySettings.color}>
                            <Styled.ServerImage src={getImageForLight(light)} />
                        </Styled.IconWrapper>
                        <Styled.ServerName>{light.Description ?? lightsNames[index]}</Styled.ServerName>
                        {light.LightType === LightType.Switch && (
                            <ThemedSwitch
                                disabled={isOffScene}
                                checked={getBooleanValue(light.Value?.toString())}
                                onChange={(v) => onLightValueChanged(v.toString(), index)}
                                $color={categorySettings.color}
                            />
                        )}
                    </Styled.HeaderWrapper>
                    {(light.LightType == LightType.DimmerPercent ||
                        light.LightType == LightType.DimmerVoltage0 ||
                        light.LightType == LightType.DimmerVoltage1) && (
                        <Styled.SliderWrapper>
                            <ThemedSlider
                                step={1}
                                min={Number(light?.Range?.[0] ?? 0)}
                                max={Number(light?.Range?.[1] ?? 100)}
                                disabled={isOffScene}
                                value={Number(light.Value)}
                                onChange={(v) => onLightValueChanged(v.toString(), index)}
                            />
                        </Styled.SliderWrapper>
                    )}
                    {(light.LightType == LightType.Color || light.LightType == LightType.Color_Strip) && (
                        <Styled.ColorSliderWrapper>
                            <Styled.SliderWrapper>
                                <ThemedSlider
                                    $color={getLightColor(light.Value?.toString() ?? '')}
                                    disabled={isOffScene}
                                    step={1}
                                    min={Number(light?.Range?.[0] ?? 0)}
                                    max={Number(light?.Range?.[1] ?? 100)}
                                    value={getLightValue(light.Value?.toString() ?? '', light.Range ?? [])}
                                    onChange={(v) =>
                                        onLightValueChanged(
                                            getColorStringWithChangedValue(+v, light?.Value?.toString() ?? ''),
                                            index,
                                        )
                                    }
                                />
                            </Styled.SliderWrapper>
                            <ColorSelectionButton
                                disabled={isOffScene}
                                color={getLightColor(light.Value?.toString() ?? '')}
                                onChange={(v) =>
                                    onLightValueChanged(
                                        getColorStringWithChangedColor(v, light?.Value?.toString() ?? ''),
                                        index,
                                    )
                                }
                            />
                        </Styled.ColorSliderWrapper>
                    )}
                </Styled.LightWrapper>
            ))}
        </Space>
    );
};

export default SceneView;
