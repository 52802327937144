import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { AuthContext } from 'contexts/AuthContext';
import { WebsocketMessage } from 'models/server/WebsocketMessage';
import { useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import useAuth from './useAuth';
import { lastWebsocketMessageState } from './useSignalRConfig';
import useStateRef from './useStateRef';

const signalRUrl = 'https://dtem-homesrv-apis-signalr.azurewebsites.net/SmartHomeHub';

type Props = {
    serverIds: number[];
    onNewMessage: (serverId: string, message: WebsocketMessage) => void;
};

const useSignalR = ({ serverIds, onNewMessage }: Props) => {
    const { isDemo } = useContext(AuthContext);
    const { getUserToken } = useAuth();
    const [connection, setConnection, connectionRef] = useStateRef<HubConnection | undefined>(undefined);
    const setLastWebsocketMessage = useSetRecoilState(lastWebsocketMessageState);

    const handleNewMessage = (message: string, messageServerId: string) => {
        try {
            const lastMessage = JSON.parse(message) as WebsocketMessage;
            if (!lastMessage || !messageServerId) {
                return;
            }

            if (process.env.NODE_ENV === 'development') {
                console.log(messageServerId, lastMessage);
            }

            setLastWebsocketMessage(lastMessage);
            onNewMessage(messageServerId, lastMessage);
        } catch (ex) {
            if (process.env.NODE_ENV === 'development') {
                console.log(ex, message);
            }
        }
    };

    const connectSignalR = async (): Promise<HubConnection | undefined> => {
        if (isDemo) {
            return;
        }

        if (connection?.state === HubConnectionState.Connected) {
            return connectionRef.current;
        }
        const cloudToken = await getUserToken();

        return await new Promise((resolve) => {
            try {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(signalRUrl, {
                        accessTokenFactory: () => {
                            return cloudToken ?? '';
                        },
                    })
                    .withAutomaticReconnect()
                    .build();

                newConnection.serverTimeoutInMilliseconds = 86400000;
                newConnection.on('NewMessage', (message, messageServerId) =>
                    handleNewMessage(message, messageServerId),
                );
                newConnection.onclose((err) => {
                    if (err?.message?.includes('Invalid user Id')) {
                        newConnection.stop();
                        connectSignalR();

                        if (process.env.NODE_ENV === 'development') {
                            console.log(err);
                        }
                    }
                });
                newConnection.start().then(() => {
                    setConnection(newConnection);

                    newConnection?.invoke('JoinPortalServers', serverIds);

                    resolve(newConnection);
                });
            } catch {
                resolve(undefined);
            }
        });
    };

    const disconnectFromSignalR = async () => {
        if (isDemo) {
            return;
        }

        if (connection?.state !== HubConnectionState.Connected) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            console.log('Information: WebSocket disconnected');
        }

        await connection.stop();
    };

    useEffect(() => {
        return () => {
            disconnectFromSignalR();
        };
    }, []);

    return {
        connectSignalR,
        disconnectFromSignalR,
        connectionRef,
        connection,
    };
};

export default useSignalR;
