import { ThemedButton, ThemedSlider } from 'components/ThemedComponents/ThemedComponents.styles';
import { getVirtualDatapointNameTranslation } from 'helpers/StringHelper';
import i18n from 'i18next';
import { capitalize } from 'lodash';
import { Datapoint } from 'models/server/Datapoint';
import { VirtualDevice } from 'models/server/VirtualDevice';
import { DatapointNames } from 'models/server/enums/DatapointNames';
import { DatapointType } from 'models/server/enums/DatapointType';
import { VirtualDeviceType } from 'models/server/enums/VirtualDeviceType';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ServersCheckedControlsProps } from '../ServersControls/ServersControls';
import * as Styled from './ServerOptionsController.styles';

type Props = {
    checkedControls: ServersCheckedControlsProps[];
    disabled: boolean;
    type: VirtualDeviceType;
    isEditMode: boolean;
    virtualDevices: VirtualDevice[];
    onChangeEditMode: () => void;
    onSelectAll: () => void;
    onGroupedButtonClicked: (dpFilter: (dp: Datapoint) => boolean) => void;
    onSliderValueChanged: (dpFilter: (dp: Datapoint) => boolean, value: number) => void;
    onSwitchValueChanged: (
        dpFilter: (dp: Datapoint) => boolean,
        value: boolean,
        disabledFilter?: (dp: Datapoint) => boolean,
    ) => void;
};

type DpOption = {
    dpFilter: (dp: Datapoint) => boolean;
    disabledFilter?: (dp: Datapoint) => boolean;
    type: 'button' | 'slider' | 'switch';
    customNameOn?: string;
    customNameOff?: string;
};

export const getServerControllerOptions = (type: VirtualDeviceType): DpOption[] => {
    switch (type) {
        case VirtualDeviceType.CentralLightController:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualAllOff,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualAllOn,
                },
            ];
        case VirtualDeviceType.ConstantLightController:
        case VirtualDeviceType.Dimmer:
            return [
                {
                    type: 'slider',
                    dpFilter: (x) => x.type == DatapointType.Brightness && x.name == DatapointNames.VirtualSlider,
                },
                {
                    type: 'switch',
                    dpFilter: (x) =>
                        x.type == DatapointType.Switch &&
                        (x.name == DatapointNames.VirtualSwitch || x.name == DatapointNames.VirtualEnable),
                },
            ];
        case VirtualDeviceType.AlarmClock:
            return [
                {
                    type: 'slider',
                    dpFilter: (x) =>
                        x.type == DatapointType.Number && x.name == DatapointNames.VirtualSnoozeIntervalDuration,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarmClock,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStartSnoozeTimer,
                },
            ];
        case VirtualDeviceType.CentralHomeButtons:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualComingHome,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualLeavingHome,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualSleep,
                },
            ];
        case VirtualDeviceType.FireAndWaterAlarm:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarm,
                },
            ];
        case VirtualDeviceType.GarageGate:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerOpen,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerClose,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStop,
                },
            ];
        case VirtualDeviceType.LightController:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
                },
            ];
        case VirtualDeviceType.PresenceSimulation:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
                },
            ];
        case VirtualDeviceType.PushSwitch:
        case VirtualDeviceType.PushButton:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button,
                },
            ];
        case VirtualDeviceType.Schedule:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
                },
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSchedulerOn,
                    disabledFilter: (x) => x.type == DatapointType.TimeProgram,
                    customNameOff: i18n.t('datapointNames.VirtualSchedulerOff'),
                    customNameOn: i18n.t('datapointNames.VirtualSchedulerOn'),
                },
            ];
        case VirtualDeviceType.SelectionSwitch:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualIncreaseButton,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualDecreaseButton,
                },
            ];
        case VirtualDeviceType.StaircaseTimer:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch && x.name == DatapointNames.VirtualSwitch,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStartTimer,
                },
            ];
        case VirtualDeviceType.Switch:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch,
                },
            ];
        case VirtualDeviceType.ToiletVentilationController:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch,
                },
            ];
        case VirtualDeviceType.CentralScheduler:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch,
                    customNameOff: i18n.t('datapointNames.VirtualSchedulerOff'),
                    customNameOn: i18n.t('datapointNames.VirtualSchedulerOn'),
                },
            ];
        case VirtualDeviceType.AstroFunction:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch,
                    customNameOff: i18n.t('general.activeOff'),
                    customNameOn: i18n.t('datapointNames.activeOn'),
                },
            ];

        case VirtualDeviceType.Blinds:
            return [
                {
                    type: 'slider',
                    dpFilter: (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSetBlinds,
                },
                {
                    type: 'slider',
                    dpFilter: (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualSetSlats,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualShadingOn,
                },
            ];
        case VirtualDeviceType.BurglarAlarm:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmAlarmSystem,
                },
                {
                    type: 'button',
                    dpFilter: (x) =>
                        x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmAlarmSystemDelayed,
                },
                {
                    type: 'button',
                    dpFilter: (x) =>
                        x.type == DatapointType.Button && x.name == DatapointNames.VirtualArmWithoutPresenceDetectors,
                },
                {
                    type: 'button',
                    dpFilter: (x) =>
                        x.type == DatapointType.Button && x.name == DatapointNames.VirtualDisarmAlarmSystem,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualQuitAlarm,
                },
            ];
        case VirtualDeviceType.Calendar:
            return [
                {
                    type: 'switch',
                    dpFilter: (x) => x.type == DatapointType.Switch,
                },
            ];
        case VirtualDeviceType.CentralBattery:
            return [
                {
                    type: 'slider',
                    dpFilter: (x) => x.type == DatapointType.Number && x.name == DatapointNames.VirtualLowBatteryLimit,
                },
            ];
        case VirtualDeviceType.CentralBlindsController:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerUp,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerDown,
                },
                {
                    type: 'button',
                    dpFilter: (x) =>
                        x.type == DatapointType.Button && x.name == DatapointNames.VirtualCentralBlindsStop,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualShadowing,
                },
            ];
        case VirtualDeviceType.CentralGarageGate:
            return [
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerOpen,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualTriggerClose,
                },
                {
                    type: 'button',
                    dpFilter: (x) => x.type == DatapointType.Button && x.name == DatapointNames.VirtualStop,
                },
            ];
        case VirtualDeviceType.CentralRoomControl:
        case VirtualDeviceType.CentralWindowsAndDoors:
        case VirtualDeviceType.RoomController:
        case VirtualDeviceType.TotalEnergyMonitor:
        case VirtualDeviceType.OperationalModeSelector:
        case VirtualDeviceType.EnergyManagement:
        case VirtualDeviceType.Alphatronics:
        default:
            return [];
    }
};

const ServerOptionsController = ({
    checkedControls,
    type,
    isEditMode,
    virtualDevices,
    onSelectAll,
    onChangeEditMode,
    onGroupedButtonClicked,
    onSliderValueChanged,
    onSwitchValueChanged,
}: Props) => {
    const { t } = useTranslation();

    const options: DpOption[] = useMemo(() => getServerControllerOptions(type), [type]);

    const isLoading = checkedControls.some((x) => x.loading);

    if (!isEditMode) {
        return (
            <Styled.Wrapper>
                <ThemedButton onClick={onChangeEditMode}>{t('general.startGroupedChanges')}</ThemedButton>
            </Styled.Wrapper>
        );
    }

    const getView = (option: DpOption) => {
        switch (option.type) {
            case 'button': {
                const vd = virtualDevices?.[0];
                const dp = vd?.datapoints?.find(option.dpFilter);

                if (!dp) {
                    return <></>;
                }

                return (
                    <Styled.ButtonWrapper>
                        <ThemedButton
                            onClick={() => onGroupedButtonClicked(option.dpFilter)}
                            disabled={checkedControls.length === 0 || isLoading}
                            $version="virtualDevice"
                        >
                            {getVirtualDatapointNameTranslation(dp?.name)}
                        </ThemedButton>
                    </Styled.ButtonWrapper>
                );
            }
            case 'slider': {
                const vd = virtualDevices?.[0];
                const dp = vd?.datapoints?.find(option.dpFilter);

                if (!dp) {
                    return <></>;
                }

                const min = Number(dp.range[0] ?? 0);

                return (
                    <ThemedSlider
                        min={min}
                        max={Number(dp.range[1] ?? 100)}
                        step={1}
                        onAfterChange={(v) => onSliderValueChanged(option.dpFilter, v)}
                        disabled={checkedControls.length === 0 || isLoading}
                    />
                );
            }
            default:
                return (
                    <Styled.SwitchWrapper>
                        <ThemedButton
                            onClick={() => onSwitchValueChanged(option.dpFilter, true, option.disabledFilter)}
                            disabled={checkedControls.length === 0 || isLoading}
                            $version="virtualDevice"
                        >
                            {option.customNameOn ?? t('general.mainSwitchOn')}
                        </ThemedButton>
                        <ThemedButton
                            onClick={() => onSwitchValueChanged(option.dpFilter, false, option.disabledFilter)}
                            disabled={checkedControls.length === 0 || isLoading}
                            $version="virtualDevice"
                        >
                            {option.customNameOff ?? t('general.mainSwitchOff')}
                        </ThemedButton>
                    </Styled.SwitchWrapper>
                );
        }
    };

    return (
        <Styled.Wrapper>
            <Styled.HeaderWrapper>
                <Styled.Title>{`${capitalize(t('general.selected'))} ${checkedControls.length} ${
                    checkedControls.length === 1 ? t('general.control') : t('general.controls')
                }`}</Styled.Title>
                <ThemedButton disabled={isLoading} onClick={onSelectAll}>
                    {t('general.selectAll')}
                </ThemedButton>
                <ThemedButton disabled={isLoading} onClick={onChangeEditMode}>
                    {t('general.close')}
                </ThemedButton>
            </Styled.HeaderWrapper>
            {options.map((x, index) => (
                <Styled.OptionWrapper key={index}>{getView(x)}</Styled.OptionWrapper>
            ))}
        </Styled.Wrapper>
    );
};

export default ServerOptionsController;
