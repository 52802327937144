import { ThemedButton, ThemedModal } from 'components/ThemedComponents/ThemedComponents.styles';
import { Footer } from 'components/YesNoModal/YesNoModal.styles';
import { useTranslation } from 'react-i18next';

import WpcSchemeElement from '../WpcSchemeElement/WpcSchemeElement';
import * as Styled from './WpcSelectElementModal.styles';
import { elementsConfig } from '../../CreateWpcProjectPage';

type Props = {
    visible: boolean;
    onElementSelected: (elementType: number) => void;
    onClose: () => void;
};

const WpcSelectElementModal = ({ visible, onClose, onElementSelected }: Props) => {
    const { t } = useTranslation();

    return (
        <ThemedModal
            width="fit-content"
            title={''}
            open={visible}
            onCancel={onClose}
            style={{ top: 150 }}
            closable={true}
            maskClosable={true}
            footer={
                <Footer>
                    <ThemedButton onClick={onClose} $version="2">
                        {t('general.close')}
                    </ThemedButton>
                </Footer>
            }
        >
            <Styled.Wrapper wrap size={10}>
                {elementsConfig(t)
                    .filter((x) => x.removable)
                    .map((e) => (
                        <WpcSchemeElement key={e.type} element={e} onClick={() => onElementSelected(e.type)} />
                    ))}
            </Styled.Wrapper>
        </ThemedModal>
    );
};

export default WpcSelectElementModal;
