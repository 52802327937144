import { routes } from 'App';
import { ThemedMapWrapper } from 'components/ThemedComponents/ThemedComponents.styles';
import { isEmpty } from 'helpers/StringHelper';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import Server from 'models/Server';
import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import { useNavigate } from 'react-router';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const L = require('leaflet');

const mcg = L.markerClusterGroup();

const MarkerCluster = ({ markers }: { markers: Server[] }) => {
    const map = useMap();
    const navigate = useNavigate();

    const onServerSelected = (server: Server) => {
        navigate(`${routes.server}/${server.id}`);
    };

    useEffect(() => {
        mcg.clearLayers();

        if (markers.length === 0) {
            return;
        }

        markers.forEach((server: Server) => {
            const marker = L.marker([Number(server.latitude), Number(server.longitude)]);
            marker
                .addTo(mcg)
                .bindPopup(server.name)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .on('mouseover', () => marker.openPopup())
                .on('mouseout', () => marker.closePopup())
                .on('click', () => onServerSelected(server));
            return marker;
        });

        if (markers.length > 0) {
            map.fitBounds(mcg.getBounds());
        }
        map.addLayer(mcg);
    }, [markers, map]);

    return null;
};

interface Props {
    markers: Server[];
    height?: number;
}

const MarkersMap = ({ markers, height = 400 }: Props): JSX.Element => {
    const [zoom, setZoom] = useState<number>();
    const validMarkers = markers.filter((x) => !isEmpty(x.latitude) && !isEmpty(x.longitude));

    const getCenter = (): [number, number] => {
        if (validMarkers.length === 0) {
            return [49, 9];
        }

        const lats = validMarkers.map((m) => Number(m.latitude));
        const lngs = validMarkers.map((m) => Number(m.longitude));
        return [(Math.min(...lats) + Math.max(...lats)) / 2, (Math.min(...lngs) + Math.max(...lngs)) / 2];
    };

    useEffect(() => {
        setZoom(13);
    }, []);

    return zoom ? (
        <ThemedMapWrapper key={height}>
            <MapContainer style={{ height: height }} zoom={3} center={getCenter()}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MarkerCluster markers={validMarkers} />
            </MapContainer>
        </ThemedMapWrapper>
    ) : (
        <></>
    );
};

export default MarkersMap;
