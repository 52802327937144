import { Space } from 'antd';
import {
    ThemedCard,
    ThemedCheckableTag,
    ThemedDatePicker,
    ThemedInputNumber,
    ThemedSelect,
    ThemedSwitch,
    ThemedTimePicker,
} from 'components/ThemedComponents/ThemedComponents.styles';
import dayjs from 'dayjs';
import { getLocalFromUtc, getUtcFromLocal } from 'helpers/StringHelper';
import { EnergyManagementDevice, EnergyManagementDeviceProperty } from 'models/server/EnergyManagementDevice';
import { IconWrapper, TagWrapper } from 'pages/SceneEditPage/SceneEditPage.styles';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { InputTitle } from '../EnergyManagerDeviceView.styles';
import * as Styled from './EnergyManagerDeviceScheduleView.styles';

type Props = {
    index: number;
    property: EnergyManagementDeviceProperty;
    properties: EnergyManagementDeviceProperty[];
    energyDevices: EnergyManagementDevice[];
    onPropertyChanged: (property: EnergyManagementDeviceProperty, newProperty: EnergyManagementDeviceProperty) => void;
    onRemovePriority: (index: number) => void;
};

export const getFreePriorities = (device: EnergyManagementDevice[]): number[] => {
    const usedPrio = device.flatMap((z) => z.Priorities.map((x) => x.Priority));
    const allPriorities = Array.from(Array(100).keys()).filter((x) => x !== 0);
    return allPriorities.filter((x) => !usedPrio.includes(x));
};

const EnergyManagerDeviceScheduleView = ({
    property: prop,
    energyDevices,
    index,
    properties,
    onPropertyChanged,
    onRemovePriority,
}: Props) => {
    const { t } = useTranslation();
    const [applyToAllDays, setApplyToAllDays] = useState(false);

    const days = useMemo(
        () => [
            t('general.sunday'),
            t('general.monday'),
            t('general.tuesday'),
            t('general.wednesday'),
            t('general.thursday'),
            t('general.friday'),
            t('general.saturady'),
        ],
        [],
    );

    const selectOptions = useMemo(
        () => [
            {
                value: 0,
                label: t('general.off'),
            },
            {
                value: 1,
                label: t('general.on'),
            },
            {
                value: 2,
                label: t('general.solar'),
            },
            {
                value: 3,
                label: t('general.solarAndLowPrice'),
            },
        ],
        [],
    );

    const onPriorityChanged = (value: number) => {
        const usedPrio = energyDevices.flatMap((z) => z.Priorities.map((x) => x.Priority));
        if (usedPrio.includes(value)) {
            const freePrio = getFreePriorities(energyDevices);
            const freeAbovePrio =
                value > (prop?.Priority ?? 0) ? freePrio.filter((x) => x > value) : freePrio.filter((x) => x < value);
            onPropertyChanged(prop, {
                ...prop,
                Priority:
                    (value > (prop?.Priority ?? 0) ? freeAbovePrio?.[0] : freeAbovePrio?.[freeAbovePrio.length - 1]) ??
                    freePrio[0],
            });
        } else {
            onPropertyChanged(prop, {
                ...prop,
                Priority: value,
            });
        }
    };

    const addCustomTime = () => {
        const newTime = getUtcFromLocal(dayjs().add(1, 'm'), 'YYYY-MM-DDTHH:mm:00');
        const newProp: EnergyManagementDeviceProperty = {
            ...prop,
            FinishTimeCalendar: [...(prop.FinishTimeCalendar ?? []), newTime],
        };

        onPropertyChanged(prop, newProp);
    };

    const onRemoveCustomTime = (index: number) => {
        const calendarItems = [...prop.FinishTimeCalendar];
        calendarItems.splice(index, 1);
        const newProp: EnergyManagementDeviceProperty = {
            ...prop,
            FinishTimeCalendar: calendarItems,
        };
        onPropertyChanged(prop, newProp);
    };

    return (
        <ThemedCard checked={false} $width="100%">
            <Styled.Wrapper>
                {index !== 0 && (
                    <Styled.RemoveButton>
                        <IconWrapper>
                            <IoMdCloseCircleOutline onClick={() => onRemovePriority(index)} size={18} />
                        </IconWrapper>
                    </Styled.RemoveButton>
                )}
                <Space size={20} wrap>
                    <div>
                        <InputTitle>{t('energyManagementConfiguration.priority')}</InputTitle>
                        <ThemedInputNumber
                            precision={0}
                            value={prop.Priority}
                            min={1}
                            disabled={prop.Mode === 0 || prop.Mode === 1}
                            onChange={(value: number | null) => value !== null && onPriorityChanged(value)}
                        />
                    </div>
                    <div>
                        <InputTitle>
                            {`${t('energyManagementConfiguration.level')} (${prop.Level}${
                                index === properties.length - 1 ? '+' : ' - ' + properties[index + 1].Level
                            })`}
                        </InputTitle>
                        <ThemedInputNumber
                            precision={0}
                            disabled={index === 0}
                            value={prop.Level}
                            min={0}
                            onChange={(value: number | null) =>
                                value !== null &&
                                onPropertyChanged(prop, {
                                    ...prop,
                                    Level: value,
                                })
                            }
                        />
                    </div>
                    <div>
                        <InputTitle>{t('energyManagementConfiguration.mode')}</InputTitle>
                        <ThemedSelect
                            style={{ width: 158 }}
                            value={prop.Mode}
                            options={selectOptions}
                            onChange={(v: number | null) => {
                                if (v === null) {
                                    return;
                                }
                                const freePrio = getFreePriorities(energyDevices);
                                onPropertyChanged(prop, {
                                    ...prop,
                                    Mode: v,
                                    Priority:
                                        v === 0 || v === 1 ? undefined : prop.Priority ? prop.Priority : freePrio[0],
                                });
                            }}
                        />
                    </div>
                    <div>
                        <InputTitle>{t('energyManagementConfiguration.hysteresis')}</InputTitle>
                        <ThemedInputNumber
                            precision={1}
                            step={0.1}
                            value={prop.Hysteresis}
                            min={0}
                            max={100}
                            onChange={(value: number | null) =>
                                value !== null &&
                                onPropertyChanged(prop, {
                                    ...prop,
                                    Hysteresis: value,
                                })
                            }
                        />
                    </div>
                </Space>
                <Space size={20} wrap>
                    {prop.FinishTimeScheduler.map((time, schedulerIndex) => (
                        <div key={schedulerIndex}>
                            <InputTitle>{days[schedulerIndex]}</InputTitle>
                            <div>
                                <ThemedTimePicker
                                    disabled={applyToAllDays && schedulerIndex !== 0}
                                    allowClear={false}
                                    format="HH:mm"
                                    showNow={false}
                                    value={dayjs('00:00', 'HH:mm').add(time === -1 ? 0 : time, 'seconds')}
                                    onChange={(value) => {
                                        if (value) {
                                            if (schedulerIndex === 0 && applyToAllDays) {
                                                onPropertyChanged(prop, {
                                                    ...prop,
                                                    FinishTimeScheduler: prop.FinishTimeScheduler.map(
                                                        () => value.hour() * 60 * 60 + value.minute() * 60,
                                                    ),
                                                });
                                            } else {
                                                onPropertyChanged(prop, {
                                                    ...prop,
                                                    FinishTimeScheduler: prop.FinishTimeScheduler.map((z, zIndex) =>
                                                        zIndex === schedulerIndex
                                                            ? value.hour() * 60 * 60 + value.minute() * 60
                                                            : z,
                                                    ),
                                                });
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    <Styled.Row>
                        <ThemedSwitch
                            checked={applyToAllDays}
                            onChange={(v) => {
                                const value = v;

                                if (value) {
                                    onPropertyChanged(prop, {
                                        ...prop,
                                        FinishTimeScheduler: prop.FinishTimeScheduler.map(
                                            (z, index, array) => array[0],
                                        ),
                                    });
                                }

                                setApplyToAllDays(value);
                            }}
                        />
                        {t('energyManagementConfiguration.applyToAllDays')}
                    </Styled.Row>
                </Space>
                <div>
                    <Styled.CustomFinishLabel>
                        {t('energyManagementConfiguration.customFinish')}
                    </Styled.CustomFinishLabel>
                    <Space size={[20, 10]} wrap>
                        {prop.FinishTimeCalendar?.map((item, calendarIndex) => (
                            <ThemedCard
                                checked
                                key={calendarIndex}
                                style={{
                                    opacity:
                                        dayjs(item, { utc: true, format: 'YYYY-MM-DDTHH:mm:ssT' }) < dayjs() ? 0.6 : 1,
                                }}
                            >
                                <Styled.Row>
                                    <ThemedDatePicker
                                        showTime
                                        format="DD.MM.YYYY HH:mm"
                                        allowClear={false}
                                        showNow={false}
                                        value={getLocalFromUtc(item, 'YYYY-MM-DDTHH:mm:ssT')}
                                        onChange={(value) => {
                                            if (!value) {
                                                return;
                                            }
                                            onPropertyChanged(prop, {
                                                ...prop,
                                                FinishTimeCalendar: prop.FinishTimeCalendar.map((x, dIndex) =>
                                                    dIndex == calendarIndex
                                                        ? !!value
                                                            ? getUtcFromLocal(value, 'YYYY-MM-DDTHH:mm:00')
                                                            : getUtcFromLocal(dayjs(), 'YYYY-MM-DDTHH:mm:00')
                                                        : x,
                                                ),
                                            });
                                        }}
                                        onSelect={(value) => {
                                            onPropertyChanged(prop, {
                                                ...prop,
                                                FinishTimeCalendar: prop.FinishTimeCalendar.map((x, dIndex) =>
                                                    dIndex == calendarIndex
                                                        ? !!value
                                                            ? getUtcFromLocal(value, 'YYYY-MM-DDTHH:mm:00')
                                                            : getUtcFromLocal(dayjs(), 'YYYY-MM-DDTHH:mm:00')
                                                        : x,
                                                ),
                                            });
                                        }}
                                    />
                                    <IconWrapper>
                                        <IoMdCloseCircleOutline
                                            onClick={() => onRemoveCustomTime(calendarIndex)}
                                            size={18}
                                        />
                                    </IconWrapper>
                                </Styled.Row>
                            </ThemedCard>
                        ))}
                        <ThemedCheckableTag onClick={addCustomTime} checked $dashed>
                            <TagWrapper>
                                <MdAdd fontSize={25} />
                            </TagWrapper>
                        </ThemedCheckableTag>
                    </Space>
                </div>
            </Styled.Wrapper>
        </ThemedCard>
    );
};

export default EnergyManagerDeviceScheduleView;
