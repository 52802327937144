import { Layout, Menu } from 'antd';
import { ThemedCheckableTag, overflowScrollbarStyle } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

const { Sider } = Layout;

export const SideMenu = styled(Sider)`
    margin: 16px !important;
    box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
    border-radius: 12px;
    background-color: ${(props) => props.theme.colors.backgroundLayer1} !important;
    width: 280px !important;
    min-width: 280px !important;
    max-width: 280px !important;
    padding: 16px 8px;

    @media (max-width: 750px) {
        position: absolute !important;
        top: 0px;
        left: 0px;
        z-index: 9999999999999;
    }
`;

export const Line = styled.div`
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        ${(props) => props.theme.colors.text + '40'},
        rgba(255, 255, 255, 0)
    ) !important;
    border: 0px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;
    height: 0.0625rem;
    margin: 12px -16px;
`;

export const Logo = styled.img`
    max-width: 50%;
    margin-left: 25%;
    margin-right: 25%;
    cursor: pointer;
`;

export const ItemsMenu = styled(Menu)`
    width: 100%;
    background-color: transparent;
    border: none !important;
    color: ${(props) => props.theme.colors.titleText} !important;

    .ant-menu {
        overflow-y: auto;
        max-height: 400px;
        ${overflowScrollbarStyle}
    }

    .ant-menu-submenu-open {
        background: ${(props) => props.theme.colors.backgroundLayer2 + '66'} !important;
        border-radius: 4px;
    }

    .ant-menu-item-selected {
        background-color: ${(props) => props.theme.colors.text + '1A'};
        color: ${(props) => props.theme.colors.titleText} !important;
    }

    .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
            color: ${(props) => props.theme.colors.titleText} !important;
        }
    }
`;

export const Dot = styled.div<{ $isConnected: boolean }>`
    height: 6px;
    width: 6px;
    min-width: 6px !important;
    border-radius: 50%;
    background-color: ${(props) => (props.$isConnected ? props.theme.colors.success : props.theme.colors.error)};
`;

export const ClosedWrapper = styled(ThemedCheckableTag)`
    position: absolute;
    top: 50%;
    left: 0;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 2px;
`;

export const CloseWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    visibility: hidden;

    @media (max-width: 750px) {
        visibility: visible;
    }
`;
