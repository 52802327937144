import { overflowXScrollbarStyle } from 'components/ThemedComponents/ThemedComponents.styles';
import styled from 'styled-components';

export const ValuesGrid = styled.div`
    display: flex;
`;

export const DndWrapper = styled.div`
    overflow-x: auto;
    padding-bottom: 3px;
    ${overflowXScrollbarStyle}
`;

export const DragIconWrapper = styled.div`
    position: absolute;
    left: -10px;
    top: -10px;
    z-index: 99999;
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 2px;
`;

export const MainWrapper = styled.div`
    position: relative;
`;

export const Wrapper = styled.div``;
