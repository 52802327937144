import {
    Button,
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    Modal,
    Progress,
    Select,
    Slider,
    Spin,
    Switch,
    Tag,
    TimePicker,
} from 'antd';
import styled, { ExecutionContext, css } from 'styled-components';

const { RangePicker } = DatePicker;

export type ControlVersion = '0' | '1' | '2' | 'virtualDevice';

const background = (version: ControlVersion, important?: boolean) => css`
    background-color: ${(props) => {
        switch (version) {
            case '0':
                return props.theme.colors.backgroundLayer0 + (important ? ' !important' : '');
            case '1':
                return props.theme.colors.backgroundLayer1 + (important ? ' !important' : '');
            case '2':
                return props.theme.colors.backgroundLayer2 + (important ? ' !important' : '');
            case 'virtualDevice':
                return props.theme.colors.virtualDeviceButtonBackground + (important ? ' !important' : '');
        }
    }};
`;

export type ThemedButtonProps = {
    $version?: ControlVersion;
    $width?: number | string;
    $fill?: boolean;
    $action?: boolean;
    $height?: number;
};

const buttonCss = (props: ExecutionContext & ThemedButtonProps) => css`
    ${background(props.$version ?? '1')}
    ${props.$fill && `flex: 1;`}
    border-color: ${props.theme.colors.text + '80'} !important;
    color: ${props.theme.colors.text};

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: ${props.$width === undefined || typeof props.$width === 'number'
        ? `${props.$width ?? 80}px`
        : props.$width};
    height: ${`${props.$height ?? 36}px`};

    &[class*='ant-btn-round'] {
        padding: 0;
        height: ${props.$width ?? 32}px;
    }

    &[class*='ant-btn-dangerous'] {
        border-color: #ff4d4f !important;
    }

    ${props.$action &&
    css`
        border-color: #07bc0c !important;
        color: #07bc0c !important;
    `}

    &:disabled {
        color: ${(props) => props.theme.colors.text};
        opacity: 0.5;

        &[class*='ant-btn-dangerous'] {
            color: #ff4d4f !important;
        }

        ${props.$action &&
        css`
            color: #07bc0c !important;
        `}
    }

    &:hover {
        border-color: ${props.theme.colors.text} !important;
        color: ${props.theme.colors.text} !important;
    }
`;

export const ThemedButton = styled(Button)<ThemedButtonProps>`
    ${(props) => buttonCss(props)}
`;

export const ThemedSpin = styled(Spin)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin-dot .ant-spin-dot-item {
        background-color: ${(props) => props.theme.colors.text};
    }
`;

export const overflowScrollbarStyle = css`
    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 6px;
        background: ${(props) => props.theme.colors.text + '80'};
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: ${(props) => props.theme.colors.text + '80'};
    }
`;

export const overflowXScrollbarStyle = css`
    &::-webkit-scrollbar {
        height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 6px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 6px;
        background: ${(props) => props.theme.colors.text + '80'};
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: ${(props) => props.theme.colors.text + '80'};
    }
`;

export const ThemedTag = styled(Tag)`
    color: ${(props) => props.theme.colors.text} !important;
`;

export const ThemedCheckableTag = styled(Tag.CheckableTag)<{ $dashed?: boolean; $error?: boolean }>`
    color: ${(props) => props.theme.colors.text} !important;
    background-color: ${(props) => props.theme.colors.backgroundLayer1 + '80'} !important;
    ${(props) =>
        props.$dashed &&
        css`
            border: 1px dashed ${props.theme.colors.text};
        `}

    ${(props) =>
        props.$error &&
        css`
            border: 1px solid ${props.theme.colors.error};
        `}

    &[class*='ant-tag-checkable-checked'] {
        background-color: ${(props) => props.theme.colors.backgroundLayer2} !important;
    }

    &:hover {
        background-color: ${(props) => props.theme.colors.backgroundLayer2 + '80'} !important;
    }
`;

export const ThemedSearch = styled(Input)`
    width: 300px;
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.backgroundLayer1};
    border-color: ${(props) => props.theme.colors.text + '80'} !important;

    & > input {
        background-color: transparent;
        color: ${(props) => props.theme.colors.text};
    }
`;

export const ThemedSlider = styled(Slider)<{ $color?: string }>`
    .ant-slider-rail {
        background-color: ${({ theme }) => theme.colors.backgroundLayer2 + '80'} !important;
    }

    .ant-slider-track {
        background-color: ${(props) => props.$color ?? props.theme.colors.text} !important;
    }

    .ant-slider-handle::after {
        box-shadow: 0 0 0 2px ${(props) => props.$color ?? props.theme.colors.text} !important;
    }

    .ant-slider-handle::before {
        box-shadow: none;
    }

    .ant-slider-handle:hover::after {
        box-shadow: 0 0 0 4px ${(props) => props.$color ?? props.theme.colors.text} !important;
    }

    .ant-slider-handle:focus::after {
        box-shadow: 0 0 0 4px ${(props) => props.$color ?? props.theme.colors.text} !important;
    }

    &[class*='ant-slider-disabled'] {
        opacity: 0.6;
        .ant-slider-rail {
            background-color: ${({ theme }) => theme.colors.backgroundLayer2 + '80'} !important;
        }

        .ant-slider-track {
            background-color: ${(props) => props.$color ?? props.theme.colors.text} !important;
        }
    }
`;

export const ThemedSwitch = styled(Switch)<{ $color?: string }>`
    background-color: ${({ theme }) => theme.colors.backgroundLayer2 + '80'};

    &:hover {
        background-color: ${({ theme }) => theme.colors.text + '40'} !important;
    }

    &[class*='ant-switch-checked'] {
        background-color: ${({ theme, $color }) => ($color ?? theme.colors.text) + 'B3'} !important;

        &:hover {
            background-color: ${({ theme, $color }) => ($color ?? theme.colors.text) + '80'} !important;
        }
    }
`;

export const ThemedSelect = styled(Select)<{ $version?: ControlVersion }>`
    width: 100%;
    min-width: 100px;

    .ant-select-selector {
        ${(props) => background(props.$version ?? '1', true)}
        border-color: ${(props) => props.theme.colors.text + '80'} !important;
        color: ${(props) => props.theme.colors.text} !important;
    }

    .ant-select-arrow,
    .anticon {
        color: ${(props) => props.theme.colors.text} !important;
    }

    &[class*='ant-select-disabled'] {
        opacity: 0.6;
    }
`;

export const ThemedInput = styled(Input)<{ $version?: ControlVersion; $width?: number | string }>`
    ${(props) => background(props.$version ?? '1', true)}
    width: ${(props) => {
        if (!props.$width) {
            return '300px';
        }
        if (typeof props.$width === 'number') {
            return `${props.$width}px`;
        }

        return props.$width;
    }};
    color: ${(props) => props.theme.colors.text};
    border-color: ${(props) => props.theme.colors.text + '80'} !important;

    & > input {
        background-color: transparent;
        color: ${(props) => props.theme.colors.text};
    }
`;

export const ColorStateButton = styled(ThemedButton)<{ $color?: string; $isActive?: boolean; $width: number }>`
    background-color: ${(props) => (props.$isActive ? props.$color : 'transparent')} !important;
    border-color: ${(props) => props.$color} !important;

    &:disabled {
        background-color: transparent !important;
    }
`;

export const ThemedTimePicker = styled(TimePicker)`
    ${background('1')}

    border-color: ${(props) => props.theme.colors.text + '80'} !important;

    input {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .anticon {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .ant-picker-clear {
        ${background('1')}
    }
`;

export const ThemedDatePicker = styled(DatePicker)`
    ${background('1')}

    border-color: ${(props) => props.theme.colors.text + '80'} !important;

    input {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .anticon {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .ant-picker-clear {
        ${background('1')}
    }
`;

export const ThemedInputNumber = styled(InputNumber)<{ $version?: ControlVersion }>`
    ${(props) => background(props.$version ?? '1', true)}
    border-color: ${(props) => props.theme.colors.text + '80'} !important;
    width: 100%;

    input {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .ant-input-number-group-addon {
        color: ${(props) => props.theme.colors.text} !important;
        border-left: none;
        border-color: ${(props) => props.theme.colors.text + '80'} !important;
    }

    .ant-input-number {
        background-color: transparent !important;
        border-color: ${(props) => props.theme.colors.text + '80'} !important;
    }

    &[class*='ant-input-number-status-error'] {
        border-color: ${(props) => props.theme.colors.error} !important;
    }

    .ant-input-number-handler-wrap {
        border-radius: 0;
    }

    &[class*='ant-input-number-disabled'] {
        opacity: 0.6;
    }
`;

export const ThemedRangePicker = styled(RangePicker)`
    ${background('1')}

    border-color: ${(props) => props.theme.colors.text + '80'} !important;

    input {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .anticon {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .ant-picker-clear {
        ${background('1')}
    }
`;

export const ThemedModal = styled(Modal)<{ $version?: ControlVersion }>`
    .ant-modal-content,
    .ant-modal-header {
        ${(props) => background(props.$version ?? '1')}
    }

    &,
    .ant-modal-title,
    .anticon-close {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .leaflet-layer,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-attribution,
    .geosearch {
        ${(props) =>
            props.theme.theme === 'dark'
                ? css`
                      filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
                  `
                : undefined}
    }

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
        ${background('2', true)}
        color: ${(props) => props.theme.colors.text} !important;
    }
`;

export const ThemedInputPassword = styled(Input.Password)<{ $marginTop: number }>`
    ${background('2', true)}
    border-color: ${(props) => props.theme.colors.text + '80'} !important;
    ${(props) =>
        props.$marginTop &&
        css`
            margin-top: ${props.$marginTop}px;
        `}

    & > input {
        background-color: transparent;
        color: ${(props) => props.theme.colors.text};
    }

    .anticon {
        color: ${(props) => props.theme.colors.text} !important;
    }
`;

export const ThemedCard = styled(ThemedCheckableTag)<{ $width?: string; checked: boolean }>`
    padding: 15px;
    cursor: default;

    &:hover {
        background-color: ${(props) =>
            props.checked
                ? props.theme.colors.backgroundLayer2
                : props.theme.colors.backgroundLayer1 + '80'} !important;
    }

    width: ${(props) => props.$width ?? 'fit-content'};
    height: fit-content;
`;

export const ThemedProgress = styled(Progress)`
    .ant-progress-text {
        color: ${(props) => props.theme.colors.text} !important;
    }
`;

export const ThemedCheckboxGroup = styled(Checkbox.Group)<{ $version?: ControlVersion }>`
    .ant-checkbox-wrapper {
        color: ${(props) => props.theme.colors.text} !important;
    }

    .ant-checkbox-inner {
        background-color: #50505080;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        border-color: ${(props) => props.theme.colors.backgroundLayer1} !important;
        ${(props) => background(props.$version ?? '1', true)}
    }
`;

export const ThemedMapWrapper = styled.div`
    .leaflet-layer,
    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out,
    .leaflet-control-attribution,
    .geosearch {
        ${(props) =>
            props.theme.theme === 'dark'
                ? css`
                      filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
                  `
                : undefined}
    }

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
        ${background('2', true)}
        color: ${(props) => props.theme.colors.text} !important;
    }
`;
