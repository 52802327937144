import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SceneWrapper = styled.div`
    flex: 1;
    display: flex;
    gap: 10px;
`;

export const ColorDatapointWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
`;
