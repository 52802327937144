import { Form } from 'antd';
import styled from 'styled-components';

export const AddForm = styled(Form)`
    width: 300px;
    margin: 100px auto 0;

    .ant-input:-webkit-autofill,
    .ant-input:-webkit-autofill:hover,
    .ant-input:-webkit-autofill:focus,
    .ant-input:-webkit-autofill:active {
        color-scheme: dark;
        -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.backgroundLayer2} inset !important;
        box-shadow: 0 0 0 30px ${(props) => props.theme.colors.backgroundLayer2} inset !important;
    }
`;

export const ItemTitle = styled.div`
    color: ${(props) => props.theme.colors.text};
    margin-left: 5px;
`;
