import { Layout } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

export const MainHeader = styled(Header)`
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 55px;
    margin-top: 16px;
    gap: 16px;
    padding-left: 10px;

    @media (max-width: 750px) {
        padding-right: 10px;
        padding-left: 20px;
    }
`;

export const TitleWrapper = styled.div`
    flex: 1;
    color: ${(props) => props.theme.colors.text + 'CC'};
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 6px;
`;
